/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
User Role :

'' - empty non passport user
P - Passport user
S - Shoprunner user
*/

export default {
    auth: {
        isAuthenticated: false,
        accessToken: '',
        userType: '',
        expiresAt: '',
        lastVisitedPage: '',
        authError: '',
        authSuccess: '',
        rememberConsent: false,
        reAuthenticatedUser: '',
    },
    profile: {
        firstName: '',
        lastName: '',
        userRole: '',
        contactId: '',
        email: '',
        membershipType: 'G',
        membershipTypeLoading: false,
    },
    shopRunner: {
        srToken: '',
    },
    addressBook: {
        addressBookEntries: [],
        billingAddress: [],
        totalEntries: 0,
        totalPages: 0,
        pageNumber: 0,
        guestAddressBookEntries: [],
    },
    wallet: {
        walletEntries: [],
        error: {
            walletSaveCreditCard: '',
        },
        creditCardSavedFlag: false,
    },
    enterpriseId: {
        enterpriseId: '',
        error: false,
    },
};
