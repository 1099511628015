/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { withStyles } from '@material-ui/core/styles';

const QaListStyle = ({ palette, breakpoints }) => ({
    layoutBorder: {
        border: `1px solid ${(palette.text?.disabled || '#B7B7B7')}`,
        padding: '14px 19px',
        marginBottom: '28px',
    },
    tabs: {
        '& button': {
            minWidth: 'auto',
            borderBottom: `2px solid ${(palette.common?.white || '#fff')}`,
            overflow: 'visible',
            '& span': {
                textTransform: 'capitalize',
            },
            '&:hover': {
                borderBottom: `2px solid ${(palette.text?.secondary || '#757575')}`,
            },
            '&:focus-visible': {
                outline: '-webkit-focus-ring-color auto 1px',
            },
        },
    },
    addIcon: {
        justifyContent: 'flex-end',
        display: 'flex',
        color: (palette.text?.secondary || '#757575'),
        '& svg': {
            fontSize: '30px',
        },
    },
    expansionTitle: {
        '& h2,h3,h4,h5,h6,p,span': {
            fontSize: '1.14rem',
            fontWeight: 400,
            lineHeight: 1.5,
            margin: 0,
        },
    },
    layoutTitleBox: {
        '& h2,h3,h4,h5,h6,p,span': {
            fontSize: '1.71rem',
            fontWeight: 400,
            lineHeight: 1.3,
            margin: 0,
        },
    },
    expansionContainer: {
        marginBottom: '10px',
    },
    layoutTitle: {
        marginLeft: '7px',
    },
    formControl: {
        margin: '20px 0px',
        width: '100%',
    },
    marginBottom18: {
        marginBottom: '18px',
    },
    expansionDetails: {
        '& p': {
            fontSize: '16px',
            overflowWrap: 'break-word',
        },
    },
    [breakpoints?.down('md')]: {
        layoutBorder: {
            padding: '10px',
        },
    },
});

const ExpansionPanel = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles(({ palette, breakpoints }) => ({
    root: {
        backgroundColor: (palette.colorNeutral20 || '#f5f5f5'),
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
        '&:hover': {
            backgroundColor: (palette.backToHomeHoverBg || '#EBEBEB'),
        },
        '&:focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px',
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0px',
        },
    },
    expanded: {},
    [breakpoints?.down('md')]: {
        root: {
            padding: '0px 12px',
            '& h6': {
                fontSize: '16px',
            },
        },
    },
}))(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiExpansionPanelDetails);

export {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    QaListStyle,
};

export default QaListStyle;
