/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import initialState from '../../../../initialState';
import * as actions from './Subscription-Action';

const subscriptionReducer = (state = initialState.payment.subscription, action) => {
    switch (action.type) {
        case actions.subscribeEmail().type:
            return {
                ...state,
                emailOptIn: true,
            };
        case actions.unsubscribeEmail().type:
            return {
                ...state,
                emailOptIn: false,
            };
        case actions.subscribeTextmsg().type:
            return {
                ...state,
                smsOptIn: true,
            };
        case actions.unsubscribeTextmsg().type:
            return {
                ...state,
                smsOptIn: false,
            };
        case actions.setSubscriptionMessage().type:
            return {
                ...state,
                optInEntryMessage: action.data,
            };
        case actions.subscribeOrderStatus().type:
            return {
                ...state,
                orderStatusOptIn: true,
            };
        case actions.unsubscribeOrderStatus().type:
            return {
                ...state,
                orderStatusOptIn: false,
            };
        default:
            return state;
    }
};

export default subscriptionReducer;
