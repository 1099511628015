/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import {
    fork, takeLatest, call, put,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';

import {
    getVenmoClientId,
    loadVenmoClientId,
    clearVenmoClientId,
    submitVenmoPayment,
} from './Venmo-Actions';
import { placeOrder } from '../../Payment-Actions';

import * as showLoadingMessageActions from '../../../Common/ducks/ShowLoadingMessage/ShowLoadingMessage-Actions';
import cartServices from '../../../../../../../apis/cart-service-apis/cartServices';
import { checkJWT } from '../../../Global/Global-Operations';

function* workerGetVenmoClientId() {
    try {
        mbpLogger.logDebug({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'workerGetVenmoClientId',
            message: 'Get Venmo Pay Client Id',
        });

        // show loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        const jwtToken = yield call(checkJWT);

        const payload = {
            env: {},
            jwtToken,
            paymentMethod: 'Venmo',
        };

        // Get Venmo Session Client Token
        const response = yield call(cartServices.paymentClientToken, payload);
        const { clientToken } = response.data;

        yield put(clearVenmoClientId());
        yield put(loadVenmoClientId(clientToken));
        // hide loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'workerGetVenmoClientId',
            jsError: ex,
            message: 'Failed in getting google client id',
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    }
}

function* workerSubmitVenmoPayment(action) {
    try {
        mbpLogger.logDebug({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'workerSubmitVenmoPayment',
            message: 'Submit Venmo Pay Payment',
        });

        const {
            result,
            history,
        } = action.data;

        const placeOrderConfig = {
            history,
            params: {},
            disablePaymentFields: false,
            source: 'PWA',
            venmoPayment: {
                nonce: result.nonce,
            },
        };

        yield put(placeOrder(placeOrderConfig));
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'workerSubmitVenmoPayment',
            jsError: ex,
            message: 'Failed submitting venmo payment',
        });
    }
}

function* watcherSubmitVenmoPayment() {
    yield takeLatest(submitVenmoPayment().type, workerSubmitVenmoPayment);
}

function* watcherGetVenmoClientId() {
    yield takeLatest(getVenmoClientId().type, workerGetVenmoClientId);
}

const watchers = [
    fork(watcherGetVenmoClientId),
    fork(watcherSubmitVenmoPayment),
];

export {
    watchers,
};

export default {};
