/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const REQUEST_ENTERPRISE_ID = 'REQUEST_ENTERPRISE_ID';
export const LOAD_ENTERPRISE_ID_FROM_LOCAL_STORAGE = 'LOAD_ENTERPRISE_ID_FROM_LOCAL_STORAGE';
export const ENTERPRISE_ID_LOADED = 'ENTERPRISE_ID_LOADED';
export const UPDATE_ENTERPRISE_ID = 'UPDATE_ENTERPRISE_ID';
export const UPDATE_ON_SIGN_IN_ENTERPRISE_ID = 'UPDATE_ON_SIGN_IN_ENTERPRISE_ID';
export const ENTERPRISE_ID_MESSAGE_LOADED = 'ENTERPRISE_ID_MESSAGE_LOADED';
export const ENTERPRISE_ID_LOADING_ERROR = 'ENTERPRISE_ID_LOADING_ERROR';
export const ENTERPRISE_ID_LOADING = 'ENTERPRISE_ID_LOADING';
export const ENTERPRISE_ID_DISABLE_LOADING = 'ENTERPRISE_ID_DISABLE_LOADING';
export const LOAD_ENTERPRISE_USER_SEGMENT = 'LOAD_ENTERPRISE_USER_SEGMENT';
