/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { uuidv4 } from '../../../helpers/uuid';

/* eslint-disable no-bitwise */
export const cipher = (salt) => {
    const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
    const byteHex = (n) => `0${Number(n).toString(16)}`.substr(-2);
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

    return (text) => text
        .split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
};

export const decipher = (salt) => {
    const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
    // const saltChars = textToChars(salt);
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return (encoded) => encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join('');
};

export const getCryptedOrderDetailsURL = (orderNumber, uuid = uuidv4(), zipCode, billingZipCode) => {
    try {
        const cipherKey = uuid.slice(0, 8);
        const cryptedOrderNumber = cipher(cipherKey)(orderNumber).replace(
            /^(.{8})(.{4})(.{4})(.{4})(.+)$/,
            '$1-$2-$3-$4-$5',
        );
        const cryptedZipCode = zipCode ? cipher(cipherKey)(zipCode) : '';
        const cryptedBillingZipCode = billingZipCode ? cipher(cipherKey)(billingZipCode) : '';
        if (cryptedZipCode && cryptedBillingZipCode) {
            return `/account/orders/${uuid}/${cryptedOrderNumber}/${cryptedZipCode}/${cryptedBillingZipCode}`;
        }
        return `/account/orders/${uuid}/${cryptedOrderNumber}`;
    } catch (e) {
        return '';
    }
};

export const getParamsFromCryptedURL = (url = '/') => {
    try {
        if (url) {
            const urlArray = url.replace(/^\//, '').split('/'); // remove the begining [/] if exists and split it into array
            const uuid = urlArray[2];
            const cryptedOrderNumber = urlArray[3];
            const cryptedZipCode = urlArray[4];
            const cryptedBillingZipCode = urlArray[5];
            // Verify URL format: account/orders/7521d209-d0a1-4339-b0a4-adbf63f4c52d/096e6f6e-6e6d-696e-6c68-6b69696e6a
            // or format: /account/orders/2e6d05b7-7346-4957-bfc8-6754c90c2f89/02656465-6566-6265-6763-6066626267/6c61606663/6c61606663
            // or format: /account/orders/57948236-439f-4df3-b60f-c72959876a5c/33373032363537373036/3934353336/3934353336
            if (
                uuid && /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(uuid)
                && cryptedOrderNumber && /^[0-9a-f]{8}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{0,4}(-[0-9a-f]{10})?$/.test(cryptedOrderNumber)
            ) {
                const cipherKey = uuid.slice(0, 8);
                const orderNumber = cryptedOrderNumber ? decipher(cipherKey)(cryptedOrderNumber.replace(/-/g, '')) : '';
                const zipCode = cryptedZipCode ? decipher(cipherKey)(cryptedZipCode.replace(/-/g, '')) : '';
                const billingZipCode = cryptedBillingZipCode ? decipher(cipherKey)(cryptedBillingZipCode.replace(/-/g, '')) : '';
                return {
                    uuid,
                    orderNumber,
                    zipCode,
                    billingZipCode,
                };
            }
            return {};
        }
        return {};
    } catch (e) {
        return {};
    }
};

export const cipherAgentDetails = async (agentId, agentKey, salt, year) => {
    const value = agentId + agentKey + salt + year;
    const encodedValue = new TextEncoder().encode(value);
    let hashBuffer = '';
    try {
        hashBuffer = await crypto.subtle.digest('SHA-256', encodedValue);
    } catch (e) {
        return hashBuffer;
    }
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

    return hashHex;
};
