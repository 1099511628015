/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import Media from 'react-media';
import { Button, Drawer, withStyles } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
    bool, func, object, string,
} from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PassportPage from '../../../PassportPage/PassportPage';
import { closePassportSideBar } from '../../../../../../state/ducks/Passport/Passport-Action';
import { getPassportSideBarStatus } from '../../../../../../state/ducks/Passport/Passport-Selectors';

const styles = () => ({
    paper: {
        height: '70%',
        bottom: '0',
        top: 'auto',
    },

    drawerContent: {
        maxWidth: '530px',
        width: '100%',
        overflow: 'auto',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '0px',
        zIndex: 2,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    closeIcon: {
        fontSize: '30px',
        color: 'secondary',
        '&:hover': {
            color: 'red',
        },
    },
});

const PassportSideTab = ({
    classes, ssrDeviceType = '', addedBenefits, passportCloseSideBar, passportSideBarStatus,
}) => (
    <>
        <Media
            query="(min-width: 767px)"
            defaultMatches={ssrDeviceType === 'desktop'}
            render={() => (
                <Drawer
                    variant="temporary"
                    anchor="right"
                    open={passportSideBarStatus}
                    onClose={() => passportCloseSideBar()}
                >
                    <Button onClick={() => passportCloseSideBar()} className={classes.closeButton}>
                        <HighlightOffIcon className={classes.closeIcon} />
                    </Button>
                    <div className={classes.drawerContent}>
                        <PassportPage addedBenefits={addedBenefits} />
                    </div>
                </Drawer>
            )}
        />
        <Media
            query="(max-width: 767px)"
            defaultMatches={ssrDeviceType === 'mobile'}
            render={() => (
                <Drawer
                    variant="temporary"
                    anchor="bottom"
                    open={passportSideBarStatus}
                    onClose={() => passportCloseSideBar()}
                    classes={{ paper: classes.paper }}
                >
                    <Button onClick={() => passportCloseSideBar()} className={classes.closeButton}>
                        <HighlightOffIcon className={classes.closeIcon} />
                    </Button>
                    <div className={classes.drawerContent}>
                        <PassportPage addedBenefits={addedBenefits} />
                    </div>
                </Drawer>
            )}
        />

    </>
);

PassportSideTab.propTypes = {
    classes: object,
    ssrDeviceType: string,
    addedBenefits: bool,
    passportCloseSideBar: func,
    passportSideBarStatus: bool,
};
PassportSideTab.defaultProps = {
    classes: {},
    addedBenefits: false,
    ssrDeviceType: '',
    passportCloseSideBar: () => {},
    passportSideBarStatus: false,
};

const mapStateToProps = (state) => ({
    passportSideBarStatus: getPassportSideBarStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
    passportCloseSideBar: bindActionCreators(closePassportSideBar, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PassportSideTab));
