/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Define a single function that doesnt do anything and import it to
 * other files to reduce the amount of anonymous functions floating around
 * as well as make it more expressive as to whats supposed to happen.
 */
function noop() {}

export default noop;
