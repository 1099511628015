/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from './restClient';

const RESOURCE_ROOT_CARTSERVICE = '/checkout';
const RESOURCE_VERSION_CARTSERVICE = '/v2';
const RESOURCE_PAGE_CARTSERVICE = '/cart';
const RESOURCE_PAGE_PAYMENT_ROUTE_CARTSERVICE = '/payment';
const RESOURCE_PAGE_INITPAYMENY_ROUTE_CARTSERVICE = '/initialize';

/**
 * initPayment
 * url - /checkout/{apiversion}/cart/payment/{cartId}/initialize?brand={brandId}
 *
 * @param {Object} args
 * @param {String} args.jwtToken
 * @param {String} args.cartId
 * @param {String} args.paymentMethod
 */
const initPayment = ({
    env, jwtToken, cartId, paymentMethod,
}) => {
    const envObj = env || {};

    envObj.rootUri = `${RESOURCE_ROOT_CARTSERVICE}${RESOURCE_VERSION_CARTSERVICE}`;

    const resourcePage =  `${RESOURCE_PAGE_CARTSERVICE}${RESOURCE_PAGE_PAYMENT_ROUTE_CARTSERVICE}/${cartId}${RESOURCE_PAGE_INITPAYMENY_ROUTE_CARTSERVICE}`;

    const payload = {
        paymentMethod,
    };

    return restClient.postRequest(envObj, resourcePage, jwtToken, payload)
        .then((response) => response);
};

export default initPayment;
