/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// Auth
export const SET_ACCESS_TOKEN = 'MEMBER/AUTH/SET_ACCESS_TOKEN';
export const TRIGGER_INTERSTITIAL_LOGIN = 'MEMBER/AUTH/TRIGGER_INTERSTITIAL_LOGIN';
export const LOG_LAST_VISITED_PAGE = 'MEMBER/AUTH/LOG_LAST_VISITED_PAGE';
export const LOG_USER_LOGIN_SESSION_READY = 'MEMBER/AUTH/LOGIN_SESSION_READY';
export const CLEAR_AUTH_DEPENDENT_STATE = 'MEMBER/AUTH/CLEAR_AUTH_DEPENDENT_STATE';

// Profile
export const SET_PROFILE_EMAIL_ID = 'mbp-member/SET_PROFILE_EMAIL_ID';
export const LOADED_PROFILE_USER_ROLE = 'mbp-member/LOADED_PROFILE_USER_ROLE';
export const SET_PROFILE_USER_ROLE = 'mbp-member/SET_PROFILE_USER_ROLE';

// Address Book
export const ADD_ADDRESS_BOOK = 'mbp-member/ADD_ADDRESS_BOOK';
export const EDIT_ADDRESS_BOOK = 'mbp-member/EDIT_ADDRESS_BOOK';
export const LOAD_ADDRESS_BOOK = 'mbp-member/LOAD_ADDRESS_BOOK';
export const REMOVE_ADDRESS_BOOK = 'mbp-member/REMOVE_ADDRESS_BOOK';
export const SAVED_GUEST_ADDRESS_BOOK = 'mbp-member/SAVED_GUEST_ADDRESS_BOOK';
export const PROCESS_ADDRESS_BOOK_COMPLETED = 'mbp-member/PROCESS_ADDRESS_BOOK_COMPLETED';

// Shop runner
export const LOAD_SHOPRUNNER = 'mbp-member/LOAD_SHOPRUNNER';

// Wallet
export const LOAD_WALLET = 'mbp-member/LOAD_WALLET';
export const ADD_CREDIT_CARD_TO_WALLET = 'mbp-member/ADD_CREDIT_CARD_TO_WALLET';
export const SET_CARD_SET_SAVED_FLAG = 'mbp-member/SET_CARD_SET_SAVED_FLAG';
