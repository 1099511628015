/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    takeEvery, call, fork, select, put,
} from 'redux-saga/effects';
import personClient from 'wc-api-person';
import mbpLogger from 'mbp-logger';
import mbpUtil from 'mbp-api-util';
import * as walletActions from './Wallet-Actions';
import * as walletActionTypes from './Wallet-ActionTypes';
import { commonSelectors } from '../Common';
import { authOperations } from '../Auth';
import { getIsWriteScopeCheckout } from '../../../Checkout/ducks/Global/Global-Selectors';

const { checkJWT } = authOperations.workers;

// Source Id is used to tokenize creditcard
// It is a required field - if its not passed in the request - tokenization will fail
const SOURCE_ID = 'W0066';

function* workerLoadWallet() {
    try {
        const contactId = yield select(commonSelectors.getContactId);
        yield put(walletActions.clearCreditCardError());
        yield put(walletActions.saveCreditCardFlag(false));
        const isWriteScopeCheckout = yield select(getIsWriteScopeCheckout);
        if (isWriteScopeCheckout) {
            const accessToken = yield call(checkJWT);
            const wcEnv = {
                host: mbpUtil.getEnv('APP_WC_HOST'),
            };
            const response = yield call(personClient.getWallet, wcEnv, contactId, accessToken);
            if (response.data && response.data.result) {
                const { wallet } = response.data.result;
                yield put(walletActions.saveWallet(wallet));
            }
        }
        yield put(walletActions.savedWallet());
    } catch (ex) {
        const { response } = ex;
        if (
            response
            && response.data
            && response.data.statusCode
            && response.data.statusCode.toString() !== '422'
        ) {
            mbpLogger.logError({
                function: 'workerLoadWallet',
                appName: process.env.npm_package_name,
                module: 'Wallet-Operations.js',
                jsError: ex,
                message: 'workerLoadWallet - Load Wallet Failed',
            });
        }
    }
}

// Get Tokenized card value
function* tokenizeCard(card) {
    let tokenizeCardValue = '';

    try {
        const accessToken = yield call(checkJWT);
        const wcEnv = {
            host: mbpUtil.getEnv('APP_WC_HOST'),
        };

        const response = yield call(personClient.tokenizeCard, wcEnv, accessToken, card, SOURCE_ID);
        tokenizeCardValue = response?.data?.cyptoccNumber;

        // tokenize value return as ERROR - invalid card number
        if (!tokenizeCardValue || tokenizeCardValue === 'ERROR') {
            tokenizeCardValue = '';

            yield put(walletActions.addCreditCardError('Invalid card number'));
            mbpLogger.logError({
                function: 'tokenizeCard',
                appName: process.env.npm_package_name,
                module: 'Wallet-Operations.js',
                message: 'Invalid card number',
            });
        }
    } catch (ex) {
        yield put(walletActions.addCreditCardError('Sorry, The credit card could not be saved'));
        mbpLogger.logError({
            function: 'tokenizeCard',
            appName: process.env.npm_package_name,
            module: 'Wallet-Operations.js',
            jsError: ex,
            message: 'Tokenize wallet card failed',
        });
    }

    return tokenizeCardValue;
}

const removeMaskForAccountNumber = (number) => {
    const strAccountNumber = number.toString();
    if (strAccountNumber.indexOf('-') >= 0) {
        return strAccountNumber.split('-').join('');
    }
    return strAccountNumber;
};

function* workerAddCardToWallet(action) {
    try {
        mbpLogger.logDebug({
            function: 'workerAddCardToWallet',
            appName: process.env.npm_package_name,
            module: 'Wallet-Operations.js',
            message: 'Proceed to Add card to wallet',
        });

        const { data } = action;
        let cardNumber = data?.accountNumber || '';
        cardNumber = removeMaskForAccountNumber(cardNumber);

        /* Tokenize card
           In order to stay in complaince with security, creditcard should be tokenized
           In the below code - cardnumber will be converted into a tokenized value. */
        const tokizeCardNumber = yield call(tokenizeCard, cardNumber);

        if (tokizeCardNumber) {
            // prepare to add card to wallet
            const contactId = yield select(commonSelectors.getContactId);
            const accessToken = yield call(checkJWT);
            const wcEnv = {
                host: mbpUtil.getEnv('APP_WC_HOST'),
            };
            const {
                nameOnCreditCard, cardType, expirationYear, expirationMonth,
            } = data;
            const firstTwoDigits = cardNumber.substring(0, 2);
            const accountNumberLength = cardNumber.length;
            const lastFourDigits = cardNumber.substring((accountNumberLength - 4), accountNumberLength);
            let mapExpirationYear = expirationYear;

            if (expirationYear.length === 4) {
                mapExpirationYear = expirationYear.substring(2, 4);
            }

            const expiryDate = `${expirationMonth}/${mapExpirationYear}`;

            const payload = {
                accountNumber: tokizeCardNumber,
                nameOnCreditCard,
                expirationMonth,
                expirationYear,
                cardType,
                firstTwoDigits,
                lastFourDigits,
                expiryDate,
                sourceID: SOURCE_ID,
            };

            // Add Card
            const response = yield call(personClient.addCardToWallet, wcEnv, accessToken, contactId, payload);

            // Card Added - Get All Cards in Wallet
            if (response?.data?.result?.wallet?.walletId) {
                yield put(walletActions.loadWallet());
            } else {
                // log error
                yield put(walletActions.addCreditCardError('Sorry, The credit card could not be saved'));
                mbpLogger.logError({
                    function: 'saveCreditCard',
                    appName: process.env.npm_package_name,
                    module: 'Wallet-Operations.js',
                    message: 'Currently Not Able to save credit Card',
                });
            }
        }
    } catch (ex) {
        // log error
        if (ex?.response?.data?.error?.errorDesc) {
            const msgError = ex.response.data.error.errorDesc;
            if (msgError.indexOf('WalletID cannot have duplicates') >= 0) {
                // log error
                yield put(walletActions.addCreditCardError('Sorry, this card is already saved in your wallet.'));
            } else {
                // log error
                yield put(walletActions.addCreditCardError('Sorry, The credit card could not be saved'));
            }
        } else {
            // log error
            yield put(walletActions.addCreditCardError('Sorry, The credit card could not be saved'));
        }

        mbpLogger.logError({
            function: 'saveCreditCard',
            appName: process.env.npm_package_name,
            module: 'Wallet-Operations.js',
            jsError: ex,
            message: 'Currently Not Able to save credit Card',
        });
    }
}

function* watcherLoadWallet() {
    yield takeEvery(walletActionTypes.LOAD_WALLET, workerLoadWallet);
}

function* watcherAddCardToWallet() {
    yield takeEvery(walletActionTypes.ADD_CREDIT_CARD_TO_WALLET, workerAddCardToWallet);
}

const watchers = [
    fork(watcherLoadWallet),
    fork(watcherAddCardToWallet),

];

export default {
    watchers,
};
