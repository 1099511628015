/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionsTypes from './AddressSearchQAS-ActionTypes';

export const queryQasAddressSearch = (data) => ({
    type: actionsTypes.QUERY_QAS_ADDRESS_SEARCH,
    data,
});

export const loadQasAddressSearch = (data) => ({
    type: actionsTypes.LOAD_QAS_ADDRESS_SUGGESTION,
    data,
});

export const clearQasAddressSearch = () => ({
    type: actionsTypes.CLEAR_QAS_ADDRESS_SUGGESTION,
});

export const getFormatQasAddress = (data) => ({
    type: actionsTypes.GET_FORMAT_QAS_ADDRESS,
    data,
});

export const loadFomattedQasAddress = (data) => ({
    type: actionsTypes.LOAD_FORATTED_QAS_ADDRESS,
    data,
});

export const clearFomattedQasAddress = () => ({
    type: actionsTypes.CLEAR_FORATTED_QAS_ADDRESS,
});

export const flagOnShowValidatedAddressSuggestion = () => ({
    type: actionsTypes.FLAG_ON_SHOW_VALIDATED_ADDRESS_SUGGESTION,
});

export const flagOffShowValidatedAddressSuggestion = () => ({
    type: actionsTypes.FLAG_OFF_SHOW_VALIDATED_ADDRESS_SUGGESTION,
});

export const resetQasAddressState = () => ({
    type: actionsTypes.RESET_QAS_ADDRESS_STATE,
});

export const keepAddressQasVerification = (saveHandler) => ({
    type: actionsTypes.KEEP_ADDRESS_QAS_VERIFICATION,
    saveHandler,
});

export const editAddressQasVerification = () => ({
    type: actionsTypes.EDIT_ADDRESS_QAS_VERIFICATION,
});

export const selectRecommandedAddressQasVerification = (address, saveHandler) => ({
    type: actionsTypes.SELECT_ADDRESS_QAS_VERIFICATION,
    address,
    saveHandler,
});

export const proceedSaveBookAddressPwaDesktop = (data) => ({
    type: actionsTypes.SAVE_BOOK_ADDRESS_PWA_DESKTOP,
    data,
});

export const completeSaveBookAddressPwaDesktop = () => ({
    type: actionsTypes.SAVE_BOOK_ADDRESS_PWA_DESKTOP_COMPLETE,
});

export const flagOnQasFailed = () => ({
    type: actionsTypes.FLAG_ON_QAS_FAILED,
});

export const flagOnQasReset = () => ({
    type: actionsTypes.FLAG_ON_QAS_RESET,
});
