/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import GraphqlSeoFooter from '../../GraphqlHomePage/Partials/GraphqlSeoFooter/GraphqlSeoFooter';

const PassportBlockSeoFooter = ({ classes, data }) => {
    const footerData = data?.modular_blocks?.[0]?.footer_seo || {};

    return (
        <div className={classes.seoFooter}>
            <GraphqlSeoFooter data={footerData}  />
        </div>
    );
};

PassportBlockSeoFooter.propTypes = {
    data: object.isRequired,
    classes: object.isRequired,
};

export default PassportBlockSeoFooter;
