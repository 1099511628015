/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Define the action types related to MBP UI App
 */
const CGC_RESET_CARD_DATA = 'CGC_RESET_CARD_DATA';
const CGC_SET_CARD_DATA = 'CGC_SET_CARD_DATA';
const CGC_ADD_DATA_TO_API = 'CGC_ADD_DATA_TO_API';
const CGC_ADD_LABELS_DATA_TO_API = 'CGC_ADD_LABELS_DATA_TO_API';
const CGC_GET_CUSTOM_CARD_DATA = 'CGC_GET_CUSTOM_CARD_DATA';
const CGC_SET_CUSTOM_CARD_DATA = 'CGC_SET_CUSTOM_CARD_DATA';
const CGC_CARD_ERROR = 'CGC_CARD_ERROR';
export default {
    CGC_RESET_CARD_DATA,
    CGC_SET_CARD_DATA,
    CGC_ADD_DATA_TO_API,
    CGC_ADD_LABELS_DATA_TO_API,
    CGC_GET_CUSTOM_CARD_DATA,
    CGC_SET_CUSTOM_CARD_DATA,
    CGC_CARD_ERROR,
};
