/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { string } from 'prop-types';
import loadable from '@loadable/component';

import { loadBundle } from '../../helpers/mfe/mfeHelpers';

import SiteLoader from '../Checkout/containers/Common/SiteLoader/SiteLoader';

const MicroFrontendClient = ({
    clientName, host, ...rest
}) => {
    if (clientName && host) {
        const LoadableClient = loadable(async () => {
            const loadedBundle = await loadBundle(clientName, host);
            if (!loadedBundle) {
                return import(/* webpackMode: "eager" */ '../../helpers/mfe/errorResponse');
            }

            return loadedBundle;
        }, {
            fallback: <SiteLoader />,
        });

        // eslint-disable-next-line react/jsx-props-no-spreading
        return <LoadableClient {...rest} />;
    }

    if (typeof window !== 'undefined') {
        return <div>Sorry! I failed to load the requested app.</div>;
    }

    return null;
};

MicroFrontendClient.propTypes = {
    clientName: string,
    host: string,
};

MicroFrontendClient.defaultProps = {
    clientName: null,
    host: null,
};

export default MicroFrontendClient;
