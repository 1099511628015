/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// Generate uuid
/* eslint-disable */
// UUID Version-4 ("Random") Generator
// 9f3353f6-3925-495e-a1bf-99204968709e
// Ref https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid/2117523#2117523
export const uuidv4 = () => ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, (c) => {
    if (typeof window !== 'undefined' && window.crypto) {
        return (c ^ (window.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16);
    }
    return (Math.random() * 0xffffffff >> 4 & (15 >> (c / 4))).toString(16);
})
