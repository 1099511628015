/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

export const processPartialRefund = (wcEnv, jwtToken, payload, params, headers) => {
    // add savings pass endpoint to resource page var
    const resourcePath = 'retention/selfservice/order/partialCredit';

    return restClient.postFacade(wcEnv, resourcePath, jwtToken, payload, params, headers, false)
        .then((response) => response.data);
};

export default {};
