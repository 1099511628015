/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getIsHoldoutPersonalizationStatus } from '../../../../state/ducks/App/App-Selectors';
import { setHoldoutPersonalizationStatus  } from '../../../../state/ducks/App/App-Actions';
import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';

export const useHoldoutPersonalization = (ffHoldoutPersonalizationEnabled) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setHoldoutPersonalizationStatus(ffHoldoutPersonalizationEnabled));
    }, [ffHoldoutPersonalizationEnabled]);
};

export const isHoldoutPersonalizationEnabled = () => useSelector(getIsHoldoutPersonalizationStatus) || useSelector(getFeatureFlag('is-personalization-holdout-enabled'));

export default { useHoldoutPersonalization, isHoldoutPersonalizationEnabled };
