/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './Wallet-ActionTypes';
import initialState from '../../initialState';

const walletReducer = (state = initialState.wallet, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_WALLET:
            return {
                ...state,
                walletEntries: [],
            };
        case actionTypes.SAVE_WALLET:
            return {
                ...state,
                walletEntries: action.data.walletEntries,
            };
        case actionTypes.SET_CARD_ERROR:
            return {
                ...state,
                error: {
                    walletSaveCreditCard: action.data,
                },
            };
        case actionTypes.CLEAR_CARD_ERROR:
            return {
                ...state,
                error: {
                    walletSaveCreditCard: '',
                },
            };
        case actionTypes.SET_CARD_SET_SAVED_FLAG:
            return {
                ...state,
                creditCardSavedFlag: action.data,
            };
        default:
            return state;
    }
};

export default walletReducer;
