/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';

import { useExitIntent, useScrollDetection } from './utils';

export default function UserExitIntentTracker() {
    const [exitEvent, setExitEvent] = useState(false);

    const handleExitIntent = () => {
        setExitEvent(true);
    };

    const handleClose = () => {
        setExitEvent(false);
    };

    useExitIntent(handleExitIntent);
    useScrollDetection(handleExitIntent, 1000);

    return (
        <Dialog open={exitEvent} onClose={handleClose}>
            <DialogTitle>User Exit Tracker</DialogTitle>
            <DialogContent>
                <p>User is trying to exit.</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
