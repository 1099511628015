/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import ReactHTMLParser from 'react-html-parser';
import { makeStyles } from '@material-ui/core/styles';
import {
    arrayOf, shape, string,
} from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import GraphqlBreadcrumbSeoSchema from './Partials/GraphqlBreadcrumbSeoSchema';
import { transformCharacters } from '../../../../helpers/markdown/characterTransformer';
import { getPresentationFamily } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const useStyles = makeStyles((theme) => ({
    breadCrumb: {
        listStyle: 'none',
        fontSize: theme.palette?.text?.fontSize14 || '14px',
        display: 'inline-flex',
        alignItems: 'baseline',
        margin: 0,
        padding: 0,
        flexWrap: 'wrap',
        [theme.breakpoints.down(400)]: {
            textAlign: 'center',
            fontSize: theme.palette?.text?.fontSize13 || '13px',
            lineHeight: '1.5',
        },
    },
    breadCrumbListItem: {
        marginRight: '5px',
        '&:last-child': {
            color: '#757575',
            whiteSpace: 'nowrap',
        },
        '&::after': {
            content: '"»"',
            position: 'relative',
            right: '0',
        },
        '&:last-child::after': {
            content: '""',
            display: 'none',
        },
        '& a': {
            textDecoration: 'none',
            paddingRight: '5px',
            whiteSpace: 'nowrap',
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
            '&:hover, &:focus': {
                textDecoration: 'underline',
            },
        },
    },
    breadCrumbListItemFood: {
        marginRight: '5px',
        '&:last-child': {
            color: theme.palette.primary[500] || '#595959',
        },
        '&::after': {
            content: '"\u276F"',
            position: 'relative',
            right: '0',
            fontSize: '0.9em',
        },
        '&:last-child::after': {
            content: '""',
            display: 'none',
        },
        '& a': {
            textDecoration: 'underline',
            paddingRight: '5px',
            whiteSpace: 'nowrap',
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
    },
    breadCrumbFood: {
        listStyle: 'none',
        fontSize: theme.palette?.text?.fontSize14 || '14px',
        display: 'inline-flex',
        alignItems: 'baseline',
        margin: 0,
        padding: 0,
        flexWrap: 'wrap',
        [theme.breakpoints.down(600)]: {
            textAlign: 'center',
            fontSize: theme.palette?.text?.fontSize13 || '13px',
            lineHeight: '1.5',
            width: '100%',
            overflowY: 'auto',
            whiteSpace: 'nowrap',
            flexWrap: 'nowrap',
        },
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
}));

const GraphqlBreadcrumbs = ({
    breadCrumbArray, presentationFamily,
}) => {
    const classes = useStyles();
    const breadCrumbBasedFamily = presentationFamily === 'food' ? classes.breadCrumbListItemFood : classes.breadCrumbListItem;
    const mainContainerBreadCrumb = presentationFamily === 'food' ? classes.breadCrumbFood : classes.breadCrumb;

    return (
        <>
            <GraphqlBreadcrumbSeoSchema breadCrumbArray={breadCrumbArray} />
            <ol className={mainContainerBreadCrumb} aria-label="hidden" tabIndex="-1">
                { breadCrumbArray && breadCrumbArray.length > 0 ? breadCrumbArray.map((d, i) => (
                // if last element remove <Link>
                    i === breadCrumbArray?.length - 1 && d.href !== '/'
                        ? (
                            <li key={d.title} className={breadCrumbBasedFamily} aria-hidden="false">
                                {transformCharacters(ReactHTMLParser(d.title))}
                            </li>
                        )
                        : (
                            <li key={d.title} className={breadCrumbBasedFamily}>
                                <Link tabindex="-1" to={d.href} aria-label={d.title}>
                                    {transformCharacters(ReactHTMLParser(d.title))}
                                </Link>
                            </li>
                        )
                )) : (
                // default if no breadcrumb data
                    <li className={breadCrumbBasedFamily}>
                        <Link to="/">
                            <span role="img" aria-label="Home icon">🏠</span> Home
                        </Link>
                    </li>
                ) }
            </ol>
        </>
    );
};

GraphqlBreadcrumbs.propTypes = {
    breadCrumbArray: arrayOf(
        shape({
            title: string.isRequired,
            href: string.isRequired,
        }),
    ).isRequired,
    presentationFamily: string.isRequired,
};

const mapStateToProps = (state) => ({
    presentationFamily: getPresentationFamily(state),
});

const enhance = compose(
    connect(mapStateToProps),
);

export default enhance(GraphqlBreadcrumbs);
