/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';
import { GRAPHQL_ENV } from '..';

/**
 * @param {string} brand - baskets or berries
 */

const findPassportContent = (brand) => (
    gql`query PassportQuery {
            findContent(brand: "${brand}", environment: "${GRAPHQL_ENV}", 
                contentType:"passport" 
                includeRefs:[
                    "passport_pricing_conditional_logic.at_checkout.passport_referenced_sku",
                    "passport_pricing_conditional_logic.utm_params.passport_referenced_sku",
                    "passport_messaging.user_with_expired_passport_subscription.passport_referenced_sku",
                    "passport_messaging.user_whose_passport_subscription_is_about_to_expire.passport_referenced_sku",
                    "passport_messaging.user_who_never_bought_passport_subscription.passport_referenced_sku"
                    "passport_messaging.active_passport_subscription_signed_in.passport_referenced_sku"
                    "passport_messaging.active_passport_subscription_signed_out.passport_referenced_sku"
                    "myaccount_passport.renew_button_passport_price.passport_referenced_sku",
                    "myaccount_passport.renew_early_button_passport_price.passport_referenced_sku",
                    "ab_testing.control.passport_referenced_sku",
                    "ab_testing.variant.passport_referenced_sku",
                ]) {
                    content
        }
    }
`
);

export default findPassportContent;
