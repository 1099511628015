/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export class MemCache {
    constructor({ storeInterface }) {
        this.store = storeInterface;
    }

   set = (key, value) => {
       this.store.set(key, value);
       return true;
   }

   get = (key) => {
       if (this.store.has(key)) {
           return this.store.get(key);
       }

       return null;
   }
}

const memCache = new MemCache({
    storeInterface: new Map(),
});

export default memCache;
