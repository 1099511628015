/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const ADD_RECIPIENT = 'ADD_RECIPIENT';
const UPDATE_RECIPIENTS = 'UPDATE_RECIPIENTS';
const CHANGE_WORKING_RECIPIENT = 'CHANGE_WORKING_RECIPIENT';
const UPDATE_WORKING_RECIPIENT = 'UPDATE_WORKING_RECIPIENT';
const SET_WORKING_RECIPIENT = 'SET_WORKING_RECIPIENT';
const LOAD_ORDERITEM_DETAILS = 'LOAD_ORDERITEM_DETAILS';
const ENTERPRISE_SAVED_RECIPIENT_GIFT_MESSAGE = 'MFE/MBP-CHECKOUT/ENTERPRISE_SAVED_RECIPIENT_GIFT_MESSAGE';

export {
    ADD_RECIPIENT,
    UPDATE_RECIPIENTS,
    CHANGE_WORKING_RECIPIENT,
    UPDATE_WORKING_RECIPIENT,
    SET_WORKING_RECIPIENT,
    LOAD_ORDERITEM_DETAILS,
    ENTERPRISE_SAVED_RECIPIENT_GIFT_MESSAGE,
};
