/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { duck as tmDuck } from '../ducks/TagManager';
import * as app from '../ducks/App';
import member from '../ducks/Member/reducers';
import account from './Account-Reducers/Account-Reducers';
import addToCart from '../ducks/AddToCart';
import passport from '../ducks/Passport';
import cart from '../ducks/Cart';
import order from '../ducks/Order';
import common from '../ducks/Common';
import payment from '../ducks/Payment';
import recipient from '../ducks/Recipient';
import checkout from '../ducks/Checkout';
import ui from './UI-Reducer/UI-Reducer';
import page from './Page-Reducers/Page-Reducers';
import sci from './SCI-Reducers/SCI-Reducers';
// Commented out above code and created reducer for page (Page-Reducer) for handling page state into test cases.

export default {
    addToCart,
    passport,
    app: app.default,
    tagManager: tmDuck.default,
    page,
    member,
    ui,
    cart,
    order,
    common,
    payment,
    recipient,
    checkout,
    account,
    sci,
};
