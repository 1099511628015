/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as shopRunnerActions from './Shoprunner-Actions';
import * as shopRunnerOperations from './Shoprunner-Operations';
import shopRunnerReducer from './Shoprunner-Reducers';

const shopRunnerWatcherSagas = [
    ...shopRunnerOperations.watchers,
];

export {
    shopRunnerActions,
    shopRunnerOperations,
    shopRunnerWatcherSagas,
};
export default shopRunnerReducer;
