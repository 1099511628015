/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpLogger from 'mbp-logger';

const playAudio = (audio) => {
    try {
        audio.play();
    } catch (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            function: 'audioHelper.playAudio',
            message: `audio autoplay error: ${error}`,
            component: 'audioHelper.js',
        });
    }
};

/**
 * Simulates a user interaction to enable audio autoplay. By default,
 * some browsers block all media with sound from playing automatically
 * until the user has interacted with the page somehow.
*/
const unlockAudio = () => {
    try {
        const audio = new Audio();
        playAudio(audio);

        window.removeEventListener('click', unlockAudio);
        window.removeEventListener('touchstart', unlockAudio);
    } catch (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            function: 'audioHelper.unlockAudio',
            message: `audio autoplay error: ${error}`,
            component: 'audioHelper.js',
        });
    }
};

export { unlockAudio, playAudio };
