/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/**
 * Checkout APIs
 */

import getOrderDetails from './getOrderDetails';
import getViewTime from './getViewTime';
import deleteViewTime from './deleteViewTime';
import getPaymentDetails from './getPaymentDetails';
import applyPromotion from './applyPromotion';
import removePromotion from './removePromotion';
import placeOrder from './placeOrder';
import applyGiftCard from './applyGiftCard';
import addRecipient from './addRecipient';
import calculateShipping from './calculateShipping';
import removeOrderItem from './removeOrderItem';
import updateCart from './updateCart';
import duplicateCart from './duplicateCart';
import getCartCount from './getCartCount';
import getDeliveryCalendar from './getDeliveryCalendar';
import orderPrepare from './orderPrepare';
import updateBillingAddress from './updateBillingAddress';
import removeGiftCard from './removeGiftCard';
import processChasePay from './processChasePay';
import processVisaCheckout from './processVisaCheckout';
import validateOrder from './validateOrder';
import getChasePayCheckoutSession from './getChasePayCheckoutSession';
import getQASAddress from './getQASAddress';
import verifyWine, { verifyWineAndAvailability } from './verifyWine';
import getCityStateFromZip from './getCityStateFromZip';
import getPayPalClientToken from './getPayPalClientToken';
import createOptInEntry from './createOptInEntry';
import addAddonItemsToCart from './addAddonItemsToCart';
import addWrapupsToCart from './addWrapupsToCart';
import rewardElegilibity from './rewardElegilibity';
import rewardBalance from './rewardBalance';
import getGreetingAvailability from './getGreetingAvailability';
import {
    processCgcGreetingCard, processCGCGreetingCardAPI, generateCgcCard, fetchCGCCardData,
    generateCgcLabel, fetchCGCLabeldData, fetchCGCCustomCardData, getCgcCustomCard,
} from './fetchCGCData';
import addToCart from './addToCart';
import getGCIKey from './getGCIKey';
import putPersonalizationResponse from './putPersonalizationResponse';
import getPmallShippingMethods from './getPmallShippingMethods';
import updateUserBilling from './updateUserBilling';

import {
    validateMerchantApplePay,
    savePayMethodApplePay,
    saveShipMethodApplePay,
    authorizeApplePay,
    saveRecipientAddressApplePay,
    cancelApplePay,
    placeOrderApplePay,
} from './applePay';

const orderClient = {
    getPmallShippingMethods,
    getOrderDetails,
    getViewTime,
    deleteViewTime,
    getPaymentDetails,
    getCartCount,
    addToCart,
    addRecipient,
    removeOrderItem,
    duplicateCart,
    updateCart,
    calculateShipping,
    applyPromotion,
    removePromotion,
    getDeliveryCalendar,
    updateBillingAddress,
    applyGiftCard,
    removeGiftCard,
    getChasePayCheckoutSession,
    processChasePay,
    processVisaCheckout,
    orderPrepare,
    placeOrder,
    validateOrder,
    verifyWine,
    validateMerchantApplePay,
    savePayMethodApplePay,
    saveShipMethodApplePay,
    authorizeApplePay,
    saveRecipientAddressApplePay,
    cancelApplePay,
    placeOrderApplePay,
    getCityStateFromZip,
    getPayPalClientToken,
    getQASAddress,
    createOptInEntry,
    addAddonItemsToCart,
    addWrapupsToCart,
    rewardElegilibity,
    rewardBalance,
    getGreetingAvailability,
    processCgcGreetingCard,
    processCGCGreetingCardAPI,
    getGCIKey,
    generateCgcCard,
    fetchCGCCardData,
    generateCgcLabel,
    fetchCGCLabeldData,
    fetchCGCCustomCardData,
    getCgcCustomCard,
    putPersonalizationResponse,
    updateUserBilling,
    verifyWineAndAvailability,
};

export default orderClient;
