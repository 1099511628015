/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpLogger from 'mbp-logger';

// Helper to find DataLayer entries from array
export const getDataLayerActionEntry = (action, dataLayer) => {
    try {
        if (action) {
            const found = dataLayer.filter((entry) => entry.action === action);

            return (found) ? found[0] : null;
        }
    } catch (ex) {
        mbpLogger.logError({
            action,
            dataLayer,
            function: 'getDataLayerActionEntry',
            appName: process.env.npm_package_name,
            module: 'tag-manager',
            jsError: ex,
            message: 'Failed to get data layer action entry',
        });
    }

    return null;
};

export default {};
