/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import parser from 'ua-parser-js';
import mbpLogger from 'mbp-logger';

/*
    @intention discoverDeviceType - determines device based on parsed UA, should add device detection logic to here
    @intention isDesktopMobileTablet - reusable helper that handles deployed and local environments, takes a parsed or unparsed UA

    IMPORTANT NOTES:
        ~ Mobile
        ~ Desktop
        ~ Tablets
            ** tablet is not a accepted viewport for GQL queries currently. Its behavior is overriden at the query level Graphql Homepage and Header as examples
            - sometimes come through without device.type, in this case it is undefined and they fall through into the browser logic which returns dekstop e.g. FireFox on Android
            - for Ipads using Safari, the device.type is tablet but the browser.name contains mobile, so the device logic has to be above browser
        ~ Bots
            ** some bots come through without any information to determine device, we need to fallback to desktop for flowers only
*/

/**
 *
 * @param {object} parsedUserAgent // userAgent object after ua-parser
 * @param {string} hostname // optional param to determine device fallback for bots without UA strings
 * @returns {string} "desktop" || "mobile" || "tablet" -- default: "mobile"
 */
const discoverDeviceType = (parsedUserAgent, hostname) => {
    const { device = '', browser = '' } = parsedUserAgent; // more are available from parsedUA (cpu, os, etc)

    // mobile checks
    if (device && device.type === 'mobile') {
        mbpLogger.logDebug({
            message: 'deviceType set to mobile by isDesktopMobileTablet [ Mobile Check ]',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            parsedUserAgent,
        });
        return 'mobile';
    }
    // tablet checks
    if (device && device.type === 'tablet') {
        mbpLogger.logDebug({
            message: 'deviceType set to tablet by isDesktopMobileTablet [ Tablet Check ]',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            parsedUserAgent,
        });
        return 'tablet';
    }

    // desktop checks
    // can add array of browser strings to check against (forcing IE to always desktop)
    if (browser && !browser?.name?.toLowerCase()?.includes('mobile') && browser.name !== undefined) { // when mocking emulator mobile from desktop, UA still has browser info, need to exclude mobile
        mbpLogger.logDebug({
            message: 'deviceType set to desktop by isDesktopMobileTablet [ Desktop Check]',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            parsedUserAgent,
        });
        return 'desktop';
    }

    // desktop mobile emulator
    if (browser?.name?.toLowerCase()?.includes('mobile') && device.type === 'mobile') {
        mbpLogger.logDebug({
            message: 'deviceType set to mobile by isDesktopMobileTablet [ Desktop Mobile Emulator Check]',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            parsedUserAgent,
        });
        return 'mobile';
    }

    // bots
    if (browser.name === undefined && device.type === undefined) {
        mbpLogger.logDebug({
            message: 'deviceType set to desktop by isDesktopMobileTablet [ 18f Bot Check ]',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            parsedUserAgent,
        });
        if (hostname?.includes('1800flowers')) {
            return 'desktop';
        }
    }

    mbpLogger.logDebug({
        message: 'deviceType set to mobile by isDesktopMobileTablet [ Default ]',
        appName: process.env.npm_package_name,
        module: 'mbp-pwa-ui',
        parsedUserAgent,
    });

    // mobile first (this can be changed to default and pass the determination job to <DesktopMobile />) -> queries using viewport will still need to default to 'desktop' or 'mobile' in this case
    return 'mobile';
};

/**
 *
 * @param {object || string} userAgent // parsed userAgent from initial request or unparsed userAgent
 * @param {string} hostname // optional param used for bot deviceType fallback
 */
const isDesktopMobileTablet = (userAgent, hostname = '') => {
    if (typeof userAgent === 'object') {
        return discoverDeviceType(userAgent, hostname);
    }

    // un parsed UA
    if (typeof userAgent === 'string')  {
        const parsedUA = parser(userAgent);
        return discoverDeviceType(parsedUA, hostname);
    }

    // localhost:3000 will not have a UA from SSR
    if (!userAgent) {
        if (typeof navigator === 'undefined' || !navigator || !navigator.userAgent) return 'mobile';

        const localDeviceType = parser(navigator.userAgent);

        return discoverDeviceType(localDeviceType, hostname);
    }

    return mbpLogger.logError({
        appName: process.env.npm_package_name,
        component: 'isDesktopMobileTablet.js',
        message: `Unhandled input given to isDesktopMobileTablet ${userAgent}`,
    });
};

export default isDesktopMobileTablet;
