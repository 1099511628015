/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const CONFIG_LOAD_FLAG = 'mbp-operations/App/Config/LOAD/FLAG';
const CONFIG_LOAD = 'mbp-operations/App/Config/LOAD';
const CONFIG_LOADED = 'mbp-operations/App/Config/LOADED';
const CONFIG_LOAD_FAILED = 'mbp-operations/App/Config/LOAD/FAILED';

export default {
    CONFIG_LOAD_FLAG,
    CONFIG_LOAD,
    CONFIG_LOADED,
    CONFIG_LOAD_FAILED,
};
