/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as profileActionTypes from './Profile-ActionTypes';
import initialState from '../../initialState';

const profileReducer = (state = initialState.profile, action) => {
    switch (action.type) {
        case profileActionTypes.CLEAR_PROFILE_DETAILS:
            return {
                ...state,
                firstName: '',
                lastName: '',
                userRole: '',
                contactId: '',
                passportFlag: '',
                passportStartDate: '',
                passportEndDate: '',
                email: '',
            };
        case profileActionTypes.SET_PROFILE_DETAILS:
            return {
                ...state,
                firstName: action.data.firstName || '',
                lastName: action.data.lastName || '',
                userRole: action.data.userRole || '',
                contactId: action.data.contactId || '',
                email: action.data.email || '',
                loginsCount: action.data.loginsCount || '',
                loyaltyStatus: action.data.loyaltyStatus || '',
                passportFlag: action.data.passportFlag || '',
                passportStartDate: action.data.passportStartDate || '',
                passportEndDate: action.data.passportEndDate || '',
            };
        case profileActionTypes.SET_PROFILE_EMAIL_ID:
            return {
                ...state,
                email: action.data,
            };
        case profileActionTypes.SET_PROFILE_USER_ROLE:
            return {
                ...state,
                userRole: action.data,
            };
        case profileActionTypes.SET_PROFILE_AUTO_RENEW_STATUS:
            return {
                ...state,
                autoRenewStatus: action.data,
            };
        case profileActionTypes.SET_MEMBERSHIP_TYPE:
            return {
                ...state,
                membershipType: action.membershipType,
                membershipTypeLoading: false,
            };
        case profileActionTypes.SET_MEMBERSHIP_TYPE_LOADING:
            return {
                ...state,
                membershipTypeLoading: true,
            };
        default:
            return state;
    }
};

export default profileReducer;
