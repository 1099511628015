/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
    object, bool,
} from 'prop-types';
import { Redirect, withRouter } from 'react-router';
import { connect, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import memberDucks from '../../../state/ducks/Member/ducks';
import { useAuth } from '../../../state/ducks/Member/ducks/Auth/Plugin/auth';
import { setPageTitle } from '../../../state/ducks/Member/ducks/Auth/Plugin/auth/helpers';
import { getUserEmailId, getUserType } from '../../../state/ducks/Member/ducks/Common/Common-Selectors';

const styles = {
    loaderIcon: {
        margin: '20% auto',
        width: '66px',
        height: '66px',
        backgroundImage: 'url(https://cdn1.cheryls.com/wcsstore/CherylAndCompany/images/ajax-loader-large.gif)',
    },
    PageBackGround: {
        zIndex: '3500',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        position: 'fixed',
        touchAction: 'none',
    },
};

const {
    common: {
        commonSelectors,
    },
} = memberDucks;

const LoginLoader = (props) => {
    const { classes } = props;
    return (
        <div className={classes.PageBackGround}>
            <div className={classes.loaderIcon} />
        </div>
    );
};

LoginLoader.propTypes = {
    classes: object.isRequired,
};

const AuthRoutes = ({
    isAuthenticated,
    classes,
}) => {
    const { logout } = useAuth();
    const emailId = useSelector(getUserEmailId);
    const userType = useSelector(getUserType);

    return (
        <Switch>
            <Route
                exact
                path="/auth/login"
                render={() => {
                    setPageTitle('Redirecting to Sign in page');

                    return <LoginLoader classes={classes} />;
                }}
            />
            <Route
                exact
                path="/auth/create"
                render={() => {
                    setPageTitle('Redirecting to create account page');

                    return <LoginLoader classes={classes} />;
                }}
            />
            <Route
                exact
                path="/auth/callback"
                render={() => {
                    setPageTitle('Signing in');

                    return <LoginLoader classes={classes} />;
                }}
            />
            <Route
                exact
                path="/auth/logout"
                render={() => {
                    setPageTitle('Logout');

                    if (isAuthenticated) {
                        logout();
                    } else if (userType === 'G') {
                        return <Redirect to="/" />;
                    }

                    return <LoginLoader classes={classes} />;
                }}
            />
            <Route
                path="/auth/callbackguest"
                render={(props) => {
                    setPageTitle('Signing in as guest');

                    if (isAuthenticated || emailId) {
                        props.history.push('/');
                    }

                    return <LoginLoader classes={classes} />;
                }}
            />
        </Switch>
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: commonSelectors.getIsAuthenticatedStatus(state),
});

AuthRoutes.propTypes = {
    history: object.isRequired,
    isAuthenticated: bool.isRequired,
    classes: object.isRequired,
};

export default typeof window !== 'undefined'
    ? withRouter(connect(mapStateToProps)(withStyles(styles)(AuthRoutes)))
    : () => <div />;
