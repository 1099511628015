/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const getSCICampaign = (state) => state.sci.sci_campaign_id;
export const getSCILocNumber = (state) => state.sci.sci_loc_number;
export const getSCIDispostionType = (state) => state.sci.sci_disposition_type;
export const getSCILoadingState = (state) => state.sci.sciIsLoading;
export const getSCIDecedentData = (state) => state.sci.sci_decedent_data;

export default {};
