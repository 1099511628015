/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import ReactHtmlParser from 'react-html-parser';

const replaceSpecialChar = (entry, isHtmlParser = false) => {
    let value = entry;
    if (typeof value === 'string')  {
        if (isHtmlParser) {
            value = ReactHtmlParser(entry.replace(/"([^"]*?)"/ig, '&ldquo;$1&rdquo;').replace(/'\b/g, '&rsquo;'));
        } else {
            value = entry.replace(/"([^"]*?)"/ig, '&ldquo;$1&rdquo;').replace(/'\b/g, '&rsquo;');
        }
    }
    return value;
};

export default replaceSpecialChar;
