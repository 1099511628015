/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
/* eslint-disable */
export const genericProductPaginationContainer = () => ({
    keyArgs: ['id', 'term', 'zipCode', 'locationType', 'pageSize', 'page', 'productOptions', ['facets','priceRangeFilter']],
    merge(existing, incoming, { args: { productOptions: { page } = {} } }) {
        if (!existing || page === 1) {
            // if page is one discard the previously cached data for the query so the new data is not appended to stale data
            return incoming;
        }
        // added additional params to update existing cache;
        // totalProducts -> for guided nav total count may get change on pagination
        let additionalParams = {};
        if(typeof incoming?.totalProducts !== undefined) additionalParams['totalProducts'] = incoming?.totalProducts;
        return {
            ...existing,
            products: [
                ...existing.products,
                ...incoming.products,
            ],
            ...additionalParams,
            /* eslint-disable-next-line no-underscore-dangle */
            __typename: incoming?.__typename,
        };
    },
});

export default {};
