/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, call, takeEvery, put, select,
} from 'redux-saga/effects';

import mbpLogger from 'mbp-logger';
import { duck as tmDuck } from '../../../TagManager';
import memberDucks from '../../../Member/ducks';
import * as commonSelectors from '../../../Common/Common-Selectors';
import { setWorkingRecipient } from '../../../Recipient/Recipient-Actions';
import { enterpriseAddGiftMessage } from '../../../Recipient/ducks/GiftMessageForm/GiftMessageForm-Operations';
import { showLoadingMessageActions } from '../../../Common/ducks/ShowLoadingMessage';
import { errorActions, errorOperations }  from '../../../Common/ducks/Error';
import { retrieveCart } from '../../Cart-Operations';
import {
    deleteItem, deleteItemFood, enterpriseDeleteItem, deleteMiniCartItem,
} from './DeleteItem-Actions';
import { checkoutOperations } from '../../../Checkout/ducks/Checkout';
import { getPassportBundleItemCurrentlyInCart, getProductRetailPrice } from '../../../App/App-Selectors';
import { passportBundleItemRemovedCart, setProductRetailPrice } from '../../../App/App-Actions';
import { loadCardIsleAvailable } from '../../../Recipient/ducks/CardIsle/CardIsle-Operations';
import { callHandleCartFailure } from '../../Cart-Actions';
import orderClient from '../../../../../apis/checkout-apis/orderClient';
import { trackCartUpdate } from '../../../TagManager/ducks/TagManager/TagManager-Helpers';
import { getFeatureFlags } from '../../../Checkout/ducks/App/App-Selectors';

const { prepareRetreiveCart, checkoutRouteHandler } = checkoutOperations;

const {
    auth: {
        authOperations,
    },
    profile: {
        profileActions: {
            setProfileUserRole,
        },
    },
} = memberDucks;

const { checkJWT } = authOperations.workers;

const removeItem = ({
    wcEnv,
    jwtToken,
    orderItemId,
    orderId,
}) => orderClient.removeOrderItem(wcEnv, jwtToken, orderItemId, orderId);

function* onDeleteItem(action) {
    try {
        const {
            orderItemId, isPassport, typeOrderItem, positionItem, history, skuCode, parentSku,
        } = action.data;
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        const orderId = yield select(commonSelectors.getOrderId);
        const jwtToken = yield call(checkJWT);
        const configObj = {
            wcEnv: {},
            jwtToken,
            orderItemId,
            orderId,
        };

        const recipientEditingIndex = yield select(commonSelectors.getWorkingRecipient);
        const orderItemCount = yield select(commonSelectors.getNoOfOrderItems);

        yield call(removeItem, configObj);

        // clear error
        yield put(errorActions.clearErrors({
            errType: 'cart',
            field: 'error',
        }));

        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: orderItemId,
        }));

        yield put(errorActions.clearErrors({
            errType: 'error',
            field: '',
        }));

        yield put(errorActions.clearErrors({
            errType: 'recipientFormClearAllFields',
            field: '',
        }));

        const isPassportItemInCart = yield select(getPassportBundleItemCurrentlyInCart);
        if (isPassportItemInCart) {
            yield put(passportBundleItemRemovedCart(skuCode));
        }

        const bundleData = yield select(commonSelectors.getPassportBundleData);
        const passportData = yield select(commonSelectors.getPassportItem);

        const parentSkuCart = passportData?.[0]?.cartItems?.[0]?.product?.parentProductCode || null;
        const bundleSkuData = bundleData?.productSku || null;
        if (
            parentSku // Dont delete this if all the elements are undefined is trying delete twice.
            && bundleSkuData // Dont delete this if all the elements are undefined is trying delete twice.
            && parentSkuCart  // Dont delete this if all the elements are undefined is trying delete twice.
            && parentSku === bundleSkuData
            && parentSkuCart === bundleData?.passportSku
        ) {
            const passportOrderId = passportData?.[0]?.cartItems?.[0].cartItemId;
            const passConfigObj = {
                wcEnv: {},
                jwtToken,
                orderItemId: passportOrderId,
                orderId,
            };

            yield call(removeItem, passConfigObj);
        }

        // To remove retail price which has been removed from cart
        // TODO: remove this code once we will get retail price and sale price form cart api for checkout flow
        const listOfRetailPrices = yield select(getProductRetailPrice);
        if (listOfRetailPrices) {
            const modifiedPriceList = listOfRetailPrices.filter((item) => !(Object.keys(item)).includes(orderItemId));
            yield put(setProductRetailPrice(modifiedPriceList));
        }

        // Tealium Tracking Start
        const eventAction = 'Remove from cart';
        let removeItemTracker = {};
        if (isPassport) {
            removeItemTracker = {
                eventCategory: 'Checkout',
                eventAction,
                eventLabel: 'Passport',
            };
            yield put(setProfileUserRole(''));
        } else {
            removeItemTracker = {
                eventCategory: 'Checkout',
                eventAction,
                eventLabel: `${skuCode}|<<pageType>>`,
            };
        }
        yield put(tmDuck.actions.trackEvent(removeItemTracker));
        // Tealium Tracking Ends

        yield call(prepareRetreiveCart, typeOrderItem);
        yield call(retrieveCart);
        yield call(checkoutRouteHandler, history);
        const flagState = yield select(getFeatureFlags);

        if (typeof window !== 'undefined' && window.location.href.indexOf('/shipping/') > 0) {
            const cIEnabled = flagState['is-card-isle-enabled'] || false;

            if (cIEnabled) {
                // Review the product has card isle available.
                yield call(loadCardIsleAvailable);
            }

            const totalItems = String(orderItemCount - 1);
            if (positionItem === totalItems || positionItem < recipientEditingIndex) {
                const newIndexEdit = recipientEditingIndex > 0 ? recipientEditingIndex - 1 : recipientEditingIndex;
                yield put(setWorkingRecipient((newIndexEdit)));
            }
        }

        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());

        // Retrive current cart
        // Send cart snapshot to cart track event
        const fdOrder = yield select(commonSelectors.getCartOrder);
        trackCartUpdate(flagState, 'EventRemoveFromCart', fdOrder);
    } catch (ex) {
        mbpLogger.logError({
            orderItem: action.data,
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'delete item',
            jsError: ex,
            message: 'delete item Failed',
        });
        yield put(callHandleCartFailure(ex));
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'cart');
    }
}

function* watchDeleteItem() {
    yield takeEvery(deleteItem().type, onDeleteItem);
}

function* workerHandleDeleteItem(action) {
    try {
        // Show Loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        // Prepare Delete
        const {
            orderItemId, isPassport, history, page, skuCode,
            parentSku, type, orderCartItemId,
        } = action.data;
        const jwtToken = yield call(checkJWT);
        const orderId = yield select(commonSelectors.getOrderId);
        const configObj = {
            wcEnv: {},
            jwtToken,
            orderItemId,
            orderId,
        };

        // Call Delete Item API
        yield call(removeItem, configObj);

        const bundleData = yield select(commonSelectors.getPassportBundleData);
        const passportData = yield select(commonSelectors.getPassportItem);

        if (
            parentSku // Dont delete this if all the elements are undefined is trying delete twice.
            && bundleData // Dont delete this if all the elements are undefined is trying delete twice.
            && passportData?.[0]?.cartItems?.[0].product?.parentProductCode  // Dont delete this if all the elements are undefined is trying delete twice.
            && parentSku === bundleData?.productSku
            && passportData[0].cartItems[0].product.parentProductCode === bundleData?.passportSku
            && passportData?.[0]?.cartItems?.[0].cartItemId
        ) {
            const passportOrderId = passportData?.[0]?.cartItems?.[0].cartItemId;
            const passConfigObj = {
                wcEnv: {},
                jwtToken,
                orderItemId: passportOrderId,
                orderId,
            };
            yield call(removeItem, passConfigObj);
        }

        // Clear Error
        yield put(errorActions.clearErrors({
            errType: 'cart',
            field: 'error',
        }));

        if (type === 'greeting') {
            yield call(enterpriseAddGiftMessage, {
                data: {
                    isMultiline: false,
                    giftMessage: {
                        message: 'No Card Message',
                        signature: '',
                    },
                    orderItemId: orderCartItemId,
                },
            });
        }

        // Tealium Tracking Start
        const eventAction = 'Remove from cart';
        let removeItemTracker = {};
        if (isPassport) {
            removeItemTracker = {
                eventCategory: 'Checkout',
                eventAction,
                eventLabel: 'Passport|<<pageType>>',
            };
        } else {
            removeItemTracker = {
                eventCategory: 'Checkout',
                eventAction,
                eventLabel: `${skuCode}|<<pageType>>`,
            };
        }
        yield put(tmDuck.actions.trackEvent(removeItemTracker));
        // Tealium Tracking Ends
        const cartCount = yield select(commonSelectors.getCartCount);
        if (cartCount - 1 > 0) {
            yield call(prepareRetreiveCart, page);
        }

        // Get Latest Cart
        yield call(retrieveCart);

        yield call(checkoutRouteHandler, history);

        // Hide Loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        const orderId = yield select(commonSelectors.getOrderId);
        mbpLogger.logError({
            orderId,
            orderItem: action.data,
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'delete item',
            jsError: ex,
            message: 'delete item Failed',
        });
        yield put(callHandleCartFailure(ex));
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'cart');
    }
}

function* workerHandleMiniCartDeleteItem(action) {
    try {
        // Show Loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        // Prepare Delete
        const { orderItemId, page, parentSku } = action.data;
        const jwtToken = yield call(checkJWT);
        const orderId = yield select(commonSelectors.getOrderId);
        const configObj = {
            wcEnv: {},
            jwtToken,
            orderItemId,
            orderId,
        };

        // Call Delete Item API
        yield call(removeItem, configObj);

        const bundleData = yield select(commonSelectors.getPassportBundleData);
        const passportData = yield select(commonSelectors.getPassportItem);

        if (
            parentSku // Dont delete this if all the elements are undefined is trying delete twice.
            && bundleData // Dont delete this if all the elements are undefined is trying delete twice.
            && passportData?.[0]?.cartItems?.[0].product?.parentProductCode  // Dont delete this if all the elements are undefined is trying delete twice.
            && parentSku === bundleData?.productSku
            && passportData[0].cartItems[0].product.parentProductCode === bundleData?.passportSku
            && passportData?.[0]?.cartItems?.[0].cartItemId
        ) {
            const passportOrderId = passportData?.[0]?.cartItems?.[0].cartItemId;
            const passConfigObj = {
                wcEnv: {},
                jwtToken,
                orderItemId: passportOrderId,
                orderId,
            };
            yield call(removeItem, passConfigObj);
        }

        // Clear Error
        yield put(errorActions.clearErrors({
            errType: 'mini cart',
            field: 'error',
        }));

        const cartCount = yield select(commonSelectors.getCartCount);
        if (cartCount - 1 > 0) {
            yield call(prepareRetreiveCart, page);
        }

        // Get Latest Cart
        yield call(retrieveCart);

        // Hide Loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        const orderId = yield select(commonSelectors.getOrderId);
        mbpLogger.logError({
            orderId,
            orderItem: action.data,
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'mini cart delete item',
            jsError: ex,
            message: 'mini cart delete item Failed',
        });
        yield put(callHandleCartFailure(ex));
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'cart');
    }
}

function* watchHandleMiniCartDeleteItem() {
    yield takeEvery(deleteMiniCartItem().type, workerHandleMiniCartDeleteItem);
}
function* watchHandleDeleteItem() {
    yield takeEvery(deleteItemFood().type, workerHandleDeleteItem);
}

function* watchEnterpriseDeleteItem() {
    yield takeEvery(enterpriseDeleteItem().type, workerHandleDeleteItem);
}

const watchers = [
    fork(watchDeleteItem),
    fork(watchHandleDeleteItem),
    fork(watchHandleMiniCartDeleteItem),
    fork(watchEnterpriseDeleteItem),
];

export { watchers, removeItem };

export default {};
