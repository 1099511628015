/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export default {
    app: {
        config: {},
        featureFlags: {},
    },
    payment: {
        paymentForm: {
            accountNumber: '',
            amount: '',
            brand: '',
            cvv: '',
            expirationMonth: '',
            expirationYear: '',
            nameOnCreditCard: '',
            paymentMethod: {
                id: 'CreditCard',
                name: 'Credit Card',
            },
            isFormValid: false,
            lastFourDigits: '',
            isFromWallet: false,
            savePaymentMethod: false,
        },
        rewardPoints: {
            balance: {},
            eligibility: 'F',
            selected: {
                points: '',
                amount: '',
            },
        },
        billingAddressForm: {
            firstName: '',
            lastName: '',
            address1: '',
            address2: '',
            address3: '',
            addressId: '',
            city: '',
            email: '',
            confirmEmail: '',
            country: '',
            locationType: '',
            organizationName: '',
            phone: '',
            mobilePhone: '',
            state: '',
            zipCode: '',
            cityArray: [],
            isFormValid: false,
            isFormAutoFilled: false,
            checkSameAsDeliveryAddress: false,
        },
        paymentMethods: {
            CreditCard: {
                id: 'CreditCard',
                name: 'Credit/Debit Card',
                primary: 1,
                active: 1,
                logo: [
                    'https://cdn1.1800flowers.com/wcsstore/Flowers/images/2018/mobile/visa-icon.png',
                    'https://cdn1.1800flowers.com/wcsstore/Flowers/images/2018/mobile/amex-logo.png',
                    'https://cdn1.1800flowers.com/wcsstore/Flowers/images/2018/mobile/discover-logo.png',
                    'https://cdn1.1800flowers.com/wcsstore/Flowers/images/2018/mobile/mastercard-logo.png',
                ],
                enterpriseLogo: ['//images.contentstack.io/v3/assets/blt95e0138311135214/blt55a4652be9c043dd/632087e72975ce72ea2faf4f/card-logo.png'],
            },
            PayPal: {
                id: 'PayPal',
                name: 'PayPal',
                logo: ['//cdn1.1800flowers.com/wcsstore/Flowers/images/2018/mobile/Paypal-logo.png'],
                enterpriseLogo: ['//images.contentstack.io/v3/assets/blt95e0138311135214/blt18ea45241bb20e3a/632076f927d06f32eeaf01ff/paypal-logo-2.png'],
                active: 1,
            },
            GooglePay: {
                id: 'GooglePay',
                name: 'Google Pay',
                active: 1,
                logo: ['//images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt5a7e84259ee82669/6005828227b1080f8436dcfb/GooglePay_mark_800_gray.png'],
                enterpriseLogo: ['//images.contentstack.io/v3/assets/blt95e0138311135214/blte1835c5b5e425629/63248c8004361d715f550e7d/Google_Pay_Logo.svg.png'],
            },
            ChasePay: {
                id: 'ChasePay',
                name: 'Chase Pay',
                active: 1,
                logo: ['//cdn1.1800flowers.com/wcsstore/Flowers/images/2018/mobile/chasepay_logo.png'],
            },
            Venmo: {
                id: 'Venmo',
                name: 'Venmo',
                active: 1,
                logo: [
                    '//images.contentstack.io/v3/assets/bltdd99f24e8a94d536/bltf958b3498c1dd5a8/5f981c7a83a0a3620dd3a459/png-transparent-venmo-payment-bank-pay-blue-text-trademark.png',
                ],
                enterpriseLogo: ['//images.contentstack.io/v3/assets/blt95e0138311135214/blte1205ab655488a54/63208a4a1ad88e716559d3e6/venmo-logo.png'],
            },
            ApplePay: {
                id: 'ApplePay',
                name: 'Apple Pay',
                active: 1,
                logo: ['//cdn1.1800flowers.com/wcsstore/Flowers/images/btn_apple_pay_mark.png'],
                enterpriseLogo: ['//images.contentstack.io/v3/assets/blt95e0138311135214/blt47fca650b1ddd55e/6324a3838966566ecdfbcea6/applepay-logo.png'],
            },
            VisaCheckout: {
                id: 'VisaCheckout',
                name: 'Click to Pay',
                active: 1,
                logo: ['//images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt5144bc0bcb0037ee/5f04ad87e54ba12bdf19b795/src-logo.png'],
                enterpriseLogo: ['//images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt5144bc0bcb0037ee/5f04ad87e54ba12bdf19b795/src-logo.png'],
            },
            Klarna: {
                id: 'Klarna',
                name: 'Klarna',
                active: 1,
                logo: ['https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.png'],
                enterpriseLogo: ['//images.contentstack.io/v3/assets/blt95e0138311135214/blt517f493effcc3f73/632076f98966566ecdfbb10a/klarna-logo.png'],
            },
            Paze: {
                id: 'Paze',
                name: 'Paze',
                active: 1,
                logo: ['//images.contentstack.io/v3/assets/blt95e0138311135214/blt76780fa01f9ac638/669ed7a979f20443263944b4/paze-blue.svg'],
                enterpriseLogo: ['//images.contentstack.io/v3/assets/blt95e0138311135214/blt76780fa01f9ac638/669ed7a979f20443263944b4/paze-blue.svg'],
            },
        },
        subscription: {
            smsOptIn: false,
            emailOptIn: true,
        },
        account: {
            accountMessage: null,
            isSubscribe: false,
        },
        promotion: {
            promoCode: '',
            memberShipId: '',
        },
        venmo: {
            clientToken: '',
        },
    },
    error: {},
};
