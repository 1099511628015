/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint operator-linebreak: ["error", "none"] */
const SET_READY_STATE_REQUEST =    'mbp-account-ui/gift-list/set/state/ready/REQUEST';
const HANDLE_NAVIGATE_TO_PAGE_REQUEST = 'mbp-account-ui/navigate/to/REQUEST';
const HANDLE_REMOVE_REDIRECT_REQUEST = 'mbp-account-ui/navigate/reset/REQUEST';

const FETCH_GIFT_LIST_EMAIL_ADDRESS_FROM_ID_REQUEST = 'mbp-account-ui/gift-list/landing-page/get/email/REQUEST';
const FETCH_GIFT_LISTS_REQUEST = 'mbp-account-ui/gift-list/get/lists/REQUEST';
const HANDLE_GIFT_LIST_SEND_CODE_TO_EMAIL_REQUEST =    'mbp-account-ui/gift-list/send-code/REQUEST';
const HANDLE_GIFT_LIST_SUBMIT_VERIFICATION_CODE_REQUEST =    'mbp-account-ui/gift-list/submit-verification-code/REQUEST';
const HANDLE_GIFT_LIST_LOGIN_WITH_CUST_NBR_REQUEST =    'mbp-account-ui/gift-list/login-with-custnbr/REQUEST';
const HANDLE_GIFT_LIST_OCCASION_CHANGE_REQUEST =    'mbp-account-ui/gift-list/occasion/change/REQUEST';
const HANDLE_GIFT_LIST_RECIPIENT_CLICKED_REQUEST =    'mbp-account-ui/gift-list/recipient/click/REQUEST';
const HANDLE_GIFT_LIST_ADD_NEW_RECIPIENT_CLICKED_REQUEST =    'mbp-account-ui/gift-list/recipient/add/REQUEST';
const HANDLE_GIFT_LIST_EDIT_RECIPIENT_CLICKED_REQUEST =    'mbp-account-ui/gift-list/recipient/edit/REQUEST';
const HANDLE_GIFT_LIST_RECOMMENDATION_CLICKED_REQUEST =    'mbp-account-ui/gift-list/recommendation/click/REQUEST';
const HANDLE_GIFT_LIST_ADD_TO_CART_REQUEST =    'mbp-account-ui/gift-list/add-to-cart/click/REQUEST';
const HANDLE_GIFT_LIST_CHOOSE_SOMETHING_DIFFERENT_REQUEST =    'mbp-account-ui/gift-list/choose-something-else/click/REQUEST';
const HANDLE_GIFT_LIST_CHECKOUT_CLICKED_REQUEST =    'mbp-account-ui/gift-list/checkout/click/REQUEST';
const HANDLE_GIFT_LIST_SHOP_THE_SITE_CLICKED_REQUEST =    'mbp-account-ui/gift-list/shop-the-site/click/REQUEST';
const HANDLE_GIFT_LIST_CONFIRM_SHOP_THE_SITE_CLICKED_REQUEST =    'mbp-account-ui/gift-list/shop-the-site/confirm/click/REQUEST';
const HANDLE_GIFT_LIST_ENTER_ITEM_NUMBER_CLICKED_REQUEST =    'mbp-account-ui/gift-list/enter-item-number/click/REQUEST';
const HANDLE_GIFT_LIST_PAGINATION_CHANGED_REQUEST =    'mbp-account-ui/gift-list/page/change/REQUEST';
const HANDLE_GIFT_LIST_SUBMIT_LINK_USER_REQUEST =    'mbp-account-ui/gift-list/find-by-customer-or-order/sumbit/REQUEST';

const FETCH_AUTHENTICATION_STATE_REQUEST =    'mbp-account-ui/auth/get-state/REQUEST';
const FETCH_USER_PROFILE_REQUEST =    'mbp-account-ui/home/user-profile/get/REQUEST';
const FETCH_ADDRESS_BOOK_REQUEST = 'mbp-account-ui/address-book/get/REQUEST';
const FETCH_BILLING_ADDRESS_REQUEST =    'mbp-account-ui/billing-address/get/REQUEST';
const FETCH_ORDER_BY_EMAIL_REQUEST = 'mbp-account-ui/orders/email/get/REQUEST';
const FETCH_ORDER_DETAIL_BY_ORDER_NUMBER_REQUEST =    'mbp-account-ui/orders/detail/get/REQUEST';
const FETCH_ORDER_DETAIL_BY_BUY_AGAIN =    'mbp-account-ui/buy/again/orders/detail/get/REQUEST';

const FETCH_EMAIL_PREFERENCE_REQUEST =    'mbp-account-ui/email/preference/get/REQUEST';
const FETCH_EMAIL_ADDRESS_REQUEST = 'mbp-account-ui/email/id/get/REQUEST';
const FETCH_SUBSCRIPTIONS_REQUEST = 'mbp-account-ui/subscriptions/get/REQUEST';
const FETCH_SUBSCRIPTIONS_BY_STATUS_REQUEST = 'mbp-account-ui/subscriptions-by-status/get/REQUEST';
const HANDLE_CANCEL_SUBSCRIPTION_REQUEST = 'mbp-account-ui/subscription/cancel/REQUEST';
const HANDLE_CANCELLATION_SURVEY_REQUEST = 'mbp-account-ui/subscription/cancellation-survey/REQUEST';
const HANDLE_UPDATE_SUBSCRIPTION_REQUEST = 'mbp-account-ui/subscription/update/REQUEST';
const HANDLE_UPDATE_SUBSCRIPTION_LIST_REQUEST = 'mbp-account-ui/subscription-list/update/REQUEST';
const HANDLE_OPEN_MODAL_REQUEST = 'mbp-account-ui/modal/open/REQUEST';
const HANDLE_UPDATE_MODAL_REQUEST = 'mbp-account-ui/modal/update/REQUEST';
const HANDLE_CLOSE_MODAL_REQUEST = 'mbp-account-ui/modal/close/REQUEST';
const HANDLE_ACCOUNT_SIGN_IN_REQUEST = 'mbp-account-ui/sign-in/REQUEST';
const HANDLE_SUBMIT_CREATE_ACCOUNT_REQUEST =    'mbp-account-ui/create-account/submit/REQUEST';
const HANDLE_CLICK_RESET_PASSWORD_REQUEST =    'mbp-account-ui/reset-password/click/REQUEST';
const HANDLE_SUBMIT_RESET_PASSWORD_REQUEST =    'mbp-account-ui/reset-password/submit/REQUEST';
const HANDLE_CLEAR_PAGE_DATA_REQUEST = 'mbp-account-ui/clear-page-data/REQUEST';
const HANDLE_CLICK_CONTINUE_SHOPPING_REQUEST =    'mbp-account-ui/continue-shopping/click/REQUEST';
const HANDLE_EDIT_RECIPIENT_CLICKED_REQUEST =    'mbp-account-ui/recipient/edit/REQUEST';
const HANDLE_SUBMIT_ADD_NEW_RECIPIENT_REQUEST =    'mbp-account-ui/recipient/save/REQUEST';
const HANDLE_REMOVE_RECIPIENT_CLICKED_REQUEST =    'mbp-account-ui/recipient/remove/click/REQUEST';
const HANDLE_SUBMIT_REMOVE_RECIPIENT_REQUEST =    'mbp-account-ui/recipient/remove/REQUEST';
const HANDLE_ADD_NEW_RECIPIENT_REQUEST = 'mbp-account-ui/recipient/add/REQUEST';
const HANDLE_CHANGE_SORT_BY_REQUEST = 'mbp-account-ui/recipient/sortby/REQUEST';
const HANDLE_CLICK_EDIT_BILLING_ADDRESS_REQUEST =    'mbp-account-ui/billing/edit/click/REQUEST';
const HANDLE_SUBMIT_PROFILE_AND_PASSWORD_REQUEST = 'mbp-account-ui/submitprofile/edit/REQUEST';
const HANDLE_UPDATE_FIRSTNAME_LASTNAME_REQUEST = 'mbp-account-ui/firstlastname/edit/REQUEST';
const HANDLE_UPDATE_PASSWORD_REQUEST = 'mbp-account-ui/password/edit/REQUEST';
const HANDLE_CLICK_ADD_NEW_CREDIT_CARD_REQUEST =    'mbp-account-ui/billing/wallet/add/REQUEST';
const HANDLE_SAVE_CREDIT_CARD_REQUEST =    'mbp-account-ui/billing/wallet/save/REQUEST';
const HANDLE_CLICK_REMOVE_CREDIT_CARD_REQUEST =    'mbp-account-ui/billing/wallet/remove/click/REQUEST';
const HANDLE_CLICK_EDIT_CREDIT_CARD_REQUEST =    'mbp-account-ui/billing/wallet/edit/click/REQUEST';
const HANDLE_REMOVE_CREDIT_CARD_REQUEST =    'mbp-account-ui/billing/wallet/remove/REQUEST';
const HANDLE_SET_DEFAULT_CREDIT_CARD_REQUEST =    'mbp-account-ui/billing/wallet/set-default/REQUEST';
const HANDLE_SUBMIT_ORDER_BY_ZIP_CODE_REQUEST =    'mbp-account-ui/billing/orders/zip-code/REQUEST';
const HANDLE_UPDATE_EMAIL_PREFERENCE_REQUEST =    'mbp-account-ui/email/preference/update/REQUEST';
const HANDLE_UPDATE_NEW_EMAIL_ADDRESS_REQUEST =    'mbp-account-ui/email/id/update/REQUEST';
const HANDLE_VERIFY_ADDRESS_REQUEST =   'mbp-account-ui/qas/address/verify/REQUEST';
const THREAD_SEARCH_ADDRESS_REQUEST =   'mbp-account-ui/qas/address/search/REQUEST';
const THREAD_SELECT_AN_ADDRESS_REQUEST =    'mbp-account-ui/qas/address/select/REQUEST';
const THREAD_SET_ADDRESS_VERIFICATION_STATE_REQUEST =    'mbp-account-ui/qas/address/verification/state/set/REQUEST';
const HANDLE_CLEAR_AUTO_SEARCH_REQUEST = 'mbp-account-ui/qas/address/search/clear/REQUEST';
const HANDLE_AUTH0_LOGIN_CLICKED_REQUEST = 'mbp-account-ui/auth0/login/REQUEST';
const HANDLE_PASSWORDLESS_LOGIN_REQUEST = 'mbp-account-ui/auth0/login/passwordless/REQUEST';
const HANDLE_DESKTOP_ADD_NEW_RECIPIENT_REQUEST = 'mbp-account-ui/desktop/add/new/REQUEST';
const HANDLE_CLOSE_DIALOG_REQUEST = 'mbp-account-ui/desktop/close/dialog/REQUEST';
const HANDLE_DESKTOP_ADD_NEW__FORM_REQUEST = 'mbp-account-ui/desktop/add/new/form/REQUEST';
const HANDLE_DESKTOP_SINGLE_ADDRESS_GET_REQUEST = 'mbp-account-ui/desktop/single/address/get/REQUEST';
const HANDLE_DESKTOP_EDIT_ADDRESS_REQUEST = 'mbp-account-ui/desktop/edit/address/REQUEST';
const HANDLE_DESKTOP_ADDRESS_REMOVE_REQUEST = 'mbp-account-ui/desktop/address/REQUEST';
const HANDLE_DESKTOP_CHANGE_PASSWORD_REQUEST = 'mbp-account-ui/account/dailog/desktop/REQUEST';
const HANDLE_DESKTOP_CHANGE_EMAIL_REQUEST = 'mbp-account-ui/desktopemail/preference/update/REQUEST';
const HANDLE_DESKTOP_CLICK_EDIT_CREDIT_CARD_REQUEST =    'mbp-account-ui/billing/desktop/wallet/edit/click/REQUEST';
const HANDLE_SET_DEFAULT_BILLING_VALUE_REQUEST =    'mbp-account-ui/billing/set/default/value/REQUEST';
const HANDLE_DESKTOP_DIALOG_CONFIRM_CARD_REQUEST = 'mbp-account-ui/billing/desktop/dialog/confirm/card/REQUEST';
const HANDLE_PROCESS_CANCEL_ORDER_REQUEST = 'mbp-account-ui/order/cancel/REQUEST';
const HANDLE_PROCESS_REPLACE_ORDER_REQUEST = 'mbp-account-ui/order/replace/REQUEST';
const HANDLE_PROCESS_PARTIAL_REFUND_REQUEST = 'mbp-account-ui/order/partial/refund/REQUEST';
const HANDLE_SUBSCRIPTION_RECIPIENT_FORM_REQUEST = 'mbp-account-ui/subscriptions/put/REQUEST';
const HANDLE_PROCESS_CHECK_AVAILABILITY_REQUEST = 'mbp-account-ui/order/check-available/REQUEST';
const HANDLE_CLEAR_IS_AVAILABLE_REQUEST = 'mbp-account-ui/clear/check-available/REQUEST';
const HANDLE_CLEAR_PARTIAL_REFUND_ERROR_REQUEST = 'mbp-account-ui/clear/partial/refund/error/REQUEST';
const HANDLE_CLEAR_REPLACE_RESPONSE_REQUEST = 'mbp-account-ui/clear/replace/response/REQUEST';
const HANDLE_MMO_GET_DELIVERY_DATES_REQUEST = 'mbp-account-ui/order/mmo/deliverydate/get/REQUEST';
const HANDLE_FETCH_PRODUCT_AVAILABILITY_REQUEST = 'mbp-account-ui/order/mmo/productavailability/get/REQUEST';
const HANDLE_MMO_SUBMIT_MODIFIED_ORDER_REQUEST = 'mbp-account-ui/order/mmo/modified/order/put/REQUEST';
const HANDLE_FETCH_SUBSCRIPTION_GIFT_MESSAGE_REQUEST = 'mbp-account-ui/subscriptions/gift/message/get/REQUEST';
const HANDLE_UPDATE_SUBSCRIPTION_GIFT_MESSAGE_REQUEST = 'mbp-account-ui/update/subscription/gift/message/put/REQUEST';
const ClEAR_SUBSCRIPTION_GIFT_MESSAGE_REQUEST = 'mbp-account-ui/clear/subscription/gift/message/put/REQUEST';
const ClEAR_PRODUCT_AVILABILITY_REQUEST = 'mbp-account-ui/clear/product/avilability/REQUEST';
const HANDLE_FETCH_HD_EDITABLE_CHECK_REQUEST = 'mbp-account-ui/order/mmo/hdeditablecheck/get/REQUEST';
const HANDLE_FETCH_PASSPORT_AUTO_RENEWAL_STATUS_REQUEST = 'mbp-account-ui/subscriptions/passport/get/REQUEST';
const HANDLE_UPDATE_PASSPORT_AUTO_RENEWAL_STATUS_REQUEST = 'mbp-account-ui/subscriptions/passport/put/REQUEST';
const HANDLE_SET_PASSPORT_AUTO_RENEWAL_STATUS_REQUEST = 'mbp-account-ui/subscriptions/passport/set/put/REQUEST';
const CLEAR_ROSS_ORDER_DATA_REQUEST = 'mbp-account-ui/clear/ross/order/data/REQUEST';
const CLEAR_ROSS_ERROR_REQUEST = 'mbp-account-ui/clear/ross/error/REQUEST';
// const HANDLE_CSI_GUARDRAIL_CHECK_REQUEST = 'mbp-account-ui/order/csi/guardrailcheck/REQUEST';
const HANDLE_CLEAR_ADDRESS_VERIFICATION_REQUEST = 'mbp-account-ui/clear/address/verification/REQUEST';
const HANDLE_MASS_CANCEL_SUBSCRIPTION_REQUEST = 'mbp-account-ui/subscriptions/mass/cancel/subscriptions/post/REQUEST';
const HANDLE_VALIDATE_AGENT_KEY_REQUEST = 'mbp-account-ui/subscriptions/validate/agent/key/get/REQUEST';
const HANDLE_VALIDATE_CAPTCHA_REQUEST = 'mbp-account-ui/subscriptions/validate/captcha/post/REQUEST';
const HANDLE_CLEAR_AGENT_SUB_MGMT_STATE_REQUEST = 'mbp-account-ui/subscriptions/clear/agent/sub/mgmt/state/REQUEST';
const HANDLE_CLEAR_SUBSCRIPTION_STATE_REQUEST = 'mbp-account-ui/subscriptions/clear/agent/subscriptions/state/REQUEST';
const HANDLE_MARKET_PLACE_FORM_DATA_REQUEST = 'mbp-account-ui/marketplace/form/data/get/REQUEST';
const HANDLE_SET_ASM_CUSTOMER_EMAIL_REQUEST = 'mbp-account-ui/marketplace/set/asm/customer/email/put/REQUEST';
const HANDLE_UPDATE_SUBSCRIPTION_FREQUENCY_REQUEST = 'mbp-account-ui/subscriptions/update/subscription/frequency/put/REQUEST';
const HANDLE_CLEAR_EMAIL_ADDRESS_REQUEST = 'mbp-account-ui/clear/email/address/REQUEST';
const HANDLE_ADD_ASM_AGENT_NOTES_REQUEST = 'mbp-account-ui/agent/sub/management/add/notes/put/REQUEST';
const HANDLE_FETCH_ASM_AGENT_NOTES_REQUEST = 'mbp-account-ui/agent/sub/management/get/notes/get/REQUEST';
const CLEAR_ASM_AGENT_NOTES_STATE_REQUEST = 'mbp-account-ui/clear/asm/agent/notes/state/REQUEST';
const CLEAR_ASM_AGENT_NOTES_ERR_TRACKING_REQUEST = 'mbp-account-ui/clear/asm/agent/notes/err/tracking/REQUEST';
const SET_THUMBS_UP_FOR_DELIVERY_IMAGE_REQUEST = 'mbp-account-ui/set/thumbsup/for/delivery/image/REQUEST';
const HANDLE_CANCEL_PASSPORT_MEMBERSHIP_REQUEST = 'mbp-account-ui/account/passport/cancel/put/REQUEST';
const CLEAR_CANCEL_PASSPORT_MEMBERSHIP_STATE_REQUEST = 'mbp-account-ui/clear/cancel/passport/state/REQUEST';
const SET_RECIPIENT_OPEN_STATE_REQUEST = 'mbp-account-ui/set/recipient/state/REQUEST';
const CLEAR_ORDER_HISTORY_ROSS_DATA_REQUEST = 'mbp-account-ui/clear/order/history/ross/data/REQUEST';
const GET_GIFTLIST_OCCASIONS = 'mbp-account-ui/get/giftlist/occasion/REQUEST';
const SET_RECIPIENT_VERIFY_DATA_REQUEST = 'mbp-account-ui/set/recipient/verify/data/REQUEST';
const CLEAR_RECIPIENT_VERIFY_DATA_REQUEST = 'mbp-account-ui/clear/recipient/verify/data/REQUEST';
const HANDLE_FETCH_SUBSCRIPTIONS_REQUEST = 'mbp-account-ui/fetch/subscriptions/REQUEST';
const HANDLE_SUBSCRIPTION_SWAP_REQUEST = 'mbp-account-ui/update/subscriptions/swap/REQUEST';
const HANDLE_CLEAR_SWAP_SUBSCRIPTION_REQUEST = 'mbp-account-ui/clear/swap/subscription/REQUEST';
const UPDATE_USER_PROFILE_REQUEST = 'mbp-account-ui/update/user/profile/REQUEST';

const ActionType = {
    FETCH_GIFT_LIST_EMAIL_ADDRESS_FROM_ID_REQUEST,
    FETCH_GIFT_LISTS_REQUEST,
    FETCH_AUTHENTICATION_STATE_REQUEST,
    FETCH_USER_PROFILE_REQUEST,
    FETCH_ADDRESS_BOOK_REQUEST,
    FETCH_BILLING_ADDRESS_REQUEST,
    FETCH_ORDER_BY_EMAIL_REQUEST,
    FETCH_ORDER_DETAIL_BY_ORDER_NUMBER_REQUEST,
    FETCH_ORDER_DETAIL_BY_BUY_AGAIN,
    FETCH_EMAIL_PREFERENCE_REQUEST,
    FETCH_EMAIL_ADDRESS_REQUEST,
    FETCH_SUBSCRIPTIONS_REQUEST,
    FETCH_SUBSCRIPTIONS_BY_STATUS_REQUEST,
    SET_READY_STATE_REQUEST,
    HANDLE_VERIFY_ADDRESS_REQUEST,
    THREAD_SEARCH_ADDRESS_REQUEST,
    THREAD_SELECT_AN_ADDRESS_REQUEST,
    THREAD_SET_ADDRESS_VERIFICATION_STATE_REQUEST,
    HANDLE_CLEAR_AUTO_SEARCH_REQUEST,
    HANDLE_GIFT_LIST_SEND_CODE_TO_EMAIL_REQUEST,
    HANDLE_GIFT_LIST_SUBMIT_VERIFICATION_CODE_REQUEST,
    HANDLE_GIFT_LIST_LOGIN_WITH_CUST_NBR_REQUEST,
    HANDLE_GIFT_LIST_OCCASION_CHANGE_REQUEST,
    HANDLE_GIFT_LIST_PAGINATION_CHANGED_REQUEST,
    HANDLE_GIFT_LIST_RECIPIENT_CLICKED_REQUEST,
    HANDLE_GIFT_LIST_ADD_NEW_RECIPIENT_CLICKED_REQUEST,
    HANDLE_GIFT_LIST_RECOMMENDATION_CLICKED_REQUEST,
    HANDLE_GIFT_LIST_ADD_TO_CART_REQUEST,
    HANDLE_GIFT_LIST_CHOOSE_SOMETHING_DIFFERENT_REQUEST,
    HANDLE_GIFT_LIST_SHOP_THE_SITE_CLICKED_REQUEST,
    HANDLE_GIFT_LIST_CONFIRM_SHOP_THE_SITE_CLICKED_REQUEST,
    HANDLE_GIFT_LIST_SUBMIT_LINK_USER_REQUEST,
    HANDLE_NAVIGATE_TO_PAGE_REQUEST,
    HANDLE_REMOVE_REDIRECT_REQUEST,
    HANDLE_EDIT_RECIPIENT_CLICKED_REQUEST,
    HANDLE_GIFT_LIST_EDIT_RECIPIENT_CLICKED_REQUEST,
    HANDLE_GIFT_LIST_CHECKOUT_CLICKED_REQUEST,
    HANDLE_GIFT_LIST_ENTER_ITEM_NUMBER_CLICKED_REQUEST,
    HANDLE_ACCOUNT_SIGN_IN_REQUEST,
    HANDLE_SUBMIT_CREATE_ACCOUNT_REQUEST,
    HANDLE_CLICK_RESET_PASSWORD_REQUEST,
    HANDLE_SUBMIT_RESET_PASSWORD_REQUEST,
    HANDLE_CLEAR_PAGE_DATA_REQUEST,
    HANDLE_OPEN_MODAL_REQUEST,
    HANDLE_UPDATE_MODAL_REQUEST,
    HANDLE_CLOSE_MODAL_REQUEST,
    HANDLE_CLICK_CONTINUE_SHOPPING_REQUEST,
    HANDLE_REMOVE_RECIPIENT_CLICKED_REQUEST,
    HANDLE_SUBMIT_REMOVE_RECIPIENT_REQUEST,
    HANDLE_SUBMIT_ADD_NEW_RECIPIENT_REQUEST,
    HANDLE_ADD_NEW_RECIPIENT_REQUEST,
    HANDLE_CHANGE_SORT_BY_REQUEST,
    HANDLE_CLICK_EDIT_BILLING_ADDRESS_REQUEST,
    HANDLE_SUBMIT_PROFILE_AND_PASSWORD_REQUEST,
    HANDLE_UPDATE_FIRSTNAME_LASTNAME_REQUEST,
    HANDLE_UPDATE_PASSWORD_REQUEST,
    HANDLE_CLICK_ADD_NEW_CREDIT_CARD_REQUEST,
    HANDLE_SAVE_CREDIT_CARD_REQUEST,
    HANDLE_CLICK_REMOVE_CREDIT_CARD_REQUEST,
    HANDLE_REMOVE_CREDIT_CARD_REQUEST,
    HANDLE_SET_DEFAULT_CREDIT_CARD_REQUEST,
    HANDLE_CLICK_EDIT_CREDIT_CARD_REQUEST,
    HANDLE_SUBMIT_ORDER_BY_ZIP_CODE_REQUEST,
    HANDLE_UPDATE_EMAIL_PREFERENCE_REQUEST,
    HANDLE_UPDATE_NEW_EMAIL_ADDRESS_REQUEST,
    HANDLE_CANCEL_SUBSCRIPTION_REQUEST,
    HANDLE_CANCELLATION_SURVEY_REQUEST,
    HANDLE_MASS_CANCEL_SUBSCRIPTION_REQUEST,
    HANDLE_UPDATE_SUBSCRIPTION_REQUEST,
    HANDLE_UPDATE_SUBSCRIPTION_LIST_REQUEST,
    HANDLE_AUTH0_LOGIN_CLICKED_REQUEST,
    HANDLE_PASSWORDLESS_LOGIN_REQUEST,
    HANDLE_DESKTOP_ADD_NEW_RECIPIENT_REQUEST,
    HANDLE_CLOSE_DIALOG_REQUEST,
    HANDLE_DESKTOP_ADD_NEW__FORM_REQUEST,
    HANDLE_DESKTOP_SINGLE_ADDRESS_GET_REQUEST,
    HANDLE_DESKTOP_EDIT_ADDRESS_REQUEST,
    HANDLE_DESKTOP_ADDRESS_REMOVE_REQUEST,
    HANDLE_DESKTOP_CHANGE_PASSWORD_REQUEST,
    HANDLE_DESKTOP_CHANGE_EMAIL_REQUEST,
    HANDLE_DESKTOP_CLICK_EDIT_CREDIT_CARD_REQUEST,
    HANDLE_SET_DEFAULT_BILLING_VALUE_REQUEST,
    HANDLE_DESKTOP_DIALOG_CONFIRM_CARD_REQUEST,
    HANDLE_PROCESS_CANCEL_ORDER_REQUEST,
    HANDLE_PROCESS_REPLACE_ORDER_REQUEST,
    HANDLE_PROCESS_PARTIAL_REFUND_REQUEST,
    HANDLE_SUBSCRIPTION_RECIPIENT_FORM_REQUEST,
    HANDLE_PROCESS_CHECK_AVAILABILITY_REQUEST,
    HANDLE_CLEAR_IS_AVAILABLE_REQUEST,
    HANDLE_CLEAR_PARTIAL_REFUND_ERROR_REQUEST,
    HANDLE_CLEAR_REPLACE_RESPONSE_REQUEST,
    HANDLE_MMO_GET_DELIVERY_DATES_REQUEST,
    HANDLE_FETCH_PRODUCT_AVAILABILITY_REQUEST,
    HANDLE_MMO_SUBMIT_MODIFIED_ORDER_REQUEST,
    HANDLE_FETCH_SUBSCRIPTION_GIFT_MESSAGE_REQUEST,
    HANDLE_UPDATE_SUBSCRIPTION_GIFT_MESSAGE_REQUEST,
    ClEAR_SUBSCRIPTION_GIFT_MESSAGE_REQUEST,
    ClEAR_PRODUCT_AVILABILITY_REQUEST,
    HANDLE_FETCH_HD_EDITABLE_CHECK_REQUEST,
    HANDLE_FETCH_PASSPORT_AUTO_RENEWAL_STATUS_REQUEST,
    HANDLE_UPDATE_PASSPORT_AUTO_RENEWAL_STATUS_REQUEST,
    HANDLE_SET_PASSPORT_AUTO_RENEWAL_STATUS_REQUEST,
    CLEAR_ROSS_ORDER_DATA_REQUEST,
    CLEAR_ROSS_ERROR_REQUEST,
    //  HANDLE_CSI_GUARDRAIL_CHECK_REQUEST,
    HANDLE_CLEAR_ADDRESS_VERIFICATION_REQUEST,
    HANDLE_VALIDATE_AGENT_KEY_REQUEST,
    HANDLE_VALIDATE_CAPTCHA_REQUEST,
    HANDLE_CLEAR_AGENT_SUB_MGMT_STATE_REQUEST,
    HANDLE_CLEAR_SUBSCRIPTION_STATE_REQUEST,
    HANDLE_MARKET_PLACE_FORM_DATA_REQUEST,
    HANDLE_SET_ASM_CUSTOMER_EMAIL_REQUEST,
    HANDLE_UPDATE_SUBSCRIPTION_FREQUENCY_REQUEST,
    HANDLE_CLEAR_EMAIL_ADDRESS_REQUEST,
    HANDLE_ADD_ASM_AGENT_NOTES_REQUEST,
    HANDLE_FETCH_ASM_AGENT_NOTES_REQUEST,
    CLEAR_ASM_AGENT_NOTES_STATE_REQUEST,
    CLEAR_ASM_AGENT_NOTES_ERR_TRACKING_REQUEST,
    SET_THUMBS_UP_FOR_DELIVERY_IMAGE_REQUEST,
    HANDLE_CANCEL_PASSPORT_MEMBERSHIP_REQUEST,
    CLEAR_CANCEL_PASSPORT_MEMBERSHIP_STATE_REQUEST,
    SET_RECIPIENT_OPEN_STATE_REQUEST,
    CLEAR_ORDER_HISTORY_ROSS_DATA_REQUEST,
    GET_GIFTLIST_OCCASIONS,
    SET_RECIPIENT_VERIFY_DATA_REQUEST,
    CLEAR_RECIPIENT_VERIFY_DATA_REQUEST,
    HANDLE_FETCH_SUBSCRIPTIONS_REQUEST,
    HANDLE_SUBSCRIPTION_SWAP_REQUEST,
    HANDLE_CLEAR_SWAP_SUBSCRIPTION_REQUEST,
    UPDATE_USER_PROFILE_REQUEST,
};

const ActionKeyValue = [];

Object.entries(ActionType).forEach((entry) => {
    const [key, value] = entry;
    ActionKeyValue.push({ key, value });
});

export const getActionKey = (value) => {
    const index = ActionKeyValue.findIndex((item) => item.value === value);
    /* istanbul ignore next */
    if (index > -1) {
        return ActionKeyValue[index].key;
    }
    return false;
};

export const getSuccessType = (type) => type.replace(/\/REQUEST$/, '/LOADED');
export const getFailureType = (type) => type.replace(/\/REQUEST$/, '/FAILURE');

export default ActionType;
