/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const subscriptionConfig = {
    subscription_keys: ['subscription_duration', 'subscription_type', 'subscription_interval'],
    subscription_type: [
        'basic',
        'discovery',
        'basic1',
        'basic2',
        'basic3',
        'basic4',
        'monthOnlyPayFull',
        'monthOnly',
        'basic5',
        'basic6',
        'basic7',
        'basic8',
        'basic9',
        'basic10',
    ],
    subscription_duration: {
        '-1': 'Continue until I stop',
        1: 'Continue until I stop',
        3: 'Three Months',
        6: 'Six Months',
        9: 'Nine Months',
        12: 'Twelve Months',
    },
    subscription_interval: {
        1: 'Every Week',
        30: 'Monthly',
        2: 'Every 2 Weeks',
        4: 'Every 4 Weeks',
        6: 'Every 6 Weeks',
        60: 'BiMonthly',
        8: 'Every 8 Weeks',
    },
};

export default subscriptionConfig;
