/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const getCampaignDetails = (isMobile, campaign_section) => {
    const campaignDetails = !isMobile
        ? ((campaign_section?.desktop_banner?.url
            && campaign_section?.desktop_banner?.title)
                && {
                    banner: campaign_section.desktop_banner,
                    color: campaign_section?.banner_background_color || 'white',
                    terms: campaign_section.campaign_disclaimer,
                    from_date: campaign_section?.from_date,
                    to_date: campaign_section?.to_date,
                })
        : ((campaign_section?.mobile_banner?.url
            && campaign_section?.mobile_banner?.title)
            && {
                banner: campaign_section.mobile_banner,
                color: campaign_section?.banner_background_color || 'white',
                terms: campaign_section.campaign_disclaimer,
                from_date: campaign_section?.from_date,
                to_date: campaign_section?.to_date,
            });

    return campaignDetails;
};

export const isValidCampaign = (campaignDetails) => {
    if (campaignDetails?.from_date && campaignDetails?.to_date) {
        const getTime = (date) => new Date(date).getTime();
        const today = () => new Date().getTime();
        if (today() > getTime(campaignDetails.from_date)
        && today() < getTime(campaignDetails.to_date)) {
            return true;
        }
    }
    return false;
};
