/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import * as actionTypes from './Global-ActionTypes';

export const clearAuthDependentState = (forceClear) => ({
    type: actionTypes.CLEAR_AUTH_DEPENDENT_STATE,
    data: {
        forceClear,
    },
});

export const logUserLoginInSessionReady = () => ({
    type: actionTypes.LOG_USER_LOGIN_SESSION_READY,
});

export const setAccessToken = (accessToken) => ({
    type: actionTypes.SET_ACCESS_TOKEN,
    data: {
        accessToken,
    },
});

export const triggerInterstitialLogin = (history, containsSubscription) => ({
    type: actionTypes.TRIGGER_INTERSTITIAL_LOGIN,
    history,
    containsSubscription,
});

export const logLastVisitedPage = (urls) => ({
    type: actionTypes.LOG_LAST_VISITED_PAGE,
    data: {
        urls,
    },
});

// Profile
export const setProfileEmailId = (data) => ({
    type: actionTypes.SET_PROFILE_EMAIL_ID,
    data,
});

export const loadedProfileUserRole = () => ({
    type: actionTypes.LOADED_PROFILE_USER_ROLE,
});

export const setProfileUserRole = (data) => ({
    type: actionTypes.SET_PROFILE_USER_ROLE,
    data,
});

// Address Book

export const addAddressBook = (addressBookEntry) => ({
    type: actionTypes.ADD_ADDRESS_BOOK,
    data: {
        addressBookEntry,
    },
});

export const editAddressBook = (addressBookEntry) => ({
    type: actionTypes.EDIT_ADDRESS_BOOK,
    data: {
        addressBookEntry,
    },
});

export const loadAddressBook = (pageNumber) => ({
    type: actionTypes.LOAD_ADDRESS_BOOK,
    data: {
        pageNumber,
    },
});

export const removeAddressBook = (addressBookEntryId) => ({
    type: actionTypes.REMOVE_ADDRESS_BOOK,
    data: {
        addressBookEntryId,
    },
});

export const saveGuestAddressBook = (guestAddressBookEntries) => ({
    type: actionTypes.SAVED_GUEST_ADDRESS_BOOK,
    data: {
        guestAddressBookEntries,
    },
});

export const proccessAddressBookCompleted = () => ({
    type: actionTypes.PROCESS_ADDRESS_BOOK_COMPLETED,
});

// Shop Runner
export const loadShopRunner = () => ({
    type: actionTypes.LOAD_SHOPRUNNER,
});

// Wallet
export const loadWallet = () => ({
    type: actionTypes.LOAD_WALLET,
});

export const addCreditCard = (cardDetails) => ({
    type: actionTypes.ADD_CREDIT_CARD_TO_WALLET,
    data: cardDetails,
});

export const saveCreditCardFlag = (cardFlag) => ({
    type: actionTypes.SET_CARD_SET_SAVED_FLAG,
    data: cardFlag,
});

export default {};
