/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const PLACE_ORDER = 'mbp-pwa-ui/PLACE_ORDER';
export const CLICKSTREAM_ORDER_COMPLETE = 'mbp-pwa-ui/CLICKSTREAM/ORDER_COMPLETE';
export const SHOW_ORDER_CONFIRMATION = 'mbp-pwa-ui/SHOW_ORDER_CONFIRMATION';
export const PROCESS_NBCU_MOVIE = 'mbp-pwa-ui/PROCESS/NBCU/MOVIE';
export const APPLE_PAY_ORDER_SUCCESS = 'mbp-pwa-ui/APPLE/PAY/ORDER/SUCCESS';
export const APPLE_PAY_VALIDATE_MERCHANT_SUCCESS = 'mbp-pwa-ui/APPLE/PAY/VALIDATE/MERCHANT/SUCCESS';
