/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { array, object } from 'prop-types';
import loadable from '@loadable/component';

import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import DesktopMobile from '../../../helpers/DesktopMobile/DesktopMobile';

import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';

const LoadableDesktopFooter  = loadable(() => import(/* webpackChunkName: "DesktopGraphqlFooter" */ './DesktopGraphqlFooter'));
const LoadableMobileFooter = loadable(() => import(/* webpackChunkName: "MobileGraphqlFooter" */ './MobileGraphqlFooter'));

const GraphqlFooter = ({ footerData, legalData }) => {
    const ffIsAddShoppersScriptEnabled = useSelector(getFeatureFlag('is-add-shoppers-script-enabled'));
    const isRecaptchaCheckoutEnabled = useSelector(getFeatureFlag('is-giftcard-btn-captcha-enabled'))
        || useSelector(getFeatureFlag('is-placeorder-btn-captcha-enabled'));
    const brand = useSelector(getBrand);

    return (
        <>
            <DesktopMobile
                desktop={() => (
                    <LoadableDesktopFooter
                        desktopFooterData={footerData}
                        legalData={legalData}
                        brand={brand}
                        isRecaptchaCheckoutEnabled={isRecaptchaCheckoutEnabled}
                    />
                )}
                mobile={() => (
                    <LoadableMobileFooter
                        mobileFooterData={footerData}
                        brand={brand}
                        legalData={legalData}
                        isRecaptchaCheckoutEnabled={isRecaptchaCheckoutEnabled}
                    />
                )}
            />
            {ffIsAddShoppersScriptEnabled && (
                <Helmet>
                    <script type="text/javascript">
                        {'{" var js = document.createElement(\'script\'); js.type = \'text/javascript\'; js.async = true; js.id = \'AddShoppers\'; js.src = (\'https:\' == document.location.protocol ? \'https://shop.pe/widget/\' : \'http://cdn.shop.pe/widget/\') + \'widget_async.js#5f7f4723d559303e1b1252f6\'; document.getElementsByTagName("head")[0].appendChild(js);"}'}
                    </script>
                </Helmet>
            )}
        </>
    );
};

GraphqlFooter.propTypes = {
    footerData: array.isRequired,
    legalData: object.isRequired,
};

export default GraphqlFooter;
