/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useQuery } from '@apollo/client';
import { object, func } from 'prop-types';
import mbpLogger from 'mbp-logger';

import * as app from '../../../../state/ducks/App';
import { setPassportData } from '../../../../state/ducks/Passport/Passport-Action';
import findPassportContent from '../../../gql/queries/findPassportContent';
import { GRAPHQL_ENV } from '../../../gql';

const brandSelectors = app.ducks.brand.selectors;

const GraphqlPassportContainer = ({ brand, setPassportCodeAction }) => {
    const Passport_PAGE_QUERY = findPassportContent(brand['domain-name']);
    const { loading, error, data } = useQuery(Passport_PAGE_QUERY);

    if (loading) {
        return null;
    }

    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            query: Passport_PAGE_QUERY,
            component: 'GraphqlPassportContainer.js',
            message: 'Error loading data from Graphql',
            env: GRAPHQL_ENV,
            error,
        });
        return null;
    }

    if (!data) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            query: Passport_PAGE_QUERY,
            component: 'GraphqlPassportContainer.js',
            message: 'No data returned for query',
            env: GRAPHQL_ENV,
        });
    } else if (!data?.findContent?.content) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            query: Passport_PAGE_QUERY,
            component: 'GraphqlPassportContainer.js',
            message: 'No content returned for query',
            env: GRAPHQL_ENV,
        });
    } else {
        const passportData = data?.findContent?.content?.entries || [];

        setPassportCodeAction(passportData[0]);
    }

    return null;
};

GraphqlPassportContainer.propTypes = {
    setPassportCodeAction: func.isRequired,
    brand: object.isRequired,
};

const mapStateToProps = (state) => ({
    brand: brandSelectors.getBrand(state),
});
const mapDispatchToProps = (dispatch) => ({
    setPassportCodeAction: bindActionCreators(setPassportData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GraphqlPassportContainer);
