/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { Component } from 'react';
import mbpLogger from 'mbp-logger';
import {
    any, string, func, bool, shape,
} from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getBrand } from '../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import PageErrorBoundaryUI from './components/PageErrorBoundaryUI';

/**
 *
 * @param {String} boundaryName The name of the boundary that will be used in error reporting. Please use single words or underscores in between words if more than a single word is needed.
 * @param {String?} errorMessage The message, if any, to be displayed when the component fails. If no message is included, nothing will show.
 * @param {Function?} fallback A fallback component that will render if an error occurs. If defined, errorMessage will not be displayed
 */
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        const { boundaryName } = this.props;

        this.state = {
            hasError: false,
        };
        if (!boundaryName) {
            throw new Error('No boundaryName was provided to ErrorBoundary. This is mandatory.');
        }
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        const { boundaryName, location, brand } = this.props;

        /*
            Get the first component in the stack for easy reference in console.
            If the error is thrown in a component with .displayName set, only componentStack has that name in it.
            Regex capture that pulls the component name e.g. "\n in HeroSingle\n in Hero\n...etc" Becomes HeroSingle
        */
        const componentName = /in (\w+)/.exec(errorInfo?.componentStack)?.[1];
        const path = location?.pathname;
        const domain = brand?.domain;
        mbpLogger.logError({
            function: boundaryName,
            appName: process.env.npm_package_name,
            module: 'ErrorBoundary',
            message: `An error originated from ${boundaryName} on path: ${path} with brand: ${domain} with type: ${error.name} || message: ${error.message} || within: ${componentName}`,
            jsError: error,
            componentStack: errorInfo,
        });
    }

    render() {
        const {
            children, fallback, errorMessage, showReload,
        } = this.props;
        const { hasError } = this.state;
        let component = children;
        if (hasError) {
            if (fallback && typeof fallback === 'function') {
                component = fallback();
            } else if (errorMessage) {
                component = (
                    <PageErrorBoundaryUI errorMessage={errorMessage} showReload={showReload} />
                );
            } else { // render empty div if nothing is passed (will help maintain page structure)
                component = <div />;
            }
        }
        return component;
    }
}

ErrorBoundary.propTypes = {
    boundaryName: string.isRequired,
    children: any.isRequired,
    location: shape({
        pathname: string,
    }).isRequired,
    brand: shape({
        domain: string,
    }),
    fallback: func,
    errorMessage: string,
    showReload: bool,
};

ErrorBoundary.defaultProps = {
    brand: null,
    fallback: null,
    errorMessage: null,
    showReload: true,
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
});

export default connect(mapStateToProps)(withRouter(ErrorBoundary));
