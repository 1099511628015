/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const sanitizingString = (item) => {
    if (item) {
        // 1. Escaping " double quotes
        // 2. Removing new lines
        // 3. Removed <br> tag
        return item.replace(/"/g, '\\"').replace(/(\r\n|\n|\r)/gm, '').replace(/<br>/g, ' ').replace(/<br\/>/g, ' ');
    }
    return '';
};

export default {};
