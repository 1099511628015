/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { combineReducers } from 'redux';
import createState from 'redux-create-state';

import utils from '../../../../utils';
import * as configActions from './Config-Actions';

const INITIAL_STATE = {};

const byDomain = utils.createReducer(INITIAL_STATE)({
    [configActions.configLoaded().type]: (state, action) => createState(state || INITIAL_STATE, [`${action.domain}.${action.topic}`, action.payload]),
});

export default combineReducers({
    byDomain,
});
