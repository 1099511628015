/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import expressCheckoutActionTypes from './ExpressCheckout-ActionType';

export const processExpressCheckout = (payload) => ({
    type: expressCheckoutActionTypes.PROCESS_EXPRESS_CHECKOUT,
    data: payload,
});

export const addToCartSuccess = () => ({
    type: expressCheckoutActionTypes.EXPRESS_CHECKOUT_ADD_ITEM_TO_CART_SUCCESS,
});

export const addToCartFailed = (exception) => ({
    type: expressCheckoutActionTypes.EXPRESS_CHECKOUT_ADD_ITEM_TO_CART_FAILED,
    payload: exception,
});
