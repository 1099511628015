/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// helper function strips markdown link - matches [,] and removes brackets - matches (,) and removes () and text inside
// we should really figure out a better solution and refactor
function markdownLinkLiteralParser(answer) {
    const newAnswer = answer
        .replace('[sunflowers](/sunflowers)', "<a href='https://www.1800flowers.com/sunflowers'>sunflowers</a>")
        .replace('[tulips](/tulips)', "<a href='https://www.1800flowers.com/tulips'>tulips</a>")
        .replace('[discount flowers](/cheapflowers)', "<a href='https://www.1800flowers.com/cheapflowers'>discount flowers</a>")
        .replace('[delivery options][1]', "<a href='https://www.1800flowers.com/customer-service'>delivery options</a>")
        .replace('[birthday rose delivery](/happy-birthday-roses)', "<a href='https://www.1800flowers.com/happy-birthday-roses'>birthday rose delivery</a>")
        .replace('[red roses](/red-roses)', "<a href='https://www.1800flowers.com/red-roses'>red roses.</a>")
        .replace('[roses](/roses)', "<a href='https://www.1800flowers.com/red-roses'>roses</a>")
        .replace('[birth flowers by month](/birth-flowers)', "<a href='https://www.1800flowers.com/birth-flowers'>birth flowers by month</a>")
        // .replace('',"")
        // /birthday-flowers-10359 specific ^^
        .replace('  [1]: /customer-service', '')
        .replace(/[\][]/gi, '')
        .replace(/\([^()]*\)/g, '')
        .replace(/#/g, '');
    return newAnswer;
}

export default markdownLinkLiteralParser;
