/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actions from './Auth-Actions';
import initialState from '../../initialState';

const authLogonReducer = (state = initialState.auth, action) => {
    switch (action.type) {
        case actions.setAuthDetails().type:
            return {
                ...state,
                accessToken: action.data.accessToken,
                userType: action.data.userType,
                isAuthenticated: action.data.isAuthenticated,
            };
        case actions.setAccessToken().type:
            return {
                ...state,
                accessToken: action.data.accessToken,
            };
        case actions.logLastVisitedPage().type:
            return {
                ...state,
                lastVisitedPage: action.data.urls,
            };
        default:
            return state;
    }
};

export default authLogonReducer;
