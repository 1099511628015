/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const UPDATE_CART_SNAPSHOT = 'mbp-checkout/UPDATE/CART/SNAPSHOT';
const LOAD_CART = 'mbp-checkout/CART/LOAD';
const GET_CART = 'mbp-checkout/GET/CART';
const UPDATE_QTY = 'mbp-checkout/CART/UPDATE_QTY';
const CART_LOADED = 'mbp-checkout/CART/LOADED';
const RECALCULATE_GIFT_CARD_TOTAL = 'mbp-pw-ui/RECALCULATE_GIFT_CARD_TOTAL';
const CALL_HANDLE_CART_FAILURE = 'mbp-checkout/CALL_HANDLE_CART_FAILURE';

export default {
    UPDATE_CART_SNAPSHOT,
    LOAD_CART,
    GET_CART,
    UPDATE_QTY,
    CART_LOADED,
    RECALCULATE_GIFT_CARD_TOTAL,
    CALL_HANDLE_CART_FAILURE,
};
