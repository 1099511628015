/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Switch, Route } from 'react-router';
import {
    object, bool, string, array,
} from 'prop-types';
import { Helmet } from 'react-helmet';

const AppRoutes = (props) => {
    const {
        store,
        apolloClient,
        is404,
        productUnavailableName,
        pRoutes,
    } = props;
    return (
        <Switch>
            {/* Public */
                pRoutes.map((route, index) => {
                    const { Component, path } = route;
                    const keyRoute = `route${index}`;
                    return (
                        <Route
                            path={path}
                            key={keyRoute}
                            render={() => (
                                <Component
                                    store={store}
                                    apolloClient={apolloClient}
                                    Helmet={Helmet}
                                    is404={is404}
                                    productUnavailableName={productUnavailableName}
                                />
                            )}
                        />
                    );
                })
            }
        </Switch>
    );
};

AppRoutes.propTypes = {
    store: object.isRequired,
    apolloClient: object.isRequired,
    is404: bool.isRequired,
    productUnavailableName: string.isRequired,
    pRoutes: array.isRequired,
};

export default AppRoutes;
