/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const addASMAgentNotesApi = async (wcEnv, jwtToken, payload) => {
    const resourcePage = 'retention-subscription/passport/add-notes';

    return restClient.putFacade(wcEnv, resourcePage, jwtToken, payload)
        .then((response) => response.data);
};

export default addASMAgentNotesApi;
