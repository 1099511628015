/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import brandTheme from '../components/AppShell/brandtheme';

/**
 * Returns brand presentation_family
 * This function uses brandTheme to get the presentation_family
 * @param {string} brandId brand id - 1001
 */
const getBrandPresentationFamily = (brandId) => {
    let presentationFamily = '';
    if (brandId) {
        const brandCode = Object.keys(brandTheme).filter((brand) => brandTheme[brand]?.id === brandId)[0];
        presentationFamily = brandTheme[brandCode]?.presentation_family || '';
    }

    return presentationFamily;
};

export default getBrandPresentationFamily;
