/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const CLEAR_PROFILE_DETAILS = 'mbp-member/CLEAR_PROFILE_DETAILS';
export const SET_PROFILE_DETAILS = 'mbp-member/SET_PROFILE_DETAILS';
export const SET_PROFILE_EMAIL_ID = 'mbp-member/SET_PROFILE_EMAIL_ID';
export const SET_PROFILE_USER_ROLE = 'mbp-member/SET_PROFILE_USER_ROLE';
export const LOAD_PROFILE_DETAILS = 'mbp-member/LOAD_PROFILE_DETAILS';
export const LOAD_OFFLINE_PROFILE_DETAILS = 'mbp-member/LOAD_OFFLINE_PROFILE_DETAILS';
export const UPDATE_SHOPRUNNER_USER = 'mbp-member/UPDATE_SHOPRUNNER_USER';
export const LOAD_PROFILE_USER_ROLE = 'mbp-member/LOAD_PROFILE_USER_ROLE';
export const LOADED_PROFILE_USER_ROLE = 'mbp-member/LOADED_PROFILE_USER_ROLE';
export const SET_PROFILE_EMAIL_ADDRESS = 'mbp-member/SET_PROFILE_EMAIL_ADDRESS';
export const SET_PROFILE_AUTO_RENEW_STATUS = 'mbp-member/SET_PROFILE_AUTO_RENEW_STATUS';
export const SET_MEMBERSHIP_TYPE = 'mbp-member/SET_MEMBERSHIP_TYPE';
export const SET_MEMBERSHIP_TYPE_LOADING = 'mbp-member/SET_MEMBERSHIP_TYPE/LOADING';
export const UPDATE_PROFILE_DETAILS = 'mbp-member/UPDATE_PROFILE_DETAILS';
