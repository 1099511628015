/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import mbpLogger from 'mbp-logger';
import { bool } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { setPassportSaleForceABTest } from '../../../../state/ducks/Passport/Passport-Action';
import { getCurrentPageType } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Selectors';
import { getBrandName } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import useSalesforcePersonalizedContentQuery from '../../../helpers/Personalization/useSalesforcePersonalizedContentQuery';

const PassportSaleForceABTest = ({
    isNewPassportDesignEnabled,
    ...props
}) => {
    const dispatch = useDispatch();
    const pageType = useSelector(getCurrentPageType);
    const brand = useSelector(getBrandName) || '1800flowers';
    const interactionName = `${brand} - Get Campaign - Passport`;

    const { loading, data, error } = useSalesforcePersonalizedContentQuery({
        interactionName,
        targeting: {
            pageType,
        },
        featureFlag: true,
        apolloOptions: {
            fetchPolicy: 'cache-first',
        },
        optInHoldoutParticipation: false,
        resolveIdentity: false,
    });

    if ((loading) || (!loading && !data && !error)) {
        return null;
    }

    if (error) {
        mbpLogger.logError({
            function: 'PassportBuilderABTest',
            appName: process.env.npm_package_name,
            module: 'mbp-checkout',
            message: 'Passport Salesforce AB test returned an error',
            jsError: error,
        });
    }

    if (data?.contentSF?.campaign?.campaignResponses?.length > 0) {
        dispatch(setPassportSaleForceABTest(data));
    }

    return null;
};

PassportSaleForceABTest.propTypes = {
    isNewPassportDesignEnabled: bool.isRequired,
};

export default PassportSaleForceABTest;
