/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const polyfills = [];

// TODO: Add check (https://stackoverflow.com/questions/43502448/how-to-detect-async-function-support-without-eval)
polyfills.push(import(/* webpackChunkName: "polyfill-regenerator" */ 'regenerator-runtime'));

// https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#polyfill
if (typeof window.CustomEvent !== 'function') {
    polyfills.push(import(/* webpackChunkName: "polyfill-custom-event" */ 'custom-event-polyfill'));
}

if (!window.Intl) {
    polyfills.push(import(/* webpackChunkName: "polyfill-intl" */ 'intl'));
}

if (!window.URL) {
    polyfills.push(import(/* webpackChunkName: "polyfill-url" */ 'url-polyfill'));
}

if (!window.Number || !window.Number.isNaN) {
    polyfills.push(import(/* webpackChunkName: "polyfill-number" */ 'core-js/es/number'));
}

export default polyfills;
