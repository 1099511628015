/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { createPortal } from 'react-dom';
import Backdrop from '@material-ui/core/Backdrop';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { object } from 'prop-types';
import Fade from '@material-ui/core/Fade';

import useAuth0 from './useAuth';

const styles = (theme) => ({
    loaderBackground: {
        zIndex: '10000',
        backgroundColor: 'rgba(0, 0 , 0, 0.8)',
    },
    spinner: {
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        position: 'fixed',
        top: '38%',
        left: '48%',
        zIndex: '10001',
    },
    clickToResume: {
        color: '#FFF',
        fontWeight: 'bold',
    },
    overlaySpinner: {
        cursor: 'pointer',
    },
});

const AuthSiteLoader = ({ classes }) => {
    const {
        popUpParamsRef,
        loginByAuth0PopUp,
        showOverlay,
    } = useAuth0();

    if (typeof document !== 'undefined') {
        const portalRoot = document.getElementById('fullPageLoadingDiv');

        const loaderDiv = (
            <Grid
                className={classes.overlaySpinner}
                onClick={() => {
                    if (typeof window !== 'undefined' && window.focusAuthLoginPopup) {
                        window.focusAuthLoginPopup();
                    } else {
                        loginByAuth0PopUp(
                            popUpParamsRef.current.options,
                            popUpParamsRef.current.params,
                        );
                    }
                }}
            >
                <Fade
                    in
                    timeout={{
                        entry: 100,
                        exit: 600,
                    }}
                >
                    <Backdrop open className={classes.loaderBackground}>
                        <Typography className={classes.clickToResume}>
                            {'Don\'t see the login screen, click to resume'}
                        </Typography>
                        <CircularProgress className={classes.spinner} />
                    </Backdrop>
                </Fade>
            </Grid>
        );

        if (!portalRoot || !showOverlay) return null;

        return createPortal(
            loaderDiv,
            portalRoot,
        );
    }

    return null;
};

AuthSiteLoader.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(AuthSiteLoader);
