/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpUtil from 'mbp-api-util';
import PersonClient from 'wc-api-person';
import mbpLogger from 'mbp-logger';

import { updateTokenInLocalStorage, setTokenLocalStorage, getTokenLocalStorage } from './helpers';

export async function getGuestUserAccessToken() {
    let userInfo = getTokenLocalStorage('userInfo');
    userInfo = userInfo ? JSON.parse(userInfo) : '';

    let accessToken = userInfo.token;

    const jwtTest = mbpUtil.testJWT(accessToken);

    if (jwtTest && jwtTest.isGuest && jwtTest.isValid && !jwtTest.isExpired) {
        return accessToken;
    }

    try {
        const guid = mbpUtil.getEnv('APP_AUTH0_GUID');
        const response = await PersonClient.getGuestToken({}, { guid });
        accessToken = response?.data?.accessToken || response?.data?.access_token || '';
    } catch (ex) {
        mbpLogger.logError({
            module: 'mbp-pwa-ui',
            fileName: 'guest.js',
            function: 'getGuestUserAccessToken',
            jsError: ex,
            message: 'failed to get guest accesstoken',
        });

        return '';
    }

    updateTokenInLocalStorage(accessToken);

    return accessToken;
}

export function setGuestEmailIdInLocalStorage(emailId) {
    const userInfoLocalStorage = getTokenLocalStorage('userInfo');

    if (userInfoLocalStorage) {
        const objUserInfo = JSON.parse(userInfoLocalStorage);
        objUserInfo.profile.email = emailId;
        setTokenLocalStorage('userInfo', objUserInfo);
    }
}

export default {};
