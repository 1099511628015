/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const fetchDeliveryDates = (jwtToken, payload, params, headers) => {
    const wcEnv = {
        rootUri: '/retention',
    };
    const resourcePage = '/selfservice/mmo/deliveryDates';

    return restClient.postFacade(wcEnv, resourcePage, jwtToken, payload, params, headers, false)
        .then((response) => response);
};

export default fetchDeliveryDates;
