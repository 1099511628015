/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { END } from 'redux-saga';
import {
    fork, take, call, put, select,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';

import { duck as tmDuck } from '../../../../../TagManager';
import * as actionTypes from './Error-ActionTypes';
import { setOrderItemError } from './Error-Actions';
import { getCustomerCare } from '../../Common-Selectors';

function* GenericErrorMessage() {
    const phoneNo = yield select(getCustomerCare);
    const contactLink = `<a style="color:#f44336" href="tel:'${phoneNo}'">${phoneNo}</a>`;
    return `We are sorry, there seems to be a technical issue, please try again later or call our customer service (${contactLink}) for help. We're here for you!`;
}

export function* processOrderItemError(action) {
    try {
        let errorObject = [];

        const {
            errorFeatureType, errorValues, orderItemId,
        } = action.data || {};

        const { detailedError } = errorValues || {};
        if (detailedError.errorKey === 'ERROR_NOT_AUTHORIZED_TO_ACCESS_CART') {
            errorObject = {
                error: {
                    errorKey: detailedError.errorKey,
                    errorMessage: 'We are sorry, there may be a network connection issue. Please try again.',
                    validationErrors: [],
                },
            };
        } else {
            const validationErrors = {};
            (detailedError.validationErrors || []).forEach((errorLine) => {
                let keyItem = errorLine.errorKey;
                if (keyItem.indexOf('recipient.') >= 0) {
                    keyItem = keyItem.replace('recipient.', '');
                }
                if (keyItem.indexOf('payment.') >= 0) {
                    keyItem = keyItem.replace('payment.', '');
                }
                if (keyItem.indexOf('occasion.') >= 0) {
                    keyItem = keyItem.replace('occasion.', '');
                }
                validationErrors[keyItem] = errorLine.errorMessage;
            });

            if (validationErrors.firstName || validationErrors.lastName) {
                validationErrors.firstAndLastName = 'Recipient\'s first and last name is required';
            }

            errorObject[errorFeatureType] = {
                errorKey: detailedError.errorKey,
                errorMessage: detailedError?.errorMessage || detailedError,
                validationErrors,
            };
        }
        // Track Error [Tealium]
        yield put(setOrderItemError(orderItemId, errorObject));

        const errorEventObj = {
            eventCategory: 'Errors',
            eventAction: errorFeatureType ? `${errorFeatureType[0].toUpperCase()}${errorFeatureType.slice(1)}` : 'Checkout',
            eventLabel: detailedError.errorKey || errorFeatureType,
        };

        yield put(tmDuck.actions.trackEvent(errorEventObj));

        console.error('Error Info:', {
            function: 'getOrderItemError',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: action ? JSON.stringify(action) : 'Error getting order item',
        });
    } catch (ex) {
        const genericError = yield call(GenericErrorMessage);
        yield put(setOrderItemError(
            action.orderItemId,
            {
                error: {
                    errorMessage: genericError,
                },
            },
        ));
    }
}

export function* processFeatureErrors(action) {
    try {
        let errorObject = [];

        const {
            errorFeatureType, errorValues, orderItemId,
        } = action.data || {};

        if (errorValues.errorKey === 'ERROR_NOT_AUTHORIZED_TO_ACCESS_CART') {
            errorObject = {
                error: {
                    errorKey: errorValues.errorKey,
                    errorMessage: 'We are sorry, there may be a network connection issue. Please try again.',
                    validationErrors: {},
                },
            };
        } else {
            const validationErrors = {};
            (errorValues.fieldErrors || []).forEach((errorLine) => {
                let keyItem = errorLine.fieldName;
                if (keyItem.indexOf('recipient.') >= 0) {
                    keyItem = keyItem.replace('recipient.', '');
                }
                if (keyItem.indexOf('payment.') >= 0) {
                    keyItem = keyItem.replace('payment.', '');
                }
                if (keyItem.indexOf('occasion.') >= 0) {
                    keyItem = keyItem.replace('occasion.', '');
                }
                validationErrors[keyItem] = errorLine.defaultMessage;
            });

            if (validationErrors.firstName || validationErrors.lastName) {
                validationErrors.firstAndLastName = 'Recipient\'s first and last name is required';
            }

            errorObject[errorFeatureType] = {
                errorKey: errorValues.errorKey,
                errorMessage: errorValues.errorMessage || `Error generated in ${errorFeatureType}`,
                validationErrors,
            };
        }

        // Track Error [Tealium]
        yield put(setOrderItemError(orderItemId, errorObject));

        const errorEventObj = {
            eventCategory: 'Errors',
            eventAction: errorFeatureType ? `${errorFeatureType[0].toUpperCase()}${errorFeatureType.slice(1)}` : 'Checkout',
            eventLabel: errorValues.errorKey || errorFeatureType,
        };

        yield put(tmDuck.actions.trackEvent(errorEventObj));

        mbpLogger.logError({
            function: 'processFeatureErrors',
            appName: process.env.npm_package_name,
            module: 'Error-Operations',
            jsError: action?.data ? JSON.stringify(action.data) : 'Error getting order item',
            message: action?.data ? JSON.stringify(action.data) : 'Error getting order item',
        });
    } catch (ex) {
        const genericError = yield call(GenericErrorMessage);
        yield put(setOrderItemError(
            action.orderItemId,
            {
                error: {
                    errorMessage: genericError,
                },
            },
        ));
    }
}

function* orderItemErrorWatcherSaga() {
    let action = yield take(actionTypes.PROCESS_ORDER_ITEM_ERROR);
    while (action !== END) {
        yield fork(processOrderItemError, action);
        action = yield take(actionTypes.PROCESS_ORDER_ITEM_ERROR);
    }
}

function* featureErrorsWatcherSaga() {
    let action = yield take(actionTypes.PROCESS_FEATURES_ERROR);
    while (action !== END) {
        yield fork(processFeatureErrors, action);
        action = yield take(actionTypes.PROCESS_FEATURES_ERROR);
    }
}

const watchers = [
    fork(orderItemErrorWatcherSaga),
    fork(featureErrorsWatcherSaga),
];

export { watchers };

export default {};
