/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import AuthProvider from './AuthProvider';
import AuthContext from './auth-context';
import { getAccessTokenSafely } from './auth';
import useAuth from './useAuth';
import withAuth from './withAuth';

/**
 * set to AuthContext and getTokenSafely to window scope,
 * to allow it to be consumed at MFE Checkout this would not be needed
 * once we get auth in its own module
 */
if (typeof window !== 'undefined') {
    window.mbpAuth = {
        AuthContext,
        getAccessTokenSafely,
    };
}

export {
    AuthProvider,
    AuthContext,
    useAuth,
    withAuth,
};

export default {};
