/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';
import { GRAPHQL_ENV } from '..';

/**
 * @param {string} brand - baskets or berries
 * @param {string} locale - english, spanish, yiddish - default: en-us
 */

const getBrandTheme = (brand, locale) => (
    gql`query getTheme {
      findContent(brand : "${brand}", environment: "${GRAPHQL_ENV}", locale: "${locale || 'en-us'}", contentType:"brand_configuration")
        {
          content
        }
      globalHotfix: findContent(brand : "global", environment: "${GRAPHQL_ENV}", contentType:"global_css")
        {
          content
        }
    }`
);
export default getBrandTheme;
