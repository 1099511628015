/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as authActions from './Auth-Actions';
import * as authActionTypes from './Auth-ActionTypes';
import authOperations from './Auth-Operations';
import authReducers from './Auth-Reducer';

const authWatcherSagas = [
    ...authOperations.watchers,
];

export {
    authActions,
    authActionTypes,
    authOperations,
    authWatcherSagas,
};

export default authReducers;
