/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as enterpriseIdActionTypes from './EnterpriseId-ActionTypes';

const initialState = {
    enterpriseId: '',
    enterpriseIdMessage: null,
    error: '',
    loading: false,
    segments: null,
};

const enterpriseIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case enterpriseIdActionTypes.ENTERPRISE_ID_LOADING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case enterpriseIdActionTypes.ENTERPRISE_ID_LOADED:
            return {
                ...state,
                enterpriseId: action.data,
                loading: false,
                error: false,
            };
        case enterpriseIdActionTypes.ENTERPRISE_ID_MESSAGE_LOADED:
            return {
                ...state,
                enterpriseIdMessage: action.data,
            };

        case enterpriseIdActionTypes.LOAD_ENTERPRISE_USER_SEGMENT:
            return {
                ...state,
                segments: action.data,
            };

        case enterpriseIdActionTypes.ENTERPRISE_ID_LOADING_ERROR:
            return {
                ...state,
                error: true,
            };
        case enterpriseIdActionTypes.ENTERPRISE_ID_DISABLE_LOADING:
            return { ...state, loading: false,  error: false };
        default:
            return state;
    }
};

export default enterpriseIdReducer;
