/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import AuthContext from './auth-context';

const withAuth0 = (WrappedComponent) => (props) => (
    <AuthContext>
        {(auth) => (
            <WrappedComponent
                auth={auth}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
            />
        )}
    </AuthContext>
);

export default withAuth0;
