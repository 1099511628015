/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import {
    string, any, object,
} from 'prop-types';
import mbpLogger from 'mbp-logger';
import { getOperatingSystem } from '../../../../state/ducks/App/ducks/Config/Config-Helpers';
import { getAbcChatLink } from '../../../../state/ducks/App/App-Selectors';
import triggerPopChatEvent from '../../../components/MultiBrandChatController/AigoChatWidgetWrapperV2/popOpenChat';

const postMessage = (userData) => {
    try {
        if (
            typeof window !== 'undefined'
            && 'postMessage' in window
        ) {
            triggerPopChatEvent(userData);
        }
    } catch (ex) {
        mbpLogger.logError({
            message: `LinkOpenChat.js postMessage error error = ${ex}`,
            module: 'mbp-pwa-ui',
        });
    }
};

const LinkOpenChat = (props) => {
    const {
        children, customClass, userData, abcChatLink,
    } = props;
    const { message = '', orderNumber = '', intent = '' } = userData || {};
    const operatingSystem = getOperatingSystem().toLowerCase();

    const determineMobileChatLink = () => {
        let mobileChatLink = '';
        if (operatingSystem === 'ios') {
            if (intent) {
                const abcContextEncoded = encodeURIComponent(`biz-intent-id=${intent}&biz-group-id=${orderNumber}&body=${message}`);
                mobileChatLink = intent ? `${abcChatLink}?${abcContextEncoded}` : abcChatLink;
            } else mobileChatLink = abcChatLink;
        }
        return mobileChatLink;
    };

    return (
        operatingSystem === 'android' || operatingSystem === 'ios'
            ? (
                <a
                    href={determineMobileChatLink()}
                    rel="noreferrer"
                    data-testid="mobileLinkOpenChat"
                >
                    {children}
                </a>
            )
            : (
                <span role="presentation" className={`${customClass} link`} onClick={() => postMessage(userData)} data-testid="spanLinkOpenChat">
                    {children}
                </span>
            )
    );
};

LinkOpenChat.defaultProps = {
    customClass: '',
    userData: {},
    abcChatLink: '',
};

LinkOpenChat.propTypes = {
    children: any.isRequired,
    customClass: string,
    userData: object,
    abcChatLink: string,
};

const mapStateToProps = (state) => ({
    abcChatLink: getAbcChatLink(state),
});

export default connect(mapStateToProps)(LinkOpenChat);
