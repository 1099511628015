/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import restClient from './restClient';

const RESOURCE_ROOT_CARTSERVICE = '/checkout';
const RESOURCE_VERSION_CARTSERVICE = '/v2';
const RESOURCE_PAGE_CARTSERVICE = '/cart';
const RESOURCE_PAGE_ACTION = '/calculatecart';

/**
 * calculateCart
 * url - /checkout/{apiversion}/cart/{cartId}/calculatecart?brand={brandId}
 *
 * @param {Object} args
 * @param {String} args.env
 * @param {String} args.jwtToken
 * @param {String} args.cartId
 * @param {Object} args.payload
 * @param {Object} args.headers
 */
const calculateCart = ({
    env, jwtToken, cartId, payload, headers,
}) => {
    const envObj = env || {};

    envObj.rootUri = `${RESOURCE_ROOT_CARTSERVICE}${RESOURCE_VERSION_CARTSERVICE}`;

    const resourcePage = `${RESOURCE_PAGE_CARTSERVICE}/${cartId}${RESOURCE_PAGE_ACTION}`;

    return restClient.putRequest(envObj, resourcePage, jwtToken, null, payload, headers)
        .then((response) => response);
};

export default calculateCart;
