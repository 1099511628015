/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable import/prefer-default-export */
/* eslint-disable object-curly-newline */

/*
    mbp-account-ui redux

    Redux Actions are declared in Action & ActionType.
    You perform a Redux Action by dispatching to EventDispatcher(yourAction(params)).
    Redux Actions are defined in EventDispatcher object like this:
        yourAction: params => {
            type: ActionType.ACTION_NAME_CONSTANT,
            callList: [ middleware methods to call ],      // these are the methods of the Services object, they optionally return data object
            params
        }

    If all middleware methods succeeded, a new action is then dispatched with:
        {
            type: ActionType.ACTION_NAME_CONSTANT with final "/REQUEST" changed to "/LOADED",
            params,
            { ... all data returned by middleware methods }
        }

    If not, a new action is dispatched with type ActionType.ACTION_NAME_CONSTANT with final "/REQUEST" changed to "/FAILURE"

*/

import { takeEvery, call, put, all, fork, select } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import mbpLogger from 'mbp-logger';

// import { actions as tagManagerActions } from '../../../../../state/ducks/TagManager';
// import { decode as htmlDecode } from '../../utils/htmlEntity';
import {
    getActionKey,
    getSuccessType,
    getFailureType,
} from '../reducers/ActionType';
import { deepObjectExtend, isEmpty } from '../../utils/object';
import Services from '../reducers/Services';
import { loadCartCount, loadOrderId } from '../../../../../state/ducks/Order/Order-Actions';
import { getFeatureFlags } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getSSRDeviceType } from '../../../../../state/ducks/App/App-Selectors';
import { getBrand, getLastVisted, getProfileInfo, getIsAuthenticatedStatus } from '../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import * as auth from '../../../../../state/ducks/Member/ducks/Auth/Plugin/auth/auth';
import Helper from '../reducers/Helper';
// import GAEventObject from '../../tealium/GAEventObject';

import configENV from '../../config';
import { loadCart } from '../../../../../state/ducks/Cart/Cart-Actions';

const { ENABLE_CONSOLE_LOG } = configENV;

const mergeAPIResponseToDataObject = (response, data) => {
    if (response && response.length) {
        const { ...myObj } = [...response.map((x) => x.data)];
        const responseData = {};
        Object.keys(myObj).forEach((key) => {
            Object.assign(responseData, myObj[key]);
        });
        deepObjectExtend(data, responseData);
    }
    return data;
};

export function* workerSagas(action) {
    const { callList } = action;
    const type = getSuccessType(action.type);
    const typeError = getFailureType(action.type);
    let data = { ...action };
    delete data.type;
    delete data.callList;
    // Call Auth0 to get new accessToken
    const accessToken =  yield call(auth.getAccessTokenSafely);
    // Get AuthenticatedStatus from state
    const isAuthenticatedStatus = yield select(getIsAuthenticatedStatus);
    // Get profileInfo from state
    const profileInfo = yield select(getProfileInfo);
    // Get featureFlags from state
    const featureFlags = yield select(getFeatureFlags);
    // Get device type from state
    const deviceType = yield select(getSSRDeviceType);
    // Get brand code from state
    const brandDetails = yield select(getBrand);
    const brandCode = brandDetails.code;
    // Get last visited page to route back
    const lastVisited = yield select(getLastVisted);
    // By default we set `writeAccess` value to true for every API call
    // We can set `writeAccess` to false in EventDispatcher for any request that needs only read access
    const { writeAccess = true } = data;
    // Extract scope value from the accessToken
    // expected value:
    // - `openid email profile offline_access read:checkout read:account`
    // - `openid profile email offline_access write:checkout write:account`
    const jwtDecoded = jwtDecode(accessToken);
    const { scope = '' } = jwtDecoded;
    const frontendFlag = (featureFlags?.['is-keep-me-signed-in-redirect-enabled'] === true) || false;
    if (frontendFlag && Helper.isLoginRequired(featureFlags, scope, writeAccess)) {
        return yield call(
            auth.loginWithRedirect,
            { prompt: 'login' },
            { routeBack: lastVisited },
            brandCode,
        );
    }

    const { trifectaUserEmailLookup = false } = data;
    if (trifectaUserEmailLookup && featureFlags?.['is-using-email-trifecta-lookup'] === true) {
        // Try to get user email from jwtToken, sessionStorage, and localStorage if failed from Redux Store
        profileInfo.email = Helper.getUserEmailFromMultipleLocations(isAuthenticatedStatus, accessToken, profileInfo.email, deviceType);
    }

    // Get profile Info and auth info from member state to use that when we got those fileds blank from userProfile
    const userInfo = {
        profile: profileInfo,
        isAuthenticated: isAuthenticatedStatus,
    };

    const services = new Services(null, featureFlags, accessToken, deviceType, userInfo);

    try {
        const actionTypeKey = getActionKey(action.type);

        if (ENABLE_CONSOLE_LOG) {
            console.log('SAGA >>>', actionTypeKey, callList);
        }
        if (data.modal && data.modal.content && data.modal.content.url) {
            const content = yield services.fetchModalContent(data.modal.content.url);
            /* istanbul ignore next */
            data.modal.content = content.data;
        }

        if (callList && callList.length) {
            callList.forEach((x) => {
                if (typeof services[x] !== 'function') {
                    throw new Error('argument fn is undefined');
                }
            });
            const response = yield all(callList.map((x) => call(services[x], data)));
            const backendFlag = featureFlags['is-keep-me-signed-in-backend-redirect-enabled'];
            if (backendFlag && Array.isArray(response)) {
                const actionTypeToBeIgnoredForLogin = ['HANDLE_UPDATE_PASSWORD_REQUEST'];
                const filteredResponseFor401Status = response.filter((res) => res.data?.statusCode === 401);

                if (filteredResponseFor401Status.length >= 1 && actionTypeToBeIgnoredForLogin.indexOf(actionTypeKey) === -1) {
                    return yield call(
                        auth.loginWithRedirect,
                        { prompt: 'login' },
                        { routeBack: lastVisited },
                        brandCode,
                    );
                }
            }
            data = mergeAPIResponseToDataObject(response, data);
        }
        if (ENABLE_CONSOLE_LOG) {
            console.log('SAGA >>>', actionTypeKey, data);
        }

        yield put({ type, data });

        if (
            data.response
            && data.isCartUpdated
            && (
                !isEmpty(data.response.cartId)
                || !isEmpty(data.response.orderId)
            )
        ) {
            yield put(loadOrderId([data.response.cartId || data.response.orderId]));
            const cartCount = data.response.itemsInCart.orderSize || 0;

            yield put(loadCartCount(Number(cartCount)));    // through this updating cart icon value over the gift list add-to-cart

            yield put(loadCart(data.response.itemsInCart));
        }
    } catch (error) {
        if (ENABLE_CONSOLE_LOG) console.log('SAGA >>>', typeError, error);
        const errorMessage = `typeError: ${typeError}, error: ${error}`;
        mbpLogger.logError({
            module: 'mbp-account',
            message: `mbp-account - ${errorMessage}`,
            component: `Saga - ${deviceType}`,
        });
        yield put({ type: typeError, error });
    }
    return null;
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* watcherSagas() {
    yield takeEvery((action) => getActionKey(action.type), workerSagas);
}

export const accountSagas = fork(watcherSagas);
