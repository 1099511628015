/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const RESOURCE_PAGE_FACADE = '/cart';
const RESOURCE_ROOT_FACADE = '/checkout';

/**
 * add item to cart via facade
 * @param {*} wcEnv
 *
 */
const removeOrderItem = (wcEnv, jwtToken, orderItemId, orderId) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE;

    let resourcePage = `${RESOURCE_PAGE_FACADE}/${orderItemId}`;

    if (orderId) {
        resourcePage = `${RESOURCE_PAGE_FACADE}/${orderId}/item/${orderItemId}`;
    }

    return restClient.deleteFacade(env, resourcePage, jwtToken)
        .then((response) => response);
};

export default removeOrderItem;
