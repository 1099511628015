/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as profileActions from './Profile-Actions';
import * as profileActionTypes from './Profile-ActionTypes';
import profileOperations from './Profile-Operations';
import reducers from './Profile-Reducers';

const profileWatcherSagas = [
    ...profileOperations.watchers,
];

export {
    profileActions,
    profileOperations,
    profileActionTypes,
    profileWatcherSagas,
};

export default reducers;
