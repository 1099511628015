/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string, object, shape, oneOfType, bool, number,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useSSRMediaQuery from '../../../../helpers/hooks/useSSRMediaQuery';
import DynamicImagePrefetch from './DynamicImagePrefetch';
import normalizeAltCopy from '../../../../helpers/normalizeAltCopy';

const defaultParams = 'auto=webp'; // if no params & needs to be ?

const defaultBreakpoints = {
    desktop: '1024px',
    tablet: '640px',
    mobile: '200px',
};

/**
 * Styling
 */
const useStyles = makeStyles(() => ({
    rootContainer: ({ rootContainerStyle }) => rootContainerStyle,
    rootContainerWithWidth: ({ fullWidth, rootContainerStyle }) => ({
        width: fullWidth ? '100%' : 'auto',
        ...rootContainerStyle,
    }),
}));

/**
 *
 * @descriptions build path based on inputs.
 * @param {string} path
 * @param {string} qualityParams
 * @param {string} extraParams
 * @returns {string} url
 */
const buildSrc = (path, qualityParams, extraParams) => {
    let output = path;

    if (qualityParams && path && path.includes('personalizationmall')) {
        output = `${output}&${qualityParams}&${extraParams}`;
    } else if (qualityParams) {
        output = `${output}?${qualityParams}&${extraParams}`;
    } else {
        output = `${output}?${extraParams}`;
    }
    return output;
};
// render eagerly unless ssr
const productImageLoading = () => (typeof window !== 'undefined' ? 'eager' : 'lazy');

/**
 * Responsive Image Component
 * @param {string} path
 * @param {string} alt
 * @param {object} params
 * @param {object} breakpoints
 * @param {string} className
 * @param {boolean} isExternalSource
 */
const ResponsiveImage = ({
    path,
    alt,
    role,
    ariaLabel,
    params = {},
    breakpoints = {},
    className,
    style = {},
    isExternalSource,
    fullWidth = false,
    rootContainerStyle,
    dimensions,
}) => {
    const classes = useStyles({
        fullWidth, rootContainerStyle,
    });

    const qualityParams = {
        desktop: params?.desktop || 'quality=100',
        tablet: params?.tablet || 'quality=100',
        mobile: params?.mobile || 'quality=100',
    };

    const desktopBreakpoint = breakpoints?.desktop ?? defaultBreakpoints.desktop;
    const tabletBreakpoint = breakpoints?.tablet ?? defaultBreakpoints.tablet;
    const mobileBreakpoint = breakpoints?.mobile ?? defaultBreakpoints.mobile;

    const deviceType = useSSRMediaQuery(Object.entries(breakpoints) > 0 ? breakpoints : defaultBreakpoints);

    const src = buildSrc(path, qualityParams[deviceType], defaultParams);

    const buildRoleSrc = path && path.includes('personalizationmall') ? `${path}&${defaultParams}` : `${path}?${defaultParams}`;
    /**
     * If things are coming from someone else, dont spam with
     * interally defined parameters in query.
     */
    if (isExternalSource) {
        return (
            <picture className={classes.rootContainer}>
                <source type="image/jpeg" media={`(min-width: ${defaultBreakpoints.desktop})`} srcSet={`${path}`} />
                <source type="image/jpeg" media={`(min-width: ${defaultBreakpoints.tablet})`} srcSet={`${path}`} />
                <source type="image/jpeg" media={`(min-width: ${defaultBreakpoints.mobile})`} srcSet={`${path}`} />
                {/* img fallback if browser doesn't support picture */}
                {role
                    ? (
                        <img style={style} className={className} src={`${path}`} alt={normalizeAltCopy(alt)} role={role} aria-label={ariaLabel} loading={productImageLoading()} />
                    ) : (
                        <img style={style} className={className} src={`${path}`} alt={normalizeAltCopy(alt)}  loading={productImageLoading()} />
                    )}
            </picture>
        );
    }

    return (
        <>
            <DynamicImagePrefetch src={src} />
            <picture className={classes.rootContainerWithWidth}>
                <source
                    type="image/jpeg"
                    media={`(min-width: ${desktopBreakpoint})`}
                    srcSet={buildSrc(path, qualityParams.desktop, defaultParams)}
                />
                <source
                    type="image/jpeg"
                    media={`(min-width: ${tabletBreakpoint})`}
                    srcSet={buildSrc(path, qualityParams.tablet, defaultParams)}
                />
                <source
                    type="image/jpeg"
                    media={`(min-width: ${mobileBreakpoint})`}
                    srcSet={buildSrc(path, qualityParams.mobile, defaultParams)}
                />
                {/* img fallback if browser doesn't support picture */}

                {role
                    ? (
                        <img
                            style={style}
                            className={className}
                            src={buildRoleSrc}
                            alt={normalizeAltCopy(alt)}
                            role={role}
                            aria-label={ariaLabel}
                            loading={productImageLoading()}
                            width={dimensions.width}
                            height={dimensions.height}
                        />
                    ) : (
                        <img
                            style={style}
                            className={className}
                            src={buildRoleSrc}
                            alt={normalizeAltCopy(alt)}
                            loading={productImageLoading()}
                            width={dimensions.width}
                            height={dimensions.height}
                        />
                    )}
            </picture>
        </>

    );
};

// TODO: Default params provided by default props

ResponsiveImage.propTypes = {
    path: string.isRequired,
    alt: string,
    role: string,
    ariaLabel: string,
    params: shape({
        desktop: string,
        tablet: string,
        mobile: string,
    }),
    breakpoints: shape({
        desktop: string,
        tablet: string,
        mobile: string,
    }),
    className: oneOfType([
        string,
        object,
    ]).isRequired,
    style: object,
    isExternalSource: bool,
    fullWidth: bool,
    rootContainerStyle: object,
    dimensions: shape({
        width: string,
        height: number,
    }),
};

ResponsiveImage.defaultProps = {
    alt: '',
    role: '',
    ariaLabel: '',
    params: {},
    breakpoints: {},
    style: {},
    isExternalSource: false,
    fullWidth: false,
    rootContainerStyle: {},
    dimensions: {},
};

export default ResponsiveImage;
