/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import configActionTypes from './Config-ActionTypes';

export const loadConfig = (domain, config) => ({
    type: configActionTypes.CONFIG_LOAD,
    payload: {
        domain,
        config,
    },
});

export const loadFlags = () => ({
    type: configActionTypes.CONFIG_LOAD_FLAG,
});

export const configLoaded = (domain, topic, config) => ({
    type: configActionTypes.CONFIG_LOADED,
    domain,
    topic,
    payload: config,
});

export const configLoadFailed = (exception) => ({
    type: configActionTypes.CONFIG_LOAD_FAILED,
    payload: exception,
});
