/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import { END } from 'redux-saga';
import {
    call, take, put, fork, select,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import * as cgcApis from '../../../apis/checkout-apis/fetchCGCData';
import { getGCIPersonalizationKey, getCGCCardData, getUserSubmittedProductFilterZipcode } from '../App/App-Selectors';
import { getBrand } from '../App/ducks/Brand/Brand-Selectors';
import { showLoadingMessageActions } from '../Common/ducks/ShowLoadingMessage';
import {
    gciPersonalizationComplete,
    gciPersonalizationUpdateStatus,
} from '../Recipient/ducks/GiftMessageForm/GiftMessageForm-Actions';
import {
    setCGCCardData,
    addCGCDataToApi,
    addCGCLabelsDataToApi,
    getCGCCustomCardData,
    setCGCCustomCardData,
    setCGCCardError,
} from './CGC-Actions';
import * as authOperations from '../Member/ducks/Auth/Auth-Operations';
import { setGCIKey } from '../App/App-Actions';
import { getFeatureFlags } from '../App/ducks/Config/Config-Selectors';
import { getIsPassportUserLoggedIn, getMemberLoyalty } from '../Member/ducks/Common/Common-Selectors';
import getPriceFromEngine from '../../../apis/product-apis/priceEngine';
import { trackEvent } from '../TagManager/ducks/TagManager/TagManager-Actions';
import cartServices from '../../../apis/cart-service-apis/cartServices';
import { getOrderId } from '../Checkout/ducks/Global/Global-Selectors';
import { getError } from '../Common/ducks/Error/Error-Actions';
import { processFeatureErrors } from '../Checkout/ducks/Common/ducks/Error/Error-Operations';
import { getActiveABTest } from '../App/ducks/ABTesting/ABTesting-Selectors';

function* workerAddCGCData(data) {
    const {
        cardData, message, cgcData, callBackHandler, pageType,
    } = data;

    try {
        const featureFlags = yield select(getFeatureFlags);
        const jwtToken = yield call(authOperations.checkJWT);
        let cartId = yield select(getOrderId);
        const brand = yield select(getBrand);
        const primaryBrand = brand.domain || '1800flowers';
        const messageStyle = message?.style;
        const brandData = cgcData?.brandData;
        const gciKeyId = yield select(getGCIPersonalizationKey);
        const activeABTests = yield select(getActiveABTest);
        const userSubmittedZip = yield select(getUserSubmittedProductFilterZipcode);

        // Getting string cart id
        if (Array.isArray(cartId)) {
            cartId = cartId[0];
        }

        // add data to Reducer and API
        let response = null;
        if (featureFlags['is-cgc-api-from-main-url']) {
            const env = {};
            const frontView = cardData?.front_view || {};
            const innerView = cardData?.inner_view || {};

            let sku = cardData.sku;
            if (sku && brandData.brand_code && sku.indexOf(brandData.brand_code) < 0) {
                sku = `${brandData.brand_code}-${sku}`;
            }

            const cardObj = {
                cardId: cardData.uid,
                sku,
                parentSku: cgcData.productSku,
                orientation: cardData.card_orientation?.toUpperCase() || 'VERTICAL',
                frontView: {
                    imagePosition: frontView.main_image_position || 'Center',
                    imageOverlayText: frontView.image_overlay_text || '',
                    overlayTextFontFamily: frontView.overlay_text_font_family || 'Arial',
                    overlayTextSize: frontView.overlay_text_size ? `${frontView.overlay_text_size}` : '20',
                    overlayTextAlignment: frontView.overlay_text_alignment || 'Top Center',
                    displayImageUrl: frontView.card_image.url || '',
                    printImageUrl: frontView.print_image_high_res_?.url || '',
                },
                innerView: {
                    messagefontFamily: cgcData.cgcMessage?.style?.fontFamily || innerView.heading_font_family || 'Arial',
                    messageFontSize: innerView.heading_font_size ? `${innerView.heading_font_size}` : '16',
                    messageFontColor: cgcData.cgcMessage?.style?.color || '#000',
                    messageAlignment: innerView.heading_alignment || 'left',
                    messageBody: message?.text || '',
                    messageImage: '',
                    occasion: cgcData.occasionList?.[cardData?.ocassions?.[0]?.uid] || '',
                    signature: '-',
                },
                backView: {
                    copyRightMessage: brandData?.card_back_view_disclaimer || '',
                    brandLogoUrl: brandData?.brand_logo?.url,
                },
            };

            if (cartId && cgcData.orderItemId) {
                response = yield call(cartServices.generateGreetingCard,
                    env, jwtToken, primaryBrand, cartId, cgcData.orderItemId, cgcData.cardType || '', cardData.card_orientation?.toUpperCase() || 'VERTICAL', cardObj,
                );
            }
        } else {
            const backCardAttributes = cgcData?.brandData?.back_card_attributes;
            const innerView = cardData?.inner_view;

            const obj = {
                message_body: message?.text,
                front_logo: brandData?.brand_logo?.url,
                cover_image: cardData?.front_view?.print_image_high_res_?.url,
                pd_guid: gciKeyId,
                message_font_color: messageStyle?.color || '#000',
                message_font_family: messageStyle?.fontFamily || innerView?.heading_font_family || 'Crimson-Text-Regular',
                text_alignment: messageStyle?.textAlign || innerView?.heading_alignment || 'left',
                message_font_size: innerView?.heading_font_size || '16',
                back_card_font_family: backCardAttributes?.font_family || 'Arial',
                back_card_font_size: backCardAttributes?.back_view_font_size || '',
                back_card_font_color: backCardAttributes?.font_color || '#000',
                back_card_disclaimer: brandData?.card_back_view_disclaimer || '',
                back_card_color: backCardAttributes?.background_color || '#fff',
                card_size: `${brandData?.card_size_height}x${brandData?.card_size_width}` || '',
                card_orientation: cardData.card_orientation?.toLowerCase() || '',
                productSku: cgcData.productSku,
                OrdersItemId: cgcData.orderItemId,
                cardSku: cardData.sku,
            };
            response = yield call(cgcApis.fetchCGCCardData, obj);
        }

        if (response.data?.success || response.data?.action === 'cardCreated') {
            let cgcStateData = yield select(getCGCCardData);
            if (cgcStateData.some((stateData) => stateData.orderItemId === cgcData.orderItemId)) {
                cgcStateData = cgcStateData.filter((stateData) => stateData.orderItemId !== cgcData.orderItemId) || [];
            }

            const newStateData = [...cgcStateData, cgcData];
            let responseData = {
                key_id: gciKeyId,
                sku: cardData.sku,
                text: message.text,
                thumbnail: `${cardData?.front_view.card_image?.url}?width=63`,
                action: 'personalizationComplete',
            };

            if (featureFlags['is-cgc-api-from-main-url']) {
                responseData = {
                    key_id: response.data.keyId,
                    sku: cardData.sku,
                    text: response.data.text,
                    thumbnail: `${response.data.thumbnail}?width=63`,
                    action: 'personalizationComplete',
                };
            }

            let priceRules = null;
            let priceRulesResponse = {};

            if (featureFlags['is-loyalty-greeting-card-enabled'] && cardData.sku) {
                const loyaltyStatus = yield select(getMemberLoyalty);
                const passportUser = yield select(getIsPassportUserLoggedIn);

                const payload = {
                    jwtToken,
                    products: [
                        {
                            partNumber: `${brandData.brand_code}-${cardData.sku}`,
                        },
                    ],
                    variants: [],
                };
                if (loyaltyStatus && passportUser) {
                    payload.variants.push(
                        {
                            name: 'LOYALTY_TYPE',
                            value: loyaltyStatus.toUpperCase(),
                        },
                        {
                            name: 'CUSTOMER_TYPE',
                            value: 'PASSPORT',
                        },
                    );
                }

                if (featureFlags['is-zip-dynamic-pricing-enabled'] && activeABTests.zipDynamicPricing) {
                    payload.variants.push({
                        name: 'ZIP_CODE',
                        value: userSubmittedZip,
                    });
                }

                if (payload.variants.length > 0) {
                    priceRulesResponse = yield call(getPriceFromEngine, {}, jwtToken, payload, false);

                    if (priceRulesResponse?.data?.products?.[0]?.priceRules) {
                        priceRules = priceRulesResponse.data.products[0].priceRules;
                    }
                }
            }

            const personalizationCompleteResponse = {
                brandCode: brandData.brand_code.toString(),
                itemType: 'GCI',
                productCode: cardData?.sku,
                response: {
                    responseData: JSON.stringify(responseData),
                },
            };

            if (featureFlags['is-loyalty-greeting-card-enabled'] && priceRules?.length > 0) {
                personalizationCompleteResponse.priceRules = priceRules;
            }

            yield put(gciPersonalizationComplete({
                orderItemId: cgcData.orderItemId,
                personalizationCompleteResponse,
                handleCallBack: data.callBackHandler,
                pageType,
            }));

            yield put(showLoadingMessageActions.updateShowLoadingMessage());

            yield put(setCGCCardData(newStateData));

            const updateGCIPersonalization = yield take(gciPersonalizationUpdateStatus().type);

            if (updateGCIPersonalization?.data?.status && callBackHandler) {
                yield call(callBackHandler);
            }

            if (featureFlags['is-loyalty-greeting-card-enabled'] && priceRules?.length > 0) {
                // Tagging for loyalty personalized card
                yield put(trackEvent({
                    eventCategory: 'Checkout',
                    eventAction: 'Add to cart',
                    eventLabel: 'Personalized Card - Giftastic',
                }));
            }
        }
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        // load error
        yield call(getError, ex, 'giftMessage');

        yield fork(processFeatureErrors, {
            data: {
                orderItemId: cgcData.orderItemId,
                errorFeatureType: 'giftMessage',
                errorValues: ex.response?.data,
            },
        });

        mbpLogger.logError({
            function: 'workerAddCGCData',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            jsError: ex,
            message: 'Failed to save card',
        });

        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    }
}
// worker to add cgcLabelsData
function* workerAddCGCLabelsData(data) {
    const {
        cardData, message, imageConverted,
        customsCardType, customType, fileImage,
        setCGCLabelData, updateCGCCustomsData,
    } = data;

    const messageSetting = cardData?.message_settings;
    const messageStyle = message?.style;
    const messageImageSettings = cardData?.message_image_settings;
    const featureFlags = yield select(getFeatureFlags);
    const jwtToken = yield call(authOperations.checkJWT);
    const brand = yield select(getBrand);
    const primaryBrand = brand.domain || '1800flowers';
    let cartId = yield select(getOrderId);

    // Getting string cart id
    if (Array.isArray(cartId)) {
        cartId = cartId[0];
    }

    // add data to Reducer and API for CGC labels
    try {
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        let response = null;

        if (featureFlags['is-cgc-api-from-main-url']) {
            // set the sku with the code of the brand. example: 1002-PLW1
            let sku = cardData.sku;
            if (sku && brand.id && sku.indexOf(brand.id) < 0) {
                sku = `${brand.id}-${sku}`;
            }

            // Set the card Type according backend values
            let cardType = '';
            if (customType === 'label') {
                if (customsCardType === 'photo') {
                    cardType = 'B2C_PHOTO_LABEL';
                } else if (customsCardType === 'text') {
                    cardType = 'B2C_MSG_LABEL';
                } else if (customsCardType === 'both') {
                    cardType = 'B2C_PHOTO_MSG_LABEL';
                }
            } else if (customType === 'hangtag') {
                if (customsCardType === 'photo') {
                    cardType = 'B2C_HANGTAG_PHOTO';
                } else if (customsCardType === 'text') {
                    cardType = 'B2C_HANGTAG_MESSAGE';
                } else if (customsCardType === 'both') {
                    cardType = 'B2C_HANGTAG_PHOTO_MESSAGE';
                }
            }

            // Call the API to upload the file was modified in the
            // canvas
            let serverImgURL = '';
            if (customsCardType && customsCardType !== 'text' && fileImage) {
                const responseUpload = yield call(cartServices.uploadImageGreetingCard, {
                    wcEnv: {},
                    jwtToken,
                    fileImage,
                });

                if (responseUpload?.data?.imageUrl && cardData) {
                    serverImgURL = responseUpload.data.imageUrl || '';
                }
            }

            const dataPayload = {
                primaryBrand,
                cartId,
                cardType,
                label: {
                    sku,
                    parentSku: cardData.product_sku,
                    orientation: 'vertical',
                    labelHeight: cardData.label_height,
                    labelWidth: cardData.label_width,
                    bleedWidth: cardData.bleed_width,
                    labelImage: cardData.label_image.url || '',
                    containerBorder: cardData.container_border,
                    borderColor: cardData.container_border_color,
                    borderWidth: cardData.container_border_width,
                    content: {
                        width: cardData.content_area_width,
                        height: cardData.content_area_height,
                        label_size: `${cardData.label_width}x${cardData.label_height}`,
                        image: {
                            imageUrl: customsCardType !== 'text' ? serverImgURL : '',
                            imageWidth: cardData.label_width,
                            imageHeight: cardData.label_height,
                            margineWithText: '',
                        },
                        message: {
                            text: customsCardType && customsCardType !== 'photo' ? message?.text : '',
                            fontFamily: messageStyle?.fontFamily || messageSetting?.heading_font_family,
                            fontColor: messageStyle?.color || messageSetting?.message_color,
                            alignment: messageStyle?.textAlign || messageSetting?.heading_alignment,
                            size: messageSetting?.heading_font_size,
                            imageUrl: customsCardType !== 'text' ? serverImgURL : '',
                        },
                    },
                },
            };

            response = yield call(cartServices.createLabelGreetingCard, {
                wcEnv: {},
                jwtToken,
                data: dataPayload,
            });

            if (response?.data?.keyId) {
                const personalizeData = {
                    personalizationType: 'PRODUCT_PERSONALIZATION_GCI',
                    productPersonalization: {
                        sku: cardData.sku,
                        key_id: response.data.keyId,
                        text: response.data.text || '',
                        thumbnail: response.data.thumbnail || '',
                        coverImage: cardData.label_image.url || '',
                        action: 'personalizationComplete',
                        productCode: cardData.sku,
                        brandCode: brand.id,
                    },

                };

                if (setCGCLabelData) {
                    setCGCLabelData(personalizeData);
                }

                if (updateCGCCustomsData) {
                    updateCGCCustomsData(personalizeData.productPersonalization);
                }
            }
        } else {
            const gciKeyId = yield select(getGCIPersonalizationKey);

            const obj = {
                card_type: customType,
                message_body: message?.text,
                font_size: messageSetting?.heading_font_size,
                label_size: `${cardData?.label_width}x${cardData?.label_height}`,
                label_width: cardData?.label_width,
                label_height: cardData?.label_height,
                label_type: customsCardType,
                background_image: cardData?.label_image?.url,
                cover_image: imageConverted || '',
                text_color: messageStyle?.color || messageSetting?.message_color,
                font_family: messageStyle?.fontFamily || messageSetting?.heading_font_family,
                text_alignment: messageStyle?.textAlign || messageSetting?.heading_alignment,
                pd_guid: gciKeyId,
                container_border_color: cardData?.container_border_color,
                container_border_width: cardData?.container_border_width,
                content_area_width: cardData?.content_area_width,
                content_area_height: cardData?.content_area_height,
                bleed_width: cardData?.bleed_width,
                image_width: messageImageSettings?.image_width,
                message_width: messageImageSettings?.message_width,
                space_between_image_and_message: messageImageSettings?.space_between_message_image,
            };

            response = yield call(cgcApis.fetchCGCLabeldData, obj);
        }

        if (response?.data?.success || response?.data?.keyId) {
            yield put(setCGCCardError(''));
            yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        } else {
            mbpLogger.logError({
                function: 'workerAddCGCLabels',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                response,
                message: 'add labels response failed',
            });
            yield put(setCGCCardError('Error! something went wrong, please try again later.'));
        }
    } catch (ex) {
        mbpLogger.logError({
            function: 'workerAddCGCLabels',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            jsError: ex,
            message: 'Failed to Call Api',
        });
        yield put(setCGCCardError('Error! something went wrong, please try again later.'));
    }
}

function* workerGetCGCCustomCardData(keyId) {
    const jwtToken = yield call(authOperations.checkJWT);
    const featureFlags = yield select(getFeatureFlags);

    yield put(setCGCCustomCardData({}));
    yield put(setGCIKey(keyId));

    try {
        let response = null;
        if (featureFlags['is-cgc-api-from-main-url']) {
            response = yield call(cartServices.getLabelGreetingCard, { wcEnv: {}, keyId, jwtToken });
        } else {
            response = yield call(cgcApis.fetchCGCCustomCardData, keyId);
        }

        if (response.status === 200) {
            if (featureFlags['is-cgc-api-from-main-url']) {
                yield put(setCGCCustomCardData(response.data));
            } else {
                yield put(setCGCCustomCardData(response.data.data));
            }
        }
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        mbpLogger.logError({
            function: 'workerGetCGCCustomCardData',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            jsError: ex,
            message: 'Failed to Call Api',
        });
        yield put(setCGCCardError('Error! something went wrong, please try again later.'));
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    }
}

function* watchAddCGCData() {
    let action = yield take(addCGCDataToApi().type);

    while (action !== END) {
        yield fork(workerAddCGCData, action.payload);
        action = yield take(addCGCDataToApi().type);
    }
}
function* watchAddCGCLabelsData() {
    let action = yield take(addCGCLabelsDataToApi().type);

    while (action !== END) {
        yield fork(workerAddCGCLabelsData, action.payload);
        action = yield take(addCGCLabelsDataToApi().type);
    }
}

function* watchGetCGCCustomCardData() {
    let action = yield take(getCGCCustomCardData().type);

    while (action !== END) {
        yield fork(workerGetCGCCustomCardData, action.payload);
        action = yield take(getCGCCustomCardData().type);
    }
}
const watchers = [fork(watchAddCGCData), fork(watchAddCGCLabelsData), fork(watchGetCGCCustomCardData)];
export default watchers;
