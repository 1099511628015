/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import listPickerImageConstantsArray from './listPickerConstants';
import foodConstantArray from './FoodConstants';
import firtsListPickerConstantArray from './FirstListPickerConstants';
import locationConstantArray from './LocationsConstants';
import orderTypeConstantArray from './OrderTypeConstants';

const imagesConstantArray = [...listPickerImageConstantsArray, ...foodConstantArray, ...firtsListPickerConstantArray, ...locationConstantArray, ...orderTypeConstantArray];

export default imagesConstantArray;
