/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as AddDonationActionTypes from './Donation-ActionTypes';

export const addDonation = (data) => ({
    type: AddDonationActionTypes.ADD_DONATION_ITEM,
    data,
});
export const loadAddToDonationError = (error) => ({
    type: AddDonationActionTypes.ADD_DONATION_ITEM_ERROR,
    data: {
        error,
    },
});
