/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    loadGooglePayClientId,
    clearGooglePayClientId,
    useAnotherGooglePayAccount,
} from './GooglePay-Actions';

const initialState =         {
    changeAccount: false,
    clientToken: '',
};

const googlePayReducer = (state = initialState, action) => {
    switch (action.type) {
        case useAnotherGooglePayAccount().type:
            return {
                ...state,
                changeAccount: action.data,
            };
        case loadGooglePayClientId().type:
            return {
                ...state,
                clientToken: action.data,
            };
        case clearGooglePayClientId().type:
            return {
                ...state,
                clientToken: '',
            };
        default:
            return state;
    }
};

export default googlePayReducer;
