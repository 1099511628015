/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const RESOURCE_ROOT_FACADE = '/checkout';
const RESOURCE_PAGE_FACADE = '/payment';
const RESOURCE_PAGE_ACTION = '/express/visaCheckout';

const processVisaCheckout = (wcEnv, jwtToken, orderId, visaCallId, encryptedKey, encryptedPaymentData, params) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE;

    const resourcePage = `${RESOURCE_PAGE_FACADE}/${orderId}${RESOURCE_PAGE_ACTION}`;

    const payload = {
        visaCallId,
        encryptedKey,
        encryptedPaymentData,
    };

    return restClient.postFacade(env, resourcePage, jwtToken, payload, params)
        .then((response) => response);
};

export default processVisaCheckout;
