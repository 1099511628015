/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { END } from 'redux-saga';
import {
    take, takeEvery, fork, call, select, put, takeLatest,
} from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import mbpLogger from 'mbp-logger';
import * as checkoutActions from '../Checkout/Checkout-Actions';
import {
    billingClearUpdateFields,
    triggerStateCityData,
    updateBillingForm,
    updateCheckBillingAddressSameForm,
} from './ducks/BillingAddressForm/BillingAddressForm-Actions';
import { paymentFormActions } from './ducks/PaymentForm';
import { errorActions, errorOperations } from '../../../Common/ducks/Error';
import { showLoadingMessageActions } from '../../../Common/ducks/ShowLoadingMessage';
import { clearPaypalState } from './plugins/Paypal/Paypal-Actions';
import { retrieveCart } from '../../../Cart/Cart-Operations';
import {
    placeOrder,
    clickStreamOrderComplete,
    updateShowOrderConfirmation,
    applyGiftCard,
    applyGiftCardProccessCompleted,
    removeGiftCard,
    removeGiftCardProccessCompleted,
    postOrderCompleteAttributionData,
} from './Payment-Actions';
import * as commonSelectors from '../../../Common/Common-Selectors';
import { getBrand } from '../../../App/ducks/Brand/Brand-Selectors';
import { resetCGCCardData } from '../../../CGC/CGC-Actions';
import { callHandleCartFailure } from '../Cart/Cart-Actions';
import { removePersistPageDataFromStorage } from '../../../../../app/helpers/EnterpriseID/CustomerDataPersistance/customerDataPersistance';

import enterpriseIdDuck from '../../../Member/ducks/EnterpriseId/EnterpriseId-Operations';
import { processCgcGreetingCard, processCGCGreetingCardAPI } from '../../../../../apis/checkout-apis/fetchCGCData';
import orderClient from '../../../../../apis/checkout-apis/orderClient';
import postChatEventsWithEID from '../../../../../apis/enterpriseid-apis/chatEventEnterpriseId';
import { orderCompleteAttributionData } from '../../../../../app/helpers/attribution/attributionHelpers';
import { getPaypalTokenizedCard } from './plugins/Paypal/Paypal-Selectors';
import { checkJWT } from '../Global/Global-Operations';
import { promotionActions } from './ducks/Promotion';
import { processOrderItemError } from '../Common/ducks/Error/Error-Operations';
import { getFeatureFlags } from '../App/App-Selectors';
import { getTokenLocalStorage } from '../../../Member/ducks/Auth/helper/helper';
import { profileActions } from '../../../Member/ducks/Profile';
import { unsubscribeEmail, unsubscribeOrderStatus, unsubscribeTextmsg } from './ducks/Subscription/Subscription-Action';
import { cartStatusCompleteList } from '../../../../../app/helpers/common/helper';

const getStateCityData = ({
    jwtToken, zipCode,
}) => orderClient.getCityStateFromZip(
    {},
    jwtToken,
    zipCode,
);

const applyGCOnOrder = ({
    JWT_TOKEN,
    orderId,
    giftcardNumber,
    giftcardPin,

}) => orderClient.applyGiftCard({}, JWT_TOKEN, orderId, giftcardNumber, giftcardPin, '', '', '', '');

const removeGCOnOrder = ({
    JWT_TOKEN,
    orderId,
    giftcardNumber,
    giftCardId,

}) => orderClient.removeGiftCard({}, JWT_TOKEN, orderId, giftcardNumber, giftCardId);

const parseBannerCookie = () => {
    const cookies = new Cookies();
    const bCookie = cookies.get('banner');

    if (bCookie) {
        const bAttr = JSON.parse(JSON.stringify(bCookie));

        mbpLogger.logInfo({
            bCookie,
            function: 'parseBannerCookie',
            appName: process.env.npm_package_name,
            module: 'mbp-checkout :: checkout',
            message: 'Parsed banner cookie for order upload',
        });

        return bAttr;
    }
    return '';
};

const processCelebrationsGreetingCard = async (data, token, isMainUrlEnabled) => {
    try {
        let response = null;
        if (isMainUrlEnabled) {
            response = await processCgcGreetingCard(data, token);
        } else {
            response = await processCGCGreetingCardAPI(data);
        }
        if (!response.status === 200) {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                function: 'processCelebrationsGreetingCard',
                message: `card-placed call failed with response ${response.status}`,
                data,
                response,
            });
        }
        return response;
    } catch (er) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'processCelebrationsGreetingCard',
            message: 'card-placed call failed with error',
            data,
            error: er,
        });
        return er;
    }
};

function* persistBanner() {
    const logOrderId = yield select(commonSelectors.getOrderId);
    const featureFlags = yield select(getFeatureFlags);

    try {
        const bannerObj = parseBannerCookie();
        if (bannerObj?.c) {
            if (featureFlags['is-checkout-monitoring-enabled']) {
                mbpLogger.logError({
                    appName: process.env.npm_package_name,
                    module: 'PlaceOrder-Operations.js',
                    function: 'persistBanner',
                    errorMessage: bannerObj,
                    message: `Call Persist Banner - ${logOrderId}`,
                });
            }
            yield put(checkoutActions.saveBannerToOrder(bannerObj.c, bannerObj.l)); // c = bannerCode, l= lsid
            yield take(checkoutActions.saveBannerProcessCheckCompleted().type);
        }
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'PlaceOrder-Operations.js',
            function: 'persistBanner',
            jsError: ex,
            errorMessage: ex.response,
            message: `PersistBanner Failed - ${logOrderId} - Error occured while parsing banner`,
        });
    }
}

const removeMaskForPhoneNumber = (number) => {
    let strNumber = number.toString().trim();
    strNumber = strNumber.replace(/\s/g, '').replace(/[^0-9+]/g, '');
    if (strNumber.indexOf('+') === 0 && strNumber.indexOf('1') === 1) {
        strNumber = strNumber.substring(2, strNumber.length);
    } else if (strNumber.indexOf('+') >= 0) {
        strNumber = strNumber.replace(/\s/g, '').replace(/[^0-9]/g, '');
    }
    return strNumber;
};

const removeMaskForAccountNumber = (number) => {
    const strAccountNumber = number.toString();
    if (strAccountNumber.indexOf('-') >= 0) {
        return strAccountNumber.split('-').join('');
    }
    return strAccountNumber;
};

const emptyBilling = () => ({
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    address3: '',
    addressId: '',
    city: '',
    email: '',
    confirmEmail: '',
    country: '',
    locationType: '',
    organizationName: '',
    phone: '',
    mobilePhone: '',
    state: '',
    zipCode: '',
});

function* buildConfigObj({
    disablePaymentFields,
    paypalPayment,
    visaCheckoutPayment,
    params,
    venmoPayment,
    klarnaPayment,
}) {
    let configObj = {};
    const logOrderId = yield select(commonSelectors.getOrderId);
    const flagState = yield select(getFeatureFlags);

    try {
        if (flagState['is-checkout-monitoring-enabled']) {
            mbpLogger.logError({
                function: 'buildConfigObj',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                message: 'buildConfigObj - Start',
                orderId: logOrderId,
            });
        }

        const paymentMethod = yield select(commonSelectors.getPaymentMethod);
        const jwtToken = yield call(checkJWT);
        const isSmsOptIn = yield select(commonSelectors.getSMSOptIn);

        configObj = {
            orderId: yield select(commonSelectors.getOrderId),
            jwtToken,
            billingInfo: yield select(commonSelectors.getBillingInfo),
            orderTotal: yield select(commonSelectors.getAmount),
            user: yield select(commonSelectors.getSubscription),
        };

        // Add Passport
        if (yield select(commonSelectors.getPassportSubscriptionStatus)) {
            configObj.orderTotal.passportTermsAndConditionChecked = 'Y';
        }

        // Unmask Phone Number
        if (configObj.billingInfo.phone) {
            configObj.billingInfo.phone = removeMaskForPhoneNumber(configObj.billingInfo.phone);
        }

        // Unmask Mobile Phone
        if (configObj.billingInfo.mobilePhone) {
            configObj.billingInfo.mobilePhone = removeMaskForPhoneNumber(configObj.billingInfo.mobilePhone);
        }

        if (flagState['is-checkout-monitoring-enabled']) {
            mbpLogger.logError({
                function: 'buildConfigObj',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                message: `Payment Method - ${paymentMethod.id}`,
                orderId: logOrderId,
            });
        }

        // Add params to configObj
        configObj.params = params || {};
        if (disablePaymentFields === false) {
            const paymentInfo = yield select(commonSelectors.getPaymentInfo);
            configObj.paymentInfo = { ...paymentInfo };

            if (paymentMethod.id === 'PayPal') {
                configObj.paymentInfo = {};
                // Set payment method accepted by place order
                configObj.paymentInfo.paymentMethod = 'paypal';
                const tokenizedPaypalCard = yield select(getPaypalTokenizedCard);
                if (paypalPayment.isTokenizedPaypalCard && tokenizedPaypalCard) {
                    configObj.paymentInfo.accountNumber = tokenizedPaypalCard;
                    configObj.paymentInfo.isCardTokenized = true;
                } else if (paypalPayment.nonce) {
                    configObj.paymentInfo.accountNumber = paypalPayment.nonce;
                }
            } else if (paymentMethod.id === 'Venmo' || venmoPayment?.nonce) {
                configObj.paymentInfo = {};
                configObj.paymentInfo.paymentMethod = 'venmo';
                configObj.paymentInfo.accountNumber = venmoPayment.nonce;
            } else if (paymentMethod.id === 'ChasePay') {
                // Set payment method accepted by place order
                configObj.paymentInfo.paymentMethod = 'CHASECheckout';
                const emptyBillingAddress = yield call(emptyBilling);
                if (isSmsOptIn) {
                    emptyBillingAddress.mobilePhone = configObj.billingInfo.mobilePhone;
                }
                configObj.billingInfo = emptyBillingAddress;
            } else if (paymentMethod.id === 'VisaCheckout' && visaCheckoutPayment === true) {
                // Set payment method accepted by place order
                configObj.paymentInfo.paymentMethod = 'visacheckout';
                const emptyBillingAddress = yield call(emptyBilling);
                if (isSmsOptIn) {
                    emptyBillingAddress.mobilePhone = configObj.billingInfo.mobilePhone;
                }
                configObj.billingInfo = emptyBillingAddress;
            } else if (paymentMethod.id === 'Klarna' && klarnaPayment?.authorizeCode) {
                configObj.paymentInfo = {};
                configObj.paymentInfo.paymentMethod = 'Klarna';
                configObj.paymentInfo.accountNumber = klarnaPayment.authorizeCode;
            } else {
                if (flagState['is-checkout-monitoring-enabled']) {
                    mbpLogger.logError({
                        function: 'buildConfigObj',
                        appName: process.env.npm_package_name,
                        module: 'mbp-pwa-ui',
                        message: 'Payment Method - Creditcard',
                        orderId: logOrderId,
                    });
                }

                configObj.paymentInfo.isCardTokenized = false;
                if (paymentInfo && paymentInfo.isFromWallet) {
                    configObj.paymentInfo.isCardTokenized = true;
                }

                configObj.paymentInfo.paymentMethod = 'creditcard';
                configObj.paymentInfo.accountNumber = removeMaskForAccountNumber(configObj.paymentInfo.accountNumber);

                const amount = yield select(commonSelectors.getAmount);
                configObj.paymentInfo.amount = yield amount.orderTotal;

                if (!configObj.paymentInfo.isCardTokenized) {
                    configObj.paymentInfo.lastFourDigits = configObj.paymentInfo.accountNumber.substr(configObj.paymentInfo.accountNumber.length - 4);
                }

                // If the reward points have information, send it in the payment information
                const rewardPointsInfo = yield select(commonSelectors.getRewardPointsInfo);
                if (rewardPointsInfo
                    && rewardPointsInfo.selected
                    && rewardPointsInfo.selected.amount
                    && rewardPointsInfo.selected.points
                ) {
                    configObj.paymentInfo.rewards = rewardPointsInfo.selected;
                }

                if (configObj.paymentInfo
                    && configObj.paymentInfo.expirationMonth
                    && parseInt(configObj.paymentInfo.expirationMonth, 10) < 10) {
                    const twoDigitMonth = `0${parseInt(configObj.paymentInfo.expirationMonth, 10)}`;
                    configObj.paymentInfo.expirationMonth = twoDigitMonth;
                }
                if (configObj.paymentInfo
                    && configObj.paymentInfo.expirationYear
                    && parseInt(configObj.paymentInfo.expirationYear, 10) < 100) {
                    const fourDigitYear = `20${configObj.paymentInfo.expirationYear}`;
                    configObj.paymentInfo.expirationYear = fourDigitYear;
                }
            }
        } else {
            if (flagState['is-checkout-monitoring-enabled']) {
                mbpLogger.logError({
                    function: 'buildConfigObj',
                    appName: process.env.npm_package_name,
                    module: 'mbp-pwa-ui',
                    message: 'Gift Message Covers Order Total',
                    orderId: logOrderId,
                });
            }
            // Gift Message Covers Order Total
            configObj.paymentInfo = {
                paymentMethod: 'GiftCard',
            };
        }
        if (flagState['is-checkout-monitoring-enabled']) {
            mbpLogger.logError({
                function: 'buildConfigObj',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                message: 'buildConfigObj - End - payload config obj prepared',
                orderId: logOrderId,
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'buildConfigObj',
            jsError: ex,
            message: 'buildConfigObj Failed',
            orderId: logOrderId,
        });
    }

    return configObj;
}

const checkIsReviewOrderPage = () => {
    let isReviewOrderPage = false;

    if (typeof window !== 'undefined') {
        if (window.location.href.indexOf('/checkout/review-order') >= 0) {
            isReviewOrderPage = true;
        }
    }

    return isReviewOrderPage;
};

const checkIsPaymentPage = () => {
    let isPaymentPage = false;

    if (typeof window !== 'undefined') {
        if (window.location.href.indexOf('/checkout/payment') >= 0) {
            isPaymentPage = true;
        }
    }

    return isPaymentPage;
};

const orderItemLevelErrors = (errorList = []) => errorList.filter((error) => ((Number(error.errorKey) > 0)));

const updateBillingAddressInAddressBook = () => ({
    type: 'MFE/CHECKOUT/UPDATE_BILLING_ADDRESS_IN_ADDRESS_BOOK',
});

function* onPlaceOrder(action) {
    const logOrderId = yield select(commonSelectors.getOrderId);
    const flagState = yield select(getFeatureFlags);

    try {
        const {
            history, source,
            disablePaymentFields,
            params,
            paypalPayment,
            visaCheckoutPayment,
            googlePayment,
            venmoPayment,
            klarnaPayment,
            recaptcha,
        } = action.payload;
        if (flagState['is-checkout-monitoring-enabled']) {
            mbpLogger.logError({
                function: 'onPlaceOrder',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                message: 'onPlaceOrder | START',
                orderId: logOrderId,
            });
        }

        // If the banner exist in the cookie.
        yield call(persistBanner);

        /**
         * Update user billing adddress if it is login
         * in address book, after order is placed
         * the worker saga is in mbp-checkout
         *
         * TODO :: need to replace once place order saga is moved in mbp-checkout
         */
        yield put(updateBillingAddressInAddressBook());

        // Associate EnterpriseId with guest Email address
        if (flagState['is-enterprise-id-update-enabled']) {
            yield call(enterpriseIdDuck.workers.workerSagaUpdateEnterpriseId);
        }

        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        yield put(errorActions.clearErrors({
            errType: 'paymentClearAllFields',
            field: 'error',
        }));

        const configObj = yield call(buildConfigObj, {
            disablePaymentFields,
            paypalPayment,
            visaCheckoutPayment,
            params,
            googlePayment,
            venmoPayment,
            klarnaPayment,
        });

        if (source && configObj.params) {
            configObj.params.sourceApp = source;
        }
        if (flagState['is-checkout-monitoring-enabled']) {
            mbpLogger.logError({
                function: 'onPlaceOrder',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                message: 'call - placeOrder API',
                orderId: logOrderId,
            });
        }

        // Place Order API Call
        const placeOrderResponse = yield call(
            orderClient.placeOrder,
            {},
            configObj.jwtToken,
            configObj.orderId,
            configObj.params,
            configObj.billingInfo,
            configObj.paymentInfo,
            configObj.orderTotal,
            configObj.user,
            recaptcha,
        );

        if (flagState['is-checkout-monitoring-enabled']) {
            mbpLogger.logError({
                function: 'onPlaceOrder',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                message: 'placeOrder API Response',
                orderId: logOrderId,
            });
        }

        if (placeOrderResponse.data.successFlag === true || placeOrderResponse.data.successFlag === 'true') {
            if (source === 'PWA-DESKTOP' || source === 'PWA' || source === 'SPC') {
                yield put(paymentFormActions.paymentClearUpdateFields());
                yield put(billingClearUpdateFields());
                yield put(updateCheckBillingAddressSameForm(false));
                yield put(clearPaypalState());
            }
            // Get Cart
            yield call(retrieveCart);

            const orderStatus = yield select(commonSelectors.getOrderStatus);
            if (orderStatus && cartStatusCompleteList.includes(orderStatus)) {
                const brand = yield select(getBrand);
                yield put(clickStreamOrderComplete(configObj.paymentInfo));
                // clear banner codes attemps when an order is completed
                yield put(promotionActions.setBannerCodesAttempted([]));
                yield put(updateShowOrderConfirmation());

                yield put(unsubscribeOrderStatus());
                yield put(unsubscribeTextmsg());
                yield put(unsubscribeEmail());

                // Delete persistPageData from storage once user has purchased what they were looking for
                if (flagState['is-personalization-recently-viewed-products-enabled']) {
                    yield call(removePersistPageDataFromStorage);
                }

                if (history && (source === 'PWA-DESKTOP' || source === 'PWA')) {
                    // send attribution data to events api for reporting
                    const attributionData = yield call(orderCompleteAttributionData, configObj.orderId, configObj.orderTotal, brand?.code, flagState);
                    if (attributionData) {
                        yield put(postOrderCompleteAttributionData(attributionData));
                    }
                    history.push(`/checkout/order-confirmation/${configObj.orderId}`);
                }

                if (flagState['is-celebration-card-enabled-checkout']) {
                    const greetingCardData = yield select(commonSelectors.getGreetingCards);
                    if (greetingCardData?.length) {
                        const confirmCard = yield call(processCelebrationsGreetingCard, greetingCardData, configObj.jwtToken, flagState['is-cgc-api-from-main-url']);
                        if (confirmCard?.data?.message !== 'Path updated') {
                            mbpLogger.logError({
                                function: 'onPlaceOrder',
                                appName: process.env.npm_package_name,
                                module: 'mbp-pwa-ui',
                                message: 'Failed updating celebrations card',
                                cardData: greetingCardData,
                                cgcResponse: confirmCard,
                            });
                        }
                        yield put(resetCGCCardData());
                    }
                }
                if (flagState['is-celebration-card-enabled-pdp']) {
                    const labelData = yield select(commonSelectors.getLabelData);
                    if (labelData?.length) {
                        const confirmCard = yield call(processCelebrationsGreetingCard, labelData, configObj.jwtToken, flagState['is-cgc-api-from-main-url']);
                        if (confirmCard?.data?.message !== 'Path updated') {
                            mbpLogger.logError({
                                function: 'onPlaceOrder',
                                appName: process.env.npm_package_name,
                                module: 'mbp-pwa-ui',
                                message: 'Failed updating label and hangtags',
                                cardData: labelData,
                                cgcResponse: confirmCard,
                            });
                        }
                    }
                }

                // update membershipType and userRole in localStorage after purchasing passport order
                const isPassportOrder = yield select(commonSelectors.isPassportOrder);
                const userInfoLocalStorage = getTokenLocalStorage('userInfo');
                if (isPassportOrder && userInfoLocalStorage) {
                    const objUserInfo = JSON.parse(userInfoLocalStorage);
                    objUserInfo.profile.membershipType = 'P';
                    objUserInfo.profile.userRole = 'P';
                    yield put(profileActions.setProfileDetails(objUserInfo.profile));
                }
            }
        } else {
            yield put(paymentFormActions.paymentUpdateFormStatus(false));
            yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
            throw new Error(`${placeOrderResponse.data}`);
        }
    } catch (ex) {
        mbpLogger.logError({
            function: 'onPlaceOrder',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: 'Failed onPlaceOrder',
            jsError: ex,
            orderId: logOrderId,
        });

        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        const validationErrors = ex.response?.data?.detailedError?.validationErrors;
        const orderItemErrors = orderItemLevelErrors(validationErrors);

        if (orderItemErrors.length > 0) {
            yield fork(processOrderItemError, {
                data: {
                    orderItemId: logOrderId,
                    errorFeatureType: 'recipient',
                    errorValues: ex.response?.data,
                },
            });
        }

        yield call(errorOperations.getError, ex, 'payment');
        const IsPaymentHasError = yield select(commonSelectors.getIsPaymentHasError);
        const isShippingHasErrors = yield select(commonSelectors.getIsRecipientHasError);
        const { history } = action.payload;

        // Let the user in review page if the issue is in the product item
        // other wise we sent the user back to payment page
        if (isShippingHasErrors && (checkIsReviewOrderPage() || checkIsPaymentPage())) {
            history.push(`/checkout/shipping/${logOrderId}`);
        } else if (IsPaymentHasError && checkIsReviewOrderPage()) {
            history.push(`/checkout/payment/${logOrderId}`);
        }

        yield put(paymentFormActions.paymentUpdateFormStatus(false));
    }
}

function* applyGC({ giftcardNumber, giftcardPin }) {
    try {
        // Calculate Shipping Befor Apply GC
        yield put(checkoutActions.calculateShipping());
        yield take(checkoutActions.calculateShippingComplete().type);
        // Show Site Loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        // Clear Error
        yield put(errorActions.clearErrors({
            errType: 'giftCard',
            field: 'error',
        }));

        const JWT_TOKEN = yield call(checkJWT);
        const orderId = yield select(commonSelectors.getOrderId);
        const applyGcResult = yield call(applyGCOnOrder, {
            JWT_TOKEN,
            orderId,
            giftcardNumber,
            giftcardPin,
        });

        if (applyGcResult.data.successFlag === 'true') {
            yield call(retrieveCart);
        } else {
            // To do : parse the error and show appropriate message to custome
            yield call(errorOperations.getError, 'Gift Card Could not be applied', 'giftCard');
        }

        // Hide Site Loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (exp) {
        yield put(callHandleCartFailure(exp));

        const orderId = yield select(commonSelectors.getOrderId);

        // Hide Site Loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());

        yield call(errorOperations.getError, exp, 'giftCard');

        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'applyGC',
            jsError: exp,
            message: 'Failed to apply Gift Card',
            orderId,
        });
    }
    yield put(applyGiftCardProccessCompleted());
}

function* removeGC({ giftcardNumber, giftCardId }) {
    try {
        // Show Site Loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        // Clear Error
        yield put(errorActions.clearErrors({
            errType: 'giftCard',
            field: 'error',
        }));

        const JWT_TOKEN = yield call(checkJWT);
        const orderId = yield select(commonSelectors.getOrderId);
        const removeGcResult = yield call(removeGCOnOrder, {
            JWT_TOKEN,
            orderId,
            giftcardNumber,
            giftCardId,
        });

        if (removeGcResult.data.successFlag === 'true') {
            yield call(retrieveCart);
        }

        // Hide Site Loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (exp) {
        yield put(callHandleCartFailure(exp));

        const orderId = yield select(commonSelectors.getOrderId);

        // Load Error Message
        yield call(errorOperations.getError, exp, 'giftCard');

        // Hide Site Loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());

        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'removeGC',
            jsError: exp,
            message: 'Failed to remove Gift Card',
            orderId,
        });
    }
    yield put(removeGiftCardProccessCompleted());
}

function* placeOrderWatcher() {
    let action = yield take(placeOrder().type);
    while (action !== END) {
        yield call(onPlaceOrder, action);
        action = yield take(placeOrder().type);
    }
}

function* applyGiftCardWatcher() {
    let action = yield take(applyGiftCard().type);
    while (action !== END) {
        yield call(applyGC, action.payload);
        action = yield take(applyGiftCard().type);
    }
}

function* removeGiftCardWatcher() {
    let action = yield take(removeGiftCard().type);
    while (action !== END) {
        yield call(removeGC, action.payload);
        action = yield take(removeGiftCard().type);
    }
}

const isCandaZip = (zipcode) => new RegExp(/^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[- ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}/).test(zipcode);

function* billingFormTriggerStateCityData(action) {
    try {
        let formZipCode = action.data.trim();
        let labelZip = 'Zip Code';
        let checkZip = false;

        if ((formZipCode.length === 6 || formZipCode.length === 7) && isCandaZip(formZipCode)) {
            checkZip = true;
            const zipSplit = formZipCode.replace(/\s/gi, '').toUpperCase().split('');
            zipSplit.splice(3, 0, ' ');
            formZipCode = zipSplit.join('');
            labelZip = 'Postal Code';
        } else if (formZipCode.length >= 5) {
            checkZip = true;
        }

        const jwtToken = yield call(checkJWT);
        const reqObj = {
            jwtToken,
            zipCode: formZipCode,
        };

        yield put(errorActions.loadError({ errType: 'payment', errMsg: { zipCode: '' } }));

        if (reqObj && reqObj.zipCode && checkZip) {
            const resStateCityData = yield call(getStateCityData, reqObj);
            const cityStateDate = resStateCityData?.data?.esbSaltaServiceResponse;

            if (cityStateDate && cityStateDate.checkZipResponse
                && cityStateDate.checkZipResponse.checkZipResult
                && cityStateDate.checkZipResponse.checkZipResult.flwsErrors) {
                yield put(errorActions.loadError({ errType: 'payment', errMsg: { zipCode: `${'Invalid '}${labelZip}` } }));
            }

            if (cityStateDate && cityStateDate.checkZipResponse
                && cityStateDate.checkZipResponse.checkZipResult
                && cityStateDate.checkZipResponse.checkZipResult.location
                && cityStateDate.checkZipResponse.checkZipResult.location.state) {
                const {
                    checkZipResponse: {
                        checkZipResult: {
                            location: {
                                state,
                                country,
                                cityList: {
                                    city,
                                },
                                zipCode,
                            },
                        },
                    },
                } = cityStateDate;
                const cityList = [
                    ...new Set(city),
                ];

                yield put(errorActions.loadError({ errType: 'payment', errMsg: { zipCode: '' } }));

                if (country === 'CAN' || country === 'USA') {
                    yield put(updateBillingForm({
                        country: country.slice(0, -1),
                        state,
                        cityArray: cityList,
                        city: cityList[0].toLowerCase().replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()),
                        zipCode,
                    }));
                } else {
                    yield put(updateBillingForm({
                        state,
                        cityArray: cityList,
                        city: cityList[0].toLowerCase().replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()),
                        zipCode,
                    }));
                }
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            functio: 'billingFormTriggerStateCityData',
            jsError: ex,
        });
    }
}

function* watcherTriggerStateCityData() {
    yield takeLatest(triggerStateCityData().type, billingFormTriggerStateCityData);
}

function* postAttributionDataOnOrderComplete(action) {
    const attrData = action.payload;
    const token = yield call(checkJWT);
    const featureFlags = yield select(getFeatureFlags);
    if (attrData) {
        try {
            yield call(postChatEventsWithEID, { ...attrData, token, featureFlags });
        } catch (e) {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                jsError: e,
                message: 'postAttributionDataOnOrderComplete error',
            });
        }
    }
}

function* postAttributionDataOnOrderCompleteWatcher() {
    yield takeEvery(postOrderCompleteAttributionData().type, postAttributionDataOnOrderComplete);
}

// Payment Plugins
// Watcher Sagas
const watchers = [
    fork(placeOrderWatcher),
    fork(applyGiftCardWatcher),
    fork(removeGiftCardWatcher),
    fork(watcherTriggerStateCityData),
    fork(postAttributionDataOnOrderCompleteWatcher),
];

export {
    watchers,
    onPlaceOrder,
};
