/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { decodeHTML } from 'entities';
import QueryString from 'qs';

import mbpLogger from 'mbp-logger';

import { getDataLayerActionEntry } from './pageDataLayerCommonHelpers';
import { generateCheckoutPageDataLayer } from './pageDataLayerCheckoutHelpers';
import { generateMyAccountDataLayer } from './pageDataLayerMyAccountHelpers';

// const generatetePageViewEvents = ({
//     productImpArrays,
// }) => {
//     const events = [];
//     const eventParams = {
//         eventCategory: 'Site Wide',
//         eventAction: 'Large Request',
//         nonInteraction: '1',
//     };

//     // Provide array of events
//     events.push({
//         ...eventParams,
//         ...productImpArrays,
//     });

//     return events;
// };

// Build data layer for Product page
const generateProductPageDataLayer = (page, pageDataLayer) => {
    const { location, dataLayer } = page;
    let { product } = getDataLayerActionEntry('track_product_data', dataLayer) || {};
    const { passportData } = getDataLayerActionEntry('track_passport_data', dataLayer) || {};
    const { items } = getDataLayerActionEntry('track_cyo_data', dataLayer) || {};
    const baseDataLayer = { ...pageDataLayer };
    let movieSkus = [];
    const { modalData } = getDataLayerActionEntry('track_NBCU_data', dataLayer) || {};
    const redirectForSku = location?.search && location.search.includes('redirect-for-sku');
    if (modalData?.products?.length > 0) {
        movieSkus = modalData?.products;
    }

    const track404Data = getDataLayerActionEntry('track_404_data', dataLayer) || null;
    // track_404_data
    if (track404Data) {
        product = {};
        product.partNumber = track404Data.page?.code || '';
    }

    if (!product && !track404Data) {
        mbpLogger.logError({
            page,
            product,
            dataLayer,
            function: 'generateProductPageDataLayer',
            appName: process.env.npm_package_name,
            module: 'mbp-page',
            message: 'Missing required params [product]',
        });

        return false;
    }

    try {
        baseDataLayer.pageType = 'product';
        baseDataLayer.pageName = 'Product';
        baseDataLayer.redirectForSku = !!redirectForSku;

        if (product) {
            baseDataLayer.name = decodeHTML(product.name) || '';
            baseDataLayer.id = product.partNumber || '';
            baseDataLayer.canonicalUrl = page.path || '';
            baseDataLayer.products = [product];
            baseDataLayer.passportItem = passportData;
            baseDataLayer.cyoComponents = items;
            baseDataLayer.movieSkus = movieSkus;
        }

        if (location.state) {
            const {
                categoryId, categoryName, categoryPath, categoryLegacyId,
            } = location.state;

            baseDataLayer.category = {
                id: categoryId,
                shortDescription: categoryName,
                categoryPath,
                legacyId: categoryLegacyId,
            };
        }
    } catch (ex) {
        mbpLogger.logError({
            product,
            pageDataLayer,
            function: 'generateProductPageDataLayer',
            appName: process.env.npm_package_name,
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to generate Product data layer input data.',
        });
    }

    return baseDataLayer;
};

// Build data layer object for Category pages
const generateCategoryPageDataLayer = (page, pageDataLayer) => {
    const { dataLayer } = page;
    const baseDataLayer = { ...pageDataLayer };
    if (!page) {
        mbpLogger.logError({
            page,
            pageDataLayer,
            function: 'generateCategoryPageDataLayer',
            appName: process.env.npm_package_name,
            module: 'tag-manager',
            message: 'Missing required params [page]',
        });

        return false;
    }

    try {
        baseDataLayer.pageType = 'category';
        baseDataLayer.pageName = 'Category';
        const track_category_data = getDataLayerActionEntry('track_category_data', dataLayer);
        const track_product_listing_data = getDataLayerActionEntry('track_product_listing_data', dataLayer); // TODO: Replace with category.products
        if (track_category_data && track_product_listing_data) {
            const { category = {}, redirectForCategory } = track_category_data;
            const { products = [] } = track_product_listing_data;

            baseDataLayer.name = decodeHTML(category?.shortDescription) || '';
            baseDataLayer.id = category?.id;
            baseDataLayer.legacyId = category?.legacyId;
            baseDataLayer.redirectForCategory = !!redirectForCategory;
            baseDataLayer.totalCategoryProducts = track_category_data.totalCategoryProducts || 0;
            // baseDataLayer.identifier = category.identifier;
            baseDataLayer.canonicalUrl = page?.path || '';

            if (products) {
                baseDataLayer.products = products;
            }

            if (category) {
                baseDataLayer.category = {
                    ...category,
                    path: page?.path || '',
                };
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            page,
            function: 'generateCategoryPageDataLayer',
            appName: process.env.npm_package_name,
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to generate Category data layer input data.',
        });
    }

    return {
        ...baseDataLayer,
    };
};

// Build data layer object for Search Results pages
const generateSearchResultsDataLayer = (page, pageDataLayer) => {
    const { dataLayer } = page;
    const baseDataLayer = { ...pageDataLayer };

    if (!page) {
        mbpLogger.logError({
            page,
            pageDataLayer,
            function: 'generateSearchResultsDataLayer',
            appName: process.env.npm_package_name,
            module: 'tag-manager',
            message: 'Missing required params [data]',
        });
        return false;
    }

    try {
        baseDataLayer.pageType = 'search';
        baseDataLayer.pageName = 'Search';
        const track_product_listing_data = getDataLayerActionEntry('track_product_listing_data', dataLayer);
        if (track_product_listing_data) {
            const {
                searchTerm, products, searchInputTerm, totalSearchProducts,
            } = track_product_listing_data;

            if (searchTerm) {
                baseDataLayer.searchTerm = searchTerm;
                baseDataLayer.searchInputTerm = searchInputTerm;
                baseDataLayer.totalSearchProducts = totalSearchProducts || 0;

                // Append searchTerm param to "page" for proper search  reporting.
                baseDataLayer.page = `${baseDataLayer.page}?searchTerm=${searchTerm}`;
            } else {
                mbpLogger.logError({
                    page,
                    pageDataLayer,
                    function: 'generateSearchResultsDataLayer',
                    appName: process.env.npm_package_name,
                    module: 'tag-manager',
                    jsError: track_product_listing_data,
                    message: 'Failed to generate Search Results search term data.',
                });
            }

            if (products) {
                baseDataLayer.products = products;
            } else {
                mbpLogger.logError({
                    page,
                    pageDataLayer,
                    function: 'generateSearchResultsDataLayer',
                    appName: process.env.npm_package_name,
                    module: 'tag-manager',
                    jsError: track_product_listing_data,
                    message: 'Failed to generate Search Results products data.',
                });
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            page,
            pageDataLayer,
            function: 'generateSearchResultsDataLayer',
            appName: process.env.npm_package_name,
            module: 'tag-manager',
            jsError: ex,
            message: 'Failed to generate Search Results data layer input data.',
        });
    }

    return baseDataLayer;
};

const generateCustomerVariants = (contentTestVariants, redirectTestVariant) => {
    let eventsArray = '';

    if (contentTestVariants?.length > 0) {
        eventsArray = [...contentTestVariants, ...eventsArray];
    }

    if (redirectTestVariant?.eventAction) {
        eventsArray = [redirectTestVariant, ...eventsArray];
    }

    return eventsArray;
};

// Generate page data layer object
export const generatePageDataLayer = (data) => {
    if (!data) {
        mbpLogger.logError({
            data,
            function: 'generatePageDataLayer',
            appName: process.env.npm_package_name,
            module: 'tag-manager',
            message: 'Missing required params',
        });

        return null;
    }

    const {
        page,
        session: {
            user = {},
            paymentDetails = {},
        } = {},
    } = data;
    let pageDataLayer = {};
    const { location, flags } = page;
    const isMobile = (typeof window !== 'undefined') ? window.isMobile : false;

    // Data
    const track_seo_data = getDataLayerActionEntry('track_seo_data', page.dataLayer);
    const { items } = getDataLayerActionEntry('track_gift_list_data', page.dataLayer) || {};

    try {
        pageDataLayer.page = page.location.pathname;

        if  (isMobile) {
            // AMP
            pageDataLayer.ampEnabled = true;
        }

        // Set Brand
        pageDataLayer.brand = page.brand;

        // Transport Type
        // const transport = (navigator && navigator.sendBeacon) ? 'beacon' : 'xhr';
        // pageDataLayer.guaTransportType = transport;

        // Customer Details
        pageDataLayer.customer_id = user?.email || '';
        pageDataLayer.customer_eid = user?.enterpriseId || '';
        pageDataLayer.customer_firstName = user?.firstName || '';
        pageDataLayer.customer_lastName = user?.lastName || '';
        pageDataLayer.customer_email = user?.email || '';
        pageDataLayer.customer_contactId = user?.contactId || '';
        pageDataLayer.customer_userRole = user?.userRole || '';
        pageDataLayer.customer_userType = user?.userRole || user?.userType || 'G';
        const abTestingGAEvents = page?.variantTestGAEvents || page?.redirectTestGAEvent
            ? generateCustomerVariants(page?.variantTestGAEvents, page?.redirectTestGAEvent)
            : '';
        pageDataLayer.customer_variants = abTestingGAEvents || '';
        // pageDataLayer.customer_userId = user?.email || ''; // TODO: Add hashed email to Member state (see order.billingAddress.hashedEmail)
        // pageDataLayer.customer_id_prefix = 'loginId_prefix' || ''; // TODO: Not sure what this is used for. JSP: fn:replace(person.credential.logonID, logonId, '')
        // pageDataLayer.customer_crm_id = (order.fdUserDataVO) ? order.fdUserDataVO.crmID : ''; // TODO: Do we still use this?

        // Location
        if (page.locationURL) {
            pageDataLayer.location = page.locationURL;
        }

        // Referrer
        if (page.referrer) {
            pageDataLayer.referrer = page.referrer;
        }

        // Title
        pageDataLayer.title = decodeHTML((track_seo_data?.seoData?.page_title && track_seo_data.seoData.page_title.trim() !== '') ? track_seo_data.seoData.page_title : document.title);

        // Inventory status
        pageDataLayer.instock = true; // Static value because the product will no be on the site if it's out of stock. Required for BounceX.

        // Personalization Hero Image data
        const track_hero_image_data = getDataLayerActionEntry('track_hero_image_data', page.dataLayer);
        if (typeof track_hero_image_data !== 'undefined' && track_hero_image_data?.tagImpressionState !== null) {
            pageDataLayer.hero_image_display_type = track_hero_image_data.tagImpressionState;
        } else {
            pageDataLayer.hero_image_display_type = null;
        }

        // Personalization Cart Overlay
        const track_cart_overlay_data = getDataLayerActionEntry('track_cart_overlay_data', page.dataLayer);
        if (typeof track_cart_overlay_data !== 'undefined' && track_cart_overlay_data?.tagImpressionState !== null) {
            pageDataLayer.cart_overlay_display_type = track_cart_overlay_data.tagImpressionState;
        } else {
            pageDataLayer.cart_overlay_display_type = null;
        }

        const track_order_data = getDataLayerActionEntry('track_order_data', page.dataLayer);
        if (track_order_data && track_order_data.order) { // Checkout
            page.paymentDetails = paymentDetails || null;

            pageDataLayer = generateCheckoutPageDataLayer(page, pageDataLayer, flags?.['is-product-id-consistent-events-enabled']);
        }

        const track_siginin_dropdown = getDataLayerActionEntry('track_siginin_dropdown', page.dataLayer);
        if (typeof track_siginin_dropdown !== 'undefined' && track_siginin_dropdown?.tagImpressionState !== null) {
            pageDataLayer.track_siginin_dropdown_display_type = track_siginin_dropdown.tagImpressionState;
        } else {
            pageDataLayer.track_siginin_dropdown_display_type = null;
        }

        if (page.account) { // Account
            // baseDataLayer.brand = page.brand.code || '';
            pageDataLayer = generateMyAccountDataLayer(page, pageDataLayer);
        }

        // Flags
        if (page.flags) {
            pageDataLayer.flags = page.flags;
        }

        if (items?.length > 0) {
            // gift list
            pageDataLayer.gift_list = items;
        }

        if (page.type === 'home') {
            pageDataLayer.pageType = 'homepage';
            pageDataLayer.pageName = 'Homepage';
        }

        if (page.type === 'GiftHistoryHomeURL') {
            pageDataLayer.pageType = 'giftlist';
            pageDataLayer.pageName = 'GiftList';
        }

        if (page.type === 'product') {
            pageDataLayer = generateProductPageDataLayer(page, pageDataLayer);
        }

        if (page.type === 'category') {
            pageDataLayer = generateCategoryPageDataLayer(page, pageDataLayer);
        }

        if (page.path && page.path.indexOf('/searchterm') !== -1) {
            pageDataLayer = generateSearchResultsDataLayer(page, pageDataLayer);
        }

        // Category Template
        if (page.type === 'category-template') {
            pageDataLayer.pageType = page.type || '';
            pageDataLayer.pageName = page.contentType || '';
        }

        // Add-Ons page
        if (page.path && page.path.indexOf('/add-ons') !== -1) {
            pageDataLayer.pageType = 'productAddOns';
            pageDataLayer.pageName = 'ProductAddOns';
        }

        // Wrap-Up page
        if (page.path && page.path.indexOf('/wrap-up') !== -1) {
            pageDataLayer.pageType = 'productWrapUp';
            pageDataLayer.pageName = 'ProductWrapUp';
        }

        if (page.contentType === 'passport') {
            pageDataLayer.pageType = 'passport';
            pageDataLayer.pageName = 'Passport Lander';
        }

        if (page.contentType === 'club_lander') {
            pageDataLayer.pageType = 'club';
            pageDataLayer.pageName = 'Club Lander';
        }

        //  Content page
        if (page.type === 'content' || page.type === 'content-page') {
            pageDataLayer.pageType = page.type || '';
            pageDataLayer.pageName = page.contentType || '';
        }

        //  Content page
        if (page.type === 'department') {
            pageDataLayer.pageType = page.type || '';
            pageDataLayer.pageName = page.contentType || '';
        }

        // Radio Intersitial form results
        if (page.path && page.path.indexOf('/radio-offers-results') !== -1) {
            pageDataLayer.radioEventTerm = page.path.split('/')[2];
        }

        //  lander perfect gift page
        if (page.type === 'perfect-gifts') {
            pageDataLayer.pageType = page.type;
            pageDataLayer.pageName = page.name;
        }

        //  coupon page || Universal Lander Page
        if (page.type === 'coupon_page' || page.type === 'universal-lander') {
            pageDataLayer.pageType = page.type;
            pageDataLayer.pageName = page.name;
        }

        // Not Found
        if (page.type === 'notfound') {
            pageDataLayer.pageType = page.type || '';
            pageDataLayer.pageName = page.contentType || '';
        }

        // moved condition to top and wrapped every overwriting change
        if (flags?.ffIsTealiumEventOverrideEnabled) {
            const params = QueryString.parse((location?.search || '').replace(/^\?/, ''));
            if (params?.['redirect-for-category'] || params?.['redirect-for-sku'] || location?.state?.searchInputTerm) {
                pageDataLayer.pageType = 'search';
            }
        }

        return  pageDataLayer;
    } catch (ex) {
        mbpLogger.logError({
            data,
            function: 'generatePageDataLayer',
            appName: process.env.npm_package_name,
            module: 'mbp-page',
            jsError: ex,
            message: 'Generate page data layer object failed.',
        });
    }

    return null;
};

export default {};
