/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actions from './TagManager-Actions';
import * as operations from './TagManager-Operations';
import * as selectors from './TagManager-Selectors';

import reducer from './TagManager-Reducer';

export {
    actions,
    operations,
    selectors,
};

export default reducer;
