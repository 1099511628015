/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const ErrCodeFieldMapping = {
    /*
        Error Key Mapping - WCS Cart Services
    */
    _ERR_RECIPIENT_INFO_VALIDATION: {
        _ERR_NOT_AVALIABLE_IN_ZIP: ['zipCode', '_productNotAvailable'],
        INVALID_ZIPCODE: 'zipCode',
        EMPTY_LOCATION_TYPE: 'locationType',
        EMPTY_FIRST_NAME: 'firstName',
        EMPTY_LAST_NAME: 'lastName',
        EMPTY_ADDRESSLINE1: 'address1',
        EMPTY_CITY: 'city',
        ERROR_POBOX_Allowed: 'address1',
        EMPTY_STATE: 'state',
        EMPTY_ZIP_CODE: 'zipCode',
        EMPTY_COMPANY_NAME: 'organizationName',
    },
    _ERR_SUBMITTING_ORDER: {
        INVALID_ZIPCODE: 'zipCode',
        _ERR_PAY_CARD_NUMBER_INVALID: 'accountNumber',
        _ERR_EXCEEDED_NUMBER_OF_PAYMENT_RETRIES: 'error',
        PLACE_ORDER_FAILED_FOR_IPG_PAYPAL: 'error',
        PLACE_ORDER_TRANSCATION_FAILURE_FOR_PAYPAL: 'error',
        _ERR_NOT_AVALIABLE_IN_DATE_AND_ZIP: 'error',
    },
    _ERROR_CHECKOUT_REST_APPLY_PROMOTION: {
        PROMOTION_CODE_EMPTY: 'promotionCode',
        ERR_PROMOTION_CODE_DOES_NOT_EXIST: 'promotionCode',
    },
    _ERROR_CHECKOUT_REST_APPLY_GIFTCARD: {
        EGC_ACCOUNT_CLOSED: 'error',
        EGC_NOT_EXIST: 'error',
    },
    _ERROR_CHECKOUT_REST_SUBMITTING_ORDER_VALIDATION: {
        'PaymentAuthorizationTaskCmd.611': 'accountNumber',
        ERROR_AddressEmpty: 'address1',
        ERROR_email1Empty: 'email',
        ERROR_confirmEmailEmpty: 'confirmEmail',
        ERROR_StateEmpty: 'state',
        ERROR_phone1Empty: 'phone',
        ERROR_LastNameEmpty: 'lastName',
        ERROR_ZipCodeEmpty: 'zipCode',
        ERROR_cc_cvc_Empty: 'cvv',
        EDPPaymentMethods_EMPTY_EXPIRY_DATE: 'invalidYearAndMonth',
        EDPPaymentMethods_INVALID_EXPIRY_DATE: 'invalidYearAndMonth',
        ERROR_FirstNameEmpty: 'firstName',
        ERROR_CityEmpty: 'city',
        EDPPaymentMethods_EMPTY_EXPIRY_MONTH: 'month',
        EDPPaymentMethods_EMPTY_EXPIRY_YEAR: 'year',
        _ERR_PAY_CARD_NUMBER_INVALID: 'accountNumber',
    },
    /*
        Error Key Mapping - New Cart Services
    */
    ErrCodeFieldMapping: {
        'recipient.locationType': 'locationType',
        'recipient.firstName': 'firstName',
        'recipient.lastName': 'lastName',
        'recipient.address1': 'address1',
        'recipient.address2': 'address2',
        'recipient.zipcode': 'zipCode',
        'recipient.state': 'state',
        'recipient.city': 'city',
        'recipient.country': 'country',
        'recipient.phoneNumber': 'phone',
    },
    ERROR_MISSING_ADDRESS_INFO: {
        error: 'orderItemError',
    },
    ERROR_SHOP_CART_AVAILABILITY: {
        error: 'orderItemError',
    },
    ERROR_GIFT_CARD_AUTHORIZE_INSUFFICIENT_FUNDS: {
        error: 'giftCard',
    },
    ERROR_AUTHORIZING_GIFT_CARD: {
        error: 'giftCard',
    },
    ERROR_CART_SUBMIT_REQUEST: {
        'payment.paymentMethod': 'error',
        accountNumber: 'accountNumber',
        expirationYear: 'invalidYearAndMonth',
        cvv: 'cvv',
        expirationMonth: 'invalidYearAndMonth',
        nameOnCreditCard: 'nameOnCreditCard',
        firstName: 'firstName',
        lastName: 'lastName',
        address1: 'address1',
        zipCode: 'zipCode',
        city: 'city',
        state: 'state',
        country: 'country',
        phoneNumber: 'phone',
        email: 'email',
    },
    ERROR_CART_UPDATE_BILLING_REQUEST: {
        firstName: 'firstName',
        lastName: 'lastName',
        address1: 'address1',
        zipCode: 'zipCode',
        city: 'city',
        state: 'state',
        country: 'country',
        phoneNumber: 'phone',
        email: 'email',
    },
    ERROR_WRONG_CVV: {
        error: 'cvv',
    },
    ERROR_CREDIT_CARD_AUTH_DECLINED: {
        error: 'accountNumber',
    },
    ERROR_INVALID_LOCATION_TYPE: {
        error: 'locationType',
    },
    ERROR_MISSING_LOCATION_TYPE: {
        error: 'locationType',
    },
    ERROR_INVALID_ORGANIZATION_NAME: {
        error: 'organizationName',
    },
    ERROR_MISSING_ORGANIZATION_NAME: {
        error: 'organizationName',
    },
    ERROR_SAVE_RECIPIENT: {
        'recipient.locationType': 'locationType',
        'recipient.firstName': 'firstName',
        'recipient.lastName': 'lastName',
        'recipient.address1': 'address1',
        'recipient.address2': 'address2',
        'recipient.zipcode': 'zipCode',
        'recipient.state': 'state',
        'recipient.city': 'city',
        'recipient.country': 'country',
        'recipient.phoneNumber': 'phone',
        'recipient.email': 'email',
    },
    ERROR_INVALID_PHONE_NUMBER: 'Phone Number is invalid or missing',
    ERROR_VALIDATING_PRODUCT_AVAILABILITY: {
        error: 'orderItemError',
    },
    ERROR_ITEM_NOT_BUYABLE: {
        error: 'error',
    },
    ERROR_NOT_AVAILABLE_NEXT_DELIVERY_DATE: {
        error: 'error',
    },
    ERROR_INVALID_VIEWING_TIME: {
        error: 'orderItemError',
    },
    ERROR_EXTERNAL_EXPIRED_ITEMS: {
        error: 'Please select a future date and/or time to continue.',
    },
};

export default ErrCodeFieldMapping;
