/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    PLACE_ORDER,
    SHOW_ORDER_CONFIRMATION,
    APPLE_PAY_ORDER_SUCCESS,
    APPLE_PAY_VALIDATE_MERCHANT_SUCCESS,
    CLICKSTREAM_ORDER_COMPLETE,
} from './Payment-ActionTypes';

const placeOrder = (config) => ({
    type: PLACE_ORDER,
    payload: config,
});

const clickStreamOrderComplete = (payload) => ({
    type: CLICKSTREAM_ORDER_COMPLETE,
    payload,
});

export const updateShowOrderConfirmation = () => ({
    type: SHOW_ORDER_CONFIRMATION,
});

const applePayOrderSuccess = (history, email) => ({
    type: APPLE_PAY_ORDER_SUCCESS,
    payload: { history, email },
});

const applePayOrderValidateMerchantSuccess = () => ({
    type: APPLE_PAY_VALIDATE_MERCHANT_SUCCESS,
});

const clearPassportError = () => ({
    type: 'PASSPORT/CLEAR_PASSPORT_ERROR',
});

export {
    placeOrder,
    clickStreamOrderComplete,
    applePayOrderSuccess,
    applePayOrderValidateMerchantSuccess,
    clearPassportError,
};
