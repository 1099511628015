/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import Helmet from 'react-helmet';
import { string, bool } from 'prop-types';
import { useSelector } from 'react-redux';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const DynamicImagePrefetch = ({ src, isWindow }) => {
    const isDynamicImagePrefetchingEnabled = useSelector(getFeatureFlag('is-dynamic-image-prefetching-enabled'));

    if (!isDynamicImagePrefetchingEnabled) {
        return <></>;
    }
    // only do this on the server
    if (typeof window !== 'undefined' && isWindow) {
        return <></>;
    }
    return (
        <Helmet>
            <link rel="preload" href={src} as="image" />
        </Helmet>
    );
};

DynamicImagePrefetch.propTypes = {
    src: string.isRequired,
    isWindow: bool,
};

DynamicImagePrefetch.defaultProps = {
    isWindow: true,
};

export default DynamicImagePrefetch;
