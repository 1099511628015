/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import PayloadGenerator from './payloadGenerator';

const mapAddressTypeIdToLocationType = (addressTypeId) => {
    switch (addressTypeId) {
        case '2': return 'Business';
        case '4': return 'Funeral home';
        case '5': return 'Hospital';
        case '6': return 'Apartment';
        case '7': return 'Apartment';
        case '8': return 'School';
        case '11': return 'Church';
        case '1':
        default:  return 'Residence';
    }
};

class PayloadGeneratorFromOrderHistory extends PayloadGenerator {
    generatePayloadFromOrderHistoryItem = ({
        itemDetailList,
        recipient,
    }) => {
        const addOnItems = itemDetailList.filter((items) => items.addonType === 'ADDON');
        const item = itemDetailList.filter((items) => items.addonType !== 'ADDON')?.[0];

        if (addOnItems?.length) this.setComponents(addOnItems);

        this.setProductDetails({
            brandCode: item.productBrand,
            productCode: item.productSku,
            quantity: item.quantity,
        });

        this.setSubscription({
            type: item.subscriptionType,
            interval: item.interval,
            duration: item.duration,
        });

        const { addressDetail, firstName, lastName } = recipient;
        const address = addressDetail?.[0] || {};
        this.addRecipient({
            address1: address.addressLine1,
            address2: address.addressLine2,
            address3: address.addressLine3,
            city: address.cityName,
            country: address.countryCode,
            state: address.state,
            zipCode: address.zipCode,
            firstName,
            lastName,
            email: recipient.emailAddress,
            phone: recipient.phone?.number,
            locationType: mapAddressTypeIdToLocationType(address.addressType),
            organizationName: recipient.businessName,
        });

        const payload = this.constructPayload();

        return payload;
    }
}

export default PayloadGeneratorFromOrderHistory;
