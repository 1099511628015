/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const SET_AB_TESTING_VARIANT = 'SET_AB_TESTING_VARIANT';
export const SET_AB_TESTING_LOADED = 'SET_AB_TESTING_LOADED';
export const SET_AB_TESTING_TIMEOUT = 'SET_AB_TESTING_TIMEOUT';

export const SET_FAB_VARIANT = 'SET_FAB_VARIANT';

export default {};
