/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// TODO Refactor this
export default function determineLocale(history, isCanadianCategory = false, isEnglandCategory = false) {
    if (history?.location?.pathname || history?.location?.state) {
        if (['canada'].some((path) => history.location.pathname?.includes(path) || history.location.state?.prevUrl?.includes(path)) || isCanadianCategory) {
            return 'en-ca';
        }
        if (['england', 'united-kingdom', '-uk'].some((path) => history.location.pathname?.includes(path) || history.location.state?.prevUrl?.includes(path)) || isEnglandCategory) {
            return 'en-gb';
        }
    }
    return 'en-us';
}
