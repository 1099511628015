/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Query } from '@apollo/client/react/components';
import mbpLogger from 'mbp-logger';

import findContent from '../../../../../../../../gql/queries/findContent';

import { GRAPHQL_ENV } from '../../../../../../../../gql';

import PassportTncContent from '../PassportTncContent/PassportTncContent';
import SiteLoader from '../../../../../Common/SiteLoader/SiteLoader';

const GraphqlPassportTnC = () => {
    // Display same tnc content for all brands - using brand as 1800flowers
    const QUERY = findContent({
        feed: 'findContentPage',
        brand: '1800flowers',
        locale: 'en-us',
        url: '/passport-terms-and-conditions',
        contentType: 'template_page_seo_articles',
    });

    return (
        <Query query={QUERY}>
            {({ loading, error, data }) => {
                if (loading) {
                    return <SiteLoader />;
                }

                if (error) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        query: QUERY,
                        component: 'GraphqlPassportTnC.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });
                    return null;
                }

                if (!data) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: QUERY,
                        component: 'GraphqlPassportTnC.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }
                let content = null;
                if (data.findContentPage?.content) {
                    content = data.findContentPage.content?.entries?.[0];
                }
                return (
                    <PassportTncContent data={content} />
                );
            }}
        </Query>
    );
};

export default GraphqlPassportTnC;
