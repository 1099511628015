/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './RetryCalculateShipping-ActionTypes';

const updateRetryCalculateShippingAttempt = () => ({ type: actionTypes.RETRY_CALCULATE_SHIPPING_ATTEMPT });

const clearCalculateShippingAttempt = () => ({ type: actionTypes.CLEAR_CALCULATE_SHIPPING_ATTEMPT });

const updateCalculateShippingApplied = (data) => ({
    type: actionTypes.UPDATE_CALCULATE_SHIPPING_APPLIED,
    data,
});

export {
    updateRetryCalculateShippingAttempt,
    clearCalculateShippingAttempt,
    updateCalculateShippingApplied,
};
