/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const GET_SCI_DATA = 'GET_SCI_DATA';
const SET_SCI_CAMPAIGNID = 'SET_SCI_CAMPAIGNID';
const SET_SCI_LOCNUMBER = 'SET_SCI_LOCNUMBER';
const SET_SCI_DISPOSITIONTYPE = 'SET_SCI_DISPOSITIONTYPE';
const CLEAR_SCI_CAMPAIGNID = 'CLEAR_SCI_CAMPAIGNID';
const CLEAR_SCI_LOCNUMBER = 'CLEAR_SCI_LOCNUMBER';
const CLEAR_SCI_DISPOSITIONTYPE = 'CLEAR_SCI_DISPOSITIONTYPE';
const SET_SCI_LOADING_STATE = 'GET_SCI_LOADING_STATE';
const SET_SCI_DECEDENTDATA = 'SET_SCI_DECEDENTDATA';

export default {
    GET_SCI_DATA,
    SET_SCI_CAMPAIGNID,
    SET_SCI_LOCNUMBER,
    SET_SCI_DISPOSITIONTYPE,
    CLEAR_SCI_CAMPAIGNID,
    CLEAR_SCI_LOCNUMBER,
    CLEAR_SCI_DISPOSITIONTYPE,
    SET_SCI_LOADING_STATE,
    SET_SCI_DECEDENTDATA,
};
