/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const BRAND_LOAD = 'MBP_UI_APP/Brand/LOAD';
const BRAND_LOADED = 'MBP_UI_APP/Brand/LOADED';
const BRAND_LOAD_FAILED = 'MBP_UI_APP/Brand/LOAD/FAILED';

const BRAND_SET_CURRENT_BRAND_ID = 'MBP_UI_APP/SET/CURRENT/BRAND/ID';

export default {
    BRAND_LOAD,
    BRAND_LOADED,
    BRAND_LOAD_FAILED,

    BRAND_SET_CURRENT_BRAND_ID,
};
