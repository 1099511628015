/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { createSelector } from 'reselect';

import brandThemes from '../../../../../app/components/AppShell/brandtheme';

export const getCurrentBrandId = (state) => state.ui.brandId;
export const getConfigByDomains = (state) => state.app.config.byDomain;

export const getBrandsById = (state) => {
    if (state && state.page && state.page.brands && state.page.brands.byId) {
        return state.page.brands.byId;
    }

    return null;
};

const brandByDomain = {
    '18F': '1800flowers',
    HD: 'harryanddavid',
    '18B': '1800baskets',
    SCH: 'simplychocolate',
    FBQ: 'fruitbouquets',
    CCO: 'cheryls',
    PZU: 'personalizationuniverse',
    TPF: 'thepopcornfactory',
    WLF: 'wolfermans',
    SY: 'stockyards',
    BRY: 'berries',
    VC: 'vitalchoice',
    FL: 'florists',
};

// Brand Details
export const getBrand = createSelector(
    [
        getCurrentBrandId,
        getBrandsById,
    ],
    (currentBrandId, configCodes) => {
        if (currentBrandId && configCodes[currentBrandId]) {
            return configCodes[currentBrandId];
        }
        return null;
    },
);

// Feature Flag
export const getFeatureFlags = createSelector(
    [getCurrentBrandId, getConfigByDomains],
    (currentBrandId, configCodes) => {
        if (
            currentBrandId
            && brandByDomain[currentBrandId]
            && configCodes[brandByDomain[currentBrandId]]
            && configCodes[brandByDomain[currentBrandId]]['app-feature-flags']
        ) {
            return configCodes[brandByDomain[currentBrandId]]['app-feature-flags'];
        }

        return {};
    },
);

export const getPhoneNumber = createSelector(
    [getCurrentBrandId, getConfigByDomains],
    (currentBrandId, configCodes) => {
        if (
            currentBrandId
            && brandByDomain[currentBrandId]
            && configCodes[brandByDomain[currentBrandId]]
            && configCodes[brandByDomain[currentBrandId]]['ref-brand']?.phoneNumber
        ) {
            return configCodes[brandByDomain[currentBrandId]]['ref-brand']?.phoneNumber;
        }

        return {};
    },
);
export const getFeatureFlag = (selector) => createSelector(
    [getFeatureFlags],
    (featureFlags) => {
        if (Array.isArray(selector)) {
            let result = featureFlags;
            // eslint-disable-next-line consistent-return
            selector.forEach((propertyName) => {
                // eslint-disable-next-line no-prototype-builtins
                if (!result?.hasOwnProperty || !result.hasOwnProperty(propertyName)) {
                    return false;
                }
                result = result[propertyName];
            });

            return result;
        }

        if (featureFlags) {
            return featureFlags[selector] ?? false;
        }

        return false;
    },
);

export const getBestSellersId = createSelector(
    [
        getBrand,
    ],
    (currentBrand) => {
        const { code } = currentBrand || {};
        if (code) {
            const { best_sellers_category_id } = brandThemes[code] || brandThemes['18F'];
            return best_sellers_category_id;
        }
        return '';
    },
);

export const getPresentationFamily = createSelector(
    [
        getBrand,
    ],
    (currentBrand) => {
        if (currentBrand && currentBrand.code) {
            const code = brandThemes[currentBrand.code] ? currentBrand.code : '18F';
            const { presentation_family } = brandThemes[code];
            return presentation_family;
        }

        return null;
    },
);

export const getDisplayAdditionalCompliance = createSelector(
    [
        getBrand,
    ],
    (currentBrand) => {
        if (currentBrand && currentBrand.code) {
            const { implement_additional_compliance_age_verify } = brandThemes[brandThemes[currentBrand.code] ? currentBrand.code : '18F'];
            return implement_additional_compliance_age_verify;
        }

        return null;
    },
);

export const getPriceRangeLayout = createSelector(
    [
        getBrand,
    ],
    (currentBrand) => {
        const { code } = currentBrand || {};
        if (code) {
            const { price_range_layout } = brandThemes[code] || brandThemes['18F'];
            return price_range_layout;
        }
        return '';
    },
);

export const getPassportPageUrl = (state) => state?.passport?.passportData?.url || '/passport-flowers';

export const getDeviceType = (state) => state?.app?.ssr?.config?.deviceType;
