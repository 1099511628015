/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/styles';
import { bool, object } from 'prop-types';
import Slider from './Slider';

const styles = (theme) => ({
    root: {
        maxWidth: '1260px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '15px 32px',
        [theme.breakpoints.down(1140)]: {
            maxWidth: '90%',
        },
        [theme.breakpoints.down(768)]: {
            maxWidth: '600px',
        },
        [theme.breakpoints.down(560)]: {
            maxWidth: '350px',
        },
    },
    carouselHeader: {
        fontSize: '30px',
        fontFamily: 'Montserrat',
        fontWeight: 700,
        paddingBottom: '40px',
        textTransform: 'uppercase',
    },
});

const PassportCarousel = ({
    imageObj, classes, isAuthenticated, isActivePassportUser,
}) =>  {
    const hasTitle = imageObj && imageObj.carousel_title;
    const imageKeys = Object.keys(imageObj);
    return (
        <div className={classes.root}>
            <div className={classes.lock} />
            {hasTitle ? <div className={classes.carouselHeader}>{imageObj.carousel_title}</div> : null}
            <Slider imagesKey={imageKeys} imageObj={imageObj} classes={classes} isAuthenticated={isAuthenticated} isActivePassportUser={isActivePassportUser} />
        </div>
    );
};

PassportCarousel.propTypes = {
    imageObj: object.isRequired,
    classes: object.isRequired,
    isAuthenticated: bool.isRequired,
    isActivePassportUser: bool.isRequired,
};

export default withStyles(styles)(PassportCarousel);
