/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';

// Material UI
import { Button, CircularProgress, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Error from '@material-ui/icons/Error';
import {
    arrayOf, bool, func, number, object, shape, string,
} from 'prop-types';
import { RenderChatAsLink } from '../../../MultiBrandChatController/AigoChatWidgetWrapperV2/popOpenChat';
import { getOperatingSystem } from '../../../../../state/ducks/App/ducks/Config/Config-Helpers';

// Auth
import { useAuth } from '../../../../../state/ducks/Member/ducks/Auth/Plugin/auth';

// Redux / Helpers
import {
    clearPassportError, closePassportSideBar, setPassportData, triggerPassportSubscription,
} from '../../../../../state/ducks/Passport/Passport-Action';
import {
    getPassportABData, getPassportCampaignData, getPassportData, getPassportError, getPassportItem, getPassportSideBarStatus, getPassportSignInData, getPassportMemberProfile, getIsPassportRenewalAllowed,
} from '../../../../../state/ducks/Passport/Passport-Selectors';
import { getFeatureFlags, getPresentationFamily, getCurrentBrandId } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getIsAuthenticatedStatus, getIsPassportUserLoggedIn } from '../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import validatePassport from '../../../../helpers/validatePassport';
import getTrackingLabel from '../../../../helpers/passportHelper';
import { trackEvent } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getCampaignDetails, isValidCampaign } from './PassportCampaignHelpers';
// Components
import ResponsiveImage from '../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import YotpoModules from './YotpoModules';
import PassportYotpoFAQ from '../PassportYotpoFAQ';
import PassportCarousel from './Carousel/PassportCarousel';
import PlaceholderBanner from './PlaceholderBanner';
import YotpoStaticReferralBanner from './YotpoStaticReferralBanner';
import PassportEventGrid from './PassportEventGrid';
import PassportTncModal from '../../../../pages/Checkout/containers/CheckoutFood/components/Partials/Passport/PassportTnCModal/PassportTnCModal';
import PassportVideo from './Video/PassportVideo';
import PassportBlockSeoFooter from './PassportBlockSeoFooter';
import { getActiveABTests } from '../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import { nodeToString, getSkuWithoutBrand } from '../../../../helpers/common/helper';
import GraphqlSeoHeadTags from '../../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

        fontFamily: 'sans-serif',

        '& h3': {
            fontSize: '1.8rem',
            margin: '0px',
            textAlign: 'center',
        },

        '& h2': {
            fontSize: '1.8rem',
            fontWeight: '500',
            margin: '0px',
            textAlign: 'center',
        },

        '& button:hover': {
            backgroundColor: 'transparent',
        },
    },
    hero: ({ headerBannerBackgroundColor }) => ({
        width: '100%',
        textAlign: 'center',
        backgroundColor: headerBannerBackgroundColor,
    }),
    subHero: {
        width: 'fit-content',
        margin: 'auto',
    },
    smallText: {
        fontSize: '20px',
        fontFamily: 'Montserrat !important',
        fontWeight: 'regular',
        marginTop: '10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '.8rem',
        },

        '& > button': {
            fontWeight: 'inherit',
            fontSize: 'inherit',
            fontFamily: 'inherit',
            color: 'white',
            textTransform: 'capitalize',
            textDecoration: 'underline',
            border: 'none',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            '&:focus-visible': {
                outline: '-webkit-focus-ring-color auto 1px',
            },
            '&:hover': {
                textDecoration: 'none',
            },
        },
    },
    userBtn: {
        fontWeight: 'inherit',
        fontSize: 'inherit',
        fontFamily: 'inherit',
        color: 'white',
        textTransform: 'capitalize',
        '&:focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px',
        },
        '&:hover': {
            textDecoration: 'none !important',
        },
    },
    underText: {
        padding: '0 50px',
        [theme.breakpoints.down('md')]: {
            padding: '0 30px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 10px',
        },
        '& a': {
            color: 'white !important',
            display: 'inline-block',
        },
    },
    noTransform: {
        textTransform: 'none !important',
        display: 'inline-block',
        padding: 0,
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        margin: '1rem 0rem 1rem 0rem',
        '& button': {
            borderRadius: '0 !important',
        },
        '& button:focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px',
        },

        '& button:hover': {
            backgroundColor: 'rgba(0,0,0, .7)',
            color: '#fff !important',
        },
    },

    buttonContainerMobile: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        margin: '1rem auto 0.5rem',
        width: 'fit-content',
        '& > button': {
            width: '100%',
            borderRadius: '0 !important',
        },
    },
    addCartBtn: {
        background: 'white',
        padding: '10px 3em',
        color: 'rgb(67,116,139)',
        textTransform: 'uppercase',
        fontSize: '20px',
        margin: '0.5rem 1rem',
        fontFamily: 'Montserrat !important',
        fontWeight: 'bold',
        '&:focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem',
        },
    },
    rootMobile: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

        fontFamily: 'sans-serif',

        '& h3': {
            margin: '0px',
            textAlign: 'center',
            fontSize: '1.1rem',
        },

        '& h2': {
            fontWeight: '500',
            margin: '0px',
            textAlign: 'center',
            fontSize: '1rem',
        },

        '& button': {
            fontSize: '1rem',
        },

        '& p': {
            fontSize: '1rem',
        },
        '& .yotpo-vip-tiers-grid-header': {
            minHeight: '215px',

        },
    },
    bannerContainer: {
        width: '100%',
        textAlign: 'center',
    },
    passportAppImage: {
        objectFit: 'cover',
        width: '100%',
        bannerBottom: '12px',
    },
    fullWidth: {
        [theme.breakpoints.down(1440)]: {
            width: '100%',
        },
    },
    multiBannerImage: {
        objectFit: 'cover',
        width: '100%',
        height: '450px',
        [theme.breakpoints.down(1280)]: {
            height: '420px',
        },
        [theme.breakpoints.down(1250)]: {
            height: '400px',
        },
        [theme.breakpoints.down(1200)]: {
            height: '350px',
        },
        [theme.breakpoints.down(980)]: {
            height: '320px',
        },
        [theme.breakpoints.down(920)]: {
            height: '270px',
        },
        [theme.breakpoints.down(760)]: {
            height: '220px',
        },
        [theme.breakpoints.down(640)]: {
            height: '200px',
        },
    },
    image: {
        objectFit: 'cover',
        width: '100%',
        height: '450px',
        [theme.breakpoints.down(1260)]: {
            height: '350px',
        },
    },
    imageMobile: {
        objectFit: 'cover',
        width: '100%',
        height: 'auto',
    },
    underHeroBanner: {
        width: '100%',
        marginTop: '-5px',
        padding: '3rem 1rem',
        backgroundColor: 'rgb(67,116,139)',
        textAlign: 'center',
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            padding: '1rem 0.5rem',
        },

        '& h2': {
            fontFamily: 'Montserrat !important',
            fontWeight: 'normal',
            fontSize: '25px !important',
        },

        '& > p': {
            margin: '0',
            fontSize: '25px !important',
        },
    },
    underHeroBannerMobile: {
        width: '100%',
        marginTop: '-5px',
        textAlign: 'center',
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            padding: '1rem 0.5rem',
        },

        '& h2': {
            fontFamily: 'Montserrat !important',
            fontWeight: 'normal',
            fontSize: '20px !important',
        },

        '& > p': {
            margin: '0',
            fontSize: '20px !important',
        },
    },
    loadingCircle: {
        marginLeft: '10px',
    },
    errorContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    errorIcon: {
        marginRight: '5px',
    },
    detailsSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        margin: '3rem 0 1rem 0',

        [theme.breakpoints.down('sm')]: {
            margin: '2rem 0 3rem 0',
        },

        '& > h3': {
            color: 'rgb(67,116,139)',
        },
    },
    detailsIconSection: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        position: 'relative',
    },
    iconBorder: {
        width: '2px',
        backgroundColor: 'rgba(173,173,173,0.5)',

        position: 'absolute',
        right: '0',
        top: '30px',
        bottom: '60px',

        [theme.breakpoints.down('sm')]: {
            top: '10px',
            bottom: '10px',
        },
    },
    iconBorderMobile: {
        width: '2px',
        backgroundColor: 'rgba(173,173,173,0.5)',

        position: 'absolute',
        right: '0',
        top: '10px',
        bottom: '10px',
    },
    verticalIconBorder: {
        height: '2px',
        backgroundColor: 'rgba(173,173,173,0.5)',

        position: 'absolute',
        top: '0',
        right: '10px',
        left: '10px',
    },
    iconBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
        margin: '1rem',
        fontSize: '1.2rem',
        width: '20%',
        maxWidth: '250px',
        textAlign: 'center',

        position: 'relative',

        [theme.breakpoints.down('md')]: {
            padding: '4%',
            margin: '0',
            maxWidth: '350px',
            width: '25%',

            [theme.breakpoints.down('sm')]: {
                width: '47%',
                fontSize: '1rem',
            },
        },

        '& > img': {
            height: '80px',

            [theme.breakpoints.down('md')]: {
                height: '50px',
            },
        },
    },
    iconBoxMobile: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4%',
        margin: '0',
        fontSize: '1rem',
        width: '46%',
        maxWidth: '350px',
        textAlign: 'center',

        position: 'relative',

        '& > img': {
            height: '50px',
        },
    },
    detailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: '0px',

        '& span': {
            width: 'auto',
        },
    },
    detailsImage: {
        width: '100%',
        objectFit: 'cover',
    },
    testimonialgif: {
        padding: '2rem',
        maxWidth: '700px',
        width: '100%',

        [theme.breakpoints.down('md')]: {
            padding: '0',
            maxWidth: '90%',
        },
    },
    footerSection: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'rgb(105,150,173)',
        padding: '3rem 1rem',
        color: 'white',
        textAlign: 'center',
        '& > p': {
            fontSize: '20px',
            fontFamily: 'Montserrat',
        },
        '& > h3': {
            color: 'white',
            fontSize: '30px !important',
            fontWeight: 'bold',
        },
    },
    brandImageContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '1000px',
        padding: '1rem 0',

        '& > img': {
            maxWidth: '185px',
            maxHeight: '100px',
            padding: '0.5rem',
            margin: '0.5rem',
        },
    },

    brandImageContainerMobile: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '1000px',
        padding: '1rem 0',

        '& > img': {
            maxWidth: 'auto',
            maxHeight: '44px',
            padding: '0.5rem',
            margin: '0.5rem',
        },
    },
    disclaimerSection: {
        paddingBottom: '2rem',
        maxWidth: '70%',
        '& > p': {
            fontSize: '0.85rem',
            lineHeight: '1.1rem',
        },
        '& > span': {
            fontSize: '0.85rem',
            lineHeight: '1.1rem',
        },
    },
    sectionThreeHeading: {
        paddingTop: '3rem',
        color: theme.palette.common.black || '#000000',
    },
    sectionThreeHeadingMobile: {
        paddingTop: '2rem',
        color: theme.palette.common.black || '#000000',
    },
    yotpoModule: {
        width: '100%',
        backgroundColor: '#f5f5f5',
        // padding: '50px 0px',
        [theme.breakpoints.down('md')]: {
            width: '90%',
        },
    },
    seoFooter: {
        marginBottom: '10px',
        '& h2': {
            fontSize: '14px',
            textAlign: 'start',
            fontWeight: '700',
        },
    },
    passportVideo: {
        position: 'relative',
        maxWidth: '1200px',
        display: 'flex',
        justifyContent: 'center',
        margin: '50px 0px',
        padding: '30px 0px',
        '& video': {
            height: '75%',
            width: '100%',
        },
        [theme.breakpoints.down(1000)]: {
            maxWidth: '850px',
            '& video': {
                height: '75%',
                // width: '75%',
            },
        },
        [theme.breakpoints.down(599)]: {
            marginTop: '40px',
            padding: '0px',
            '& video': {
                height: '75%',
                width: '100%',
            },
        },
    },
    customControls: {
        position: 'absolute',
        top: '35%',
        fontSize: '150px',
        color: 'white',
        opacity: 0.6,
        '@media screen and (max-width: 375px)': {
            fontSize: '50px',
        },
        '@media screen and (min-width: 376px) and (max-width: 600px)': {
            fontSize: '80px',
        },
    },
    referralModule: {
        marginTop: '50px',
        '& .yotpo-widget-referral-widget .yotpo-action-button-widget .yotpo-filled-button': {
            padding: '10px',
            height: 'auto !important',
        },
        '& .yotpo-widget-referral-widget .yotpo-background.yotpo-center-background': {
            height: 'auto !important',
        },
    },
}));

const PassportEnrollmentPage = ({
    data,
    featureFlags,
    passportData,
    passportABData,
    passportSignInData,
    passportCampaignData,
    isAuthenticated,
    passportMemberProfile,
    passportError,
    passportSideBarStatus,
    trackPassport,
    triggerSubscription,
    isYotpoEnabled,
    passportInCart,
    closePassportSideBanner,
    history,
    isPassportUserLoggedIn,
    presentationFamily,
    callClearPassportError,
    isPassportRenewalAllowed,
    activeABTests,
    setPassportCodeAction,
    passportOrderItem,
}) => {
    const { loginByRedirect } = useAuth();
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm')) || passportSideBarStatus;
    const operatingSystem = getOperatingSystem().toLowerCase();
    const currentBrand = useSelector((state) => getCurrentBrandId(state));
    const customerData = useSelector((state) => state?.member);
    const underTB = useMediaQuery('(max-width: 620px)'); // mob devices
    const underDT = useMediaQuery('(min-width: 1024px) and (max-width: 621px)'); // tab devices

    const isNonPassportUser = validatePassport('isNonPassportUser', passportData);
    const isActivePassportUser = validatePassport('isActivePassportUser', passportData);
    const isExpiredPassportUser = validatePassport('isExpiredPassportUser', passportData);
    const isExpiringPassportUser = validatePassport('isExpiringPassportUser', passportData);

    const [open, setOpen] = useState(false);
    const label = getTrackingLabel({}, passportData, featureFlags['is-passport-conditional-offer-on']);

    useEffect(() => {
        const url = history?.location?.pathname ? history.location.pathname : '';
        // VARIANT A is "Passport Enrolment Page"
        // CONTROL is "Passport Side Bar"
        let action = '';
        if (url !== passportData?.url
            || featureFlags['which-passport-sidebar-enabled-variant']?.variant === 'CONTROL') {
            action = 'Passport Sidebar Test CONTROL';
        }

        trackPassport({
            eventCategory: 'Test Impression',
            eventAction: action,
            eventLabel: label,
        });

        // clear passport error
        callClearPassportError();
    }, []);

    if (data?.header_section
        && data?.details_sections
        && data?.section_three
        && data?.campaign_section
        && data?.footer_section
        && data?.continue_shopping_link
        && data?.carousel_images) {
        const {
            header_section, hero_banners, section_three, footer_section, campaign_section, continue_shopping_link, dynamic_messaging,
            placeholder_banner, event_grid, carousel_images, yotpo_referral_module, seo,
        } = data;
        const guestMemberBanner = hero_banners?.guest_banner;
        const passMemberBanner = hero_banners?.passport_membership_banner;
        const expPassMemberBanner = hero_banners?.expiring_membership_banner;

        // Campaign Check
        const campaignDetails = getCampaignDetails(isMobile, campaign_section) || {};
        const isCampaignValid = isValidCampaign(campaignDetails);
        const campaignLink = (campaign_section?.link?.title
            && campaign_section?.link?.href)
            && campaign_section.link;

        // Seo Check
        const seoData = seo || {};

        // Hero Section
        const headerBannerMobile = (header_section?.mobile_banner?.url && header_section?.mobile_banner?.title) && header_section.mobile_banner;
        const headerBannerDesktop = (header_section?.desktop_banner?.url && header_section?.desktop_banner?.title) && header_section.desktop_banner;
        let headerBanner = !isMobile ? headerBannerDesktop : headerBannerMobile;
        let headerBannerBackgroundColor = header_section?.background_color;
        let bannerData = guestMemberBanner;
        const ENABLE_MULTI_HERO_BANNERS = featureFlags['enable-multiple-pp-hero-banners'];

        // User-type Hero Banners Section
        if (ENABLE_MULTI_HERO_BANNERS) {
            if (isNonPassportUser || isExpiredPassportUser) bannerData = guestMemberBanner;
            else if (isExpiringPassportUser) bannerData = expPassMemberBanner;
            else if (isActivePassportUser) bannerData = passMemberBanner;
            headerBannerBackgroundColor = bannerData?.background_color || '';

            if (underDT) headerBanner = bannerData?.tab_banner;
            else if (underTB) headerBanner = bannerData?.mobile_banner;
            else headerBanner = bannerData?.desktop_banner;
        }

        const classes = useStyles({ headerBannerBackgroundColor });

        // hero banner styles
        let bannerStyles = !isMobile ? classes.image : classes.imageMobile;
        if (ENABLE_MULTI_HERO_BANNERS) bannerStyles = classes.multiBannerImage;

        // Passport Details
        const passportDetails = {};
        // setting passport messaging and sku
        passportDetails.passportEnrolmentTerm = passportData?.duration_short_text || 'year';
        passportDetails.itemSku = passportData?.item_id;
        passportDetails.price = passportData?.price;
        // AB Pricing Test
        if (passportABData?.passport_item_sku && passportABData?.term_for_enrolment_page && passportABData?.price) {
            passportDetails.price = passportABData.price;
            passportDetails.passportEnrolmentTerm = passportABData.term_for_enrolment_page;
            passportDetails.itemSku = passportABData.passport_item_sku;
        }
        // Set Passport Sign in Data
        if (passportSignInData?.price && passportSignInData?.passportEnrolmentTerm && passportSignInData?.itemSku) {
            passportDetails.price = passportSignInData.price;
            passportDetails.passportEnrolmentTerm = passportSignInData.passportEnrolmentTerm;
            passportDetails.itemSku = passportSignInData.itemSku;
        }
        // Set Passport Campaign Data
        if (passportCampaignData?.price && passportCampaignData?.passportEnrolmentTerm && passportCampaignData?.itemSku) {
            passportDetails.price = passportCampaignData.price;
            passportDetails.passportEnrolmentTerm = passportCampaignData.passportEnrolmentTerm;
            passportDetails.itemSku = passportCampaignData.itemSku;

            if (passportCampaignData?.desktopBanner?.url
                && passportCampaignData?.desktopBanner?.title
                && passportCampaignData?.mobileBanner?.url
                && passportCampaignData?.mobileBanner?.title) {
                if (!isMobile) {
                    headerBanner = passportCampaignData.desktopBanner;
                } else {
                    headerBanner = passportCampaignData.mobileBanner;
                }
            }
        }

        if (!passportCampaignData?.enable && featureFlags['passport-google-optimize']) {
            // Google Optimize AB Test
            if (
                (activeABTests.loaded || activeABTests.timeout)
                && passportData?.ab_testing?.variant?.passport_referenced_sku?.[0]?.price && passportData?.ab_testing?.control?.passport_referenced_sku?.[0]?.price
            ) {
                const passport = { ...passportData };
                if (activeABTests.passportSkuTest === 'Rule_One') {
                    const passportVariant = passportData.ab_testing.variant.passport_referenced_sku[0];
                    passportDetails.itemSku = passportVariant.item_sku;
                    passportDetails.price = passportVariant.price;

                    if (passportVariant.price !== passport.price) {
                        passport.item_id = getSkuWithoutBrand(passportVariant.item_sku);
                        passport.price = passportVariant.price;
                        setPassportCodeAction(passport);
                    }
                } else {
                    const passportControl = passportData.ab_testing.control.passport_referenced_sku[0];
                    passportDetails.itemSku = passportControl.item_sku;
                    passportDetails.price = passportControl.price;

                    if (passportControl.price !== passport.price) {
                        passport.item_id = getSkuWithoutBrand(passportControl.item_sku);
                        passport.price = passportControl.price;
                        setPassportCodeAction(passport);
                    }
                }
            }
        }

        const isPassportMemberSignedIn = () => {
            if (isActivePassportUser && isPassportUserLoggedIn) {
                return true;
            }
            if (isPassportUserLoggedIn && passportMemberProfile?.membershipType === 'P') {
                return true;
            }
            return false;
        };

        // Under Hero Banner Section
        let underHeroBannerColor = header_section?.under_hero_banner_color || '#c35801';
        if (ENABLE_MULTI_HERO_BANNERS) underHeroBannerColor = bannerData.under_hero_banner_color || underHeroBannerColor;
        // continue shopping button
        const continueShoppingLink = (continue_shopping_link?.href && continue_shopping_link?.title)
            && continue_shopping_link;

        // dynamic messages for under hero banner
        const expiringPassportMember = dynamic_messaging?.expiring_passport_member;
        const currentPassportMember = dynamic_messaging?.current_passport_member;
        const formerPassportMember = dynamic_messaging?.former_passport_member;
        const guestUser = dynamic_messaging?.guest_user;

        const showPassportPrice = !dynamic_messaging?.hide_passport_price;

        // if passport item is already added to cart
        const passportCartPrice = passportOrderItem?.[0]?.cartItems?.[0]?.product?.salePrice || passportDetails?.price || '';
        let passportAddedCopy = (
            <>
                Celebrations Passport has been added to your cart for ${passportCartPrice} for a one year membership.
                At the end of your term, your membership will automatically renew each year at the price of $29.99 or the current market rate.
                Click here for a complete description of the Celebrations Passport program{' '}
                <button onClick={() => setOpen(true)} type="button" className={classes.noTransform}>Terms and Conditions</button>
                , our {' '}
                <Link
                    tabindex="-1"
                    to="/About-Us-Privacy-Policy"
                    aria-hidden="true"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Privacy Notice
                </Link>
                , and {' '}
                <Link
                    tabindex="-1"
                    to="/About-Us-Privacy-Policy#CoLoyalty"
                    aria-hidden="true"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Additional Info for CO Consumers
                </Link>.
            </>
        );

        const cmsPassportAddedCopy = dynamic_messaging?.passport_added_copy;
        if (cmsPassportAddedCopy) {
            const sanitizedCopy = cmsPassportAddedCopy.replace('{passport_price}', `$${passportDetails?.price}`).replace('{passport_added_price}', `$${passportCartPrice}`);
            passportAddedCopy = ReactHtmlParser(sanitizedCopy);
        }

        const [dynamicBannerInfo, setDynamicBannerInfo] = useState({});
        const { headerMessage, userBtn } = dynamicBannerInfo;

        const dynamicSecondaryCTABtn = (message, btnMsg, topMessage, bottomMsg) => (
            <>
                {topMessage && <p className={classes.smallText}>{topMessage}</p>}
                <p className={classes.smallText}>
                    {message}{' '}
                    <span
                        role="button"
                        tabIndex="0"
                        className={classes.userBtn}
                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                        onClick={() => loginByRedirect()}
                        onKeyPress={(e) => {
                            if (e?.key === 'Enter') {
                                loginByRedirect();
                            }
                        }}
                    >
                        {btnMsg}
                    </span>
                </p>
                {bottomMsg && <p className={classes.smallText}>{bottomMsg}</p>}
            </>
        );

        const dynamicBannerMessaging = () => {
            const dynamicPageInfo = {};

            if (isActivePassportUser && !isAuthenticated) { // current member
                dynamicPageInfo.headerMessage = currentPassportMember?.under_hero_banner_text || 'Sign in and do your thing. Great gifts, exclusive perks and free shipping await.';
                dynamicPageInfo.userBtn = dynamicSecondaryCTABtn('Not a member?', 'Sign Up', null, currentPassportMember?.secondary_cta_copy || 'Automatically Renews at $29.99/Year after First Year');
                dynamicPageInfo.userType = 'member';
            } else if (isPassportUserLoggedIn && isExpiringPassportUser && isPassportRenewalAllowed) { // expiring passport member
                dynamicPageInfo.headerMessage = expiringPassportMember?.under_hero_banner_text || 'Unlock free standard shipping/no service charge, plus exclusive perks and rewards.';
                dynamicPageInfo.userType = 'expiring';
            } else if (isExpiredPassportUser) { // expired / former member
                dynamicPageInfo.headerMessage = formerPassportMember?.under_hero_banner_text || 'Unlock free standard shipping/no service charge, plus exclusive perks and rewards.';
                if (!isAuthenticated) dynamicPageInfo.userBtn = dynamicSecondaryCTABtn('Already a Member?', 'Sign In', formerPassportMember?.secondary_cta_copy || 'Automatically Renews at $29.99/Year after First Year');
                dynamicPageInfo.userType = 'expired';
            } else if (passportMemberProfile.membershipType === 'G') { // guest
                dynamicPageInfo.headerMessage = guestUser?.under_hero_banner_text || 'Great gifts, exclusive perks and free standard shipping/no service charge await.';
                dynamicPageInfo.userBtn = dynamicSecondaryCTABtn('Already a Member?', 'Sign In', null, guestUser?.secondary_cta_copy || 'Automatically Renews at $29.99/Year after First Year');
                dynamicPageInfo.userType = 'guest';
            } else {
                dynamicPageInfo.headerMessage = header_section?.heading || 'Unlimited free shipping is just the start of discovering more perfect gifts.';
            }

            return dynamicPageInfo;
        };

        useEffect(() => {
            setDynamicBannerInfo(dynamicBannerMessaging());
        }, [isAuthenticated]);

        const { userType = '' } = dynamicBannerInfo;

        // Passport CTA Button Logic
        const passportButton = (user) => {
            let btnText = 'Sign up for just';
            if (user === 'expired') btnText = formerPassportMember?.cta_button_text || 'Rejoin for just';
            if (user === 'expiring') btnText = expiringPassportMember?.cta_button_text || 'Renew early for just';
            if (user === 'guest') btnText = guestUser?.cta_button_text || 'Join now for just';

            const isMember = user === 'member';
            let cta_btn = btnText;
            let price = `$${passportDetails.price}`;
            if (featureFlags['enable-passport-term']?.enrollmentPage) price = `${price}/${passportDetails.passportEnrolmentTerm}`;
            if (showPassportPrice) {
                cta_btn = cta_btn.includes('{passport_price}') ? cta_btn.replace('{passport_price}', price) : `${cta_btn} ${price}`;
            }

            return (
                <>
                    {isMember ? (
                        <Button
                            className={classes.addCartBtn}
                            variant="contained"
                            style={{ color: underHeroBannerColor }}
                            onClick={() => loginByRedirect()}
                        >
                            {currentPassportMember?.cta_button_text || 'Sign In'}
                        </Button>
                    )
                        : (
                            <Button
                                className={classes.addCartBtn}
                                variant="contained"
                                disabled={passportError.length > 0}
                                style={{ color: underHeroBannerColor }}
                                onClick={() => {
                                    if (!loading) setLoading(true);
                                    if (passportSideBarStatus) {
                                        trackPassport({
                                            eventCategory: 'Passport Click',
                                            eventAction: 'Passport Sidebar Test CONTROL',
                                            eventLabel: label,
                                        });
                                        triggerSubscription('passportSideBar', passportDetails?.itemSku);
                                    } else {
                                        trackPassport({
                                            eventCategory: 'Passport Click',
                                            eventAction: 'Passport Sidebar Test VARIANT',
                                            eventLabel: label,
                                        });
                                        triggerSubscription('passportPage', passportDetails?.itemSku);
                                    }
                                }}
                            >
                                {cta_btn}
                                {(loading && !passportError)
                                    && <CircularProgress size={24} color="inherit" className={classes.loadingCircle} />}
                            </Button>
                        )}
                </>
            );
        };

        const goToCheckoutBtn = (
            <Button
                className={classes.addCartBtn}
                variant="contained"
                style={{ color: underHeroBannerColor }}
                onClick={() => {
                    if (loading) setLoading(false);
                    // Close side banner
                    if (passportSideBarStatus) closePassportSideBanner();

                    if (passportInCart?.[0]?.ordersId) {
                        history.push(`/checkout/cart/${passportInCart[0].ordersId}`);
                    }
                }}
            >
                Go To Checkout
            </Button>
        );

        const continueShoppingBtn = (
            <Button
                className={classes.addCartBtn}
                variant="contained"
                style={{ color: underHeroBannerColor }}
                onClick={() => {
                    if (loading) setLoading(false);
                    // Close side banner
                    if (passportSideBarStatus) {
                        closePassportSideBanner();
                        return;
                    }
                    trackPassport({
                        eventCategory: 'Test Impression',
                        eventAction: continueShoppingLink.title,
                        eventLabel: label,
                    });
                    history.push(continueShoppingLink.href);
                }}
            >
                Continue Shopping
            </Button>
        );

        const continueCheckoutBtn = (
            <div className={!isMobile ? classes.buttonContainer : classes.buttonContainerMobile}>
                {goToCheckoutBtn}
                {continueShoppingLink
                    && continueShoppingBtn}
            </div>
        );

        const addToCartBtn = (
            <div className={!isMobile ? classes.buttonContainer : classes.buttonContainerMobile}>
                {!isYotpoEnabled && passportButton('default')}
                {!isPassportMemberSignedIn() && isYotpoEnabled
                    ? passportButton(userType) : null}

            </div>
        );

        const addToCartButton = passportInCart.length < 1
            ? addToCartBtn
            : (
                <>
                    {continueCheckoutBtn}
                    <p className={`${classes.smallText} ${classes.underText}`}>
                        {passportAddedCopy}
                    </p>
                </>
            );

        const isSignedIn = isAuthenticated || passportMemberProfile?.membershipType !== 'G';

        // EVENT BANNER - Section Three in CMS
        const isEventBannerEnabled = section_three?.enable || false;

        // const IS_YOTPO_APP_DOWNLOAD_BANNER_ENABLED = featureFlags['is-yotpo-app-download-banner-enabled'];
        const IS_YOTPO_FAQ_MODULE_ENABLED = featureFlags['is-yotpo-faq-module-enabled'];
        // const IS_CELEBRATION_PASSPORT_LEARN_MORE_BANNER_ENABLED = featureFlags['is-celebration-passport-learn-more-banner-enabled'];
        // const IS_SECTION_THREE_BANNER_ENABLED = featureFlags['is-passport-page-section-three-banner-enabled'];
        const isPlaceholderBannerEnabled = placeholder_banner?.enable || false;

        // Passport App Banner Section (placed just above the footer banner)
        // const passportAppBanner = !isMobile
        //     ? (passport_app_banner?.desktop_banner?.url
        //         && passport_app_banner?.desktop_banner?.title)
        //         && passport_app_banner?.desktop_banner
        //     : (passport_app_banner?.mobile_banner?.url
        //         && passport_app_banner?.mobile_banner?.title)
        //     && passport_app_banner?.mobile_banner;

        // const hasPassportBannerAppStyle = !isMobile ? {
        //     margin: passport_app_banner?.desktop_banner_margin || 0,
        // } : {
        //     margin: passport_app_banner?.mobile_banner_margin || 0,
        // };

        // const passportAppBannerLink = passport_app_banner?.link?.title && passport_app_banner?.link?.href && passport_app_banner?.link;
        // const renderPassportAppBanner = () => <img className={!isMobile ? classes.passportAppImage : classes.imageMobile} src={passportAppBanner?.url} alt={passportAppBanner?.title} />;

        // Footer Section
        const brandIcons = (footer_section?.brand_icons?.icon?.length > 0)
            ? footer_section.brand_icons.icon
            : [];
        const transform = (nod) => {
            if (nod?.type === 'tag' && nod?.name === 'p') {
                const value = nod?.children?.map(nodeToString)?.join('');
                if (value && typeof value === 'string' && value.toLowerCase()?.includes('chat')) {
                    return <RenderChatAsLink value={value} operatingSystem={operatingSystem} currentBrand={currentBrand} featureFlags={featureFlags} customerData={customerData} />;
                }
            }
            return undefined;
        };
        const disclaimerText = footer_section?.disclaimer
            && ReactHtmlParser(footer_section.disclaimer, { transform });
        const footerSectionColor = footer_section?.fob_banner_color || '#7c2709';
        const footerSectionHeading = footer_section?.heading;
        const footerSectionSubHeader = footer_section?.sub_header;
        const footerSectionBackgroundImgUrl = footer_section?.fob_background_image_url?.url; // desktop image
        const footerSectionMobileBackgroundImgUrl = footer_section?.mobile_fob_background_image_url?.url;

        const renderFOBBanner = () => {
            if (footerSectionBackgroundImgUrl) {
                if (!isMobile) {
                    return { backgroundImage: `url(${footerSectionBackgroundImgUrl}` };
                }
                return { backgroundImage: `url(${footerSectionMobileBackgroundImgUrl})` };
            }
            return { backgroundColor: footerSectionColor };
        };

        return (
            <>
                {seoData.seo_searchable && <GraphqlSeoHeadTags seoData={seoData} />}
                <div className={!isMobile ? classes.root : classes.rootMobile}>
                    {/* Hero */}
                    <div className={classes.hero}>
                        <div className={classes.subHero}>
                            <ResponsiveImage
                                className={bannerStyles}
                                path={headerBanner?.url}
                                alt={headerBanner?.description || 'Passport Hero Image'}
                            />
                        </div>
                    </div>

                    {/* Under Hero Banner */}
                    <div className={!isMobile ? classes.underHeroBanner : classes.underHeroBannerMobile} style={{ backgroundColor: underHeroBannerColor }}>
                        {headerMessage && <h2>{headerMessage}</h2>}
                        {addToCartButton}
                        {!isSignedIn && passportInCart.length < 1 ? userBtn : null}
                        {passportError
                            && (
                                <div className={classes.errorContainer}>
                                    <Error className={classes.errorIcon} color="error" />
                                    <p>{passportError}</p>
                                </div>
                            )}
                    </div>
                    {/* Event Banner Space */}
                    {!passportSideBarStatus && isEventBannerEnabled && <PlaceholderBanner bannerInfo={section_three} classes={classes} isMobile={isMobile} />}

                    {/* Yotpo Modules */}
                    {!passportSideBarStatus && isYotpoEnabled && <YotpoModules classes={classes} featureFlags={featureFlags} isMobile={isMobile} history={history} />}

                    {/* Carousel */}
                    {!passportSideBarStatus && carousel_images?.enable_carousel && <PassportCarousel imageObj={carousel_images} isAuthenticated={isAuthenticated} isActivePassportUser={isActivePassportUser} />}

                    {/* Referral Yotpo Module */}
                    {!passportSideBarStatus && featureFlags['is-yotpo-referral-module-enabled'] && (
                        <>
                            {
                                isAuthenticated ? (
                                    <div className={classes.referralModule}>
                                        <div className="yotpo-widget-instance" data-yotpo-instance-id="103326" />
                                    </div>
                                )
                                    : <YotpoStaticReferralBanner bannerInfo={yotpo_referral_module} classes={classes} isMobile={isMobile} loginByRedirect={loginByRedirect} />
                            }
                        </>
                    )}

                    {/* Video */}
                    {!passportSideBarStatus && data?.video && <PassportVideo classes={classes} video={data?.video} presentationFamily={presentationFamily} />}

                    {/* Placeholder Banner */}
                    {!passportSideBarStatus && isPlaceholderBannerEnabled && <PlaceholderBanner bannerInfo={placeholder_banner} classes={classes} isMobile={isMobile} />}

                    {/* Event Grid */}
                    {!passportSideBarStatus && <PassportEventGrid brandIcons={brandIcons} gridData={event_grid} isMobile={isMobile} />}

                    {/* Campaign Section  ???? */}
                    {!passportSideBarStatus && isCampaignValid
                        && (
                            campaignLink
                                ? (
                                    <Button
                                        onClick={() => {
                                            history.push(campaignLink.href);
                                        }}
                                        style={{
                                            backgroundColor: campaignDetails?.color,
                                        }}
                                        className={classes.detailsContainer}
                                    >
                                        <img className={classes.detailsImage} src={campaignDetails?.banner?.url} alt={campaignDetails?.banner?.description || 'Campaign Image'} />
                                    </Button>
                                )
                                : (
                                    <div
                                        className={classes.detailsContainer}
                                        style={{
                                            backgroundColor: campaignDetails?.color,
                                        }}
                                    >
                                        <img className={classes.detailsImage} src={campaignDetails?.banner?.url} alt={campaignDetails?.banner?.description || 'Campaign Image'} />
                                    </div>
                                )
                        )}
                    {/* Passport App Banner Section (placed just above the footer section) */}
                    {/* {IS_CELEBRATION_PASSPORT_LEARN_MORE_BANNER_ENABLED && (
                        <div className={classes.bannerContainer} style={hasPassportBannerAppStyle}>
                            {passportAppBannerLink ? <a href={passportAppBannerLink.href}>{renderPassportAppBanner()}</a> : renderPassportAppBanner()}
                        </div>
                    )} */}

                    {/* Footer Section */}
                    <div className={classes.footerSection} style={renderFOBBanner()}>
                        {footerSectionHeading && <h3 style={{ textTransform: 'uppercase', fontFamily: 'Montserrat', fontWeight: 'bold' }}>{footerSectionHeading}</h3>}
                        <p style={{ marginTop: '0' }}>{footerSectionSubHeader}</p>
                        <div className={!isMobile ? classes.brandImageContainer : classes.brandImageContainerMobile}>
                            {
                                brandIcons?.map((icon) => {
                                    if (icon?.url && icon?.title) {
                                        return (
                                            <img src={icon.url} alt={icon?.description || ''} key={icon.title} />
                                        );
                                    }
                                    return null;
                                })
                            }
                        </div>
                        {addToCartButton}
                        <div style={{ marginTop: '-15px' }}>
                            {!isSignedIn && passportInCart.length < 1 ? userBtn : null}
                        </div>
                        {passportError
                            && (
                                <div className={classes.errorContainer}>
                                    <Error className={classes.errorIcon} color="error" />
                                    <p>{passportError}</p>
                                </div>
                            )}
                    </div>

                    {/* FAQ */}
                    {!passportSideBarStatus && IS_YOTPO_FAQ_MODULE_ENABLED && <PassportYotpoFAQ />}

                    {/* Disclaimer Section */}
                    {!passportSideBarStatus && (
                        <div className={classes.disclaimerSection}>
                            {disclaimerText}
                            {isCampaignValid
                                && campaignDetails?.terms
                                && (ReactHtmlParser(campaignDetails?.terms))}
                        </div>
                    )}
                    {open
                        && (
                            <PassportTncModal
                                dialogOpen={open}
                                closeModal={() => setOpen(false)}
                            />
                        )}

                    {/* Disclaimer / SEO Copy */}
                    {featureFlags['is-passport-page-seo-footer-enabled'] && (
                        <PassportBlockSeoFooter data={data} classes={classes} />
                    )}
                </div>
            </>
        );
    }
    return null;
};

const mapStateToProps = (state) => ({
    activeABTests: getActiveABTests(state),
    featureFlags: getFeatureFlags(state),
    passportData: getPassportData(state),
    passportError: getPassportError(state),
    passportInCart: getPassportItem(state),
    passportABData: getPassportABData(state),
    passportSignInData: getPassportSignInData(state),
    passportCampaignData: getPassportCampaignData(state),
    passportSideBarStatus: getPassportSideBarStatus(state),
    isPassportUserLoggedIn: getIsPassportUserLoggedIn(state),
    passportMemberProfile: getPassportMemberProfile(state),
    isAuthenticated: getIsAuthenticatedStatus(state),
    presentationFamily: getPresentationFamily(state),
    isPassportRenewalAllowed: getIsPassportRenewalAllowed(state),
    passportOrderItem: getPassportItem(state),
});

const mapDispatchToProps = (dispatch) => ({
    trackPassport: bindActionCreators(trackEvent, dispatch),
    setPassportCodeAction: bindActionCreators(setPassportData, dispatch),
    callClearPassportError: bindActionCreators(clearPassportError, dispatch),
    closePassportSideBanner: bindActionCreators(closePassportSideBar, dispatch),
    triggerSubscription: bindActionCreators(triggerPassportSubscription, dispatch),
});

PassportEnrollmentPage.propTypes = {
    activeABTests: object.isRequired,
    history: object.isRequired,
    data: shape({
        header_section: shape({
            heading: string,
            desktop_banner: shape({
                url: string,
                title: string,
            }),
        }),
        section_three: shape({
            heading: string,
            url: string,
            title: string,
        }),
        campaign_section: shape({
            heading: string,
            url: string,
            title: string,
        }),
        footer_section: shape({
            heading: string,
            url: string,
            title: string,
        }),
    }),
    triggerSubscription: func.isRequired,
    passportCampaignData: shape({
        id: string,
        itemSku: string,
        price: string,
        term: string,
    }),
    passportData: shape({
        item_id: string,
        duration_short_text: string,
        passport_messaging: shape({
            user_with_expired_passport_subscription: shape({
                message_for_enrolment_page: string,
                passportsku: string,
            }),
            user_whose_passport_subscription_is_about_to_expire: shape({
                message_for_enrolment_page: string,
                passportsku: string,
            }),
            user_who_never_bought_passport_subscription: shape({
                message_for_enrolment_page: string,
                passportsku: string,
            }),
        }),
    }),
    trackPassport: func.isRequired,
    passportInCart: arrayOf(shape({
        cartItems: {
            ordersId: string,
        },
    })),
    passportSignInData: shape({
        enable: bool,
        price: number,
        itemSku: string,
        passportEnrolmentTerm: string,
        checkoutPageMessage: string,
        productPageMessage: string,
    }),
    passportSideBarStatus: bool,
    closePassportSideBanner: func.isRequired,
    passportError: string,
    callClearPassportError: func.isRequired,
    passportABData: shape({
        price: number,
        passport_item_sku: string,
        term_for_enrolment_page: string,
    }),
    featureFlags: shape({
        'is-passport-conditional-offer-on': bool,
    }),
    isPassportUserLoggedIn: bool,
    passportMemberProfile: shape({
        membershipType: string,
        email: string,
    }),
    presentationFamily: string,
    isAuthenticated: bool.isRequired,
    isYotpoEnabled: bool,
    setPassportCodeAction: func.isRequired,
    isPassportRenewalAllowed: bool,
    passportOrderItem: object.isRequired,
};

PassportEnrollmentPage.defaultProps = {
    data: {},
    passportCampaignData: {},
    passportData: {},
    passportInCart: [],
    passportSignInData: {},
    featureFlags: {},
    passportSideBarStatus: false,
    passportError: '',
    passportABData: {},
    isPassportUserLoggedIn: false,
    passportMemberProfile: {},
    isYotpoEnabled: false,
    presentationFamily: 'flower',
    isPassportRenewalAllowed: false,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PassportEnrollmentPage),
);
