/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { all } from 'redux-saga/effects';

import { accountSagas } from '../../app/pages/Account/state/sagas';
import { duck as tmDuck } from '../ducks/TagManager';
import memberWatcherSagas from '../ducks/Member/sagas';
import { commonWatcherSagas as commonSagas } from '../ducks/Common';
import { cartWatcherSagas as cartSagas } from '../ducks/Cart';
import { orderWatcherSagas as orderSagas } from '../ducks/Order';
import { paymentWatcherSagas as paymentSagas } from '../ducks/Payment';
import { recipientWatcherSagas } from '../ducks/Recipient';
import { watchers as checkoutSagas } from '../ducks/Checkout';
import SCIWatchers from '../ducks/SCI/SCI-Operations';
import CGCWatcher from '../ducks/CGC/CGC-Operations';
import * as app from '../ducks/App';
import clickStreamWatchers from '../ducks/TagManager/ducks/ClickStreamEvents';
import { addToCartWatchers } from '../ducks/AddToCart';
import { passportWatchers } from '../ducks/Passport';

export const universalSagas = [
    ...addToCartWatchers,
    ...passportWatchers,
    ...app.watchers,
    ...commonSagas,
    ...cartSagas,
    ...orderSagas,
    ...paymentSagas,
    ...recipientWatcherSagas,
    ...checkoutSagas,
    ...memberWatcherSagas,
    accountSagas,
    ...tmDuck.operations.watchers,
    ...SCIWatchers,
    ...CGCWatcher,
    ...clickStreamWatchers,
];

export default function* rootSaga() {
    yield all(universalSagas);
}
