/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const MAX_INVALID_PROMO_CODES_ATTEMPTS = 3;
const SUGGEST_PROMO_CODE_USE_CASE = 'suggest_promo_code';

const USE_CASES = [
    {
        name: SUGGEST_PROMO_CODE_USE_CASE,
        featureFlag: 'is-aigo-promo-code-usecase-enabled',
    },
];

export { USE_CASES, SUGGEST_PROMO_CODE_USE_CASE, MAX_INVALID_PROMO_CODES_ATTEMPTS };
