/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from './restClient';

const RESOURCE_ROOT_FACADE = '/checkout';
const RESOURCE_PAGE_FACADE = '/cgc/v2';
const RESOURCE_PAGE_ACTION = '/greeting/label/';

/**
 * getCartNoId
 * url - /checkout/{apiversion}/cart?brand={brandId}
 *
 * @param {Object} args
 * @param {Object} args.env
 * @param {String} args.jwtToken
 */
const getLabelGreetingCard = ({
    wcEnv,
    jwtToken,
    keyId,
}) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE;

    const resourcePage = `${RESOURCE_PAGE_FACADE}${RESOURCE_PAGE_ACTION}${keyId}`;

    return restClient.getRequest(env, resourcePage, jwtToken)
        .then((response) => response);
};

export default getLabelGreetingCard;
