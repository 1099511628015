/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// Overrides the Material UI components default styles with colors from CMS (if available)
export default (palette) => ({
    MuiButton: {
        containedPrimary: {
            '&:hover': {
                backgroundColor: palette.cms?.primary_hover,
            },
        },
        containedSecondary: {
            '&:hover': {
                backgroundColor: palette.cms?.secondary_hover,
            },
        },
        textSecondary: {
            '&:hover': {
                textDecoration: 'underline',
                backgroundColor: 'transparent',
            },
        },
    },
});
