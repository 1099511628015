/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from './restClient';

const RESOURCE_ROOT_CARTSERVICE = '/checkout';
const RESOURCE_VERSION_CARTSERVICE = '';
const RESOURCE_PAGE_CARTSERVICE = '/availability';
const RESOURCE_PAGE_ACTION = '/deliverycalendar';

/**
 * displayCalendar
 * url - /availability/deliveryCalendar?skuProductCode={skuProductCode}&brandCode={brandCode}&zipCode={zipCode}&locationType={locationType}&startDate=${startDate}&customerType={customerType}
 *
 * @param {Object} args
 * @param {String} args.jwtToken
 * @param {String} args.cartId
 * @param {String} args.skuProductCode
 * @param {String} args.brandCode
 * @param {String} args.zipcode
 * @param {String} args.locationType
 * @param {String} args.startDate
 * @param {String} args.customerType
 */

// deliveryCalendar?variant=flex2&skuProductCode=101980L&brandCode=1001&zipCode=11514&locationType=Residence&deliveryDate=2023-01-31&brand=flowers&entryBrand=flowers
const availablityDeliveryCalendar = ({
    env, jwtToken, brandCode, customerType,
    productCode, locationType, zipCode, startDate,
    variantId,
}) => {
    const envObj = env || {};

    envObj.rootUri = `${RESOURCE_ROOT_CARTSERVICE}${RESOURCE_VERSION_CARTSERVICE}`;

    const payload = {
        brandCode,
        productCode,
        locationType,
        zipCode,
        startDate,
        customerType,
    };
    if (variantId && variantId !== '') {
        payload.variantId = variantId;
    }

    const resourcePage = `${RESOURCE_PAGE_CARTSERVICE}${RESOURCE_PAGE_ACTION}`;

    return restClient.postRequest(envObj, resourcePage, jwtToken, payload)
        .then((response) => response);
};

export default availablityDeliveryCalendar;
