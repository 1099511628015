/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const APPLY_GIFT_CARD_ACTION = 'mbp-checkout/APPLY/GIFTCARD';
const REMOVE_GIFT_CARD_ACTION = 'mbp-checkout/REMOVE/GIFTCARD';
const PROCESS_PAYMENT_PAGE = 'PROCESS_PAYMENT_PAGE';
const PLACE_ORDER = 'PLACE_ORDER';
const PLACE_ORDER_COMPLETE = 'mbp-checkout/PLACE/ORDER/COMPLETE';
const CLICKSTREAM_ORDER_COMPLETE = 'mbp-pwa-ui/CLICKSTREAM/ORDER_COMPLETE';
const UPDATE_COMPLETE_PAYMENT_DETAILS = 'UPDATE_COMPLETE_PAYMENT_DETAILS';
const LOAD_COMPLETE_PAYMENT_DETAILS = 'LOAD_COMPLETE_PAYMENT_DETAILS';
const CHECKOUT_PAYMENT_METHODS_LOAD = 'mbp-checkout/PAYMENT/METHODS/LOAD';
const ACTIVE_PAYMENT_METHOD = 'mbp-checkout/ACTIVE_PAYMENT_METHOD';
const INACTIVE_PAYMENT_METHOD = 'mbp-checkout/INACTIVE_PAYMENT_METHOD';
const SHOW_ORDER_CONFIRMATION = 'SHOW_ORDER_CONFIRMATION';
const APPLY_GIFT_CARD_PROCESS_COMPLETED = 'mbp-checkout/APPLY_GIFT_CARD_PROCESS_COMPLETED';
const REMOVE_GIFT_CARD_PROCESS_COMPLETED = 'mbp-checkout/REMOVE_GIFT_CARD_PROCESS_COMPLETED';
const CHECKOUT_PAYMENT_METHODS_VALIDATE_API_DESKTOP = 'mbp-checkout/VALIDATE_API_DESKTOP';
const PROCESS_NBCU_MOVIE = 'mbp-checkout/PROCESS/NBCU/MOVIE';
const POST_ORDER_COMPLETE_ATTRIBUTION_DATA = 'mbp-checkout/POST/ORDER/COMPLETE/ATTRIBUTTION/DATA';

const SKIP_VALIDATE_CART = 'MFE/MBP-CHECKOUT/SKIP_VALIDATE_CART';

export default {
    PROCESS_PAYMENT_PAGE,
    PLACE_ORDER,
    PLACE_ORDER_COMPLETE,
    CLICKSTREAM_ORDER_COMPLETE,
    UPDATE_COMPLETE_PAYMENT_DETAILS,
    LOAD_COMPLETE_PAYMENT_DETAILS,
    ACTIVE_PAYMENT_METHOD,
    INACTIVE_PAYMENT_METHOD,
    APPLY_GIFT_CARD_ACTION,
    REMOVE_GIFT_CARD_ACTION,
    CHECKOUT_PAYMENT_METHODS_LOAD,
    SHOW_ORDER_CONFIRMATION,
    APPLY_GIFT_CARD_PROCESS_COMPLETED,
    REMOVE_GIFT_CARD_PROCESS_COMPLETED,
    CHECKOUT_PAYMENT_METHODS_VALIDATE_API_DESKTOP,
    PROCESS_NBCU_MOVIE,
    POST_ORDER_COMPLETE_ATTRIBUTION_DATA,
    SKIP_VALIDATE_CART,
};
