/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

export const registerClickStreamEvent = async ({ jwt, payload }) => {
    const wcEnv = { rootUri: '/' };
    const resourcePath = 'personalize/customerEvents/events/publish';
    restClient.postFacade(wcEnv, resourcePath, jwt, payload);
};

export default {};
