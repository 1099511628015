/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { appWatcherSagas } from './ducks/App';

import { checkoutWatcherSagas } from './ducks/Checkout';
import { expressCheckoutWatcherSagas } from './ducks/ExpressCheckout';
import { paymentWatcherSagas } from './ducks/Payment';
import { errorWatcherSagas } from './ducks/Common/ducks/Error';

const watcherSagas = [
    ...appWatcherSagas,
    ...checkoutWatcherSagas,
    ...expressCheckoutWatcherSagas,
    ...paymentWatcherSagas,
    ...errorWatcherSagas,
];

export default watcherSagas;
