/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { oneOfType, string, number } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import SkeletonStyles from './SkeletonStyles';

const useStyles = makeStyles({
    ...SkeletonStyles,
    root: (styles) => ({
        width: styles.width || '98%',
        maxWidth: styles.maxWidth || '1400px',
        height: styles.height || '0px',
        display: 'flex',
        margin: styles.margin || '15px auto',
    }),
});
const GenericSkeleton = ({
    height, width, className, id, maxWidth, margin,
}) => {
    const classes = useStyles({
        height, width, maxWidth, margin,
    });
    return (
        <div className={`${classes.root} ${classes.shimmer} ${className}`} data-testid={id} />
    );
};

GenericSkeleton.propTypes = {
    width: oneOfType([string, number]),
    height: oneOfType([string, number]),
    maxWidth: string,
    className: string,
    id: string,
    margin: string,
};

GenericSkeleton.defaultProps = {
    className: '',
    width: null,
    height: null,
    id: '',
    maxWidth: '',
    margin: '',
};

export default GenericSkeleton;
