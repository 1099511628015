/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import {
    fork, takeLatest, call, put,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';

import {
    getGooglePayClientId,
    loadGooglePayClientId,
    clearGooglePayClientId,
    submitGooglePayment,
} from './GooglePay-Actions';
import {
    getfirstAndLastName,
    normalizeGooglePaymentBillingAddress,
} from './GooglePay-Helper';

import { placeOrder } from '../../Payment-Actions';

import * as showLoadingMessageActions from '../../../Common/ducks/ShowLoadingMessage/ShowLoadingMessage-Actions';
import * as authOperations from '../../../Member/ducks/Auth/Auth-Operations';

// TODO: Create Separate Actions, helpers and Selectors at injection stage.
import { removeMaskForPhoneNumber } from '../../../Checkout/Checkout-Helpers';
import { updateBillingForm } from '../../../Checkout/ducks/Payment/ducks/BillingAddressForm/BillingAddressForm-Actions';
import { setPaymentMethod } from '../../../Checkout/ducks/Payment/ducks/PaymentForm/PaymentForm-Actions';
import orderClient from '../../../../../apis/checkout-apis/orderClient';
import cartServices from '../../../../../apis/cart-service-apis/cartServices';

export const setRecipientAddress = async (jwtToken, orderId, orderItemId, shippingAddress) => {
    let response = {};
    try {
        const recipientName = getfirstAndLastName(shippingAddress?.name || '');
        let zipCode = shippingAddress.postalCode;
        let state = shippingAddress.administrativeArea;

        let phone = '0000000000';
        if (shippingAddress.phoneNumber) {
            phone = removeMaskForPhoneNumber(shippingAddress.phoneNumber);
        }

        if (!['US', 'CA'].includes(shippingAddress.countryCode)) {
            zipCode = '00000';
            state = 'OS';
        }

        const recipientAddress = {
            address1: shippingAddress.address1 || '.',
            address2: shippingAddress.address2 || '',
            city: shippingAddress.locality,
            country: shippingAddress.countryCode,
            dpvIndicator: 'N',
            entryId: '',
            firstName: recipientName.firstName,
            isAddressVerified: 'U', // Unverified
            lastName: recipientName.lastName,
            locationType: 'Residence',
            nameOfLocation: '',
            organizationName: '',
            phone,
            state,
            zipCode,
            addressId: '',
        };

        const recipientResponse = await orderClient.addRecipient(
            {},
            jwtToken,
            orderItemId,
            recipientAddress,
        );
        response = recipientResponse?.data;
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'setRecipientAddress',
            message: 'Error Saving Recipient address in Google Pay',
            orderId,
        });

        response = {
            transactionState: 'ERROR',
            error: {
                intent: 'PAYMENT_AUTHORIZATION',
                message: 'Error Saving Recipient address in Google Pay',
                reason: 'SET_RECIPIENT_ADDRESS_INVALID',
            },
        };
    }
    return response;
};

function* workerGetGooglePayClientId() {
    try {
        mbpLogger.logDebug({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'workerGetGooglePayClientId',
            message: 'Get Google Pay Client Id',
        });

        // show loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        const jwtToken = yield call(authOperations.checkJWT);

        const payload = {
            env: {},
            jwtToken,
            paymentMethod: 'GooglePay',
        };

        // Get Paypal Session Client Token
        const response = yield call(cartServices.paymentClientToken, payload);
        const { clientToken } = response.data;
        yield put(clearGooglePayClientId());
        yield put(loadGooglePayClientId(clientToken));

        // hide loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'workerGetGooglePayClientId',
            jsError: ex,
            message: 'Failed in getting google client id',
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    }
}

function* workerSubmitGooglePayment(action) {
    try {
        mbpLogger.logDebug({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'workerSubmitGooglePayment',
            message: 'Submit Google Pay Payment',
        });

        const {
            paymentDataResponse,
            result,
            history,
            orderId,
            orderItemId,
            isCheckout,
        } = action.data;

        const jwtToken = yield call(authOperations.checkJWT);

        if (!isCheckout) {
            yield put(setPaymentMethod({
                id: 'GooglePay',
                name: 'Google Pay',
            }));

            yield call(setRecipientAddress, jwtToken, orderId, orderItemId, paymentDataResponse.shippingAddress);
        }

        const billingAddress = normalizeGooglePaymentBillingAddress(paymentDataResponse);
        billingAddress.isFormValid = true;

        const paymentCardType = result?.details?.cardType || '';
        const cardlastFourDigit = result?.details?.lastFour || '';

        if (isCheckout) {
            yield put(updateBillingForm(billingAddress));
        }

        const placeOrderConfig = {
            history,
            isCheckout,
            params: {},
            disablePaymentFields: false,
            source: 'PWA',
            googlePayment: {
                nonce: result.nonce,
                billingAddress,
                paymentCardType,
                cardlastFourDigit,
            },
        };

        yield put(placeOrder(placeOrderConfig));
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'workerSubmitGooglePayment',
            jsError: ex,
            message: 'Failed submitting GooglePay payment',
        });
    }
}

function* watcherSubmitGooglePayment() {
    yield takeLatest(submitGooglePayment().type, workerSubmitGooglePayment);
}

function* watcherGetGooglePayClientId() {
    yield takeLatest(getGooglePayClientId().type, workerGetGooglePayClientId);
}

const watchers = [
    fork(watcherGetGooglePayClientId),
    fork(watcherSubmitGooglePayment),
];

export {
    watchers,
};

export default {};
