/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const RESOURCE_ROOT_FACADE_MERCHANT = '/checkout';
const RESOURCE_PAGE_FACADE_MERCHANT = '/payment';
const RESOURCE_PAGE_ACTION_MERCHANT = '/applePay/merchant';

export const validateMerchantApplePay = (wcEnv, jwtToken, data) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE_MERCHANT;
    const resourcePage =    RESOURCE_PAGE_FACADE_MERCHANT + RESOURCE_PAGE_ACTION_MERCHANT;

    return restClient
        .postFacade(env, resourcePage, jwtToken, data)
        .then((response) => response);
};

const RESOURCE_ROOT_FACADE_PAYMENT = '/checkout';
const RESOURCE_PAGE_FACADE_PAYMENT = '/payment';
const RESOURCE_PAGE_ACTION_PAYMENT = '/applePay/paymethod';

export const savePayMethodApplePay = (wcEnv, jwtToken, data) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE_PAYMENT;
    const resourcePage = RESOURCE_PAGE_FACADE_PAYMENT + RESOURCE_PAGE_ACTION_PAYMENT;

    return restClient
        .postFacade(env, resourcePage, jwtToken, data)
        .then((response) => response);
};

const RESOURCE_ROOT_FACADE_RECIPIENT = '/checkout';
const RESOURCE_PAGE_FACADE_RECIPIENT = '/payment';
const RESOURCE_PAGE_ACTION_RECIPIENT = '/applePay/recipient';

export const saveRecipientAddressApplePay = (wcEnv, jwtToken, data) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE_RECIPIENT;
    const resourcePage =    RESOURCE_PAGE_FACADE_RECIPIENT + RESOURCE_PAGE_ACTION_RECIPIENT;

    return restClient
        .postFacade(env, resourcePage, jwtToken, data)
        .then((response) => response);
};

const RESOURCE_ROOT_FACADE_SHIPPING = '/checkout';
const RESOURCE_PAGE_FACADE_SHIPPING = '/payment';
const RESOURCE_PAGE_ACTION_SHIPPING = '/applePay/shipping';

export const saveShipMethodApplePay = (wcEnv, jwtToken, data) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE_SHIPPING;
    const resourcePage =    RESOURCE_PAGE_FACADE_SHIPPING + RESOURCE_PAGE_ACTION_SHIPPING;

    return restClient
        .postFacade(env, resourcePage, jwtToken, data)
        .then((response) => response);
};

const RESOURCE_ROOT_FACADE_AUTHORIZE = '/checkout';
const RESOURCE_PAGE_FACADE_AUTHORIZE = '/payment';
const RESOURCE_PAGE_ACTION_AUTHORIZE = '/applePay/authorize';

export const authorizeApplePay = (wcEnv, jwtToken, data) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE_AUTHORIZE;
    const resourcePage =    RESOURCE_PAGE_FACADE_AUTHORIZE + RESOURCE_PAGE_ACTION_AUTHORIZE;

    return restClient
        .postFacade(env, resourcePage, jwtToken, data)
        .then((response) => response);
};

const RESOURCE_ROOT_FACADE_CANCEL = '/checkout';
const RESOURCE_PAGE_FACADE_CANCEL = '/payment';
const RESOURCE_PAGE_ACTION_CANCEL = '/applePay';

export const cancelApplePay = (wcEnv, jwtToken, data) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE_CANCEL;
    const resourcePage = RESOURCE_PAGE_FACADE_CANCEL + RESOURCE_PAGE_ACTION_CANCEL;
    return restClient
        .deleteFacade(env, resourcePage, jwtToken, data)
        .then((response) => response);
};

const RESOURCE_ROOT_FACADE_PLACE_ORDER = '/checkout';
const RESOURCE_PAGE_FACADE_PLACE_ORDER = '/payment';
const RESOURCE_PAGE_ACTION_PLACE_ORDER = '/applePay/submit';

export const placeOrderApplePay = (wcEnv, jwtToken, data) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE_PLACE_ORDER;
    const resourcePage = RESOURCE_PAGE_FACADE_PLACE_ORDER + RESOURCE_PAGE_ACTION_PLACE_ORDER;
    return restClient
        .postFacade(env, resourcePage, jwtToken, data)
        .then((response) => response);
};

export default {};
