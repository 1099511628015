/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable no-underscore-dangle */
import {
    createStore, applyMiddleware, compose, combineReducers,
} from 'redux';
import { createBrowserHistory, createMemoryHistory } from 'history';
import createSagaMiddleware, { END } from 'redux-saga';
import MBPLogger from 'mbp-logger';

import rootSaga from './sagas';
import sagaContext from './sagas/context';
import rootReducer from './reducers';

export const configureHistory = (url) => {
    let history = null;

    if (typeof window !== 'undefined') {
        history = createBrowserHistory();
    } else {
        const config = {};
        if (url) {
            config.initialEntries = [url];
        }
        history = createMemoryHistory(config);
    }

    return history;
};

const sagaMiddleware = createSagaMiddleware({
    context: sagaContext,
});

const devTools =  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })
    : () => (noop) => noop;

// runSaga is middleware.run function
// rootSaga is a your root saga for static sagas
// eslint-disable-next-line no-shadow
function createSagaInjector(runSaga, rootSaga) {
    // Create a dictionary to keep track of injected sagas
    const injectedSagas = new Map();

    const isInjected = (key) => injectedSagas.has(key);

    const injectSaga = (key, saga) => {
        // We won't run saga if it is already injected
        if (isInjected(key)) return;

        // Sagas return task when they executed, which can be used
        // to cancel them
        const task = runSaga(saga);

        // Save the task if we want to cancel it in the future
        injectedSagas.set(key, task);
    };

    // Inject the root saga as it a staticlly loaded file,
    injectSaga('root', rootSaga);

    return injectSaga;
}

function createReducer(asyncReducers) {
    return combineReducers({
        ...rootReducer,
        ...asyncReducers,
    });
}

const configureStore = (initialState = {}) => {
    // const persistedReducer = persistReducer(persistConfig, rootReducer);
    const middlewares = [
        sagaMiddleware, // Redux-Saga
        MBPLogger.middlewareLogger.logger, // MBP-Logger
        MBPLogger.middlewareLogger.crashReporter, // MBP-Logger
    ];
    const enhancers = [applyMiddleware(...middlewares), devTools()];
    const composedEnhancers = compose(...enhancers);
    const store = createStore(createReducer(), initialState, composedEnhancers);
    store.runSaga = sagaMiddleware.run;

    // Add injectSaga method to our store
    store.injectSaga = createSagaInjector(sagaMiddleware.run, rootSaga);

    // Add a dictionary to keep track of the registered async reducers
    store.asyncReducers = {};

    // Create an inject reducer function
    // This function adds the async reducer, and creates a new combined reducer
    store.injectReducer = (key, asyncReducer) => {
        store.asyncReducers[key] = asyncReducer;
        store.replaceReducer(createReducer(store.asyncReducers));
    };

    store.close = () => {
        store.dispatch(END);
    };

    return store;
};

export default configureStore;
