/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from './restClient';

const RESOURCE_ROOT_CARTSERVICE = '/checkout';
const RESOURCE_VERSION_CARTSERVICE = '/v2';
const RESOURCE_PAGE_CARTSERVICE = '/cart';
const RESOURCE_PAGE_ACTION = '/upsells';

/**
 * mergeCart
 * url - /checkout/{apiversion}/cart/upsells?brand={brandId}
 *
 * @param {Object} args
 * @param {Object} args.env
 * @param {String} args.jwtToken
 * @param {String} args.cartId
 * * @param {String} args.cartItemId
 * @param {String} args.products
 */

const addUpsellsToCart = ({
    env, jwtToken, cartId, data,
}) => {
    const envObj = env || {};
    envObj.rootUri = `${RESOURCE_ROOT_CARTSERVICE}${RESOURCE_VERSION_CARTSERVICE}`;
    const resourcePage = `${RESOURCE_PAGE_CARTSERVICE}/${cartId}${RESOURCE_PAGE_ACTION}`;
    return restClient.putRequest(envObj, resourcePage, jwtToken, data)
        .then((response) => response);
};
export default addUpsellsToCart;
