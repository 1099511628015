/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from './restClient';

const RESOURCE_ROOT_CARTSERVICE = '/checkout';
const RESOURCE_VERSION_CARTSERVICE = '/v2';
const RESOURCE_PAGE_CARTSERVICE = '/cart';
const RESOURCE_PAGE_ACTION = '/attribution';

/**
 * recordAttributions
 * url - /checkout/{apiversion}/cart/{cartId}/attribution?brand={brandId}
 *
 * @param {Object} args
 * @param {String} args.env
 * @param {String} args.jwtToken
 * @param {String} args.cartId
 * @param {String} args.bannerCode
 * @param {array} args.attributes
 */
const recordAttributions = ({
    env, jwtToken, cartId, bannerCode, attributes,
}) => {
    const envObj  = env || {};

    envObj.rootUri = `${RESOURCE_ROOT_CARTSERVICE}${RESOURCE_VERSION_CARTSERVICE}`;

    const payload = {
        bannerCode,
        attributes,
    };

    const resourcePage = `${RESOURCE_PAGE_CARTSERVICE}/${cartId}${RESOURCE_PAGE_ACTION}`;
    return restClient.postRequest(envObj, resourcePage, jwtToken, payload)
        .then((response) => response);
};

export default recordAttributions;
