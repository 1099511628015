/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackEvent } from '../../../../../TagManager/ducks/TagManager/TagManager-Actions';
import { getAccessToken, getIsAuthenticatedStatus } from '../../../Common/Common-Selectors';
import { verifyScopeExist } from '../../helper/helper';

const TagAuthenticationStatus = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);
    const accessToken = useSelector(getAccessToken);

    useEffect(() => {
        if (accessToken && isAuthenticated) {
            const isWriteAccess = verifyScopeExist(accessToken, 'write:checkout');
            const isReadAccess = verifyScopeExist(accessToken, 'read:checkout');

            if (isWriteAccess) {
                dispatch(trackEvent({
                    eventCategory: 'Registered User',
                    eventAction: 'Permissions',
                    eventLabel: 'Write Access',
                }));
            } else if (isReadAccess) {
                dispatch(trackEvent({
                    eventCategory: 'Registered User',
                    eventAction: 'Permissions',
                    eventLabel: 'Read Access',
                }));
            }
        }
    }, [accessToken, isAuthenticated]);

    return null;
};

export default TagAuthenticationStatus;
