/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const BANNER_CODE = 'r';
const BANNER_CODE_REF = 'ref';
const LINKSHARE_URL = 'lsurl';
const LINKSHARE_SKU = 'lssku';
const URL = 'u';
const ITEM_SKU = 'sku';
const PRODUCT_SKU = 'p';
const CATEGORY_ID = 'c';
const CATEGORY_IDENTIFFIER = 'cc';
const SEARCH_TERM = 's';
const LSID = 'lsid';
const SCI_LOC_NUMBER = 'LocNumber';
const ENTRY_BRAND = 'entryBrand';

const allConstants = ['ref', 'lsurl', 'lssku', 'u', 'sku', 'p', 'c', 'cc', 's', 'lsid', 'LocNumber'];

export default {
    BANNER_CODE,
    BANNER_CODE_REF,
    LINKSHARE_URL,
    LINKSHARE_SKU,
    URL,
    ITEM_SKU,
    PRODUCT_SKU,
    CATEGORY_ID,
    CATEGORY_IDENTIFFIER,
    SEARCH_TERM,
    LSID,
    SCI_LOC_NUMBER,
    ENTRY_BRAND,
    allConstants,
};
