/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const styleConfig = {
    borderRadius: '4px',
    // Shimmer Config
    background: 'linear-gradient(270deg, #E7EDF1, #F2F8FC, #E7EDF1)',
    backgroundSize: '1000% 1000%',
    animation: '$shimmer 3.4s ease infinite',
};

const SkeletonStyles = {

    // Text Blocks
    textSM: {
        marginBottom: '4px',
        width: '45px',
        height: '6px',
        borderRadius: styleConfig.borderRadius,
        background: styleConfig.background,
        backgroundSize: styleConfig.backgroundSize,
        animation: styleConfig.animation,
    },
    textMD: {
        width: '100px',
        height: '20px',
        borderRadius: styleConfig.borderRadius,
        background: styleConfig.background,
        backgroundSize: styleConfig.backgroundSize,
        animation: styleConfig.animation,
    },
    textLG: {
        width: '150px',
        height: '20px',
        borderRadius: styleConfig.borderRadius,
        background: styleConfig.background,
        backgroundSize: styleConfig.backgroundSize,
        animation: styleConfig.animation,
    },
    textMW: {
        marginBottom: '6px',
        width: '50%',
        height: '12px',
        borderRadius: styleConfig.borderRadius,
        background: styleConfig.background,
        backgroundSize: styleConfig.backgroundSize,
        animation: styleConfig.animation,
    },
    textSF: {
        marginBottom: '6px',
        width: '80%',
        height: '12px',
        borderRadius: styleConfig.borderRadius,
        background: styleConfig.background,
        backgroundSize: styleConfig.backgroundSize,
        animation: styleConfig.animation,
    },
    textFW: {
        marginBottom: '6px',
        width: '100%',
        height: '12px',
        borderRadius: styleConfig.borderRadius,
        background: styleConfig.background,
        backgroundSize: styleConfig.backgroundSize,
        animation: styleConfig.animation,
    },

    // Blocks
    block: {
        borderRadius: styleConfig.borderRadius,
        background: styleConfig.background,
        backgroundSize: styleConfig.backgroundSize,
        animation: styleConfig.animation,
    },

    // IMG Blocks
    img: {
        marginBottom: '10px',
        width: '100px',
        height: '100px',
        borderRadius: '100%',
        background: styleConfig.background,
        backgroundSize: styleConfig.backgroundSize,
        animation: styleConfig.animation,
    },

    // Utility Object Classes
    wrapper: {
        padding: '20px 0',
        width: '100%',
        height: 'auto',
    },
    container: {
        display: 'flex',
        justifyContent: 'space-around',
        margin: '0 auto',
    },
    divider: {
        borderBottom: '1px solid #F5F5F5',
    },

    // Utility Classes
    center: {
        margin: '0 auto',
    },

    // Shimmer Configuration
    shimmer: {
        background: styleConfig.background,
        backgroundSize: styleConfig.backgroundSize,
        animation: styleConfig.animation,
    },
    '@keyframes shimmer': {
        '0%': { backgroundPosition: '0% 50%' },
        '50%': { backgroundPosition: '100% 50%' },
        '1%': { backgroundPosition: '0% 50%' },
    },
};

export default SkeletonStyles;
