/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './ShowLoadingMessage-ActionTypes';

const updateShowLoadingMessage = () => ({ type: actionTypes.UPDATE_SHOW_LOADING_MESSAGE });

const flagOffShowLoadingMessage = () => ({ type: actionTypes.FLAGOFF_SHOW_LOADING_MESSAGE });

const updateLoaderId = (id) => ({ type: actionTypes.UPDATE_LOADER_ID, data: id });

export { updateShowLoadingMessage, flagOffShowLoadingMessage, updateLoaderId  };
