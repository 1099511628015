/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, call, put, select,
} from 'redux-saga/effects';
import qs from 'qs';
import mbpLogger from 'mbp-logger';

import { duck as tmDuck } from '../../../TagManager';
import * as commonSelectors from '../Common/Common-Selectors';
import * as showLoadingMessageActions from '../Common/ducks/ShowLoadingMessage/ShowLoadingMessage-Actions';
import { processExpressCheckout } from './ExpressCheckout-Actions';
import { orderActions, orderOperations } from '../../../Order';
import { retrieveCart } from '../../../Cart/Cart-Operations';
import { getIsAuthenticatedStatus, getUserRole } from '../Global/Global-Selectors';
import {
    logLastVisitedPage, clearAuthDependentState,
} from '../Global/Global-Actions';
import { getBaseCode } from '../../../../../app/helpers/tracking/product/dataLayerProductHelpers';
import { checkJWT } from '../Global/Global-Operations';

// TODO: Convert to action
import { updateFormAddressFromPreFill } from '../Recipient/ducks/RecipientForm/RecipientForm-Actions';
import { setUserSelectedAddress, setUserSubmittedProductFilterZipcode } from '../../../App/App-Actions';
import takeLeading from '../../../../../app/helpers/sagas/takeLeading';
import { getBrand, getFeatureFlags } from '../../../App/ducks/Config/Config-Selectors';
import { billingUpdateFormValue } from '../Payment/ducks/BillingAddressForm/BillingAddressForm-Actions';
import cartServices from '../../../../../apis/cart-service-apis/cartServices';

const extractBrandIdFromSku = (queryParams) => {
    if (queryParams?.sku?.indexOf('-') > 0) {
        return queryParams.sku.split('-')[0];
    }
    return '';
};

const extractSmartGiftAttr = (queryParams) => {
    if (queryParams && queryParams.tc) {
        return {
            attributeName: 'smTrackingCode',
            attributeValue: queryParams.tc,
        };
    }
    return null;
};

const extractSourceAttr = (queryParams) => {
    const skuKey = queryParams.sku || null;
    if (queryParams && skuKey && queryParams.customParams?.[skuKey]?.source) {
        return {
            attributeName: 'source',
            attributeValue: queryParams.customParams[skuKey].source,
        };
    }
    return null;
};

const extractSCILocNumberAttr = (queryParams) => {
    const skuKey = queryParams.sku || null;
    if (queryParams && skuKey && queryParams.customParams?.[skuKey]?.SCILocNumber) {
        return {
            attributeName: 'SCILocNumber',
            attributeValue: queryParams.customParams[skuKey].SCILocNumber,
        };
    }
    return null;
};

const extractAttributesFromParams = (queryParams) => {
    const attributesFromParams = extractSmartGiftAttr(queryParams);
    if (attributesFromParams) {
        return [attributesFromParams];
    }
    return [];
};

const extractSGandSCIAttributesFromParams = (queryParams) => {
    const attributesFromParams = [extractSmartGiftAttr(queryParams), extractSourceAttr(queryParams), extractSCILocNumberAttr(queryParams)];
    const filteredAttributesFromParams = attributesFromParams.filter((attr) => attr !== null);

    return filteredAttributesFromParams;
};

const extractRecipient = (queryParams, isNewExpCheckout = false) => {
    if (queryParams
        && (
            queryParams.firstName
            || queryParams.lastName
            || queryParams.addr1
            || queryParams.addr2
            || queryParams.city
            || queryParams.country
            || queryParams.state
            || queryParams.zipCode
            || queryParams.phone
            || queryParams.locType
            || queryParams.orgName
        )
    ) {
        if (isNewExpCheckout) {
            return {
                locationType: queryParams.locType || '',
                addressId: queryParams.addressId || '',
                firstName: queryParams.firstName || '',
                lastName: queryParams.lastName || '',
                organizationName: queryParams.orgName || '',
                address1: queryParams.addr1 || '',
                address2: queryParams.addr2 || '',
                address3: queryParams.addr3 || '',
                city: queryParams.city || '',
                state: queryParams.state || '',
                zipCode: queryParams.zipCode || '',
                country: queryParams.country || 'USA',
                phoneNumber: queryParams.phone || '',
                mobileNumber: queryParams.mobilePhone || '',
                email: queryParams.email || '',
                dpvIndicator: 'N',
                isAddressVerified: false,
            };
        }
        return {
            data: [{
                firstName: queryParams.firstName || '',
                lastName: queryParams.lastName || '',
                address1: queryParams.addr1 || '',
                address2: queryParams.addr2 || '',
                city: queryParams.city || '',
                country: queryParams.country || 'USA',
                state: queryParams.state || '',
                zipCode: queryParams.zipCode || '',
                phone: queryParams.phone || '',
                locationType: queryParams.locType || '',
                organizationName: queryParams.orgName || '',
            }],
            available: true,
        };
    }

    if (isNewExpCheckout) {
        return {};
    }

    return {
        available: false,
    };
};

const extractDeliveryDate = (queryParams, isNewExpCheckout = false) => {
    if (queryParams && queryParams.deliveryDate) {
        let currentDate = `${queryParams.deliveryDate} 00:00:00`;
        let today = new Date(currentDate);

        if (today && queryParams.deliveryDate.indexOf('-') > 0 && today.toString() === 'Invalid Date') {
            currentDate = `${queryParams.deliveryDate.replace(/-/g, '/')} 00:00:00`;
            today = new Date(currentDate);
        } else if (today && queryParams.deliveryDate.indexOf('/') > 0 && today.toString() === 'Invalid Date') {
            currentDate = `${queryParams.deliveryDate.replace(/\//g, '-')} 00:00:00`;
            today = new Date(currentDate);
        }

        if (today && today.toString() !== 'Invalid Date') {
            let dd = today.getDate();
            let mm = today.getMonth() + 1;
            const yyyy = today.getFullYear();
            if (dd < 10) {
                dd = `0${dd}`;
            }
            if (mm < 10) {
                mm = `0${mm}`;
            }
            if (isNewExpCheckout) {
                const formattedDeliveryDate = `${mm}/${dd}/${yyyy} 00:00:00`;
                const objDeliveryDate = {
                    deliveryDate: formattedDeliveryDate,
                    deliverRangeStartDate: formattedDeliveryDate,
                    deliveryDateType: 'on',
                    deliverySLA: '18F-GND',
                    locationType: queryParams.locType || '',
                    zipCode: queryParams.zipCode || '',
                };

                return objDeliveryDate;
            }
            const formattedDeliveryDate = `${yyyy}-${mm}-${dd}`;
            const updateDeliveryDate = {
                calendarDateType: 'on',
                deliverySLA: '18F-GND',
                calendarStartDate: formattedDeliveryDate,
                deliveryDate: formattedDeliveryDate,
                locationType: queryParams.locType || '',
                zipCode: queryParams.zipCode || '',
            };
            return {
                data: {
                    deliveryDate: updateDeliveryDate,
                },
                available: true,
            };
        }
    }

    if (isNewExpCheckout) {
        return {};
    }

    return {
        available: false,
    };
};

const extractBillingAddress = (queryParams, isNewExpCheckout = false) => {
    if (queryParams
        && (
            queryParams.bFirstName
            && queryParams.bLastName
            && queryParams.bAddr1
            && queryParams.bCity
            && queryParams.bCountry
            && queryParams.bState
            && queryParams.bZipCode
            && queryParams.bPhone
            && queryParams.bEmail
        )
    ) {
        if (isNewExpCheckout) {
            return {
                firstName: queryParams.bFirstName || '',
                lastName: queryParams.bLastName || '',
                organizationName: queryParams.bOrgName || '',
                address1: queryParams.bAddr1 || '',
                address2: queryParams.bAddr2 || '',
                address3: queryParams.bAddr3 || '',
                city: queryParams.bCity || '',
                state: queryParams.bState || '',
                country: queryParams.bCountry || '',
                zipCode: queryParams.bZipCode || '',
                phoneNumber: queryParams.bPhone || '',
                mobilePhone: queryParams.bMobilePhone || '',
                email: queryParams.bEmail || '',
            };
        }

        return {
            data: {
                firstName: queryParams.bFirstName || '',
                lastName: queryParams.bLastName || '',
                address1: queryParams.bAddr1 || '',
                address2: queryParams.bAddr2 || '',
                address3: queryParams.bAddr3 || '',
                addressId: queryParams.addressId || '',
                city: queryParams.bCity || '',
                country: queryParams.bCountry || '',
                state: queryParams.bState || '',
                zipCode: queryParams.bZipCode || '',
                phone: queryParams.bPhone || '',
                mobilePhone: queryParams.bMobilePhone || '',
                email: queryParams.bEmail || '',
            },
            page: 'expressCheckout',
            available: true,
        };
    }

    if (isNewExpCheckout) {
        return null;
    }

    return {
        available: false,
    };
};

export function* handleAuthorizationApiFailures(resumeFunction, resumeFunctionParameter) {
    const logOrderId = yield select(commonSelectors.getOrderId);
    const flagState = yield select(getFeatureFlags);

    if (flagState['is-checkout-monitoring-enabled']) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'Auth-Operations.js',
            function: 'handleAuthorizationApiFailures',
            message: 'handleAuthorizationApiFailures start',
            orderId: logOrderId,
        });
    }

    yield put(clearAuthDependentState(true));

    yield call(checkJWT);

    yield call(resumeFunction, resumeFunctionParameter);

    if (flagState['is-checkout-monitoring-enabled']) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'Auth-Operations.js',
            function: 'handleAuthorizationApiFailures',
            message: 'handleAuthorizationApiFailures end',
            orderId: logOrderId,
        });
    }
}

/*
 * New Express Checkout Process
 */
const extractGiftMessageNewApi = (queryParams) => ({
    message: queryParams.giftMessage?.trim() || 'No Card Message',
    signature: queryParams?.giftMessage?.trim()
        ? (queryParams.signature || queryParams.bFirstName || '') : '',
    occasionCode: queryParams.occasionCode || '',
});

const extractPromoCodeNewApi = (queryParams) => {
    if (queryParams.promoCode) {
        return {
            promotionCode: queryParams.promoCode,
            membershipId: queryParams.membershipId || '',
        };
    }
    return null;
};

const extractProdComponents = (queryParams, brandCode) => {
    const prodComponents = [];
    if (queryParams.components) {
        const components = JSON.parse(queryParams.components);
        if (components.length > 0) {
            components.forEach((listItem) => {
                prodComponents.push({
                    brandCode,
                    componentType: listItem.type,
                    productCode: listItem.sku,
                    productId: listItem.sku,
                    quantity: listItem.qty,
                });
            });
        }
    }
    return prodComponents;
};

const extractSubsDetails = (queryParams) => {
    if (queryParams.subscriptionDetail) {
        return JSON.parse(queryParams.subscriptionDetail);
    }
    return null;
};

const extractProduct = (queryParams, brandCode, featureFlags) => {
    let objectProd = [];
    if (queryParams.sku) {
        if (queryParams.isPassport) {
            objectProd = [
                {
                    productCode: queryParams.sku,
                    brandCode,
                    recipient: null,
                },
            ];
        }

        objectProd = [
            {
                productCode: queryParams.sku,
                brandCode,
                delivery: extractDeliveryDate(queryParams, true),
                subscription: extractSubsDetails(queryParams),
                greeting: extractGiftMessageNewApi(queryParams),
                recipientInfo: {
                    recipient: extractRecipient(queryParams, true),
                },
                components: extractProdComponents(queryParams, brandCode),
            },
        ];

        // // Get SCI and Smart Gift Attributes from the URL ex. source, SCILocNumber, and tc=xxxx; featureFlag reliant
        let attributesFromParams = [];
        if (featureFlags['is-source-attribute-used-in-checkout']) {
            attributesFromParams = extractSGandSCIAttributesFromParams(queryParams, featureFlags);
        } else {
            attributesFromParams = extractAttributesFromParams(queryParams);
        }

        if (attributesFromParams && attributesFromParams.length > 0) {
            objectProd[0].attributes = attributesFromParams;
        }
    }

    return objectProd;
};

const extractCustomer = (queryParams) => {
    if (queryParams && queryParams.sku) {
        const customerAddress = extractBillingAddress(queryParams, true);

        if (customerAddress) {
            return {
                customerAddress,
            };
        }
    }

    return null;
};

const getObjectPayload = (queryParams, brandCode, primaryBrand, featureFlags) => {
    const reqObj = { primaryBrand };
    console.log('queryParams', queryParams);
    if (queryParams.refId) {
        reqObj.referenceId = queryParams.refId;
    } else {
        reqObj.products = extractProduct(queryParams, brandCode, featureFlags);

        if (reqObj.products.length) {
            const customer = extractCustomer(queryParams);
            if (customer) {
                reqObj.customer = customer;
            }

            const promotion = extractPromoCodeNewApi(queryParams);
            if (promotion) {
                reqObj.promotion = promotion;
            }
        } else {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                module: 'ExpressCheckout-Operations',
                function: 'queryParams.products',
                jsError: queryParams,
                message: 'Failed getting the products query params',
            });
        }
    }
    return reqObj;
};

function* expressCheckoutNewApi(action) {
    try {
        mbpLogger.logDebug({
            appName: process.env.npm_package_name,
            module: 'ExpressCheckout-Operations',
            function: 'expressCheckoutNewApi',
            message: 'new express checkout initiated',
        });

        const {
            location: { search },
            history,
        } = action.data;
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        const brandProperties = yield select(getBrand);
        const queryParams = qs.parse(search.replace('?', ''));

        const extractedBrandId = extractBrandIdFromSku(queryParams);
        const { isPassport } = queryParams;

        let brandCode = queryParams.brandId || extractedBrandId || brandProperties.id || '1001';
        if (isPassport) {
            brandCode = '1001';
        }

        const productSku = queryParams.sku || '';
        let redirectUrl = queryParams.rUrl || '';

        if (brandCode === '1030' || brandCode === '1033') { // pmall products
            // Prepare recipient for add in redux
            const extractedRecipient = extractRecipient(queryParams);
            let recipientsData;

            if (extractedRecipient) {
                recipientsData = extractedRecipient;
                yield put(updateFormAddressFromPreFill(recipientsData));
                yield put(setUserSelectedAddress({
                    checkoutAutoFill: true,
                    addressSource: 'expressCheckout',
                    recipient: recipientsData,
                }));

                yield put(setUserSubmittedProductFilterZipcode(recipientsData.zipCode));
            }

            yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
            yield call(history.push, `/searchterm/${productSku}`);
        } else {
            const featureFlags = yield select(getFeatureFlags);
            const payload = getObjectPayload(queryParams, brandCode, brandProperties.domain, featureFlags);
            const jwtToken = yield call(checkJWT);

            if (isPassport) {
                const isAuthenticated = yield select(getIsAuthenticatedStatus);
                // check if the user is loggedin
                if (!isAuthenticated) {
                    yield put(logLastVisitedPage(`/checkout/express${search}`));

                    yield call(history.push, '/auth/login');
                } else {
                    const userRole = yield select(getUserRole);
                    // checking if user has Passport subscription
                    if (userRole === 'P') {
                        yield call(history.push, '/account/passport');
                        // if not passport user
                    } else if (userRole !== 'P') {
                        mbpLogger.logDebug({
                            appName: process.env.npm_package_name,
                            module: 'ExpressCheckout-Operations',
                            function: 'expressCheckoutNewApi',
                            message: 'new express checkout passport',
                        });

                        const addRefExpressResponse = yield call(cartServices.expressCheckout, {
                            env: {},
                            jwtToken,
                            primaryBrand: payload.primaryBrand,
                            products: payload.products,
                        });

                        if (addRefExpressResponse.data.cartId) {
                            const orderId = addRefExpressResponse.data.cartId;

                            if (redirectUrl !== '') {
                                yield call(history.push, redirectUrl);
                            } else {
                                yield put(orderActions.loadExpressCheckout());

                                yield call(orderOperations.setOrderId, {
                                    data: [orderId],
                                });

                                // retrive cart
                                const routeBack = `/checkout/payment/${orderId}`;
                                yield call(history.push, routeBack);
                            }
                        }
                    }
                }
            } else {
                const addRefExpressResponse = yield call(cartServices.expressCheckout, {
                    env: {},
                    jwtToken,
                    payload,
                });

                if (addRefExpressResponse.data.cartId) {
                    const orderId = addRefExpressResponse.data.cartId;
                    const orderItemId = addRefExpressResponse.data.cartItems?.[0]?.cartItemId;

                    yield call(orderOperations.setOrderId, {
                        data: [orderId],
                    });

                    // retrive cart
                    yield call(retrieveCart);

                    // billing email address
                    if (queryParams.bemail) {
                        yield put(billingUpdateFormValue({
                            target: {
                                name: 'email',
                                value: queryParams.bemail,
                            },
                        }));
                        yield put(billingUpdateFormValue({
                            target: {
                                name: 'confirmEmail',
                                value: queryParams.bemail,
                            },
                        }));
                    }

                    if (!redirectUrl) {
                        yield put(orderActions.loadExpressCheckout());

                        // redirecting to shipping page
                        if (orderId) {
                            // Track Add-to-Cart
                            const recips = yield select(commonSelectors.getRecipients);
                            let addedItem = null;

                            recips.forEach((recip) => {
                                const { cartItems } = recip;

                                cartItems.forEach((orderItem) => {
                                    if (orderItem.cartItemId === orderItemId) {
                                        addedItem = orderItem;
                                    }
                                });
                            });

                            if (addedItem) {
                                const { price, product } = addedItem;
                                const trackAddToCartPayload = {
                                    eventName: 'add_to_cart', // express_checkout?
                                    eventCategory: 'Product Page', // TODO: look what is the tracking for this
                                    eventAction: 'Add to Cart', // TODO: look what is the tracking for this
                                    eventLabel: product.parentProductName,
                                    products: [
                                        {
                                            name: product.parentProductName,
                                            partNumber: product.productCode,
                                            baseCode: getBaseCode(product.productCode),
                                            brand: product.brandId,
                                            sKUs: [{
                                                partNumber: product.productCode,
                                            }],
                                            price,
                                            quantity: 1,
                                            position: 1,
                                            categoryId: '',
                                            categoryName: '',
                                            categoryPath: '',
                                        },
                                    ],
                                };

                                yield put(tmDuck.actions.trackEvent(trackAddToCartPayload));
                            }
                            redirectUrl = `/checkout/shipping/${orderId}`;
                        }
                    }
                }
                yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
                yield call(history.push, redirectUrl || '/');
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'ExpressCheckout-Operations',
            function: 'expressCheckoutNewApi',
            jsError: ex,
            message: 'Error occured while processing new express checkout',
        });

        yield call(action.data.history.push, '/');
    }
}

function* onProcessExpressCheckout(action) {
    try {
        yield call(expressCheckoutNewApi, action);
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'ExpressCheckout-Operations',
            function: 'onProcessExpressCheckout',
            jsError: ex,
            message: 'Error occured while processing express checkout',
        });
    }
}

function* watchProcessExpressCheckout() {
    yield takeLeading(processExpressCheckout().type, onProcessExpressCheckout);
}

const watchers = [
    fork(watchProcessExpressCheckout),
];

export {
    watchers,
};
export default {};
