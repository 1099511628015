/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import * as passportActions from './Passport-Action';
import passportReducer from './Passport-Reducer';
import { watchers as passportWatchers } from './Passport-Operations';

export { passportActions, passportWatchers };

const passportReducerPersistConfig = {
    key: 'passport',
    storage: sessionStorage,
};

const passportPersistReducer = persistReducer(passportReducerPersistConfig, passportReducer);
export default passportPersistReducer;
