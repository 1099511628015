/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/**
 * CartService APIs
 */
import availablityDeliveryCalendar from './availablityDeliveryCalendar';
import addToCart from './addToCart';
import addUpsellsToCart from './addUpsellsToCart';
import addGreetingCard from './addGreetingCard';
import addGreetingCardItems from './addGreetingCardItems';
import addPassportToCart from './addPassportToCart';
import calculateCart from './calculateCart';
import createLabelGreetingCard from './createLabelGreetingCard';
import donation from './donation';
import expressCheckout from './expressCheckout';
import generateGreetingCard from './generateGreetingCard';
import getApplePayMerchantId from './getApplePayMerchantId';
import getCartOrderDetails from './getCartOrderDetails';
import getCartCount from './getCartCount';
import getLabelGreetingCard from './getLabelGreetingCard';
import initPayment from './initPayment';
import mergeCart from './mergeCart';
import paymentClientToken from './paymentClientToken';
import placeOrderCart from './placeOrderCart';
import recordAttributions from './recordAttributions';
import removeOrderItem from './removeOrderItem';
import saveRecipient from './saveRecipient';
import uploadImageGreetingCard from './uploadImageGreetingCard';
import updateCartItemDeliveryInfo from './updateCartItemDeliveryInfo';

const cartServices = {
    availablityDeliveryCalendar,
    addToCart,
    addUpsellsToCart,
    addGreetingCard,
    addGreetingCardItems,
    addPassportToCart,
    calculateCart,
    createLabelGreetingCard,
    donation,
    expressCheckout,
    generateGreetingCard,
    getApplePayMerchantId,
    getCartOrderDetails,
    getCartCount,
    getLabelGreetingCard,
    initPayment,
    mergeCart,
    paymentClientToken,
    placeOrderCart,
    saveRecipient,
    recordAttributions,
    removeOrderItem,
    uploadImageGreetingCard,
    updateCartItemDeliveryInfo,
};

export default cartServices;
