/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
    object, string, shape, bool, array, number,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Helmet from 'react-helmet';
import { makeStyles } from '@material-ui/core';
import { uid } from 'react-uid';
import { getIsBot } from '../../../../state/ducks/App/App-Selectors';
import QaList from './Partials/QaList';
import uniqueId from '../../../helpers/contentstack/uniqueId';
import removeMarkdown from '../../../helpers/contentstack/removeMarkdown';
import useUIDQueryConditionally from '../../../helpers/hooks/useUIDQueryConditionally';
import DesktopMobile from '../../../helpers/DesktopMobile/DesktopMobile';
import GenericSkeleton from '../../GraphqlComponents/GraphqlSkeletonComponents/GenericSkeleton';
import { sanitizingString } from '../../../helpers/Seo/sanitizingString';

const useStyles = makeStyles(() => ({
    qaList: {
        width: '100%',
    },
}));

const QaListContainer = ({
    data: blockData, skeletonData, identifyWidthObj, faqSchema, isBot,
}) => {
    const classes = useStyles();
    const { data, loading } = useUIDQueryConditionally({
        data: blockData,
        newDataSelector: (dta) => dta?.findContentByUID?.content?.entry,
        evaluateCondition: (dta) => Object.keys(dta || {}).length === 2,
        uidSelector: (dta) => dta?.uid,
        contentTypeSelector: (dta) => dta?._content_type_uid,
    });
    if (loading) {
        return (
            <DesktopMobile
                desktop={() => skeletonData?.desktop_skeleton && <GenericSkeleton height={skeletonData?.skeleton_height} />}
                mobile={() => skeletonData?.mobile_skeleton && <GenericSkeleton height={skeletonData?.mobile_skeleton_height} />}
            />
        );
    }
    const setlayout = (viewAllData, popularDescription, categorizeDescription, category, defaultCategoryValue, faqArray) => {
        const presentation = data.presentation || {};
        const layoutTitle = data.title || '';
        const categorize = presentation.categorize || false;
        const showPopular = presentation.show_popular || false;
        const accordion = presentation.accordion || false;
        const setLayout = [];

        if (showPopular) {
            setLayout.push(
                <Grid item xs={12} key={uid(`Popular-${layoutTitle}`)} className="qaList popular">
                    <QaList
                        layoutTitle={`Popular ${layoutTitle}`}
                        layoutDescription={popularDescription}
                        identifyWidthObj={identifyWidthObj}
                        isCategory={false}
                        accordion={accordion}
                    />
                </Grid>,
            );
        }
        if (!categorize) {
            setLayout.push(
                <Grid item xs={12} key={uid(`Non-Categorize-${layoutTitle}`)} className="qaList loose">
                    <QaList
                        layoutTitle={layoutTitle}
                        layoutDescription={viewAllData}
                        identifyWidthObj={identifyWidthObj}
                        isCategory={false}
                        accordion={accordion}
                        faqSchemaSkip={(faqSchema && faqArray?.length)}
                    />
                </Grid>,
            );
        } else {
            setLayout.push(
                <Grid item xs={12} key={uid(`Categorize-${layoutTitle}`)} className="qaList categorized">
                    <QaList
                        layoutTitle={`${layoutTitle} by Category`}
                        layoutDescription={categorizeDescription}
                        categoryDescription={category}
                        identifyWidthObj={identifyWidthObj}
                        defaultCategoryValue={defaultCategoryValue}
                        isCategory
                        accordion={accordion}
                        faqSchemaSkip={(faqSchema && faqArray?.length)}
                    />
                </Grid>,
            );
        }
        return setLayout;
    };

    if (!data) return null;
    const categoryDescription = [];
    const popularDescription = [];
    const layoutDescription = [];
    const viewAllData = [];
    const categories = data.categories || [];
    const faqArray = [];
    const cmsuid = data.uid || null;
    let defaultCategoryValue = '';
    if (categories?.length > 0) {
        categories.forEach((categoryItem, index) => {
            const category = categoryItem.category || {};
            const categoryTitle = category.category_title || '';
            const categoryDetail = category.entries || [];
            const categoryValue = categoryTitle.split(' ').join('').toLowerCase();
            const faqDetail = {
                key: categoryValue,
                value: [],
            };
            if (index === 0) {
                defaultCategoryValue = categoryValue;
            }
            categoryDescription.push({
                label: categoryTitle,
                value: categoryValue,
            });
            if (categoryDetail && categoryDetail.length > 0) {
                categoryDetail.forEach((faqItem) => {
                    const entry = { ...faqItem }?.entry;
                    const isPopular = { ...entry }?.popular;
                    if (isPopular) {
                        popularDescription.push(entry);
                    }
                    faqDetail.value.push(entry);
                    faqArray.push(entry);
                });
            }
            layoutDescription.push(faqDetail);
            if (faqDetail?.value && faqDetail?.value.length > 0 && !isBot) {
                viewAllData.push(...faqDetail?.value);
            }
        });
        if (!isBot) { // prevent duplicate SEO data
            categoryDescription.push({
                label: 'View All',
                value: 'viewall',
            });

            layoutDescription.push({
                key: 'viewall',
                value: viewAllData,
            });
        }
    }
    return (
        <>
            {faqSchema && (
                <Helmet>
                    <script type="application/ld+json">
                        {`{"@context": "https://schema.org","@type": "FAQPage","mainEntity": [${faqArray && faqArray.map((faqData) => (`{"@type": "Question","name": "${sanitizingString(removeMarkdown(faqData.question))}","acceptedAnswer": {"@type": "Answer","text": "${sanitizingString(removeMarkdown(faqData.answer))}"}}`))}]}`}
                    </script>
                </Helmet>
            )}
            <div className={classes.qaList} data-uniqueid={uniqueId(data.unique_selector)} cmsuid={cmsuid}>
                {setlayout(viewAllData, popularDescription, layoutDescription, categoryDescription, defaultCategoryValue, faqArray)}
            </div>
        </>
    );
};

QaListContainer.propTypes = {
    faqSchema: bool,
    data: shape({
        locale: string.isRequired,
        categories: array,
        presentation: object,
        title: string.isRequired,
        uid: string.isRequired,
    }).isRequired,
    skeletonData: shape({
        mobile_skeleton: bool,
        desktop_skeleton: bool,
        skeleton_height: number,
        mobile_skeleton_height: number,
    }),
    identifyWidthObj: shape({
        isMobile: bool.isRequired,
        isDesktop: bool.isRequired,
    }).isRequired,
    isBot: bool,
};

QaListContainer.defaultProps = {
    faqSchema: false,
    skeletonData: {},
    isBot: false,
};

const mapStateToProps = (state) => ({
    isBot: getIsBot(state),
});
const enhance = compose(
    connect(mapStateToProps, null),
);
export default enhance(QaListContainer);
