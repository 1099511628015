/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable import/prefer-default-export */
import React from 'react';

export const formatPhoneNumber = (phoneNumber) => {
    try {
        return phoneNumber
            .trim()
            .replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
        // eslint-disable-next-line no-empty
    } catch (ex) {}
    return '';
};

export const mmoFormatPhoneNumber = (phone) => {
    let phoneNumber;
    const V1 = /^\((\d\d\d)\)\s$/; // the phone come with (201), dont need continue the process
    const V2 = /^\((\d\d\d)\)\s(\d\d\d)-$/; // the phone come with (201) 235-, dont need continue the process
    // start transform the code
    if (V1.test(phone)) {
        phoneNumber = String(phone).replace(V1, '$1');
        return phoneNumber;
    }

    if (V2.test(phone)) {
        phoneNumber = String(phone).replace(V2, '($1) $2');
        return phoneNumber;
    }

    phoneNumber = String(phone).replace(/[^0-9]+/g, '');

    if (phoneNumber.length > 10) {
        // International numbers
        phoneNumber = phoneNumber.replace(/^(\d\d)(\d{3})(\d{3})(\d{0,4}).*/, '$1 $2 $3 $4');
    } else if (phoneNumber.length > 5) {
        // big to 5 digit do the form is it is not already there 201123435 -> (201) 123-435
        phoneNumber = phoneNumber.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, '($1) $2-$3');
    } else if (phoneNumber.length > 3) {
        // bit to 3 digit do the form is it is not already there 20123 -> (201) 23
        phoneNumber = phoneNumber.replace(/^(\d\d\d)(\d{0,4})/, '($1) $2');
    } else {
        // bit to 1 digit do the form is it is not already there 201 -> (201)
        phoneNumber = phoneNumber.replace(/^(\d*)/, '$1');
    }

    return phoneNumber;
};

export const phoneNumberLink = (phone) => (
    <a href={`tel:+${phone.replace(/\D/g, '')}`} className="link">
        {phone}
    </a>
);

export const getCreditCardType = (cardNumber) => {
    // start without knowing the credit card type
    let result = '';
    // first check for Mastercard
    if (/^5[1-5]/.test(cardNumber)) result = 'MC';
    // then check for Visa
    else if (/^4[0-9]/.test(cardNumber)) result = 'VI';
    // then check for American Express
    else if (/^3[47]/.test(cardNumber)) result = 'AX';
    // then Discover
    else if (/^6(?:011|5[0-9]{2})/.test(cardNumber)) result = 'DS';
    return result;
};

export const formatPrice = (price) => {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(Number(price))) {
        return Number(price).toFixed(2);
    }
    return price;
};

export const formatTextBreakToSpace = (text) => text.replace(/\n/g, ' ');
