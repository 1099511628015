/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';

/**
 * @param {string} contentType - model name
 * @param {string} brand - domain name: brand
 * @param {string} locale -
 * @param {string} viewport -
 * @param {array} references - pass any includerefs necessary. Use the following format ['"objectPath"','"objectPath"'] :note single/double quotes
 */

const findFooter = (data) => {
    const brand = data.brand;
    const GRAPHQL_ENV = data.GRAPHQL_ENV;
    const contentType = data.contentType;
    const locale = data.locale;
    const references = data.references || [];

    return gql`query getFooter {
        business: findContent(brand: "${brand}", contentType: "${contentType}", environment: "${GRAPHQL_ENV}", locale: "${locale}", includeRefs: ${references})
        {
            content
        }
        legal: findContent(brand: "global",  environment: "${GRAPHQL_ENV}", contentType:"footer_legal_links")
        {
            content
        }
    }`;
};

export default findFooter;
