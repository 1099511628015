/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// Excludes Promo From the list
export const getOrderItemsExcludePromo = (state) => {
    if (state.cart?.recipients) {
        const { recipients } = state.cart;
        return recipients.filter((items) => (
            items.cartItems.filter((cartItems) => !cartItems.product.isPassport && cartItems.itemInfo?.lineItemType !== 'DONATION').length !== 0
        ));
    }
    return [];
};

export const getCurrentViewOrderItem = (state) => {
    const recipients = getOrderItemsExcludePromo(state);
    if (recipients.length) {
        return recipients[0];
    }
    return {};
};
