/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionsTypes from './DeleteItem-ActionTypes';

export const deleteItem = (orderItemId, isPassport, history, typeOrderItem, positionItem, skuCode, parentSku) => ({
    type: actionsTypes.DELETE_ITEM,
    data: {
        orderItemId,
        isPassport,
        history,
        typeOrderItem,
        positionItem,
        skuCode,
        parentSku,
    },
});

export const deleteItemFood = (orderItemId, isPassport, history, page, skuCode, parentSku) => ({
    type: actionsTypes.HANDLE_DELETE_ITEM_FOOD,
    data: {
        orderItemId,
        isPassport,
        history,
        page,
        skuCode,
        parentSku,
    },
});
export const deleteMiniCartItem = (orderItemId, page, parentSku) => ({
    type: actionsTypes.HANDLE_MINI_CART_DELETE_ITEM,
    data: {
        orderItemId,
        page,
        parentSku,
    },
});

export const enterpriseDeleteItem = (orderItemId, isPassport, history, typeOrderItem, positionItem, skuCode, parentSku, type, orderCartItemId) => ({
    type: actionsTypes.ENTERPRISE_DELETE_ITEM,
    data: {
        orderItemId,
        isPassport,
        history,
        typeOrderItem,
        positionItem,
        skuCode,
        parentSku,
        type,
        orderCartItemId,
    },
});

export default {};
