/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getEnterpriseId } from '../state/ducks/Member/ducks/EnterpriseId/EnterpriseId-Selectors';

const withAsyncScript = (scriptUrl, includeUserId = false) => (WrappedComponent) => (props) => {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [scriptError, setScriptError] = useState(null);
    const enterpriseId = useSelector(getEnterpriseId);
    const appendScript = () => {
        const script = document.createElement('script');
        script.src = includeUserId && enterpriseId ? `${scriptUrl}&userID=${enterpriseId}` : scriptUrl;
        script.async = true;
        script.onload = () => setScriptLoaded(true);
        script.onerror = () => setScriptError('Error loading script.');
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    };

    useEffect(() => {
        if (enterpriseId) {
            appendScript();
        }
    }, [enterpriseId]);

    useEffect(() => {
        if (!includeUserId) {
            appendScript();
        }
    }, [scriptUrl]);
    const newProps = {
        ...props,
        // eventually we will deprecate isScriptLoaded, isScriptLoadSucceed
        isScriptLoaded: scriptLoaded,
        isScriptLoadSucceed: scriptLoaded,
        // going forward, we should only use below two scriptLoaded, scriptError
        scriptLoaded,
        scriptError,
    };

    // eslint-disable-next-line react/jsx-props-no-spreading,
    return <WrappedComponent {...newProps} />;
};
export default withAsyncScript;
