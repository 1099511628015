/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const serilizeGetQuery = (url) => {
    const decodedURL = decodeURIComponent(url);
    const removeLineBreaks = decodedURL.replace(/\n/g, '');
    const reduceSpaces = removeLineBreaks.replace(/\s\s+/g, ' '); // many spaces -> one
    // split off query, only query needs to be encoded
    const [origin, query] = reduceSpaces.split(/=(.+)/);
    // remove any operationName and variable tags from end of query
    const [slimQuery, operationName, variables] = query.split('&');
    // The below takes a different approach based on the use of graphql variables, which require a operationName
    // otherwise we will remove the name and serialize further
    let cleanedURL = '';
    // if not using a named operation with variables
    if (!operationName.includes('operationName') && variables !== 'variables={}' && query) {
        // get queryName
        const queryName = slimQuery.slice(0, slimQuery.indexOf('{'));
        // remove queryName from query string
        const denamedQuery = queryName.trim().length >= 1 ? slimQuery.split(queryName)[1] : slimQuery;
        // encode just query string, replace single instance of }} with a space to match sw
        const encodedQuery = encodeURIComponent(denamedQuery.replace('}}', '} }'));
        // proceed as normal with serialization
        cleanedURL = `${origin}=${encodedQuery}`;
        // if a query is using graphql variables eg. guided nav and findCategoryById
    } else {
        // remove variables= because = cannot be encoded but variables object needs to be
        const variablesObject = variables.split('=')[1];
        /*
                similar to non named queries
                    - need to replace a single instance of }}
                    - recombine query properly with operationName and variables
                    - encode variables object, but hardcode &variables= in order to be sent as is
            */
        cleanedURL = `${origin}=${encodeURIComponent(slimQuery.replace('}}', '} }'))}&${operationName}&variables=${encodeURIComponent(variablesObject)}`;
    }
    return cleanedURL;
};

export default serilizeGetQuery;
