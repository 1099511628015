/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actions from './AddToCart-Actions';

const INITIAL_STATE = {
    error: '',
    errorList: {},
    loader: false,
    itemAdded: null,
    giftMessage: {
        message: '',
        occasionCode: '',
    },
};

const cartReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.clearAddToCartError().type:
            return {
                ...state,
                error: '',
                errorList: {},
            };
        case actions.loadItemAdded().type:
            return { ...state, itemAdded: action.data.item };
        case actions.loadAddToCartError().type:
            return {
                ...state,
                error: action.data.error,
                errorList: action.data.errorList,
            };
        case actions.loadAddToCartloader().type:
            return { ...state, loader: action.data.loader };
        case actions.loadAddToCartGiftMessage().type:
            return {
                ...state,
                giftMessage: {
                    message: action.data.message,
                    occasionCode: action.data.occasionCode,
                },
            };
        default:
            return state;
    }
};

export default cartReducer;
