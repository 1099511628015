/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import jwtDecode from 'jwt-decode';
import { string } from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
    phoneNumberBrands,
    brandImagePaths,
} from './helpers/brandErrorPageHelpers';
import { getBrandIdByHostName } from '../../../../helpers/brandIdentification/brandIdentification';
import brandTheme from '../../../AppShell/brandtheme';

const useStyles = makeStyles(() => ({
    root: {
        display: 'fixed',
        width: '100vw',
        height: '100vh',
        paddingLeft: '2em',
        paddingRight: '2em',
        boxSizing: 'border-box',
        '@media screen and (max-width: 600px)': {
            paddingLeft: '0.5em',
            paddingRight: '0.5em',
        },
    },
    title: {
        fontSize: '1.5em',
        fontWeight: 'bold',
    },
    logo: {
        display: 'block',
        margin: '1em auto 0em',
        height: '89px',
    },
    reloadButton: ({ brandCode }) => ({
        display: 'block',
        margin: '2em auto',
        textTransform: 'capitalize',
        // use mui defualt colors if we cannot find in theme
        backgroundColor: brandTheme[brandCode]?.palette?.primary?.main || false,
        '&:hover': {
            backgroundColor: brandTheme[brandCode]?.palette?.primary?.[900] || false,
        },
    }),
}));

export const ContactId = ({ className }) => {
    const JWT_PAYLOAD_KEY_1 = 'https://800-flowers.net';
    const JWT_PAYLOAD_KEY_2 = 'https://1800flowers.com/';
    // Get Token from sessionStorage
    let contactId = 'NO_CONTACT_ID';

    if (typeof sessionStorage !== 'undefined') {
        const rootSessionStorage = JSON.parse(sessionStorage.getItem('persist:root'));
        if (rootSessionStorage && rootSessionStorage.member) {
            const memberObj = JSON.parse(rootSessionStorage.member);
            if (memberObj?.auth?.accessToken) {
                const decodeToken = jwtDecode(memberObj.auth.accessToken);
                const jwtPayload = decodeToken[JWT_PAYLOAD_KEY_1] || decodeToken[JWT_PAYLOAD_KEY_2];
                contactId = jwtPayload?.contactId || 'NO_CONTACT_ID';
            }
        }
    }

    return (
        <div className={className || ''}>
            <b>Contact ID:</b>&nbsp;{`${contactId}`}
        </div>
    );
};

ContactId.propTypes = {
    className: string,
};

ContactId.defaultProps = {
    className: '',
};

const AppErrorBoundary = () => {
    const defaultHost = '800flowers';
    let brandCode = getBrandIdByHostName(defaultHost);
    if (typeof window !== 'undefined') {
        const currentHost = window.location.hostname;
        brandCode = getBrandIdByHostName(currentHost) || brandCode;
    }
    const classes = useStyles({ brandCode });
    const imageUrl = brandImagePaths[brandCode].defaultImg;
    const altText = brandImagePaths[brandCode].alt;
    const phoneNumber = phoneNumberBrands[brandCode];

    const handleReload = () => {
        if (typeof window !== 'undefined') {
            window.location.reload();
        }
    };

    return (
        <div className={classes.root}>
            <img src={imageUrl} alt={altText} className={classes.logo} />
            <Typography id="sorryPage" paragraph variant="h1" className={classes.title}>
                Sorry!
            </Typography>
            <Typography variant="body1">
                There was an unexpected problem.
            </Typography>
            <Typography paragraph variant="body1">
                Please call {phoneNumber} to complete your order
            </Typography>
            <ContactId />
            <Button
                variant="contained"
                color="primary"
                className={classes.reloadButton}
                onClick={handleReload}
            >
                Try Again
            </Button>
        </div>
    );
};

export default AppErrorBoundary;
