/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpLogger from 'mbp-logger';
import restClient from '../../app/helpers/restClient';
import Debouncer from '../../app/helpers/DynamicPricing/Debouncer';
import useBrowserUUID from '../../app/helpers/useBrowserUUID';

const RESOURCE_ROOT_FACADE = '/acquisition';
const RESOURCE_PAGE_FACADE = '/pricing';
const RESOURCE_PAGE_ACTION = '/product';

let debouncer = new Debouncer(restClient);

const getPriceFromEngine = (wcEnv, jwtToken, reqBody, shouldBatch, reInitBatch = false) => {
    const env = wcEnv;
    const payload = reqBody;
    env.rootUri = RESOURCE_ROOT_FACADE;
    const resourcePage = `${RESOURCE_PAGE_FACADE}${RESOURCE_PAGE_ACTION}`;

    if (!payload.anonymousId) {
        payload.anonymousId = (typeof window !== 'undefined' && window.localStorage) ? useBrowserUUID() : '';
    }

    if (!payload.primaryBrand && typeof window !== 'undefined') {
        payload.primaryBrand =  window.location.hostname?.split?.('.')?.[1] || '';
    }

    if (reInitBatch) {
        debouncer = new Debouncer(restClient);
    }

    if (!shouldBatch) {
        return restClient.postFacade(env, resourcePage, jwtToken, payload)
            .then((response) => response);
    }

    return new Promise((resolve, reject) => {
        debouncer.addRequest(wcEnv, jwtToken, resourcePage, payload, (err, data) => {
            if (err !== null) {
                mbpLogger.logError({
                    appName: process.env.npm_package_name,
                    jsError: err,
                    message: `PriceDebouncer: error in callback... ${err.message}`,
                    payload,
                });
                reject(err);
                return;
            }
            resolve({ data });
        });
    });
};

export default getPriceFromEngine;
