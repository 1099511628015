/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import createState from 'redux-create-state';

import * as actions from './ABTesting-Actions';
import createReducer from '../../../../utils/createReducer';

const AB_TESTING_INITIAL_STATE = {
    redirectEventAction: '',
};

const redirectEventAction = createReducer(AB_TESTING_INITIAL_STATE)({
    [actions.setABRedirectEventAction().type]: (state, action) => createState(state || AB_TESTING_INITIAL_STATE, ['redirectEventAction', action.payload]),
});

export default redirectEventAction;
