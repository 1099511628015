/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    SET_AB_REDIRECT_GA, CLEAR_AB_REDIRECT_GA,
} from './ABTesting-ActionTypes';

export const setABRedirectEventAction = (payload) => ({
    type: SET_AB_REDIRECT_GA,
    payload,
});

export const clearABRedirectEventAction = (payload = '') => ({
    type: CLEAR_AB_REDIRECT_GA,
    payload,
});
