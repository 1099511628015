/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const getOGImageFallback = (brand) => {
    // Most images live here: https://app.contentstack.com/#!/stack/blt95e0138311135214/assets/bltc6a7aeb4c0143ef6?branch=main
    const brandMap = {
        '0800flowers': 'https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blteb80eb70ce7579b2/5d1536b504e065d077c95a66/fw_logo_flowers_light@2x.png?auto=webp',
        '1800flowers': 'https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blteb80eb70ce7579b2/5d1536b504e065d077c95a66/fw_logo_flowers_light@2x.png?auto=webp',
        '1800baskets': 'https://images.contentstack.io/v3/assets/blt95e0138311135214/bltc6a7aeb4c0143ef6/18b.png?auto=webp',
        harryanddavid: 'https://images.contentstack.io/v3/assets/blt89dbf1c763ec00a6/blt71d9d155e1fcd3d5/hd-logo_png.png?auto=webp',
        wolfermans: 'https://images.contentstack.io/v3/assets/blt95e0138311135214/bltefaf64ec93508a79/wlf.png?auto=webp',
        cheryls: 'https://images.contentstack.io/v3/assets/blt95e0138311135214/blta76d7cbd4cf3a362/cco.png?auto=webp',
        stockyards: 'https://images.contentstack.io/v3/assets/blt95e0138311135214/bltcabbd690f77e39d0/sy.png?auto=webp',
        thepopcornfactory: 'https://images.contentstack.io/v3/assets/blt95e0138311135214/blt7b6cfa7a2ae97d9c/tpf.png?auto=webp',
        simplychocolate: 'https://images.contentstack.io/v3/assets/blt95e0138311135214/bltf2c3968ff91359d4/sch.png?auto=webp',
        berries: 'https://images.contentstack.io/v3/assets/blt95e0138311135214/blt9a4635519f7ebb01/bry.png?auto=webp',
        vitalchoice: 'https://images.contentstack.io/v3/assets/blt95e0138311135214/blt45b9745b7ea42ff5/vc.png?auto=webp',
        fruitbouquets: 'https://images.contentstack.io/v3/assets/blt95e0138311135214/blt05b55897ca88e9c9/fbq.png?auto=webp',
        personalizationmall: 'https://images.contentstack.io/v3/assets/blt95e0138311135214/blt8d759bea4f1a0bf8/pmall.png?auto=webp',
        florists: 'https://images.contentstack.io/v3/assets/bltdbb9b0b21e621277/blt4ab27fa5f480ed9a/Florists.com_Logo_Color_OT.png?auto=webp',
    };

    if (brandMap[brand]?.length) return brandMap[brand];

    return 'https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blteb80eb70ce7579b2/5d1536b504e065d077c95a66/fw_logo_flowers_light@2x.png';
};

export default getOGImageFallback;
