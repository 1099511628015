/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export default {
    WEBCHAT_ORDER_ATTRIBUTION: 'webchat.order.attribution',
    WEBCHAT_ORDER_ATTRIBUTION_WITH_EID: 'chat.webchat.order.attribution',
    WEBCHAT_STARTED: 'webchat.started',
    WEBCHAT_STARTED_WITH_EID: 'chat.webchat.started',
    WEBCHAT_END_WITH_EID: 'chat.webchat.ended',
    WEBCHAT_END: 'webchat.ended',
};
