/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const RESOURCE_PAGE_FACADE = '/cart';
const RESOURCE_ROOT_FACADE = '/checkout';
const RESOURCE_PAGE_ACTION = '/recipient';

const addRecipient = (wcEnv, jwtToken, orderItemId, recipient, entryId, orderId) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE;

    const payload = { recipient };

    if (entryId) {
        payload.entryId = entryId;
        payload.updateStoredAddress = true;
    }

    let resourcePage = `${RESOURCE_PAGE_FACADE}/${orderItemId}${RESOURCE_PAGE_ACTION}`;
    if (orderId) {
        resourcePage = `${RESOURCE_PAGE_FACADE}/${orderId}${RESOURCE_PAGE_ACTION}/${orderItemId}`;
    }

    return restClient.putFacade(env, resourcePage, jwtToken, payload)
        .then((response) => response);
};

export default addRecipient;
