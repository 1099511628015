/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    takeEvery, call, put, fork,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import personClient from 'wc-api-person';

import { createAccountInProgress, setAccountMessage } from './Account-Actions';
import * as accountActionTypes from './Account-ActionTypes';
import { checkJWT } from '../../../Global/Global-Operations';

const createSignUp = ({
    JWT_TOKEN,
    accountObj,
}) => personClient.createAccountSignUp({}, JWT_TOKEN, accountObj);

function* accountSignUp(action) {
    yield put(createAccountInProgress(true));
    try {
        const JWT_TOKEN = yield call(checkJWT);
        const { data } = action;
        yield call(createSignUp, {
            JWT_TOKEN,
            accountObj: data,
        });
        yield put(setAccountMessage('created'));
    } catch (ex) {
        if (ex?.response?.data?.status === 412) {
            yield put(setAccountMessage('account_exist'));
        } else {
            yield put(setAccountMessage('failed'));

            mbpLogger.logError({
                function: 'accountRegister',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                message: 'accountRegister - Error',
                jsError: ex,
            });
        }
    }
    yield put(createAccountInProgress(false));
}

function* watcherAccountRegister() {
    yield takeEvery(accountActionTypes.SET_ACCOUNT_REGISTER, accountSignUp);
}

const watchers = [
    fork(watcherAccountRegister),
];

export {
    watchers,
    accountSignUp,
};

export default {};
