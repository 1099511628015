/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// import mbpLogger from 'mbp-logger';

export const generateMyAccountDataLayer = ({
    location,
}, pageDataLayer) => {
    const dataLayer = { ...pageDataLayer };

    // Account Home
    if (location.pathname === '/account') {
        dataLayer.pageType = 'myaccount';
        dataLayer.pageName = 'MyAccount';
    }
    // Account: OrderTracking
    if (location.pathname === '/account/orders') {
        dataLayer.pageType = 'ordertracking';
        dataLayer.pageName = 'OrderTrackingSearch';
    } else if (location.pathname?.includes('/account/orders/')) {
        dataLayer.pageType = 'ordertrackingdetail';
        dataLayer.pageName = 'OrderTrackingSearch';
    }

    // Account: AddressBook
    if (location.pathname === '/account/address-book') {
        dataLayer.pageType = 'addressbook';
        dataLayer.pageName = 'MyAccount';
    }
    // Account: Manage Subscriptions
    if (location.pathname === '/account/subscriptions') {
        dataLayer.pageType = 'subscription';
        dataLayer.pageName = 'MyAccount';
    }
    // Account: Payment & Billing
    if (location.pathname === '/account/billing-address') {
        dataLayer.pageType = 'billingaddress';
        dataLayer.pageName = 'MyAccount';
    }
    // Account: Change Password
    if (location.pathname === '/account/change-password') {
        dataLayer.pageType = 'changepassword';
        dataLayer.pageName = 'MyAccount';
    }
    // Account: Change Email
    if (location.pathname === '/account/change-email') {
        dataLayer.pageType = 'changeemail';
        dataLayer.pageName = 'MyAccount';
    }
    // Account: Promotions and Offers
    if (location.pathname === '/account/email-preference') {
        dataLayer.pageType = 'emailpreference';
        dataLayer.pageName = 'MyAccount';
    }
    // Account: Passport
    if (location.pathname === '/account/passport') {
        dataLayer.pageType = 'accountpassport';
        dataLayer.pageName = 'MyAccount';
    }
    // Account: Giftlist
    if (location.pathname === '/account/gift-list') {
        dataLayer.pageType = 'giftlist';
        dataLayer.pageName = 'GiftList';
    }

    // Account: GiftHistory
    if (location.pathname === '/gifthistory-home-gift-baskets') {
        dataLayer.pageType = 'gifthistory';
        dataLayer.pageName = 'GiftHistory';
    }

    if (location.pathname === '/gifthistory-home-cookies') {
        dataLayer.pageType = 'gifthistory';
        dataLayer.pageName = 'GiftHistory';
    }

    if (location.pathname === '/gifthistory-home-steaks') {
        dataLayer.pageType = 'gifthistory';
        dataLayer.pageName = 'GiftHistory';
    }

    if (location.pathname === '/gifthistory-home-bakery') {
        dataLayer.pageType = 'gifthistory';
        dataLayer.pageName = 'GiftHistory';
    }

    // Account: Profile
    if (location.pathname === '/account/profile') {
        dataLayer.pageType = 'accountprofile';
        dataLayer.pageName = 'MyAccount';
    }

    return dataLayer;
};

export default {};
