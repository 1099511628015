/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { getCountryDetailsByThreeDigitCountryCode } from '../../helpers/countryCode';
import getBrandPresentationFamily from '../../../../../app/helpers/getBrandPresentationFamily';

const countryMapper = {
    CANADA: 'CA',
    'UNITED STATES OF AMERICA': 'US',
    CAN: 'CA',
    USA: 'US',
};

function checkIsInternational(orderItem) {
    const isInternational = ['USA', 'US', 'CAN', 'CA'].includes(orderItem?.deliveryInfo?.countryCode || '');
    if (isInternational) {
        return true;
    }
    return false;
}

function getInternationalCountryField(orderItem) {
    let countryCode = '';
    const intCountryCode = orderItem?.deliveryInfo?.countryCode || '';
    if (intCountryCode.length === 3) {
        const countryDetails = getCountryDetailsByThreeDigitCountryCode(intCountryCode);
        countryCode = countryDetails.countryCodeTwoDigit || '';
    } else {
        countryCode = intCountryCode;
    }
    return countryCode;
}

function getCountryField(country) {
    const countryCode = country || '';
    return countryMapper[countryCode] || countryCode;
}

const getOrderDate = (timePlaced) => {
    let returnOrderDate = '';
    if (timePlaced) {
        // replace T to space and / to - for timePlaced string
        // timePlaced - 2020-10-10T07:00:00 or 2020-10-10 07:00:00
        const orderDate = timePlaced.replace('T', ' ').replace(/-/g, '/');
        returnOrderDate = new Date(orderDate);
    }
    return returnOrderDate;
};

const convertThreeToTwoDigitCountryCode = (countryList) => {
    const returnCountryList = [];
    countryList.forEach((countryCode) => {
        const countryInfo = getCountryDetailsByThreeDigitCountryCode(countryCode);
        if (countryInfo.countryCodeTwoDigit && countryInfo.countryName) {
            returnCountryList.push({
                key: countryInfo.countryCodeTwoDigit,
                value: countryInfo.countryName,
            });
        }
    });
    return returnCountryList;
};

const filterInternationalCountry = (country) => {
    // extract international country from array by filtering out USA, CAN
    // eg : ["USA", "CAN", "AUS"] - return AUS
    const internationalCountry = country.filter((countryCode) => !['USA', 'CAN'].includes(countryCode))[0];
    return internationalCountry || '';
};

const getCountryDeliveryList = (productBrand, countryList, isInternational) => {
    let returnCountryList = countryList;
    const productFamily = getBrandPresentationFamily(productBrand);
    if (productFamily === 'flower' && isInternational) {
        returnCountryList = [filterInternationalCountry(countryList)];
    }
    return returnCountryList;
};

const getCountryDeliverableTwoDigit = (productBrand, countries, isInternational) => {
    const countryList = [];
    if (productBrand && countries?.length > 0) {
        const deliverableCountry = getCountryDeliveryList(productBrand, countries, isInternational);
        deliverableCountry.forEach((countryCode) => {
            const countryInfo = getCountryDetailsByThreeDigitCountryCode(countryCode);
            if (countryInfo.countryCodeTwoDigit) {
                countryList.push(countryInfo.countryCodeTwoDigit);
            }
        });
    }
    if (countryList.length > 0) {
        return countryList;
    }
    return ['US', 'CA'];
};

export {
    getOrderDate,
    getCountryField,
    checkIsInternational,
    getCountryDeliveryList,
    filterInternationalCountry,
    getInternationalCountryField,
    convertThreeToTwoDigitCountryCode,
    getCountryDeliverableTwoDigit,
};
