/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from './restClient';

const RESOURCE_ROOT_CARTSERVICE     = '/checkout';
const RESOURCE_VERSION_CARTSERVICE  = '/v2';
const RESOURCE_PAGE_CARTSERVICE     = '/cart';
const RESOURCE_PAGE_ACTION          = '/item';

/**
 * removeOrderItem
 * url - /checkout/{apiVersion}/cart/{cartId}/item
 *
 * @param {String} env
 * @param {String} jwtToken
 * @param {String} orderItemId
 * @param {String} cartId
 */

const removeOrderItem = ({
    env, jwtToken, orderItemId, cartId,
}) => {
    const envObj = env || {};
    envObj.rootUri = `${RESOURCE_ROOT_CARTSERVICE}${RESOURCE_VERSION_CARTSERVICE}`;
    const resourcePage = `${RESOURCE_PAGE_CARTSERVICE}/${cartId}${RESOURCE_PAGE_ACTION}`;

    const payload = {
        cartItemId: orderItemId,
        calculateCart: false,
    };

    return restClient.deleteRequest(envObj, resourcePage, jwtToken, payload)
        .then((response) => response);
};

export default removeOrderItem;
