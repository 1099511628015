/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const updateABTestingState = (state, action) => {
    // all searchTest testing actions get added to the same sub object of the overall abTesting section of state
    if (action.payload.searchTest) {
        const searchTest = { ...state.searchTest };
        if (typeof action.payload.searchTest === 'string') {
            searchTest[action.payload.searchTest.toLowerCase()] = true;
        } else if (Array.isArray(action.payload.searchTest)) {
            action.payload.searchTest.forEach((term) => {
                searchTest[term.toLowerCase()] = true;
            });
        }
        return {
            ...state,
            searchTest,
        };
    }
    if (action.payload.pricingTest) {
        let pricingTest;
        if (state?.pricingTest) {
            pricingTest = { ...state.pricingTest };
            pricingTest[action.payload.pricingTest] = true;
        } else {
            pricingTest = {
                [action.payload.pricingTest]: true,
            };
        }
        return {
            ...state,
            pricingTest,
        };
    }
    if (action.payload.shippingTest) {
        let shippingTest;
        if (state?.shippingTest) {
            shippingTest = { ...state.shippingTest };
            shippingTest[action.payload.shippingTest] = true;
        } else {
            shippingTest = {
                [action.payload.shippingTest]: true,
            };
        }
        return {
            ...state,
            shippingTest,
        };
    }
    return { ...state, ...action.payload };
};

export default {};
