/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import axios from 'axios';
import jwtDecode from 'jwt-decode';
import Helper from '../state/reducers/Helper';
import configENV from '../config';

const { IS_AUTHENTICATED } = configENV;

axios.interceptors.response.use(null, (error) => {
    const expectedError =        error.response
        && error.response.status >= 400
        && error.response.status < 500;
    if (!expectedError) {
        console.error(error);
    }
    return Promise.reject(error);
});

const getJwtInfo = (accessToken, idToken) => {
    const jwToken = accessToken ? jwtDecode(accessToken) : {};
    let contactId;
    let firstName;
    let lastName;
    let email;
    let isAuthenticated;
    let userType;
    let userRole;
    let aud;
    let iat;
    let exp;
    let XAtt3;
    let XAtt4;
    let XAtt5;
    let XAtt6;
    let loginsCount;
    const { sub } = jwToken;
    const persistMember = sessionStorage.getItem('persist:member');
    if (persistMember) {
        const auth = JSON.parse(JSON.parse(sessionStorage.getItem('persist:member')).auth);
        const profile = JSON.parse(JSON.parse(sessionStorage.getItem('persist:member')).profile);
        contactId = profile.contactId;
        isAuthenticated = auth.isAuthenticated;
        userType = auth.userType;
        email = profile.email;
        firstName = profile.firstName;
        lastName = profile.lastName;
        userRole = profile.userRole;
    }

    if (idToken) {
        const jwtId = jwtDecode(idToken);
        if (jwtId.email) {
            if (persistMember) ({ aud, iat, exp } = jwtId);
            else {
                isAuthenticated = IS_AUTHENTICATED;
                // eslint-disable-next-line object-curly-newline
                ({ email, aud, iat, exp } = jwtId);
                contactId = jwtId['https://1800flowers.com/contactId'];
                firstName = jwtId['https://1800flowers.com/firstName'];
                lastName = jwtId['https://1800flowers.com/lastName'];
            }
            XAtt3 = jwtId['https://1800flowers.com/XAtt3'];
            XAtt4 = jwtId['https://1800flowers.com/XAtt4'];
            XAtt5 = jwtId['https://1800flowers.com/XAtt5'];
            XAtt6 = jwtId['https://1800flowers.com/XAtt6'];
            loginsCount = jwtId['https://1800flowers.com/loginsCount'];
        }
    }

    const data = {
        contactId,
        email,
        aud,
        iat,
        exp,
        XAtt3,
        XAtt4,
        XAtt5,
        XAtt6,
        sub,
        firstName,
        lastName,
        loginsCount,
        isAuthenticated,
        userType,
        userRole,
    };
    return data;
};

const getUserProfile = (accessToken, idToken, ContactId) => {
    const userProfile = {};
    const customData = getJwtInfo(accessToken, idToken);
    userProfile.accessToken = accessToken;
    userProfile.idToken = idToken;
    const userContactId = ContactId || customData.contactId;
    userProfile.ContactId = userContactId;
    userProfile.userType = 'G';
    userProfile.userRole = '';

    if (customData.XAtt4 && customData.XAtt5) {
        userProfile.userRole = 'P';
    }
    Object.entries(customData).forEach(([key, value]) => {
        if (key !== 'contactId' && typeof value !== 'undefined') {
            userProfile[key] = value;
        }
    });
    // Get updated email address from session storage
    const profileObject = Helper.getSessionStorageObject(
        userContactId,
        'userProfile',
    );
    const { email } = profileObject;
    if (email) {
        userProfile.email = email;
    } else {
        // Store email address in session storage for `update email id` API
        const object = {
            userProfile: {
                email: customData.email,
                timestamp: new Date().getTime(),
            },
        };
        Helper.setSessionStorageObject(userProfile.ContactId, object);
        userProfile.email = customData.email;
    }
    userProfile.isGuestAccount = /^GUEST-/.test(userContactId);
    if (!userProfile.email) {
        const jwtToken = accessToken ? jwtDecode(accessToken) : {};
        const jwtEmail = jwtToken?.['https://1800flowers.com/']?.emailId;
        userProfile.email = jwtEmail || 'No Email Found-httpService';
    }
    return { ...userProfile };
};

export default {
    getUserProfile,
};
