/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import { createSelector } from 'reselect';
import { getWalletEntries } from '../../../Member/ducks/Common/Common-Selectors';
import { getFeatureFlags } from '../../../Checkout/ducks/App/App-Selectors';

export const googlePayClientId = (state) => state.payment.googlePay.clientToken;

export const getGooglePayCardFromWallet = (state) => {
    const walletCards = getWalletEntries(state);
    return walletCards.filter((card) => (card?.payType === 'GP'))[0];
};

export const getGooglePayChangeAccount = (state) => state.payment.googlePay.changeAccount;

export const getGooglePaymentUsingCardFromWallet = createSelector(
    [
        getFeatureFlags,
        getGooglePayChangeAccount,
        getGooglePayCardFromWallet,
    ],
    (flags, useGooglePayFromWallet, cardFromWallet) => {
        if (flags['is-googlepay-wallet-enabled'] && !useGooglePayFromWallet && cardFromWallet?.payType === 'GP') {
            return true;
        }
        return false;
    },
);

export const getDisplayGooglePayFromWallet = createSelector(
    [
        getFeatureFlags,
        getGooglePayCardFromWallet,
    ],
    (flags, cardFromWallet) => {
        if (flags['is-googlepay-wallet-enabled'] && cardFromWallet?.payType === 'GP') {
            return true;
        }
        return false;
    },
);

export const getGooglePayTokenizedCard = (state) => {
    const savedGooglePayCard = getGooglePayCardFromWallet(state);
    if (savedGooglePayCard?.cardNumber) {
        return savedGooglePayCard.cardNumber;
    }
    return null;
};

export default {};
