/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { createPortal } from 'react-dom';
import Backdrop from '@material-ui/core/Backdrop';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { element, string, object } from 'prop-types';
import Fade from '@material-ui/core/Fade';

const styles = (theme) => ({
    loaderBackground: {
        zIndex: '10000',
        backgroundColor: 'rgba(255,255,255,0.5)',
    },
    spinner: {
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        position: 'fixed',
        top: '35%',
        left: '46%',
        zIndex: '10001',
    },
});

const SiteLoader = ({ target, classes, elementToRender }) => {
    if (typeof document !== 'undefined') {
        const portalRoot = target
            ? document.getElementById(target)
            : document.getElementById('fullPageLoadingDiv');
        const loaderDiv = (
            <Fade
                in
                timeout={{
                    entry: 100,
                    exit: 600,
                }}
            >
                <Backdrop open className={classes.loaderBackground}>
                    <CircularProgress className={classes.spinner} />
                </Backdrop>
            </Fade>
        );
        if (!portalRoot) {
            return null;
        }
        return createPortal(
            elementToRender || loaderDiv,
            portalRoot,
        );
    }

    return null;
};

SiteLoader.propTypes = {
    target: string,
    classes: object.isRequired,
    elementToRender: element,
};

SiteLoader.defaultProps = {
    elementToRender: undefined,
    target: '',
};

export default withStyles(styles)(SiteLoader);
