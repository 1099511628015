/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './Subscription-ActionTypes';

export const subscribeEmail = () => ({
    type: actionTypes.SUBSCRIBE_EMAIL,
});

export const unsubscribeEmail = () => ({
    type: actionTypes.UNSUBSCRIBE_EMAIL,
});

export const subscribeTextmsg = () => ({
    type: actionTypes.SUBSCRIBE_TEXTMSG,
});

export const unsubscribeTextmsg = () => ({
    type: actionTypes.UNSUBSCRIBE_TEXTMSG,
});

export const subscribeOrderStatus = () => ({
    type: actionTypes.SUBSCRIBE_ORDERSTATUS_OPT_IN,
});

export const unsubscribeOrderStatus = () => ({
    type: actionTypes.UNSUBSCRIBE_ORDERSTATUS_OPT_IN,
});

export const subscriptionOptIn = (data) => ({
    type: actionTypes.SUBSCRIPTION_OPT_IN,
    data,
});

export const setSubscriptionMessage = (data) => ({
    type: actionTypes.SET_SUBSCRIPTION_MESSAGE,
    data,
});
