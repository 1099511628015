/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import Cookies from 'universal-cookie';

export function MeasureTimeTaken() {
    const clockTime = {
        start: 0,
        end: 0,
        timeTaken: 0,
    };

    const timeTaken = () => {
        if (clockTime.start > 0 && clockTime.end > 0) {
            clockTime.timeTaken = clockTime.end - clockTime.start;
        }

        return clockTime;
    };

    const startClock = () => {
        clockTime.start = window.performance.now();
        return clockTime.start;
    };

    const endClock = () => {
        clockTime.end = window.performance.now();
        return timeTaken();
    };

    return {
        startClock,
        endClock,
    };
}

export function circuitBreaker(thresholdCount) {
    let count = 0;

    function breakCircuit() {
        if (count < thresholdCount) {
            count += 1;
            return false;
        }

        return true;
    }

    return breakCircuit;
}

export const parseIdToken = (objToken, arrGetValOf) => {
    const objReturn = {};
    Object.keys(objToken).forEach((val) => {
        const strNode = val.toString();
        const iStartPos = strNode.lastIndexOf('/') + 1;
        const iEndPos = strNode.length;
        const strKey = strNode.substring(iStartPos, iEndPos);
        if (arrGetValOf.indexOf(strKey) >= 0) {
            objReturn[strKey] = objToken[strNode];
        }
    });
    return objReturn;
};

export const removeTokenInSession = () => {
    if (typeof window !== 'undefined' && window.sessionStorage) {
        window.sessionStorage.removeItem('app:accessToken');
    }
};

export const removeTokenLocalStorage = (name) => {
    if (typeof window !== 'undefined' && window.localStorage) {
        window.localStorage.removeItem(name);
    }
};

export const setTokenLocalStorage = (value, name) => {
    if (typeof window !== 'undefined' && window.localStorage) {
        window.localStorage.setItem(name, JSON.stringify(value));
    }
};

export const setCookie = (name, value, expiresIn) => {
    const cookie = new Cookies();
    const currentDate = new Date();
    const expDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + expiresIn));
    cookie.set(name, value, {
        expires: expDate,
    });
};

export const getTokenLocalStorage = (name) => ((typeof window !== 'undefined' && window.localStorage) ? window.localStorage.getItem(name) : '');

// eslint-disable-next-line prefer-template
const b64DecodeUnicode = (str) => decodeURIComponent(Array.prototype.map.call(atob(str), (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));

export const parseJwt = (token) => JSON.parse(b64DecodeUnicode(token.split('.')[1].replace('-', '+').replace('_', '/')));

export const getCookie = (name) => {
    if (typeof document !== 'undefined') {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
    return '';
};

export const flattenObject = (obj) => {
    const flattened = {};

    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            Object.assign(flattened, flattenObject(obj[key]));
        } else {
            flattened[key] = obj[key];
        }
    });

    return flattened;
};

export const verifyScopeExist = (accessToken, scopeToVerify) => {
    if (accessToken) {
        const decryptJWT = parseJwt(accessToken);
        const scopesInToken = decryptJWT?.scope || '';
        if (scopeToVerify && scopesInToken.indexOf(scopeToVerify) >= 0) {
            return true;
        }

        // Supporting the new JWT scope
        // More info: https://1800flowersinc.atlassian.net/wiki/spaces/Authentica/pages/2499575809/PWA+Audience+and+Rules+Hooks+Migration+Strategy#How-Does-This-Affect-Dependent-Services%3F
        const permissionInToken = decryptJWT?.permissions || [];
        if (permissionInToken && permissionInToken.includes(scopeToVerify) >= 0) {
            return true;
        }
    }
    return false;
};
