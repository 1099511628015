/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session'; // defaults to localStorage for web and AsyncStorage for react-native
import { combineReducers } from 'redux';
import * as paymentActions from './Payment-Actions';
import * as paymentOperations from './Payment-Operations';
import { paymentMethodsReducer } from './Payment-Reducer';

import paymentFormReducer, { paymentFormActions } from './ducks/PaymentForm';
import billingAddressFormReducer, { billingAddressFormActions } from './ducks/BillingAddressForm';
import rewardPointsReducer, { rewardPointsOperations, rewardPointsActions } from './ducks/RewardPoints';
import subscriptionReducer, { subscriptionActions, subscriptionOperations } from './ducks/Subscription';
import accountReducer, { accountActions, accountOperations } from './ducks/Account';
import promotionReducer, { promotionActions } from './ducks/Promotion';

import * as venmoPlugin from './plugins/Venmo';

// Watchers
const paymentWatcherSagas = [
    ...paymentOperations.watchers,

    // Payment Method Watchers
    ...rewardPointsOperations.watchers,
    ...subscriptionOperations.watchers,
    ...accountOperations.watchers,
    ...venmoPlugin.venmoOperations.watchers,
];

const paymentFormReducerPersistConfig = {
    key: 'paymentForm',
    storage: sessionStorage,
    whitelist: ['paymentMethod'],
};
const paymentFormPersistReducer = persistReducer(paymentFormReducerPersistConfig, paymentFormReducer);

// Reducer
const paymentReducers = combineReducers({
    // paymentForm: paymentFormReducer,
    paymentForm: paymentFormPersistReducer,
    paymentMethods: paymentMethodsReducer,
    billingAddressForm: billingAddressFormReducer,
    rewardPoints: rewardPointsReducer,
    subscription: subscriptionReducer,
    account: accountReducer,
    promotion: promotionReducer,
    venmo: venmoPlugin.venmoReducer,
});

// Ducks
const paymentDucks = {
    paymentForm: {
        paymentFormActions,
    },
    billingAddressForm: {
        billingAddressFormActions,
    },
    rewardPoints: {
        rewardPointsActions,
        rewardPointsOperations,
        rewardPointsReducer,
    },
    subscription: {
        subscriptionActions,
        subscriptionOperations,
        subscriptionReducer,
    },
    account: {
        accountActions,
        accountOperations,
        accountReducer,
    },
    promotion: {
        promotionActions,
    },
};

// Plugins
const paymentPlugins = {
    Venmo: venmoPlugin,
};

export {
    paymentActions,
    paymentOperations,
    paymentWatcherSagas,
    paymentPlugins,
    paymentDucks,
};

export default paymentReducers;
