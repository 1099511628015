/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    SET_CHECKOUT_FEATURE_FLAG,
} from './App-ActionTypes';

export const setCheckoutFeatureFlag = (data) => ({
    type: SET_CHECKOUT_FEATURE_FLAG,
    payload: data,
});

export default {};
