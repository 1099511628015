/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { phoneNumberLink } from './formater';

export default function getSiteConfig(item) {
    if (typeof window !== 'undefined' && typeof sessionStorage !== 'undefined') {
        const persistPage = sessionStorage ? sessionStorage.getItem('persist:page') : null;
        if (persistPage) {
            try {
                let brand;
                const { byId } = JSON.parse(JSON.parse(sessionStorage.getItem('persist:page')).brands);
                Object.keys(byId).forEach((key) => {
                    brand = key;
                });
                if (!brand) return null;
                if (item === 'phoneNumber') {
                    return phoneNumberLink(byId[brand][item]);
                } if (item) {
                    return byId[brand][item];
                }
                return byId[brand];
            } catch (ex) {
                return null;
            }
        }
    }
    return null;
}
