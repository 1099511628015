/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const CLICKSTREAM_EVENT = 'CLICKSTREAM/EVENT';
const PRODUCT_IMPRESSION = 'CLICKSTREAM/PRODUCT_IMPRESSION';
const CUSTOM_TRACKING_EVENT = 'CLICKSTREAM/CUSTOM_TRACKING_EVENT';
const CUSTOMER_IDENTIFICATION_EVENT = 'CLICKSTREAM/CUSTOMER_IDENTIFICATION_EVENT';
const SET_CLICKSTREAM_EXPERIMENTS = 'SET_CLICKSTREAM_EXPERIMENTS';

export default {
    CLICKSTREAM_EVENT,
    PRODUCT_IMPRESSION,
    CUSTOM_TRACKING_EVENT,
    CUSTOMER_IDENTIFICATION_EVENT,
    SET_CLICKSTREAM_EXPERIMENTS,
};
