/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const RESOURCE_PAGE = '/cart';
const RESOURCE_ROOT = '/checkout';

const duplicateCart = (wcEnv, jwtToken, orderItemId, orderId) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT;

    const resourcePage = `${RESOURCE_PAGE}/${orderId}/duplicate/${orderItemId}`;
    return restClient.postFacade(env, resourcePage, jwtToken)
        .then((response) => response);
};

export default duplicateCart;
