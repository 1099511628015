/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const TAGMANAGER_TRACK_PAGE_VIEW = 'mbp-tag-manager/TRACK/PAGE/VIEW';
const TAGMANAGER_TRACK_PAGE_VIEW_COMPLETE = 'mbp-tag-manager/TRACK/PAGE/VIEW/COMPLETE';
const TAGMANAGER_SET_CURRENT_PAGE_VIEW = 'mbp-tag-manager/SET/CURRENT/PAGE/VIEW';
const TAGMANAGER_TRACK_EVENT = 'mbp-tag-manager/TRACK/EVENT';
const TAGMANAGER_TRACK_EVENT_COMPLETE = 'mbp-tag-manager/TRACK/EVENT/COMPLETE';
const TAGMANAGER_QUEUE_PAGE_EVENT = 'mbp-tag-manager/QUEUE/EVENT';
const TAGMANAGER_SET_SESSION_CAMPAIGN = 'mbp-tag-manager/SET/SESSION/CAMPAIGN';
const TAGMANAGER_QUEUED_EVENT_COMPLETE = 'mbp-tag-manager/QUEUE/EVENT/COMPLETE';

export default {
    TAGMANAGER_TRACK_PAGE_VIEW,
    TAGMANAGER_TRACK_PAGE_VIEW_COMPLETE,
    TAGMANAGER_SET_CURRENT_PAGE_VIEW,
    TAGMANAGER_TRACK_EVENT,
    TAGMANAGER_TRACK_EVENT_COMPLETE,
    TAGMANAGER_QUEUED_EVENT_COMPLETE,
    TAGMANAGER_QUEUE_PAGE_EVENT,
    TAGMANAGER_SET_SESSION_CAMPAIGN,
};
