/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpLogger from 'mbp-logger';
import Cookies from 'universal-cookie';
import parser from 'ua-parser-js';

const brandIdsByHostName = {
    '1800flowers': '18F',
    harryanddavid: 'HD',
    '1800baskets': '18B',
    simplychocolate: 'SCH',
    fruitbouquets: 'FBQ',
    cheryls: 'CCO',
    personalizationuniverse: 'PZU',
    thepopcornfactory: 'TPF',
    wolfermans: 'WLF',
    stockyards: 'SY',
    berries: 'BRY',
    personalizationmall: 'PML',
    vitalchoice: 'VC',
    florists: 'FL',
};

export const getBrandDomain = (hostName) => {
    let brandDomain = '1800flowers';

    Object.keys(brandIdsByHostName).forEach((domain) => {
        if (hostName.indexOf(domain) !== -1) {
            brandDomain = domain;
        }
    });

    return brandDomain;
};

export const discoverDeviceType = () => {
    const parsedUserAgent =  (typeof window !== 'undefined') ? parser(navigator.userAgent) : {};
    const { device = '', browser = '' } = parsedUserAgent; // more are available from parsedUA (cpu, os, etc)
    // mobile checks
    if (device && device.type === 'mobile') {
        return 'mobile';
    }
    // tablet checks
    if (device && device.type === 'tablet') {
        return 'tablet';
    }
    // desktop checks
    // can add array of browser strings to check against (forcing IE to always desktop)
    if (browser && !browser?.name?.toLowerCase()?.includes('mobile')) { // when mocking emulator mobile from desktop, UA still has browser info, need to exclude mobile
        return 'desktop';
    }

    return 'desktop';
};

// Browser detection code taken from https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
export const getBrowser = () => {
    let sBrowser = '';
    const sUsrAg = (typeof window !== 'undefined') ? navigator.userAgent : '';
    const isBrave = (typeof window !== 'undefined') ? typeof navigator.brave  !== 'undefined' : false;

    // The order matters here, and this may report false positives for unlisted browsers.
    if (sUsrAg.indexOf('Firefox') > -1 || sUsrAg.indexOf('FxiOS') > -1) {
        sBrowser = 'Mozilla Firefox';
        // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
        // Android
        // "Mozilla/5.0 (Android 11; Mobile; rv:85.0) Gecko/85.0 Firefox/85.0"
        // iOS
        // "Mozilla/5.0 (iPhone; CPU OS 14_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) FxiOS/31.0 Mobile/15E148 Safari/605.1.15"
    } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
        sBrowser = 'Samsung Internet';
        // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
        // non Samsung Android
        // Mozilla/5.0 (Linux; Android 11; Mi A3) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/13.2 Chrome/83.0.4103.106 Mobile Safari/537.36
    } else if (sUsrAg.indexOf('AlohaBrowser') > -1) {
        sBrowser = 'Aloha Browser';
        // Android
        // "Mozilla/5.0 (Linux; Android 11; XIAOMI Mi A3 Build/NMF26F) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/83.0.4103.96 Mobile Safari/537.36 AlohaBrowser/3.1.1"
        // iOS
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 14_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Version/14.4 Safari/605.1.15 AlohaBrowser/3.1.1"
    } else if (sUsrAg.indexOf('UCBrowser') > -1) {
        sBrowser = 'UC Browser';
        // iOS
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 14_4 like Mac OS X; en-US) AppleWebKit/537.51.1 (KHTML, like Gecko) Mobile/18D52 UCBrowser/11.3.5.1203 Mobile"
    } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1 || sUsrAg.indexOf('OPT') > -1) {
        sBrowser = 'Opera';
        // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
        // Android
        // "Mozilla/5.0 (Linux; Android 11; Mi A3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/86.0.4240.198 Mobile Safari/537.36 OPR/61.2.3076.56749"
        // iOS
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 14_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 OPT/2.5.5"
    } else if (sUsrAg.indexOf('Trident') > -1) {
        sBrowser = 'Microsoft Internet Explorer';
        // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf('Edge') > -1 || sUsrAg.indexOf('EdgiOS') > -1 || sUsrAg.indexOf('EdgA') > -1) {
        sBrowser = 'Microsoft Edge';
        // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
        // Android
        // "Mozilla/5.0 (Linux; Android 11; Mi A3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.3865.116 Mobile Safari/537.36 EdgA/46.01.4.5140"
        // iOS
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 14_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0 EdgiOS/46.1.2 Mobile/15E148 Safari/605.1.15"
    } else if (sUsrAg.indexOf('DuckDuckGo') > -1) {
        sBrowser = 'DuckDuckGo';
        // DuckDuckGo Android
        // "Mozilla/5.0 (Linux; Android 11) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/88.0.4324.93 Mobile DuckDuckGo/5 Safari/537.36"
        // DuckDuckGo iOS
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 14_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.4 Mobile/15E148 DuckDuckGo/7 Safari/605.1.15"
    } else if (isBrave) {
        sBrowser = 'Brave';
        // Brave has no diff on iOS
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 14_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0 Mobile/15E148 Safari/604.1"
        // Brave has no diff on Android
        // "Mozilla/5.0 (Linux; Android 11; Mi A3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.96 Mobile Safari/537.36"
    } else if (sUsrAg.indexOf('Chrome') > -1 || sUsrAg.indexOf('CriOS') > -1) {
        sBrowser = 'Chrome';
        // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
        // Chrome Android
        // "Mozilla/5.0 (Linux; Android 11; Mi A3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.93 Mobile Safari/537.36"
        // Chrome iOS
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 14_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/87.0.4280.77 Mobile/15E148 Safari/604.1"
    } else if (sUsrAg.indexOf('Safari') > -1) {
        sBrowser = 'Apple Safari';
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 14_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0.3 Mobile/15E148 Safari/604.1"
    } else {
        sBrowser = 'unknown';
    }
    return sBrowser;
};

export const getOperatingSystem = () => {
    const parsedUserAgent = (typeof window !== 'undefined') ? parser(navigator.userAgent) : { os: {} };
    const { os: { name = '' } } = parsedUserAgent;
    return name;
};

const getKey = (entry, brand) => entry.substring(brand.length + 1);

export const parseConfig = (rawConfig, domain) => {
    const parsedConfig = {};

    try {
        mbpLogger.logDebug({
            rawConfig,
            domain,
            function: 'parseConfig',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: 'Parse Config | START',
        });

        // Pull out brand-specfic properties
        Object.keys(rawConfig).forEach((entry) => {
            if (entry.indexOf(domain) !== -1) {
                const fkey = entry.startsWith(domain) ? getKey(entry, domain) : '';

                if (fkey) {
                    parsedConfig[fkey] = rawConfig[entry];
                }
            }
        });
    } catch (ex) {
        mbpLogger.logError({
            domain,
            rawConfig,
            function: 'parseConfig',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            jsError: ex,
            message: 'Failed to get parse config data.',
        });
    }

    return parsedConfig;
};

export const isCanaryModeActive = () => {
    const cookies = new Cookies();
    const canaryCookie = cookies.get('canary');
    if (canaryCookie && canaryCookie === 'true') {
        return true;
    }
    return false;
};

export const getAppVersion = () => {
    let version = '';
    const isCanary = isCanaryModeActive();
    if (isCanary) {
        version = 'canary';
    }
    return version;
};

// Generate uuid
/* eslint-disable */
const uuidv4 = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
});

export const getUniqueKey = () => `${uuidv4()}-${Date.now()}`;

export const getUserKey = (sessionUserKey) => {
    let userKey = '';
    if (typeof window !== 'undefined') {
        const cookies = new Cookies();
        userKey = cookies.get('userKey');
        if (!userKey) {
            userKey = sessionUserKey || getUniqueKey();
            cookies.set('userKey', userKey, {
                path: '/',
                encode: String,
            });
        }
    }
    return userKey;
};

export default {};
