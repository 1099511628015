/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useContext } from 'react';
import Auth0Context from './auth-context';

const useAuth = () => {
    const context = useContext(Auth0Context);

    return context;
};

export default useAuth;
