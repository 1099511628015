/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import checkoutActionTypes from './Checkout-ActionTypes';

export const calculateShipping = () => ({
    type: checkoutActionTypes.CALCULATE_SHIPPING_ACTION,
});

export const calculateShippingComplete = () => ({
    type: checkoutActionTypes.CALCULATE_SHIPPING_COMPLETE,
});

export const loadOrderData = (order) => ({
    type: checkoutActionTypes.LOAD_ORDER_DATA,
    data: order,
});

export const saveBannerToOrder = (bannerCode, lsid) => ({
    type: checkoutActionTypes.PERSIST_ATTRIBUTION_ACTION,
    payload: {
        bannerCode,
        lsid,
    },
});

export const saveBannerProcessCheckCompleted = () => ({
    type: checkoutActionTypes.SAVE_BANNER_PROCESS_CHECK_COMPLETED,
});

export const saveBannerProcessCheckFailed = () => ({
    type: checkoutActionTypes.SAVE_BANNER_PROCESS_CHECK_FAILED,
});

export const setSubmitPaymentButtonType = (type) => ({
    type: checkoutActionTypes.SET_SUBMIT_PAYMENT_BUTTONT_TYPE,
    payload: type,
});

export const checkApplePayEnabled = () => ({
    type: checkoutActionTypes.CHECK_APPLE_PAY_ENABLED,
});

export const appProcessPage = (page, history, pageData) => ({
    type: checkoutActionTypes.APP_PROCESS_PAGE,
    data: {
        page,
        history,
        pageData,
    },
});

export const validateShopCart = (payload) => ({
    type: checkoutActionTypes.VALIDATESHOPCART,
    data: payload,
});

export const handleAddExtraItem = (extraItemData, orderItemId, selectedItems, handleClose) => ({
    type: checkoutActionTypes.HANDLE_ADD_EXTRA_ITEM,
    data: {
        extraItemData,
        orderItemId,
        selectedItems,
        handleClose,
    },
});

// dependency

export const getDynamicPassportCharge = () => ({
    type: 'PASSPORT/GET_DYNAMIC_PASSPORT_CHARGE',
});

export const saveExtraAttributesCompleted = () => ({
    type: checkoutActionTypes.SAVE_EXTRA_ATTRIBUTES_COMPLETED,
});
