/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
/* eslint-disable import/prefer-default-export */

import * as actionsTypes from './Shoprunner-ActionTypes';

export const loadShopRunner = () => ({
    type: actionsTypes.LOAD_SHOPRUNNER,
});

export const validateShopRunnerToken = (accesstoken) => ({
    type: actionsTypes.VALIDATE_SHOPRUNNER,
    data: {
        accesstoken,
    },
});

export const loadSrToken = (srToken) => ({
    type: actionsTypes.LOAD_SR_TOKEN,
    data: {
        srToken,
    },
});

export const clearSrToken = () => ({
    type: actionsTypes.CLEAR_SR_TOKEN,
});
