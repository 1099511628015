/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import Cookies from 'universal-cookie';
import isDesktopMobileTablet from '../../../app/helpers/DesktopMobile/isDesktopMobileTablet';

export const isWineAvailable = (data) => {
    let availability = false;
    if (!data?.wineAvailability) {
        return false;
    }
    const keys = Object.keys(data.wineAvailability);
    keys.forEach((key) => {
        if (data.wineAvailability[key]?.isProductAvailable) {
            availability = true;
        }
    });
    return availability;
};

export const mockSSREnvironment = (hostname, location) => {
    const mockSSRConfig = {};
    const cookies = new Cookies();
    const sv = cookies.get('sv');
    mockSSRConfig.deviceType = isDesktopMobileTablet();
    mockSSRConfig.isBot = false;
    mockSSRConfig.hostname = hostname;
    mockSSRConfig.path = location.pathname;
    mockSSRConfig.userAgent = navigator.userAgent;
    mockSSRConfig.sv = sv;
    return mockSSRConfig;
};

export const getSCIandSGParams = (sgCustomParams = {}, sku = '') => {
    const sciParams = {};
    if (sku && sgCustomParams) {
        sciParams.source = sgCustomParams[sku]?.source;
        sciParams.SCILocNumber = sgCustomParams[sku]?.SCILocNumber;
    }

    return sciParams;
};

export default {};
