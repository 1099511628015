/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from './restClient';

const RESOURCE_ROOT_CARTSERVICE = '/checkout';
const RESOURCE_VERSION_CARTSERVICE = '/v2';
const RESOURCE_PAGE_CARTSERVICE = '/cart';
const RESOURCE_PAGE_ACTION = '/submitcart';
/**
 * submitCart
 * url - /checkout/{apiVersion}/cart/{cartId}/submitcart?brand={brandId}
 *
 * @param {Object} args
 * @param {string} args.env
 * @param {String} args.jwtToken
 * @param {String} args.cartId
 * @param {String} args.sourceApp
 * @param {Object} args.customer
 * @param {Object} args.payment
 */
const placeOrderCart = ({
    env, jwtToken, cartId, sourceApp, customer,
    payment, recaptcha,
}) => {
    const envObj = env || {};

    envObj.rootUri = `${RESOURCE_ROOT_CARTSERVICE}${RESOURCE_VERSION_CARTSERVICE}`;

    const resourcePage = `${RESOURCE_PAGE_CARTSERVICE}/${cartId}${RESOURCE_PAGE_ACTION}`;

    const payload = {
        sourceApp,
        payment,
        customer,
    };

    if (recaptcha) {
        return restClient.postRequest(env, resourcePage, jwtToken, payload, {}, { recaptcha })
            .then((response) => response);
    }

    return restClient.postRequest(envObj, resourcePage, jwtToken, payload)
        .then((response) => response);
};

export default placeOrderCart;
