/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './RewardPoints-ActionTypes';

export const loadRewardEligibility = () => ({
    type: actionTypes.LOAD_REWARD_POINTS_ELIGIBILITY,
});

export const setRewardEligibility = (data) => ({
    type: actionTypes.SET_REWARD_POINTS_ELIGIBILITY,
    data,
});

export const loadRewardPoints = () => ({
    type: actionTypes.LOAD_REWARD_POINTS,
});

export const setRewardPoints = (data) => ({
    type: actionTypes.SET_LOAD_REWARD_POINTS,
    data,
});

export const setSelectedPointsReward = (amount, points) => ({
    type: actionTypes.SET_SELECTED_REWARD_POINTS,
    data: {
        amount,
        points,
    },
});

export const clearAllRewardPoints = () => ({
    type: actionTypes.CLEAR_ALL_REWARD_POINTS,
});

export const clearSelectedRewardPoints = () => ({
    type: actionTypes.CLEAR_SELECTED_REWARD_POINTS,
});
