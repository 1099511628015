/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './Wallet-ActionTypes';

export const loadWallet = () => ({
    type: actionTypes.LOAD_WALLET,
});

export const saveWallet = (walletEntries) => ({
    type: actionTypes.SAVE_WALLET,
    data: {
        walletEntries,
    },
});

export const clearWallet = () => ({
    type: actionTypes.CLEAR_WALLET,
});

export const savedWallet = () => ({
    type: actionTypes.SAVED_WALLET,
});

export const addCreditCard = (cardDetails) => ({
    type: actionTypes.ADD_CREDIT_CARD_TO_WALLET,
    data: cardDetails,
});

export const addCreditCardError = (error) => ({
    type: actionTypes.SET_CARD_ERROR,
    data: error,
});
export const clearCreditCardError = () => ({
    type: actionTypes.CLEAR_CARD_ERROR,
});
export const saveCreditCardFlag = (cardFlag) => ({
    type: actionTypes.SET_CARD_SET_SAVED_FLAG,
    data: cardFlag,
});
