/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const cancelOrderAPI = (jwt, data) => {
    const wcEnv = {
        rootUri: '/retention',
    };
    const resourcePath = '/selfservice/order/cancel';
    return restClient.postFacade(wcEnv, resourcePath, jwt, data, null, null, false).then((response) => response);
};

export default cancelOrderAPI;
