/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const fetchASMAgentNotesApi = async (wcEnv, jwtToken, data) => {
    const resourcePage = `retention-subscription/passport/retrieve-agent-notes/${data}`;

    return restClient.getFacade(wcEnv, resourcePage, jwtToken)
        .then((response) => response.data);
};

export default fetchASMAgentNotesApi;
