/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import {
    number,
    string,
    bool,
} from 'prop-types';
import mbpLogger from 'mbp-logger';
import EmailCaptureWidget from './EmailCaptureWidget';
import findContent from '../../gql/queries/findContent';
import { getBrandName } from '../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getIsAuthenticatedStatus } from '../../../state/ducks/Member/ducks/Common/Common-Selectors';

const EmailCaptureWidgetQueryContainer = ({
    brandName,
    showModalAfterMS,
    isUserAuthenticated,
}) => {
    if (isUserAuthenticated) return null;

    const EMAIL_CAPTURE_WIDGET_QUERY = findContent({
        brand: brandName,
        contentType: 'email_capture_widget',
    });

    const { data, error, loading } = useQuery(EMAIL_CAPTURE_WIDGET_QUERY);

    if (loading) {
        return null;
    }

    if (error) {
        mbpLogger.logError({
            function: 'EmailCaptureWidgetQueryContainer.js',
            message: 'Error loading data from findContent api',
            appName: process.env.npm_package_name,
            module: 'EmailCaptureWidget',
            jsError: error,
        });
    }

    const emailCaptureWidgetCMSData = data?.findContent?.content?.entries?.[0];

    if (emailCaptureWidgetCMSData) {
        const blockData = { ...emailCaptureWidgetCMSData };
        return (
            <EmailCaptureWidget
                widgetCMSData={blockData}
                showModalAfterMS={showModalAfterMS}
            />
        );
    }
    return null;
};

const mapStateToProps = (state) => ({
    brandName: getBrandName(state),
    isUserAuthenticated: getIsAuthenticatedStatus(state),
});

EmailCaptureWidgetQueryContainer.propTypes = {
    brandName: string,
    isUserAuthenticated: bool,
    showModalAfterMS: number,
};

EmailCaptureWidgetQueryContainer.defaultProps = {
    brandName: '',
    isUserAuthenticated: false,
    showModalAfterMS: 0,
};

export default connect(
    mapStateToProps,
    {},
)(EmailCaptureWidgetQueryContainer);
