/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { combineReducers } from 'redux';

import errorReducer from './ducks/Error';
import showLoadingMessageReducer from './ducks/ShowLoadingMessage';
import qasAddressSearchReducer from './ducks/AddressSearchQAS';
import pmallPersonalizationReducer from './ducks/PmallPersonalization';

export default combineReducers({
    error: errorReducer,
    showLoadingMessage: showLoadingMessageReducer,
    addressSearchQAS: qasAddressSearchReducer,
    pmallPersonalization: pmallPersonalizationReducer,
});
