/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpLogger from 'mbp-logger';
import restClient from '../../../app/helpers/restClient';
import { getGuestUserAccessToken } from '../../../state/ducks/Member/ducks/Auth/Plugin/auth/guest';
// import axios from 'axios';

const reportConversion = async (token, reqBody = {}) => {
    try {
        const freshToken = await getGuestUserAccessToken() || token;
        const route = 'bumblebee/report-conversion';

        const response = await restClient.postFacade({ rootUri: '/' }, route, freshToken, reqBody);
        delete response.headers;
        delete response.request;
        delete response.config;
        // * Local testing region:
        // const response = await axios.post('http://localhost:8085/bb_api/report_conversion', formattedPayload);
    } catch (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            errorMessage: error,
            message: `WebChat: Report conversion: ${error} - convid: ${reqBody.conversationId}`,
        });
    }
};

export default reportConversion;
