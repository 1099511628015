/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import sessionStorage from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import * as actions from './Cart-Actions';

const cartReducer = (state = {}, action) => {
    switch (action.type) {
        case actions.updateCartSnapShot().type:
            return action.data;
        default:
            return state;
    }
};

export default persistReducer({
    key: 'cart-reducer',
    storage: sessionStorage,
    blacklist: [],
}, cartReducer);
