/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const cleanObjectFromNullUndefined = (obj = {}) => {
    const newObj = { ...obj };
    const objectKeys = Object.keys(newObj);
    if (objectKeys.length) {
        objectKeys.forEach((key) => {
            if (typeof newObj[key] === 'object' && !Array.isArray(newObj[key])) {
                cleanObjectFromNullUndefined(newObj[key]);
            } else if (newObj[key] === null || typeof newObj[key] === 'undefined') {
                delete newObj[key];
            }
        });
    }
    return newObj;
};

class PayloadGenerator {
    constructor() {
        this.attributes = [];
        this.subscription = null;
        this.personalization = null;
        this.greeting = null;
        this.wineVerify = null;
        this.components = [];
        this.item = {};
        this.recipients = [];
    }

    addAttributes = ({
        attributeName,
        attributeValue,
    }) => {
        this.attributes.push({
            attributeName,
            attributeValue,
        });
    }

    setSubscription = ({
        type,
        duration,
        interval,
    }) => {
        if (
            type !== ''
            || duration !== ''
            || interval !== ''
        ) {
            this.subscription = {
                type,
                duration,
                interval,
            };
        }
    }

    setProductDetails = ({
        productCode,
        productId,
        quantity,
        brandCode,
        categoryId,
        lineItemType,
    }) => {
        this.item = cleanObjectFromNullUndefined({
            productCode,
            productId,
            quantity,
            brandCode,
            categoryId,
            lineItemType,
        });
    }

    addRecipient = ({
        address1,
        address2,
        address3,
        addressId,
        addressStatus,
        addressTypeIndicator,
        city,
        confirmEmail,
        country,
        dpvIndicator,
        email,
        entryId,
        firstName,
        isAddressVerified,
        keepThisAddress,
        lastName,
        locationType,
        mobilePhone,
        organizationName,
        phone,
        state,
        useAddressForAll,
        zipCode,
    }) => {
        this.recipients.push(cleanObjectFromNullUndefined({
            address1,
            address2,
            address3,
            addressId,
            addressStatus,
            addressTypeIndicator,
            city,
            confirmEmail,
            country: country || 'USA',
            dpvIndicator,
            email,
            entryId,
            firstName,
            isAddressVerified,
            keepThisAddress,
            lastName,
            locationType: locationType || 'Residence',
            mobilePhone,
            organizationName,
            phone,
            state,
            useAddressForAll,
            zipCode,
        }));
    }

    setPersonalization = ({
        personalizationId,
        personalizationLines,
        personalizationType,
        productPersonalization: {
            action,
            brandCode,
            key_id,
            personalizations_id,
            productCode,
            text,
            thumbnail,
        } = {},
    }) => {
        this.personalization = cleanObjectFromNullUndefined({
            personalizationId,
            /**
         * {
                perAttributeId: 'string',
                perAttributeValue: 'string',
            },
         */
            ...personalizationLines?.length && { personalizationLines: [...personalizationLines] },
            personalizationType, // PACKAGE_GREETING/PRODUCT_PERSONALIZATION/PRODUCT_PERSONALIZATION_GCI/PRODUCT_PERSONALIZATION_PU/EXTERNAL
            productPersonalization: {
                action,
                brandCode,
                key_id,
                personalizations_id,
                productCode,
                text,
                thumbnail,
            },
        });
    }

    setGreeting = ({
        message,
        messageLines,
        occasionCode,
        signature,
    }) => {
        this.greeting = cleanObjectFromNullUndefined({
            message,
            ...messageLines?.length && { messageLines: [...messageLines] },
            occasionCode,
            signature,
        });
    }

    setWineVerification = ({
        birthDay,
        birthMonth,
        birthYear,
        minAge,
    }) => {
        this.wineVerify = cleanObjectFromNullUndefined({
            birthDay,
            birthMonth,
            birthYear,
            minAge,
        });
    }

    setComponents = (items) => {
        const components = [];

        items.forEach((item) => {
            const formatteditem = {
                brandCode: item.productBrand,
                componentType: item.addonType, // CYO, PYO, ADDON
                productCode: item.productSku,
                productId: item.productId || '',
                quantity: item.quantity || '1',
                ...item.wineVerify && {
                    wineVerify: {
                        birthDay: item.wineVerify.birthDay,
                        birthMonth: item.wineVerify.birthMonth,
                        birthYear: item.wineVerify.birthYear,
                        minAge: item.wineVerify.minAge,
                    },
                },
            };
            components.push(cleanObjectFromNullUndefined(formatteditem));
        });
        this.components.push(...components);
    }

    getRecipients = () => this.recipients

    constructPayload = () => ({
        ...this.item,
        ...this.subscription && { subscription: this.subscription },
        ...this.attributes?.length && { attributes: this.attributes },
        ...this.subscription && { subscription: this.subscription },
        ...this.personalization && { personalization: this.personalization },
        ...this.greeting && { greeting: this.greeting },
        ...this.wineVerify && { wineVerify: this.wineVerify },
        ...this.components?.length && { components: this.components },
    })
}

export default PayloadGenerator;
