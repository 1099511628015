/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const GET_VENMO_CLIENTID = 'mbp-pwa-ui/GET/VENMO/CLIENTID';
export const CLEAR_VENMO_CLIENTID = 'mbp-pwa-ui/CLEAR/VENMO/CLIENTID';
export const LOAD_VENMO_CLIENTID = 'mbp-pwa-ui/LOAD/VENMO/CLIENTID';
export const SUBMIT_VENMO_PAYMENT = 'mbp-pwa-ui/SUBMIT/VENMO/PAYMENT';

export default {};
