/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';

import sessionStorage from 'redux-persist/lib/storage/session'; // defaults to localStorage for web and AsyncStorage for react-native

import * as recipientActions from './Recipient-Actions';
import * as recipientOperations from './Recipient-Operations';
import * as recipientReducer from './Recipient-Reducer';

// Ducks
import recipientFormReducer, { recipientFormOperations, recipientFormActions } from './ducks/RecipientForm';
import retryCalculateShippingReducer, { retryCalculateShippingActions } from './ducks/RetryCalculateShipping';
import shippingInfoReducer, { shippingInfoActions, shippingInfoOperations } from './ducks/ShippingInfo';
import giftMessageReducer, { giftMessageFormActions, giftMessageFormOperations, giftMessageFormSelectors } from './ducks/GiftMessageForm';
import cardIsleReducer, { cardIsleActions, cardIsleOperations, cardIsleHelpers } from './ducks/CardIsle';
import giftMessageExpressionsReducer, {
    giftMessageExpressionsActions,
    // giftMessageExpressionsOperations
} from './ducks/GiftMessageExpressions';

const recipientReducers = combineReducers({
    recipients: recipientReducer.recipientsReducer,
    cartItems: recipientReducer.orderItemsReducer,
    workingRecipient: recipientReducer.workingRecipientReducer,
    recipientForm: recipientFormReducer,
    calShipping: retryCalculateShippingReducer,
    shippingInfo: shippingInfoReducer,
    giftMessageExpressions: giftMessageExpressionsReducer,
    giftMessage: giftMessageReducer,
    cardIsle: cardIsleReducer,
});

const recipientDucks = {
    recipientForm: {
        recipientFormOperations,
        recipientFormActions,
    },
    giftMessageForm: {
        giftMessageFormActions,
        giftMessageFormOperations,
        giftMessageFormSelectors,
        giftMessageReducer,
    },
    retryCalculateShipping: {
        retryCalculateShippingActions,
        retryCalculateShippingReducer,
    },
    shippingInfo: {
        shippingInfoActions,
        shippingInfoOperations,
        shippingInfoReducer,
    },
    giftMessageExpressions: {
        giftMessageExpressionsActions,
        // giftMessageExpressionsOperations,
    },
    cardIsle: {
        cardIsleActions,
        cardIsleOperations,
        cardIsleReducer,
        cardIsleHelpers,
    },
};

const recipientWatcherSagas = [
    ...recipientFormOperations.watchers,
    ...giftMessageFormOperations.watchers,
    ...recipientOperations.watchers,
    ...shippingInfoOperations.watchers,
    ...cardIsleOperations.watchers,
    // ...giftMessageExpressionsOperations.watchers,
];

export {
    recipientOperations,
    recipientActions,
    recipientWatcherSagas,
    recipientDucks,
};

const recipientReducerPersistConfig = {
    key: 'recipient',
    storage: sessionStorage,
    whitelist: ['cartItems'],
};

const recipientPersistReducer = persistReducer(recipientReducerPersistConfig, recipientReducers);

export default recipientPersistReducer;
