/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import actionTypes from './Payment-ActionTypes';

const setSkipValidateCart = (data) => ({
    type: actionTypes.SKIP_VALIDATE_CART,
    data,
});

const loadCompletedPaymentDetails = () => ({
    type: actionTypes.LOAD_COMPLETE_PAYMENT_DETAILS,
});

const updateCompletedPaymentDetails = (data) => ({
    type: actionTypes.UPDATE_COMPLETE_PAYMENT_DETAILS,
    data,
});

const activePaymentMethod = (paymentMethod) => ({
    type: actionTypes.ACTIVE_PAYMENT_METHOD,
    data: {
        paymentMethod,
    },
});

const inActivePaymentMethod = (paymentMethod) => ({
    type: actionTypes.INACTIVE_PAYMENT_METHOD,
    data: {
        paymentMethod,
    },
});

const placeOrder = (config) => ({
    type: actionTypes.PLACE_ORDER,
    payload: config,
});

const clickStreamOrderComplete = (payload) => ({
    type: actionTypes.CLICKSTREAM_ORDER_COMPLETE,
    payload,
});

const applyGiftCard = (giftcardNumber, giftcardPin) => ({
    type: actionTypes.APPLY_GIFT_CARD_ACTION,
    payload: {
        giftcardNumber,
        giftcardPin,
    },
});

const applyGiftCardProccessCompleted = () => ({
    type: actionTypes.APPLY_GIFT_CARD_PROCESS_COMPLETED,
});

const removeGiftCard = (giftcardNumber, giftCardId) => ({
    type: actionTypes.REMOVE_GIFT_CARD_ACTION,
    payload: {
        giftcardNumber,
        giftCardId,
    },
});

const removeGiftCardProccessCompleted = () => ({
    type: actionTypes.REMOVE_GIFT_CARD_PROCESS_COMPLETED,
});

const loadPaymentMethods = (data) => ({
    type: actionTypes.CHECKOUT_PAYMENT_METHODS_LOAD,
    data,
});

export const updateShowOrderConfirmation = () => ({
    type: actionTypes.SHOW_ORDER_CONFIRMATION,
});

const processNBCUMovie = (isFromApplePay, history) => ({
    type: actionTypes.PROCESS_NBCU_MOVIE,
    payload: {
        isFromApplePay,
        history,
    },
});

const postOrderCompleteAttributionData = (payload) => ({
    type: actionTypes.POST_ORDER_COMPLETE_ATTRIBUTION_DATA,
    payload,
});

// dependency

export const clearPassportError = () => ({
    type: 'PASSPORT/CLEAR_PASSPORT_ERROR',
});

export {
    placeOrder,
    clickStreamOrderComplete,
    updateCompletedPaymentDetails,
    loadCompletedPaymentDetails,
    activePaymentMethod,
    inActivePaymentMethod,
    applyGiftCard,
    applyGiftCardProccessCompleted,
    removeGiftCard,
    removeGiftCardProccessCompleted,
    loadPaymentMethods,
    processNBCUMovie,
    postOrderCompleteAttributionData,
    setSkipValidateCart,
};
