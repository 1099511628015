/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import mbpLogger from 'mbp-logger';
import QueryString from 'qs';

import { useRef, useState, useEffect } from 'react';

// attempt, success, and fail helper methods
let totalAttempts = 0;
const incrementAttempts = (callback, interval, maxAttempts) => {
    totalAttempts += 1;

    if (typeof maxAttempts !== 'undefined') {
        if (totalAttempts > maxAttempts) {
            clearInterval(interval);
            callback();

            const urlPath = window.location.pathname || '';

            mbpLogger.logWarning({
                jsError: {
                    callback,
                    interval,
                    maxAttempts,
                    urlPath,
                },
                function: 'incrementAttempts',
                appName: process.env.npm_package_name,
                module: 'mbp-tag-managment',
                message: `Reached maximum number of attempts. Going to stop checking. [urlPath: ${urlPath}]`,
            });
        }
    }
};

const timer = (args, test, callback, sleep, maxAttempts) => {
    const interval = setInterval(() => {
        try {
            if (test(...args)) {
                clearInterval(interval);
                callback();
            } else {
                incrementAttempts(callback, interval, maxAttempts);
            }
        } catch (e) {
            mbpLogger.logError({
                args,
                callback,
                sleep,
                maxAttempts,
                jsError: e,
                function: 'timer',
                appName: process.env.npm_package_name,
                module: 'mbp-tag-managment',
                message: 'Failure in check.',
            });

            incrementAttempts(callback, interval, maxAttempts);
        }
    }, sleep);
};

export const when = (test, callback, sleep = 100, maxAttempts) => {
    totalAttempts = 0;
    // eslint-disable-next-line no-undef
    const args = Array.prototype.slice.call(arguments, 2);

    timer(args, test, callback, sleep, maxAttempts);
};

/*
 * @description hook to find the intersection between two elements
 * @param {object} First Param
 * @return {array} Element reference and intersection object
 */
export const useIntersect = ({ root = null, rootMargin, threshold = 1 }) => {
    const [entry, updateEntry] = useState({});
    const [node, setNode] = useState(null);
    const observer = useRef(null);
    useEffect(() => {
        if (observer.current) observer.current.disconnect();
        observer.current = new window.IntersectionObserver(([entryValue]) => updateEntry(entryValue), { root, rootMargin, threshold });
        const { current: currentObserver } = observer;
        if (node) currentObserver.observe(node);
        return () => currentObserver.disconnect();
    }, [node]);
    return [setNode, entry];
};

export const setProductDemandType = (history, demandType) => {
    history.push({
        search: `demandType=${demandType}`,
    });
};

export const getProductDemandType = (location) => QueryString.parse(location.search.replace('?', ''))?.demandType;

export const getAttributeByName = (attributeList, attributeName) => {
    let attribute = [];
    if (attributeList?.length > 0) {
        attribute = attributeList.filter((attr) => attr.id === attributeName && attr.values?.[0]?.value === 'true') || [];
    }

    return attribute;
};

export const getAttributeByNameAndReturnValue = (attributeList, attributeName) => {
    let attribute = [];
    if (attributeList?.length > 0) {
        attribute = attributeList.filter((attr) => attr.id === attributeName)?.[0]?.values?.[0]?.value || 'true';
    }
    return attribute;
};

export const getCartAttributeByNameAndReturnValue = (attributeList, attributeName) => {
    let attributeValue = '';
    if (attributeList?.length > 0) {
        attributeValue = attributeList.filter((attr) => attr.attributeName === attributeName)?.[0]?.attributeValue || '';
    }

    return attributeValue;
};

export const getPersAttrValueByName = (attributeName, attributeList) => {
    let attribute = null;

    if (attributeList?.length > 0) {
        attribute = attributeList.find((attr) => attr.perAttributeId === attributeName) || [];
    }

    return attribute?.perAttributeValue || '';
};

export default {};
