/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './Recipient-ActionTypes';

export const addCartRecipient = (recipient) => ({
    type: actionTypes.ADD_RECIPIENT,
    data: recipient,
});

export const updateRecipients = (recipient) => ({
    type: actionTypes.UPDATE_RECIPIENTS,
    data: recipient,
});

export const changeWorkingRecipient = () => ({
    type: actionTypes.CHANGE_WORKING_RECIPIENT,
});

export const updateWorkingRecipient = () => ({
    type: actionTypes.UPDATE_WORKING_RECIPIENT,
});

export const setWorkingRecipient = (index) => ({
    type: actionTypes.SET_WORKING_RECIPIENT,
    data: {
        index,
    },
});

export const loadOrderItemDetails = (data) => ({
    type: actionTypes.LOAD_ORDERITEM_DETAILS,
    data,
});

// action when cart has single item and gift message is filled out and user continues to payment
export const enterpriseSaveRecipientGiftMessage = (data) => ({
    type: actionTypes.ENTERPRISE_SAVED_RECIPIENT_GIFT_MESSAGE,
    data,
});
