/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { createSelector } from 'reselect';

export const getOIErrors = (state) => state?.checkout?.error || null;

export const getOrderItemErrors = createSelector(
    [getOIErrors],
    (errors) => errors || {},
);
