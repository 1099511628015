/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const CUSTOM_BREAKPOINTS = {
    tabPortrait: 768,
    tabLandscape: 1024,
    checkout: 959,
};

export default CUSTOM_BREAKPOINTS;
