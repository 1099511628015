/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

export const updateIdentifiersEnterpriseIdRestCall = async (jwt, identifiersArray, enterpriseId) => {
    const wcEnv = { rootUri: '/' };
    const resourcePage = `userEvents/enterpriseId/${enterpriseId}`;
    const response = await restClient.patchFacade(wcEnv, resourcePage, jwt, identifiersArray);
    delete response.headers;
    delete response.request;
    delete response.config;

    return {
        enterpriseId: response?.data?.enterpriseId || null,
        profileAttributes: response?.data?.profileAttributes || [],
    };
};

export default {};
