/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import cardType from 'credit-card-type';

// Set Visa && Discover cards to 16 digit length

cardType.updateCard(cardType.types.VISA, {
    lengths: [16],
});

cardType.updateCard(cardType.types.DISCOVER, {
    lengths: [16],
});

export default cardType;
