/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    number,
    bool,
    object,
    string,
    func,
} from 'prop-types';
import mbpLogger from 'mbp-logger';
import EmailOptInContainer from './EmailOptInContainer';
import useSalesforcePersonalizedContentQuery from '../../helpers/Personalization/useSalesforcePersonalizedContentQuery';
import { getBrand } from '../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getIsAuthenticatedStatus } from '../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getCurrentPageView } from '../../../state/ducks/TagManager/ducks/TagManager/TagManager-Selectors';
import { getCustomerEmail } from '../../pages/Account/state/ducks/Common/Common-Selectors';
import { resetEmailOptInSFResponse } from '../../../state/ducks/App/App-Actions';
import { getEmailSubscriptionStatus, getEmailOptInSFResponse, getEmailOptInRefetchTimestamp } from '../../../state/ducks/App/App-Selectors';
import { fetchEmailSubscribeStatus } from '../../../apis/account-apis/fetchEmailSubscribeStatus';

const EmailOptInBuilder = ({ page, brand, showModalAfterMS }) => {
    const brandName = brand?.domain || '1800flowers';
    const brandId = brand?.code || '18F';
    const interactionName = `${brandName} - Get Campaign - Email Optin`;

    const targeting = {
        pageType: page?.pageType || 'home',
    };

    const {
        loading, error, data, variables,
    } = useSalesforcePersonalizedContentQuery({
        queryName: 'PersonalizedContent',
        interactionName,
        targeting,
        optInHoldoutParticipation: true,
        resolveIdentity: true,
    });

    if (loading || variables?.skip) {
        return null;
    }

    if (error) {
        mbpLogger.logError({
            function: 'EmailOptInQueryContainer.js',
            message: 'Error loading data from PersonalizedContent API',
            appName: process.env.npm_package_name,
            module: 'Email Opt In',
            jsError: error,
        });
    }

    if (data?.contentSF) {
        const emailOptInData = data?.contentSF?.campaign?.campaignResponses?.[0];
        if (emailOptInData) {
            const blockData = { ...emailOptInData?.payload };
            return (
                <EmailOptInContainer
                    emailOptInData={blockData}
                    showModalAfterMS={showModalAfterMS}
                    salesforceResponse={data?.contentSF}
                    brandId={brandId}
                    page={page}
                    isSubscribed={false}
                />
            );
        }
    }
    return null;
};

EmailOptInBuilder.propTypes = {
    brand: object.isRequired,
    page: object.isRequired,
    showModalAfterMS: number.isRequired,
};

const EmailOptInQueryContainer = ({
    brand,
    showModalAfterMS,
    isUserAuthenticated,
    page,
    currentLoggedInEmailId,
    emailOptInSFResponse,
    emailOptInDetails,
    emailOptInRefetchTimestamp,
    resetEmailOptInData,
}) => {
    const brandId = brand?.code || '18F';
    const { isSubscribed = false } = emailOptInDetails;
    const [isLoggedInUserSubscribed, setIsUserSubscribedToMarketingEmails] = useState(false);
    const currentTime = new Date().getTime();
    const sessionIntervalForFetch = 3600; // 1 Hr
    // for fetching updated salesforce api response after specified interval
    const isExpired = Math.round((currentTime - emailOptInRefetchTimestamp) / 1000) > sessionIntervalForFetch;
    const isOptInModalShownToUser = /^true$/i.test(window?.sessionStorage?.getItem('isOptInModalShown'));

    useEffect(() => {
        if (isUserAuthenticated && !isSubscribed && !isLoggedInUserSubscribed && !isOptInModalShownToUser) {
            // dont show modal if logged In user already opt in for marketing emails.
            fetchEmailSubscribeStatus({ brandId, email: currentLoggedInEmailId }).then((response) => {
                const { isUserSubscribedToMarketingEmails } = response?.data;
                setIsUserSubscribedToMarketingEmails(isUserSubscribedToMarketingEmails);
            });
        }
        if (isExpired && !isSubscribed && !isOptInModalShownToUser) resetEmailOptInData();
    }, []);

    if (!page?.pageName || !page?.pageType || isOptInModalShownToUser) return null;

    // prevent rendering if loggedIn/guest user already subscribed to marketing email
    if (isSubscribed && !Object.entries(emailOptInSFResponse).length) return null;

    if (isUserAuthenticated && isLoggedInUserSubscribed) return null;

    if ((!isExpired || isSubscribed) && Object.entries(emailOptInSFResponse).length) {
        const emailOptInData = emailOptInSFResponse?.campaign?.campaignResponses?.[0];
        if (emailOptInData) {
            const blockData = { ...emailOptInData?.payload };
            return (
                <EmailOptInContainer
                    emailOptInData={blockData}
                    showModalAfterMS={0}
                    salesforceResponse={emailOptInSFResponse}
                    brandId={brandId}
                    page={page}
                    isSubscribed={isSubscribed}
                />
            );
        }
    } else {
        return (
            <EmailOptInBuilder
                showModalAfterMS={showModalAfterMS}
                brand={brand}
                page={page}
            />
        );
    }
    return null;
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
    isUserAuthenticated: getIsAuthenticatedStatus(state),
    page: getCurrentPageView(state),
    currentLoggedInEmailId: getCustomerEmail(state),
    emailOptInSFResponse: getEmailOptInSFResponse(state),
    emailOptInDetails: getEmailSubscriptionStatus(state),
    emailOptInRefetchTimestamp: getEmailOptInRefetchTimestamp(state),
});

const mapDispatchToProps = (dispatch) => ({
    resetEmailOptInData: bindActionCreators(resetEmailOptInSFResponse, dispatch),
});

EmailOptInQueryContainer.propTypes = {
    brand: object,
    isUserAuthenticated: bool,
    showModalAfterMS: number,
    page: object,
    currentLoggedInEmailId: string,
    emailOptInSFResponse: object,
    emailOptInDetails: object,
    emailOptInRefetchTimestamp: number.isRequired,
    resetEmailOptInData: func.isRequired,
};

EmailOptInQueryContainer.defaultProps = {
    brand: {},
    isUserAuthenticated: false,
    showModalAfterMS: 0,
    page: {},
    currentLoggedInEmailId: '',
    emailOptInSFResponse: {},
    emailOptInDetails: {},
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EmailOptInQueryContainer);
