/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const DELETE_ITEM = 'DELETE_ITEM';
const HANDLE_DELETE_ITEM_FOOD = 'HANDLE_DELETE_ITEM_FOOD';
const ENTERPRISE_DELETE_ITEM = 'ENTERPRISE_DELETE_ITEM';
const HANDLE_MINI_CART_DELETE_ITEM = 'HANDLE_MINI_CART_DELETE_ITEM_FOOD';

export {
    DELETE_ITEM, HANDLE_DELETE_ITEM_FOOD, ENTERPRISE_DELETE_ITEM, HANDLE_MINI_CART_DELETE_ITEM,
};

export default {};
