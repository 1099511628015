/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const RESOURCE_ROOT_FACADE = '/checkout';
const RESOURCE_PAGE_FACADE = '/cart';
const RESOURCE_PAGE_ACTION = '/rewards/eligibility';

const rewardElegilibity = (
    wcEnv, jwtToken, orderId, creditCard,
    isCardTokenized, sourceApp,
) => {
    const payload = {
        orderId,
        creditCard,
        isCardTokenized,
        sourceApp,
    };

    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE;
    const resourcePage = RESOURCE_PAGE_FACADE + RESOURCE_PAGE_ACTION;

    return restClient.postFacade(env, resourcePage, jwtToken, payload)
        .then((response) => response);
};

export default rewardElegilibity;
