/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core';
import chrome from './Images/chrome.png';
import edge from './Images/edge.png';
import firefox from './Images/firefox.png';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
};

const useStyles = makeStyles({
    modalContainer: {
        backgroundColor: 'white',
    },
    typography: {
        marginBottom: '10px',
        fontWeight: 'bold',
    },
    modalBox: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginTop: '20px',
    },
    aTag: {
        display: 'block',
        textDecoration: 'none',
    },
    iconImage: {
        height: '60px',
        width: '60px',
    },
    iconTitle: {
        color: 'black',
        margin: '0 0 0 0',
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: 'lightgrey',
        marginTop: '30px',
    },
});

const InternetExplorerWarningModal = () => {
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };

    const classes = useStyles();

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.modalContainer}

        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h3" className={classes.typography}>
                    We&apos;re sorry but this browser is no longer supported.
                </Typography>
                <Typography id="modal-modal-description">
                    You will experience limited functionality and unusable pages with this browser. We recommend upgrading to the latest version of one of the following browsers:
                </Typography>
                <div className={classes.modalBox}>
                    <a href="https://www.google.com/chrome/" className={classes.aTag}>
                        <img src={chrome} alt="chrome" className={classes.iconImage} />
                        <p className={classes.iconTitle}>Chrome</p>
                    </a>
                    <a href="https://www.microsoft.com/en-us/edge" className={classes.aTag}>
                        <img src={edge} alt="edge" className={classes.iconImage} />
                        <p className={classes.iconTitle}>Edge</p>
                    </a>
                    <a href="https://www.mozilla.org/en-US/firefox/new/" className={classes.aTag}>
                        <img src={firefox} alt="firefox" className={classes.iconImage} />
                        <p className={classes.iconTitle}>Firefox</p>
                    </a>
                </div>
                <Button className={classes.button} onClick={handleClose}>
                    I understand, take me to the site anyways
                </Button>
            </Box>
        </Modal>
    );
};

export default InternetExplorerWarningModal;
