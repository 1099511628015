/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// eslint-disable-next-line import/no-unresolved
import sciActionTypes from './SCI-Actiontypes';

export const getSCIData = (mId, referrer, utm_term, history, bannerCodeData, locNumber) => ({
    type: sciActionTypes.GET_SCI_DATA,
    payload: {
        mId,
        referrer,
        utm_term,
        history,
        bannerCodeData,
        locNumber,
    },
});

export const setSCICampaign = (sci_campaign_id) => ({
    type: sciActionTypes.SET_SCI_CAMPAIGNID,
    payload: sci_campaign_id,
});

export const setSCILocNumber = (sci_loc_number) => ({
    type: sciActionTypes.SET_SCI_LOCNUMBER,
    payload: sci_loc_number,
});

export const setSCIDispositionType = (sci_disposition_type) => ({
    type: sciActionTypes.SET_SCI_DISPOSITIONTYPE,
    payload: sci_disposition_type,
});

export const setSCILoadingState = (boolean) => ({
    type: sciActionTypes.SET_SCI_LOADING_STATE,
    payload: boolean,
});

export const setSCIDecedent = (sci_decedent_data) => ({
    type: sciActionTypes.SET_SCI_DECEDENTDATA,
    payload: sci_decedent_data,
});

export const clearSCICampaign = () => ({
    type: sciActionTypes.CLEAR_SCI_CAMPAIGNID,
});

export const clearSCILocNumber = () => ({
    type: sciActionTypes.CLEAR_SCI_LOCNUMBER,
});

export const clearSCIDispositionType = () => ({
    type: sciActionTypes.CLEAR_SCI_DISPOSITIONTYPE,
});

export default {};
