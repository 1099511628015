/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable */ 
export const shopRunner = (srPartnerCode) => {
    if (typeof window !== 'undefined') {
        window._shoprunner_com = {};
        window.sr_pageRefreshes = false;
        var sr_partnerCode = srPartnerCode;
        var shrEnvId = 2;
        _shoprunner_com.version = 3;
        _shoprunner_com.enabled = true;
        sr_pageRefreshes = true;
        if (sr_partnerCode !== null && sr_partnerCode !== "")
        _shoprunner_com.retailerID = sr_partnerCode;
        // _shoprunner_com.loginValidationURL = "/ShopRunnerFormView?storeId=" + storeId;
        // _shoprunner_com.loginValidationURL = "https://fast-api-staging.800-flowers.net/r/api/checkout/shoprunner/validate?brand=harryanddavid";
        _shoprunner_com.environmentID = shrEnvId;
        _shoprunner_com.checkout = {};
        _shoprunner_com.checkout.enabled = false;
        _shoprunner_com.checkout.partnerAPIEndPoint = "";
        if (_shoprunner_com.enabled) {
            _shoprunner_com.prefix = window.parent.document.location.protocol + "//";
            _shoprunner_com.sr_jsContentURL = _shoprunner_com.prefix + "staging-content.shoprunner.com";
            if (_shoprunner_com.environmentID == 2)
                _shoprunner_com.sr_jsContentURL = _shoprunner_com.prefix + "content.shoprunner.com";
            var sr_CSS_URL = _shoprunner_com.sr_jsContentURL + "/" + _shoprunner_com.retailerID + ".css";
            var sr_js_content_el_URL = _shoprunner_com.sr_jsContentURL + "/" + _shoprunner_com.retailerID + ".js";
            setTimeout(function() {
                if (!document.querySelector("#shoprunner-css")) {
                    var a = document.createElement("link");
                    a.href = sr_CSS_URL;
                    a.type = "text/css";
                    a.rel = "stylesheet";
                    a.id = "shoprunner-css";
                    document.getElementsByTagName("head")[0].appendChild(a);
                }
                if (!document.querySelector("#shoprunner-js")) {
                    var b = document.createElement("script");
                    b.src = sr_js_content_el_URL;
                    b.type = "text/javascript";
                    b.id = "shoprunner-js";
                    document.getElementsByTagName("head")[0].appendChild(b);
                }
            }, 1);
        }
        _shoprunner_com.docReady = false;
        _shoprunner_com.dom_loaded = function() {
            _shoprunner_com.docReady = true;
            if (typeof sr_$ !== "undefined") {
                sr_$.run();
            }
        };
        if (document.addEventListener)
            document.addEventListener("DOMContentLoaded", _shoprunner_com.dom_loaded, false);
        else if (document.attachEvent)
            document.attachEvent("onreadystatechange", _shoprunner_com.dom_loaded);
        if (window.addEventListener)
            window.addEventListener("load", _shoprunner_com.dom_loaded, false);
        else if (window.attachEvent)
            var r = window.attachEvent("onload", _shoprunner_com.dom_loaded)
    }
}
