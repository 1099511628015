/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useTracking } from 'react-tracking';

export const handleDuplicateDataLayerEntry = (data) => {
    const dataLayer = (window.mbpDataLayer) ? window.mbpDataLayer : null;
    let duplicate = false;

    if (dataLayer) {
        dataLayer.forEach((entry) => {
            if (entry.action === data.action) { // Simple Comparison
                duplicate = true;
            }
        });
    }

    if (duplicate) {
        const tempDataLayer = window.mbpDataLayer.filter((entry) => (entry.action !== data.action));

        // Re-set data layer
        window.mbpDataLayer = tempDataLayer;
    }
};

export const buildComponentList = (componentsList, type, category = '') => {
    const components = [];
    componentsList.forEach((component, index) => {
        components.push({
            id: component.id,
            baseCode: component.id,
            partNumber: component.productPartNumber || component.id,
            name: component.name,
            list: type,
            category,
            variant: component.id,
            price: component.price || '',
            position: index + 1,
        });
    });
    return components;
};

export const validateDataLayer = (data) => {
    const { page } = data;
    let newPage = false;

    // Reset
    if (window.mbpDataLayer
        && (page && page.path)
    ) {
        window.mbpDataLayer.forEach((entry) => {
            if (entry.page && entry.page.path !== page.path) {
                newPage = true;
            }
        });

        if (newPage) {
            window.mbpDataLayer = [];
        }
    } else {
        // Init data layer object (GTM)
        window.dataLayer = window.dataLayer || [];
    }

    // Duplicates
    handleDuplicateDataLayerEntry(data);
};

export const updateDataLayer = (data) => {
    // Init data layer object (MBP)
    (window.mbpDataLayer = window.mbpDataLayer || []).push(data);
};

export const trackEvent = (data) => {
    const tracking = useTracking();

    tracking.trackEvent(data);
};

export const getPageDataFromLocation = (props) => {
    const { name, type, accountPage = null } = props.pageProps;

    const pageProps =  {
        name,
        type,
        path: props.location?.pathname,
    };

    if (accountPage) {
        pageProps.account = true;
    }
    return pageProps;
};

export const getProductListData = (params) => {
    const {
        buildProductDataHandler,
    } = params;
    if (buildProductDataHandler) {
        const {
            data,
            path,
            giftBox,
            localFlorist,
        } = params;
        const { productData } = buildProductDataHandler({
            data,
            path,
            giftBox,
            localFlorist,
            icBannerBlock: {},
        });

        return productData;
    }

    return [];
};

// export const trackSearchProductListEventHandler = (props) => {
//     const {
//         products,
//     } = props;

//     return { products };
// };

export const trackPageHandler = (pageProps) => (props) => {
    const {
        page, location,
        categoryData, searchTerm, searchInputTerm,
        pageData,
    } = props;
    // Category
    if (categoryData
        && (
            (page && page.type === 'category') // TODO: popuplate page prop for category pages
            || (pageProps && pageProps.type === 'category') // Current - Manual
        )
    ) {
        const categoryOccasion = categoryData?.content?.entries?.[0]?.category_occasion?.[0];
        const { category } = categoryData;
        const redirectForCategory = page?.search && page.search.includes('redirect-for-category');
        return { category: { categoryOccasion, ...category }, redirectForCategory, categoryOccasion };
    }

    // Search
    if ((page && page.type === 'search') // TODO: popuplate page prop for category pages
        || (pageProps && pageProps.type === 'search') // Current - Manual
    ) {
        if (location) {
            return {
                searchTerm,
                searchInputTerm,
                totalSearchProducts: pageData?.totalProducts || 0,
                page: getPageDataFromLocation({
                    location,
                    pageProps,
                }) || null,
            };
        }
    }

    // Product
    if ((page && page.type === 'product') // TODO: popuplate page prop for category pages
        || (pageProps && pageProps.type === 'product') // Current - Manual
    ) {
        if (location) {
            const redirectForSku = page?.search && page.search.includes('redirect-for-sku');
            return {
                redirectForSku,
                page: getPageDataFromLocation({
                    location,
                    pageProps,
                }) || null,
            };
        }
    }

    // My Account
    if ((page && page.accountPage)
        || (pageProps && pageProps.accountPage)
    ) {
        return {
            page: getPageDataFromLocation({ location, pageProps }),
        };
    }

    // Default
    // No page prop, construct page object from location props and type param
    if (!page && (location && pageProps)) {
        return {
            page: getPageDataFromLocation({ location, pageProps }),
        };
    }

    return { page };
};

export const trackPassportData = (props = {}) => {
    const { bannerOnPage, passportData } = props;
    return {
        passportData: { page: bannerOnPage, ...passportData },
        action: 'track_passport_data',
    };
};

export const trackNBCUData = (props = {}) => {
    const { modalData } = props;
    return {
        modalData: { modalData },
        action: 'track_NBCU_data',
    };
};

export const trackOrderDataHandler = (props = {}) => {
    const { order, externalCart = {} } = props;
    const EVENT_ACTION = 'track_order_data';

    // The rest...
    if (order) {
        return {
            externalCart,
            order,
            action: EVENT_ACTION,
        };
    }

    return null;
};
