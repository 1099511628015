/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

export const EnterpriseIdPayloadBody = (eventType, metaData) => ({
    eventType,
    metaData,
});

export const EnterpriseIdNewPayloadBodyType = (eventType, brand, enterpriseId,  metaData) => ({
    eventType,
    brand,
    enterpriseId,
    metaData,
});

export const createEnterpriseIdRestCall = async (jwt, data) => {
    const wcEnv = { rootUri: '/' };
    const resourcePage = 'userEvents/enterpriseId';
    const response = await restClient.postFacade(wcEnv, resourcePage, jwt, data);
    delete response.headers;
    delete response.request;
    delete response.config;

    return  {
        enterpriseId: response?.data?.enterpriseId || null,
        profileAttributes: response?.data?.profileAttributes || [],

    };
};

export default {};
