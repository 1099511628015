/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const brands = {
    1029: 'Shari\'s Berries',
    1010: 'Fruit Bouquets',
    1020: 'Wolferman\'s',
    1001: '1-800-Flowers',
    1019: 'Harry & David',
    1009: '1-800-Baskets',
    1025: 'Simply Chocolate',
    1012: 'Cheryl\'s Cookies',
    1022: 'Personalization Universe',
    1002: 'The Popcorn Factory',
    1018: 'Stock Yards',
    1030: 'Personalization Mall',
    1031: 'Vital Choice',
    1027: 'The Market',
    1034: 'Florists',
    1033: 'Things Remembered',
};

export const brandCode = {
    1029: 'bry',
    1010: 'fbq',
    1020: 'wlf',
    1001: '18f',
    1019: 'hd',
    1009: '18b',
    1025: 'sch',
    1012: 'cco',
    1022: 'pzu',
    1002: 'tpf',
    1018: 'sy',
    1030: 'pma',
    1031: 'vc',
    1027: 'tmp',
    1034: 'fl',
    1033: 'tr',
};

export const getSisterBrandName = (productBrand, currentBrand) => {
    /**
     * Not showing The Market as a sister brand.
     */
    if (productBrand === '1027') {
        return null;
    }
    if (!productBrand || !currentBrand) {
        return null;
    }
    if (productBrand !== currentBrand && brands[productBrand]) {
        return brands[productBrand];
    }
    return null;
};

export const getSisterBrandCode = (productBrand) => {
    if (productBrand) {
        return brandCode[productBrand];
    }
    return null;
};

export const getSkuWithoutBrand = (skuId = '') => {
    const splitSku = skuId?.split?.('-');
    return splitSku?.[splitSku?.length - 1] || '';
};

// reachable by Assistive Tech (screen readers etc.) but does not show visually
export const hideLabelWithAssistiveTech = () => {
    const hideLabel = {};
    hideLabel.clip = 'rect(0 0 0 0)';
    hideLabel.height = '1px';
    hideLabel.overflow = 'hidden';
    hideLabel.position = 'absolute';
    hideLabel.width = '1px';

    return hideLabel;
};

// use for the callback setTimeout for testcases
export const callBackSetTimeout = (callback) => {
    setTimeout(() => {
        if (callback) callback();
    }, 1000);
};

export const sliderFocusOnTouch = (containerRef) => {
    // Smoothing out scroll behavior
    let firstClientX;
    let clientX;

    // eslint-disable-next-line consistent-return
    const preventTouch = (e) => {
        const minValue = 5;
        clientX = e.touches[0].clientX - firstClientX;
        // Vertical scrolling does not work when you start swiping horizontally.
        if (Math.abs(clientX) > minValue) {
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    };

    const touchStart = (e) => {
        firstClientX = e.touches[0].clientX;
    };
    if (containerRef.current) {
        containerRef.current.addEventListener('touchstart', touchStart);
        containerRef.current.addEventListener('touchmove', preventTouch, {
            passive: false,
        });
    }
};

// match css value between "font-family:" and ";"
export const parseFont = (font) => font?.match(/:\s(.*?);/, '')?.[1] || null;

// Return class based on product show per row number
export const getProductPerRow = (classes, rowNumber) => {
    switch (rowNumber) {
        case 5:
            return classes.fiveProductsInRow;
        case 4:
            return classes.fourProductsInRow;
        case 3:
            return classes.threeProductsInRow;
        default:
            return classes.twoProductsInRow;
    }
};

export const generateDomId = (_str = '') => {
    if (typeof _str === 'string') {
        const id = _str.replace?.(/[^0-9a-z]/gi, ''); // allowing number and string only
        return id;
    }
    return '';
};

export const htmlToText = (html) => {
    if (typeof document === 'undefined') return '';
    const tempDivElement = document.createElement('div');  // Create a new div element
    tempDivElement.innerHTML = html;  // Set the HTML content with the given value
    return tempDivElement.textContent || tempDivElement.innerText || ''; // Retrieve the text property of the element
};
export const nodeToString = (node) => {
    if (node?.type === 'text') {
        return node?.data;
    }
    if (node?.type === 'tag') {
        const childrenString = node?.children?.map(nodeToString)?.join('');
        // eslint-disable-next-line prefer-template
        return `<${node?.name}${node?.attribs ? ' ' + Object.entries(node?.attribs).map(([key, value]) => `${key}="${value}"`).join(' ') : ''}>${childrenString}</${node?.name}>`;
    }
    return '';
};
export const cartStatusCompleteList = ['published', 'placed', 'paid', 'publishfailed', 'testcart'];

export default {};
