/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import mbpLogger from 'mbp-logger';
import {
    shape, string, object, number,
} from 'prop-types';
import {
    makeStyles, SvgIcon, IconButton,
} from '@material-ui/core';
import {
    KeyboardArrowUp,
} from '@material-ui/icons';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import MultiBrandChatController from './MultiBrandChatController';
import { getFeatureFlag, getFeatureFlags } from '../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getCurrentPageType } from '../../../state/ducks/TagManager/ducks/TagManager/TagManager-Selectors';
import {
    getAccessToken, getBrand,
} from '../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getCartOrder, getTotalItemInCart } from '../../../state/ducks/Checkout/ducks/Common/Common-Selectors';
import { USE_CASES, MAX_INVALID_PROMO_CODES_ATTEMPTS, SUGGEST_PROMO_CODE_USE_CASE } from './constants/useCases';
import findSkuByPartNumbers from '../../gql/queries/findSkuByPartNumbers';
import { unlockAudio } from './utils/audioHelper';
import { externalTabSendConversation } from './utils/multipleTabsChat';

const useStyles = makeStyles((theme) => ({
    openButton: {
        zIndex: '9990',
        position: 'fixed',
        bottom: 80,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        border: (props) => (props.isChatIconDisplayed || props.isFeedbackButtonDisplayed || props.showTopButton ? `solid ${theme.palette.cms?.primary} 1px` : 'none'),
        backgroundColor: 'white',
        alignItems: 'center',
        width: '70px',
        borderRight: 'none',
        '& svg': {
            color: theme.palette.cms?.primary,
            fontSize: '30px',
            width: 'auto',
            display: 'block',
            margin: '0 auto',
        },
        '& span': {
            display: 'block',
            color: theme.palette.cms?.primary,
            fontSize: '1rem',
            textAlign: 'center',
        },
        '& button': {
            padding: '10px 5px',
            color: theme.palette.cms?.primary,
            cursor: 'pointer',
            fontSize: '1.2rem',
            backgroundColor: 'white',
            // border: 'none',
            borderBottom: `solid ${theme.palette.cms?.primary} 1px`,
            height: '70px',
            width: '100%',
            borderRadius: '0',
            '&:last-child': {
                borderBottom: 'none',
            },
        },
        '& path': {
            fill: theme.palette.cms?.primary,
        },
        [theme.breakpoints.down('600')]: {
            right: 20,
        },
    },
    feedBackButtonTop: {
        borderTop: 'none !important',
        backgroundColor: 'unset !important',
        height: '70px !important',
    },
    smartgiftEnabled: {
        [theme.breakpoints.down('600')]: {
            bottom: 95,
        },
    },
    textStyles: {
        fontSize: '12px !important',
        paddingTop: '8px !important',
    },
    iconButton: {
        height: '70px !important',
    },
}));

const MultiBrandChatButton = ({
    featureFlags, brand, cartOrder, token, pageType, cartCount,
}) => {
    // STATE REGION
    const [isFeedbackBtnClicked, setIsFeedbackBtnClicked] = useState(false);
    const [isChatBtnClicked, setIsChatBtnClicked] = useState(false);
    const [isUseCaseTriggered, setIsUseCaseTriggered] = useState('');
    const [showTopButton, setShowTopButton] = useState(false);
    const [isGqlQuery, setIsGqlQuery] = useState(false);
    const [mainTabConvID, setMainTabConvID] = useState('');
    const [mainTabMessages, setMainTabMessages] = useState([]);
    const [popOpenChatDetail, setPopOpenChatDetail] = useState({});
    const [categoryList, setCategoryList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [shouldOpenChatUseCase, setShouldOpenChatUseCase] = useState(false);
    const [initialCartCount] = useState(cartCount);
    const [localStorageItem, setLocalStorageItem] = useState('');
    // SELECTOR REGION
    const ffIsSmartgiftEnabled = useSelector((state) => getFeatureFlag('is-smart-gift-enabled')(state));
    const isChatIconDisplayed = useSelector((state) => getFeatureFlag('is-chat-icon-displayed')(state));
    const isFeedbackButtonDisplayed = useSelector((state) => getFeatureFlag('is-feedback-button-displayed')(state));
    const bannerCodesAttempted = useSelector((state) => state?.checkout?.payment?.promotion?.bannerCodesAttempted);

    const classes = useStyles({
        showTopButton, isFeedbackButtonDisplayed, isChatIconDisplayed,
    });
    /**
     * listens for a useCase and renders multiBrandChatController when data is received from window?.postMessage
     */
    const registerChatOpenEventListener = () => {
        window?.addEventListener('message', (event) => {
            // We don't trust the sender of the message if it's from different origin
            // Unit test will be running on 'localhost' domain
            if (!RegExp(window?.location.host).test(event.origin) && window?.location.host !== 'localhost') return;

            const { guestToken, eventType } = event?.data || {};
            const isValidUser = guestToken === window?.mbp?.env?.APP_AUTH0_GUID;
            const isChatButtonClicked = eventType === 'WebChat.open';

            // check if the event type is a use case and the property has not been passed in the init packet
            const isValidUseCase = !isUseCaseTriggered && USE_CASES.some(
                (useCase) => useCase?.name === eventType && featureFlags?.[useCase?.featureFlag],
            );
            // Fire onChatOpen() if a valid userId and eventType
            if (isValidUser && (isChatButtonClicked || isValidUseCase)) {
                const useCase = isValidUseCase ? eventType : '';

                // we will only run this if useCase is clippy promoCode, otherwise, we open chatbot
                if (useCase === SUGGEST_PROMO_CODE_USE_CASE) {
                    const fullArrayOfSkus = [];
                    // this returns an array of recipients data
                    const cartSkus = cartOrder?.recipients;
                    cartSkus?.forEach((recipient) => {
                        const arrayOfPartNumbers = recipient.cartItems?.filter((item) => item.product?.productCode);
                        if (arrayOfPartNumbers.length) {
                            arrayOfPartNumbers.forEach((partNumber) => {
                                fullArrayOfSkus.push(partNumber.product?.productCode);
                            });
                        }
                    });
                    // put data into correct format for gql query
                    const arrPartNumbers = `["${fullArrayOfSkus.join('","')}"]`;
                    // eslint-disable-next-line quotes
                    const QUERY = findSkuByPartNumbers(brand?.domain, arrPartNumbers, `isPromoRestricted `);
                    // * set the query to pass into component but do NOT open chat yet. This is handled in MBCC
                    setIsGqlQuery(QUERY);
                    setIsUseCaseTriggered(useCase);
                } else {
                    // set the useCase and open the chat
                    setIsUseCaseTriggered(useCase);
                    setIsChatBtnClicked(true);
                }
            }
        }, false);
    };

    const popOpenChatHandler = (event) => {
        if (event?.detail?.loadMoreButtonClick) {
            if (featureFlags['is-chat-proactive-sales-usecase-enabled'] && !featureFlags['is-csp-chat-widget-enabled']) {
                // * Collection page use case: have hit the load more button twice without adding to cart
                try {
                    const categoryData = JSON.parse(localStorage?.getItem('persistPageData') || {});
                    setCategoryList((prevState) => {
                        const updatedState = [...prevState, categoryData?.collection?.categoryName || 'no name given'];
                        // * if we havent hit load button twice yet AND if something HAS NOT been added to cart and chat hasnt been popped
                        if (updatedState?.length === 2 && initialCartCount >= cartCount && !isUseCaseTriggered && !isChatBtnClicked) {
                            setPopOpenChatDetail({ categoryNames: updatedState });
                            setIsUseCaseTriggered('proactive_sales_help');
                            setIsChatBtnClicked(true);
                        }
                        return updatedState;
                    });
                } catch (error) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        errorMessage: error,
                        message: `WebChat: proactive sales pop chat failure - ERROR: ${error}`,
                    });
                }
            }
        } else if (event?.detail?.didSetPersistData) {
            setLocalStorageItem(event?.detail?.didSetPersistData);
        } else if (event?.detail?.origin) {
            setPopOpenChatDetail(event.detail);
            setIsChatBtnClicked(true);
        } else {
            setIsChatBtnClicked(true);
        }
    };

    const scrollListener = () => {
        let lastScrollTop = 0;
        if (window.scrollY > (document.body.scrollHeight / 2)) setShowTopButton(true);
        else if (window.pageYOffset < lastScrollTop) setShowTopButton(false);
        lastScrollTop = window.pageYOffset <= 0 ? 0 : window.pageYOffset;
    };

    useEffect(() => {
        if (bannerCodesAttempted?.length === MAX_INVALID_PROMO_CODES_ATTEMPTS && !isUseCaseTriggered && typeof window !== 'undefined') {
            window?.postMessage({
                guestToken: window?.mbp.env.APP_AUTH0_GUID,
                eventType: SUGGEST_PROMO_CODE_USE_CASE,
            });
        }
    }, [bannerCodesAttempted]);

    // listen for changes to localStorage to check if another tab is requesting the conversationID
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const externalTabConversation = (event) => externalTabSendConversation(event, mainTabConvID, brand?.code, mainTabMessages, featureFlags);
            window?.addEventListener('storage', externalTabConversation, false);
            return () => {
                window?.removeEventListener('storage', externalTabConversation, false);
            };
        }
    }, [mainTabConvID, JSON.stringify(mainTabMessages)]);

    useEffect(() => {
        // Register window?.postmessage event listener
        if (token && cartOrder && Object.keys(cartOrder)?.length && Object.keys(brand)?.length && Object.keys(featureFlags)?.length) {
            if (window && typeof window !== 'undefined') registerChatOpenEventListener();
        }
    }, [token, cartOrder, brand, featureFlags]);

    useEffect(() => {
        try {
            if (pageType === 'product' && featureFlags['is-chat-proactive-sales-usecase-enabled'] && !featureFlags['is-csp-chat-widget-enabled']) {
                // * SET -> PDP use case: have gone to two PDPs and have navigated away from the second PDP without adding to cart.
                const productData = JSON.parse(localStorage?.getItem('persistPageData') || {});
                setProductList((prevState) => {
                    if (prevState?.find((item) => (item.productName === productData?.product?.name || item.productSku === productData?.product?.partNumber))) return prevState;
                    let updatedState = [...prevState];
                    if (productData?.product?.name || productData?.product?.partNumber) {
                        updatedState = [...prevState, { productName: productData?.product?.name, productSku: productData?.product?.partNumber }];
                    }
                    // * if we havent landed on 2 PDPs yet OR if something HAS been added to cart
                    if (updatedState.length < 2 || initialCartCount < cartCount) return updatedState;
                    // * otherwise, lets go ahead and set our data/pop chat
                    setPopOpenChatDetail({ productNames: productList.map((item) => item.productName), productSkus: productList.map((item) => item.productSku) });
                    setShouldOpenChatUseCase(true);
                    return updatedState;
                });
            }
        } catch (error) {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                errorMessage: error,
                message: `WebChat: proactive sales pop chat failure - ERROR: ${error}`,
            });
        }
    }, [window?.location?.href, pageType, localStorageItem]);

    useEffect(() => {
        if (shouldOpenChatUseCase && !isUseCaseTriggered && !isChatBtnClicked && (initialCartCount >= cartCount)) {
            setIsUseCaseTriggered('proactive_sales_help');
            setIsChatBtnClicked(true);
        }
    }, [window?.location?.href]);

    useEffect(() => {
        if (window && typeof window !== 'undefined') {
            window.addEventListener('click', unlockAudio, { once: true });
            window.addEventListener('touchstart', unlockAudio, { once: true });
            window.addEventListener('scroll', scrollListener);
            window.addEventListener('popOpenChat', popOpenChatHandler);
        }
        return () => {
            if (window && typeof window !== 'undefined') {
                window.removeEventListener('popOpenChat', popOpenChatHandler);
                window.removeEventListener('click', unlockAudio);
                window.removeEventListener('touchstart', unlockAudio);
                window.removeEventListener('scroll', scrollListener);
                window.removeEventListener('message', registerChatOpenEventListener);
            }
        };
    }, []);

    return (
        <>
            {(isFeedbackBtnClicked || isChatBtnClicked)
                && (
                    <ErrorBoundary
                        boundaryName="ChatWidget Error Boundary"
                        fallback={() => {
                            // NOTE: Will probably want to replace alert with custom pop up component to avoid issues below.
                            // Alert will block the js script, delay by 1s to allow for logging of error.
                            // Delay prevents user from refreshing the page before the error is logged.
                            // eslint-disable-next-line no-alert
                            setTimeout(() => alert('An error has occurred with the chat. Please refresh the page and try again!'), 1000);
                            return null;
                        }}
                    >
                        <MultiBrandChatController
                            isFeedbackBtnClicked={isFeedbackBtnClicked}
                            isChatBtnClicked={isChatBtnClicked}
                            isUseCaseTriggered={isUseCaseTriggered}
                            isGqlQuery={isGqlQuery}
                            mainTabConvID={mainTabConvID}
                            setMainTabConvID={setMainTabConvID}
                            setMainTabMessages={setMainTabMessages}
                            popOpenChatDetail={popOpenChatDetail}
                            setPopOpenChatDetail={setPopOpenChatDetail}
                            setIsChatBtnClicked={setIsChatBtnClicked}
                        />
                    </ErrorBoundary>
                )}
            {(!isFeedbackBtnClicked && !isChatBtnClicked)
                && (
                    <div>
                        {(
                            // if we have determined that it is safe to render dummy buttons, check the route to see if we should ignore BOTH chat and feedback. If so, do not render anything.
                            (featureFlags['chat-and-survey-page-restrictions']?.['desktop']?.['chat'] || [])?.includes(window?.location?.pathname || 'noPathGiven')
                            && (featureFlags['chat-and-survey-page-restrictions']?.['desktop']?.['feedback'] || [])?.includes(window?.location?.pathname || 'noPathGiven')
                        )
                            ? null : (
                                <div className={` ${ffIsSmartgiftEnabled ? classes.smartgiftEnabled : ''} ${classes.openButton} `}>
                                    {/* Top Button */}
                                    {showTopButton && (
                                        <IconButton
                                            aria-hidden="true"
                                            tabIndex="-1"
                                            onClick={() => { window?.scrollTo(0, 0); setShowTopButton(!showTopButton); }}
                                            className={classes.iconButton}
                                        >
                                            <KeyboardArrowUp style={{
                                                position: 'relative', bottom: '0px', padding: '0px', margin: '0px, 0px, 0px, 15px',
                                            }}
                                            />
                                            <span className={classes.textStyles}>Top</span>
                                        </IconButton>
                                    )}
                                    {/* Feedback Button */}
                                    {/* // shape = { desktop: {feedback: [''], chat:['']}, mobile: {feedback: [''], chat: ['']} } */}
                                    {(!(featureFlags['chat-and-survey-page-restrictions']?.['desktop']?.['feedback'] || [])?.includes(window?.location?.pathname || 'noPathGiven')
                                        && isFeedbackButtonDisplayed)
                                        && (
                                            <IconButton
                                                aria-hidden="true"
                                                tabIndex="-1"
                                                // eslint-disable-next-line
                                                className={`${!showTopButton ? classes.feedBackButtonTop : ''} `}
                                                onClick={() => { setIsFeedbackBtnClicked(true); }}
                                            >
                                                <span>[+]</span>
                                                <span className={`${classes.textStyles} `}>Feedback</span>
                                            </IconButton>
                                        )}
                                    {/* Chat Button */}
                                    {/* // shape = { desktop: {feedback: [''], chat:['']}, mobile: {feedback: [''], chat: ['']} } */}
                                    {(!(featureFlags['chat-and-survey-page-restrictions']?.['desktop']?.['chat'] || [])?.includes(window?.location?.pathname || 'noPathGiven')
                                        && isChatIconDisplayed)
                                        && (
                                            <IconButton
                                                aria-hidden="true"
                                                tabIndex="-1"
                                                data-testid="open-chat-btn"
                                                onClick={() => {
                                                    if ((pageType === 'product' || pageType === 'category') && featureFlags['is-chat-proactive-sales-usecase-enabled'] && !featureFlags['is-csp-chat-widget-enabled']) {
                                                        const productData = JSON.parse(localStorage?.getItem('persistPageData') || {});
                                                        if (productData?.product?.partNumber && productData?.product?.name) {
                                                            setProductList((prevState) => [...prevState, { productName: productData?.product?.name || 'no name given', productSku: productData?.product?.partNumber || 'no part number given' }]);
                                                        } else if (productData?.collection?.categoryName) setCategoryList([...categoryList, productData?.collection?.categoryName]);
                                                        setPopOpenChatDetail({ productNames: productList.map((item) => item.productName), productSkus: productList.map((item) => item.productSku), categoryNames: categoryList });
                                                        setIsUseCaseTriggered('bumblebee');
                                                    }
                                                    setIsChatBtnClicked(true);
                                                }}
                                                className={`${classes.iconButton} `}
                                            >
                                                <SvgIcon>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 48.23 41.81" fill="#fff">
                                                        <path
                                                            d="M17.83 40.78c5.75-.11 9.34-2.94 11.45-7.91.49-1.15 2.13-.61 3.25-.79 8.34-1.3 14.08-8 13.52-15.88-.56-8-6.93-13.83-15.44-14-4-.09-8-.05-12 0C9.51 2.25 3 7.62 2.21 15.73s4.7 14.66 13.53 16.35c.8.15 1.69 0 2.4 1-1.42 2.14-3.52 1.09-5.15.69a17.47 17.47 0 0 1-12.87-19C1 7 8.43.3 16.59.09q7.5-.18 15 0c8.25.21 15.63 6.85 16.53 14.69 1 8.65-4.41 17-12.85 18.91a10 10 0 0 0-7 4.52c-2.42 3.71-5.87 4.52-10.44 2.57zM36 13.72H12.24v-1.06H36zm0 8.09H12.26v-1.06H36z"
                                                        />
                                                    </svg>
                                                </SvgIcon>
                                                <span className={classes.textStyles}>Chat</span>
                                            </IconButton>
                                        )}

                                </div>
                            )}
                    </div>
                )}
        </>
    );
};

MultiBrandChatButton.propTypes = {
    cartCount: number.isRequired,
    pageType: string.isRequired,
    featureFlags: object.isRequired,
    brand: shape({
        'domain-name': string,
    }).isRequired,
    token: string.isRequired,
    cartOrder: object.isRequired,
};

const mapStateToProps = (state) => ({
    pageType: getCurrentPageType(state),
    featureFlags: getFeatureFlags(state),
    brand: getBrand(state),
    cartCount: getTotalItemInCart(state),
    cartOrder: getCartOrder(state),
    token: getAccessToken(state),
});

export default connect(mapStateToProps)(MultiBrandChatButton);
