/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const updateSubscriptionApi = async (wcEnv, jwtToken, payload) => {
    const { ruleId, updateType } = payload;
    const resourceUrl = 'retention-subscription/passport/subscription';
    let resourcePage = '';
    let body = {};
    switch (updateType) {
        case 'skip':
            resourcePage = `${resourceUrl}/skip-shipment`;
            body = { ruleId };
            break;
        case 'pause':
            resourcePage = `${resourceUrl}/pause`;
            body = { ruleId, pauseFlag: true };
            break;
        case 'resume':
            resourcePage = `${resourceUrl}/pause`;
            body = { ruleId, pauseFlag: false };
            break;
        default:
            resourcePage = `${resourceUrl}/skip-shipment`;
            body = { ruleId };
    }

    return restClient.putFacade(wcEnv, resourcePage, jwtToken, body)
        .then((response) => response.data);
};

export default updateSubscriptionApi;
