/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import ResponsiveImage from '../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
/* eslint-disable react/prop-types */

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'sans-serif',
        flexWrap: 'wrap',
        padding: '4rem 0',
        backgroundColor: '#f5f5f5',
        width: '100%',
    },
    maxGridContainer: {
        maxWidth: '1260px',
        alignItems: 'space-between',
        flexFlow: 'wrap',
    },
    childColumn: {
        display: 'flex',
        flexDirection: 'row',

        '& img': {
            padding: '13px',
        },
        [theme.breakpoints.down(768)]: {
            flexDirection: 'column',
        },

    },
    imageMobile: {
        objectFit: 'cover',
        width: '100%',
        height: 'auto',
        padding: '5px 27px !important',
    },
    image: {
        objectFit: 'cover',
        width: '100%',
        height: '620px',
        [theme.breakpoints.down(1260)]: {
            height: '46vw',
        },
    },

});

const PassportEventGrid = ({
    // eslint-disable-next-line no-unused-vars
    classes, isMobile, gridData,
}) => {
    const eventOneBanner = gridData?.event_one && gridData?.event_one?.banner_image?.url;
    const eventTwoBanner = gridData?.event_two && gridData?.event_two?.banner_image?.url;
    const eventThreeBanner = gridData?.event_three && gridData?.event_three?.banner_image?.url;
    const eventFourBanner = gridData?.event_four && gridData?.event_four?.banner_image?.url;

    const renderEventBanners = (bannerInfo) => {
        if (bannerInfo && bannerInfo?.link?.href) {
            return  (
                <a href={bannerInfo?.link?.href}>
                    <ResponsiveImage
                        className={!isMobile ? classes.image : classes.imageMobile}
                        path={bannerInfo?.banner_image?.url}
                        alt={bannerInfo?.link?.title}
                    />
                </a>
            );
        }
        return                     (
            <ResponsiveImage
                className={!isMobile ? classes.image : classes.imageMobile}
                path={bannerInfo?.banner_image?.url}
                alt={bannerInfo?.link?.title}
            />
        );
    };
    return (
        <Grid container className={classes.root} spacing={2} columns={2}>
            <Grid className={classes.maxGridContainer}>
                <Grid className={classes.childColumn}>
                    {eventOneBanner ? renderEventBanners(gridData?.event_one) : null }
                    {eventThreeBanner ? renderEventBanners(gridData?.event_three) : null }
                </Grid>
                <Grid  className={classes.childColumn}>
                    {eventTwoBanner ? renderEventBanners(gridData?.event_two) : null }
                    {eventFourBanner ? renderEventBanners(gridData?.event_four) : null }
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(PassportEventGrid);
