/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    ApolloLink,
    HttpLink,
} from '@apollo/client';
import mbpUtil from 'mbp-api-util';

import Cookies from 'universal-cookie';
// import getCookie from '../../helpers/common/storage/cookieStorage';

class MultiAPILink extends ApolloLink {
    constructor(config, request) {
        super(request);
        this.config = config;
        this.httpLink = new HttpLink({ ...config });
    }

    request(operation, forward) {
        const api = operation.getContext()?.api;
        const jwt = operation.getContext()?.jwt;

        // Branching
        const cookies = new Cookies();
        if (cookies.get('branch')?.length) {
            const branchValue = cookies.get('branch');
            operation.setContext({
                fetchOptions: {
                    method: 'GET',
                },
                headers: {
                    branch: branchValue,
                },
            });
        // Personalized Content Service
        } else if (api === 'personalizedContent') {
            operation.setContext({
                uri: `https://${mbpUtil.getEnv('APP_WC_HOST')}/r/api/customer-personalize/graphql?${operation.operationName}`,
                fetchOptions: {
                    method: 'POST',
                },
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            });
        // Experiments
        } else if (api === 'experiments') {
            operation.setContext({
                uri: `https://${mbpUtil.getEnv('APP_WC_HOST')}/r/api/experiments/graphql?`,
                fetchOptions: {
                    method: 'POST',
                },
                headers: {
                    // authorization: `Bearer ${jwt}`,
                },
            });
        // Aggregator
        } else {
            operation.setContext({
                fetchOptions: {
                    method: 'GET',
                },
                headers: {
                    'baggage-sessionId': cookies.get('ShopperManagerEnterprise'),
                    sv: cookies.get('sv'),
                },
            });
        }
        return this.httpLink.request(operation, forward);
    }
}

export default MultiAPILink;
