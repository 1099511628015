/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import initialState from '../../initialState';
import { removePmallItem, setPmallPersonalizationData, updatePmallImages } from './PmallPersonalization-Actions';

const pmallPersonalizationInitalState = initialState.pmallPersonalization;
const pmallPersonalizationReducer = (state = pmallPersonalizationInitalState, action) => {
    const count = state.countCacheBust ?? state?.common?.pmallPersonalization?.countCacheBust;

    switch (action.type) {
        case setPmallPersonalizationData().type:
            return {
                ...state,
                countCacheBust: (count || 0) + 1,
                customizations: action.data,
            };
        case updatePmallImages().type:
            return {
                ...state,
                countCacheBust: (count || 0) + 1,
            };
        case removePmallItem().type:
            return {
                ...state,
                customizations: {},
            };
        default:
            return state;
    }
};

export default pmallPersonalizationReducer;
