/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import initialState from '../../initialState';
import * as actionTypes from './Shoprunner-ActionTypes';

const shopRunnerReducer = (state = initialState.shopRunner, action) => {
    switch (action.type) {
        case actionTypes.LOAD_SR_TOKEN:
            return {
                ...state,
                srToken: action.data.srToken,
            };
        case actionTypes.CLEAR_SR_TOKEN:
            return {
                ...state,
                srToken: '',
            };
        default:
            return state;
    }
};

export default shopRunnerReducer;
