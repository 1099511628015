/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    object, func, number,
} from 'prop-types';
import mbpLogger from 'mbp-logger';
import EmailCaptureWidgetModal from './EmailCaptureWidgetModal';
import { emitCustomerIdentificationEvent } from '../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import { setEmailCaptureStatus } from '../../../state/ducks/App/App-Actions';
import validateEmailAddress from '../../helpers/validateEmailAddress';

class EmailCaptureWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentEmailId: '',
            onSubmitCheck: false,
            modalOpen: false,
            validEmailId: false,
            showConfirmation: false,
        };
        this.addressFieldRef = React.createRef();
    }

    componentDidMount() {
        const {
            showModalAfterMS,
        } = this.props;
        setTimeout(() => {
            this.setState({ modalOpen: true });
        }, showModalAfterMS || 10000);
    }

    componentDidCatch(jsError, reactInfo) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: `Unhandled Error in Email Capture Widget \n jsError = ${jsError} \n reactInfo = ${reactInfo}`,
        });
    }

    validateUserSubmittedEmailId = async () => {
        // this function determines when to validate a email Id that the user enters
        const { currentEmailId } = this.state;
        const emailId = currentEmailId;
        // remove the space to string at end
        const updatedEmailId = emailId.trimEnd();

        this.setState({ currentEmailId: updatedEmailId, validEmailId: validateEmailAddress(updatedEmailId), onSubmitCheck: !validateEmailAddress(updatedEmailId) });
    };

    handleEmailIdChange = (e) => {
        this.setState(
            {
                currentEmailId: e.target.value.trimStart(),
                onSubmitCheck: false,
            },
            () => { this.validateUserSubmittedEmailId(); },
        );
    };

    closeModal = () => {
        this.setState({
            modalOpen: false,
        });
    }

    handleButtonSubmit = () => {
        const { captureEmailId, updateEmailCaptureStatus } = this.props;
        const { currentEmailId } = this.state;
        const customerIdentificationPayload = {
            signInMethod: 'promo-banner-email',
            user: {
                email: currentEmailId,
            },
        };

        if (!validateEmailAddress(currentEmailId)) {
            this.setState({
                onSubmitCheck: true,
            });
        }

        if (!validateEmailAddress(currentEmailId)) return;

        captureEmailId(customerIdentificationPayload);

        this.setState({
            showConfirmation: true,
        });

        updateEmailCaptureStatus(true);
    };

    render() {
        const {
            widgetCMSData,
        } = this.props;

        const {
            onSubmitCheck,
            modalOpen,
            validEmailId,
            currentEmailId,
            showConfirmation,
        } = this.state;

        return (
            <>
                {modalOpen && (
                    <EmailCaptureWidgetModal
                        emailCaptureWidgetCMSData={widgetCMSData}
                        isOpen={modalOpen}
                        currentEmailId={currentEmailId}
                        handleButtonSubmit={this.handleButtonSubmit}
                        closeModal={this.closeModal}
                        onSubmitCheck={onSubmitCheck}
                        handleEmailIdChange={this.handleEmailIdChange}
                        validEmailId={validEmailId}
                        showConfirmation={showConfirmation}
                    />
                )}
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    captureEmailId: bindActionCreators(emitCustomerIdentificationEvent, dispatch),
    updateEmailCaptureStatus: bindActionCreators(setEmailCaptureStatus, dispatch),
});

EmailCaptureWidget.propTypes = {
    captureEmailId: func.isRequired,
    updateEmailCaptureStatus: func.isRequired,
    widgetCMSData: object.isRequired,
    showModalAfterMS: number,
};

EmailCaptureWidget.defaultProps = {
    showModalAfterMS: 0,
};

export default connect(
    null,
    mapDispatchToProps,
)(EmailCaptureWidget);
