/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
export default {
    showLoadingMessage: {
        flag: false,
        loaderId: '5',
    },
    error: {
        error: '',
        promotion: {
            promotionCode: '',
            error: '',
        },
        giftCard: '',
        cart: '',
        recipientForm: {
            zipCode: '',
            locationType: '',
            firstName: '',
            lastName: '',
            address1: '',
            city: '',
            error: '',
            state: '',
            email: '',
        },
        payment: {
            lastName: '',
            firstName: '',
            address1: '',
            email: '',
            confirmEmail: '',
            state: '',
            phone: '',
            zipCode: '',
            city: '',
            accountNumber: '',
            cvv: '',
            invalidYearAndMonth: '',
            error: '',
            month: '',
            year: '',
            nameOnCreditCard: '',
            validateBillingAddress: false,
        },
        calendar: '',
        giftMessage: '',
        dtw: '',
        passport: '',
    },
    properties: {},
    addressSearchQAS: {
        suggestion: {},
        formattedAddress: {},
        showValidatedAddressSuggestion: false,
        qasFailed: false,
    },
    pmallPersonalization: {
        countCacheBust: 0,
    },
};
