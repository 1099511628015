/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const getActiveABTests = (state) => state.ui.abTesting;
export const getActiveABTest = (testName) => (state) => state?.ui?.abTesting?.[testName] || '';
export const getFABTest = (testName) => (state) => state?.ui?.fab?.[testName] || '';

export default {};
