/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

// Cancel all of user's subscriptions
const massCancelUserSubscriptionsApi = async (wcEnv, jwtToken, payload, params, headers) => {
    const resourcePage = 'retention-subscription/passport/internal/mass-cancel-subscriptions';
    const data = [payload];

    return restClient.postFacade(wcEnv, resourcePage, jwtToken, data, params, headers, false)
        .then((response) => response.data);
};

export default massCancelUserSubscriptionsApi;
