/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const GET_ORDER_ID = 'GET_ORDER_ID';
export const LOAD_ORDER_ID = 'LOAD_ORDER_ID';
export const LOAD_EXPRESS_CHECKOUT = 'mbp-checkout/LOAD_EXPRESS_CHECKOUT';
export const CLEAR_ORDER_ID_CHECKOUT = 'mbp-checkout/CLEAR_ORDER_ID_CHECKOUT';
export const LOAD_CART_COUNT = 'mbp-checkout/LOAD_CART_COUNT';
export const FETCH_LAST_ORDER_DETAILS = 'FETCH_LAST_ORDER_DETAILS';
export const SET_LAST_ORDERED_ITEM = 'SET_LAST_ORDERED_ITEM';
export const SET_ALL_ORDERED_ITEM = 'SET_ALL_ORDERED_ITEM';
export const FETCH_ALL_ORDER_DETAILS = 'FETCH_ALL_ORDER_DETAILS';

export default {
    GET_ORDER_ID,
    LOAD_ORDER_ID,
    LOAD_EXPRESS_CHECKOUT,
    CLEAR_ORDER_ID_CHECKOUT,
    FETCH_LAST_ORDER_DETAILS,
    SET_LAST_ORDERED_ITEM,
    SET_ALL_ORDERED_ITEM,
    FETCH_ALL_ORDER_DETAILS,
};
