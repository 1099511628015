/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useQuery } from '@apollo/client';
import {
    func, object, shape, string,
} from 'prop-types';
import mbpLogger from 'mbp-logger';

import findContent from '../../../gql/queries/findContent';
import { getBrand } from '../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { setPassportABData } from '../../../../state/ducks/Passport/Passport-Action';
import { GRAPHQL_ENV } from '../../../gql';

const GraphqlABPassport = ({
    setPassportABPrice, variant, contentType, brand,
}) => {
    const query = ` \\"a_b_testing.variant\\": \\"${variant?.variant}\\"`;
    const findContentOptions = {
        brand: brand['domain-name'],
        contentType,
        typename: '__typename',
        query,
    };

    const Passport_AB_PRICE_QUERY = findContent(findContentOptions);
    const { loading, error, data } = useQuery(Passport_AB_PRICE_QUERY);

    if (loading) {
        return null;
    }

    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            query: Passport_AB_PRICE_QUERY,
            component: 'GraphqlABPassport.js',
            message: 'Error loading data from Graphql',
            env: GRAPHQL_ENV,
            error,
        });
        return null;
    }

    if (!data) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            query: Passport_AB_PRICE_QUERY,
            component: 'GraphqlABPassport.js',
            message: 'No data returned for query',
            env: GRAPHQL_ENV,
        });
    } else if (!data?.findContent?.content) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            query: Passport_AB_PRICE_QUERY,
            component: 'GraphqlABPassport.js',
            message: 'No content returned for query',
            env: GRAPHQL_ENV,
        });
    } else {
        const PassportABPrice = data?.findContent?.content?.entries?.[0] || {};

        setPassportABPrice(PassportABPrice);
    }

    return null;
};

GraphqlABPassport.propTypes = {
    setPassportABPrice: func,
    brand: shape({
        'domain-name': string,
    }),
    variant: object,
    contentType: string,
};

GraphqlABPassport.defaultProps = {
    setPassportABPrice: () => {},
    brand: {},
    variant: {},
    contentType: '',
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
});
const mapDispatchToProps = (dispatch) => ({
    setPassportABPrice: bindActionCreators(setPassportABData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GraphqlABPassport);
