/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
    mbp-account-ui redux

    Checklist for mbp-account-ui operations requiring middleware (all files in src/state/reducers):

            Declare _REQUEST action in ActionType.js
            Define request action in EventDispatch.js, including callList of middleware method(s)
            Write middleware method(s) in Services.js
            Write "Action...Success" reducer in Action.js to handle results of middleware
        and finally:
            write code that dispatches to EventDispatcher(yourAction(params)), where needed

    The gory details:

        Code dispatches to a redux action using definition from EventDispatcher.js
        This action's constant is defined in ActionType.js and ends in _REQUEST.

            Example:  FETCH_ADDRESS_BOOK_REQUEST   'mbp-account-ui/address-book/get/REQUEST'

        EventDispatcher's action includes the parameters passed, as well as callList of middleware to be invoked.

            Example:    fetchAddressBook: recipients => ({
                            type: ActionType.FETCH_ADDRESS_BOOK_REQUEST,
                            recipients,
                            callList: ['fetchAddressBook', 'fetchCountryData'],
                        })

        When dispatched, this action is picked up by the WatcherSaga which then invokes WorkerSagas to invoke the middleware.
        Only _REQUEST actions are picked up by WatcherSaga.  All other actions only picked up by Reducers.js
        A _REQUEST action may additionally be picked up by Reducer which sets state to "Busy" while middleware is running.

        The middleware methods live in state/ducks/Services.js and are methods of the Services class.
        If the middleware retrieves data (as it does in addressbook example), the middleware often stores the data in session
        storage in addition to returning the data.

        If any of the middleware methods fail, a new failure action is formed by replacing /REQUEST with /FAILURE

            Example:    mbp-account-ui/address-book/get/FAILURE         (note: no ACTION constant defined for this)

        This FAILURE action is picked up by Reducers.js which typically just sets a not-ready status in state.

        If all middleware methods succeed, a new success action is formed by replacing /REQUEST with /LOADED.
        The data for this new action is an aggregate of all data retrieved by middleware.

            Example:    type: mbp-account-ui/address-book/get/LOADED       (note: no ACTION constant defined for this)
                        recipients,
                        **addressbook and country data fields filled by middleware**

        This action is picked up by reducer which transforms the original ACTION constant by converting to Capitalize
        case and substituting "REQUEST" with "Success".  This new "Action...Success" is a method in the
        Action class defined in state/reducers/Action.js, and is then called

            Example:    FETCH_ADDRESS_BOOK_REQUEST becomes  FetchAddressBookSuccess

        The Action method then takes action based on the middleware data received.  E.g. If middleware retrieves data,
        the Action method is likely a reducer that stores this data in state.

        ------------------

        Note: Because under this scheme components sometimes do not have direct dependencies that are
              detected by React, the toggleState hack exists.  By setting toggleState to !toggleState at
              the end of an "Action...Success" method, it forces component(s) to update.

*/

import ActionType from './ActionType';
import configENV from '../../config';

const { SHOP_THE_SITE_KEY } = configENV;

const EventDispatcher = {
    // HandleCloseModalSuccess
    handleCloseModal: (closeAll = false) => ({
        type: ActionType.HANDLE_CLOSE_MODAL_REQUEST,
        closeAll,
    }),

    // HandleNavigateToPageSuccess
    handleNavigateToPage: (url, id, orderId) => ({
        type: ActionType.HANDLE_NAVIGATE_TO_PAGE_REQUEST,
        url,
        id,
        orderId,
        callList: ['fetchGiftListRecipients'],
    }),
    // HandleRemoveRedirectSuccess
    handleRemoveRedirect: () => ({
        type: ActionType.HANDLE_REMOVE_REDIRECT_REQUEST,
    }),
    // HandleAuth0LoginClickedSuccess
    handleRedirectToLogin: () => ({
        type: ActionType.HANDLE_AUTH0_LOGIN_CLICKED_REQUEST,
        callList: ['auth0Login'],
    }),
    // HandleAuth0LoginClickedSuccess
    handleCreateAccount: () => ({
        type: ActionType.HANDLE_AUTH0_LOGIN_CLICKED_REQUEST,
        register: 'Y',
        callList: ['auth0Login'],
    }),
    // HandleGiftListSubmitLinkUserSuccess
    handleGiftListSubmitLinkUser: (data) => ({
        type: ActionType.HANDLE_GIFT_LIST_SUBMIT_LINK_USER_REQUEST,
        ...data,
        callList: ['linkUserToGiftList'],
    }),
    // HandleGiftListOccasionChangeSuccess
    handleGiftListOccasionChange: (event, occasions) => ({
        type: ActionType.HANDLE_GIFT_LIST_OCCASION_CHANGE_REQUEST,
        target: event.target,
        occasions,
        occasionId: event.target.value,
    }),
    // HandleGiftListCheckoutClickedSuccess
    handleGiftListProceedToCheckout: (target, occasionId, recipients) => ({
        type: ActionType.HANDLE_GIFT_LIST_CHECKOUT_CLICKED_REQUEST,
        target,
        occasionId,
        recipients,
    }),
    // HandleGiftListChooseSomethingDifferentSuccess
    handleGiftListChooseSomethingDifferent: (recipient) => ({
        type: ActionType.HANDLE_GIFT_LIST_CHOOSE_SOMETHING_DIFFERENT_REQUEST,
        recipient,
        callList: ['removeProductFromCart'],
    }),
    // HandleGiftListRecommendationClickedSuccess
    handleGiftListRecommendationClicked: (product, recipient, comparedProducts, template) => ({
        type: ActionType.HANDLE_GIFT_LIST_RECOMMENDATION_CLICKED_REQUEST,
        trackEvent: 'GiftListRecommendationClicked',
        trackEventLabel: product.name,
        product,
        recipient,
        comparedProducts,
        template,
    }),
    // HandleGiftListAddToCartSuccess
    handleGiftListAddToCart: (productId, recipient, closeModal, template) => ({
        type: ActionType.HANDLE_GIFT_LIST_ADD_TO_CART_REQUEST,
        trackEvent: 'GiftListAddToCart',
        trackEventLabel: 'add_to_cart',
        productId,
        recipient,
        closeModal,
        template,
        callList: ['addProductToCart'],
    }),
    // HandleGiftListShopTheSiteClickedSuccess
    handleGiftListShopTheSiteClicked: (recipient, template) => ({
        type: ActionType.HANDLE_GIFT_LIST_SHOP_THE_SITE_CLICKED_REQUEST,
        recipient,
        template,
    }),
    // HandleGiftListConfirmShopTheSiteClickedSuccess
    handleConfirmGiftListShopTheSiteClicked: (recipient, url = '/', dontShowModal) => ({
        type: ActionType.HANDLE_GIFT_LIST_CONFIRM_SHOP_THE_SITE_CLICKED_REQUEST,
        trackEvent: 'ConfirmGiftListShopTheSiteClicked',
        dontShowModal,
        object: { [SHOP_THE_SITE_KEY]: recipient },
        url,
        callList: ['storeShopTheSiteConfirmationInSessionStorage'],
    }),
    // HandleGiftListEnterItemNumberClickedSuccess
    handleGiftListEnterItemNumberClicked: (recipient, template) => ({
        type: ActionType.HANDLE_GIFT_LIST_ENTER_ITEM_NUMBER_CLICKED_REQUEST,
        trackEvent: 'GiftListEnterItemNumberClicked',
        recipient,
        template,
    }),
    // HandleGiftListPaginationChangedSuccess
    handleGiftListPaginationChanged: (event, occasionId, newPage, orderId) => ({
        type: ActionType.HANDLE_GIFT_LIST_PAGINATION_CHANGED_REQUEST,
        target: event.target,
        occasionId,
        newPage,
        orderId,
        callList: ['fetchGiftListRecipientsByPage'],
    }),
    // HandleGiftListRecipientClickedSuccess
    handleGiftListRecipientClicked: (recipient) => ({
        type: ActionType.HANDLE_GIFT_LIST_RECIPIENT_CLICKED_REQUEST,
        recipient,
        callList: ['getchShopTheSiteModalState'],
    }),
    // HandleGiftListAddNewRecipientClickedSuccess
    handleGiftListAddNewRecipientClicked: (occasionId, template) => ({
        type: ActionType.HANDLE_GIFT_LIST_ADD_NEW_RECIPIENT_CLICKED_REQUEST,
        recipient: {},
        template,
        occasionId,
        callList: ['fetchCountryData'],
    }),
    handleChangeSortBy: (sortBy) => ({
        type: ActionType.HANDLE_CHANGE_SORT_BY_REQUEST,
        sortBy,
    }),
    // FetchGiftListsSuccess
    fetchGiftListsOnPageLoad: (id, orderId) => ({
        type: ActionType.FETCH_GIFT_LISTS_REQUEST,
        id,
        orderId,
        callList: ['fetchGiftListRecipients'],
    }),
    setReadyFetchingState: () => ({
        type: ActionType.SET_READY_STATE_REQUEST,
    }),
    fetchUserProfile: () => ({
        type: ActionType.FETCH_USER_PROFILE_REQUEST,
        writeAccess: false,
        callList: ['fetchUserProfile'],
    }),
    updateUserProfile: (updatedUserProfile) => ({
        type: ActionType.UPDATE_USER_PROFILE_REQUEST,
        updatedUserProfile,
        callList: ['updateUserProfile'],
    }),
    fetchAddressBook: (recipients, isAddressAddedFromCheckout) => ({
        type: ActionType.FETCH_ADDRESS_BOOK_REQUEST,
        recipients,
        isAddressAddedFromCheckout,
        callList: ['fetchAddressBook', 'fetchCountryData'],
    }),
    fetchBillingAddress: (billingAddress, wallet) => ({
        type: ActionType.FETCH_BILLING_ADDRESS_REQUEST,
        billingAddress,
        wallet,
        callList: ['fetchBillingAddress', 'fetchCountryData'],
    }),
    fetchOrdersByEmail: (featureFlags, ssrDeviceType) => ({
        type: ActionType.FETCH_ORDER_BY_EMAIL_REQUEST,
        featureFlags,
        ssrDeviceType,
        callList: ['fetchOrdersByEmail'],
    }),
    // FetchOrderDetailByOrderNumberSuccess
    fetchOrderDetails: (uuid, orderNumber, zipCode, billingZipCode, options, featureFlags, ssrDeviceType, isAuthenticatedStatus) => ({
        type: ActionType.FETCH_ORDER_DETAIL_BY_ORDER_NUMBER_REQUEST,
        uuid,
        orderNumber,
        zipCode,
        billingZipCode,
        options,
        featureFlags,
        ssrDeviceType,
        isAuthenticatedStatus,
        // `trifectaUserEmailLookup` is used as a condition to retrieve `user email` from Redux Store, accessToken,
        // sessionStorage, and localStorage respectively if the previous option is unavailable
        trifectaUserEmailLookup: true,
        callList: ['fetchOrderDetails'],
    }),
    handleProcessReplaceOrder: (data) => ({
        type: ActionType.HANDLE_PROCESS_REPLACE_ORDER_REQUEST,
        data,
        callList: ['replaceOrder'],
    }),
    handleProcessPartialRefund: (data) => ({
        type: ActionType.HANDLE_PROCESS_PARTIAL_REFUND_REQUEST,
        data,
        callList: ['partialRefund'],
    }),
    handleProcessCheckAvailability: (data) => ({
        type: ActionType.HANDLE_PROCESS_CHECK_AVAILABILITY_REQUEST,
        data,
        writeAccess: false,
        callList: ['checkProductAvailability'],
    }),
    handleClearIsAvailable: () => ({
        type: ActionType.HANDLE_CLEAR_IS_AVAILABLE_REQUEST,
    }),
    handleClearPartialRefundError: () => ({
        type: ActionType.HANDLE_CLEAR_PARTIAL_REFUND_ERROR_REQUEST,
    }),
    handleClearReplaceResponse: () => ({
        type: ActionType.HANDLE_CLEAR_REPLACE_RESPONSE_REQUEST,
    }),
    handleProcessCancelOrder: (data) => ({
        type: ActionType.HANDLE_PROCESS_CANCEL_ORDER_REQUEST,
        data,
        callList: ['cancelOrder'],
    }),
    //  handleCSIGuardRailCheck: (data) => ({
    //      type: ActionType.HANDLE_CSI_GUARDRAIL_CHECK_REQUEST,
    //      data,
    //      callList: ['CSIGuardRailCheck'],
    //  }),
    // FetchEmailPreferenceSuccess
    fetchEmailPreference: (errorModal = true) => ({
        type: ActionType.FETCH_EMAIL_PREFERENCE_REQUEST,
        errorModal,
        callList: ['fetchEmailPreference'],
    }),
    fetchEmailAddress: () => ({
        type: ActionType.FETCH_EMAIL_ADDRESS_REQUEST,
        callList: ['fetchEmailAddress'],
    }),
    // Retrieve email address from indiv_id from email
    // FetchGiftListEmailAddressFromIdSuccess
    fetchEmailAddressFromId: (indivId, accessToken) => ({
        type: ActionType.FETCH_GIFT_LIST_EMAIL_ADDRESS_FROM_ID_REQUEST,
        indivId,
        accessToken,
        callList: ['fetchEmailAddressFromId'],
    }),
    fetchSubscriptions: (payload, brand, subscriptions, featureFlags) => ({  // payload contains customer's email address as customerId and page no.
        type: ActionType.FETCH_SUBSCRIPTIONS_REQUEST,
        payload,
        brand,
        subscriptions,
        featureFlags,
        callList: ['fetchSubscriptions'],
    }),
    fetchSubscriptionByStatus: (payload, brand, subscriptions, featureFlags) => ({  // payload contains customer's email address as customerId and page no.
        type: ActionType.FETCH_SUBSCRIPTIONS_BY_STATUS_REQUEST,
        payload,
        brand,
        subscriptions,
        featureFlags,
        callList: ['fetchSubscriptionByStatus'],
    }),
    cancelSubscription: (ruleId, brand, featureFlags, agentId) => ({  // ruleId is unique identifier for subscription
        type: ActionType.HANDLE_CANCEL_SUBSCRIPTION_REQUEST,
        ruleId,
        brand,
        featureFlags,
        agentId,
        callList: ['cancelSubscription'],
    }),
    cancellationSurvey: (customerId, ruleId, productId, questionAndAnswerList) => ({  // ruleId is unique identifier for subscription
        type: ActionType.HANDLE_CANCEL_SUBSCRIPTION_REQUEST,
        ruleId,
        customerId,
        productId,
        questionAndAnswerList,
        callList: ['cancellationSurvey'],
    }),
    cancelAllUserSubscriptions: (customerId) => ({
        type: ActionType.HANDLE_MASS_CANCEL_SUBSCRIPTION_REQUEST,
        customerId,
        callList: ['cancelAllUserSubscriptions'],
    }),
    updateSubscriptionList: (payload) => ({
        type: ActionType.HANDLE_UPDATE_SUBSCRIPTION_LIST_REQUEST,
        payload,
    }),
    updateSubscription: (ruleId, brand, featureFlags, updateType) => ({  // ruleId is unique identifier for subscription
        type: ActionType.HANDLE_UPDATE_SUBSCRIPTION_REQUEST,
        ruleId,
        brand,
        featureFlags,
        updateType,
        callList: ['updateSubscription'],
    }),
    // HandlePasswordlessLoginSuccess
    handlePasswordlessLogin: () => ({
        type: ActionType.HANDLE_PASSWORDLESS_LOGIN_REQUEST,
        callList: ['auth0PasswordlessLogin'],
    }),
    // HandleGiftListSendCodeToEmailSuccess
    handleSendGiftListCodeToEmail: (email, custNbr) => ({
        type: ActionType.HANDLE_GIFT_LIST_SEND_CODE_TO_EMAIL_REQUEST,
        email,
        custNbr,
        callList: ['sendCodeToEmail'],
    }),
    // HandleGiftListSubmitVerificationCodeSuccess
    handleSubmitGiftListVerificationCode: (email, code, custNbr) => ({
        type: ActionType.HANDLE_GIFT_LIST_SUBMIT_VERIFICATION_CODE_REQUEST,
        email,
        code,
        custNbr,
        callList: ['submitGiftListVerificationCode'],
    }),
    // HandleGiftListLoginWithCustNbrSuccess
    handleGiftListLoginWithCustNbr: (custNbr, register = 'N') => ({
        type: ActionType.HANDLE_GIFT_LIST_LOGIN_WITH_CUST_NBR_REQUEST,
        custNbr,
        register,
        callList: ['giftListLoginWithCustNbr'],
    }),
    handleEditRecipientClicked: (recipient, AddressBookEntryId, AddressId) => ({
        type: ActionType.HANDLE_EDIT_RECIPIENT_CLICKED_REQUEST,
        recipient,
        AddressBookEntryId,
        AddressId,
        callList: ['fetchCountryData'],
    }),
    // HandleGiftListEditRecipientClickedSuccess
    handleGiftListEditRecipientClicked: (recipient, template, isGiftlistV2 = false) => ({
        type: ActionType.HANDLE_GIFT_LIST_EDIT_RECIPIENT_CLICKED_REQUEST,
        recipient,
        template,
        isGiftlistV2,
        callList: ['fetchCountryData'],
    }),
    handleAddNewRecipient: () => ({
        type: ActionType.HANDLE_ADD_NEW_RECIPIENT_REQUEST,
        recipient: {},
        callList: ['fetchCountryData'],
    }),
    // HandleRemoveRecipientClickedSuccess
    handleRemoveRecipientClicked: (recipient, AddressId, addressType) => ({
        type: ActionType.HANDLE_REMOVE_RECIPIENT_CLICKED_REQUEST,
        recipient,
        AddressId,
        addressType,
    }),
    // HandleSubmitRemoveRecipientSuccess
    handleSubmitRemoveRecipient: (
        AddressBookEntryId,
        AddressId,
        recipient,
        addressType,
        currentPage,
        recipientsByPage,
    ) => ({
        type: ActionType.HANDLE_SUBMIT_REMOVE_RECIPIENT_REQUEST,
        AddressBookEntryId,
        AddressId,
        recipient,
        addressType,
        currentPage,
        recipientsByPage,
        callList: ['removeGiftListOrAddressBookRecipient'],
    }),
    handleAccountSignIn: (pageData) => ({
        type: ActionType.HANDLE_ACCOUNT_SIGN_IN_REQUEST,
        pageData,
    }),
    handleSubmitResetPassword: (pageData) => ({
        type: ActionType.HANDLE_SUBMIT_RESET_PASSWORD_REQUEST,
        pageData,
    }),
    handleSubmitCreateAccount: (pageData) => ({
        type: ActionType.HANDLE_SUBMIT_CREATE_ACCOUNT_REQUEST,
        pageData,
    }),
    handleClickResetPassword: (pageData) => ({
        type: ActionType.HANDLE_CLICK_RESET_PASSWORD_REQUEST,
        pageData,
    }),
    handleClickContinueShopping: (pageData) => ({
        type: ActionType.HANDLE_CLICK_CONTINUE_SHOPPING_REQUEST,
        pageData,
    }),
    handleClearPageData: () => ({
        type: ActionType.HANDLE_CLEAR_PAGE_DATA_REQUEST,
    }),
    // HandleOpenModalSuccess
    handleOpenModal: (modal) => ({
        type: ActionType.HANDLE_OPEN_MODAL_REQUEST,
        modal,
    }),
    // HandleUpdateModalSuccess
    handleUpdateModal: (modal) => ({
        type: ActionType.HANDLE_UPDATE_MODAL_REQUEST,
        modal,
    }),
    // HandleSubmitAddNewRecipientSuccess
    handleSubmitAddNewRecipient: (
        data,
        AddressId,
        AddressBookEntryId,
        templateName,
        occasionId,
        orderItemId,
        currentPage,
        recipient,
        billingAddress,
    ) => ({
        type: ActionType.HANDLE_SUBMIT_ADD_NEW_RECIPIENT_REQUEST,
        recipientData: data,
        AddressId,
        AddressBookEntryId,
        templateName,
        occasionId,
        orderItemId,
        currentPage,
        recipient,
        billingAddress,
        callList: ['fetchCountryData', 'updateRecipientAddress'],
    }),

    // ThreadSearchAddressSuccess
    handleSearchAddress: (params) => ({
        type: ActionType.THREAD_SEARCH_ADDRESS_REQUEST,
        params,
        writeAccess: false,
        callList: ['searchAddress'],
    }),

    // ThreadSetAddressVerificationStateSuccess
    handleSetVerificationState: (isVerified) => ({
        type: ActionType.THREAD_SET_ADDRESS_VERIFICATION_STATE_REQUEST,
        writeAccess: false,
        isVerified,
    }),

    // HandleVerifyAddressSuccess
    handleVerifyAddress: (params) => ({
        type: ActionType.HANDLE_VERIFY_ADDRESS_REQUEST,
        params,
        writeAccess: false,
        callList: ['searchAddress'],
    }),

    // ThreadSelectAnAddressSuccess
    handleSelectAnAddress: (address, isAccountUsingZipCodeSpecificDeliveryRoutes) => ({
        type: ActionType.THREAD_SELECT_AN_ADDRESS_REQUEST,
        address,
        isAccountUsingZipCodeSpecificDeliveryRoutes,
        writeAccess: false,
        callList: ['selectAddress'],
    }),

    handleClearAddressVerification: () => ({
        type: ActionType.HANDLE_CLEAR_ADDRESS_VERIFICATION_REQUEST,
        writeAccess: false,
    }),

    // // HandleClearAutoSearchSuccess
    // resetAutoSearch: () => ({
    //     type: ActionType.HANDLE_CLEAR_AUTO_SEARCH_REQUEST,
    // }),

    handleClickEditBillingAddress: (billingAddress, wallet, template) => ({
        type: ActionType.HANDLE_CLICK_EDIT_BILLING_ADDRESS_REQUEST,
        billingAddress,
        wallet,
        template,
        callList: ['fetchCountryData'],
    }),
    // HandleSubmitProfileAndPasswordSuccess
    handleSubmitProfileAndPassword: () => ({
        type: ActionType.HANDLE_SUBMIT_PROFILE_AND_PASSWORD_REQUEST,
    }),
    // HandleUpdateFirstnameLastnameSuccess
    handleUpdateFirstnameLastname: (data) => ({
        type: ActionType.HANDLE_UPDATE_FIRSTNAME_LASTNAME_REQUEST,
        ...data,
        callList: ['updateFirstnameLastname'],
    }),
    // HandleUpdateNewEmailAddressSuccess
    handleChangeNewEmailAddress: (data) => ({
        type: ActionType.HANDLE_UPDATE_NEW_EMAIL_ADDRESS_REQUEST,
        ...data,
        callList: ['changeNewEmailAddress'],
    }),
    // HandleClearEmailAddressSuccess
    handleClearEmailAddress: () => ({
        type: ActionType.HANDLE_CLEAR_EMAIL_ADDRESS_REQUEST,
    }),
    // HandleUpdatePasswordSuccess
    handleUpdatePassword: (data) => ({
        type: ActionType.HANDLE_UPDATE_PASSWORD_REQUEST,
        ...data,
        callList: ['updatePassword'],
    }),
    // HandleClickAddNewCreditCardSuccess
    handleClickAddNewCreditCard: (params) => ({
        type: ActionType.HANDLE_CLICK_ADD_NEW_CREDIT_CARD_REQUEST,
        params,
    }),
    handleSaveCreditCard: (wallet) => ({
        type: ActionType.HANDLE_SAVE_CREDIT_CARD_REQUEST,
        wallet,
        callList: ['saveCreditCard'],
    }),
    handleClickRemoveCreditCard: (walletId) => ({
        type: ActionType.HANDLE_CLICK_REMOVE_CREDIT_CARD_REQUEST,
        walletId,
    }),
    // HandleClickEditCreditCardSuccess
    handleClickEditCreditCard: (walletAndBillingInfo, template) => ({
        type: ActionType.HANDLE_CLICK_EDIT_CREDIT_CARD_REQUEST,
        walletAndBillingInfo,
        template,
    }),
    handleDesktopClickEditCreditCard: (walletInfo) => ({
        type: ActionType.HANDLE_DESKTOP_CLICK_EDIT_CREDIT_CARD_REQUEST,
        walletInfo,
    }),
    handleRemoveCreditCard: (walletId) => ({
        type: ActionType.HANDLE_REMOVE_CREDIT_CARD_REQUEST,
        walletId,
        callList: ['removeCreditCard'],
    }),
    handleSetDefaultCard: (walletId) => ({
        type: ActionType.HANDLE_SET_DEFAULT_CREDIT_CARD_REQUEST,
        walletId,
        callList: ['setDefaultCreditCard'],
    }),
    handleSubmitOrderByZipCode: (data) => ({
        type: ActionType.HANDLE_SUBMIT_ORDER_BY_ZIP_CODE_REQUEST,
        ...data,
        callList: ['fetchOrderDetails'],
    }),
    handleUpdateEmailPreference: (data) => ({
        type: ActionType.HANDLE_UPDATE_EMAIL_PREFERENCE_REQUEST,
        ...data,
        callList: ['updateEmailPreference'],
    }),
    handleCancelSubscription: (subscriptionId) => ({
        type: ActionType.HANDLE_CANCEL_SUBSCRIPTION_REQUEST,
        subscriptionId,
        callList: ['cancelSubscription'],
    }),
    handleSwapSubscription: (data) => ({
        type: ActionType.HANDLE_SUBSCRIPTION_SWAP_REQUEST,
        data,
        callList: ['swapSubscription'],
    }),
    clearSwapSubscription: () => ({
        type: ActionType.HANDLE_CLEAR_SWAP_SUBSCRIPTION_REQUEST,
    }),
    handleUpdateSubscription: (subscriptionId) => ({
        type: ActionType.HANDLE_UPDATE_SUBSCRIPTION_REQUEST,
        subscriptionId,
        callList: ['UpdateSubscription'],
    }),
    handleFetchSubscriptions: () => ({
        type: ActionType.HANDLE_FETCH_SUBSCRIPTIONS_REQUEST,
    }),
    handleDesktopAddNewRecipient: (addFormLable) => ({
        type: ActionType.HANDLE_DESKTOP_ADD_NEW_RECIPIENT_REQUEST,
        recipient: {},
        addFormLable,
        callList: ['fetchCountryData'],
    }),
    //  handleCloseDialog
    handleCloseDialog: () => ({
        type: ActionType.HANDLE_CLOSE_DIALOG_REQUEST,
    }),

    handleDesktopAddNewFormClicked: (param) => ({
        type: ActionType.HANDLE_DESKTOP_ADD_NEW__FORM_REQUEST,
        param,
        recipient: {},
        callList: ['fetchCountryData'],
    }),
    handleDesktopSingleAdrressGetClicked: (data) => ({
        type: ActionType.HANDLE_DESKTOP_SINGLE_ADDRESS_GET_REQUEST,
        data,
    }),
    handleDesktopEditAddressClicked: (recipient, AddressBookEntryId, AddressId, param) => ({
        type: ActionType.HANDLE_DESKTOP_EDIT_ADDRESS_REQUEST,
        recipient,
        AddressBookEntryId,
        AddressId,
        param,
        callList: ['fetchCountryData'],
    }),
    // HandleDesktopAddressRemoveSuccess
    handleDesktopRemoveAddressClicked: (recipient, AddressId, addressType) => ({
        type: ActionType.HANDLE_DESKTOP_ADDRESS_REMOVE_REQUEST,
        recipient,
        AddressId,
        addressType,
    }),
    handleDesktopChangePassword: () => ({
        type: ActionType.HANDLE_DESKTOP_CHANGE_PASSWORD_REQUEST,
    }),
    handleDesktopChangeEmail: () => ({
        type: ActionType.HANDLE_DESKTOP_CHANGE_EMAIL_REQUEST,
    }),
    handleSetDefaultBillingValue: () => ({
        type: ActionType.HANDLE_SET_DEFAULT_BILLING_VALUE_REQUEST,
    }),
    handleDesktopDialogConfirm: (walletId, isDefaultCard, template) => ({
        type: ActionType.HANDLE_DESKTOP_DIALOG_CONFIRM_CARD_REQUEST,
        walletId,
        isDefaultCard,
        template,
    }),
    updateSubscriptionRecipientForm: (data) => ({
        type: ActionType.HANDLE_SUBSCRIPTION_RECIPIENT_FORM_REQUEST,
        data,
        callList: ['updateSubscriptionRecipientForm'],
    }),
    handleFetchDeliveryDates: (data) => ({
        type: ActionType.HANDLE_MMO_GET_DELIVERY_DATES_REQUEST,
        data,
        writeAccess: false,
        callList: ['fetchDeliveryDates'],
    }),
    handleFetchProductAvailability: (data) => ({
        type: ActionType.HANDLE_FETCH_PRODUCT_AVAILABILITY_REQUEST,
        data,
        writeAccess: false,
        callList: ['fetchProductAvailability'],
    }),
    clearProductAvilabilityRequest: () => ({
        type: ActionType.ClEAR_PRODUCT_AVILABILITY_REQUEST,
        writeAccess: false,
    }),
    submitModifiedOrder: (data) => ({
        type: ActionType.HANDLE_MMO_SUBMIT_MODIFIED_ORDER_REQUEST,
        data,
        callList: ['submitModifiedOrder'],
    }),
    handleFetchHDEditableCheck: (data) => ({
        type: ActionType.HANDLE_FETCH_HD_EDITABLE_CHECK_REQUEST,
        data,
        callList: ['fetchHDEditable'],
    }),
    fetchSubscriptionGiftMessage: (id) => ({
        type: ActionType.HANDLE_FETCH_SUBSCRIPTION_GIFT_MESSAGE_REQUEST,
        id,
        callList: ['fetchSubscriptionGiftMessage'],
    }),
    updateSubscriptionGiftMessage: (data) => ({
        type: ActionType.HANDLE_UPDATE_SUBSCRIPTION_GIFT_MESSAGE_REQUEST,
        data,
        callList: ['updateSubscriptionGiftMessage'],
    }),
    clearSubscriptionGiftMessage: () => ({
        type: ActionType.ClEAR_SUBSCRIPTION_GIFT_MESSAGE_REQUEST,
    }),
    handleFetchPassportAutoRenewalStatus: (data) => ({
        type: ActionType.HANDLE_FETCH_PASSPORT_AUTO_RENEWAL_STATUS_REQUEST,
        data,
        callList: ['fetchPassportAutoRenewalStatus'],
    }),
    cancelPassportMembership: (data) => ({
        type: ActionType.HANDLE_CANCEL_PASSPORT_MEMBERSHIP_REQUEST,
        data,
        callList: ['cancelPassportMembership'],
    }),
    clearCancelPassportMembershipState: () => ({
        type: ActionType.CLEAR_CANCEL_PASSPORT_MEMBERSHIP_STATE_REQUEST,
    }),
    updatePassportAutoRenewalStatus: (data) => ({
        type: ActionType.HANDLE_UPDATE_PASSPORT_AUTO_RENEWAL_STATUS_REQUEST,
        data,
        callList: ['updatePassportAutoRenewalStatus'],
    }),
    setPassportAutoRenewalStatus: (data) => ({
        type: ActionType.HANDLE_SET_PASSPORT_AUTO_RENEWAL_STATUS_REQUEST,
        data,
        callList: ['setPassportAutoRenewalStatus'],
    }),
    clearOrderHistoryRossData: () => ({
        type: ActionType.CLEAR_ORDER_HISTORY_ROSS_DATA_REQUEST,
    }),
    clearRossOrderData: () => ({
        type: ActionType.CLEAR_ROSS_ORDER_DATA_REQUEST,
    }),
    clearRossError: () => ({
        type: ActionType.CLEAR_ROSS_ERROR_REQUEST,
    }),
    validateAgentKey: (data) => ({
        type: ActionType.HANDLE_VALIDATE_AGENT_KEY_REQUEST,
        ...data,
        callList: ['validateAgentKey'],
    }),
    validateCaptcha: (response, callBackURL) => ({
        type: ActionType.HANDLE_VALIDATE_CAPTCHA_REQUEST,
        response,
        callBackURL,
        callList: ['validateCaptcha'],
    }),
    clearAgentSubMgmtState: (data) => ({
        type: ActionType.HANDLE_CLEAR_AGENT_SUB_MGMT_STATE_REQUEST,
        data,
        callList: ['clearAgentSubMgmtState'],
    }),
    clearSubcriptionState: () => ({
        type: ActionType.HANDLE_CLEAR_SUBSCRIPTION_STATE_REQUEST,
        callList: ['clearSubcriptionState'],
    }),
    setASMCustomerEmail: (customerEmail, callBackURL) => ({
        type: ActionType.HANDLE_SET_ASM_CUSTOMER_EMAIL_REQUEST,
        customerEmail,
        callBackURL,
        callList: ['setASMCustomerEmail'],
    }),
    updateMarketplaceFormData: (data) => ({
        type: ActionType.HANDLE_MARKET_PLACE_FORM_DATA_REQUEST,
        data,
        callList: ['updateMarketplaceFormData'],
    }),
    updateSubscriptionFrequency: (data) => ({
        type: ActionType.HANDLE_UPDATE_SUBSCRIPTION_FREQUENCY_REQUEST,
        ...data,
        callList: ['updateSubscriptionFrequency'],
    }),
    addAsmAgentNotes: (data) => ({
        type: ActionType.HANDLE_ADD_ASM_AGENT_NOTES_REQUEST,
        ...data,
        callList: ['addAsmAgentNotes'],
    }),
    fetchAsmAgentNotes: (data) => ({
        type: ActionType.HANDLE_FETCH_ASM_AGENT_NOTES_REQUEST,
        ...data,
        callList: ['fetchAsmAgentNotes'],
    }),
    clearAsmAgentNotesState: () => ({
        type: ActionType.CLEAR_ASM_AGENT_NOTES_STATE_REQUEST,
    }),
    clearAsmAgentNotesErrTracking: () => ({
        type: ActionType.CLEAR_ASM_AGENT_NOTES_ERR_TRACKING_REQUEST,
    }),
    setThumbsUpForDeliveryImage: (payload) => ({
        type: ActionType.SET_THUMBS_UP_FOR_DELIVERY_IMAGE_REQUEST,
        payload,
        callList: ['setThumbsUpForDeliveryImage'],
    }),
    setRecipientOpenStateSuccess: (payload) => ({
        type: ActionType.SET_RECIPIENT_OPEN_STATE_REQUEST,
        payload,
    }),
    fetchGiftListOccasions: () => ({
        type: ActionType.GET_GIFTLIST_OCCASIONS,
        isDirect: true,
        callList: ['fetchGiftListOccasions'],
    }),
    setRecipientVerifyData: (payload) => ({
        type: ActionType.SET_RECIPIENT_VERIFY_DATA_REQUEST,
        recipientLandingPage: payload,
    }),
    clearRecipientVerifyData: () => ({
        type: ActionType.CLEAR_RECIPIENT_VERIFY_DATA_REQUEST,
    }),
};

export default EventDispatcher;
