/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actions from './RecipientForm-Actions';
import initialState from '../../initialState';

const recipientFormReducer = (state = initialState.recipientForm, action) => {
    const formEvent = action.data;
    switch (action.type) {
        case actions.updateRecipientForm().type:
            return { ...state, ...action.data };
        case actions.updateFormAddressFromPreFill().type: {
            let firstAndLastName = action.data.firstAndLastName;
            if (!firstAndLastName && action.data.firstName && action.data.lastName) {
                firstAndLastName = `${action.data.firstName} ${action.data.lastName}`;
            }

            const finalData = {
                ...state,
                entryId: action.data.entryId,
                addressId: action.data.addressId,
                address1: action.data.address1,
                address2: action.data.address2,
                zipCode: action.data.zipCode,
                state: action.data.state,
                country: action.data.country,
                city: action.data.city,
                locationType: action.data.locationType,
                organizationName: action.data.organizationName,
                funeralServiceTime: action.data.funeralServiceTime,
                firstAndLastName,
                firstName: action.data.firstName,
                lastName: action.data.lastName,
                city_APO: action.data.city_APO,
                phone: action.data.phone,
                submitForm: false,
                cityArray: [],
                useAddressForAll: action.data.useAddressForAll || false,
                checkSameAsDeliveryAddress: action.data.checkSameAsDeliveryAddress || false,
                isFormValid: action.data.isFormValid || false,
                skipAddressBookAdd: action.data.skipAddressBookAdd || false, // NBCU items
            };
            if (action.data.email) {
                finalData.email = action.data.email; // added for NBCU item
            }
            return { ...state, ...finalData };
        }
        case actions.updateFormValues().type:
            switch (formEvent.target.name) {
                case 'firstAndLastName':
                    return { ...state, firstAndLastName: formEvent.target.value };
                case 'firstName':
                    return { ...state, firstName: formEvent.target.value };
                case 'lastName':
                    return { ...state, lastName: formEvent.target.value };
                case 'organizationName':
                    return { ...state, organizationName: formEvent.target.value };
                case 'funeralServiceTime':
                    return { ...state, funeralServiceTime: formEvent.target.value };
                case 'address1':
                    return { ...state, address1: formEvent.target.value };
                case 'address2':
                    return { ...state, address2: formEvent.target.value };
                case 'address3':
                    return { ...state, recipientAddressLine3: formEvent.target.value };
                case 'city_APO':
                    return { ...state, city_APO: formEvent.target.value };
                case 'addressId':
                    return { ...state, addressId: formEvent.target.value };
                case 'city':
                    return { ...state, city: formEvent.target.value };
                case 'email':
                    return { ...state, email: formEvent.target.value };
                case 'confirmEmail':
                    return { ...state, confirmEmail: formEvent.target.value };
                case 'country':
                    return { ...state, country: formEvent.target.value };
                case 'locationType':
                    return { ...state, locationType: formEvent.target.value };
                case 'phone':
                    return { ...state, phone: formEvent.target.value };
                case 'state':
                    return { ...state, state: formEvent.target.value };
                case 'cityArray':
                    return { ...state, cityArray: formEvent.target.value };
                case 'zipCode':
                    return { ...state, zipCode: formEvent.target.value };
                case 'giftMessageLine1':
                    return { ...state, giftMessageLine1: formEvent.target.value };
                case 'giftMessageLine2':
                    return { ...state, giftMessageLine2: formEvent.target.value };
                case 'giftMessageLine3':
                    return { ...state, giftMessageLine3: formEvent.target.value };
                case 'giftMessageLine4':
                    return { ...state, giftMessageLine4: formEvent.target.value };
                case 'giftMessageLine5':
                    return { ...state, giftMessageLine5: formEvent.target.value };
                case 'giftMessageLine6':
                    return { ...state, giftMessageLine6: formEvent.target.value };
                case 'giftMessageLine7':
                    return { ...state, giftMessageLine7: formEvent.target.value };
                case 'submitForm':
                    return { ...state, submitForm: formEvent.target.value };
                case 'checkSameAsDeliveryAddress':
                    return { ...state, checkSameAsDeliveryAddress: formEvent.target.value };
                case 'useAddressForAll':
                    return { ...state, useAddressForAll: formEvent.target.value };
                default:
                    break;
            }
            break;
        case actions.updateFromAddressBook().type:
            return { ...state, ...action.data };
        case actions.clearRecipientFormFields().type:
            return initialState.recipientForm;
        case actions.updateRecipientFormStatus().type:
            return { ...state, isFormValid: action.data };
        default:
            return state;
    }

    return state;
};

export default recipientFormReducer;
