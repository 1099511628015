/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import tagManagerActionTypes from './TagManager-ActionTypes';

export const trackPageView = (page) => ({
    type: tagManagerActionTypes.TAGMANAGER_TRACK_PAGE_VIEW,
    payload: page,
});

export const trackEvent = (dataLayer) => ({
    type: tagManagerActionTypes.TAGMANAGER_TRACK_EVENT,
    payload: {
        dataLayer,
    },
});

export const queueEvent = (dataLayer) => ({
    type: tagManagerActionTypes.TAGMANAGER_QUEUE_PAGE_EVENT,
    payload: {
        dataLayer,
    },
});

export const clearQueuedEvents = () => ({
    type: tagManagerActionTypes.TAGMANAGER_QUEUE_PAGE_EVENT,
    payload: 'clear',
});

export const setSessionCampaign = (campaign) => ({
    type: tagManagerActionTypes.TAGMANAGER_SET_SESSION_CAMPAIGN,
    payload: campaign,
});

export const trackEventComplete = () => ({
    type: tagManagerActionTypes.TAGMANAGER_TRACK_EVENT_COMPLETE,
});

export const queuedEventComplete = () => ({
    type: tagManagerActionTypes.TAGMANAGER_QUEUED_EVENT_COMPLETE,
});

export const setCurrentPageView = (pageName, pageType) => ({
    type: tagManagerActionTypes.TAGMANAGER_SET_CURRENT_PAGE_VIEW,
    payload: {
        pageName,
        pageType,
    },
});

export const trackPageViewComplete = (pageViewFired) => ({
    type: tagManagerActionTypes.TAGMANAGER_TRACK_PAGE_VIEW_COMPLETE,
    payload: pageViewFired,
});
