/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './Passport-ActionTypes';

export const passportFailed = (exception) => ({
    type: actionTypes.PASSORT_FAILED,
    payload: exception,
});

export const clearPassportError = () => ({
    type: actionTypes.CLEAR_PASSPORT_ERROR,
});

export const loadPassportError = (error, errorList = {}) => ({
    type: actionTypes.LOAD_PASSPORT_ERROR,
    data: {
        error,
        errorList,
    },
});

export const loadPassportLoader = (loader) => ({
    type: actionTypes.LOAD_PASSPORT_LOADER,
    data: {
        loader,
    },
});

export const triggerPassportSubscription = (pageOn, passportSku, history = null) => ({
    type: actionTypes.TRIGGER_PASSPORT_SUBSCRIPTION,
    data: {
        pageOn,
        passportSku,
        history,
    },
});

export const triggerAddPassportByRemoveGiftCard = (pageOn, history) => ({
    type: actionTypes.TRIGGER_ADD_PASSPORT_BY_REMOVE_GIFTCARD,
    data: {
        pageOn,
        history,
    },
});

export const setPassportData = (passportData) => ({
    type: actionTypes.SUBSCRIBE_PASSPORT_DATA,
    data: {
        passportData,
    },
});

export const getPassportTncContent = () => ({
    type: actionTypes.GET_PASSPORT_TNC_CONTENT,
});

export const loadPassportTncContent = (tncContent) => ({
    type: actionTypes.LOAD_PASSPORT_TNC_CONTENT,
    data: {
        tncContent,
    },
});

export const showPassportModalToRemoveGC = () => ({
    type: actionTypes.SHOW_PASSPORT_MODAL_TO_REMOVE_GIFTCARD,
});

export const hidePassportModalToRemoveGC = () => ({
    type: actionTypes.HIDE_PASSPORT_MODAL_TO_REMOVE_GIFTCARD,
});

export const passportProcessStart = () => ({
    type: actionTypes.PASSPORT_PROCESS_START,
});

export const passportProcessEnd = () => ({
    type: actionTypes.PASSPORT_PROCESS_END,
});

export const getDynamicPassportCharge = () => ({
    type: actionTypes.GET_DYNAMIC_PASSPORT_CHARGE,
});

// passport bundle page
export const setPassportBundleData = (passportBundle) => ({
    type: actionTypes.PASSPORT_BUNDLE_DATA,
    data: {
        passportBundle,
    },
});

// collection page -> product + passport bundle
export const setPassportBundleCollectionData = (passportBundleCollection) => ({
    type: actionTypes.PASSPORT_BUNDLE_COLLECTION_DATA,
    data: {
        passportBundleCollection,
    },
});

// url -> campaign -> utm
export const setPassportCampaignData = (passportCampaign) => ({
    type: actionTypes.PASSPORT_CAMPAIGN_DATA,
    data: {
        passportCampaign,
    },
});

// src\app\components\GraphqlComponents\GraphqlPassportContainer\GraphqlPassportSignInContainer.js
export const setPassportSignInData = (passportSignInData) => ({
    type: actionTypes.PASSPORT_SIGN_IN_DATA,
    data: {
        passportSignInData,
    },
});

// banner -> side banner
export const openPassportSideBar = () => ({
    type: actionTypes.OPEN_PASSPORT_SIDE_BAR,
    data: true,
});

export const closePassportSideBar = () => ({
    type: actionTypes.CLOSE_PASSPORT_SIDE_BAR,
    data: false,
});

export const subscribePassportBundle = (passportBundle, history) => ({
    type: actionTypes.SUBSCRIBE_PASSPORT_BUNDLE,
    data: {
        passportBundle,
        history,
    },
});

export const passportPromoAppliedSuccessfully = () => ({
    type: actionTypes.PASSPORT_PROMO_APPLIED_SUCCESSFULLY,
});

// Dependency

export const loadOrderId = (orderId) => ({
    type: 'LOAD_ORDER_ID',
    data: orderId,
});

export const calculateShipping = () => ({
    type: 'mbp-checkout/CALCULATE/SHIPPING',
});

export const calculateShippingComplete = () => ({
    type: 'mbp-checkout/CALCULATE/SHIPPING/COMPLETE',
});

export const callHandleCartFailure = (error) => ({
    type: 'mbp-checkout/CALL_HANDLE_CART_FAILURE',
    data: {
        error,
    },
});

export const setPassportABData = (abSkuData) => ({
    type: actionTypes.PASSPORT_AB_DATA,
    data: abSkuData,
});

export const setPassportSaleForceABTest = (abTestData) => ({
    type: actionTypes.PASSPORT_SALEFORCE_AB_TEST,
    data: abTestData,
});
