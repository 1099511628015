/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import 'cross-fetch/polyfill';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useHistory } from 'react-router-dom';
import mbpUtil from 'mbp-api-util';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { string, arrayOf, node } from 'prop-types';
import { Query } from '@apollo/client/react/components';
import mbpLogger from 'mbp-logger';
import loadable from '@loadable/component';
import ReactHtmlParser from 'react-html-parser';

// Brand Themes
import { Helmet } from 'react-helmet';
import AppLDProvider from '../../AppLDProvider';
import brandThemes from './brandtheme';
import addCMSTheme from '../BrandTheme/Deprecated/addCmsTheme';
import getBrandTheme from '../../gql/queries/getBrandTheme';

// helpers
import { GRAPHQL_ENV } from '../../gql';
import findContent from '../../gql/queries/findContent';
import cssVariablesEnvDir from '../EnterpriseDesignSystem/helpers/cssVariablesEnvDir';
// redux
import {
    getAppShellLoadedState, getChatBotOpenState, getBannerCode, country,
} from '../../../state/ducks/App/App-Selectors';
import { getBrowser, getOperatingSystem } from '../../../state/ducks/App/ducks/Config/Config-Helpers';
import { getFeatureFlag, getPresentationFamily } from '../../../state/ducks/App/ducks/Config/Config-Selectors';
import { loadAppShell } from '../../../state/ducks/App/App-Actions';
import { getBrand } from '../../../state/ducks/App/ducks/Brand/Brand-Selectors';

// third parties
import styleOverrides from './MuiStyleOverrides';
import { getPassportSideBarStatus, getPassportData } from '../../../state/ducks/Passport/Passport-Selectors';
import { closePassportSideBar } from '../../../state/ducks/Passport/Passport-Action';
import { isSkipChromeGoogleSigin } from '../GraphqlComponents/ChromeSignin/Helper/Helper';
import TrustArc from '../vendors/TrustArc/TrustArc';
// components
import RenderWhenVisible from '../../helpers/componentVisibility/RenderWhenVisible';
import GraphqlPassportContainer from '../GraphqlComponents/GraphqlPassportContainer/GraphqlPassportContainer';
import GraphqlABPassportPrice from '../GraphqlComponents/GraphqlPassportContainer/GraphqlABPassportPrice';
import PassportSideBar from '../GraphqlComponents/GraphqlHomePage/Partials/Sidebar/PassportSideBar';
import MultiBrandChatButton from '../MultiBrandChatController/MultiBrandChatButton';
import EmailCaptureWidgetQueryContainer from '../EmailCaptureWidget/EmailCaptureWidgetQueryContainer';
import { getActiveABTest } from '../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import MobileSurveyButon from '../Survey/MobileSurveyButton';
import InternetExplorerWarningModal from './InternetExplorerWarningModal/InternetExplorerWarningModal';
import EmailOptInQueryContainer from '../EmailOptIn/EmailOptInQueryContainer';
import FooterQuery from '../GraphqlComponents/GraphqlFooter/FooterQuery';
import { hasBranch } from '../../gql/helpers/getBranch';
import BranchIndication from '../Header/components/BranchIndication/BranchIndication';
import UserExitIntentTracker from '../../helpers/Personalization/UserExitIntentTracker/UserExitIntentTracker';
// import { trackEvent } from '../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { emitCustomerIdentificationEvent } from '../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import validateEmailAddress from '../../helpers/validateEmailAddress';
import { useHoldoutPersonalization } from '../../helpers/Personalization/HoldoutPersonalization/useHoldoutPersonalization';
import PassportSaleForceABTest from '../GraphqlComponents/GraphqlPassportContainer/PassportSaleForceABTest';

const KlarnaOnSiteMessgeScript = loadable(() => import(/* webpackChunkName: "KlarnaOnSiteMessgeScript" */ '../Checkout/KlarnaPaymentOnSiteMessage/KlarnaOnSiteMessageScript/KlarnaOnSiteMessgeScript'));

const LoadableFooterResponsive = loadable(() => import(/* webpackChunkName: "GraphqlFooterFood" */ '../GraphqlComponents/GraphqlFooterFood/FooterResponsive'));

const LoadableHeaderBuilder = loadable(() => import(/* webpackChunkName: "GraphqlHeader" */ '../GraphqlComponents/GraphqlHeader/HeaderBuilder')); // TODO - SA
const LoadableGraphqlPassportCampaignContainer = loadable(() => import(/* webpackChunkName: "GraphqlPassportCampaignContainer" */ '../GraphqlComponents/GraphqlPassportContainer/GraphqlPassportCampaignContainer'));
const LoadableGraphqlPassportSignInContainer = loadable(() => import(/* webpackChunkName: "GraphqlPassportSignInContainer" */ '../GraphqlComponents/GraphqlPassportContainer/GraphqlPassportSignInContainer'));

// third partiesconst
const LoadableChromeSignin = loadable(() => import(/* webpackChunkName: "ChromeSignin" */ '../GraphqlComponents/ChromeSignin/ChromeSignin'));
const LoadableSmartBanner = loadable(() => import(/* webpackChunkName: "SmartBanner" */ './Pixels/SmartBanner'));
const LoadableQuizDialog = loadable(() => import(/* webpackChunkName: "QuizDialog" */ '../GraphqlComponents/GraphqlContentPage/QuizDialog'));

const AppShell = ({
    children,
    ssrDeviceType,
}) => {
    const ffIsTrustarcEnabled = useSelector(getFeatureFlag('is-trustarc-enabled'));
    const ffIsIEDegradationEnabled = useSelector(getFeatureFlag('is-IE-Degradation-enabled'));
    const ffIsPassportEnabled = useSelector(getFeatureFlag('is-passport-enabled'));
    const ffIsChromeSigninEnabled = useSelector(getFeatureFlag('is-chrome-signin-enabled'));
    const ffIsWebchatEnabled = useSelector(getFeatureFlag('is-webchat-enabled'));
    const ffIsGenesysChatEnabled = useSelector(getFeatureFlag('is-genesys-chat-enabled'));
    const ffIsMobileFeedbackEnabled = useSelector(getFeatureFlag('is-mobile-feedback-button-enabled'));
    const ffIsSeoNoindexEnabled = useSelector(getFeatureFlag('is-seo-noindex-enabled'));
    const ffIsSeoNofollowEnabled = useSelector(getFeatureFlag('is-seo-nofollow-enabled'));
    const ffIsPassportUtmParamEnabled = useSelector(getFeatureFlag('is-passport-utm-param-enabled'));
    const ffIsPassportConditionalOfferOn = useSelector(getFeatureFlag('is-passport-conditional-offer-on'));
    const ffWhichPassportPriceVariant = useSelector(getFeatureFlag(['which-passport-price-variant', 'variant']));
    const ffIsNewPassportDesignEnabled = useSelector(getFeatureFlag('is-new-passport-design-enabled'));
    const ffIsKlarnaPaymentEnabled = useSelector(getFeatureFlag('is-klarna-payment-enabled'));
    const ffUserExitEnabled = useSelector(getFeatureFlag('is-exit-intent-tracker-enabled'));
    const isTempRebrand = useSelector(getFeatureFlag('is-temporary-rebrand-application'));
    const isStyleHotfix = useSelector(getFeatureFlag('is-temporary-stylesheet-hotfix'));
    const isGlobalHotfix = useSelector(getFeatureFlag('is-global-stylesheet-hotfix'));
    const ffIsSmartAppBannerEnabled = useSelector(getFeatureFlag('is-smart-app-banner-enabled'));
    const ffIsPrefetchDNS = useSelector(getFeatureFlag('is-prefetch-contentstack-dns'));
    const ffIsFontStackReferencedInHead = useSelector(getFeatureFlag('is-fontstack-in-document-head'));
    const is5LLEnabled = useSelector(getFeatureFlag('is-5LL-enabled'));
    const ffIsEmailCaptureWidgetEnabled = useSelector(getFeatureFlag('is-email-capture-widget-enabled'));
    const ffIsWkndEmailCaptureEnabled = useSelector(getFeatureFlag('is-wk-email-capture-enabled'));
    const ffIsEmailOptInEnabled = useSelector(getFeatureFlag('is-email-opt-in-enabled'));
    const ffHoldoutPersonalizationEnabled = useSelector(getFeatureFlag('is-personalization-holdout-enabled'));
    const presentationFamily = useSelector(getPresentationFamily);
    const browserDegradation = useSelector(getActiveABTest('browser_degradation_enabled'));
    const passportData = useSelector(getPassportData);
    const chatBotOpen = useSelector(getChatBotOpenState);
    const bannerCode = useSelector(getBannerCode);
    const brand = useSelector(getBrand);
    const passportSideBarStatus = useSelector(getPassportSideBarStatus);
    const appShellLoaded = useSelector(getAppShellLoadedState);
    const countryName = useSelector(country);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        dispatch(loadAppShell({
            hostname: window.location.hostname,
            location,
            history,
            appShellLoaded,
        }));

        // close passport sidebar onload
        if (passportSideBarStatus) dispatch(closePassportSideBar());
    }, []);

    useEffect(() => {
        if (passportSideBarStatus) {
            if (typeof document !== 'undefined'
                && document.getElementById('root')?.style?.position !== 'static') {
                document.getElementById('root').style.position = 'static';
            }
        }
    }, [passportSideBarStatus]);

    // hook for applying holdout personalization flow
    useHoldoutPersonalization(ffHoldoutPersonalizationEnabled);

    const handleNestedDialogScrolling = (event) => {
        const currentElement = event.target;
        // checking if multiple modal is activated/shown for the user or not.
        // if its not present stop the further flow.
        const targetParentElement = document?.getElementsByClassName('MuiDialog-root');
        const isMultipleDialog = currentElement?.closest('.MuiDialog-root');
        if (!targetParentElement || !isMultipleDialog) return;
        if (document.body.contains(document.getElementById('optin-email-modal'))) {
            document?.getElementById('root')?.removeAttribute('aria-hidden');
            document?.getElementsByTagName('body')?.[0]?.removeAttribute('style');
        }
    };

    const wkndDOMClickHandler = (event) => {
        const currentElement = event.target;
        // checking if wunderkind modal is activated/shown for the user or not.
        // if its not there stop the further flow.
        if (ffIsEmailOptInEnabled) handleNestedDialogScrolling(event);
        const targetParentElement = document?.querySelector('div[id^="bx-creative"]');
        if (!targetParentElement) return;
        const targetElement = 'button[type="submit"]';
        const targetDOMElement = targetParentElement?.querySelector(targetElement);
        const emailInputElement = targetParentElement?.querySelector('input[type="email"]');
        const emailInputElementValue = emailInputElement?.value;
        // check for clicked submit button must of the wunderkind modal
        if (targetParentElement?.contains(targetDOMElement) && currentElement?.matches(targetElement) && validateEmailAddress(emailInputElementValue)) {
            const customerIdentificationPayload = {
                signInMethod: 'promo-banner-email',
                user: {
                    email: emailInputElementValue,
                },
            };
            dispatch(emitCustomerIdentificationEvent(customerIdentificationPayload));
        }
    };

    useEffect(() => {
        let rootDOMElement = '';
        if (appShellLoaded && (ffIsWkndEmailCaptureEnabled || ffIsEmailOptInEnabled) && typeof document !== 'undefined') {
            rootDOMElement = document.getElementsByTagName('body')?.[0];
            // added click event listener for body element as we cant control display behaviour of wunderkind modal
            // on click of the submit button from wunderkind modal firing clickstream identification event with capturing email id.
            rootDOMElement?.addEventListener('click', wkndDOMClickHandler, true);
        }
        return () => {
            if (rootDOMElement) rootDOMElement?.removeEventListener('click', wkndDOMClickHandler, true);
        };
    }, [appShellLoaded, ffIsWkndEmailCaptureEnabled, ffIsEmailOptInEnabled]);

    if (!brand || !brand?.['domain-name'] || !appShellLoaded) return null;
    const operatingSystem = getOperatingSystem().toLowerCase();
    let chatBotTitles = {};
    let chatBotColor = {};
    const browser = getBrowser();

    const brandThemeQueryConfig = {
        brand: brand['domain-name'],
        contentType: 'brand_configuration',
        GRAPHQL_ENV,
    };

    const THEME_QUERY = isGlobalHotfix ? getBrandTheme(brand['domain-name']) : findContent(brandThemeQueryConfig);

    const renderChromeDropDown = () => {
        if (ffIsChromeSigninEnabled && !isSkipChromeGoogleSigin()) {
            return <LoadableChromeSignin />;
        }
        return null;
    };

    const renderMultiBrandChat = () => {
        if (
            ffIsGenesysChatEnabled && ssrDeviceType !== 'mobile' && ssrDeviceType !== 'tablet' && typeof window !== 'undefined'
        ) {
            return appShellLoaded && <MultiBrandChatButton />;
        }
        return null;
    };

    // add survey here
    const renderMobileSurveyButton = () => {
        if (
            ffIsMobileFeedbackEnabled && (ssrDeviceType === 'mobile' || ssrDeviceType === 'tablet') && typeof window !== 'undefined'
        ) {
            return appShellLoaded && <MobileSurveyButon />;
        }
        return null;
    };

    const renderNoIndexNoFollow = () => {
        if ((ffIsSeoNoindexEnabled && ffIsSeoNofollowEnabled) || (location.pathname.includes('searchterm'))) {
            return <Helmet><meta name="ROBOTS" content="NOINDEX,NOFOLLOW" /></Helmet>;
        }
        return null;
    };

    const renderEmailCaptureWidget = () => {
        if (ffIsEmailCaptureWidgetEnabled && typeof window !== 'undefined' && !location.pathname.includes('/checkout/')) {
            return appShellLoaded && <EmailCaptureWidgetQueryContainer showModalAfterMS={10000} />;
        }
        return null;
    };

    const renderEmailOptInModal = () => {
        if (ffIsEmailOptInEnabled && typeof window !== 'undefined' && !location.pathname.includes('/checkout/') && !location.pathname.includes('/wrap-up') && !location.pathname.includes('/add-ons')) {
            return appShellLoaded && <EmailOptInQueryContainer showModalAfterMS={10000} />;
        }
        return null;
    };

    const smartBannerTracking = operatingSystem === 'ios' && ssrDeviceType === 'mobile' && ffIsSmartAppBannerEnabled && brand['domain-name'] === '1800flowers' && brand['smart-app-banner-app-id'];

    if (location?.pathname?.includes('/auth/login')) {
        return <></>;
    }

    let renderHeaderAndFooter = true;
    if (location?.pathname?.includes('sign-in')) renderHeaderAndFooter = false;
    // TODO
    // - implement a better way to get brand instead of props drilling -> Redux store -> Connect to parent components with queries, hand to children as props?
    return (
        <>
            {browser === 'Microsoft Internet Explorer'
                && ffIsIEDegradationEnabled
                && ((presentationFamily === 'flower' && browserDegradation)
                    || presentationFamily === 'food')
                && <InternetExplorerWarningModal />}
            <Query query={THEME_QUERY}>
                {({ loading, error, data }) => {
                    if (loading) {
                        return null;
                    }

                    if (error) {
                        mbpLogger.logError({
                            appName: process.env.npm_package_name,
                            query: THEME_QUERY,
                            component: 'AppShell.js',
                            message: 'Error loading theme from Graphql',
                            env: GRAPHQL_ENV,
                            error,
                        });
                        // DO NOT ABORT -- continue onward if THEME not in contentstack
                    }

                    // brandThemes[brand.code] may not yet exist for brands under development.
                    // Must contain valid brand configuration data for production brands.
                    let cmsThemeData;
                    let globalHotfix;
                    if (brandThemes[brand.code]) {
                        if (!data || !data.findContent || !data.findContent.content || !data.findContent.content.entries
                            || !Array.isArray(data.findContent.content.entries)
                            || !data.findContent.content.entries[0]) {
                            mbpLogger.logWarning({
                                appName: process.env.npm_package_name,
                                query: THEME_QUERY,
                                component: 'AppShell.js',
                                message: 'No theme data returned for query',
                                env: GRAPHQL_ENV,
                            });
                        } else {
                            cmsThemeData = data.findContent.content.entries[0];
                            globalHotfix = isGlobalHotfix ? data.globalHotfix.content?.entries?.[0]?.stylesheet : '';
                            ({ chatBotTitles, chatBotColor } = addCMSTheme(brandThemes[brand.code], cmsThemeData, THEME_QUERY, GRAPHQL_ENV));
                        }
                    }
                    // grab the google_optimize test_selector array from the cms entry under 'other'
                    const abTestSelector = cmsThemeData?.other?.google_optimize?.test_selector || [];

                    const {
                        palette,
                        typography,
                        spacing,
                        breakpoints,
                        presentation_family,
                        blockHeading,
                    } = brandThemes[brandThemes[brand.code] ? brand.code : '18F'];  // if brand not set up yet, use 18F settings
                    let { overrides } = brandThemes[brandThemes[brand.code] ? brand.code : '18F'];

                    // To get font family from cms
                    typography.getFontFamily = (fontName) => {
                        const filteredFont = (typography?.cms || []).filter((font) => font.font_name === fontName);
                        if (filteredFont.length) {
                            return filteredFont[0].font_style;
                        }
                        return null;
                    };

                    overrides = { ...overrides, ...styleOverrides(palette) };

                    const theme = createTheme({
                        palette,
                        typography,
                        spacing,
                        breakpoints,
                        overrides,
                        blockHeading,
                    });

                    const isCountryEngland = countryName === 'England' && ssrDeviceType === 'mobile' && presentationFamily === 'flower';
                    const mobileCheckoutOffsetHeight = (location.pathname.includes('/checkout/') && ssrDeviceType === 'mobile') ? '100vh' : 'calc(110vh - 236px)';
                    const innerHeightScreen = isCountryEngland ? 'auto' : mobileCheckoutOffsetHeight;

                    return (
                        <AppLDProvider ssrDeviceType={ssrDeviceType}>
                            <ThemeProvider theme={theme}>
                                <div id="appshell-container" style={{ width: '100%' }}>
                                    {hasBranch() && <BranchIndication branch={hasBranch()} />}
                                    {ffIsPrefetchDNS && <Helmet><link rel="dns-prefetch" href="https://assets.contentstack.io/" /></Helmet>}
                                    {ffIsFontStackReferencedInHead ? <Helmet>{ReactHtmlParser(typography.styleblock)}</Helmet> : ReactHtmlParser(typography.styleblock)}
                                    {<Helmet><link rel="stylesheet" id="base-stylesheet-css-variables" href={`https://cdn1.${brand['domain-name']}.com/wcsstore/${brand['identifier']}/css/${cssVariablesEnvDir}/components/base/base.vars.css`} /></Helmet>}
                                    {smartBannerTracking && <LoadableSmartBanner />}
                                    <CssBaseline />
                                    {isStyleHotfix && ReactHtmlParser(cmsThemeData?.other?.stylesheet_hotfix)}
                                    {isTempRebrand && ReactHtmlParser(cmsThemeData?.other?.stylesheet)}
                                    {isGlobalHotfix && ReactHtmlParser(globalHotfix)}
                                    {abTestSelector.length > 0 && (
                                        <Helmet>
                                            (
                                            <style id="abTestSelector">
                                                {`${abTestSelector.map((d) => `${d.selector_value}{${d.attributes.value.map((css) => `${css.key}:${css.value};`).join('')}}`).join('')}`}
                                            </style>
                                            )
                                        </Helmet>
                                    )}
                                    {renderNoIndexNoFollow()}
                                    {ffIsPassportEnabled && passportData && !passportData?.item_id && <GraphqlPassportContainer />}
                                    {
                                        (ffIsPassportUtmParamEnabled
                                            && passportData
                                            && passportData?.passport_pricing_conditional_logic?.utm_params)
                                        && (<LoadableGraphqlPassportCampaignContainer />)
                                    }
                                    {
                                        (ffIsPassportConditionalOfferOn
                                        )
                                        && (<LoadableGraphqlPassportSignInContainer />)
                                    }
                                    {ffIsKlarnaPaymentEnabled && <KlarnaOnSiteMessgeScript />}
                                    {renderChromeDropDown()}
                                    {renderMultiBrandChat()}
                                    {renderMobileSurveyButton()}
                                    {renderEmailCaptureWidget()}
                                    {renderEmailOptInModal()}
                                    {renderHeaderAndFooter && (
                                        <LoadableHeaderBuilder
                                            history={history}
                                            deviceType={ssrDeviceType}
                                            presentationFamily={presentation_family}
                                            bannerCode={bannerCode}
                                        />
                                    )}
                                    <div id="main_body" style={{ minHeight: `${innerHeightScreen}` }}>
                                        {children}
                                    </div>
                                    { // Consent banner (GDPR)
                                        typeof window !== 'undefined' && mbpUtil.getEnv('TRUST_ARC_DEV_FLAG') === 'true' && ffIsTrustarcEnabled && <TrustArc />
                                    }
                                    {renderHeaderAndFooter && (
                                        <RenderWhenVisible>
                                            {presentation_family === 'food' || brand.domain === 'florists'
                                                ? <LoadableFooterResponsive />
                                                : <FooterQuery ssrDeviceType={ssrDeviceType} />}
                                        </RenderWhenVisible>
                                    )}
                                </div>

                                <PassportSideBar
                                    addedBenefits={false}
                                    ssrDeviceType={ssrDeviceType}
                                />

                                {ffWhichPassportPriceVariant
                                    && (
                                        <GraphqlABPassportPrice
                                            contentType="passport_bundle_skus"
                                            variant={ffWhichPassportPriceVariant}
                                        />
                                    )}
                                {
                                    ffIsNewPassportDesignEnabled === 'saleforce'
                                    && <PassportSaleForceABTest isNewPassportDesignEnabled={ffIsNewPassportDesignEnabled} />
                                }
                                {is5LLEnabled && <LoadableQuizDialog />}
                                {ffUserExitEnabled && <UserExitIntentTracker />}
                            </ThemeProvider>
                        </AppLDProvider>
                    );
                }}
            </Query>

        </>
    );
};

AppShell.propTypes = {
    children: arrayOf(node).isRequired,
    ssrDeviceType: string,
};

AppShell.defaultProps = {
    ssrDeviceType: '',
};

export default AppShell;
