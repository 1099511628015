/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
export const UPDATE_PMALL_PERSONALIZATION_DATA = 'mbp-checkout/UPDATE_PMALL_PERSONALIZATION_DATA';
export const REMOVE_PMALL_PERSONALIZATION = 'mbp-checkout/REMOVE_PMALL_PERSONALIZATION';

export const UPDATE_PMALL_IMAGES = 'mbp-checkout/UPDATE_PMALL_IMAGES';

export const UPDATE_CART_PMALL_PERSONALIZATION = 'mbp-checkout/UPDATE_CART_PMALL_PERSONALIZATION';
export const UPDATE_CART_PMALL_PERSONALIZATION_SUCCESS = 'mbp-checkout/UPDATE_CART_PMALL_PERSONALIZATION_SUCCESS';
export const UPDATE_CART_PMALL_PERSONALIZATION_FAILED = 'mbp-checkout/UPDATE_CART_PMALL_PERSONALIZATION_FAILED';
