/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
To Handle multiple rerender of MicroFrontendClient Component,
we need to efficiently load scipt JS file. Here are prerequisites
1. Load MFE JS file once.
2. Ability to load new version of MFE site on same user session.
3. return multiple rerender with single cached version if no new version is deployed.
4. Always resolve the final rerender with MFE Component.

Below code accomplish all mentioned prequisites.
Here is flow FLow diagram Notes: https://1800flowers-my.sharepoint.com/:w:/p/gpanchal/EaVJ47CWadVPoFLw5vG-SRIB83u_j7JV_lzAUOJB1WPnTA?email=dherman%40HarryandDavid.com&e=ft18cE

Below are all loading scenarios of SPONTANEOUS Rerender which are WORKING.
Add more scenarios here with the fix.

Scenario 1: Quick rerender of MFE Component
--------------------------------------------------------------------------------------------------------
initial render begin at 3683.279999997467
Waiting for update signal from WDS...
CheckoutJS script load starts at 5740.460000000894
second render starts at 5899.894999922253
CheckoutJS script load end at 5924.644999904558
second render end at 5932.239999994636
mother app state >>>>  {apolloClient: ApolloClient, store: {…}, history: {…}}
--------------------------------------------------------------------------------------------------------

Senario 2: Slow load of Checkout.js Script
--------------------------------------------------------------------------------------------------------
initial render begin at 4165.524999960326
second render starts at 5248.194999992847
Waiting for update signal from WDS...
CheckoutJS script load starts at 6104.399999952875
second render end at 6211.79500001017
mother app state >>>>  {apolloClient: ApolloClient, store: {…}, history: {…}}
CheckoutJS script load end at 6271.0949999745935
--------------------------------------------------------------------------------------------------------

Senario 3: Quick Rerendering and quick script load of MFE Component
--------------------------------------------------------------------------------------------------------
initial render begin at 4756.694999989122
second render starts at 7957.719999947585
[HMR] Waiting for update signal from WDS...
Script Load completed at 8936.495000030845
CheckoutJS script load starts at 8936.794999986887
second render end at 9131.41499995254
mother app state >>>>  {apolloClient: ApolloClient, store: {…}, history: {…}}
--------------------------------------------------------------------------------------------------------

Senario 4: Slow Rerendering of MFE Component
--------------------------------------------------------------------------------------------------------
initial render begin at 4548.934999969788
Waiting for update signal from WDS...
Script Load completed at 6424.520000000484
CheckoutJS script load starts at 6425.529999891296
CheckoutJS script load end at 6599.224999896251
mother app state >>>>  {apolloClient: ApolloClient, store: {…}, history: {…}}
webpackHotDevClient.js:76 The development server has disconnected.
Refresh the page if necessary.
initial render begin at 6892.735000001267
CheckoutJS script load starts at 7440.74999995064
CheckoutJS script load end at 7451.284999959171
mother app state >>>>  {apolloClient: ApolloClient, store: {…}, history: {…}}
--------------------------------------------------------------------------------------------------------
*/

import mbpLogger from 'mbp-logger';
import md5 from 'md5';

import { loadJS, waitWindowMFEComponent } from './mfeLoaderUtils';

let inProgress = false;
let renderCount = 0;

export const loadBundle = async (clientName, host) => {
    try {
        if (!inProgress) {
            inProgress = true;
            console.log(`initial render begin at ${performance.now()}`);
            const assetManifest = await fetch(`${host}/asset-manifest.json`)
                .then((res) => res.json())
                .then((manifest) => manifest);

            if (typeof assetManifest === 'object' && assetManifest?.entrypoints[0]) {
                const hash = md5(JSON.stringify(assetManifest));
                const entryPoint = assetManifest.entrypoints.find((file) => file.endsWith('.js'));
                const checkoutComponent = await loadJS(hash, `${host}/${entryPoint}`, clientName);
                inProgress = false;
                renderCount = 0;
                return checkoutComponent;
            }

            throw new Error('Failed to load Entry Point.');
        }

        renderCount += 1;
        const checkoutComponent = await waitWindowMFEComponent(clientName, `${renderCount} re-render`);
        return checkoutComponent;
    } catch (ex) {
        console.log(ex);
        inProgress = false;
        renderCount = 0;
        mbpLogger.logError({
            jsError: ex,
            module: 'mbp-pwa-ui',
            message: 'MFE Component load failed',
        });
        return null;
    }
};

export default {};
