/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

export const fetchSCIData = async ({ mId, JWT, locNumber }) => {
    const wcEnv = { rootUri: '/' };

    let resourcePath = `sci/webapi/api/1800flowers/decedentinfo/${mId}`;
    if (mId === '0' || !mId.length) {
        resourcePath = `sci/webapi/api/1800flowers/locationinfo/${locNumber}`;
    }

    const response = await restClient.getFacade(wcEnv, resourcePath, JWT, null, null, false);

    return response?.data;
};

export default {};
