/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import axios from 'axios';
import mbpLogger from 'mbp-logger';
import mbpUtil from 'mbp-api-util';

const ENV_OBJ = {
    protocol: 'https',
    host: mbpUtil.getEnv('APP_VERIFY_ADDRESS_HOST') || 'localhost',
    authToken: mbpUtil.getEnv('APP_VERIFY_ADDRESS_AUTH_TOKEN') || '',
    authTokenGuidedNav: mbpUtil.getEnv('APP_VERIFY_ADDRESS_AUTH_TOKEN_GNAV') || mbpUtil.getEnv('APP_VERIFY_ADDRESS_AUTH_TOKEN') ||  '',
    passAuthTokenInHeader: false,
};

/**
 * Call Axios to execute remote call to ContentStack service.
 * Note: brand will get added as parameter on the URL
 *
 * @param method       - get/post/put/delete/etc
 * @param url          - string for url to call
 * @param params       - object with parameters to be added to the url
 * @param data         - body
 * @param qasEnv       - QAS environment properties
 * @param authToken    - Auth Token
 * @param header       - object with headers to be added to the request
 */

const callAxios = (method, url, params, data, qasEnv, authToken, headers, timeout) => {
    try {
        // Bail if no accessToken or apiKey provided
        if (!authToken) {
            return false;
        }
        const config = {
            method,
            url,
            params,
            headers,
            data,
        };
        if (timeout) {
            config.timeout = timeout;
        }
        if (authToken && ENV_OBJ.passAuthTokenInHeader) {
            config.headers = { 'Auth-Token': authToken, ...config.headers };
        } else if (authToken && !ENV_OBJ.passAuthTokenInHeader) {
            config.params = { 'auth-token': authToken, ...config.params };
        }
        return axios(config);
    } catch (ex) {
        mbpLogger.logError({
            method,
            url,
            params,
            data,
            qasEnv,
            headers,
            function: 'callAxios',
            module: 'mbp-pwa-ui,',
            jsError: ex,
            message: 'callAxios FAILED',
        });
    }
    return null;
};

/**
 * Builds the url
 * @param {*} qasEnv  - if empty uses default.
 * @param String actionType - either be search || format
 */
const getUrl = (qasEnv, actionType) => {
    mbpLogger.logDebug({
        qasEnv,
        actionType,
        function: 'getUrl',
        module: 'mbp-qas-address-sdk',
        message: 'getUrl variables',
    });
    const hostUrl = `${(qasEnv.protocol || ENV_OBJ.protocol)}://${(qasEnv.host || ENV_OBJ.host)}`;
    const url = `${hostUrl}/capture/address/v2/${actionType}`;

    mbpLogger.logDebug({
        url,
        hostUrl,
        function: 'getUrl',
        module: 'mbp-contentstack-sdk',
        message: 'getUrl variables',
    });

    return url;
};

/**
 * @param {*} qasEnv
 * @param String actionType - either be search || format
 * @param {*} params
 */
const qasGet = (qasEnv, actionType, params, timeout, isGuidedNav = false) => {
    try {
        mbpLogger.logDebug({
            params,
            function: 'qasGet',
            module: 'mbp-qas-address-sdk',
            message: 'qasGet params',
        });
        const url = getUrl(qasEnv, actionType);
        let qasAuthToken = '';
        if (qasEnv.authToken) {
            qasAuthToken = qasEnv.authToken;
        } else {
            qasAuthToken = isGuidedNav ? ENV_OBJ.authTokenGuidedNav : ENV_OBJ.authToken;
        }
        return callAxios('get', url, params, null, qasEnv, qasAuthToken, {}, timeout);
    } catch (ex) {
        mbpLogger.logError({
            qasEnv,
            actionType,
            function: 'qasGet',
            module: 'mbp-qas-address-sdk',
            jsError: ex,
            message: 'QAS Get Failed',
        });
    }
    return null;
};

export default { qasGet };
