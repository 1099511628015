/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { channel } from 'redux-saga';
import {
    take, fork, call, put,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import Cookies from 'universal-cookie';

import {
    setABTestingVariant,
    setABTestingLoaded,
    setABTestingTimeout,
} from './ABTesting-Actions';

const optimizeChannel = channel();
const optimizeTimeout = null;

export function handleOptimizeLoad() {
    if (optimizeTimeout) {
        clearTimeout(optimizeTimeout);
    }
    optimizeChannel.put(setABTestingLoaded());
}

export function handleOptimizeTimeout() {
    optimizeChannel.put(setABTestingTimeout());
}

export const handleOptimizeTestEvent = (e) => {
    if (e.detail?.siteVariant) {
        const cookies = new Cookies();
        cookies.set('sv', e.detail.siteVariant);
    }
    optimizeChannel.put(setABTestingVariant(e.detail));
};

// eslint-disable-next-line require-yield
export function* startOptimizeChannel() {
    if (typeof document !== 'undefined') {
        document.addEventListener('optimizeTestEvent', handleOptimizeTestEvent);
    }
}

function* initializeABTesting() {
    try {
        yield call(startOptimizeChannel);
        yield call(handleOptimizeLoad);
    } catch (ex) {
        mbpLogger.logError({
            function: 'initializeABTesting',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: 'Error injecting optimize script',
            jsError: ex,
        });
    }
}

function* watchOptimizeChannel() {
    if (typeof window !== 'undefined') {
        while (true) {
            const action = yield take(optimizeChannel);
            yield put(action);
        }
    }
}

const watchers = [
    fork(watchOptimizeChannel),
];

export { watchers, initializeABTesting };
