/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import restClient from './restClient';

const RESOURCE_ROOT_CARTSERVICE = '/checkout';
const RESOURCE_VERSION_CARTSERVICE = '/v2';
const RESOURCE_PAGE_CARTSERVICE = '/cart';
const RESOURCE_PAGE_ACTION = '/item';
const RESOURCE_PAGE_DELIVERY_ACTION = '/deliverydate';

/**
 * updateCartItemDeliveryInfo
 * url - /checkout/{apiversion}/cart/{cartId}/item/deliverydate?brand={brandId}
 *
 * @param {Object} args
 * @param {Object} args.env
 * @param {String} args.jwtToken
 * @param {String} args.cartId
 * @param {String} args.cartItemId
 * @param {String} args.deliveryDate
 * @param {String} args.zipCode
 * @param {String} args.locationType
 * @param {String} args.deliverySLA
 * @param {boolean} args.calculateCart
 * @param {String} args.flexOptionId
 * @param {string} args.flexVariantId
 * @param {string} args.flexOptionKey
 * @param {String} args.holidayCode
 * @param {String} args.deliveryDateType
 * @param {String} args.deliverRangeStartDate
 * @param {array} args.attributes
 */
const updateCartItemDeliveryInfo = ({
    env,
    jwtToken,
    cartId,
    cartItemId,
    deliveryDate,
    zipCode,
    locationType,
    deliverySLA,
    calculateCartData,
    flexOptionId,
    holidayCode,
    deliveryDateType,
    deliverRangeStartDate,
    attributes,
    flexVariantId,
    flexOptionKey,
}) => {
    const envObj = env || {};

    envObj.rootUri = `${RESOURCE_ROOT_CARTSERVICE}${RESOURCE_VERSION_CARTSERVICE}`;

    const payload = {
        cartItemId,
        deliveryDate,
        zipCode,
        locationType,
        calculateCartData,
        flexOptionId,
        flexVariantId,
        flexOptionKey,
        holidayCode,
        deliverySLA,
        deliveryDateType,
        deliverRangeStartDate,
        attributes,
    };

    const resourcePage = `${RESOURCE_PAGE_CARTSERVICE}/${cartId}${RESOURCE_PAGE_ACTION}${RESOURCE_PAGE_DELIVERY_ACTION}`;

    return restClient.putRequest(envObj, resourcePage, jwtToken, payload)
        .then((response) => response);
};

export default updateCartItemDeliveryInfo;
