/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import createState from 'redux-create-state';
import { combineReducers } from 'redux';

import utils from '../../../../utils';
import * as brandActions from './Brand-Actions';

const INITIAL_STATE = {};

const byId = utils.createReducer(INITIAL_STATE)({
    [brandActions.brandLoaded().type]: (state, action) => createState(state || INITIAL_STATE, [action.id, action.payload]),
});

// const allIds = utils.createReducer(INITIAL_STATE)({
//     [brandActions.brandLoaded().type]: (state, action) => [...state, action.id],
// });

export default combineReducers({
    byId,
    // allIds,
});
