/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { fork, put, takeEvery } from 'redux-saga/effects';

import { triggerShowShippingInfo, updateShowShippingInfo } from './ShippingInfo-Actions';

function* updateShippingInfo() {
    yield put(updateShowShippingInfo());
}

function* updateShippingInfoWatcher() {
    yield takeEvery(triggerShowShippingInfo().type, updateShippingInfo);
}

const watchers = [
    fork(updateShippingInfoWatcher),
];

export { watchers };

export default {};
