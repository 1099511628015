/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import createState from 'redux-create-state';

import * as appActions from './App-Actions';

import utils from '../../utils';

const INITIAL_STATE_APP = {
    featureFlags: {},
};

const reducer = utils.createReducer(INITIAL_STATE_APP)({
    [appActions.setCheckoutFeatureFlag().type]: (state, action) => createState(state || {}, ['featureFlags', action.payload]),
});

export default reducer;
