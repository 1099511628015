/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, takeEvery, call, put, select,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';

import * as rewardPointsActions from './RewardPoints-Actions';
import * as commonSelectors from '../../../Common/Common-Selectors';
import * as showLoadingMessageActions from '../../../Common/ducks/ShowLoadingMessage/ShowLoadingMessage-Actions';
import { checkJWT } from '../../../Global/Global-Operations';
import { getFeatureFlags } from '../../../App/App-Selectors';
import cartServices from '../../../../../../../apis/cart-service-apis/cartServices';

const removeMaskForAccountNumber = (number) => {
    const strAccountNumber = number.toString();
    if (strAccountNumber.indexOf('-') >= 0) {
        return strAccountNumber.split('-').join('');
    }
    return strAccountNumber;
};

function* loadRewardEligibility() {
    try {
        const paymentData =  yield select(commonSelectors.getPaymentInfo);
        const featureFlags = yield select(getFeatureFlags);

        if (paymentData && paymentData.accountNumber && featureFlags['is-citi-rewards-enabled']) {
            yield put(rewardPointsActions.clearAllRewardPoints());

            const jwtToken = yield call(checkJWT);
            const orderId = yield select(commonSelectors.getOrderId);

            const configObj = {
                wcEnv: {},
                jwtToken,
                orderId,
                creditCard: removeMaskForAccountNumber(paymentData.accountNumber),
                isCardTokenized: (paymentData?.isFromWallet),
                sourceApp: '', // This is not needed for PWA App
            };
            const responseRE = yield call(cartServices.getRewardElegilibity, configObj);
            console.log('responseRE', responseRE.data);
            if (responseRE.status === 200) {
                yield put(rewardPointsActions.setRewardEligibility(responseRE.data));
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'loadRewardEligibility',
            message: 'Failed finding eligibility',
        });
    }
}

function* loadRewardEligibilityWatcher() {
    yield takeEvery(rewardPointsActions.loadRewardEligibility().type, loadRewardEligibility);
}

function* loadRewardPoints() {
    try {
        const paymentData =  yield select(commonSelectors.getPaymentInfo);
        const rewardPoints = yield select(commonSelectors.getRewardPointsInfo);
        const featureFlags = yield select(getFeatureFlags);
        if (
            featureFlags['is-citi-rewards-enabled']
            && paymentData?.accountNumber
            && (rewardPoints?.eligibility === 'Y' || rewardPoints?.eligibility === 'E')
        ) {
            yield put(showLoadingMessageActions.updateShowLoadingMessage());

            let rewardAction = 'ENABLE-REWARDS';
            if (rewardPoints?.eligibility === 'Y') {
                rewardAction = 'SHOW-REWARDS';
            }

            const jwtToken = yield call(checkJWT);
            const orderId = yield select(commonSelectors.getOrderId);

            const configObj = {
                wcEnv: {},
                jwtToken,
                cartId: orderId,
                creditCard: removeMaskForAccountNumber(paymentData.accountNumber),
                rewardAction,
                isCardTokenized: (paymentData?.isFromWallet),
                sourceApp: '', // This is not needed for PWA App
            };
            const responseRB = yield call(cartServices.getRewardBalance, configObj);

            if (responseRB.status === 200) {
                let dataBalance = responseRB.data;

                // If the object come empty throw an error
                if (!dataBalance || Object.keys(dataBalance).length === 0) {
                    dataBalance = { error: true };
                    mbpLogger.logError({
                        responseBalance: responseRB,
                        appName: process.env.npm_package_name,
                        module: 'mbp-pwa-ui',
                        function: 'loadRewardPoints',
                        message: 'Failed finding reward points balance',
                    });
                }

                yield put(rewardPointsActions.setRewardPoints(dataBalance));
            }

            yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        }
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'loadRewardPoints',
            message: 'Failed finding reward points balance',
        });

        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    }
}

function* loadRewardPointsWatcher() {
    yield takeEvery(rewardPointsActions.loadRewardPoints().type, loadRewardPoints);
}

const watchers = [
    fork(loadRewardPointsWatcher),
    fork(loadRewardEligibilityWatcher),
];

export { watchers };

export default {};
