/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from './restClient';

const RESOURCE_ROOT_CARTSERVICE = '/checkout';
const RESOURCE_VERSION_CARTSERVICE = '/v2';
const RESOURCE_PAGE_CARTSERVICE = '/cart';
const RESOURCE_PAGE_ACTIONSERVICE = '/greetingcard-items';

/**
 * addGreetingCard
 * url - /checkout/{apiversion}/cart/{cartId}/greetingcard-items?brand={brandId}
 *
 * @param {Object} args
 * @param {String} args.env
 * @param {String} args.jwtToken
 * @param {String} args.cartId
 * @param {String} args.cartItemId
 * @param {String} args.sku
 * @param {String} args.brandCode
 * @param {String} args.greetingCardKey
 * @param {String} args.greetingMessage
 * @param {String} args.greetingCardName
 * @param {String} args.greetingCardType
 * @param {String} args.greetingThumbNail
 */

const addGreetingCardItems = ({
    env,
    jwtToken,
    sku,
    brandCode,
    greetingMessage,
    greetingCardKey,
    greetingCardImage,
    greetingCardName,
    greetingCardType,
    greetingThumbNail,
    components,
    priceRules,
}) => {
    const envObj = env || {};

    envObj.rootUri = `${RESOURCE_ROOT_CARTSERVICE}${RESOURCE_VERSION_CARTSERVICE}`;
    const greetingCards = [{
        sku,
        brandCode,
        greetingMessage,
        greetingCardKey,
        greetingCardImage,
        greetingCardName,
        greetingCardType,
        greetingThumbNail,
        components,
    }];

    if (priceRules?.length > 0) {
        greetingCards[0].priceRules = priceRules;
    }
    const payload = {
        greetingCards,
    };

    const resourcePage = `${RESOURCE_PAGE_CARTSERVICE}${RESOURCE_PAGE_ACTIONSERVICE}`;

    return restClient.postRequest(envObj, resourcePage, jwtToken, payload)
        .then((response) => response);
};

export default addGreetingCardItems;
