/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import mbpLogger from 'mbp-logger';
import imagesConstantArray from '../AigoChatWidgetWrapperV2/chatIcons';

const EXTERNAL_TAB_KEYS = {
    GET_CONVERSATION: 'getConvId',
    SEND_CONVERSATION: 'sendConvId',
    SET_CONVERSATION: 'setConvId',
    NEW_MAIN_TAB_MESSAGE: 'newMainTabMessage',
    NEW_DUMMY_TAB_MESSAGE: 'newDummyTabMessage',
    MAIN_TAB_CLOSED: 'mainTabClosed',
    NEW_DUMMY_TAB_STATUS: 'newDummyTabStatus',
    NEW_MAIN_TAB_STATUS: 'newMainTabStatus',
    DUMMY_TAB_OPENED: 'dummyTabOpened',
    DUMMY_TAB_CLOSED: 'dummyTabClosed',
    ON_CLOSE_DIALOG: 'onCloseDialog',
    ON_TIMER_DIALOG: 'onTimerDialog',
    ON_SHOW_TIMER: 'onShowTimerDialog',
    ON_RESUME_TIMER: 'onResumeTimer',
    ON_AGENT_CLOSE: 'onAgentClose',
    ON_NOTIFICATION_SOUND: 'onNotificationSound',
};

/**
 * Check if another tab with same brandCode has a chat already opened
 * @param {string} brandCode
 * @param {any} featureFlags
 */
const externalTabChatCheck = (brandCode, featureFlags) => {
    sessionStorage.removeItem(EXTERNAL_TAB_KEYS.SET_CONVERSATION);
    if (featureFlags['is-persistent-chat-enabled']) {
        const { GET_CONVERSATION } = EXTERNAL_TAB_KEYS;
        localStorage.setItem(GET_CONVERSATION, JSON.stringify({ brandCode }));
        localStorage.removeItem(GET_CONVERSATION);
    }
};

/**
 * Send conversation for tabs of dummy chats
 * @param {any} event local storage event
 * @param {string} convID conversation id from the main tab
 * @param {string} brandCode
 * @param {string[]} mainTabMessages messages sent or received by the main tab
 */
const externalTabSendConversation = (event, convID, brandCode, mainTabMessages) => {
    const { GET_CONVERSATION, SEND_CONVERSATION, SET_CONVERSATION } = EXTERNAL_TAB_KEYS;
    const events = {
        [GET_CONVERSATION]: () => {
            // Dummy tab chat asked for the conversation -> send it
            const eventValue = JSON.parse(event?.newValue || '{}');
            if (convID && mainTabMessages.length && eventValue.brandCode === brandCode) {
                localStorage.setItem(SEND_CONVERSATION, JSON.stringify({ convId: convID, brandCode, messages: [...mainTabMessages] }));
                localStorage.removeItem(SEND_CONVERSATION);
            }
        },
        [SEND_CONVERSATION]: () => {
            // Main tab sent the conversation <- set it
            const eventValue = JSON.parse(event?.newValue || '{}');
            if (event?.newValue && eventValue?.brandCode === brandCode) {
                sessionStorage.setItem(SET_CONVERSATION, event.newValue);
            }
        },
    };
    events[event?.key]?.();
};

/**
* Handle new message from external tab
* @param {string} message message to forward
* @param {func} setMessages add new messages into current tab messages
* @param {func} onDummyMessageSendToSocket forward message to the bot/agent
* @param {bool} isFromDummyTab if is, forward message to agent/bot
* @param {string} currentDummyTabId to know if we are on a dummyTab and receiving message from another dummyTab
* @param {func} setStartTimer
* @param {string} conversationId if main tab contains conversationId
*/
const newMessage = (message, setMessages, onDummyMessageSendToSocket, isFromDummyTab, currentDummyTabId, setStartTimer, conversationId) => {
    const { response, listPickerMessageToAlter } = typeof message === 'string' ? JSON.parse(message || '{}') : {};
    setStartTimer(true);
    if (listPickerMessageToAlter) {
        setMessages((oldMessages) => oldMessages.map((element) => {
            try {
                if (JSON.stringify(element) === JSON.stringify(listPickerMessageToAlter)) {
                    const getSVGByKey = (keyTofind) => imagesConstantArray.find((image) => image?.imageKey?.toLowerCase() === keyTofind?.toLowerCase());
                    const images = JSON.parse(listPickerMessageToAlter.responseMessage?.o)?.interactiveData?.data?.images;
                    const dynamicImageObject = images?.find((imgObj) => imgObj?.identifier === response?.selectedIdentifier);
                    const dynamicIcon = dynamicImageObject?.data?.includes('https')
                        ? dynamicImageObject?.data
                        : getSVGByKey(dynamicImageObject?.data?.split('/')[2] || dynamicImageObject?.data)?.imageValue;
                    return { ...element, disable: true, selectedItem: { ...response, selectedImage: dynamicIcon } };
                }
            } catch (e) {
                mbpLogger.logError({
                    appName: process.env.npm_package_name,
                    function: 'multipleTabsChat.newMessage',
                    message: `Webchat: Could not process listpicker message: ${e} - convid: ${conversationId}`,
                });
            }
            return element;
        },
        ));
        if (isFromDummyTab && (!currentDummyTabId || conversationId)) onDummyMessageSendToSocket(response);
    } else {
        setMessages((oldMessages) => [...oldMessages, message]);
        if (isFromDummyTab && (!currentDummyTabId || conversationId)) onDummyMessageSendToSocket(message.message);
    }
};

/**
* Handle new status from external tab
* @param {string} status status received
* @param {string} value value received
* @param {func} handleStateChangeForStatusOnWebsocketMessage
* @param {func} setMainTabClosed
* @param {func} handleExternalTabCloseDialog
* @param {func} handleExternalTabTimerDialog
* @param {func} setShowTimer
* @param {func} setStartTimer
* @param {func} handleExternalTabAgentEndsConv
* @param {func} setShouldTurnNotificationOff
*/
const newMainTabStatus = (status, value, handleStateChangeForStatusOnWebsocketMessage, setMainTabClosed, handleExternalTabCloseDialog, handleExternalTabTimerDialog, setShowTimer, setStartTimer, handleExternalTabAgentEndsConv, setShouldTurnNotificationOff) => {
    const {
        MAIN_TAB_CLOSED, ON_CLOSE_DIALOG, ON_TIMER_DIALOG, ON_SHOW_TIMER, ON_RESUME_TIMER, ON_AGENT_CLOSE, ON_NOTIFICATION_SOUND,
    } = EXTERNAL_TAB_KEYS;
    if (status === 'genesys-conversation-close') {
        handleStateChangeForStatusOnWebsocketMessage('genesys-conversation-close', true);
    } else if (status === MAIN_TAB_CLOSED) {
        setMainTabClosed(true);
    } else if (status === ON_CLOSE_DIALOG) {
        handleExternalTabCloseDialog();
    } else if (status === ON_TIMER_DIALOG) {
        handleExternalTabTimerDialog();
    } else if (status === ON_AGENT_CLOSE) {
        handleExternalTabAgentEndsConv();
        setShowTimer(false);
        setStartTimer(false);
    } else if (status === ON_SHOW_TIMER) {
        setShowTimer(true);
        setStartTimer(true);
    } else if (status === ON_RESUME_TIMER) {
        setShowTimer(false);
        setStartTimer(false);
    } else if (status === ON_NOTIFICATION_SOUND) {
        setShouldTurnNotificationOff(value);
    }
};

/**
* Handle new status from external tab
* @param {string} status status received
* @param {string} value value received
* @param {string} conversationId
* @param {func} setDummyTabOpenedOrClosed
* @param {string} dummyTabId
* @param {func} handleExternalTabCloseDialog
* @param {func} setShowTimer
* @param {func} setStartTimer
* @param {func} setShouldTurnNotificationOff
*/
const newDummyTabStatus = (status, value, conversationId, setDummyTabOpenedOrClosed, dummyTabId, handleExternalTabCloseDialog, setShowTimer, setStartTimer, setShouldTurnNotificationOff) => {
    const {
        DUMMY_TAB_OPENED, DUMMY_TAB_CLOSED, ON_CLOSE_DIALOG, ON_RESUME_TIMER, ON_NOTIFICATION_SOUND,
    } = EXTERNAL_TAB_KEYS;
    if (status === DUMMY_TAB_OPENED || status === DUMMY_TAB_CLOSED) {
        setDummyTabOpenedOrClosed(conversationId, dummyTabId, status);
    } else if (status === ON_CLOSE_DIALOG) {
        handleExternalTabCloseDialog();
    } else if (status === ON_RESUME_TIMER) {
        setShowTimer(false);
        setStartTimer(true);
    } else if (status === ON_NOTIFICATION_SOUND) {
        setShouldTurnNotificationOff(value);
    }
};

/**
 * Send messages/events of external tabs
 * @param {any} event local storage event
 * @param {string} conversationId
 * @param {func} setMessages add new messages into current tab messages
 * @param {func} setMainTabClosed main tab closed,change the state
 * @param {func} onDummyMessageSendToSocket forward message to the bot/agent
 * @param {any} featureFlags
 * @param {string} currentDummyTabId to know if we are on a dummyTab and receiving message from another dummyTab
 * @param {bool} isMainTab
 */
const externalTabNewMessage = (
    event,
    conversationId,
    setMessages,
    setMainTabClosed,
    onDummyMessageSendToSocket,
    handleStateChangeForStatusOnWebsocketMessage,
    setDummyTabOpenedOrClosed,
    featureFlags,
    currentDummyTabId,
    handleExternalTabCloseDialog,
    handleExternalTabTimerDialog,
    setStartTimer,
    setShowTimer,
    handleExternalTabAgentEndsConv,
    setShouldTurnNotificationOff,
    isMainTab,
) => {
    if (featureFlags['is-persistent-chat-enabled']) {
        const {
            NEW_MAIN_TAB_MESSAGE, NEW_DUMMY_TAB_MESSAGE, NEW_MAIN_TAB_STATUS, NEW_DUMMY_TAB_STATUS,
        } = EXTERNAL_TAB_KEYS;
        const events = {
            [NEW_MAIN_TAB_MESSAGE]: () => {
                const { convId = '', message = {} } = JSON.parse(event?.newValue || '{}');
                if (!isMainTab && conversationId === convId) {
                    newMessage(message, setMessages, onDummyMessageSendToSocket, false, currentDummyTabId, setStartTimer);
                }
                localStorage.removeItem(NEW_MAIN_TAB_MESSAGE);
            },
            [NEW_DUMMY_TAB_MESSAGE]: () => {
                const { convId = '', message = {}, dummyTabId = {} } = JSON.parse(event?.newValue || '{}');
                if ((isMainTab || dummyTabId !== currentDummyTabId) && conversationId === convId) {
                    newMessage(message, setMessages, onDummyMessageSendToSocket, true, currentDummyTabId, setStartTimer, conversationId);
                }
                localStorage.removeItem(NEW_DUMMY_TAB_MESSAGE);
            },
            [NEW_MAIN_TAB_STATUS]: () => {
                const { convId = '', status = '', value = '' } = JSON.parse(event?.newValue || '{}');
                if ((!isMainTab || status === 'genesys-conversation-close') && conversationId === convId) {
                    let dummyTabs = JSON.parse(localStorage.getItem('currentDummyTabs'));
                    if (dummyTabs[0] === localStorage.getItem('nextMainTab') && status === 'mainTabClosed') {
                        dummyTabs = dummyTabs?.filter((dummyTab) => dummyTab !== localStorage.getItem('nextMainTab'));
                        localStorage.setItem('currentDummyTabs', JSON.stringify(dummyTabs));
                        newMainTabStatus(status, value, handleStateChangeForStatusOnWebsocketMessage, setMainTabClosed, handleExternalTabCloseDialog, handleExternalTabTimerDialog, setShowTimer, setStartTimer, handleExternalTabAgentEndsConv, setShouldTurnNotificationOff);
                    }
                }
                localStorage.removeItem(NEW_MAIN_TAB_STATUS);
            },
            [NEW_DUMMY_TAB_STATUS]: () => {
                const {
                    convId = '', status = '', dummyTabId = '', value = '',
                } = JSON.parse(event?.newValue || '{}');
                if ((isMainTab || dummyTabId !== currentDummyTabId) && conversationId === convId) {
                    if ((localStorage.getItem('currentDummyTabs') === null || localStorage.getItem('currentDummyTabs') === []) && status === 'dummyTabOpened') {
                        localStorage.setItem('currentDummyTabs', JSON.stringify([dummyTabId]));
                        localStorage.setItem('nextMainTab', dummyTabId);
                    } else if (localStorage.getItem('currentDummyTabs') && status === 'dummyTabOpened') {
                        const dummyTabs = JSON.parse(localStorage.getItem('currentDummyTabs'));
                        if (!dummyTabs?.includes(dummyTabId))dummyTabs?.push(dummyTabId);
                        localStorage.setItem('currentDummyTabs', JSON.stringify(dummyTabs));
                    }
                    newDummyTabStatus(status, value, conversationId, setDummyTabOpenedOrClosed, dummyTabId, handleExternalTabCloseDialog, setShowTimer, setStartTimer, setShouldTurnNotificationOff);
                }
                localStorage.removeItem(NEW_DUMMY_TAB_STATUS);
            },
        };
        events[event?.key]?.();
    }
};

/**
 * Send messages to an external tab
 * @param {any} message message to share
 * @param {string} convId conversationId
 * @param {boolean} isFromMainTab
 * @param {string} dummyTabId
 * @param {amy} featureFlags
 */
const sendMessageToExternalTab = (message, convId, isFromMainTab, dummyTabId, featureFlags) => {
    if (featureFlags['is-persistent-chat-enabled']) {
        const { NEW_MAIN_TAB_MESSAGE, NEW_DUMMY_TAB_MESSAGE } = EXTERNAL_TAB_KEYS;
        if (isFromMainTab) {
            localStorage.setItem(NEW_MAIN_TAB_MESSAGE, JSON.stringify({ convId, message, dummyTabId }));
        } else {
            localStorage.setItem(NEW_DUMMY_TAB_MESSAGE, JSON.stringify({ convId, message, dummyTabId }));
        }
    }
};

/**
 * Send events to an external tab
 * @param {string} status status to share
 * @param {string} convId conversationId
 * @param {boolean} isFromMainTab
 * @param {string} dummyTabId
 * @param {string} value in case of the event requires to send a value
 * @param {any} featureFlags
 */
const sendStatusToExternalTab = (status, convId, isFromMainTab, dummyTabId, value, featureFlags) => {
    if (featureFlags['is-persistent-chat-enabled']) {
        const { NEW_DUMMY_TAB_STATUS, NEW_MAIN_TAB_STATUS } = EXTERNAL_TAB_KEYS;
        if (isFromMainTab) {
            localStorage.setItem(NEW_MAIN_TAB_STATUS, JSON.stringify({
                convId, status, dummyTabId, value,
            }));
        } else {
            localStorage.setItem(NEW_DUMMY_TAB_STATUS, JSON.stringify({
                convId, status, dummyTabId, value,
            }));
        }
    }
};

const checkForAnotherTabTimeOut = async () => new Promise((resolve) => {
    setTimeout(resolve, 1000);
});

export {
    externalTabChatCheck, externalTabSendConversation, externalTabNewMessage, sendMessageToExternalTab, sendStatusToExternalTab, checkForAnotherTabTimeOut, EXTERNAL_TAB_KEYS,
};
