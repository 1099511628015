/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpLogger from 'mbp-logger';

let counter = 0;
const scriptMap = new Map();

export const ScriptCache = (function exec(globalObj) {
    const global = { ...globalObj };

    return (scripts) => {
        const Cache = {};

        Cache.onLoad = (key) => (cb) => {
            const stored = scriptMap.get(key);
            if (stored) {
                stored.promise.then(() => {
                    if (stored.error) {
                        cb(stored.error);
                    } else {
                        cb(null, stored);
                    }
                });
            } else {
                mbpLogger.logWarning({
                    globalObj,
                    cacheOnLoadKey: key,
                    function: 'ScriptCache',
                    appName: process.env.npm_package_name,
                    module: 'mbp-tag-managment',
                    message: 'No script cached',
                });
            }
        };

        Cache.scriptTag = (key, source) => {
            const src = source;
            if (!scriptMap.has(key)) {
                if (typeof document !== 'undefined') {
                    const tag = document.createElement('script');
                    const promise = new Promise((resolve, reject) => {
                        // let resolved = false,
                        //     errored = false,
                        const body = document.getElementsByTagName('body')[0];

                        tag.type = 'text/javascript';
                        tag.defer = true;

                        const cbName = `loaderCB${counter += 1}${Date.now()}`;
                        // let cb;

                        const cleanup = () => {
                            if (global[cbName] && typeof global[cbName] === 'function') {
                                global[cbName] = null;
                            }
                        };

                        const handleResult = (state) => (evt) => {
                            const stored = scriptMap.get(key);

                            if (state === 'loaded') {
                                stored.resolved = true;
                                resolve(src);
                            } else if (state === 'error') {
                                stored.errored = true;
                                reject(evt);
                            }

                            cleanup();
                        };

                        tag.onload = handleResult('loaded');
                        tag.onerror = handleResult('error');
                        tag.onreadystatechange = () => {
                            handleResult(tag.readyState);
                        };

                        if (src.match(/callback=CALLBACK_NAME/)) {
                            // src = src.replace(/(callback=)[^\&]+/, `$1${cbName}`);
                            // cb = window[cbName] = tag.onload;
                        } else {
                            tag.addEventListener('load', tag.onload);
                        }

                        tag.addEventListener('error', tag.onerror);

                        tag.src = src;
                        body.appendChild(tag);

                        return tag;
                    });

                    const initialState = {
                        loaded: false,
                        error: false,
                        promise,
                        tag,
                    };

                    scriptMap.set(key, initialState);
                }
            }

            return scriptMap.get(key);
        };

        Object.keys(scripts).forEach((key) => {
            const script = scripts[key];
            Cache[key] = {
                tag: Cache.scriptTag(key, script),
                onLoad: Cache.onLoad(key),
            };
        });

        return Cache;
    };
}((typeof window !== 'undefined') ? window : {}));

export default ScriptCache;
