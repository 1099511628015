/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useRef, useState } from 'react';
import { object, string } from 'prop-types';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';

const PassportVideo = ({ classes, video, presentationFamily }) => {
    const videoRef = useRef(null);
    const [playing, setPlaying] = useState(undefined);
    const [showButton, setShowButton] = useState(true);

    useEffect(() => {
        if (typeof playing !== 'undefined') {
            setTimeout(() => {
                setShowButton(false);
            }, 2000);
        }
    }, [playing]);

    // it controls the video play/pause logic
    const handleVideo = (e) => {
        e.preventDefault();
        setShowButton(true);
        if (videoRef.current.paused) {
            setPlaying(true);
            videoRef.current.play();
        } else {
            setPlaying(false);
            videoRef.current.pause();
        }
    };

    // it handles button show/hide logic
    const handleDisplayButton = (status) => {
        if (typeof status !== 'undefined') setShowButton(status);
        else if (videoRef.current.currentTime > 0 && !videoRef.current.paused && videoRef.current.currentTime !== videoRef.current.duration) {
            setPlaying(true);
            setShowButton(true);
        } else {
            setPlaying(false);
            setShowButton(true);
        }
    };

    // food_video_url and flower_video_url are backup for CDN use, in case we don't wanna use CMS assets
    const renderVideo = () => {
        if (presentationFamily === 'food' && video?.food) {
            return <source src={video?.food_video_url || video?.food?.url} type="video/mp4" preload="metadata" />;
        } if (presentationFamily === 'flower' && video?.flower) {
            return <source src={video?.flower_video_url || video?.flower?.url} type="video/mp4" preload="metadata" />;
        }
        return null;
    };

    let posterUrl;
    if (presentationFamily === 'food' && video?.food_video_poster?.url) posterUrl = video.food_video_poster.url;
    if (presentationFamily === 'flower' && video?.flower_video_poster?.url) posterUrl = video.flower_video_poster.url;

    const createVideoID = () => {
        const src = video?.food?.url || video?.flower?.url || 'Video';
        /**
         * turn https://assets.contentstack.io/v3/assets/blt89dbf1c763ec00a6/blt39521815bb9e56cb/6511b146474d4d76f24f2c43/23_025_001_HD_PearTabletop-A15_1400x584_G0_M0_A1.mp4
         * into 23_025_001_HD_PearTabletop-A15_1400x584_G0_M0_A1
         * */
        const id = src.split('/')?.pop()?.split('.')[0] || 'ComponentVideo';
        return id;
    };

    return (
        <div role="presentation" className={classes.passportVideo} onClick={handleVideo} onMouseEnter={() => handleDisplayButton(true)} onMouseLeave={() => handleDisplayButton(false)} onMouseMove={() => handleDisplayButton()}>
            <video ref={videoRef} width="100%" height="100%" id={`video_${createVideoID()}`} controlsList="nodownload" poster={posterUrl}>
                {renderVideo()}
                <track kind="captions" />
            </video>
            {showButton && (playing ? <PauseCircleFilledIcon className={classes.customControls} aria-controls={`video_${createVideoID()}`} />
                : <PlayCircleFilledIcon className={classes.customControls} aria-controls={`video_${createVideoID()}`} />)}
        </div>
    );
};

export default PassportVideo;

PassportVideo.propTypes = {
    video: object.isRequired,
    classes: object.isRequired,
    presentationFamily: string.isRequired,
};
