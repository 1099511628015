/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
    arrayOf,
    shape,
    string,
    object,
} from 'prop-types';

import { getBrand } from '../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';

function GraphqlBreadcrumbSeoSchema({ breadCrumbArray, brand }) {
    if (breadCrumbArray?.length <= 0) {
        return <></>;
    }
    return (
        <Helmet>
            <script type="application/ld+json">
                {`
                    {
                        "@context": "http://schema.org",
                        "@type": "BreadcrumbList",
                        "itemListElement": [
                            ${breadCrumbArray ? breadCrumbArray.map((d, i) => (`{
                                "@type": "ListItem",
                                "position": ${i + 1},
                                "name": "${d.title.replace(/"/g, '')}",
                                "item": "https://www.${brand.domain}.com${d.href}"
                            }`)) : null}
                        ]
                    }
                `}
            </script>
        </Helmet>
    );
}

GraphqlBreadcrumbSeoSchema.propTypes = {
    breadCrumbArray: arrayOf(
        shape({
            title: string.isRequired,
            href: string.isRequired,
        }),
    ).isRequired,
    brand: object.isRequired,
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
});

export default connect(mapStateToProps)(GraphqlBreadcrumbSeoSchema);
