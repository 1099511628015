/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { getFeatureFlags } from '../App/ducks/Config/Config-Selectors';

export const getAddToCartLoaderStatus = (state) => state.addToCart.loader;
export const getAddToCartError = (state) => state.addToCart.error;
export const getAddToCartErrorList = (state) => state.addToCart.errorList;
export const getAddToCartItemAdded = (state) => state.addToCart.itemAdded;
export const getAddToCartGiftMessage = (state) => state.addToCart.giftMessage;

const getLastAddedProductExcludePassportAndDonation = (state) => {
    const featureFlags = getFeatureFlags(state);

    if (state.cart?.recipients) {
        const { recipients } = state.cart;
        const arrRegularItems = recipients.filter((items) => (
            items.cartItems.filter((cartItems) => !cartItems.product.isPassport && cartItems.itemInfo?.lineItemType !== 'DONATION').length !== 0
        ));

        if (featureFlags['is-cart-reverse-enabled']) {
            return arrRegularItems[arrRegularItems.length - 1] || {};
        }

        return arrRegularItems[0] || {};
    }

    return {};
};

export const getLastAddedOrderItemDetails = (state) => {
    const featureFlags = getFeatureFlags(state);
    const orderItemDetails = getLastAddedProductExcludePassportAndDonation(state);

    const indexOfLastItem = orderItemDetails?.cartItems?.length || 0;

    if (featureFlags['is-cart-reverse-enabled']) {
        return orderItemDetails?.cartItems?.[indexOfLastItem - 1] || {};
    }

    return orderItemDetails?.cartItems?.[0] || {};
};
