/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    call,
    select,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import { getOrderId } from './Global-Selectors';
import * as auth from '../../../Member/ducks/Auth/Plugin/auth/auth';

export function* checkJWT() {
    let JWT_TOKEN = '';

    const logOrderId = yield select(getOrderId);

    try {
        JWT_TOKEN = yield call(auth.getAccessTokenSafely);
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'Auth-Operations.js',
            function: 'checkJWT',
            jsError: ex,
            message: 'checkJWT Failed',
            orderId: logOrderId,
        });
    }

    return JWT_TOKEN;
}

export default {};
