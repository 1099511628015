/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import createState from 'redux-create-state';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import createReducer from '../../utils/createReducer';
import * as SCIActions from './SCI-Actions';

const SCI_INITIAL_STATE = {
    sci_campaign_id: '',
    sci_loc_number: '',
    sci_disposition_type: '',
    sciIsLoading: false,
    sci_decedent_data: {},
};

const SCIPersistConfig = {
    key: 'sci',
    storage: sessionStorage,
    whiteList: [
        'sci',
    ],
};

const sci = persistReducer(SCIPersistConfig, createReducer(SCI_INITIAL_STATE)({
    [SCIActions.setSCICampaign().type]: (state, action) => createState(state || SCI_INITIAL_STATE, ['sci_campaign_id', action.payload]),
    [SCIActions.setSCILocNumber().type]: (state, action) => createState(state || SCI_INITIAL_STATE, ['sci_loc_number', action.payload]),
    [SCIActions.setSCIDispositionType().type]: (state, action) => createState(state || SCI_INITIAL_STATE, ['sci_disposition_type', action.payload]),
    [SCIActions.clearSCICampaign().type]: (state) => createState(state || SCI_INITIAL_STATE, ['sci_campaign_id', '']),
    [SCIActions.clearSCILocNumber().type]: (state) => createState(state || SCI_INITIAL_STATE, ['sci_loc_number', '']),
    [SCIActions.clearSCIDispositionType().type]: (state) => createState(state || SCI_INITIAL_STATE, ['sci_disposition_type', '']),
    [SCIActions.setSCILoadingState().type]: (state, action) => createState(state || SCI_INITIAL_STATE, ['sciIsLoading', action.payload]),
    [SCIActions.setSCIDecedent().type]: (state, action) => createState(state || SCI_INITIAL_STATE, ['sci_decedent_data', action.payload]),
}));

export default sci;
