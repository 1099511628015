/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { bool, func, object } from 'prop-types';

const YotpoStaticReferralBanner = ({
    bannerInfo, classes, isMobile, loginByRedirect,
}) => {
    const banner = !isMobile
        ? (bannerInfo?.desktop_banner?.url
    && bannerInfo?.desktop_banner?.title)
    && bannerInfo?.desktop_banner
        : (bannerInfo?.mobile_banner?.url
    && bannerInfo?.mobile_banner?.title)
    && bannerInfo?.mobile_banner;

    const bannerStyling = !isMobile ? {
        backgroundColor: bannerInfo?.background_color,
        cursor: bannerInfo?.enable_login && 'pointer',
        marginTop: '50px',
    } : {
        marginTop: '50px',
    };
    let desktopStyle;
    if (bannerInfo?.use_default_banner_size) desktopStyle = classes.fullWidth;
    else desktopStyle = classes.image;

    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    const renderEventBanner = () => (
        <img onClick={bannerInfo?.enable_login && loginByRedirect} className={!isMobile ? desktopStyle : classes.imageMobile} src={banner?.url} alt={banner?.description || 'Yotpo Static Banner'} />
    );

    return (
        <div className={`${classes.bannerContainer} ${classes.placeholderBanner}`} style={bannerStyling}>
            {renderEventBanner()}
        </div>
    );
};

YotpoStaticReferralBanner.propTypes = {
    bannerInfo: object,
    classes: object,
    isMobile: bool,
    loginByRedirect: func.isRequired,
};

YotpoStaticReferralBanner.defaultProps = {
    bannerInfo: {},
    classes: {},
    isMobile: false,
};

export default YotpoStaticReferralBanner;
