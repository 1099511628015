/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import initialState from '../../../../initialState';

import * as actions from './RewardPoints-Actions';

const rewardPointsReducer = (state = initialState.payment.rewardPoints, action) => {
    switch (action.type) {
        case actions.clearAllRewardPoints().type:
            return initialState.payment.rewardPoints;
        case actions.setRewardEligibility().type:
            return {
                ...state,
                eligibility: action.data.isEligible || false,
            };
        case actions.setRewardPoints().type:
            return {
                ...state,
                balance: action.data,
            };
        case actions.setSelectedPointsReward().type:
            return {
                ...state,
                selected: {
                    amount: action.data.amount,
                    points: action.data.points,
                },
            };
        case actions.clearSelectedRewardPoints().type:
            return {
                ...state,
                selected: {
                    amount: '',
                    points: '',
                },
            };
        default:
            return state;
    }
};

export default rewardPointsReducer;
