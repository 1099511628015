/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, put, takeEvery,
} from 'redux-saga/effects';

import {
    addCartRecipient,
    updateRecipients,
    updateWorkingRecipient,
    changeWorkingRecipient,
} from './Recipient-Actions';

function* loadRecipient(action) {
    const recipient = action.data;
    yield put(updateRecipients(recipient));
}

function* onChangeWorkingRecipient() {
    try {
        yield put(updateWorkingRecipient());
    } catch (e) {
        console.log(e);
    }
}

function* watchChangeWorkingRecipient() {
    yield takeEvery(changeWorkingRecipient().type, onChangeWorkingRecipient);
}

function* loadRecipientWatcher() {
    yield takeEvery(addCartRecipient().type, loadRecipient);
}

const watchers = [
    fork(loadRecipientWatcher),
    fork(watchChangeWorkingRecipient),
];

export {
    watchers,
};

export default {};
