/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    takeLatest, call, put, fork, select, cancel,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
// apis
import { createEnterpriseIdRestCall } from '../../../../../apis/enterpriseid-apis/createEnterpriseIdRestCall';
import { updateIdentifiersEnterpriseIdRestCall } from '../../../../../apis/enterpriseid-apis/updateIdentifiersEnterpriseIdRestCall';
// redux
import * as helper from '../Auth/helper/helper';
import * as enterpriseIdActions from './EnterpriseId-Actions';
import { logUserLoggedInSuccess } from '../Auth/Auth-Actions';
import { getIsBot } from '../../../App/App-Selectors';
import { emitCustomerIdentificationEvent } from '../../../TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import { generateCustomerIdentifiers, handleEIDResponse } from './EnterpriseId-Helpers';

export function* workerSagaCreateEnterpiseId(payload) {
    try {
        yield put(enterpriseIdActions.enterpriseIdLoading());
        // Do not assign EID to bots
        const isBot = yield select(getIsBot);
        if (isBot) {
            return;
        }

        // Try local storage value
        const localStorageValueOfEnterpriseId = yield call(helper.getTokenLocalStorage, 'enterpriseId');
        if (localStorageValueOfEnterpriseId) {
            yield put(enterpriseIdActions.enterpriseIdLoaded(localStorageValueOfEnterpriseId));
            try {
                const { identifiers, jwtToken } = yield call(generateCustomerIdentifiers, payload);
                const response = yield call(createEnterpriseIdRestCall, jwtToken, identifiers);
                if (response?.enterpriseId) {
                    yield call(handleEIDResponse, response);
                    return;
                }
                throw new Error('Invalid enterprise id');
            } catch (ex) {
                mbpLogger.logError({
                    function: 'workerSagaCreateEnterpiseId -> POST for new EID',
                    appName: process.env.npm_package_name,
                    module: 'EnterpriseId',
                    message: 'Failed to load EID from POST route creation',
                    jsError: ex,
                });
                yield put(enterpriseIdActions.enterpriseIdDisableLoading());
            }
        }

        // POST - no token in local
        try {
            const { identifiers, jwtToken } = yield call(generateCustomerIdentifiers, payload);
            const response = yield call(createEnterpriseIdRestCall, jwtToken, identifiers);
            if (response?.enterpriseId) {
                yield call(handleEIDResponse, response);
            } else {
                throw new Error('Invalid enterprise id');
            }
        } catch (ex) {
            mbpLogger.logError({
                function: 'workerSagaCreateEnterpiseId -> POST for new EID',
                appName: process.env.npm_package_name,
                module: 'EnterpriseId',
                message: 'Failed to load EID from POST route creation',
                jsError: ex,
            });
            yield put(enterpriseIdActions.enterpriseIdDisableLoading());
        }
    } catch (ex) {
        mbpLogger.logError({
            function: 'workerSagaCreateEnterpiseId -> Unhandled Error',
            appName: process.env.npm_package_name,
            module: 'EnterpriseId',
            message: 'Failed to execute saga',
            jsError: ex,
        });
        yield put(enterpriseIdActions.enterpriseIdDisableLoading());
    }
}

function* watcherCreateEnterpiseId() {
    yield takeLatest(enterpriseIdActions.requestEnterpriseId().type, workerSagaCreateEnterpiseId);
}

export function* workerSagaUpdateEnterpriseId(payload) {
    try {
        yield put(enterpriseIdActions.enterpriseIdLoading());
        // Do not allow bots to update EID
        const isBot = yield select(getIsBot);
        if (isBot) {
            yield cancel();
        }
        // Update localStorage value from PATCH route
        const localStorageValueOfEnterpriseId = yield call(helper.getTokenLocalStorage, 'enterpriseId');
        if (localStorageValueOfEnterpriseId) {
            try {
                const { identifiers, jwtToken } = yield call(generateCustomerIdentifiers, payload);
                const response = yield call(updateIdentifiersEnterpriseIdRestCall, jwtToken, identifiers, localStorageValueOfEnterpriseId);

                if (response?.enterpriseId) {
                    yield call(handleEIDResponse, response);
                    yield put(emitCustomerIdentificationEvent({ ...payload?.data }));
                } else {
                    throw new Error('Invalid enterprise id');
                }
            } catch (ex) {
                mbpLogger.logError({
                    function: 'workerSagaUpdateEnterpriseId -> Error updating EID',
                    appName: process.env.npm_package_name,
                    module: 'EnterpriseId',
                    message: 'Failed to update EID',
                    jsError: ex,
                });
                yield put(enterpriseIdActions.enterpriseIdDisableLoading());
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            function: 'workerSagaUpdateEnterpriseId -> Unhandled Error',
            appName: process.env.npm_package_name,
            module: 'EnterpriseId',
            message: 'Failed to execute saga',
            jsError: ex,
        });
        yield put(enterpriseIdActions.enterpriseIdDisableLoading());
    }
}

export function* watcherSagaUpdateEnterpriseId() {
    yield takeLatest([
        // sign in events
        enterpriseIdActions.updateOnSignInEnterpriseId().type,
        logUserLoggedInSuccess().type,
        // order events
        enterpriseIdActions.requestToUpdateEnterpiseId().type,
    ], workerSagaUpdateEnterpriseId);
}

const watchers = [
    fork(watcherCreateEnterpiseId),
    fork(watcherSagaUpdateEnterpriseId),
];

const workers = {
    workerSagaUpdateEnterpriseId,
    workerSagaCreateEnterpiseId,
};

export default {
    watchers,
    workers,
};
