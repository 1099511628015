/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { createSelector } from 'reselect';
import mbpLogger from 'mbp-logger';

export const getUrlById = (state) => state.app.brand.byId;
export const getLocation = (state) => state.router.location;
export const getCurrentBrandId = (state) => state.ui.brandId;

/**
 * @param {} state
 */
export const getBrandById = (state, id) => {
    if (state && state.app && state.app.brand && state.app.brand.byId) {
        return state.app.brand.byId[id];
    }
    mbpLogger.logError({
        state,
        function: 'getBrandById',
        appName: process.env.npm_package_name,
        module: 'mbp-app',
        message: 'state.app.brand.byId not defined in getBrandById',
    });
    return null;
};

/**
 * @param {} state
 */
export const getBrandsById = (state) => {
    if (state && state.app && state.app.brand && state.app.brand.byId) {
        if (typeof sessionStorage !== 'undefined') { // TODO: Why?
            sessionStorage.setItem(
                'persist:page',
                JSON.stringify({ brands: JSON.stringify({ byId: state.app.brand.byId }) }),
            );
        }

        return state.app.brand.byId;
    }
    mbpLogger.logError({
        state,
        function: 'getBrandById',
        appName: process.env.npm_package_name,
        module: 'mbp-app',
        message: 'state.app.brand.byId not defined in getBrandsById',
    });
    return null;
};

export const getBrand = createSelector(
    [getCurrentBrandId, getBrandsById],
    (currentBrandId, brandsById) => {
        if (currentBrandId && brandsById[currentBrandId]) {
            return brandsById[currentBrandId];
        }

        return null;
    },
);

export const getBrandIdentifier = createSelector(
    [getCurrentBrandId, getBrandsById],
    (currentBrandId, brandsById) => {
        if (currentBrandId && brandsById[currentBrandId]) {
            return brandsById[currentBrandId]['identifier'];
        }

        return null;
    },
);

export const getBrandName = createSelector(
    [getBrand],
    (brand) => {
        if (brand) {
            return brand['domain-name'];
        }
        return '1800flowers';
    },
);

export default {
    getBrandById,
    getUrlById,
    getLocation,
    getCurrentBrandId,
};
