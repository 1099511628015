/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    loadVenmoClientId,
    clearVenmoClientId,
} from './Venmo-Actions';
import initialState from '../../../../initialState';

const venmoReducer = (state = initialState.payment.venmo, action) => {
    switch (action.type) {
        case loadVenmoClientId().type:
            return {
                ...state,
                clientToken: action.data,
            };
        case clearVenmoClientId().type:
            return {
                ...state,
                clientToken: '',
            };
        default:
            return state;
    }
};

export default venmoReducer;
