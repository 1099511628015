/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';

import profileReducer from './ducks/Profile';
import authReducer from './ducks/Auth';
import shopRunnerReducer from './ducks/Shoprunner';
import addressBookReducer from './ducks/AddressBook';
import walletReducer from  './ducks/Wallet';
import abTestingReducer from './ducks/ABTesting';
import enterpriseIdReducer from './ducks/EnterpriseId';

const memberPersistConfig = {
    key: 'member',
    storage: sessionStorage,
    blacklist: [],
};

const memberReducer = combineReducers({
    profile: profileReducer,
    auth: authReducer,
    shopRunner: shopRunnerReducer,
    addressBook: addressBookReducer,
    wallet: walletReducer,
    abTesting: abTestingReducer,
    enterpriseId: enterpriseIdReducer,
});

export default persistReducer(memberPersistConfig, memberReducer);
