/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { END } from 'redux-saga';
import {
    fork, take, call, put, select,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';

import { duck as tmDuck } from '../../../TagManager';
import * as actionTypes from './Error-ActionTypes';
import { loadError, loggedErrorKeys } from './Error-Actions';
import ErrCodeFieldMapping from './constant/ErrCodeMap';
import { ErrTextMapping, ErrMessageForErrorKeyMapping } from './constant/ErrTextMap';
import { getCustomerCare, getOrderItemIds } from '../../Common-Selectors';

export const arrErrorCodes = [401, 403, 404, 500, 501, 503, 504];

function* GenericErrorMessage() {
    const phoneNo = yield select(getCustomerCare);
    const contactLink = `<a style="color:#f44336" href="tel:'${phoneNo}'">${phoneNo}</a>`;
    return `We are sorry, there seems to be a technical issue, please try again later or call our customer service (${contactLink}) for help. We're here for you!`;
}

const getErrorCodeFieldMapping = (arrError, groupKey, prefixStr, orderItemIds) => {
    const objError = {};
    let arrErrorKeys = [];

    arrError.forEach((err) => {
        let { errorKey } = err;
        let { errorMessage } = err;
        errorKey = errorKey.trim();
        errorMessage = errorMessage.trim();
        // Overwrite Error Message having error key as error text
        if (ErrTextMapping[errorMessage]) {
            errorMessage = ErrTextMapping[errorMessage];
        }
        // Overwite Error Message for a specif error key
        if (ErrMessageForErrorKeyMapping[groupKey]) {
            errorMessage = ErrMessageForErrorKeyMapping[groupKey];
        }

        const errGroupKey = groupKey.trim();
        arrErrorKeys = arrErrorKeys.concat(errorKey);
        if (ErrCodeFieldMapping[errGroupKey]) {
            const errField = ErrCodeFieldMapping[errGroupKey][errorKey];
            objError.errGroupKey = errGroupKey;
            if (errField) {
                if (typeof errField === 'string') {
                    objError[errField] = errorMessage;
                    if (ErrCodeFieldMapping[errorMessage]) {
                        objError[errField] = ErrCodeFieldMapping[errorMessage];
                    }
                } else {
                    errField.forEach((errFieldItem) => {
                        const errFieldName = (errFieldItem.indexOf('_') === 0) ? `${prefixStr}${errFieldItem}` : errFieldItem;
                        objError[errFieldName] = errorMessage;
                    });
                }
            } else if (Number.isInteger((parseInt(errorKey, 10)))) {
                objError[errorKey] = errorMessage;
            } else {
                objError.error = errorMessage;
            }
        } else  if (ErrCodeFieldMapping.ErrCodeFieldMapping[errorKey]) {
            const errField = ErrCodeFieldMapping.ErrCodeFieldMapping[errorKey];
            if (typeof errField === 'string') {
                objError[errField] = errorMessage;
            }
        } else if (errorKey && orderItemIds.includes(errorKey)) {
            objError[errorKey] = errorMessage;
        } else {
            objError.error = errorMessage;
        }
    });

    return {
        objError,
        arrErrorKeys,
    };
};

const errorCodeParser = (errorMsgs) => {
    const arrErrorMsg = errorMsgs.replace(', ', ' ').replace('{', '').replace('}', '').split(',');
    const arrOfErrors = [];
    let commonError = '';
    if (arrErrorMsg.length > 0) {
        arrErrorMsg.forEach((err, index) => {
            const objError = {};
            const tmpErr = err.split('=');
            if (tmpErr.length === 2) {
                objError.errorKey = tmpErr[0].trim();
                const errorMessage = tmpErr[1].trim();
                objError.errorMessage = errorMessage;
                arrOfErrors.push(objError);
            } else {
                commonError += err;
                if (index < arrErrorMsg.length - 1) {
                    commonError += ', ';
                }
            }
        });
        if (commonError !== '') {
            arrOfErrors.push({
                errorKey: 'error',
                errorMessage: commonError,
            });
        }
    }

    return arrOfErrors;
};

export function* getError(action, pageSection) {
    try {
        let arrErrMsg = [];
        let errCodeGroupKey = '';
        let actionData = action;

        const orderItemIds = yield select(getOrderItemIds);

        if (action?.response) {
            actionData = action.response;
        }

        if (
            actionData?.data?.detailedError?.errorKey === 'ERROR_NOT_AUTHORIZED_TO_ACCESS_CART'
            || actionData?.data?.errorKey === 'ERROR_NOT_AUTHORIZED_TO_ACCESS_CART'
        ) {
            arrErrMsg[0] = {
                errorKey: 'error',
                errorMessage: 'We are sorry, there seems to be a technical issue, please try again',
            };
        } else if (actionData?.status && arrErrorCodes.indexOf(actionData.status) >= 0) {
            const genricError = yield call(GenericErrorMessage);
            arrErrMsg[0] = {
                errorKey: 'error',
                errorMessage: genricError,
            };
        } else if (actionData?.data?.response === 'Error') {
            if (
                actionData?.data?.detailedError?.validationErrors
                && Array.isArray(actionData.data.detailedError.validationErrors)
                && actionData.data.detailedError.validationErrors.length > 0
            ) {
                arrErrMsg = actionData.data.detailedError.validationErrors;
            } else if (
                actionData?.data?.fieldErrors
                && Array.isArray(actionData.data.fieldErrors)
                && actionData.data.fieldErrors.length > 0
            ) {
                arrErrMsg = actionData.data.fieldErrors;
            } else {
                arrErrMsg = errorCodeParser(actionData.data.detailedError.errorMessage);
            }
            errCodeGroupKey = actionData.data.detailedError.errorKey.replace('400 ', '');
        } else {
            arrErrMsg[0] = {
                errorKey: 'error',
                errorMessage: actionData?.data?.detailedError?.errorMessage || actionData?.data?.errorMessage || '',
            };
        }

        const { orderItemId } = action;
        let errMessageObject;
        let errMessage;
        switch (pageSection) {
            case 'promotion': {
                errMessage = {};
                const promoMoreInfoList = [];
                const promoErrorMessage = actionData?.data?.detailedError?.errorMessage || '';
                const promoErrorList = actionData?.data?.detailedError?.validationErrors || [];

                if (promoErrorMessage) {
                    errMessage.error = promoErrorMessage;
                }

                if (promoErrorList.length > 0) {
                    promoErrorList.forEach((errorDetails) => {
                        if (errorDetails.errorMessage) {
                            promoMoreInfoList.push(errorDetails.errorMessage);
                        }
                    });
                }

                errMessage.errorMoreInfoList = promoMoreInfoList;

                break;
            }
            case 'recipientForm':
            case 'payment':
            case 'cart':
            case 'giftMessage':
                errMessageObject = getErrorCodeFieldMapping(arrErrMsg, errCodeGroupKey, orderItemId, orderItemIds);
                errMessage = errMessageObject.objError;
                break;
            default:
                errMessageObject = getErrorCodeFieldMapping(arrErrMsg, errCodeGroupKey, orderItemId, orderItemIds);
                errMessage = errMessageObject.objError.error;
                break;
        }

        // Track Error [Tealium]
        yield put(loadError({
            errType: pageSection,
            errMsg: errMessage,
        }));

        if (errMessageObject?.arrErrorKeys) {
            yield put(loggedErrorKeys(errMessageObject.arrErrorKeys));
        }

        let eventLabel = '';
        if (pageSection === 'promotion') {
            eventLabel = errMessage?.error || '';
        } else {
            eventLabel = arrErrMsg[0].errorMessage;
        }

        yield put(tmDuck.actions.trackEvent({
            eventCategory: 'Errors',
            eventAction: pageSection ? `${pageSection[0].toUpperCase()}${pageSection.slice(1)}` : 'Checkout',
            eventLabel,
        }));

        mbpLogger.logError({
            pageSection,
            function: 'getError',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: action?.response?.data ? JSON.stringify(action.response.data) : action?.message,
        });
    } catch (ex) {
        const genricError = yield call(GenericErrorMessage);
        yield put(loadError({
            errType: 'error',
            errMsg: genricError,
        }));
    }
}

function* errorWatcherSaga() {
    let action = yield take(actionTypes.GET_ERROR);
    while (action !== END) {
        yield call(getError, action.data.errMsg, action.data.errType);
        action = yield take(actionTypes.GET_ERROR);
    }
}

const watchers = [
    fork(errorWatcherSaga),
];

export { watchers, GenericErrorMessage };

export default {};
