/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const SET_AB_REDIRECT_GA = 'AB_REDIRECT_SET_GA_STRING';
export const CLEAR_AB_REDIRECT_GA = 'AB_REDIRECT_CLEAR_GA_STRING';
export const SET_AB_REDIRECT_START = 'AB_REDIRECT_START';
export const SET_AB_REDIRECT_END = 'AB_REDIRECT_END';
