/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { useSelector } from 'react-redux';
import mbpUtil from 'mbp-api-util';
import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import withAsyncScript from '../../../../lib/scriptLoader';
// Script is now loading from footer(LegalLinks) component. This one will only render a div which will have html injected by trustarc.

const styles = () => ({
    consentBanner: {
        display: 'block',
        position: 'fixed',
        bottom: '0',
        zIndex: '1000',
        width: '100%',
    },
    cookiePreferences: {
        margin: '20px 0 -20px',
        '@media screen and (max-width: 768px)': {
            margin: '20px 0 -5px',
        },
    },
});

const env = mbpUtil.getEnv('APP_GRAPHQL_ENV');
const isQA = env === 'uat1' ? 'qa.' : '';
const TRUSTARC_NOTICE_URL = `//consent.trustarc.com/notice?domain=${isQA}1800flowersprod.com&c=teconsent&js=nj&noticeType=bb&gtm=1&cdn=1&irmc=irmlink&text=true`;

const TrustArc = ({ classes }) => {
    const ffIsTrustArcRenderDiv = useSelector(getFeatureFlag('is-trustarc-div-model-enabled'));
    return (
        <>
            {!ffIsTrustArcRenderDiv && (<div id="irmlink" />)}
            {/* <div id="teconsent" className={classes.cookiePreferences} /> // This is now part of footer */}
            <div className={classes.consentBanner}>
                <div id="consent_blackbar" />
            </div>
        </>
    );
};

TrustArc.propTypes = {
    classes: object.isRequired,
};

const enhance = compose(
    withStyles(styles),
    withAsyncScript(TRUSTARC_NOTICE_URL, 'TrustArc', true),
);

export default enhance(TrustArc);
