/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const LOAD_REWARD_POINTS_ELIGIBILITY = 'mbp-checkout/LOAD/REWARD_POINTS_ELIGIBILITY';
export const SET_REWARD_POINTS_ELIGIBILITY = 'mbp-checkout/SET/REWARD_POINTS_ELIGIBILITY';
export const LOAD_REWARD_POINTS = 'mbp-checkout/LOAD/LOAD_REWARD_POINTS';
export const SET_LOAD_REWARD_POINTS = 'mbp-checkout/SET/LOAD_REWARD_POINTS';
export const SET_SELECTED_REWARD_POINTS = 'mbp-checkout/SET/SELECTED_REWARD_POINTS';
export const CLEAR_ALL_REWARD_POINTS = 'mbp-checkout/CLEAR_ALL_REWARD_POINTS';
export const CLEAR_SELECTED_REWARD_POINTS = 'mbp-checkout/CLEAR_SELECTED_REWARD_POINTS';
