/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import { all } from 'redux-saga/effects';
import * as checkout from './ducks/Checkout';
import * as expressCheckout from './ducks/ExpressCheckout';
import * as payment from './ducks/Payment';

import * as selectors from './Checkout-Selectors';
import reducers from './Checkout-Reducers';
import watchers from './Checkout-Operations';

function* checkoutSagas() {
    yield all([
        ...watchers,
    ]);
}

const ducks = {
    checkout,
    expressCheckout,
    payment,
};

export {
    ducks,
    selectors,
    watchers,
    // TODO: Use this later for dynamic load
    checkoutSagas,
};

export default reducers;
