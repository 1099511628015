/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import { string } from 'prop-types';
import { makeStyles } from '@material-ui/core';
import findContent from '../../../gql/queries/findContent';
import { getBrand, getDeviceType } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import QaListContainer from '../../ContentTypeComponents/QaList/QaListContainer';

const useStyles = makeStyles(() => ({
    faqContainer: {
        width: '70%',
        marginTop: '35px',
        '& h5': {
            textAlign: 'center',
        },
    },
    faqContainerMobile: {
        width: '70%',
        fontSize: '15px !important',
        marginTop: '20px',
    },
}));

const PassportYotpoFAQ = ({ brand, deviceType }) => {
    const classes = useStyles();
    const identifyWidthObj = {};

    if (deviceType === 'desktop') {
        identifyWidthObj.isMobile = false;
        identifyWidthObj.isDesktop = true;
    } else {
        identifyWidthObj.isMobile = true;
        identifyWidthObj.isDesktop = false;
    }

    const OUR_REWARDS_PROGRAM_FAQ = findContent({
        brand: brand.domain,
        contentType: 'question_answer_list',
        query: '\\"title\\" : \\"Our Rewards Program Frequently Asked Questions\\"',
    });
    const { data, error, loading } = useQuery(OUR_REWARDS_PROGRAM_FAQ);

    if (loading) {
        return <div style={{ height: '170px' }} />;
    }

    if (error) {
        return null;
    }

    if (data?.findContent?.content?.entries?.length === 0) {
        return null;
    }

    const faqdata = data.findContent.content.entries[0] || {};
    return (
        <div className={identifyWidthObj.isDesktop ? classes.faqContainer : classes.faqContainerMobile}>
            <QaListContainer  data={faqdata} identifyWidthObj={identifyWidthObj} />
        </div>
    );
};

PassportYotpoFAQ.propTypes = {
    brand: string.isRequired,
    deviceType: string.isRequired,
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
    deviceType: getDeviceType(state),
});
export default connect(mapStateToProps)(PassportYotpoFAQ);
