/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// Order Id
export const getOrderId = (state) => state.order.orderId || '';

// Auth
export const getAuthInfo = (state) => state.member.auth;
export const getUserType = (state) => state.member.auth.userType;
export const getAccessToken = (state) => state.member.auth.accessToken;
export const getExpiresAt = (state) => state.member.auth.expiresAt;
export const getIsAuthenticatedStatus = (state) => state.member.auth.isAuthenticated;
export const getLastVisitedPages = (state) => state.member.auth.lastVisitedPage;
export const getAuthError = (state) => state.member.auth.authError;
export const getAuthSuccess = (state) => state.member.auth.authSuccess;
export const getLastVisted = (state) => {
    if (state.member.auth.lastVisitedPage.length > 0) {
        const { lastVisitedPage } = state.member.auth;
        if (lastVisitedPage.indexOf('/shipping/') >= 0) {
            return `/checkout/shipping/${getOrderId(state)}`;
        }
        if (lastVisitedPage.indexOf('/cart/') >= 0) {
            return `/checkout/cart/${getOrderId(state)}`;
        }
        if (lastVisitedPage.indexOf('/payment/') >= 0) {
            return `/checkout/payment/${getOrderId(state)}`;
        }
        return state.member.auth.lastVisitedPage;
    }
    return '/';
};

// Profile
export const getProfileInfo = (state) => state.member?.profile || {};
export const getUserRole = (state) => state.member?.profile?.userRole || '';
export const getFirstLastName = (state) => ({
    firstName: state.member?.profile?.firstName || '',
    lastName: state.member?.profile?.lastName || '',
});
export const getContactId = (state) => state.member?.profile?.contactId;

// AddressBook
export const getAddressBookEntries = (state) => state.member.addressBook.addressBookEntries;
export const getTotalEntriesAddressBook = (state) => state.member.addressBook.totalEntries;
export const getAddressBookPageNumber = (state) => {
    const pageNumber = state.member.addressBook.getAddressBookPageNumber;
    if (pageNumber) {
        return pageNumber;
    }
    return 0;
};

// Wallet
export const getWalletEntries = (state) => state.member.wallet.walletEntries;

// Member Id
export const monthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const dayList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const getMemberId = (state) => state.cart?.customer?.customerId || null;
