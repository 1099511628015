/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const waitWindowMFEComponent = (libName, callId) => new Promise((resolve, reject) => {
    console.log(`${callId} starts at ${performance.now()}`);
    let isIntervalCleared = false;
    const id = setInterval(() => {
        if (global && global[libName] && global[libName].default) {
            clearInterval(id);
            isIntervalCleared = true;
            console.log(`${callId} end at ${performance.now()}`);
            resolve(global[libName].default);
        }
    }, 10);
    setTimeout(() => {
        if (!isIntervalCleared) {
            clearInterval(id);
            console.log(`Component Load Timeout at ${performance.now()}`);
            reject(Error('Component Load Timeout'));
        }
    }, 30000);
});

const oldScriptHashObj = {};

export const loadJS = (hash, url, libName) => new Promise((resolve, reject) => {
    const scriptHash = `${libName}-main-${hash}-bundle`;

    if (!document.getElementById(scriptHash)) {
        global[libName] = null;
        global[`webpackJsonp${libName}`] = null;
        const script = document.createElement('script');
        script.onerror = reject;
        script.onload = () => {
            console.log(`Script Load completed at ${performance.now()}`);
            // TODO: check the behavior of this cleanup for long run
            if (typeof oldScriptHashObj[libName] === 'string'
                && scriptHash !== oldScriptHashObj[libName]
                && document.getElementById(oldScriptHashObj[libName])) {
                console.log('oldScriptHashObj', libName, oldScriptHashObj);
                document.getElementById(oldScriptHashObj[libName]).remove();
            }
            oldScriptHashObj[libName] = scriptHash;
            return resolve();
        };
        script.id = scriptHash;
        script.async = true;
        script.src = url;
        document.body.appendChild(script);
    } else {
        resolve();
    }
})
    .then(() => waitWindowMFEComponent(libName, 'CheckoutJS content load'));
    // .catch((e) => {
    //     throw Error('Failed to load JS', e);
    // });

// const loadCSS = (url) => new Promise((resolve, reject) => {
//     const link = document.createElement('link');
//     link.onerror = reject;
//     link.onload = resolve;
//     link.type = 'text/css';
//     link.href = url;
//     document.head.appendChild(link);
// });

export default {};
