/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useQuery, gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import mbpLogger from 'mbp-logger';
import { getBrand } from '../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { GRAPHQL_ENV } from '../../gql';

const defaultEval = (data) => Object.keys(data?.reference?.[0] || {}).length === 2;

const defaultDataSelector = (data) => data;

const defaultNewDataSelector = (data, contentType) => {
    if (data?.findContentByUID?.content?.entry && typeof data?.findContentByUID?.content?.entry === 'object') {
        return { reference: [{ ...data.findContentByUID.content.entry, _content_type_uid: contentType }] };
    }
    return null;
};

const defaultUIDSelector = (data) => data?.reference?.[0]?.uid;

const defaultCTSelector = (data) => data?.reference?.[0]?._content_type_uid;

/**
 * @description This hook will look into the data provided. If the condition function is met, it will fetch block data
 *  via uid. If it is not, then it will not fetch that data
 * @param {func} evaluateCondition A function that runs to determine if the block should be fetched by uid
 * @param {object} data The data that the evaluateCondition will be ran against
 * @param {array} includeRefs An array of references that are needed for CS UID query
 * @param {func} uidSelector A selector function that runs agains the data object to retrieve uid
 * @param {func} contentTypeSelector A selector function that runs agains the data object to retrieve content type
 * @param {func} dataSelector A selector function that runs against the data obejct to return the passed in data
 * @param {func} newDataSelector A selector function that runs against the uid block data object
 * @returns {[object, object]} If evaluateCondition returns true, then it will return dataSelector(data)
 *      If it returns false, then it will return newDataSelector(newBlockData)
 */

export default ({
    data,
    includeRefs,
    evaluateCondition = defaultEval,
    dataSelector = defaultDataSelector,
    newDataSelector = defaultNewDataSelector,
    uidSelector = defaultUIDSelector,
    contentTypeSelector = defaultCTSelector,
}) => {
    const brand = useSelector(getBrand);
    const CONTENT_QUERY = gql`
        query DepartmentBlockQuery($brand: String!, $environment: String!, $contentType: String!, $uid: String!, $includeRefs: [String]) {
            findContentByUID(brand: $brand, environment: $environment, 
                locale: "en-us", contentType: $contentType, uid: $uid, includeRefs: $includeRefs) {
                brand
                environment
                locale
                content
            }
        }
    `;
    const { data: newBlockData, error, loading } = useQuery(CONTENT_QUERY, {
        variables: {
            brand: brand.domain,
            environment: GRAPHQL_ENV,
            includeRefs,
            uid: uidSelector(data),
            contentType: contentTypeSelector(data),
        },
        skip: !evaluateCondition(data),
    });

    // Check for error
    if (error) {
        mbpLogger.logError({
            function: contentTypeSelector(data),
            appName: process.env.npm_package_name,
            module: 'useUIDQueryConditionally',
            message: `An error has occured when trying to fetch ${contentTypeSelector(data)} data`,
            jsError: error,
        });
    }

    return { data: newBlockData ? newDataSelector(newBlockData, contentTypeSelector(data)) : dataSelector(data), loading, error };
};
