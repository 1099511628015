/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './Error-ActionTypes';

export const clearErrors = (clear) => ({
    type: actionTypes.CLEAR_ERRORS,
    data: clear,
});

export const getError = (error, errorKey) => ({
    type: actionTypes.GET_ERROR,
    errorKey,
    data: error,
});

export const loadError = (error, errorKey) => ({
    type: actionTypes.LOAD_ERROR,
    errorKey,
    data: error,
});

export const loggedErrorKeys = (errorKeys) => ({
    type: actionTypes.LOGGED_ERROR_KEYS,
    errorKeys,
});
