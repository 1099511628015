/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
const CLEAR_PAYPAL_STATE = 'MFE/CHECKOUT/CLEAR_PAYPAL_STATE';

export default {
    CLEAR_PAYPAL_STATE,
};
