/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, call, takeEvery, put, select,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import memberDucks from '../../../Member/ducks';
import { showLoadingMessageActions } from '../../../Common/ducks/ShowLoadingMessage';
import { errorActions, errorOperations } from '../../../Common/ducks/Error';
import { retrieveCart } from '../../Cart-Operations';
import { duplicateItem } from './DuplicateItem-Actions';
import { prepareRetreiveCart } from '../../../Checkout/ducks/Checkout/Checkout-Operations';

import {
    getOrderId, getCartOrder,
} from '../../../Common/Common-Selectors';
import duplicateCart from '../../../../../apis/checkout-apis/duplicateCart';
import { callHandleCartFailure } from '../../Cart-Actions';
import { trackCartUpdate } from '../../../TagManager/ducks/TagManager/TagManager-Helpers';
import { getActiveABTests } from '../../../App/ducks/ABTesting/ABTesting-Selectors';
import { getLastAddedOrderItemDetails } from '../../../AddToCart/AddToCart-Selectors';
import { getFeatureFlags } from '../../../Checkout/ducks/App/App-Selectors';

const {
    auth: {
        authOperations,
    },
} = memberDucks;

const { checkJWT } = authOperations.workers;

const requestDuplicateItem = ({
    wcEnv, jwtToken, orderItemId, orderId,
}) => duplicateCart(wcEnv, jwtToken, orderItemId, orderId);
/**
 * @description this will send the api to duplicate the item using the itemorder
 * @param {*} action
 */
function* onDuplicateItem(action) {
    try {
        // Show Loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        // Prepare Duplicate
        const flagState = yield select(getFeatureFlags);
        const checkoutVariation = flagState['which-checkout-variation'];
        const activeABTests = yield select(getActiveABTests);

        const { orderItemId, pageProcess, history } = action.data;
        const jwtToken = yield call(checkJWT);
        const orderId = yield select(getOrderId);
        const configObj = {
            wcEnv: {},
            jwtToken,
            orderItemId,
            orderId,
        };

        // Call Duplicate API
        yield call(requestDuplicateItem, configObj);

        yield put(errorActions.clearErrors({
            errType: 'cart',
            field: 'error',
        }));

        // Prepare Retreive cart
        yield call(prepareRetreiveCart, pageProcess);

        // Fetch the latest cart data
        yield call(retrieveCart);

        let redirectToShipping = false;
        if (history && history.location?.pathname) {
            // Analize is he page is payment page or review page to forcee go to shipping
            const isMatchPath = history.location.pathname.match(/payment|review-order|review/g);
            if (isMatchPath && isMatchPath?.[0]) {
                redirectToShipping = true;
            }
        }

        if (
            (
                ['food', 'enterprise', 'enterprise2'].includes(checkoutVariation)
                || activeABTests?.checkout === 'universal'
            )
            && redirectToShipping
        ) {
            history.push(`/checkout/shipping/${orderId}`);
        }

        // Hide Loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());

        if (action?.data?.callback) {
            const lastAddedItemInCart = yield select(getLastAddedOrderItemDetails);

            yield call(action.data.callback, lastAddedItemInCart.cartItemId);
        }

        // Retrive current cart
        // Send cart snapshot to cart track event
        const cartOrder = yield select(getCartOrder);
        yield call(trackCartUpdate, flagState, 'EventDuplicateCartItem', cartOrder);
    } catch (err) {
        const orderId = yield select(getOrderId);
        mbpLogger.logError({
            orderId,
            orderItem: action.data.orderItemId,
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'duplicate item',
            jsError: err,
            message: 'duplicate item Failed',
        });
        yield put(callHandleCartFailure(err));
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, err, 'cart');
    }
}

function* watchDuplicateItem() {
    yield takeEvery(duplicateItem().type, onDuplicateItem);
}

const watchers = [
    fork(watchDuplicateItem),
];

export { watchers };

export default {};
