/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    object, func, number, bool, string,
} from 'prop-types';
import EmailOptInModal from './EmailOptInModal';
import { emitCustomerIdentificationEvent, emitCustomTrackingEvent } from '../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import { setEmailOptInSFResponse, setEmailOptInRefetchTimestamp, resetEmailOptInSFResponse } from '../../../state/ducks/App/App-Actions';
import { getEmailOptInSFResponse } from '../../../state/ducks/App/App-Selectors';
import validateEmailAddress from '../../helpers/validateEmailAddress';
import { useExitIntent, useScrollDetection } from '../../helpers/Personalization/UserExitIntentTracker/utils';

const EmailOptInContainer = ({
    brandId,
    emailOptInData,
    isSubscribed,
    jestMockFlag,
    page,
    resetEmailOptInData,
    showModalAfterMS,
    salesforceResponse,
    emitCustomTrackingEvents,
    callCustomerIdentificationEvent,
    updateEmailOptInSFResponse,
    emailOptInSFResponse,
    updateEmailOptInRefetchTimestamp,
}) => {
    const [currentEmailId, setCurrentEmailId] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [validEmailId, setValidEmailId] = useState(false);
    const [onSubmitCheck, setOnSubmitCheck] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const userGroup = salesforceResponse?.campaign?.campaignResponses?.[0]?.userGroup || {};
    const entranceExperienceType = salesforceResponse?.campaign?.campaignResponses?.[0]?.payload?.experienceType === 'entrance';
    const exitExperienceType = salesforceResponse?.campaign?.campaignResponses?.[0]?.payload?.experienceType === 'exit intent';
    const isVariantFlow = userGroup?.toLowerCase() !== 'control';

    const showOptInModal = () => {
        setModalOpen(isVariantFlow);
        setShowConfirmation(isSubscribed);

        if (!isSubscribed && !Object.entries(emailOptInSFResponse).length) {
            emitCustomTrackingEvents({
                action: 'Impression',
                salesforceResponse,
                page,
                eventSubType: 'campaign-tracking',
            });
            updateEmailOptInSFResponse(salesforceResponse);
            updateEmailOptInRefetchTimestamp(new Date().getTime());
        }
    };

    const handleExitIntent = () => {
        if (!isSubscribed) showOptInModal();
    };

    if (exitExperienceType) {
        useExitIntent(handleExitIntent);
        useScrollDetection(handleExitIntent, 500);
    }

    useEffect(() => {
        if (jestMockFlag) showOptInModal();

        if (isSubscribed) {
            showOptInModal();
        } else {
            setTimeout(() => {
                if (entranceExperienceType) showOptInModal();
            }, showModalAfterMS || 10000);
        }
    }, []);

    const closeModal = () => {
        if (isSubscribed) resetEmailOptInData();
        else updateEmailOptInRefetchTimestamp(new Date().getTime());
        setModalOpen(false);
        sessionStorage.setItem('isOptInModalShown', true);
    };

    const validateUserSubmittedEmailId = async () => {
        // this function determines when to validate a email Id that the user enters
        const emailId = currentEmailId;
        // remove the space to string at end
        const updatedEmailId = emailId.trimEnd();

        setCurrentEmailId(updatedEmailId);
        setValidEmailId(validateEmailAddress(updatedEmailId));
        setOnSubmitCheck(!validateEmailAddress(updatedEmailId));
    };

    const handleEmailIdChange = (e) => {
        setCurrentEmailId(e.target.value.trimStart());
        setOnSubmitCheck(false);
    };

    const handleButtonSubmit = () => {
        const customerIdentificationPayload = {
            signInMethod: 'promo-banner-email',
            user: {
                email: currentEmailId,
            },
        };

        if (!validateEmailAddress(currentEmailId)) {
            setOnSubmitCheck(true);
            return;
        }

        emitCustomTrackingEvents({
            action: 'Click',
            salesforceResponse,
            page,
            eventSubType: 'campaign-tracking',
        });

        callCustomerIdentificationEvent(customerIdentificationPayload);

        setShowConfirmation(true);
    };

    return (
        <>
            {modalOpen && (
                <EmailOptInModal
                    emailOptInData={emailOptInData}
                    isOpen={modalOpen}
                    currentEmailId={currentEmailId}
                    showConfirmation={showConfirmation}
                    onSubmitCheck={onSubmitCheck}
                    validEmailId={validEmailId}
                    closeModal={closeModal}
                    handleButtonSubmit={handleButtonSubmit}
                    handleEmailIdChange={handleEmailIdChange}
                    validateUserSubmittedEmailId={validateUserSubmittedEmailId}
                    salesforceResponse={salesforceResponse}
                    brandId={brandId}
                    page={page}
                />
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    emailOptInSFResponse: getEmailOptInSFResponse(state),
});

const mapDispatchToProps = (dispatch) => ({
    callCustomerIdentificationEvent: bindActionCreators(emitCustomerIdentificationEvent, dispatch),
    emitCustomTrackingEvents: bindActionCreators(emitCustomTrackingEvent, dispatch),
    resetEmailOptInData: bindActionCreators(resetEmailOptInSFResponse, dispatch),
    updateEmailOptInSFResponse: bindActionCreators(setEmailOptInSFResponse, dispatch),
    updateEmailOptInRefetchTimestamp: bindActionCreators(setEmailOptInRefetchTimestamp, dispatch),
});

EmailOptInContainer.propTypes = {
    emailOptInData: object.isRequired,
    showModalAfterMS: number,
    salesforceResponse: object,
    emitCustomTrackingEvents: func.isRequired,
    page: object,
    jestMockFlag: bool,
    brandId: string.isRequired,
    callCustomerIdentificationEvent: func.isRequired,
    resetEmailOptInData: func.isRequired,
    isSubscribed: bool,
    updateEmailOptInSFResponse: func.isRequired,
    emailOptInSFResponse: object.isRequired,
    updateEmailOptInRefetchTimestamp: func.isRequired,
};

EmailOptInContainer.defaultProps = {
    showModalAfterMS: 0,
    salesforceResponse: {},
    page: {},
    jestMockFlag: false,
    isSubscribed: false,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EmailOptInContainer);
