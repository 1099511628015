/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';

const BranchIndication = ({ branch }) => {
    if (branch) {
        const styles = {
            background: 'gold',
            textAlign: 'center',
            fontWeight: '700',
            padding: '5px 0',
            position: 'sticky',
            top: 0,
            zIndex: 9999,
        };
        return <div style={styles}>Notice: You are currently targeting the &quot;{branch}&quot; branch from the CMS</div>;
    }
    return '';
};

BranchIndication.propTypes = {
    branch: PropTypes.string.isRequired,
};

export default BranchIndication;
