/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as promotionActions from './Promotion-Action';
import PromotionReducer from './Promotion-Reducer';

export { promotionActions };

export default PromotionReducer;
