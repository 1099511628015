/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpLogger from 'mbp-logger';
import restClient from '../../app/helpers/restClient';

export const subscribeEmailApi = async ({
    email, brandCode, token, action = 'CREATE',
}) => {
    const payload = { email };
    const wcEnv = { rootUri: '/' };
    const resourcePage = `profile/site/${brandCode}/user/${email}/action/${action}`;
    const response = await restClient.postFacade(wcEnv, resourcePage, token, payload);
    delete response.headers;
    delete response.request;
    delete response.config;

    let isSubscribed = false;
    const error = {};

    try {
        const updatedMessage = response?.data?.updateEmailResponse?.updateEmailResponseResult?.updateEmailResult?.updateEmailMessage;
        const responseStatus = response?.data?.updateEmailResponse?.responseStatus?.toLowerCase();
        if (/^OK--Created/.test(updatedMessage)) {
            isSubscribed = true;
            error.message = '';
        } else if (updatedMessage === 'Error--Unable to queue Triggered Send request.  There are no valid subscribers.') {
            error.message = 'There are no valid subscribers.';
        } else if (updatedMessage === 'Held') {
            error.message = 'Email address is in Held status after multiple email bounces.';
        } else if (updatedMessage === undefined && responseStatus === 'failure') {
            error.message = `EmailSubscribeBox: Error in while subscribing email: ${email}`;
        } else if (updatedMessage === 'Error--TriggeredSpamFilter') {
            error.message = 'Spam Email address found.';
        } else {
            error.message = updatedMessage;
        }
    } catch (err) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            jsError: err,
            message: `EmailSubscribeBox: Error in while subscribing email: ${email}`,
        });
        error.message = err.message;
    }
    return {
        data: { response, isSubscribed, error },
    };
};

export default {};
