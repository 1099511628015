/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const getCurrentPageView = (state) => {
    let currentPageView = state?.tagManager?.pageView;
    if (currentPageView?.pageType === 'homepage') currentPageView = { ...currentPageView, ...{ pageType: 'home' } };
    return currentPageView;
};
export const getCurrentPageType = (state) => {
    let currentPageType = state?.tagManager?.pageView?.pageType;
    if (currentPageType === 'homepage') currentPageType = 'home';
    return currentPageType;
};
export const getPageViewFiredState = (state) => state.tagManager.pageViewFired;
export const getQueuedEvents = (state) => state.tagManager.events;
export const getCampaign = (state) => state.tagManager.campaign;

export const getCurrentBrandId = (state) => state.ui.brandId;
export const getConfigByDomains = (state) => state.app.config.byDomain;

// Member Role
export const getUserRole = (state) => state.member?.profile?.userRole || '';

// Member
export const getMemberProfile = (state) => state.member.profile || null;

export const getMemberUserId = (state) => {
    // First look to the profile state
    if (state?.member?.profile?.customerId) {
        return state?.member?.profile?.customerId;
    }

    // If profile state is not found, defer to billing address object
    if (state?.cart?.customer?.customerId) {
        return state.cart.customer.customerId;
    }

    return null;
};
