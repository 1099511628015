/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object, shape, string } from 'prop-types';
import ReactMarkdown from 'react-markdown';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = {
    header: {
        fontSize: '15px',
        fontWeight: '600',
        textAlign: 'center',
    },
    container: {
        fontSize: '14px',
        color: '#000',
    },
    copy: {
        padding: '3px',
        textAlign: 'right',
        fontSize: '14px',
    },
};

const PassportTncContent = ({ classes, data }) => {
    if (!data) {
        return <p>Sorry, unable to display terms and conditions</p>;
    }

    const titleText = data.hero?.title || '';
    const copyText = data.hero?.copy || '';
    const articleBody = data.article_body || '';

    return (
        <Grid className={classes.container}>
            <Typography className={classes.header} variant="body1">{titleText}</Typography>
            <Typography className={classes.copy} align="right" variant="body2">{copyText}</Typography>
            <ReactMarkdown
                source={articleBody}
            />
        </Grid>
    );
};

PassportTncContent.propTypes = {
    data: shape({
        hero: object,
        article_body: string,
    }),
    classes: object.isRequired,
};

PassportTncContent.defaultProps = {
    data: {},
};

export default withStyles(styles)(PassportTncContent);
