/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { delay } from 'redux-saga';
import {
    fork, put, takeEvery, select,
} from 'redux-saga/effects';

import { flagOffShowLoadingMessage, updateLoaderId, updateShowLoadingMessage } from './ShowLoadingMessage-Actions';
import { getLoaderStatus, getLoaderId } from '../../Common-Selectors';

function* loaderWorkerSaga() {
    if (yield select(getLoaderStatus)) {
        const rndval = Math.random().toString().slice(2);
        yield put(updateLoaderId(rndval));
        const latestDelay = yield delay(10000, rndval);
        // Delay Execution to check if loader is on then turn off
        const lastUpdatedDelay =  yield select(getLoaderId);
        if (yield select(getLoaderStatus) && latestDelay === lastUpdatedDelay) {
            yield put(flagOffShowLoadingMessage());
        }
    }
}

function* loaderWatcherSaga() {
    yield takeEvery(updateShowLoadingMessage().type, loaderWorkerSaga);
}

const watchers = [
    fork(loaderWatcherSaga),
];

export { watchers };

export default {};
