/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { bool, object } from 'prop-types';

const PlaceholderBanner = ({ bannerInfo, classes, isMobile }) => {
    const banner = !isMobile
        ? (bannerInfo?.desktop_banner?.url
    && bannerInfo?.desktop_banner?.title)
    && bannerInfo?.desktop_banner
        : (bannerInfo?.mobile_banner?.url
    && bannerInfo?.mobile_banner?.title)
    && bannerInfo?.mobile_banner;

    const bannerStyling = !isMobile ? {
        backgroundColor: bannerInfo?.stb_background_color,
    } : {};
    const placeholderBannerUseDefaultSize = bannerInfo?.use_default_banner_size;
    const bannerLink = bannerInfo?.link?.title && bannerInfo?.link?.href && bannerInfo?.link;

    const desktopStyle = placeholderBannerUseDefaultSize ? classes.fullWidth : classes.image;

    const renderEventBanner = () => (
        <img className={!isMobile ? desktopStyle : classes.imageMobile} src={banner?.url} alt={banner?.description || 'Placeholder Banner Image'} />
    );
    return (
        <div className={classes.bannerContainer} style={bannerStyling}>
            {bannerLink ? <a href={bannerLink.href}>{renderEventBanner()}</a> : renderEventBanner()}
        </div>
    );
};

PlaceholderBanner.propTypes = {
    bannerInfo: object,
    classes: object,
    isMobile: bool,
};

PlaceholderBanner.defaultProps = {
    bannerInfo: {},
    classes: {},
    isMobile: false,
};

export default PlaceholderBanner;
