/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Query } from '@apollo/client/react/components';
import {  makeStyles } from '@material-ui/core/styles';
import mbpLogger from 'mbp-logger';
import { useHistory } from 'react-router-dom';
import { GRAPHQL_ENV } from '../../../gql';
import determineLocale from '../../../helpers/contentstack/determineLocale';
import {  getBrandName } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { setAbcChatLink } from '../../../../state/ducks/App/App-Actions';
import findFooter from '../../../gql/queries/findFooter';
import GraphqlFooter from './GraphqlFooter';

const useStyles = makeStyles((theme) => ({
    appFooter: {
        color: theme.palette.grey['50'],
        marginTop: 20,
    },
    addonPageDesktopFooter: {
        width: '100%',
    },
}));
const FooterQuery = () => {
    const brandName = useSelector(getBrandName);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const getContentTypeData = {
        brand: brandName,
        GRAPHQL_ENV,
        locale: determineLocale(history),
        contentType: 'footer',
        references: ['"footer_blocks.menu_group_links.menu_group_link_ref"'],
    };

    const FOOTER_QUERY = findFooter(getContentTypeData);
    const addonPageFooter  = (typeof window === 'object') ? (/add-ons/.test(window.location.pathname)) : false;
    const addonCartPage  = (typeof window === 'object') ? (window.location.pathname.indexOf('/cart/') !== -1) : false;

    return (
        <footer id="global_footer_container" className={addonPageFooter || addonCartPage ? `${classes.addonPageDesktopFooter} ${classes.appFooter}` : `${classes.appFooter}`}>
            <Query query={FOOTER_QUERY}>
                {({ loading, error, data }) => {
                    if (loading) {
                        return null; // skeleton component
                    }
                    if (error) {
                        mbpLogger.logError({
                            appName: process.env.npm_package_name,
                            query: FOOTER_QUERY,
                            component: 'GraphqlFooter.js',
                            message: 'Error loading data from Graphql',
                            env: GRAPHQL_ENV,
                            error,
                        });
                        return null;
                    }

                    if (!data) {
                        mbpLogger.logWarning({
                            appName: process.env.npm_package_name,
                            query: FOOTER_QUERY,
                            component: 'GraphqlFooter.js',
                            message: 'No data returned for query',
                            env: GRAPHQL_ENV,
                        });
                        return null;
                    }
                    if (!data.business?.content || !data.legal?.content) {
                        mbpLogger.logWarning({
                            appName: process.env.npm_package_name,
                            query: FOOTER_QUERY,
                            component: 'GraphqlFooter.js',
                            message: 'No content returned for query. Missing either Global Legal data or Brand stack data.',
                            env: GRAPHQL_ENV,
                        });
                        return null;
                    }
                    const businessData = data.business.content || [];
                    const legalData = data.legal.content || [];

                    if (businessData.entries?.length && legalData.entries?.length) {
                        const footerData = businessData.entries;
                        const footerBlocks = footerData.length ? footerData[0]?.footer_blocks : [];
                        const chatBlock = footerBlocks?.find((block) => block?.chat) || {};
                        const iosChatLink = chatBlock?.chat?.link?.href || '';
                        if (iosChatLink) {
                            dispatch(setAbcChatLink(iosChatLink));
                        }
                        return (<GraphqlFooter footerData={footerData} legalData={legalData.entries[0]} />);
                    }
                    return '';
                }}
            </Query>
        </footer>
    );
};

export default FooterQuery;
