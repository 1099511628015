/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import Cookies from 'universal-cookie';

export function getBranch() {
    const cookies = new Cookies();
    const branchCookie = cookies.get('branch');
    const branch = (branchCookie !== 'main' && branchCookie !== undefined) ? `, branch: "${branchCookie}"` : ', branch: "main"';
    return branch;
}

export function hasBranch() {
    const cookies = new Cookies();
    const branchCookie = cookies.get('branch');
    const branch = (branchCookie !== 'main' && branchCookie !== undefined) ? branchCookie : '';
    return branch;
}
