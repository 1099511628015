/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { useSelector } from 'react-redux';
import QueryString from 'qs';
import mbpLogger from 'mbp-logger';
import mbpUtil from 'mbp-api-util';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { node } from 'prop-types';
import { getFeatureFlag } from '../state/ducks/App/ducks/Config/Config-Selectors';

import { CAMPAIGN_PROPS, getAnalyticsStorageData } from './helpers/tracking/session/sessionTrackingHelpers';
import useBrowserUUID from './helpers/useBrowserUUID';

const AppLDProvider = ({ children, ssrDeviceType }) => {
    const isLaunchDarklyClientSdkEnabled = useSelector(getFeatureFlag('is-launch-darkly-client-sdk-enabled'));
    mbpLogger.logDebug({ appName: process.env.npm_package_name, message: 'AppLDProvider ... Wrapped LD PROVIDER around APP.js' });

    // Implement LaunchDarkly React sDK
    try {
        if (typeof window !== 'undefined'
        && isLaunchDarklyClientSdkEnabled // Enablement
        && mbpUtil.getEnv('APP_LD_CLIENT_SDK_ID') // SDK ID
        ) {
            const ldClientSideID = mbpUtil.getEnv('APP_LD_CLIENT_SDK_ID');
            const ldOptions = {
                streaming: false,
            };

            mbpLogger.logDebug({
                appName: process.env.npm_package_name,
                message: `LaunchDarkly React SDK loading clientId: ${ldClientSideID}`,
            });

            // LD Context types
            let ldUserContext = null; // user
            let ldDeviceContext = null; // device

            const browserUUID = useBrowserUUID() ||  '';

            const analyticsSession = getAnalyticsStorageData();
            const urlParams = window.location.search ? QueryString.parse((window.location.search || '').replace('?', '')) : {};
            const isNewCampaign = Object.keys(CAMPAIGN_PROPS).some((key) => urlParams[key]);

            let queryString = '';
            if (analyticsSession?.location && !isNewCampaign) {
                const urlObject = new URL(analyticsSession?.location);
                queryString = (urlObject.search || '').replace('?', '');
            } else {
                queryString = (window.location.search || '').replace('?', '');
            }

            // BrowserUUID is required for user context
            if (browserUUID) {
                ldUserContext = {
                    kind: 'user',
                    key: browserUUID,
                    brand: window.location.hostname.split('.')[1],
                    queryString, // anything past ?
                    path: window.location.pathname,
                    deviceType: ssrDeviceType,
                };
            }

            // Device Context stub
            ldDeviceContext = {
                kind: 'device',
            };

            // Build LD Multi-Context
            const ldMultiContext = {
                type: 'multi',
                device: ldDeviceContext,
                user: ldUserContext,
            };
            mbpLogger.logDebug({
                appName: process.env.npm_package_name,
                message: `LaunchDarkly React SDK ldMultiContext: ${ldMultiContext}`,
            });

            // Enhance RootComp component
            if (ldMultiContext) {
            // Wrap component w/ LDProvider
                return (
                    <LDProvider
                        clientSideID={ldClientSideID}
                        options={ldOptions}
                        context={ldUserContext}
                    >
                        {children}
                    </LDProvider>
                );
            }
        }
    } catch (err) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            jsError: err,
            message: 'LaunchDarkly React SDK failed to build and initialize.',
        });
    }
    return children;
};

AppLDProvider.propTypes = {
    children: node.isRequired,
};

export default AppLDProvider;
