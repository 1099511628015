/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import mbpLogger from 'mbp-logger';
import loadable from '@loadable/component';
import {
    shape, string, object, bool, func,
} from 'prop-types';
import { getFeatureFlags } from '../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getAccessToken } from '../../../state/ducks/App/ducks/Common/Common-Selectors';
import { getBrand } from '../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import restClient from '../../helpers/restClient';

const LoadableAigoChatWidgetWrapperV3 = loadable(() => import('./AigoChatWidgetWrapperV2/AigoChatWidgetWrapperV2'));
const LoadableCSPChatWidgetWrapper = loadable(() => import('./AigoChatWidgetWrapperV2/CSPChatWidgetWrapper'));

const MultibrandChatContainer = ({
    brand,
    featureFlags,
    isFeedbackBtnClicked,
    isChatBtnClicked,
    isUseCaseTriggered,
    isGqlQuery,
    token,
    mainTabConvID,
    setMainTabConvID,
    setMainTabMessages,
    popOpenChatDetail,
    setPopOpenChatDetail,
    setIsChatBtnClicked,
}) => {
    // STATE DEFS:
    const [flagState, setFlagState] = useState(featureFlags);
    const [hasCheckedForCSPChat, setHasCheckedForCSPChat] = useState(false);
    // SELECTOR DEFS:
    const customerData = useSelector((state) => state?.member);
    // MISC VAR DEFS:
    let shouldOpenChat = isChatBtnClicked;

    if (isGqlQuery) {
        const { error, data } = useQuery(isGqlQuery);

        if (error) {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                jsError: error,
                component: 'MultiBrandChatController.js',
                message: 'Failed to fetch content data from gql',
            });
        }
        if (!data) {
            mbpLogger.logWarning({
                appName: process.env.npm_package_name,
                component: 'MultiBrandChatController.js',
                message: 'No data returned for query',
            });
        }

        let promoIsRestricted = null;
        if (data?.findSkuByPartNumbers) {
            // we filter through to get an array of items that have promo restricted.
            promoIsRestricted = data?.findSkuByPartNumbers?.filter((sku) => sku.isPromoRestricted);
            if (!promoIsRestricted?.length) shouldOpenChat = true;
        }
    }

    useEffect(() => {
        // since we are sending in a copy of flags to control hours of operation, update all other flags in real time
        if (!flagState['is-csp-chat-widget-enabled']) {
            const newState = { ...flagState };
            newState['is-csp-chat-widget-enabled'] = false;
            setFlagState(newState);
        } else {
            setFlagState(flagState);
        }
    }, [featureFlags]);

    useEffect(() => {
        const checkCSPChat = async () => {
            const newFlagState = { ...flagState };
            try {
                // CSP HOO Functionality
                if (newFlagState['is-csp-chat-widget-enabled'] && newFlagState['is-csp-hoo-enabled']) {
                    const isWithinHOO = (await restClient.getFacade(
                        { rootUri: '/' }, 'csp/webchat/v2/hours-of-operation/validate', token,
                    ))?.data?.data || false;
                    newFlagState['is-csp-chat-widget-enabled'] = isWithinHOO || false;
                }
                // CSP WHITELIST Functionality
                if (!newFlagState['is-csp-chat-widget-enabled'] && newFlagState['is-csp-whitelisting-enabled'] && customerData?.profile?.email) {
                    const isWhitelisted = (await restClient.getFacade(
                        { rootUri: '/' },
                        'csp/webchat/v2/customer/whitelist',
                        token,
                        { email: customerData?.profile?.email },
                    ))?.data || false;
                    newFlagState['is-csp-chat-widget-enabled'] = isWhitelisted;
                }
            } catch {
                mbpLogger.logWarning({
                    appName: process.env.npm_package_name,
                    component: 'MultibrandChatController.js',
                    message: 'Failed to process request to get hours of operation',
                });
            }
            setFlagState(newFlagState);
            setHasCheckedForCSPChat(true);
        };

        checkCSPChat();
    }, []);

    if (flagState['is-chat-icon-displayed'] && hasCheckedForCSPChat) {
        return flagState['is-csp-chat-widget-enabled']
            ? (
                <LoadableCSPChatWidgetWrapper
                    brand={brand}
                    featureFlags={flagState}
                    isFeedbackBtnClicked={isFeedbackBtnClicked}
                    isChatBtnClicked={shouldOpenChat}
                    isUseCaseTriggered={isUseCaseTriggered}
                    mainTabConvID={mainTabConvID}
                    setMainTabConvID={setMainTabConvID}
                    setMainTabMessages={setMainTabMessages}
                    setIsChatBtnClicked={setIsChatBtnClicked}
                    popOpenChatDetail={popOpenChatDetail}
                    setPopOpenChatDetail={setPopOpenChatDetail}
                />
            )
            : (
                <LoadableAigoChatWidgetWrapperV3
                    brand={brand}
                    featureFlags={flagState}
                    isFeedbackBtnClicked={isFeedbackBtnClicked}
                    isChatBtnClicked={shouldOpenChat}
                    isUseCaseTriggered={isUseCaseTriggered}
                    mainTabConvID={mainTabConvID}
                    setMainTabConvID={setMainTabConvID}
                    setMainTabMessages={setMainTabMessages}
                    popOpenChatDetail={popOpenChatDetail}
                    setPopOpenChatDetail={setPopOpenChatDetail}
                    setIsChatBtnClicked={setIsChatBtnClicked}
                />
            );
    }
    return null;
};

MultibrandChatContainer.propTypes = {
    featureFlags: object.isRequired,
    brand: shape({
        'domain-name': string,
    }).isRequired,
    isFeedbackBtnClicked: bool.isRequired,
    isChatBtnClicked: bool.isRequired,
    isUseCaseTriggered: string.isRequired,
    isGqlQuery: object.isRequired,
    token: string.isRequired,
    mainTabConvID: string.isRequired,
    setMainTabConvID: func.isRequired,
    setMainTabMessages: func.isRequired,
    popOpenChatDetail: object.isRequired,
    setPopOpenChatDetail: func.isRequired,
    setIsChatBtnClicked: func.isRequired,
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    brand: getBrand(state),
    token: getAccessToken(state),
});

export default connect(mapStateToProps)(MultibrandChatContainer);
