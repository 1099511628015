/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const PROCESS_EXPRESS_CHECKOUT = 'mbp-checkout/PROCESS/EXPRESS/CHECKOUT';
const EXPRESS_CHECKOUT_ADD_ITEM_TO_CART_SUCCESS = 'mbp-checkout/EXPRESS/CHECKOUT/ADD/ITEM/SUCCESS';
const EXPRESS_CHECKOUT_ADD_ITEM_TO_CART_FAILED = 'mbp-checkout/EXPRESS/CHECKOUT/ADD/ITEM/FAILED';

export default {
    PROCESS_EXPRESS_CHECKOUT,
    EXPRESS_CHECKOUT_ADD_ITEM_TO_CART_SUCCESS,
    EXPRESS_CHECKOUT_ADD_ITEM_TO_CART_FAILED,
};
