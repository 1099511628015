/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './ShippingInfo-ActionTypes';
import initialState from '../../initialState';

const shippingInfoReducer = (state = initialState.shippingInfo, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_SHOW_SHIPPING_INFO:
            return { ...state, ...!state.showShippingInfo };
        default:
            return state;
    }
};

export default shippingInfoReducer;
