/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const PASSORT_FAILED = 'PASSPORT/PASSORT_FAILED';
export const CLEAR_PASSPORT_ERROR = 'PASSPORT/CLEAR_PASSPORT_ERROR';
export const LOAD_PASSPORT_ERROR = 'PASSPORT/LOAD_PASSPORT_ERROR';
export const LOAD_PASSPORT_LOADER = 'PASSPORT/LOAD_PASSPORT_LOADER';
export const TRIGGER_PASSPORT_SUBSCRIPTION = 'PASSPORT/TRIGGER_PASSPORT_SUBSCRIPTION';
export const SUBSCRIBE_PASSPORT_DATA = 'PASSPORT/SUBSCRIBE_PASSPORT_DATA';
export const GET_PASSPORT_TNC_CONTENT = 'PASSPORT/GET_PASSPORT_TNC_CONTENT';
export const LOAD_PASSPORT_TNC_CONTENT = 'PASSPORT/LOAD_PASSPORT_TNC_CONTENT';
export const TRIGGER_ADD_PASSPORT_BY_REMOVE_GIFTCARD = 'PASSPORT/TRIGGER_ADD_PASSPORT_BY_REMOVE_GIFTCARD';
export const SHOW_PASSPORT_MODAL_TO_REMOVE_GIFTCARD = 'PASSPORT/SHOW_PASSPORT_MODAL_TO_REMOVE_GIFTCARD';
export const HIDE_PASSPORT_MODAL_TO_REMOVE_GIFTCARD = 'PASSPORT/HIDE_PASSPORT_MODAL_TO_REMOVE_GIFTCARD';
export const PASSPORT_PROCESS_START = 'PASSPORT/PASSPORT_PROCESS_START';
export const PASSPORT_PROCESS_END = 'PASSPORT/PASSPORT_PROCESS_END';
export const GET_DYNAMIC_PASSPORT_CHARGE = 'PASSPORT/GET_DYNAMIC_PASSPORT_CHARGE';
export const PASSPORT_BUNDLE_DATA = 'PASSPORT/PASSPORT_BUNDLE_DATA';
export const PASSPORT_CAMPAIGN_DATA = 'PASSPORT/PASSPORT_CAMPAIGN_DATA';
export const PASSPORT_BUNDLE_COLLECTION_DATA = 'PASSPORT/PASSPORT_BUNDLE_COLLECTION_DATA';
export const PASSPORT_SIGN_IN_DATA = 'PASSPORT/PASSPORT_SIGN_IN_DATA';
export const OPEN_PASSPORT_SIDE_BAR = 'PASSPORT/OPEN_PASSPORT_SIDE_BAR';
export const CLOSE_PASSPORT_SIDE_BAR = 'PASSPORT/CLOSE_PASSPORT_SIDE_BAR';
export const SUBSCRIBE_PASSPORT_BUNDLE = 'PASSPORT/SUBSCRIBE_PASSPORT_BUNDLE';
export const PASSPORT_PROMO_APPLIED_SUCCESSFULLY = 'PASSPORT/PASSPORT_PROMO_APPLIED_SUCCESSFULLY';
export const PASSPORT_AB_DATA = 'PASSPORT/PASSPORT_AB_DATA';
export const PASSPORT_SALEFORCE_AB_TEST = 'PASSPORT/PASSPORT_SALEFORCE_AB_TEST';
