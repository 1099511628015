/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './GiftMessageExpressions-ActionTypes';

export const loadGiftExpressions = () => ({
    type: actionTypes.LOAD_GIFT_EXPRESSIONS,
});

export const updateGiftExpressions = (data) => ({
    type: actionTypes.UPDATE_GIFT_EXPRESSIONS,
    data,
});
