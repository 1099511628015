/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import MaskInput from '../../../../../app/pages/Checkout/containers/CheckoutFloral/Partials/TextFieldWrapper/MaskInput';

export function recipientFormFields(recipientData, orderItemDetails) {
    function getfuneralServiceTimeString() {
        if (orderItemDetails && orderItemDetails.sympathy && orderItemDetails.sympathy.viewStartTime) {
            return orderItemDetails.sympathy.viewStartTime;
        }

        return '';
    }

    // country
    let country = recipientData?.country || '';
    if (!country) {
        const countryCode = recipientData?.countryCode || 'US';
        country = countryCode.trim().slice(0, 2);
    }

    // locationType
    let locationType = recipientData?.businessTitle || '';
    if (!locationType) {
        locationType = orderItemDetails?.locationType || '';
    }

    // Phone Number
    let phoneNumber = '';
    if (recipientData?.phoneNo) {
        const maskedPhone = MaskInput.Phone(recipientData.phoneNo);
        phoneNumber = maskedPhone.maskInputVal;
    }

    const email = (recipientData?.email) ? recipientData?.email : '';

    return {
        firstName: recipientData?.firstName || '',
        lastName: recipientData?.lastName || '',
        address1: recipientData?.addressLine1 || '',
        address2: recipientData?.addressLine2 || '',
        locationType: locationType || 'Residence',
        nameOfLocation: recipientData?.organizationName || '',
        state: recipientData?.state || 'AL',
        country,
        zipCode: recipientData?.zipCode || '',
        phone: phoneNumber,
        city: recipientData?.city || '',
        organizationName: recipientData?.organizationName || '',
        viewTime: recipientData?.viewTime || '',
        funeralServiceTime: getfuneralServiceTimeString(),
        email,
    };
}

export default {};
