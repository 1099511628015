/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const RESOURCE_ROOT_FACADE = '/checkout';
const RESOURCE_PAGE_FACADE = '/payment';
const RESOURCE_PAGE_ACTION = '/express/chasepay';

// TODO: review if this is still in use
const getChasePayCheckoutSession = (wcEnv, jwtToken, orderId, params) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE;

    const resourcePage = `${RESOURCE_PAGE_FACADE}/${orderId}${RESOURCE_PAGE_ACTION}`;

    return restClient.getFacade(env, resourcePage, jwtToken, params)
        .then((response) => response);
};

export default getChasePayCheckoutSession;
