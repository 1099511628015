/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as cartActions from './Cart-Actions';
import * as cartOperations from './Cart-Operations';
import reducer from './Cart-Reducer';

// Ducks
import { duplicateItemOperations, duplicateItemActions } from './ducks/DuplicateItem';
import { deleteItemOperations, deleteItemActions } from './ducks/DeleteItem';
import { updateCartOperations, updateCartActions } from './ducks/UpdateCart';

const cartWatcherSagas = [
    ...cartOperations.watchers,
    ...duplicateItemOperations.watchers,
    ...deleteItemOperations.watchers,
    ...updateCartOperations.watchers,
];

const cartDucks = {
    duplicateItem: {
        duplicateItemOperations,
        duplicateItemActions,
    },
    deleteItem: {
        deleteItemOperations,
        deleteItemActions,
    },
    updateCart: {
        updateCartOperations,
        updateCartActions,
    },
};

export {
    cartOperations,
    cartActions,
    cartWatcherSagas,

    // Ducks
    cartDucks,
};

export default reducer;
