/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as billingAddressFormActions from './BillingAddressForm-Actions';
import billingAddressFormReducer from './BillingAddressForm-Reducer';

export { billingAddressFormActions };

export default billingAddressFormReducer;
