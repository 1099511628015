/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
/* eslint-disable import/prefer-default-export */

export const LOAD_SHOPRUNNER = 'mbp-member/LOAD_SHOPRUNNER';
export const LOAD_SR_TOKEN = 'mbp-member/LOAD_SR_TOKEN';
export const CLEAR_SR_TOKEN = 'mbp-member/CLEAR_SR_TOKEN';
export const VALIDATE_SHOPRUNNER = 'mbp-member/VALIDATE_SHOPRUNNER';
