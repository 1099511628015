/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionsTypes from './DuplicateItem-ActionTypes';

export const duplicateItem = (orderItemId, history, pageProcess, callback) => ({
    type: actionsTypes.DUPLICATE_ITEM,
    data: {
        orderItemId,
        history,
        pageProcess,
        callback,
    },
});

export default {};
