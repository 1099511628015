/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const UPDATE_RECIPIENT_FORM = 'UPDATE_RECIPIENT_FORM';
export const UPDATE_FORM = 'UPDATE_FORM';
export const UPDATE_FORM_VALUES = 'UPDATE_FORM_VALUES';
export const UPDATE_FROM_ADDRESS_BOOK = 'UPDATE_FROM_ADDRESS_BOOK';
export const TRIGGER_UPDATE_FROM_ADDRESS_BOOK = 'TRIGGER_UPDATE_FROM_ADDRESS_BOOK';
export const TRIGGER_PROCEED_TO_SAVE = 'mbp-checkout/TRIGGER_PROCEED_TO_SAVE';
export const TRIGGER_SUBMIT_FORM = 'TRIGGER_SUBMIT_FORM';
export const CLERA_UPDATE_FIELDS = 'CLEAR_UPDATE_FIELDS';
export const TRIGGER_CLEAR_UPDATE_FIELDS = 'TRIGGER_CLEAR_UPDATE_FIELDS';
export const CLEAR_UPDATE_FIELDS = 'CLEAR_UPDATE_FIELDS';
export const PREFILL_FORM_ADDRESS = 'PREFILL_FORM_ADDRESS';
export const RECIPIENT_UPDATE_FORM_STATUS = 'RECIPIENT_UPDATE_FORM_STATUS';
export const UPDATED_RECIPIENT_ADDRESS_SUCCESSFULLY = 'UPDATED_RECIPIENT_ADDRESS_SUCCESSFULLY';
export const SAVE_RECIPIENTS_PWA_DESKTOP = 'mbp-checkout/SAVE_RECIPIENTS_PWA_DESKTOP';
export const RECIPIENT_GET_STATE_CITY_DATA = 'mbp-checkout/RECIPIENT_GET_STATE_CITY_DATA';
export const RECIPIENT_GET_VIEW_TIME = 'mbp-checkout/RECIPIENT_GET_VIEW_TIME';
export const RECIPIENT_DELETE_VIEW_TIME = 'mbp-checkout/RECIPIENT_DELETE_VIEW_TIME';
export const SAVE_RECIPIENT_PWA_DESKTOP = 'mbp-checkout/SAVE_RECIPIENT_PWA_DESKTOP';
export const SUBMIT_RECIPIENT_PWA_DESKTOP = 'mbp-checkout/SUBMIT_RECIPIENT_PWA_DESKTOP';
export const SAVE_RECIPIENT_ADDRESS_PRE_CHECKOUT = 'mbp-checkout/SAVE/RECIPIENT/ADDRESS/PRE/CHECKOUT';
export const SAVE_RECIPIENT_ADDRESS_PRE_CHECKOUT_COMPLETED = 'mbp-checkout/SAVE/RECIPIENT/ADDRESS/PRE/CHECKOUT/COMPLETED';
