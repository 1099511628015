/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, select, takeEvery, call, take,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';

import { checkJWT } from '../../../Member/ducks/Auth/Auth-Operations';
import { registerClickStreamEvent } from '../../../../../apis/enterpriseid-apis/registerClickStreamEvent';
import * as clickStreamEventActions from './ClickStreamEvent-Actions';
import * as tagManagerActions from '../TagManager/TagManager-Actions';
import { addToCartSuccess } from '../../../AddToCart/AddToCart-Actions';
import { clickStreamOrderComplete } from '../../../Payment/Payment-Actions';
import { enterpriseIdLoaded } from '../../../Member/ducks/EnterpriseId/EnterpriseId-Actions';
import { enterpriseSaveRecipientGiftMessage } from '../../../Checkout/ducks/Recipient/Recipient-Actions';
import { getEnterpriseId } from '../../../Member/ducks/EnterpriseId/EnterpriseId-Selectors';
import { getBrandName } from '../../../App/ducks/Brand/Brand-Selectors';
import { getFeatureFlag } from '../../../App/ducks/Config/Config-Selectors';
import {
    buildPagePayload,
    buildUserPayload,
    buildExperimentsPayload,
    buildCategoryPayload,
    buildDevicePayload,
    buildProductsPayload,
    buildOrderPayload,
    buildOrderCompletePayload,
    buildProductFiltersPayload,
    generateProductsPayload,
    removeEmptyKeyPairs,
    removeEmptySchemaData,
    buildProductPerRow,
    buildCustomEventPayload,
    buildSalesforceExperimentPayload,
    buildSalesforceProductsPayload,
    buildGiftmessagePayload,
    getGeneratedBrowserUUID,
    buildRecipientsPayload,
    buildLoginMethodCustomPayload,
} from './ClickStreamEvent-Helpers';
import { getClickstreamExperiments } from './ClickStreamEvent-Selectors';
import { addGreetingCardGiftMessage } from '../../../Checkout/ducks/GiftMessage/GiftMessage-Actions';

function* workerClickStreamPageViewEvent(clickStreamEventPayload) {
    try {
        const isClickstream2Enabled = yield select(getFeatureFlag('is-clickstream-2-enabled'));
        if (!isClickstream2Enabled) {
            return;
        }

        const deviceTimeCreated = new Date().toISOString();
        const brand = yield select(getBrandName);

        const eventType = 'clickstream.pageview';
        let eventSubType = 'browse';
        const page = yield call(buildPagePayload, clickStreamEventPayload);

        // added condition if page.type key returned as object while navigating through Nav Bar which is a invalid datatype for the type key.
        if (typeof (page?.type) !== 'string') return;

        if (page.url.includes('checkout')) {
            eventSubType = 'checkout';
        } else if (page.url.includes('account')) {
            eventSubType = 'account';
        }

        const user = yield call(buildUserPayload);
        const experiments = yield call(buildExperimentsPayload);
        const category = buildCategoryPayload(clickStreamEventPayload);
        const device = yield call(buildDevicePayload);
        const products = buildProductsPayload(clickStreamEventPayload);
        const productFilters = yield call(buildProductFiltersPayload, clickStreamEventPayload);
        const order = yield call(buildOrderPayload);
        const browserUUID = yield call(getGeneratedBrowserUUID);

        // restricted call to clickstream pageview if order object is not present in checkout flow
        if (eventSubType === 'checkout' && page?.type !== 'cart' && !Object.entries(order).length) return;

        // jwt and eid can resolve slowly on direct loads, delay calling until payload is complete
        const jwt = yield call(checkJWT);
        let enterpriseId = yield select(getEnterpriseId);
        if (!enterpriseId) {
            yield take(enterpriseIdLoaded().type);
            enterpriseId = yield select(getEnterpriseId);
        }

        const payload = removeEmptySchemaData({
            brand,
            enterpriseId,
            eventType,
            eventSubType,
            deviceTimeCreated,
            page,
            device,
            experiments,
            user,
            productFilters,
            category,
            products,
            order,
            browserUUID,
        });

        // Send it
        yield call(registerClickStreamEvent, { jwt, payload });
    } catch (ex) {
        mbpLogger.logError({
            message: 'clickstream.page-view error',
            function: 'workerClickStreamPageViewEvent',
            appName: process.env.npm_package_name,
            module: 'Clickstream',
            jsError: ex,
        });
    }
}

function* watchClickStreamPageViewEvents() {
    yield takeEvery(tagManagerActions.trackPageView().type, workerClickStreamPageViewEvent);
}

function* workerProductImpressionEvent(clickStreamPayload) {
    try {
        const isClickstream2Enabled = yield select(getFeatureFlag('is-clickstream-2-enabled'));
        if (!isClickstream2Enabled) {
            return;
        }

        const deviceTimeCreated = new Date().toISOString();
        const productsPerRow = buildProductPerRow(clickStreamPayload.payload.productsPayload);
        const products = generateProductsPayload(clickStreamPayload.payload.productsPayload, productsPerRow);
        if (!products || !products.length) return;
        const brand = yield select(getBrandName);
        const user = yield call(buildUserPayload);

        const category = buildCategoryPayload(clickStreamPayload);
        const device = yield call(buildDevicePayload);
        const productFilters = yield call(buildProductFiltersPayload, clickStreamPayload);
        const experiments = yield select(getClickstreamExperiments);

        let eventSubType = clickStreamPayload.payload?.page?.type === 'search' ? 'search-recommendations' : 'browse-recommendations';
        const { type, url } = yield call(buildPagePayload, clickStreamPayload);
        const browserUUID = yield call(getGeneratedBrowserUUID);
        if (clickStreamPayload.payload?.salesforceResponse?.campaign) { eventSubType = 'recently-viewed'; }

        // restricted product impression event call in case pagetype is search and also URL is of different page or pagetype is shipping, payment, review-order in checkout flow.
        if ((type === 'search' && !url.includes('search')) || (url.includes('checkout') && ['shipping', 'payment', 'review-order'].includes(type))) return;

        if (url.includes('checkout')) {
            eventSubType = 'checkout-recommendations';
        } else if (url.includes('account')) {
            eventSubType = 'account-recommendations';
        }
        const jwt = yield call(checkJWT);
        let enterpriseId = yield select(getEnterpriseId);
        if (!enterpriseId) {
            yield take(enterpriseIdLoaded().type);
            enterpriseId = yield select(getEnterpriseId);
        }

        const payload = removeEmptySchemaData({
            eventType: 'clickstream.product-impressions',
            brand,
            deviceTimeCreated,
            user,
            products,
            enterpriseId,
            device,
            eventSubType,
            page: { type, url },
            productFilters,
            experiments,

            // optionals
            category,
            browserUUID,
        });
        yield call(registerClickStreamEvent, { jwt, payload });
    } catch (ex) {
        mbpLogger.logError({
            message: 'Something went wrong POSTing product-impression clickStreamEvent',
            function: 'workerProductImpressionEvent',
            appName: process.env.npm_package_name,
            module: 'Clickstream',
            jsError: ex,
        });
    }
}

function* watchProductImpressionEvent() {
    yield takeEvery(clickStreamEventActions.emitProductImpression().type, workerProductImpressionEvent);
}

function* workerClickStreamAddToCartEvent(addToCartPayload) {
    try {
        const isClickstream2Enabled = yield select(getFeatureFlag('is-clickstream-2-enabled'));
        if (!isClickstream2Enabled || !addToCartPayload) {
            return;
        }

        const deviceTimeCreated = new Date().toISOString();
        const brand = yield select(getBrandName);
        const device = yield call(buildDevicePayload);
        const user = yield call(buildUserPayload);
        const products = buildProductsPayload(addToCartPayload);
        const experiments = yield select(getClickstreamExperiments);

        const { eventType = '', metaData = {} } = addToCartPayload?.payload || {};
        let enterpriseId = yield select(getEnterpriseId);
        if (!enterpriseId) {
            yield take(enterpriseIdLoaded().type);
            enterpriseId = yield select(getEnterpriseId);
        }

        let eventSubType = 'browse';
        const page = yield call(buildPagePayload, addToCartPayload);
        if (page.url.includes('checkout')) {
            eventSubType = 'checkout';
        } else if (page.url.includes('account')) {
            eventSubType = 'account';
        }

        const jwt = yield call(checkJWT);
        const browserUUID = yield call(getGeneratedBrowserUUID);

        const cartPayload = {
            brand,
            enterpriseId,
            eventType,
            eventSubType,
            deviceTimeCreated,
            page,
            experiments,
            category: { ...metaData?.category },
            products,
            order: { cartId: metaData?.order?.orderId },
            device,
            user,
            browserUUID,
        };
        if (metaData?.zipCode) {
            cartPayload.recipients = [{ zipCode: metaData?.zipCode }];
        }

        const payload = removeEmptySchemaData(cartPayload);

        yield call(registerClickStreamEvent, { jwt, payload });
    } catch (ex) {
        mbpLogger.logError({
            message: 'ClickStreamEvent registration failed for add to cart',
            function: 'workerClickStreamAddToCartEvent',
            appName: process.env.npm_package_name,
            module: 'Clickstream',
            jsError: ex,
        });
    }
}

function* watchClickStreamAddToCartEvent() {
    yield takeEvery(addToCartSuccess().type, workerClickStreamAddToCartEvent);
}

function* workerClickStreamOrderCompleteEvent(paymentPayload) {
    try {
        const isClickstream2Enabled = yield select(getFeatureFlag('is-clickstream-2-enabled'));
        if (!isClickstream2Enabled) {
            return;
        }
        const deviceTimeCreated = new Date().toISOString();
        const orderCompletePayload = yield call(buildOrderCompletePayload, paymentPayload.payload);

        const brand = yield select(getBrandName);
        const device = yield call(buildDevicePayload);
        const user = yield call(buildUserPayload);
        const experiments = yield select(getClickstreamExperiments);

        // added billingEmailId & phone to the order-complete clickstream event.
        user.billingEmailId = orderCompletePayload.billingEmailId;
        user.phone = orderCompletePayload.phone;

        let enterpriseId = yield select(getEnterpriseId);
        if (!enterpriseId) {
            yield take(enterpriseIdLoaded().type);
            enterpriseId = yield select(getEnterpriseId);
        }

        let eventSubType = 'browse';
        const page = yield call(buildPagePayload, orderCompletePayload);
        if (page.url.includes('checkout')) {
            eventSubType = 'checkout';
        } else if (page.url.includes('account')) {
            eventSubType = 'account';
        }

        const jwt = yield call(checkJWT);
        const browserUUID = yield call(getGeneratedBrowserUUID);

        const ocPayload = {
            brand,
            enterpriseId,
            eventType: 'clickstream.order-complete',
            eventSubType,
            deviceTimeCreated,
            page,
            experiments,
            products: orderCompletePayload.products,
            order: orderCompletePayload.order,
            device,
            user,
            browserUUID,
        };

        const payload = removeEmptySchemaData(ocPayload);

        yield call(registerClickStreamEvent, { jwt, payload });
    } catch (ex) {
        mbpLogger.logError({
            message: 'ClickStreamEvent failed for order-complete',
            function: 'workerClickStreamOrderCompleteEvent',
            appName: process.env.npm_package_name,
            module: 'Clickstream',
            jsError: ex,
        });
    }
}

function* watchClickStreamOrderCompleteEvent() {
    yield takeEvery([
        clickStreamOrderComplete().type,
    ], workerClickStreamOrderCompleteEvent);
}

function* workerClickStreamCustomerIdentificationEvent(customerInfoPayload) {
    try {
        const isClickstream2Enabled = yield select(getFeatureFlag('is-clickstream-2-enabled'));
        // restricted firing event if user object dont have email in payload received from auth0 modal.
        if (!isClickstream2Enabled || !customerInfoPayload?.payload?.user?.email) {
            return;
        }

        const deviceTimeCreated = new Date().toISOString();
        const customerIdentificationPayload = {
            payload: {
                page: {
                    type: 'customer-identification',
                    title: 'Customer Identification',
                },
                loginMethod: null,
                ...customerInfoPayload?.payload,
                ...customerInfoPayload?.type,
            },
        };

        const brand = yield select(getBrandName);
        const device = yield call(buildDevicePayload);
        const userInfo = yield call(buildUserPayload);

        let enterpriseId = yield select(getEnterpriseId);
        if (!enterpriseId) {
            yield take(enterpriseIdLoaded().type);
            enterpriseId = yield select(getEnterpriseId);
        }

        let eventSubType = customerIdentificationPayload.payload?.eventSubType || 'login';
        const { user, signInMethod } = customerIdentificationPayload.payload;
        const getReferenceLoginMethod = user?.connection;

        const customEvent = buildLoginMethodCustomPayload(getReferenceLoginMethod);
        const excludedLoginConnections = ['google-oauth2', 'facebook', 'apple', 'email', 'sms'];

        // if user logged in with google or facebook, considered as a login even if user loginsCount is 1.
        if (user && signInMethod !== 'auto' && !excludedLoginConnections.includes(getReferenceLoginMethod) && user?.loginsCount === 1) {
            eventSubType = 'register';
        } else if (signInMethod === 'auto') {
            eventSubType = 'silent-auth';
        } else if (signInMethod === 'promo-banner-email') {
            eventSubType = signInMethod;
        }

        userInfo.emailId = user?.email;
        userInfo.phone = user?.phone;
        userInfo.firstName = user?.firstName;
        userInfo.lastName = user?.lastName;

        const page = yield call(buildPagePayload, customerIdentificationPayload);

        const jwt = yield call(checkJWT);
        const browserUUID = yield call(getGeneratedBrowserUUID);
        const experiments = yield select(getClickstreamExperiments);

        const ciPayload = {
            brand,
            customEvent,
            enterpriseId,
            eventType: 'clickstream.customer-identification',
            eventSubType,
            deviceTimeCreated,
            page,
            device,
            experiments,
            user: userInfo,
            browserUUID,
        };

        const payload = removeEmptySchemaData(ciPayload);

        yield call(registerClickStreamEvent, { jwt, payload });
    } catch (ex) {
        mbpLogger.logError({
            message: 'ClickStreamEvent failed for customer-identification',
            function: 'workerClickStreamCustomerIdentificationEvent',
            appName: process.env.npm_package_name,
            module: 'Clickstream',
            jsError: ex,
        });
    }
}

function* watchClickStreamCustomerIdentificationeEvent() {
    yield takeEvery([clickStreamEventActions.emitCustomerIdentificationEvent().type], workerClickStreamCustomerIdentificationEvent);
}

function* workerClickStreamCustomTrackingEvents(trackEventPayload) {
    try {
        const isClickstream2Enabled = yield select(getFeatureFlag('is-clickstream-2-enabled'));
        if (!isClickstream2Enabled) {
            return;
        }
        // only fire this specific event onetime, need something more scalable to control this in the future
        if (trackEventPayload?.payload?.category === 'Price Transparency Law' && sessionStorage.getItem('pricingUI')) return;
        const deviceTimeCreated = new Date().toISOString();
        const experiments = yield call(buildSalesforceExperimentPayload, trackEventPayload?.payload);
        const { eventSubType = 'unknown' } = trackEventPayload?.payload;
        if (!experiments && eventSubType === 'campaign-tracking') return;
        const brand = yield select(getBrandName);
        const device = yield call(buildDevicePayload);
        const user = yield call(buildUserPayload);
        const customEvent = buildCustomEventPayload(trackEventPayload?.payload);
        const getSalesforceProducts = (payload) => {
            if (payload?.displayType === 'CUST_PRODUCTS_ONLY') {
                const { salesforceResponse, min_products_to_show } = payload;
                return salesforceResponse?.products?.length >= min_products_to_show ? buildSalesforceProductsPayload(payload) : [];
            }
            return buildSalesforceProductsPayload(payload);
        };
        const products = getSalesforceProducts(trackEventPayload?.payload);
        const browserUUID = yield call(getGeneratedBrowserUUID);
        const recipients = yield call(buildRecipientsPayload, trackEventPayload);

        let enterpriseId = yield select(getEnterpriseId);
        if (!enterpriseId) {
            yield take(enterpriseIdLoaded().type);
            enterpriseId = yield select(getEnterpriseId);
        }
        const page = yield call(buildPagePayload, trackEventPayload);

        const jwt = yield call(checkJWT);
        const ctPayload = {
            brand,
            enterpriseId,
            eventType: 'clickstream.custom',
            eventSubType,
            experiments,
            customEvent,
            deviceTimeCreated,
            products,
            page,
            device,
            user,
            browserUUID,
            recipients,
        };

        const payload = removeEmptySchemaData(ctPayload);

        yield call(registerClickStreamEvent, { jwt, payload });
    } catch (ex) {
        mbpLogger.logError({
            message: 'ClickStreamEvent failed for customer tracking events',
            function: 'workerClickStreamCustomTrackingEvents',
            appName: process.env.npm_package_name,
            module: 'Clickstream',
            jsError: ex,
        });
    }
}

function* watchClickStreamCustomTrackingEvents() {
    yield takeEvery([
        clickStreamEventActions.emitCustomTrackingEvent().type,
    ], workerClickStreamCustomTrackingEvents);
}

function* workerGiftMessageEvent(giftMessagePayload) {
    try {
        const brand = yield select(getBrandName);
        const device = yield call(buildDevicePayload);
        const user = yield call(buildUserPayload);
        const browserUUID = yield call(getGeneratedBrowserUUID);
        const giftMessage = yield call(buildGiftmessagePayload, giftMessagePayload?.data);
        const page = yield call(buildPagePayload, giftMessagePayload);
        const deviceTimeCreated = new Date().toISOString();
        let enterpriseId = yield select(getEnterpriseId);
        if (!enterpriseId) {
            yield take(enterpriseIdLoaded().type);
            enterpriseId = yield select(getEnterpriseId);
        }

        const gmPayload = {
            brand,
            browserUUID,
            enterpriseId,
            eventType: 'clickstream.giftmessage',
            eventSubType: 'checkout',
            giftMessage,
            deviceTimeCreated,
            page,
            device,
            user,
        };

        const payload = removeEmptyKeyPairs(gmPayload);
        const jwt = yield call(checkJWT);
        yield call(registerClickStreamEvent, { jwt, payload });
    } catch (ex) {
        mbpLogger.logError({
            message: 'ClickStreamEvent failed for gift message',
            function: 'workerGiftMessageEvent',
            appName: process.env.npm_package_name,
            module: 'Clickstream',
            jsError: ex,
        });
    }
}

function* watchGiftMessageEvent() {
    yield takeEvery([
        addGreetingCardGiftMessage().type,
        enterpriseSaveRecipientGiftMessage().type,
    ], workerGiftMessageEvent);
}

const clickStreamWatchers = [
    fork(watchClickStreamPageViewEvents),
    fork(watchProductImpressionEvent),
    fork(watchClickStreamAddToCartEvent),
    fork(watchClickStreamOrderCompleteEvent),
    fork(watchClickStreamCustomerIdentificationeEvent),
    fork(watchClickStreamCustomTrackingEvents),
    fork(watchGiftMessageEvent),
];

export default clickStreamWatchers;
