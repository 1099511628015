/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, takeLatest, call, select, put,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import Cookies from 'universal-cookie';
import sdkFeatureFlags from 'feature-flags-sdk';

import mbpUtil from 'mbp-api-util';
import * as helper from './App-Helper';
import {
    setCheckoutFeatureFlag,
} from './App-Actions';
import {
    getProfileInfo,
    getUserType,
    getUserRole,
} from '../Global/Global-Selectors';
import { logUserSessionReady } from '../../../Member/ducks/Auth/Auth-Actions';

function getSessionKey() {
    let sessionKey = '';

    if (typeof window !== 'undefined') {
        const cookies = new Cookies();

        const currentDate = new Date();
        const expDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1));

        sessionKey = cookies.get('sessionKey');

        if (!sessionKey) {
            sessionKey = helper.getUniqueKey();
        }

        cookies.set('sessionKey', sessionKey, {
            path: '/',
            encode: String,
            expires: expDate,
        });
    }

    return sessionKey;
}

function* getFlags() {
    let flags = {};
    const profile = yield select(getProfileInfo);
    const device = helper.discoverDeviceType();
    const browser = helper.getBrowser();
    const operatingSystem = helper.getOperatingSystem();
    const userType = yield select(getUserType);
    const userStatus = yield select(getUserRole);
    const email = (profile.email) ? profile.email : '';
    const sessionKey = yield call(getSessionKey);
    const host = (typeof window !== 'undefined') ? window.location.hostname : '';
    const brandDomain = helper.getBrandDomain(host);

    const payload = {
        userKey: sessionKey || 'anonymous',
        userType: userType || 'G',
        userStatus,
        email,
        firstName: profile.firstName,
        lastName: profile.lastName,
        browser,
        operatingSystem,
        brand: brandDomain,
        version: 'rel',
        device,
    };

    const flagEnv = mbpUtil.getEnv('APP_FLAG_ENV') || 'mbp-ui';
    const featureFlagsResponse = yield call(sdkFeatureFlags.getFeatureFlags, {}, flagEnv, payload);

    flags = featureFlagsResponse.data;

    return flags;
}

function* workerLoadCheckoutFeatureFlag() {
    let flags = {};

    try {
        mbpLogger.logDebug({
            function: 'workerLoadCheckoutFeatureFlag',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: 'Load Flag | START',
        });

        flags = yield call(getFlags);

        yield put(setCheckoutFeatureFlag(flags));
    } catch (ex) {
        mbpLogger.logError({
            function: 'workerLoadCheckoutFeatureFlag',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            jsError: ex,
            message: 'Failed to load flag data.',
        });
    }

    return flags;
}

function* watchGetCheckoutFlags() {
    yield takeLatest([
        logUserSessionReady().type,
    ], workerLoadCheckoutFeatureFlag);
}

const watchers = [
    fork(watchGetCheckoutFlags),
];

export {
    watchers,
};

export default {};
