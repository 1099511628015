/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import initialState from '../../../../initialState';
import * as actionTypes from './Error-ActionTypes';

// 123456789: {
//     recipient: {
//         validationErrors: {
//             'recipient.address1': 'Address1 can not be empty.',
//             'recipient.lastName': 'Last Name can not be empty.',
//             'recipient.firstName': 'First Name can not be empty.',
//         },
//         errorMessage: 'Required information is missing for cart item 239630492.',
//     }
//     product: {
//         {
//             errorKey: '274150663',
//             errorMessage: 'Item is not available for delivery in your recipients location. Please change the address or pick a different date.',
//         },
//     }
//     deliveryDate:{}
//     dtw:{}
//     giftMessage:{}
//     validationCart:

const errorReducer = (state = initialState.error, action) => {
    switch (action.type) {
        case actionTypes.SET_ORDER_ITEM_ERROR: {
            return {
                ...state,
                [action.orderItemId]: { ...action.errors },
            };
        }
        case actionTypes.CLEAR_ORDER_ITEM_ERRORS: {
            const { orderItemId, featureType, clearData } = action.data || {};
            const newState = { ...state };

            if (orderItemId && featureType && state[orderItemId] && state[orderItemId][featureType]) {
                // Clean the full feature
                if (clearData.errType === 'clearAllFields' && newState[orderItemId][featureType]) {
                    newState[orderItemId][featureType] = {};
                    return newState;
                }

                // Clean specific field in the feature type
                if (clearData.field) {
                    if (newState[orderItemId][featureType][clearData.field]) {
                        delete newState[orderItemId][featureType][clearData.field];
                    }
                    if (newState[orderItemId][featureType].validationErrors) {
                        delete newState[orderItemId][featureType].validationErrors[clearData.field];
                    }
                }

                if (clearData.field === 'firstAndLastNameErrorMessage') {
                    newState[clearData.firstName] = '';
                    newState[clearData.lastName] = '';
                }

                return newState;
            }

            return { ...state };
        }
        case actionTypes.CLEAR_ALL_ERRORS: {
            return { ...initialState.error };
        }
        default:
            return state;
    }
};

export default errorReducer;
