/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const RESOURCE_PAGE_FACADE = '/cart';
const RESOURCE_ROOT_FACADE = '/checkout';
const RESOURCE_PAGE_ACTION = '/prepare';

const orderPrepare = (wcEnv, jwtToken, orderId) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE;

    const resourcePage = `${RESOURCE_PAGE_FACADE}/${orderId}${RESOURCE_PAGE_ACTION}`;

    return restClient.postFacade(env, resourcePage, jwtToken)
        .then((response) => response);
};

export default orderPrepare;
