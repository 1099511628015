/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import * as appActions from './App-Actions';
import { watchers as appWatcherSagas } from './App-Operations';
import appReducer from './App-Reducer';

const persistConfig = {
    key: 'app',
    storage: sessionStorage,
};

const appRersistReducer = persistReducer(persistConfig, appReducer);

export {
    appActions,
    appWatcherSagas,
};

export default appRersistReducer;
