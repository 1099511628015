/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpLogger from 'mbp-logger';
import restClient from '../../app/helpers/restClient';

export const fetchSubscriptionGiftMessageApi = async (
    token,
    id,
) => {
    const wcEnv = { rootUri: '/' };
    const resourcePage = `retention-subscription/passport/gift-message/${id}`;
    const response = await restClient.getFacade(wcEnv, resourcePage, token);
    delete response.headers;
    delete response.request;
    delete response.config;
    const error = {};
    let updatedMessage = '';
    try {
        updatedMessage = response?.data?.result;
        const responseStatus = response?.status;
        if (responseStatus === 200) {
            error.message = '';
        } else {
            error.message = updatedMessage;
        }
    } catch (err) {
        mbpLogger.logError({
            id,
            function: 'fetchSubscriptionGiftMessageApi',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: 'fetch Subcription Gift Message Failed',
        });
        updatedMessage = response?.data?.result;
        error.message = err.message;
    }
    return {
        updatedMessage,
        error: error.message,
        fetchedSubscriptionMessage: true,
    };
};

export default {};
