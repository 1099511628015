/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, takeEvery, call, put, select,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import memberDucks from '../Member/ducks';
import {
    getOrderId,
    getIfGiftCardApplied,
} from '../Common/Common-Selectors';
import {
    loadCart, getCart, cartLoaded, recalculateGiftCardTotal, callHandleCartFailure, updateCartSnapShot,
} from './Cart-Actions';
import { errorActions, errorOperations } from '../Common/ducks/Error';
import { showLoadingMessageActions } from '../Common/ducks/ShowLoadingMessage';
import { loadCartCount, loadOrderId } from '../Order/Order-Actions';
import cartServices from '../../../apis/cart-service-apis/cartServices';
import {
    clearAuthDependentState,
    logGuestSession,
    logHydraidUserSession,
    logUserLoggedInSuccess,
} from '../Member/ducks/Auth/Auth-Actions';
import takeLeading from '../../../app/helpers/sagas/takeLeading';
import { getFeatureFlags } from '../App/ducks/Config/Config-Selectors';
import { circuitBreaker } from '../Member/ducks/Auth/helper/helper';

const {
    auth: {
        authOperations,
    },
} = memberDucks;

const { checkJWT } = authOperations.workers;

/**
 * Changes the order of the cart (Last In Last Out)
 * the last item added to cart is the last item
 * @param {object} cart
 * @returns {object} reversed cart
 */
function reverseCart(cart) {
    if (cart?.recipients?.length > 0) {
        const cartToReverse = { ...cart };

        const recipients = cartToReverse.recipients.reverse();

        recipients.forEach((orderItem, index) => {
            recipients[index].cartItems = orderItem.cartItems.reverse();
        });

        cartToReverse.recipients = recipients;

        return cartToReverse;
    }

    return cart;
}

function* workerLoadCart(action) {
    const flagState = yield select(getFeatureFlags);
    const cart = JSON.parse(JSON.stringify(action.data || {}));

    if (flagState['is-cart-reverse-enabled']) {
        const cartReversed = yield call(reverseCart, cart);

        yield put(updateCartSnapShot(cartReversed));
    } else {
        yield put(updateCartSnapShot(cart));
    }
}

function* watchLoadCart() {
    yield takeLeading(loadCart().type, workerLoadCart);
}

/**
 * Handle Cart Failure
 * Cart Failure occurs when stale OrderId is used.
 * Try to rehydraid session from localstorage or redirect to homepage
*/
export function* handleCartFailure(action) {
    const logOrderId = yield select(getOrderId);
    const { error } = action.data;
    let errorKey = '';

    if (error?.response?.data?.detailedError?.errorKey) {
        errorKey = error?.response?.data?.detailedError?.errorKey;
    } else if (error?.response?.data?.errorKey) {
        errorKey = error?.response?.data?.errorKey;
    }

    if (errorKey === 'ERROR_NOT_AUTHORIZED_TO_ACCESS_CART' || errorKey === 'ERROR_INVALID_CART_STATE') {
        mbpLogger.logError({
            function: 'handleCartFailure',
            appName: process.env.npm_package_name,
            module: 'Cart-Operations.js',
            message: `Error - ${errorKey}`,
            orderId: logOrderId,
        });

        yield put(clearAuthDependentState());

        if (window !== 'undefined') {
            window.location.href = '/';
        }
    }
}

function* workerRecalculateGiftCardTotal() {
    const logOrderId = yield select(getOrderId);
    try {
        const isGiftCardApplied = yield select(getIfGiftCardApplied);
        if (isGiftCardApplied) {
            yield put(errorActions.clearErrors({
                errType: 'giftCard',
                field: 'error',
            }));

            const cartId = yield select(getOrderId);
            if (cartId) {
                const jwtToken = yield call(checkJWT);
                yield call(cartServices.calculateCart, { jwtToken, cartId });
            } else {
                mbpLogger.logError({
                    function: 'workerRecalculateGiftCardTotal',
                    appName: process.env.npm_package_name,
                    module: 'Cart-Operations.js',
                    message: 'Recalculate Gift Card Total - No OrderId',
                    orderId: logOrderId,
                });
            }
        }
    } catch (ex) {
        yield call(errorOperations.getError, ex, 'giftCard');
        mbpLogger.logError({
            function: 'workerRecalculateGiftCardTotal',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: 'Recalculate Gift Card Total failed',
            orderId: logOrderId,
            error: ex,
        });
    }
}

function* retrieveCart() {
    const orderId = yield select(getOrderId);
    const flagState = yield select(getFeatureFlags);
    yield put(showLoadingMessageActions.updateShowLoadingMessage());

    try {
        if (flagState['is-checkout-monitoring-enabled']) {
            mbpLogger.logError({
                function: 'retrieveCart',
                appName: process.env.npm_package_name,
                module: 'Cart-Operations',
                message: 'monitor: Call RetrieveCart API - Start',
                orderId,
            });
        }

        yield put(errorActions.clearErrors({
            errType: 'cart',
            field: 'error',
        }));

        if (orderId) {
            const jwtToken = yield call(checkJWT);
            const object = {
                wcEnv: {},
                jwtToken,
                cartId: orderId,
            };

            const cartResponse = yield call(cartServices.getCartOrderDetails, object);

            let cartCount = cartResponse?.data?.orderSize ? Number(cartResponse.data.orderSize) : 0;
            const isCheckoutPage = typeof window !== 'undefined' ? window.location.href.includes('/checkout') : false;
            const isCheckoutCartPage = typeof window !== 'undefined' ? window.location.href.includes('/checkout/cart') : false;
            const isOrderConfirmationPage = typeof window !== 'undefined' ? window.location.href.includes('/order-confirmation') : false;

            if (!isOrderConfirmationPage && (!isCheckoutPage || isCheckoutCartPage) && cartCount <= 0) {
                const payload = {
                    env: {},
                    jwtToken,
                };
                const responseCartCount = yield call(cartServices.getCartCount, payload);
                cartCount = responseCartCount?.data?.itemCount || 0;
            }

            yield put(loadCartCount(Number(cartCount)));
            yield put(loadCart(cartResponse.data));

            // Success
            yield put(cartLoaded());
        }

        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        if (ex.response?.errorKey !== 'ERROR_CART_NOT_FOUND') {
            mbpLogger.logError({
                function: 'retrieveCart',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                jsError: ex,
                message: 'RetrieveCart API failed',
                orderId,
            });
        }
        yield call(errorOperations.getError, ex, 'cart');
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    }
}

function* watchCartRetrieve() {
    yield takeLeading(getCart().type, retrieveCart);
}

function* watcherRecalculateGiftCardTotal() {
    yield takeEvery(recalculateGiftCardTotal().type, workerRecalculateGiftCardTotal);
}

function* watcherHandleCartFailure() {
    yield takeEvery(callHandleCartFailure().type, handleCartFailure);
}

const circuitBreakGetCartInSession = circuitBreaker(2);

export function* getCartInSession(action) {
    try {
        const { accessToken } = action.data;

        if (typeof window !== 'undefined' && accessToken) {
            const isCheckoutPage = window.location.href.includes('/checkout');
            const isOrderConfirmationPage = window.location.href.includes('/order-confirmation');
            const payload = {
                env: {},
                jwtToken: accessToken,
            };

            if (!isOrderConfirmationPage) {
                if (isCheckoutPage) {
                    const responseCart = yield call(cartServices.getCartOrderDetails, {
                        wcEnv: {},
                        jwtToken: accessToken,
                        cartId: '',
                    });
                    const cartId = responseCart.data.cartId;
                    let cartCount = responseCart?.data?.orderSize ? Number(responseCart.data.orderSize) : 0;

                    if (cartCount <= 0) {
                        const responseCartCount = yield call(cartServices.getCartCount, payload);
                        cartCount = responseCartCount?.data?.itemCount || 0;
                    }

                    yield put(loadCart(responseCart.data));
                    yield put(loadOrderId([cartId]));
                    yield put(loadCartCount(Number(cartCount)));
                } else {
                    const responseCart = yield call(cartServices.getCartCount, payload);
                    const cartId = responseCart?.data?.cartId;
                    const cartCount = responseCart?.data?.itemCount || 0;

                    yield put(loadOrderId([cartId]));
                    yield put(loadCartCount(Number(cartCount)));
                }
            }
        } else {
            yield put(loadCart({}));
            yield put(loadOrderId(['']));
            yield put(loadCartCount(0));
        }
    } catch (ex) {
        yield put(loadCart({}));
        yield put(loadOrderId(['']));
        yield put(loadCartCount(0));

        const { response } = ex;
        if (response
            && response.status
            && ['401', '403'].includes(response.status.toString())
            && !circuitBreakGetCartInSession()) {
            mbpLogger.logError({
                function: 'getCartInSession',
                appName: process.env.npm_package_name,
                module: 'cart-Operations',
                message: `getCartInSession Failed - Status code: ${response.status} proceeding to get access token`,
            });

            yield put(clearAuthDependentState(true));

            yield call(checkJWT);
        }

        if (response?.data?.errorKey !== 'ERROR_CART_NOT_FOUND') {
            mbpLogger.logError({
                function: 'getCartInSession',
                appName: process.env.npm_package_name,
                module: 'Auth-Operations.js',
                message: 'Get Cart To rehydraid session failed',
            });
        }
    }
}

function* watcherGetCartInSession() {
    yield takeEvery([
        logHydraidUserSession().type,
        logUserLoggedInSuccess().type,
        logGuestSession().type,
    ], getCartInSession);
}

const watchers = [
    fork(watchCartRetrieve),
    fork(watcherRecalculateGiftCardTotal),
    fork(watcherHandleCartFailure),
    fork(watcherGetCartInSession),
    fork(watchLoadCart),
];

export {
    watchers,
    retrieveCart,
    workerRecalculateGiftCardTotal,
    // Actions
    loadCart,
    getCart,
};
