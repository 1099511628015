/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const RESOURCE_ROOT_FACADE = '/checkout';
const RESOURCE_PAGE_FACADE = '/cgc/v2';
const RESOURCE_PAGE_ACTION = '/greeting/label';

const createLabelGreetingCard = ({
    wcEnv, jwtToken, data,
}) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE;

    const resourcePage = `${RESOURCE_PAGE_FACADE}${RESOURCE_PAGE_ACTION}`;

    return restClient.postFacade(env, resourcePage, jwtToken, data)
        .then((response) => response);
};

export default createLabelGreetingCard;
