/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import Cookies from 'universal-cookie';
import { uuidv4 } from './uuid';

const useBrowserUUID = () => {
    const cookies = new Cookies();

    const setInLocalStorage = (browserUUID) => {
        if (typeof window !== 'undefined' && window.localStorage) {
            window.localStorage.setItem('browserUUID', browserUUID);
        }
    };

    const setInCookie = (browserUUID) => {
        cookies.set('browserUUID', browserUUID);
    };

    const getFromLocalStorage = () => {
        if (typeof window !== 'undefined' && window.localStorage) {
            return window.localStorage.getItem('browserUUID');
        }
        return null;
    };

    const getFromCookie = () => cookies.get('browserUUID');

    let browserUUID = null;
    if (getFromCookie() && !getFromLocalStorage()) {
        browserUUID = getFromCookie();
        setInLocalStorage(browserUUID);
    } else if (getFromLocalStorage() && !getFromCookie()) {
        browserUUID = getFromLocalStorage();
        setInCookie(browserUUID);
    } else if (!getFromLocalStorage() && !getFromCookie()) {
        browserUUID = uuidv4();
        setInCookie(browserUUID);
        setInLocalStorage(browserUUID);
    } else {
        browserUUID = getFromCookie();
        if (getFromCookie() !== getFromLocalStorage()) {
            setInLocalStorage(browserUUID);
        }
    }

    return browserUUID;
};

export default useBrowserUUID;
