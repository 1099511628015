/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const CreditCardTypes = {
    VI: 'Visa',
    MC: 'Master Card',
    DS: 'Discover',
    AX: 'American Express',
    PY: 'PayPal',
    GP: 'Google Pay',
    AP: 'Apple Pay',
    VM: 'Venmo',
};

export default CreditCardTypes;
