/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import reducers from './Config-Reducers';

import * as actions from './Config-Actions';
import * as actionTypes from './Config-ActionTypes';
import * as helpers from './Config-Helpers';
import * as operations from './Config-Operations';
import * as selectors from './Config-Selectors';

export {
    operations, actionTypes, actions, helpers, selectors,
};

export default reducers;
