/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const LOAD_WALLET = 'mbp-member/LOAD_WALLET';
export const SAVE_WALLET = 'mbp-member/SAVE_WALLET';
export const CLEAR_WALLET = 'mbp-member/CLEAR_WALLET';
export const SAVED_WALLET = 'mbp-member/SAVED_WALLET';
export const ADD_CREDIT_CARD_TO_WALLET = 'mbp-member/ADD_CREDIT_CARD_TO_WALLET';
export const SET_CARD_ERROR = 'mbp-member/SET_CARD_ERROR';
export const CLEAR_CARD_ERROR = 'mbp-member/CLEAR_CARD_ERROR';
export const SET_CARD_SET_SAVED_FLAG = 'mbp-member/SET_CARD_SET_SAVED_FLAG';
