/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlineSharp';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {
    bool,
    func,
    object,
    string,
} from 'prop-types';

const useStyles = makeStyles((theme) => ({
    headerText: {
        fontWeight: 'bold',
        fontSize: '20px',
    },
    dialogTitle: ({ styles }) => ({
        color: styles.formColor || '#5f3c86',
        backgroundColor: styles.formBackgroundColor || '#E5E5E5',
        position: 'relative',
    }),
    closeIconWrapper: ({ styles }) => ({
        color: styles.formColor || '#5f3c86',
        position: 'absolute',
        right: '1%',
        top: '15%',
        cursor: 'pointer',
    }),
    dialogContent: ({ styles }) => ({
        backgroundColor: styles.backgroundColor || '#ffffff',
        padding: '7px 24px',
    }),
    checkCircleIcon: {
        fontSize: '4rem',
        color: '#7ed97e',
        display: 'inline-block',
        margin: 'auto',
        fontWeight: '600',
        marginTop: '2rem',
    },
    dialogContentText: {
        color: '#7ed97e',
        fontSize: '1.3rem',
        textAlign: 'center',
    },
    inputField: {
        width: '100%',
    },
    inputFieldInvalidEmail: {
        position: 'relative',
        '&::before': {
            content: '"Please enter valid email Id"',
            position: 'absolute',
            bottom: '-20px',
            width: '100%',
            height: '25px',
            left: 0,
            paddingLeft: '14px',
            backgroundColor: '#f71449',
            zIndex: '999',
            color: 'white',
            fontSize: '11px',
            fontFamily: 'sans-serif',
            lineHeight: '25px',
            fontWeight: '300',
            transition: 'opacity 0.3s ease-in',
            opacity: 1,
        },

    },
    emailFieldText: ({ styles }) => ({
        color: styles.emailFieldColor || '#5f3c86',
        fontWeight: 'bold',
        position: 'relative',
    }),
    inputFieldContainer: {
        marginBottom: '15px',
        marginTop: '5px',
    },
    formControl: {
        width: '100%',
    },
    submitButton: ({ styles }) => ({
        textTransform: 'uppercase',
        color: styles.submitButtonCopyColor || '#ffffff',
        backgroundColor: styles.submitButtonColor || '#5f3c86',
        '&:hover': {
            backgroundColor: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
    }),
    bottomContainer: {
        marginTop: '30px',
        alignItems: 'center',
    },
    headerTextFieldWrapper: {
        marginRight: '10px',
    },
    outerDialog: {
        width: '100%',
    },
    paper: {
        width: '100%',
        minHeight: '300px',
        maxHeight: '300px',
    },
    emailRequiredText: ({ styles }) => ({
        color: styles.emailFieldColor || '#5f3c86',
        fontSize: '16px',
        paddingLeft: '3px',
        paddingTop: '2.5px',
        position: 'relative',
    }),
    emailFieldValueValid: {
        '&::after': {
            position: 'absolute',
            right: 'auto',
            marginLeft: '3px',
            bottom: '1px',
            zIndex: '99',
            backgroundColor: '#00c876',
            color: 'white',
            textAlign: 'center',
            content: '"✓"',
            fontSize: '9px',
            fontWeight: 'bold',
            lineHeight: '17px',
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            animation: 'fadeZoomIn 0.3s ease-in',
            top: '8px',
        },
    },
    buttonContainer: {},
}));

const EmailCaptureWidgetModal = ({
    isOpen,
    currentEmailId,
    handleButtonSubmit,
    closeModal,
    onSubmitCheck,
    emailCaptureWidgetCMSData,
    handleEmailIdChange,
    validEmailId,
    showConfirmation,
}) => {
    const styles = {
        backgroundColor: '#ffffff',
        formColor: '#5f3c86',
        formBackgroundColor: '#E5E5E5',
        emailFieldColor: '#5f3c86',
        submitButtonColor: '#5f3c86',
        submitButtonCopyColor: '#ffffff',
    };

    const classes = useStyles({ styles });

    const emailIdVaildCss = validEmailId && currentEmailId.length > 0 ? classes.emailFieldValueValid : '';

    const showEmailControl = () => (
        <>
            <Grid item container direction="column" className={classes.textFieldContainer}>
                <Grid container item className={classes.typographyContainer}>
                    <Typography className={`${classes.emailFieldText}`} data-testid="email-id-field-label">
                        {emailCaptureWidgetCMSData?.email_field_label || 'Enter an email id'}
                    </Typography>
                    <Typography className={`${classes.emailRequiredText} ${emailIdVaildCss}`}>
                        (required)
                    </Typography>
                </Grid>
                <Grid item className={classes.inputFieldContainer}>
                    <TextField
                        placeholder={emailCaptureWidgetCMSData?.email_field_placeholder || 'Enter an email id'}
                        className={
                            (validEmailId === false && currentEmailId.length > 0) || onSubmitCheck === true
                                ? `${classes.inputField} ${classes.inputFieldInvalidEmail}`
                                : classes.inputField
                        }
                        onChange={handleEmailIdChange}
                        value={currentEmailId}
                        label=""
                        variant="outlined"
                        id="email-input-field"
                        onFocus={() => { }}
                        InputProps={{
                            disableUnderline: true,
                            inputProps: {
                                'data-element': 'email_capture_field',
                            },
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item container direction="column" className={classes.bottomContainer}>
                <Grid item className={classes.buttonContainer}>
                    <Button
                        className={classes.submitButton}
                        onClick={() => handleButtonSubmit(true)}
                        id="email-submit-button"
                        data-testid="email-submit-button"
                    >
                        {emailCaptureWidgetCMSData?.button_label || 'Submit'}
                    </Button>
                </Grid>
            </Grid>
        </>
    );

    const showConfirmationIcon = () => (
        <Grid container direction="column" className={classes.dialogContentContainer}>
            <CheckCircleIcon className={classes.checkCircleIcon} />
        </Grid>
    );

    return (
        <>
            <Dialog
                id="email-capture-modal"
                open={isOpen}
                className={`${classes.outerDialog}`}
                classes={{
                    paper: classes.paper,
                }}
                data-testid="email-capture-modal"
            >
                <DialogTitle className={classes.dialogTitle}>
                    <Grid container className={classes.headerContainer}>
                        <Grid item container className={classes.headerTextContainer}>
                            <Grid item className={classes.headerTextFieldWrapper}>
                                <Typography className={classes.headerText} data-testid="email-modal-form-label">
                                    {showConfirmation ? emailCaptureWidgetCMSData?.widget_confirmation_title || 'Success' : emailCaptureWidgetCMSData?.widget_title || 'Get updates on your email'}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                className={classes.closeIconWrapper}
                                onClick={closeModal}
                                id="email-capture-modal-close-button"
                                data-testid="email-capture-modal-close-button"
                            >
                                <HighlightOffIcon className={classes.closeIcon} />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    { showConfirmation && showConfirmationIcon() }
                    <Grid container direction="column" className={classes.dialogContentContainer}>
                        {showConfirmation ? (<DialogContentText className={classes.dialogContentText}>{emailCaptureWidgetCMSData?.widget_confirmation_message}</DialogContentText>)
                            : showEmailControl()}
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

EmailCaptureWidgetModal.propTypes = {
    isOpen: bool.isRequired,
    currentEmailId: string,
    handleButtonSubmit: func.isRequired,
    onSubmitCheck: bool.isRequired,
    closeModal: func.isRequired,
    emailCaptureWidgetCMSData: object,
    handleEmailIdChange: func,
    validEmailId: bool,
    showConfirmation: bool,
};

EmailCaptureWidgetModal.defaultProps = {
    currentEmailId: '',
    validEmailId: false,
    emailCaptureWidgetCMSData: {},
    handleEmailIdChange: () => {},
    showConfirmation: false,
};

export default EmailCaptureWidgetModal;
