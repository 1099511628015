/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, call, takeEvery, put, select,
} from 'redux-saga/effects';

import mbpLogger from 'mbp-logger';

import * as giftMessageActions from './GiftMessageForm-Actions';
import { errorActions, errorOperations } from '../../../Common/ducks/Error';
import { getOrderId } from '../../../Common/Common-Selectors';
import { showLoadingMessageActions } from '../../../Common/ducks/ShowLoadingMessage';
import * as actionTypes from './GiftMessageForm-ActionTypes';
import { retrieveCart } from '../../../Cart/Cart-Operations';
import { callHandleCartFailure } from '../../../Cart/Cart-Actions';
import orderClient from '../../../../../apis/checkout-apis/orderClient';
import { checkJWT } from '../../../Member/ducks/Auth/Auth-Operations';
import { clearOrderItemErrors } from '../../../Checkout/ducks/Common/ducks/Error/Error-Actions';
import { processOrderItemError } from '../../../Checkout/ducks/Common/ducks/Error/Error-Operations';

const removeItem = ({
    wcEnv,
    jwtToken,
    orderItemId,
    orderId,
}) => orderClient.removeOrderItem(wcEnv, jwtToken, orderItemId, orderId);

const updateGiftMessageAPI = ({
    wcEnv, jwtToken, orderItemId, giftMessage, giftAuthor, occasionCode, orderId, useThisMessageForAll, sympathy,
}) => (
    orderClient.updateCart(
        wcEnv,
        jwtToken,
        orderItemId,
        null,
        null,
        {
            message: giftMessage,
            signature: giftAuthor,
            occasionCode,
            useThisMessageForAll,
        },
        orderId,
        null,
        sympathy,
    )
);

function* addExpressCheckoutGiftMessage(action) {
    try {
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        yield put(errorActions.clearErrors({
            errType: 'giftMessage',
            field: 'error',
        }));
        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: action.data.orderItemId,
        }));
        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: `${action.data.orderItemId}_missing_info`,
        }));

        const jwtToken = yield call(checkJWT);
        const orderId = yield select(getOrderId);
        const configObj = {
            wcEnv: {},
            jwtToken,
            orderItemId: action.data.orderItemId,
            giftMessage: action.data?.giftMessage || '',
            giftAuthor: action.data?.giftAuthor || '',
            occasionCode: null,
            orderId,
        };
        const updateGiftMessageResponse = yield call(updateGiftMessageAPI, configObj);
        if (updateGiftMessageResponse.data.successFlag) {
            yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
            yield put(giftMessageActions.giftMessageUpdatedSuccessfully());
        }
    } catch (ex) {
        mbpLogger.logError({
            orderItem: action.data,
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'addGiftMessage',
            jsError: ex,
            message: 'Add gift message',
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'giftMessage');
    }
}

function* pwaDesktopAddGiftMessage(action) {
    try {
        // Show Site Loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        // Clear Error
        yield put(errorActions.clearErrors({
            errType: 'giftMessage',
            field: 'error',
        }));
        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: action.data.orderItemId,
        }));

        const jwtToken = yield call(checkJWT);
        const orderId = yield select(getOrderId);

        if (action.data.orderGreetingId) {
            const configObj = {
                wcEnv: {},
                jwtToken,
                orderItemId: action.data.orderGreetingId,
                orderId,
            };
            yield call(removeItem, configObj);
        }
        const configObj = {
            wcEnv: {},
            jwtToken,
            orderItemId: action.data.orderItemId,
            giftMessage: action.data.giftMessage,
            giftAuthor: action.data.giftAuthor,
            occasionCode: action.data.occasionCode,
            sympathy: action.data.sympathy,
            orderId,
        };
        // Save Gift Message
        yield call(updateGiftMessageAPI, configObj);

        yield put(giftMessageActions.giftMessageUpdatedSuccessfully());
        yield put(giftMessageActions.resetGiftMessageForm());
    } catch (ex) {
        yield put(callHandleCartFailure(ex));

        mbpLogger.logError({
            orderItem: action.data,
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'addGiftMessage',
            jsError: ex,
            message: 'Add gift message',
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'giftMessage');
    }
}

function* handleGiftMessageChange(action) {
    try {
        switch (action.data.name) {
            case 'message':
                yield put(giftMessageActions.loadGiftMessageChange(action.data.value));
                break;
            case 'author':
                yield put(giftMessageActions.loadAuthorChange(action.data.value));
                break;
            case 'occasion':
                yield put(giftMessageActions.updateOccasion(action.data.value));
                break;
            case 'occasionName':
                yield put(giftMessageActions.updateOccasionName(action.data.value));
                break;
            default:
                break;
        }
    } catch (ex) {
        console.log(ex);
    }
}

// Formatting the full message in text and author.
export function* formatFullGifMessage(giftMsg, msgAuthor = '') {
    if (giftMsg) {
        /*
        * Regex (/\|/g, '\n') is used to insert a new line by replacing Charater |
        * eg "Good Morning | Have a great day | thank you " to Good Morning \n Have a great day \n thank you
        */
        const giftMessage = (giftMsg !== 'No Card Message') ? giftMsg.replace(/\|/g, '\n').trim() : '';
        yield put(giftMessageActions.loadGiftMessageChange(giftMessage));
        yield put(giftMessageActions.loadAuthorChange(msgAuthor.trim()));
    }

    if (giftMsg && msgAuthor) {
        yield put(giftMessageActions.updateFormStatus(true));
    }
}

export function* watchGiftMessageChange() {
    yield takeEvery(actionTypes.TRIGGER_CHANGE_GIFT_MESSAGE, handleGiftMessageChange);
}

function* addGiftMessage(action) {
    try {
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        yield put(errorActions.clearErrors({
            errType: 'giftMessage',
            field: 'error',
        }));
        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: action.data.orderItemId,
        }));
        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: `${action.data.orderItemId}_missing_info`,
        }));
        const isMultiline = (action.data?.isMultiline || false);
        let getGiftMessage = (action.data?.giftMessage || '');
        let giftAuthor = '';
        if (isMultiline) {
            // The Regex get the last position of the pipe that is the author value
            const testMatch = getGiftMessage.match(/[^|_]*$/);

            if (testMatch.length > 0 && testMatch[0] !== 'No Card Message') {
                getGiftMessage = getGiftMessage.replace(/[^|_]*$/, '');
                giftAuthor = `${testMatch[0]}`;
            }
        } else {
            const fullMessage = getGiftMessage.split('From: ');
            if (fullMessage.length > 0 && fullMessage[0] !== 'No Card Message') {
                getGiftMessage = fullMessage[0];
                giftAuthor = fullMessage[1];
            }
        }

        const jwtToken = yield call(checkJWT);
        const orderId = yield select(getOrderId);

        if (action.data.orderGreetingId) {
            const configObj = {
                wcEnv: {},
                jwtToken,
                orderItemId: action.data.orderGreetingId,
                orderId,
            };
            yield call(removeItem, configObj);
        }

        const configObj = {
            wcEnv: {},
            jwtToken,
            orderItemId: action.data.orderItemId,
            giftMessage: getGiftMessage,
            giftAuthor,
            occasionCode: null,
            orderId,
        };

        if (action.data.useThisMessageForAll) {
            configObj.useThisMessageForAll = 'Y';
        }

        yield call(updateGiftMessageAPI, configObj);
        yield call(retrieveCart);
        /*
        const eventAction = (appSource === 'SPC') ? 'SPC_GIFT_MESSAGE_SAVE' : 'PWA_GIFT_MESSAGE_SAVE';
        const promoEventObj = {
            eventCategory: 'Checkout',
            eventAction,
            eventLabel: {
                giftMessage: action.data.giftMessage,
            },
        };
        const { occasion } = action.data;
        if (occasion) {
            promoEventObj.eventLabel.occasion = action.data.occasion;
        }
        yield put(tmDuck.actions.trackEvent(promoEventObj));
        */
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield put(giftMessageActions.giftMessageUpdatedSuccessfully());
    } catch (ex) {
        yield put(callHandleCartFailure(ex));

        mbpLogger.logError({
            orderItem: action.data,
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'addGiftMessage',
            jsError: ex,
            message: 'Add gift message',
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'giftMessage');
    }
}

function* giftMessageUpdate(action) {
    try {
        yield call(addGiftMessage, action);
        if (action.data.closeForm) {
            yield call(action.data.closeForm);
        }
    } catch (ex) {
        mbpLogger.logError({
            orderItem: action.data,
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'giftMessageUpdate',
            jsError: ex,
            message: 'Gift Message Update Failed',
        });
    }
}

function* watchGiftMessageUpdate() {
    yield takeEvery(giftMessageActions.updateGiftMessage().type, giftMessageUpdate);
}

function* enterpriseAddGiftMessage(action) {
    try {
        yield put(clearOrderItemErrors(
            action.data.orderItemId,
            'recipient',
            { errType: 'clearAllFields', field: '' },
        ));

        yield put(clearOrderItemErrors(
            action.data.orderItemId,
            'payment',
            { errType: 'clearAllFields', field: '' },
        ));

        yield put(clearOrderItemErrors(
            action.data.orderItemId,
            'giftMessage',
            { errType: 'clearAllFields', field: '' },
        ));

        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        const isMultiline = (action.data?.isMultiline || false);
        let giftMsgText = (action.data?.giftMessage.message || '');
        let giftAuthor = (action.data?.giftMessage.signature || '');
        if (isMultiline) {
            // The Regex get the last position of the pipe that is the author value
            const testMatch = giftMsgText.match(/[^|_]*$/);

            if (testMatch.length > 0 && testMatch[0] !== 'No Card Message') {
                giftMsgText = giftMsgText.replace(/[^|_]*$/, '');
                giftAuthor = `${testMatch[0]}`;
            }
        } else {
            const fullMessage = giftMsgText.split('From: ');
            if (fullMessage.length > 0 && fullMessage[0] !== 'No Card Message') {
                giftMsgText = fullMessage[0];
                if (fullMessage[1]) {
                    giftAuthor = fullMessage[1];
                }
            }
        }

        const jwtToken = yield call(checkJWT);
        const orderId = yield select(getOrderId);

        if (action.data.orderGreetingId) {
            const configObj = {
                wcEnv: {},
                jwtToken,
                orderItemId: action.data.orderGreetingId,
                orderId,
            };
            yield call(removeItem, configObj);
        }

        const configObj = {
            wcEnv: {},
            jwtToken,
            orderItemId: action.data.orderItemId,
            giftMessage: giftMsgText || 'No Card Message',
            giftAuthor,
            occasionCode: action.data.occasionCode,
            orderId,
        };

        if (action.data.useThisMessageForAll) {
            configObj.useThisMessageForAll = 'Y';
        }

        yield call(updateGiftMessageAPI, configObj);
        if (action.data.setOpenGreetingMessage) {
            yield call(action.data.setOpenGreetingMessage, false);
        }
        yield call(retrieveCart);

        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield put(giftMessageActions.giftMessageUpdatedSuccessfully());
    } catch (ex) {
        yield put(callHandleCartFailure(ex));

        mbpLogger.logError({
            orderItem: action.data,
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'addGiftMessage',
            jsError: ex,
            message: 'Add gift message',
        });

        ex.orderItemId = action.data.orderItemId;
        yield fork(processOrderItemError, {
            data: {
                orderItemId: action.data.orderItemId,
                errorFeatureType: 'giftMessage',
                errorValues: ex.response?.data,
            },
        });

        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    }
}

function* watchEnterpriseAddGiftMessage() {
    yield takeEvery(giftMessageActions.enterpriseAddGiftMessage().type, enterpriseAddGiftMessage);
}

const watchers = [
    fork(watchGiftMessageChange),
    fork(watchGiftMessageUpdate),
    fork(watchEnterpriseAddGiftMessage),
];

export {
    watchers,
    pwaDesktopAddGiftMessage,
    addExpressCheckoutGiftMessage,
    enterpriseAddGiftMessage,
};

export default {};
