/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable import/prefer-default-export */
import Action from './Action';
import { getActionKey } from './ActionType';
// import { workerSagas } from '../sagas';

import configENV from '../../config';

const { ENABLE_CONSOLE_LOG } = configENV;

const wordCase = (string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

const getActionName = (type) => {
    /* istanbul ignore next */
    if (!type) return null;
    const names = type.split('_');
    let prefix = '';
    if (names[0] === 'GET') {
        delete names[0];
        prefix = 'Fetch';
    }
    // if (names[names.length - 1] === 'REQUEST') return '@@REQUEST';
    const newName = ''.concat(prefix, names.map((n) => wordCase(n)).join(''));
    return newName;
};

const successResponse = (state, action) => {
    // Set busy state
    if (ENABLE_CONSOLE_LOG) {
        console.log('REDUCERS', getActionKey(action.type), action);
    }
    if (action.type && action.type.match(/\/REQUEST$/)) {
        if (getActionKey(action.type).match(/^(FETCH_|HANDLE_NAVIGATE_TO)/)) {
            return Action.setBusyFetching(state);
        } if (getActionKey(action.type).match(/^(SET_)/)) {
            return Action.setReadyState(state, action);
        } if (getActionKey(action.type).match(/^THREAD_/)) {
            return state;
        }
        return Action.setBusyState(state);
    }

    // Set ready state if failure response
    if (action.type && action.type.match(/\/FAILURE$/)) {
        if (ENABLE_CONSOLE_LOG) console.log('REDUCERS', action);
        return Action.setReadyState(state, action);
    }

    if (action.type && action.type.match(/\/LOADED$/)) {
        const type = action.type.replace(/\/LOADED$/, '/REQUEST');
        const key = getActionKey(type);
        if (key) {
            const actionName = getActionName(key.replace(/_REQUEST$/, '_SUCCESS'));
            const readyState = { ...state };
            readyState.isReadyState = true;
            readyState.isFetchingModal = key === 'HANDLE_CLOSE_MODAL_REQUEST' ? readyState.isFetchingModal : false;
            readyState.actionType = key;
            if (ENABLE_CONSOLE_LOG) console.log('REDUCERS', actionName, action);
            if (!Action[actionName]) {
                console.log(`Reducers.js: Action[${actionName}] is not defined`);
            } else if (typeof Action[actionName] !== 'function') {
                console.log(`Reducers.js: Action[${actionName}] = ${Action[actionName]} and is not a function`);
            } else {
                return Action[actionName](readyState, action);
            }
        }
    }
    return state;
};

const Reducers = {
    successResponse,
};

export default Reducers;
