/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as profile from './ducks/Profile';
import * as auth from './ducks/Auth';
import * as shopRunner from './ducks/Shoprunner';
import * as common from './ducks/Common';
import * as addressBook from './ducks/AddressBook';
import * as wallet from './ducks/Wallet';
import * as abTesting from './ducks/ABTesting';
import * as enterpriseId from './ducks/EnterpriseId';

export default {
    profile,
    auth,
    shopRunner,
    common,
    addressBook,
    wallet,
    abTesting,
    enterpriseId,
};
