/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import Cookies from 'universal-cookie';

const cookies = new Cookies();
const persistedUserProfile = cookies.get('persisted_user_profile');
// TODO remove after a few weeks
cookies.remove('passport_user_profile');
const isPassportUser = () => {
    if (persistedUserProfile?.passportEndDate
        && persistedUserProfile?.passportFlag
        && persistedUserProfile?.passportStartDate) {
        const expirationDate = new Date(persistedUserProfile.passportEndDate).getTime();
        const today = new Date().getTime();
        if (expirationDate > today) {
            return true;
        }
    }
    return false;
};
const isExpiredPassportUser = () => {
    if (persistedUserProfile?.passportEndDate
        && persistedUserProfile?.passportFlag
        && persistedUserProfile?.passportStartDate) {
        const expirationDate = new Date(persistedUserProfile.passportEndDate).getTime();
        const today = new Date().getTime();
        if (expirationDate < today) {
            return true;
        }
    }
    return false;
};

const getTrackingLabel = (isPassportBundleProduct, passportData, flag) => {
    let label = 'New';
    if (flag) {
        if (persistedUserProfile) {
            const passportFlag = persistedUserProfile?.passportFlag;
            const passportStartDate = persistedUserProfile?.passportStartDate;
            const passportEndDate = persistedUserProfile?.passportEndDate;
            const expiredSubDetails = passportData?.passport_messaging?.user_with_expired_passport_subscription || {};
            const expiringSubDetails = passportData?.passport_messaging?.user_whose_passport_subscription_is_about_to_expire || {};

            if (passportEndDate) {
                const today = new Date().getTime();
                const expDate = new Date(passportEndDate).getTime();
                if (expDate > today) {
                    label = 'Active';
                }
                if (passportFlag && passportStartDate) {
                    if (expiredSubDetails?.message_for_pdpage && expiredSubDetails?.passport_referenced_sku && expDate < today) {
                        label = 'Winback';
                    }
                    if (expiringSubDetails?.message_for_pdpage && expiringSubDetails?.passport_referenced_sku && expDate > today) {
                        const daysDifference = Math.ceil((expDate - today) / (1000 * 60 * 60 * 24));
                        if (daysDifference <= expiringSubDetails?.number_of_days_from_expiration) {
                            label = 'Extend';
                        }
                    }
                }
            }
        }
    }
    if (isPassportBundleProduct?.enable) {
        label = 'Bundle';
    }
    return label;
};

export { persistedUserProfile, isPassportUser, isExpiredPassportUser };
export default getTrackingLabel;
