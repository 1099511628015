/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { node, string } from 'prop-types';
import validateEmailAddress from '../validateEmailAddress';
import LinkOrATag from '../../components/GraphqlComponents/GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import replaceSpecialChar from '../replaceSpecialChar';

// Should only use for testing
export const characterTransforms = [
    {
        // Do not match <tag>char<tag>
        re: new RegExp('[^>]®'),
        char: '®',
        class: 'superscript',
    },
    {
        re: new RegExp('[^>]™'),
        char: '™',
        class: 'trademark',
    },
    {
        re: new RegExp('[^>]©'),
        char: '©',
        class: 'half-size',
    },
];

/**
 *
 * @param {String || Array} search The string, or string within the first index that will be processed
 * @returns {Array} Will return an array of strings and elements. The string will be split up, and all
 *      all characters will be wrapped in <span> tags with appropriate className as described in characterTransforms.
 */
export const transformCharacters = (search) => {
    if (!search || !search?.length) return '';
    let textArray = search;
    if (!Array.isArray(textArray)) {
        textArray = [search];
    }
    let returnArray = replaceSpecialChar(textArray[0], true);
    // Loop through transforms
    characterTransforms.forEach((transform) => {
        let placeholderArray = [];
        // Loop through each string or element
        returnArray.forEach((text) => {
            // Only perform transforms on strings and matches
            if ((typeof text === 'string') && transform.re.test(text)) {
                const iterator = text.matchAll(transform.char);
                let res = iterator.next();
                let prevIndex = -1;
                const ret = [];
                while (!res.done) {
                    // Push previous text
                    ret.push(text.slice(prevIndex + 1, res.value.index));
                    // Push wrapped character
                    ret.push(<span className={transform.class}>{text.charAt(res.value.index)}</span>);
                    prevIndex = res.value.index;
                    res = iterator.next();
                }
                // Push the rest of the sentence
                ret.push(text.slice(prevIndex + 1));
                // Will temporarily hold return values until we finish the current array
                placeholderArray = placeholderArray.concat(ret);
            } else {
                // If no match, still include
                placeholderArray.push(text);
            }
        });
        // Replace the returnArray with the new array, so we can loop through it again looking for the next character
        returnArray = placeholderArray;
    });

    return returnArray;
};

export const renderer = {
    text: ({ children }) => {
        if (typeof children === 'string') {
            return transformCharacters(children);
        }
        return children;
    },
    linkReference: ({ href, children }) => {
        if (validateEmailAddress(href)) {
            return <a href={`mailto:${href}`}>{children}</a>;
        }
        return <LinkOrATag href={href}>{children}</LinkOrATag>;
    },
};

renderer.linkReference.propTypes = {
    href: string,
    children: node.isRequired,
};

renderer.linkReference.defaultProps = {
    href: '',
};

renderer.text.propTypes = {
    children: node.isRequired,
};

export default renderer;
