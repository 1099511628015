/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import track from 'react-tracking';

import Cookies from 'universal-cookie';

import {
    validateDataLayer,
    updateDataLayer,
    trackEvent,
    trackPageHandler,
    getProductListData,
    buildComponentList,
} from '../utils/trackingUtils';

// Handle data layer injection
export const dispatchDataLayerChange = (data) => {
    if (typeof window !== 'undefined' && data) {
        validateDataLayer(data);
        updateDataLayer(data);
    }
};

// Track App
export const trackApp = (name) => track(
    { app: name },
    {
        dispatch: dispatchDataLayerChange,
    },
);

// Track Modules
export const trackModule = (name) => track({ module: name });

// Track Page
export const trackPage = (pageProps) => {
    const handler = trackPageHandler(pageProps);

    return track(handler);
};

// Track Category
export const trackCategoryData = (props = {}) => {
    const { categoryData } = props;
    if (categoryData && categoryData.category) {
        const { category } = categoryData;
        const category_occasion = categoryData?.content?.entries?.[0]?.category_occasion;

        return {
            occasion: category_occasion,
            category,
            action: 'track_category_data',
        };
    }

    return null;
};

// Track Single Product
export const trackProductData = (props = {}, state = {}) => {
    const { product, page } = props;
    let copyOfProduct = null;
    let redirectForSku = false;
    if (product) {
        copyOfProduct = JSON.parse(JSON.stringify(product));
    }
    if (page?.search) {
        redirectForSku = page?.search && page.search.includes('redirect-for-sku');
    }
    if (state?.selectedSku?.id) {
        copyOfProduct.selectedSku = state.selectedSku.id;
    }
    if (copyOfProduct) {
        return {
            redirectForSku,
            product: copyOfProduct,
            action: 'track_product_data',
        };
    }

    return null;
};

// Track Cyo components
export const trackCYOData = (props = {}, state = {}) => {
    const { location } = props;
    const { selectedSku } = state;
    const allComponentItems = selectedSku?.cyo?.cyoGroup.map((group) => group.components) || [];
    let listComponents = null;
    if (allComponentItems.length > 0) {
        listComponents = [].concat([], ...allComponentItems); // concatenating multiple groups to single
    }
    if (listComponents) {
        return {
            items: buildComponentList(listComponents, 'CYO Components', location?.state?.categoryName || location?.state?.categoryPath),
            action: 'track_cyo_data',
        };
    }

    return null;
};

// Track GL Product
export const trackGiftList = ({ recipients, occasionId }) => {
    const products = [];
    (recipients[occasionId] || []).forEach((item) => {
        products.push(...item?.products);
    });

    if (products.length > 0) {
        return {
            items: buildComponentList(products, 'GiftList'),
            action: 'track_gift_list_data',
        };
    }
    return null;
};

// Track Single Product
export const trackAddOns = (addOns) => {
    if (addOns) {
        const addOnsTrackingEvent = {
            addOns,
            action: 'track_addOns_data',
        };

        trackEvent(addOnsTrackingEvent);
    }
};

// Track Product List
export const trackProductListData = ({
    products,
    buildProductDataHandler,
    ...remainingProps
}) => {
    const productList = products || getProductListData({
        buildProductDataHandler,
        ...remainingProps,
    });

    trackEvent({
        products: productList,
        action: 'track_product_listing_data',
    });
};

export const trackData = (props = {}) => {
    const { action } = props;

    if (action) {
        trackEvent(props);
    }
};

export const coreBrowsingPageViewSuccessTest = (props = {}) => {
    let seoData = false;
    const { currentPage, dataLayer } = props;

    if (!currentPage || !dataLayer) {
        return false;
    }

    // Home Page
    if (currentPage.type === 'home') {
        dataLayer.forEach((entry) => {
            const { action } = entry;

            switch (action) {
                case 'track_seo_data':
                    seoData = true;
                    break;
                default:
                    break;
            }
        });

        return seoData;
    }

    // Category
    if (currentPage.type === 'category') {
        let categoryProducts = false;

        dataLayer.forEach((entry) => {
            const { action } = entry;

            switch (action) {
                case 'track_seo_data':
                    seoData = true;
                    break;
                case 'track_product_listing_data':
                    categoryProducts = true;
                    break;
                default:
                    break;
            }
        });

        return (seoData && categoryProducts);
    }

    // Search
    if (currentPage.path && currentPage.path.indexOf('/searchterm') !== -1) {
        let searchProducts = false;

        dataLayer.forEach((entry) => {
            const { action } = entry;

            switch (action) {
                case 'track_product_listing_data':
                    searchProducts = true;
                    break;
                default:
                    break;
            }
        });

        return (searchProducts);
    }

    // Product
    if (currentPage.type === 'product') {
        let product = false;

        dataLayer.forEach((entry) => {
            const { action } = entry;

            switch (action) {
                case 'track_seo_data':
                    seoData = true;
                    break;
                case 'track_product_data':
                    product = true;
                    break;
                default:
                    break;
            }
        });

        return (seoData && product);
    }

    // Content
    if (currentPage.type === 'content' || currentPage.type === 'content-page') {
        dataLayer.forEach((entry) => {
            const { action } = entry;

            switch (action) {
                case 'track_seo_data':
                    seoData = true;
                    break;
                default:
                    break;
            }
        });

        return seoData;
    }

    return false;
};

export const checkoutPageViewSuccessTest = (props = {}) => {
    let cartItems = false;
    const { dataLayer } = props;

    if (!dataLayer) {
        return false;
    }

    dataLayer.forEach((entry) => {
        const { action } = entry;

        switch (action) {
            case 'track_order_data':
                cartItems = true;
                break;
            default:
                break;
        }
    });

    return (cartItems);
};

// Eliminate user tracking all-together
export const handleDoNotSellMyInformationClick = () => {
    const cookies = new Cookies();
    const current = new Date();
    const next2Years = new Date();
    next2Years.setFullYear(current.getFullYear() + 2); // 2 years
    cookies.set('DoNotSell', 'Yes', {
        expires: next2Years,
    });
};
