/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const LOAD_ITEM_ADDED =  'ADDTOCART/LOAD_ITEM_ADDED';
export const LOAD_ADD_TO_CART_ERROR = 'ADDTOCART/LOAD_ADD_TO_CART_ERROR';
export const CLEAR_ADD_TO_CART_ERROR = 'ADDTOCART/CLEAR_ADD_TO_CART_ERROR';
export const LOAD_ADD_TO_CART_LOADER = 'ADDTOCART/LOAD_ADD_TO_CART_LOADER';
export const ADD_ITEM_TO_CART = 'ADDTOCART/ADD_ITEM_TO_CART';
export const ADD_ITEM_TO_CART_SUCCESS = 'ADDTOCART/ADD_ITEM_TO_CART_SUCCESS';
export const ADD_ITEM_TO_CART_FAILED = 'ADDTOCART/ADD_ITEM_TO_CART_FAILED';
export const LOAD_ADD_TO_CART_GIFT_MESSAGE = 'ADDTOCART/LOAD_ADD_TO_CART_GIFT_MESSAGE';
export const ADD_ADDONS_TO_CART = 'ADDTOCART/ADD_ADDONS_TO_CART';
export const ADD_WRAPUPS_TO_CART = 'ADDTOCART/ADD_WRAPUPS_TO_CART';
export const ADD_ADDONS_AND_WRAPUP_TO_CART = 'ADDTOCART/ADD_ADDONS_AND_WRAPUP_TO_CART';
export const BUY_AGAIN = 'BUY_AGAIN';
