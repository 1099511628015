/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, takeEvery, put, call, take, select, takeLatest,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import * as errorActions from '../../../Common/ducks/Error/Error-Actions';
import * as showLoadingMessageActions from '../../../Common/ducks/ShowLoadingMessage/ShowLoadingMessage-Actions';

// TODO: Convert and use actions
import { errorOperations } from '../../../Common/ducks/Error';
import { retrieveCart } from '../../../Cart/Cart-Operations';

import * as formActions from './RecipientForm-Actions';
import { recipientFormFields } from './RecipientForm-Helpers';
import { shippingInfoActions } from '../ShippingInfo';
import { updateBillingAddressAsDeliveryAddress, updateBillingFormAutoFilled, updateCheckBillingAddressSameForm } from '../../../Checkout/ducks/Payment/ducks/BillingAddressForm/BillingAddressForm-Actions';
import { qasAddressSearchOperations } from '../../../Common/ducks/AddressSearchQAS';
import * as commonSelectors from '../../../Common/Common-Selectors';
import { pwaDesktopAddGiftMessage, formatFullGifMessage } from '../GiftMessageForm/GiftMessageForm-Operations';
import { updateWorkingRecipient, triggerValidateOrder, triggerValidateOrderCompleted } from '../../Recipient-Actions';
import { giftMessageFormSelectors } from '../GiftMessageForm';
import { getInternationalCountryField, checkIsInternational, getCountryField } from '../../../Checkout/ducks/Checkout/Checkout-Helper';
import { addCardIsleToCart, loadCardIsleAvailable } from '../CardIsle/CardIsle-Operations';
import { resetCardIsle } from '../CardIsle/CardIsle-Actions';
import { prepareRetreiveCart } from '../../../Checkout/ducks/Checkout/Checkout-Operations';
import { resetGiftMessageForm } from '../GiftMessageForm/GiftMessageForm-Actions';
import { getCardIsleSelected } from '../CardIsle/CardIsle-Selector';

import { callHandleCartFailure } from '../../../Cart/Cart-Actions';
import orderClient from '../../../../../apis/checkout-apis/orderClient';
import { checkJWT } from '../../../Member/ducks/Auth/Auth-Operations';
import {
    getGuestAddressBookEntries, getIsAuthenticatedStatus, getOrderId, getProfileInfo,
} from '../../../Member/ducks/Common/Common-Selectors';
import { loadAddressBook, proccessAddressBookCompleted, saveGuestAddressBook } from '../../../Member/ducks/AddressBook/AddressBook-Actions';
import { setSkipValidateCart } from '../../../Checkout/ducks/Payment/Payment-Actions';
import { getIsWriteScopeCheckout } from '../../../Checkout/ducks/Global/Global-Selectors';
import { getFeatureFlags } from '../../../Checkout/ducks/App/App-Selectors';

const addRecipient = ({
    jwtToken, orderItemId, recipient, entryId, orderId,
}) => orderClient.addRecipient(
    {},
    jwtToken,
    orderItemId,
    recipient,
    entryId,
    orderId,
);

const getStateCityData = ({
    jwtToken, zipCode,
}) => orderClient.getCityStateFromZip(
    {},
    jwtToken,
    zipCode,
);

const getViewTimeData = ({
    jwtToken, orderId, orderItemId, deliveryDate, zipCode, state, country,
}) => orderClient.getViewTime(
    {},
    jwtToken,
    orderItemId,
    orderId,
    deliveryDate,
    zipCode,
    state,
    country,
);

const deleteViewTimeData = ({ jwtToken, orderId, orderItemId }) => orderClient.deleteViewTime(
    {},
    jwtToken,
    orderItemId,
    orderId,
);

const updateRecipientCart = ({
    wcEnv, jwtToken, orderItemId, giftMessage, giftAuthor, occasionCode, orderId, useThisMessageForAll, sympathy,
}) => (
    orderClient.updateCart(
        wcEnv,
        jwtToken,
        orderItemId,
        null,
        null,
        {
            message: giftMessage,
            signature: giftAuthor,
            occasionCode,
            useThisMessageForAll,
        },
        orderId,
        null,
        sympathy,
    )
);

const updateFuneralTime = ({
    wcEnv, jwtToken, orderItemId, orderId, sympathy,
}) => (
    orderClient.updateCart(
        wcEnv,
        jwtToken,
        orderItemId,
        null,
        null,
        null,
        orderId,
        null,
        sympathy,
    )
);

const isCandaZip = (zipcode) => new RegExp(/^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[- ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}/).test(zipcode);

const getFormattedZipCode = (zipCode) => {
    let zipCodeFormatted = zipCode;
    if ((zipCodeFormatted.length === 6 || zipCodeFormatted.length === 7) && isCandaZip(zipCodeFormatted)) {
        const zipSplit = zipCodeFormatted.replace(/\s/gi, '').toUpperCase().split('');
        zipSplit.splice(3, 0, ' ');
        zipCodeFormatted = zipSplit.join('');
    }
    return zipCodeFormatted;
};

function* updateForm(action) {
    try {
        yield put(formActions.updateFormValues(action.data));
    } catch (ex) {
        console.log('EXCEPTION: ', ex);
    }
}

function* updateFormFromAddressBook(action) {
    try {
        yield put(formActions.updateFromAddressBook(action.data));
    } catch (ex) {
        console.log(ex);
    }
}

function* clearFormFields() {
    try {
        yield put(formActions.clearRecipientFormFields());
    } catch (ex) {
        console.log(ex);
    }
}

function* handleProductNotAvailableZipCode() {
    const loggedErrorKeysPayload = yield take(errorActions.loggedErrorKeys().type);
    if (loggedErrorKeysPayload.errorKeys.indexOf('_ERR_NOT_AVALIABLE_IN_ZIP') >= 0) {
        yield call(retrieveCart);
    }
}

const removeMaskForPhoneNumber = (number) => {
    let strNumber = number.toString().trim();
    strNumber = strNumber.replace(/\s/g, '').replace(/[^0-9+]/g, '');
    if (strNumber.indexOf('+') === 0 && strNumber.indexOf('1') === 1) {
        strNumber = strNumber.substring(2, strNumber.length);
    } else if (strNumber.indexOf('+') >= 0) {
        strNumber = strNumber.replace(/\s/g, '').replace(/[^0-9]/g, '');
    }
    return strNumber;
};

function trimAndCompare(str1, str2) {
    const strVal1 = str1 ? str1.trim() : '';
    const strVal2 = str2 ? str2.trim() : '';
    return (strVal1 === strVal2);
}

function checkIfSavingInternationalProduct(recipientAddress) {
    if (recipientAddress.zipCode === '00000') {
        return true;
    }
    return false;
}

function checkIfAddressPresentIntoGuestAddresslist(guestAddressList, recipientFormDetails) {
    const addressExist = guestAddressList.some((el) => {
        if (el.firstName === recipientFormDetails.firstName
            && el.lastName === recipientFormDetails.lastName
            && el.address1 === recipientFormDetails.address1
            && el.address2 === recipientFormDetails.address2
            && el.locationType === recipientFormDetails.locationType
            && el.state === recipientFormDetails.state
            && el.country === recipientFormDetails.country
            && el.city === recipientFormDetails.city
            && el.zipCode === recipientFormDetails.zipCode
            && el.phone === recipientFormDetails.phone) {
            return true;
        }
        return false;
    });
    return addressExist;
}

// TODO :: remove once save recipient saga is moved to mbp-checkout
export const getGoogleSuggestedAddress = (state) => state?.mfeCheckout?.search?.googleAddressSearch?.selectedAddress;

// TODO :: remove once save recipient saga is moved to mbp-checkout
export function* verifyAddressWithGoogleSuggestion(recipientAddress) {
    const address = {
        ...recipientAddress,
        address1: recipientAddress?.address1 || '',
        address2: recipientAddress?.address2 || '',
        city: recipientAddress?.city || '',
        country: recipientAddress?.country || '',
        state: recipientAddress?.state || '',
        zipCode: recipientAddress?.zipCode || '',
    };

    const googleSuggestedAddress = yield select(getGoogleSuggestedAddress);

    const addressMatched = googleSuggestedAddress.filter((suggestedAddress) => {
        if (
            address?.address1?.toLowerCase() === suggestedAddress?.addressLine1?.toLowerCase()
            && address?.address2?.toLowerCase() === suggestedAddress?.addressLine2?.toLowerCase()
            && address?.city && address?.city?.toLowerCase() === suggestedAddress?.city?.toLowerCase()
            && address?.country && address?.country?.toLowerCase() === suggestedAddress?.country?.toLowerCase()
            && address?.state && address?.state?.toLowerCase() === suggestedAddress?.state?.toLowerCase()
            && address?.zipCode && address?.zipCode?.toLowerCase() === suggestedAddress?.zipcode?.toLowerCase()
        ) {
            return true;
        }

        return false;
    });

    if (addressMatched.length > 0) {
        address.isAddressVerified = 'V';
    }

    return address;
}

function* submitForm(action) {
    try {
        // Show loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        // Clear Error
        yield put(errorActions.clearErrors({
            errType: 'recipientForm',
            field: `${action.data.orderItemId}_productNotAvailable`,
        }));

        yield put(errorActions.clearErrors({
            errType: 'recipientFormClearAllFields',
            field: 'error',
        }));

        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: action.data.orderItemId,
        }));

        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: `${action.data.orderItemId}_missing_info`,
        }));

        yield put(errorActions.clearErrors({
            errType: 'validateBillingAddress',
        }));

        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: 'orderItemError',
        }));

        // Prepare Save Recipient
        let recipientFormDetails = action.data.recipient;
        if (recipientFormDetails.phone) {
            recipientFormDetails.phone = removeMaskForPhoneNumber(recipientFormDetails.phone);
        }

        if (action.data.useAddressForAll) {
            recipientFormDetails.useAddressForAll = 'Y';
        }

        if (recipientFormDetails.zipCode) {
            recipientFormDetails.zipCode = getFormattedZipCode(recipientFormDetails.zipCode);
        }

        if (action.data.isAddressVerified) recipientFormDetails.isAddressVerified = action.data.isAddressVerified;

        if (action.data.dpvIndicator) recipientFormDetails.dpvIndicator = action.data.dpvIndicator;

        const editingRecipient = yield select(commonSelectors.getCartOrder); // getting current saved recipient address
        const orderItemEditing = editingRecipient?.[0]?.cartItems?.[0] || {};
        const skipAddressBookAdd = ['NBCU', 'DDELVR'].includes(orderItemEditing?.itemInfo?.lineItemType);

        if (skipAddressBookAdd) {
            recipientFormDetails.skipAddressBookAdd = skipAddressBookAdd;
        }

        const jwtToken = yield call(checkJWT);
        const orderId = yield select(commonSelectors.getOrderId);

        const isQasFailed = yield select(commonSelectors.getHasQasFailed);
        const featureFlags = yield select(getFeatureFlags);

        // QAS enabled and failed or when google address search is enabled
        if ((featureFlags['is-qas-address-verification-enabled'] && isQasFailed) || featureFlags['is-google-places-enabled']) {
            recipientFormDetails = yield call(verifyAddressWithGoogleSuggestion, recipientFormDetails);
        }

        // Pass entry Id to update address book
        const reqObj = {
            wcEnv: {},
            jwtToken,
            orderItemId: action.data.orderItemId,
            recipient: recipientFormDetails,
            entryId: action.data.entryId,
            orderId,
        };

        // Save Recipient
        yield call(addRecipient, reqObj);

        const isAuthenticated = yield select(getIsAuthenticatedStatus);
        const isInternational = yield call(checkIfSavingInternationalProduct, recipientFormDetails);

        const isWriteScopeCheckout = yield select(getIsWriteScopeCheckout);
        // Registerd User - Load user addressbook
        if (isAuthenticated && isWriteScopeCheckout) {
            yield put(loadAddressBook());
        }

        // Avoid saving recipient address to guest address book if product is international
        // or if is NBCU or if is digital delivery recipient (DDELVR)
        if (!isAuthenticated && !isInternational && !skipAddressBookAdd) {
            const getGuestAddressBook = yield select(getGuestAddressBookEntries);
            let saveGuestAddressBookAddress = true;
            if (getGuestAddressBook && getGuestAddressBook.length > 0) {
                saveGuestAddressBookAddress = false;
                getGuestAddressBook.forEach((addressObject) => {
                    const firstName = trimAndCompare(addressObject.firstName, recipientFormDetails.firstName);
                    const lastName = trimAndCompare(addressObject.lastName, recipientFormDetails.lastName);
                    const address1 = trimAndCompare(addressObject.address1, recipientFormDetails.address1);
                    const address2 = trimAndCompare(addressObject.address2, recipientFormDetails.address2);
                    const locationType = trimAndCompare(addressObject.locationType, recipientFormDetails.locationType);
                    const state = trimAndCompare(addressObject.state, recipientFormDetails.state);
                    const country = trimAndCompare(addressObject.country, recipientFormDetails.country);
                    const zipCode = trimAndCompare(addressObject.zipCode, recipientFormDetails.zipCode);
                    const phone = trimAndCompare(addressObject.phone, recipientFormDetails.phone);
                    const city = trimAndCompare(addressObject.city, recipientFormDetails.city);

                    if (checkIfAddressPresentIntoGuestAddresslist(getGuestAddressBook, recipientFormDetails)) {
                        saveGuestAddressBookAddress = false;
                    } else if (!firstName || !lastName || !address1 || !address2 || !locationType || !state || !country || !zipCode || !phone || !city) {
                        saveGuestAddressBookAddress = true;
                    }
                });
            }
            if (saveGuestAddressBookAddress) {
                yield put(saveGuestAddressBook(recipientFormDetails));
            }
        }

        if (action.data.closeForm) yield call(action.data.closeForm);

        if (action.data.useAddressForAll && action.data.checkSameAsDeliveryAddress) {
            const configObj = {
                wcEnv: {},
                jwtToken,
                orderItemId: action.data.orderItemId,
                giftMessage: 'No Card Message',
                giftAuthor: '',
                occasionCode: null,
                useThisMessageForAll: 'Y',
                orderId,
            };
            yield call(updateRecipientCart, configObj);
        } else if (action.data.openGiftMessage) {
            yield call(action.data.openGiftMessage);
        }

        yield call(clearFormFields);

        yield call(prepareRetreiveCart, action.data.page);

        yield call(retrieveCart);

        if (action.data.checkSameAsDeliveryAddress) {
            const userProfile = yield select(getProfileInfo);
            if (isAuthenticated) {
                reqObj.recipient.email = userProfile.email;
                reqObj.recipient.confirmEmail = userProfile.email;
            }

            yield put(updateBillingAddressAsDeliveryAddress(reqObj.recipient));
            yield put(updateBillingFormAutoFilled(true));
        }

        // Hide Loader
        yield call(shippingInfoActions.triggerShowShippingInfo);

        yield put(formActions.updatedRecipientAddressSuccessfully());
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        yield put(callHandleCartFailure(ex));
        ex.orderItemId = action.data.orderItemId;
        yield fork(errorOperations.getError, ex, 'recipientForm');
        yield call(handleProductNotAvailableZipCode);
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    }
}

/*
    Validate Address with QAS before saving address
*/

function* proceedToSaveRecipientAddress(action) {
    const proceedToSave = yield call(qasAddressSearchOperations.workers.validateAddressQAS, action);
    const { allowSave, addressVerifiedStatus } = proceedToSave;
    if (allowSave) {
        const updateVerification = { ...action };
        updateVerification.data.dpvIndicator = addressVerifiedStatus.dpvIndicator;
        updateVerification.data.isAddressVerified = addressVerifiedStatus.isAddressVerified;
        yield call(submitForm, updateVerification);
    }
}

function* extendAddressBookWorker() {
    yield put(showLoadingMessageActions.updateShowLoadingMessage());
    yield take(proccessAddressBookCompleted().type);
    yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
}

function* recipientFormChangeWatcher() {
    yield takeEvery(formActions.updateForm().type, updateForm);
}

function* recipientFormSubmitWatcher() {
    yield takeEvery(formActions.submitForm().type, submitForm);
}

function* addressBookChangeWatcher() {
    yield takeEvery(formActions.handleAddressBookChange().type, updateFormFromAddressBook);
}

function* clearFieldsWatcher() {
    yield takeEvery(formActions.triggerClearRecipientFormFields().type, clearFormFields);
}

// Extend Addressbook Watcher
function* extendAddressBookWatcher() {
    yield takeEvery(loadAddressBook().type, extendAddressBookWorker);
}

function* proceedToSaveWatcher() {
    yield takeEvery(formActions.proceedToSave().type, proceedToSaveRecipientAddress);
}

/* PWA Desktop Code Start */
function* pwaDesktopSubmitForm(action) {
    try {
        // Show Site Loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        // Clear Error
        yield put(errorActions.clearErrors({
            errType: 'recipientForm',
            field: `${action.data.orderItemId}_productNotAvailable`,
        }));
        yield put(errorActions.clearErrors({
            errType: 'recipientFormClearAllFields',
            field: 'error',
        }));
        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: action.data.orderItemId,
        }));
        yield put(errorActions.clearErrors({
            errType: 'validateBillingAddress',
        }));

        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: 'orderItemError',
        }));

        // Recipient Form Data
        let recipientFormDetails = action.data.recipient;
        const entryId = action.data.recipient?.entryId || '';

        const isAuthenticated = yield select(getIsAuthenticatedStatus);
        const isInternational = yield call(checkIfSavingInternationalProduct, recipientFormDetails);

        if (action.data.useAddressForAll) {
            recipientFormDetails.useAddressForAll = 'Y';
        }

        if (action.data.isAddressVerified) recipientFormDetails.isAddressVerified = action.data.isAddressVerified;
        if (action.data.dpvIndicator) recipientFormDetails.dpvIndicator = action.data.dpvIndicator;

        const editingRecipient = yield select(commonSelectors.getCartOrder); // getting current saved recipient address
        const orderItemEditing = editingRecipient?.[0]?.cartItems?.[0] || {};
        const skipAddressBookAdd = ['NBCU', 'DDELVR'].includes(orderItemEditing?.itemInfo?.lineItemType);

        if (skipAddressBookAdd) {
            recipientFormDetails.skipAddressBookAdd = skipAddressBookAdd;
        }

        const jwtToken = yield call(checkJWT);
        const orderId = yield select(commonSelectors.getOrderId);

        const isQasFailed = yield select(commonSelectors.getHasQasFailed);
        const featureFlags = yield select(getFeatureFlags);

        // QAS enabled and failed or when google address search is enabled
        if ((featureFlags['is-qas-address-verification-enabled'] && isQasFailed) || featureFlags['is-google-places-enabled']) {
            recipientFormDetails = yield call(verifyAddressWithGoogleSuggestion, recipientFormDetails);
        }

        const reqObj = {
            wcEnv: {},
            jwtToken,
            orderItemId: action.data.orderItemId,
            recipient: recipientFormDetails,
            entryId,
            orderId,
        };
        // Save Recipient Address
        yield call(addRecipient, reqObj);

        // Avoid saving recipient address to guest address book if product is international
        // or if is NBCU or if is digital delivery recipient (DDELVR)
        if (!isAuthenticated && !isInternational && !skipAddressBookAdd) {
            const getGuestAddressBook = yield select(getGuestAddressBookEntries);
            let saveGuestAddressBookAddress = true;
            if (getGuestAddressBook && getGuestAddressBook.length > 0) {
                saveGuestAddressBookAddress = false;
                getGuestAddressBook.forEach((addressObject) => {
                    const firstName = trimAndCompare(addressObject.firstName, recipientFormDetails.firstName);
                    const lastName = trimAndCompare(addressObject.lastName, recipientFormDetails.lastName);
                    const address1 = trimAndCompare(addressObject.address1, recipientFormDetails.address1);
                    const address2 = trimAndCompare(addressObject.address2, recipientFormDetails.address2);
                    const locationType = trimAndCompare(addressObject.locationType, recipientFormDetails.locationType);
                    const state = trimAndCompare(addressObject.state, recipientFormDetails.state);
                    const country = trimAndCompare(addressObject.country, recipientFormDetails.country);
                    const zipCode = trimAndCompare(addressObject.zipCode, recipientFormDetails.zipCode);
                    const phone = trimAndCompare(addressObject.phone, recipientFormDetails.phone);
                    const city = trimAndCompare(addressObject.city, recipientFormDetails.city);
                    if (!firstName || !lastName || !address1 || !address2 || !locationType || !state || !country || !zipCode || !phone || !city) {
                        saveGuestAddressBookAddress = true;
                    }
                });
            }

            if (saveGuestAddressBookAddress) {
                recipientFormDetails.firstAndLastName = `${recipientFormDetails.firstName} ${recipientFormDetails.lastName}`;
                yield put(saveGuestAddressBook(recipientFormDetails));
            }
        }

        yield call(clearFormFields);

        if (action.data.checkSameAsDeliveryAddress) {
            const userProfile = yield select(getProfileInfo);

            if (isAuthenticated) {
                reqObj.recipient.email = userProfile.email;
                reqObj.recipient.confirmEmail = userProfile.email;
                reqObj.recipient.checkSameAsDeliveryAddress = true;
            }

            yield put(updateBillingAddressAsDeliveryAddress(reqObj.recipient));
            yield put(updateBillingFormAutoFilled(true));
            yield put(updateCheckBillingAddressSameForm(true));
        }
        yield call(shippingInfoActions.triggerShowShippingInfo);
        yield put(formActions.updatedRecipientAddressSuccessfully());
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        yield put(callHandleCartFailure(ex));
        ex.orderItemId = action.data.orderItemId;
        yield fork(errorOperations.getError, ex, 'recipientForm');
        yield call(handleProductNotAvailableZipCode);
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        throw ex;
    }
}

function prepareRecipientFormData(recipientFormData, isFullname = false) {
    const recipientObject = {
        entryId: recipientFormData?.entryId || '',
        firstName: recipientFormData.firstName,
        lastName: recipientFormData.lastName,
        address1: recipientFormData.address1,
        address2: recipientFormData.address2,
        locationType: recipientFormData.locationType,
        nameOfLocation: recipientFormData.nameOfLocation,
        state: recipientFormData.state,
        country: recipientFormData.country,
        zipCode: getFormattedZipCode(recipientFormData.zipCode),
        phone: removeMaskForPhoneNumber(recipientFormData.phone),
        city: recipientFormData.city,
        email: recipientFormData.email,
        organizationName: recipientFormData.organizationName,
        funeralServiceTime: recipientFormData.funeralServiceTime,
    };

    if (recipientFormData.firstAndLastName && isFullname) {
        const firstAndLastNameValue = (recipientFormData.firstAndLastName).trim().split(' ');
        recipientObject.firstName = recipientFormData.firstAndLastName;
        recipientObject.lastName = '';

        if (firstAndLastNameValue.length >= 6) {
            recipientObject.firstName = `${firstAndLastNameValue[0]} ${firstAndLastNameValue[1]} ${firstAndLastNameValue[2]}`;
            recipientObject.lastName = `${firstAndLastNameValue[3]} ${firstAndLastNameValue[4]} ${firstAndLastNameValue[5]}`;
        } else if (firstAndLastNameValue.length === 5) {
            recipientObject.firstName = `${firstAndLastNameValue[0]} ${firstAndLastNameValue[1]} ${firstAndLastNameValue[2]}`;
            recipientObject.lastName = `${firstAndLastNameValue[3]} ${firstAndLastNameValue[4]}`;
        } else if (firstAndLastNameValue.length === 4) {
            recipientObject.firstName = `${firstAndLastNameValue[0]} ${firstAndLastNameValue[1]}`;
            recipientObject.lastName = `${firstAndLastNameValue[2]} ${firstAndLastNameValue[3]}`;
        } else if (firstAndLastNameValue.length === 3) {
            recipientObject.firstName = `${firstAndLastNameValue[0]} ${firstAndLastNameValue[1]}`;
            recipientObject.lastName = firstAndLastNameValue[2];
        } else if (firstAndLastNameValue.length >= 2) {
            recipientObject.firstName = firstAndLastNameValue[0];
            recipientObject.lastName = firstAndLastNameValue[1];
        }
    }

    return recipientObject;
}

function prepartRecipientCartSympathy(recipientFormData) {
    const { funeralServiceTime } = recipientFormData;

    if (funeralServiceTime && typeof funeralServiceTime === 'string' && funeralServiceTime.includes('-')) {
        const [viewStartTime, viewEndTime] = funeralServiceTime.split(' - ');
        return {
            sympathySource: 'OTHER',
            viewStartTime,
            viewEndTime,
        };
    }

    if (funeralServiceTime && typeof funeralServiceTime === 'string') {
        return {
            sympathySource: 'OTHER',
            viewStartTime: funeralServiceTime,
            viewEndTime: '',
        };
    }

    return null;
}

function* triggerStateCityData(action) {
    try {
        let formZipCode = action.data.trim();
        let labelZip = 'Zip Code';
        let checkZip = false;

        if ((formZipCode.length === 6 || formZipCode.length === 7) && isCandaZip(formZipCode)) {
            checkZip = true;
            const zipSplit = formZipCode.replace(/\s/gi, '').toUpperCase().split('');
            zipSplit.splice(3, 0, ' ');
            formZipCode = zipSplit.join('');
            labelZip = 'Postal Code';
        } else if (formZipCode.length >= 5) { // This is important for checkout, zipcode from the API returns without the zipcode extension (60015-5253) -  60015 is used for saving the zipcode
            checkZip = true;
        }

        const jwtToken = yield call(checkJWT);
        const reqObj = {
            jwtToken,
            zipCode: formZipCode,
        };

        yield put(errorActions.loadError({ errType: 'recipientForm', errMsg: { zipCode: '' } }));

        if (reqObj && reqObj.zipCode && checkZip) {
            const resStateCityData = yield call(getStateCityData, reqObj);
            const cityStateDate = resStateCityData.data.esbSaltaServiceResponse;

            if (cityStateDate && cityStateDate.checkZipResponse
                && cityStateDate.checkZipResponse.checkZipResult
                && cityStateDate.checkZipResponse.checkZipResult.flwsErrors) {
                yield put(errorActions.loadError({ errType: 'recipientForm', errMsg: { zipCode: `${'Invalid '}${labelZip}` } }));
            }

            if (cityStateDate && cityStateDate.checkZipResponse
                && cityStateDate.checkZipResponse.checkZipResult
                && cityStateDate.checkZipResponse.checkZipResult.location
                && cityStateDate.checkZipResponse.checkZipResult.location.state) {
                const {
                    checkZipResponse: {
                        checkZipResult: {
                            location: {
                                state,
                                country,
                                cityList: {
                                    city,
                                },
                                zipCode,
                            },
                        },
                    },
                } = cityStateDate;
                const cityList = [
                    ...new Set(city),
                ];

                yield put(errorActions.loadError({ errType: 'recipientForm', errMsg: { zipCode: '' } }));

                if (country === 'CAN' || country === 'USA') {
                    yield put(formActions.updateRecipientForm({
                        country: country.slice(0, -1),
                        state,
                        cityArray: cityList,
                        city: cityList[0].toLowerCase().replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()),
                        zipCode,
                    }));
                } else {
                    yield put(formActions.updateRecipientForm({
                        state,
                        cityArray: cityList,
                        city: cityList[0].toLowerCase().replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()),
                        zipCode,
                    }));
                }
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'RecipientForm-Operations.js',
            function: 'triggerStateCityData',
            jsError: ex,
        });
    }
}

function* triggerGetViewTime(action) {
    try {
        const jwtToken = yield call(checkJWT);
        const orderId = yield select(getOrderId);
        const {
            orderItemId, promisedAvailableTime, zipCode, state, country,
        } = action.data;
        let deliveryDate = null;
        const currentDate = promisedAvailableTime ? new Date(promisedAvailableTime) : null;

        if (currentDate) {
            const currentDay = (`0${currentDate.getDate()}`).slice(-2);
            const currentMonth = (`0${currentDate.getMonth() + 1}`).slice(-2);
            deliveryDate = currentDate ? `${currentDate.getFullYear()}-${currentMonth}-${currentDay}` : null;
        }

        const viewTimeResponse = yield getViewTimeData({
            jwtToken, orderId, orderItemId, deliveryDate, zipCode, state, country,
        });

        const { viewTime } = viewTimeResponse.data;

        yield put(formActions.updateRecipientForm({
            viewTime,
        }));
    } catch (ex) {
        yield put(formActions.updateRecipientForm({
            viewTime: '',
        }));
        mbpLogger.logError({
            module: 'RecipientForm-Operations.js',
            function: 'triggerGetViewTime',
            jsError: ex,
        });
    }
}

function* triggerDeleteViewTime(action) {
    try {
        const jwtToken = yield call(checkJWT);
        const orderId = yield select(getOrderId);
        const { orderItemId } = action.data;

        yield deleteViewTimeData({ jwtToken, orderId, orderItemId });
        yield put(formActions.updateRecipientForm({ funeralServiceTime: '' }));
        yield call(retrieveCart);
    } catch (ex) {
        mbpLogger.logError({
            module: 'RecipientForm-Operations.js',
            function: 'triggerDeleteViewTime',
            jsError: ex,
        });
    }
}

function* loadRecipientDetails() {
    try {
        const flagState = yield select(getFeatureFlags);
        const cIEnabled = flagState['is-card-isle-enabled'] || false;

        const [editingRecipient] = yield select(commonSelectors.getCartOrder);
        if (editingRecipient?.recipientAddress) {
            const recipientDetails = editingRecipient.recipientAddress;
            const [cartItems] = editingRecipient?.cartItems || [];
            const isInternational = yield call(checkIsInternational, cartItems);

            // load recipient address for for the next item
            const fillRecipientDetails = yield call(recipientFormFields, recipientDetails, cartItems);

            // load gift message for the next item
            if (editingRecipient?.cartItems?.length > 0 &&  editingRecipient.cartItems[0]?.greetingMessage?.greetingMessage) {
                const giftMessageData = editingRecipient.cartItems[0].greetingMessage.greetingMessage;
                const giftAuthorData = editingRecipient.cartItems[0].greetingMessage.signature || '';
                yield call(formatFullGifMessage, giftMessageData, giftAuthorData);
            }

            // set fields values for internation products
            if (isInternational) {
                const countryField = yield call(getInternationalCountryField, cartItems);
                fillRecipientDetails.country = countryField;
                fillRecipientDetails.state = 'OS';
                fillRecipientDetails.zipCode = '00000';
            } else {
                fillRecipientDetails.country = yield call(getCountryField, recipientDetails?.country);
            }

            if (recipientDetails?.firstName) {
                const { firstName, lastName, phoneNo } = recipientDetails;
                if (firstName && lastName && phoneNo) {
                    fillRecipientDetails.isFormValid = true;
                } else {
                    fillRecipientDetails.isFormValid = false;
                }
                yield put(formActions.updateFormAddressFromPreFill(fillRecipientDetails));
            } else {
                fillRecipientDetails.isFormValid = false;
                yield put(formActions.updateFormAddressFromPreFill(fillRecipientDetails));
            }

            // Load CityList and State from Zipcode
            if (recipientDetails?.zipCode && !isInternational) {
                yield put(formActions.triggerStateCityData(recipientDetails.zipCode));
            }

            if (cIEnabled) {
                // Review the product has card isle available.
                yield call(loadCardIsleAvailable);
            }
        }

        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());

        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'RecipientForm-Operations.js',
            function: 'loadRecipientDetails',
            jsError: ex,
        });
    }
}

function* submitRecipientForm(action) {
    try {
        // Show Site Loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        const {
            orderItemId, history, dpvIndicator, isAddressVerified, checkSameAsDeliveryAddress, orderGreetingId,
        } = action.data;
        const objRecipientFormData = yield select(commonSelectors.getRecipientForm);
        const flagState = yield select(getFeatureFlags);

        const isMinifiedShippingEnabled = flagState['is-minified-shipping-enabled'] || false;

        const objPrepareRecipientFormData = yield call(prepareRecipientFormData, objRecipientFormData, isMinifiedShippingEnabled);
        const sympathy = yield call(prepartRecipientCartSympathy, objRecipientFormData);
        const actionDataSaveRecipient = {
            data: {
                orderItemId,
                recipient: objPrepareRecipientFormData,
                checkSameAsDeliveryAddress,
                useAddressForAll: false,
                dpvIndicator,
                isAddressVerified,
                page: 'shipping',
                avoidClearForm: false,
            },
        };

        // Save Recipient Address Saga
        yield call(pwaDesktopSubmitForm, actionDataSaveRecipient);

        // Add Greeting Card
        // Review if the card is enabled and filled
        let typeGreeting = 'message';
        const cardSelected = yield select(getCardIsleSelected);
        const cIEnabled = flagState['is-card-isle-enabled'] || false;
        if (cIEnabled && cardSelected?.pickupCode) {
            typeGreeting = 'card';
        }

        if (typeGreeting === 'message') {
            // Save gift message
            let payloadGiftMessage = 'No Card Message';
            const giftMessageObj = yield select(giftMessageFormSelectors.getGiftMessageField);
            /*
            * Regex (/\n/g, '|') is used to replace new line with Charater |
            * eg Good Morning \n Have a great day \n thank you to "Good Morning | Have a great day | thank you "
            */
            const messageTextPiped = giftMessageObj.message.replace(/\n/g, '|');

            if (messageTextPiped.replace('|', '').trim().length > 0) {
                payloadGiftMessage = messageTextPiped;
            }

            const occasionCode = giftMessageObj?.occasion || '';
            const actionDataSaveGiftMessage = {
                data: {
                    orderItemId,
                    giftMessage: payloadGiftMessage,
                    giftAuthor: giftMessageObj.author,
                    occasionCode,
                    sympathy,
                    orderGreetingId,
                },
            };

            // Save Gift Message Saga
            yield call(pwaDesktopAddGiftMessage, actionDataSaveGiftMessage);
        } else {
            // Save Card isle
            yield call(addCardIsleToCart, orderItemId, objRecipientFormData);

            if (sympathy) {
                const jwtToken = yield call(checkJWT);
                const orderId = yield select(getOrderId);
                const configObj = {
                    wcEnv: {},
                    jwtToken,
                    orderItemId,
                    orderId,
                    sympathy,
                };

                yield call(updateFuneralTime, configObj);
            }
        }

        if (cIEnabled) {
            yield put(resetCardIsle());
        }
        yield put(resetGiftMessageForm());

        // Get Cart
        yield call(retrieveCart);

        const recipientEditingIndex = yield select(commonSelectors.getWorkingRecipient);
        const orderItemCount = yield select(commonSelectors.getNoOfOrderItems);

        if (recipientEditingIndex < orderItemCount - 1) {
            yield put(updateWorkingRecipient());
            yield call(loadRecipientDetails);
        } else {
            yield put(triggerValidateOrder(history));
            const cartValidated = yield take(triggerValidateOrderCompleted().type);

            if (!cartValidated?.status) {
                yield call(loadRecipientDetails);
            } else {
                yield put(setSkipValidateCart(true));
            }
            yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        }
    } catch (ex) {
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'RecipientForm-Operations.js',
            function: 'submitRecipientForm',
            jsError: ex,
        });
    }
}

function* proceedSaveRecipientPwaDesktop(action) {
    const proceedToSave = yield call(qasAddressSearchOperations.workers.validateAddressQAS, action);
    const { allowSave, addressVerifiedStatus } = proceedToSave;

    if (allowSave) {
        const updateVerification = { ...action };
        updateVerification.data.dpvIndicator = addressVerifiedStatus.dpvIndicator;
        updateVerification.data.isAddressVerified = addressVerifiedStatus.isAddressVerified;

        yield call(submitRecipientForm, updateVerification);
    }
}

function* processSubmitRecipientPWADesktop(action) {
    yield call(submitRecipientForm, action);
}

function* watcherSubmitRecipientPWADesktop() {
    yield takeLatest(formActions.submitRecipientPwaDesktop().type, processSubmitRecipientPWADesktop);
}

function* watcherSaveRecipientPWADesktop() {
    yield takeLatest(formActions.proceedSaveRecipientPwaDesktop().type, proceedSaveRecipientPwaDesktop);
}

function* watcherTriggerStateCityData() {
    yield takeLatest(formActions.triggerStateCityData().type, triggerStateCityData);
}

function* watcherTriggerGetViewTime() {
    yield takeLatest(formActions.triggerGetViewTime().type, triggerGetViewTime);
}

function* watcherTriggerDeleteViewTime() {
    yield takeLatest(formActions.triggerDeleteViewTime().type, triggerDeleteViewTime);
}

/* PWA Desktop Code End */

const watchers = [
    fork(recipientFormSubmitWatcher),
    fork(recipientFormChangeWatcher),
    fork(addressBookChangeWatcher),
    fork(clearFieldsWatcher),
    fork(extendAddressBookWatcher),
    fork(proceedToSaveWatcher),
    fork(watcherSubmitRecipientPWADesktop),
    fork(watcherSaveRecipientPWADesktop),
    fork(watcherTriggerStateCityData),
    fork(watcherTriggerGetViewTime),
    fork(watcherTriggerDeleteViewTime),
];

export { watchers, addRecipient };

export default {};
