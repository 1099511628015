/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from './restClient';

const RESOURCE_ROOT_CARTSERVICE = '/checkout';
const RESOURCE_VERSION_CARTSERVICE = '/ext';
const RESOURCE_PAGE_CARTSERVICE = '/cart';
const RESOURCE_PAGE_ACTION = '/apple/merchant';

/**
 * addToCart
 * url - /checkout/{apiVersion}/cart/apple/merchant?brand={brandId}
 *
 * @param {Object} args
 * @param {String} args.env
 * @param {string} args.jwtToken
 * @param {array} args.item
 */

const getApplePayMerchantId = ({ env, jwtToken, data }) => {
    const envObj = env;
    envObj.rootUri = `${RESOURCE_ROOT_CARTSERVICE}${RESOURCE_VERSION_CARTSERVICE}`;
    const resourcePage = `${RESOURCE_PAGE_CARTSERVICE}${RESOURCE_PAGE_ACTION}`;
    const payload = data;
    return restClient.postRequest(env, resourcePage, jwtToken, payload)
        .then((response) => response);
};

export default getApplePayMerchantId;
