/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpUtil from 'mbp-api-util';

/* eslint-disable import/prefer-default-export */

const brandIdsByHostName = {
    '800flowers': '18F',
    harryanddavid: 'HD',
    '800baskets': '18B',
    simplychocolate: 'SCH',
    fruitbouquets: 'FBQ',
    cheryls: 'CCO',
    personalizationuniverse: 'PZU',
    thepopcornfactory: 'TPF',
    wolfermans: 'WLF',
    stockyards: 'SY',
    berries: 'BRY',
    personalizationmall: 'PML',
    vitalchoice: 'VC',
    florists: 'FL',
};

export const getBrandIdByHostName = (hostname) => {
    let brandId = mbpUtil.getEnv('APP_BRAND_ID') || '18F';

    Object.keys(brandIdsByHostName).forEach((key) => {
        if (hostname.indexOf(key) !== -1) {
            brandId = brandIdsByHostName[key];
        }
    });

    return brandId;
};
