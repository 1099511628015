/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
    bool, object,
} from 'prop-types';
import { getIsAuthenticatedStatus } from '../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getPassportMemberProfile, getPassportData } from '../../../../../state/ducks/Passport/Passport-Selectors';
import { getBrand } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import validatePassport from '../../../../helpers/validatePassport';

const yotpoInstanceId = {
    '18F': '69688',     // 1800 Flowers
    '18B': '93380',     // 1800 Baskets
    HD: '93374',        // Harry and David
    FB: '93376',        // Fruit Bouquets
    WLF: '93377',       // Wolferman
    BRY: '93375',       // Sherries Berries
    CCO: '93378',       // Cheryls
    TPF: '93379',       // The popcorn factory
    SCH: '93381',       // Simply Chocolate
};

const YotpoModules = ({
    isAuthenticated, passportMemberProfile, brand, classes, passportData, history, featureFlags,
}) => {
    const isPassportMember = validatePassport('isActivePassportUser', passportData);
    const [memberProfile, setMemberProfile] = useState(passportMemberProfile);

    useEffect(() => {
        if (isAuthenticated) {
            if (featureFlags['is-passport-enrollment-pg-redirect-enabled'] && memberProfile.membershipType !== passportMemberProfile?.membershipType)  {
                setMemberProfile(passportMemberProfile);
                history.push('/'); // force rerender for yotpo JS
                return;
            }

            if (typeof window !== 'undefined' && window.location) {
                if (window.location.search?.indexOf('reload=true') === -1 && isPassportMember) {
                    const newURL = `${window.location.href}?reload=true`;
                    window.location.replace(newURL);
                }
            }
        }
    }, [isAuthenticated]);

    return (
        <>
            <div
                id="swell-customer-identification"
                data-authenticated={isAuthenticated}
                data-email={passportMemberProfile?.email}
                data-id={passportMemberProfile?.email}
                style={{ display: 'none' }}
            />

            {/* How it works modules (activate Nov 1, 2021) */}
            <div className="yotpo-widget-instance" data-yotpo-instance-id="71082" />
            {/* How it works modules (activate on Jan 1, 2022) */}
            <div className="yotpo-widget-instance" data-yotpo-instance-id="69678" />
            {/* VIP Tier 1 Module (activate on Jan 1, 2022) */}
            <div className="yotpo-vip-status-only">
                <div className="yotpo-widget-instance" data-yotpo-instance-id="71086" />
            </div>
            {/* Earning Points module */}
            <div className={classes.yotpoModule}>
                <div className="yotpo-widget-instance" data-yotpo-instance-id={yotpoInstanceId[brand.code] || '69688'}  />
            </div>

            {/* VIP Tiers 2 Module (Activate on Nov 1, 2021) */}
            <div className="yotpo-widget-instance" data-yotpo-instance-id="71083" />
            {/* VIP Tiers 2 Module (activate on Jan 1, 2022) */}
            <div className="yotpo-widget-instance" data-yotpo-instance-id="69732" />

        </>
    );
};
const mapStateToProps = (state) => ({
    passportData: getPassportData(state),
    isAuthenticated: getIsAuthenticatedStatus(state),
    passportMemberProfile: getPassportMemberProfile(state),
    brand: getBrand(state),
});

YotpoModules.propTypes = {
    passportMemberProfile: object,
    isAuthenticated: bool.isRequired,
    classes: object,
    brand: object.isRequired,
    passportData: object,
    featureFlags: object,
    history: object,
};

YotpoModules.defaultProps = {
    classes: {},
    passportMemberProfile: {},
    passportData: {},
    featureFlags: {},
    history: {},
};
export default connect(mapStateToProps)(withRouter(YotpoModules));
