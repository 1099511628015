/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';

import sessionStorage from 'redux-persist/lib/storage/session'; // defaults to localStorage for web and AsyncStorage for react-native
import paymentReducers from './ducks/Payment';
import AppReducer from './ducks/App';
import errorReducer from './ducks/Common/ducks/Error/index';

const paymentReducerPersistConfig = {
    key: 'payment',
    storage: sessionStorage,
    whitelist: ['paypal', 'billingAddressForm'],
};

const orderItemErrorReducerPersistConfig = {
    key: 'error',
    storage: sessionStorage,
};

const paymentPersistReducer = persistReducer(paymentReducerPersistConfig, paymentReducers);
const orderItemErrorPersistReducer = persistReducer(orderItemErrorReducerPersistConfig, errorReducer);

const checkoutReducers = combineReducers({
    app: AppReducer,
    payment: paymentPersistReducer,
    error: orderItemErrorPersistReducer,
});

const checkoutPersistConfig = {
    key: 'checkout',
    storage: sessionStorage,
    whitelist: ['app'],
};

export default persistReducer(checkoutPersistConfig, checkoutReducers);
