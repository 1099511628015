/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import {
    GET_GOOGLEPAY_CLIENTID,
    LOAD_GOOGLEPAY_CLIENTID,
    CLEAR_GOOGLEPAY_CLIENTID,
    SUBMIT_GOOGLE_PAYMENT,
    USE_ANOTHER_GOOGLEPAY_ACCOUNT,
} from './GooglePay-ActionTypes';

export const getGooglePayClientId = () => ({
    type: GET_GOOGLEPAY_CLIENTID,
});

export const loadGooglePayClientId = (clientId) => ({
    type: LOAD_GOOGLEPAY_CLIENTID,
    data: clientId,
});

export const clearGooglePayClientId = () => ({
    type: CLEAR_GOOGLEPAY_CLIENTID,
});

export const submitGooglePayment = (data) => ({
    type: SUBMIT_GOOGLE_PAYMENT,
    data,
});

export const useAnotherGooglePayAccount = (data) => ({
    type: USE_ANOTHER_GOOGLEPAY_ACCOUNT,
    data,
});

export default {};
