/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';
import { GRAPHQL_ENV } from '..';

/**
 * @param {object} o Object: accepts feed, brand, url, contentType and locale
 * @param {string} o.feed - findContent, findContentPage etc - default: findContent
 * @param {string} o.contentType - department, guidednav, etc  - default: ''
 * @param {string} o.brand - baskets or berries - default: harryanddavid
 * @param {string} o.url - path to be fetched - default: ''
 * @param {string} o.locale - english, spanish, yiddish - default: en-us
 * @param {string} o.reference - includeRefs used for ab testing currently
 */

const findContent = (o) => {
    if (!o) {
        return null;
    }
    const feed = o.feed ? o.feed : 'findContent';
    const brandDomain = o.brand ? o.brand : 'harryanddavid';
    const urlExpr = o.url ? `url: "${o.url}",` : '';
    const contentTypeExpr = o.contentType ? `contentType: "${o.contentType}",` : '';
    const locale = o.locale ? o.locale : 'en-us';
    const typename = o.typename ? o.typename : '';
    const query = o.query ?  `query: "{${o.query}}",` : '';
    const reference = o.reference ? `includeRefs: "${o.reference}"` : '';
    // very important for serializing. add space between bracket and query name + add typenames to query
    return gql`{ ${feed}(brand: "${brandDomain}",  environment: "${GRAPHQL_ENV}", ${urlExpr} ${contentTypeExpr} ${query} ${reference} locale: "${locale}"){
        content
        ${typename}
    }}`;
};

export default findContent;
