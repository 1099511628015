/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { compareAsc } from 'date-fns';

export const determineIfFuneralIsInFuture = (funeralServicesArray) => {
    if (!funeralServicesArray?.length) return true;
    const funeralServicesDates = funeralServicesArray.map((service) => new Date(service.StartDate));
    for (let i = 0; i < funeralServicesDates.length; i += 1) {
        if (compareAsc(new Date(), new Date(funeralServicesDates[i])) === -1) {
            return true;
        }
    }
    return false;
};

export const buildSCIURLPath = (url = '/all-funeral-arrangements-400176493', params = []) => {
    if (params.length === 0) {
        return url;
    }

    let newUrl = url;
    params.forEach((param, i) => {
        if (i === 0) {
            // In the happy path the url passed into this helper function will already have some params included
            // and so new params need to be added with a & to start instead of ?
            if (url.includes('?')) {
                newUrl = `${newUrl}&${param}`;
            } else {
                newUrl = `${newUrl}?${param}`;
            }
        }
        if (param !== '' && i !== 0)  {
            newUrl = `${newUrl}&${param}`;
        }
    });
    return newUrl;
};

export const sciDirectLocIDs = {
    8488: true,
    8292: true,
    8293: true,
    8295: true,
    8411: true,
    1909: true,
    1117: true,
    9825: true,
    2799: true,
    4805: true,
    9850: true,
    2305: true,
    2310: true,
    4360: true,
    4971: true,
    4424: true,
    4583: true,
    1466: true,
    1538: true,
    2760: true,
    2784: true,
    2787: true,
    2790: true,
    2858: true,
    9582: true,
    9795: true,
    1115: true,
    1116: true,
    1114: true,
    5073: true,
    5077: true,
    5076: true,
    5256: true,
    5291: true,
    5292: true,
    5378: true,
    5481: true,
    5782: true,
    5475: true,
    5478: true,
    5482: true,
    5476: true,
    5479: true,
    2880: true,
    2881: true,
    5084: true,
    5083: true,
    5085: true,
    1967: true,
    1110: true,
    1905: true,
    1966: true,
    5067: true,
    5227: true,
    1964: true,
    1981: true,
    1142: true,
    1907: true,
    1982: true,
    2893: true,
    1239: true,
    1957: true,
    1969: true,
    2856: true,
    1904: true,
    1908: true,
    1947: true,
    1980: true,
    1139: true,
    1906: true,
    1945: true,
    1965: true,
    1989: true,
    2854: true,
    5443: true,
    5445: true,
    5499: true,
    1902: true,
    1903: true,
    2853: true,
    2855: true,
    5544: true,
    1963: true,
    1968: true,
    5075: true,
    1901: true,
    5991: true,
    2857: true,
    2859: true,
    2860: true,
    2861: true,
    9576: true,
    9579: true,
    9580: true,
    9581: true,
    9584: true,
    9810: true,
    9811: true,
    9812: true,
    9815: true,
};

export default {};
