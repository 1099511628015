/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import jwtDecode from 'jwt-decode';
import mbpLogger from 'mbp-logger';
import configENV from '../../config';
import { isEmpty } from '../../utils/object';
import brandThemes from '../../../../components/AppShell/brandtheme';
import * as authData from '../../../../../state/ducks/Member/ducks/Auth/Plugin/auth/auth';

const {
    DEV_ENV,
    ACCESS_TOKEN,
    ID_TOKEN,
    IS_AUTHENTICATED,
    PERSIST_KEY,
    SESSION_STORAGE_ENABLED,
} = configENV;

const refactorObjectData = (countries) => {
    const newArray = [];
    Object.keys(countries).forEach((key) => {
        newArray.push({ id: key, name: countries[key] });
    });
    return newArray;
};

const refactorStateData = (states) => {
    const newState = {};
    Object.keys(states).forEach((key) => {
        newState[key] = [];
        Object.keys(states[key]).forEach((k) => {
            newState[key].push({ id: k, name: states[key][k] });
        });
    });
    return newState;
};

const getAuthentication = () => {
    if (DEV_ENV) {
        const isAuthenticated = ACCESS_TOKEN && ID_TOKEN ? IS_AUTHENTICATED : false;
        return {
            isAuthenticated,
            accessToken: ACCESS_TOKEN,
            idToken: ID_TOKEN,
        };
    }
    try {
        const auth = JSON.parse(JSON.parse(sessionStorage.getItem('persist:member')).auth);
        if (!auth.user_profile) {
            auth.idToken = localStorage.getItem('user_profile');
        } else if (!auth.idToken) {
            auth.idToken = auth.user_profile;
        }
        return auth;
    } catch (ex) {
        return {};
    }
};

const getUserEmailFromMultipleLocations = (isAuthenticatedStatus, accessToken, profileInfoEmail, deviceType) => {
    if (isAuthenticatedStatus) {
        // [1] - get email from Redux Store
        let email = profileInfoEmail;
        let errorMessage = '';
        if (!email) {
            errorMessage = `Redux - isAuthenticated: ${isAuthenticatedStatus}, email: ${email}`;
            // [2] - try to get email from jwtToken
            try {
                const jwtDecoded = jwtDecode(accessToken);
                const { emailId } = jwtDecoded?.['https://1800flowers.com/'] || '';
                email = emailId;
            // eslint-disable-next-line no-empty
            } catch (error) { }
            if (!email) {
                errorMessage = `jwtDecode - isAuthenticated: ${isAuthenticatedStatus}, email: ${email}`;
                // [3] - try to retrieve email from sessionStorage
                try {
                    const { profile: profileSessionStorage } = JSON.parse(JSON.parse(sessionStorage.getItem('persist:member'))) || {};
                    ({ email } = profileSessionStorage);
                // eslint-disable-next-line no-empty
                } catch (error) { }
                if (!email) {
                    errorMessage = `sessionStorage - isAuthenticated: ${isAuthenticatedStatus}, email: ${email}`;
                    // [4] - try to retrieve email from localStorage
                    try {
                        const { profile: profileLocalStorage } = JSON.parse(localStorage.getItem('userInfo')) || {};
                        ({ email } = profileLocalStorage);
                    // eslint-disable-next-line no-empty
                    } catch (error) { }
                    if (!email) {
                        errorMessage = `localStorage - isAuthenticated: ${isAuthenticatedStatus}, email: ${email}`;
                    }
                }
            }
        }
        if (errorMessage) {
            // Sent error log when we can't get user's email after logged in
            mbpLogger.logError({
                module: 'mbp-account',
                message: `mbp-account - ${errorMessage}`,
                component: `Saga - ${deviceType}`,
            });
        }
        return email;
    }
    return profileInfoEmail;
};

const setState = (state, content) => {
    const newState = { ...state, ...content };
    if (SESSION_STORAGE_ENABLED === 'on') {
        let { billingAddress, wallet, recipients, orderList } = content;
        const { ContactId } = newState;
        if (
            (billingAddress || wallet || recipients || orderList)
            && ContactId
        ) {
            const accountStorage = sessionStorage.getItem(PERSIST_KEY);
            if (accountStorage) {
                let storageJson = JSON.parse(accountStorage);
                if (!storageJson) storageJson = { [ContactId]: {} };
                if (recipients) {
                    ({ recipients } = newState);
                    storageJson[ContactId] = {

                        ...storageJson[ContactId],
                        recipients,
                        timestamp: new Date().getTime(),
                    };
                }
                if (billingAddress) {
                    ({ billingAddress } = newState);
                    storageJson[ContactId] = {

                        ...storageJson[ContactId],
                        billingAddress,
                        timestamp: new Date().getTime(),
                    };
                }
                if (wallet) {
                    ({ wallet } = newState);
                    storageJson[ContactId] = {

                        ...storageJson[ContactId],
                        wallet,
                        timestamp: new Date().getTime(),
                    };
                }
                if (orderList) {
                    ({ orderList } = newState);
                    storageJson[ContactId] = {

                        ...storageJson[ContactId],
                        orderList,
                        timestamp: new Date().getTime(),
                    };
                }
                sessionStorage.setItem(
                    PERSIST_KEY,
                    JSON.stringify(storageJson),
                );
            }
        }
    }
    return newState;
};

const setSessionStorageObject = (ContactId, object, isSession = true) => {
    const Storage = isSession ? sessionStorage : localStorage;
    const accountStorage = Storage.getItem(PERSIST_KEY);
    let storageJson = accountStorage
        ? JSON.parse(accountStorage)
        : { [ContactId]: {} };
    const commonStorage = storageJson.common;
    if (!storageJson[ContactId] && !commonStorage) {
        storageJson = { [ContactId]: {} };
    } else if (commonStorage) {
        storageJson = { common: commonStorage };
    }
    storageJson[ContactId] = { ...storageJson[ContactId], ...object };
    Storage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
    return object;
};

const getSessionStorageObject = (ContactId, objectKey, isSession = true) => {
    const Storage = isSession ? sessionStorage : localStorage;
    const accountStorage = Storage.getItem(PERSIST_KEY);
    const storageJson = accountStorage
        ? JSON.parse(accountStorage)
        : { [ContactId]: {} };
    if (storageJson[ContactId] && storageJson[ContactId][objectKey]) {
        return storageJson[ContactId][objectKey];
    }
    return {};
};

const getChannelUser = (deviceType, pathname, featureFlag, newWismoOverride) => {
    const userDevice = deviceType === 'mobile' ? 'mobile' : 'desktop';
    const defaultCode = userDevice === 'mobile' ? '30002' : '30001';

    const brands = {
        '1800flowers': { desktop: '30016', mobile: '30026' },
        '1800baskets': { desktop: '30017', mobile: '30027' },
        fruitbouquets: { desktop: '30018', mobile: '30028' },
        simplychocolate: { desktop: '30019', mobile: '30029' },
        berries: { desktop: '30020', mobile: '30030' },
        harryanddavid: { desktop: '30021', mobile: '30031' },
        wolfermans: { desktop: '30022', mobile: '30032' },
        stockyards: { desktop: '30023', mobile: '30033' },
        thepopcornfactory: { desktop: '30024', mobile: '30034' },
        cheryls: { desktop: '30025', mobile: '30035' },
        vitalchoice: { desktop: '30079', mobile: '30080' },
    };

    if (newWismoOverride) return '99998';

    if (featureFlag) return brands?.[pathname]?.[userDevice] || defaultCode;

    return defaultCode;
};

const cartStatusCompleteList = ['published', 'placed', 'paid', 'publishfailed', 'testcart'];

const getCartAttributeByNameAndReturnValue = (attributeList, attributeName) => {
    let attributeValue = '';
    if (attributeList?.length > 0) {
        attributeValue = attributeList.filter((attr) => attr.attributeName === attributeName)?.[0]?.attributeValue || '';
    }

    return attributeValue;
};

const getGiftListItemFromCart = ({ data }) => {
    try {
        const {
            cartId,
            recipients,
            cartStatus,
        } = data;

        let returnData = {};
        if (!isEmpty(recipients)) {
            recipients.forEach((recipient) => {
                const { cartItems, recipientAddress } = recipient;
                if (!isEmpty(cartItems) && !isEmpty(recipientAddress)) {
                    cartItems.forEach((order) => {
                        const {
                            cartItemId,
                            attributes,
                            deliveryInfo,
                            product: {
                                brandCode,
                                productCode: partNumber,
                                parentProductName: name,
                                imagePath,
                                imageName,
                                listPrice,
                                salePrice,
                                parentCatentryId: parentCatalogEntryId,
                            },
                        } = order;
                        // Get product SKU from partNumber: cco_222211 or cco_VDY16_156991
                        const sku = partNumber.replace(/[^-]+-[IP]-/, '').replace(/([^_]+_)*(\d+)$/g, '$2');
                        // Don't add 'c.jpg' if flowersImgName already ended by .jpeg or .png
                        const image = /\.(jpe?g|png)$/.test(imageName) ? `${imagePath}${imageName}` : `${imagePath}${imageName}c.jpg`;
                        const productDetail = {
                            content: '',
                            crossedPrice: (listPrice === salePrice) ? '' : listPrice,
                            discountPrice: (listPrice === salePrice) ? '' : salePrice,
                            id: partNumber,
                            image,
                            imageName,
                            imagePath,
                            name,
                            parentCatalogEntryId,
                            partNumber,
                            price: listPrice,
                            sku,
                            brandCode,
                        };
                        if (cartStatusCompleteList.includes(cartStatus)) {
                            returnData = {};
                        } else {
                            returnData[cartItemId] = {
                                addressId: recipient.addressId,
                                orderId: cartId,
                                orderItemId: cartItemId,
                                parentCatentryId: parentCatalogEntryId,
                                sku,
                                attributes,
                                productDetail,
                                deliveryInfo,
                            };
                        }
                    });
                }
            });
        }
        return returnData;
    } catch (ex) {
        return {};
    }
};

const getItemDetailsFromCart = (orderItemId, productsInCart) => {
    let productDetail = {};
    let hasDeliveryDate = false;
    if (!isEmpty(productsInCart)) {
        // eslint-disable-next-line consistent-return
        Object.keys(productsInCart).forEach((addressId) => {
            const productInCart = productsInCart[addressId];
            const { deliveryInfo, attributes } = productInCart;
            const giftHistoryOrderItemId = getCartAttributeByNameAndReturnValue(attributes, 'giftHistoryOrderItemId') || null;
            if (orderItemId === giftHistoryOrderItemId) {
                ({ productDetail } = productInCart);
                hasDeliveryDate = deliveryInfo && deliveryInfo.deliveryDate !== '';
            }
        });
    }
    return {
        hasDeliveryDate,
        productDetail,
    };
};

const updateRecipientsFromCartItems = (recipients, occasionId, productsInCart) => {
    const newRecipients = recipients;
    // Adding default value of addToCart and cartDetail to recipient object
    if (!isEmpty(newRecipients[occasionId])) {
        newRecipients[occasionId].forEach((item, key) => {
            newRecipients[occasionId][key].addToCart = '';
            newRecipients[occasionId][key].cartDetail = {};
        });
    }
    if (!isEmpty(productsInCart)) {
        Object.keys(productsInCart).forEach((orderItemId) => {
            const productInCart = productsInCart[orderItemId];
            // Get giftHistoryOrderItemId from productInCart
            const { attributes } = productInCart;
            const giftHistoryOrderItemId = getCartAttributeByNameAndReturnValue(attributes, 'giftHistoryOrderItemId') || null;

            // Update recipient object by adding: addToCart & cartDetail object
            // cartDetail object will be used as an item in cart indicator for Gift List
            // Look into DesktopRecipientDetails.js, MobileRecipientDetails.js, and ProductAddedToCart.js for more info
            if (!isEmpty(newRecipients[occasionId]) && giftHistoryOrderItemId) {
                newRecipients[occasionId].forEach((item, key) => {
                    if (item.orderItemId === giftHistoryOrderItemId) {
                        const { products } = item;
                        const sku = productInCart.sku.replace(/-P-/, '-I-').replace(/([^_]+_)*(\d+)$/g, '$2'); // Get product SKU from partNumber: cco_222211 or cco_VDY16_156991
                        newRecipients[occasionId][key].addToCart = sku;
                        newRecipients[occasionId][key].cartDetail = {
                            orderId: productInCart.orderId,
                            orderItemId: productInCart.orderItemId,
                            skuCatentryId: productInCart.parentCatentryId,
                        };
                        products.forEach((prod) => {
                            if (prod.partNumber === sku) {
                                newRecipients[occasionId][key].cartDetail.productDetail = {
                                    ...prod,
                                };
                            }
                        });
                        // If users added product by product number
                        if (isEmpty(newRecipients[occasionId][key].cartDetail.productDetail)) {
                            newRecipients[occasionId][key].cartDetail.productDetail = productInCart.productDetail;
                        }
                    }
                });
            }
        });
    }
    return newRecipients;
};

const checkRemainingRecipient = (recipients, occasionId) => {
    let counter = 0;
    if (!isEmpty(recipients[occasionId])) {
        recipients[occasionId].forEach((item, key) => {
            if (!recipients[occasionId][key].addToCart) {
                counter += 1;
            }
        });
    }
    return counter;
};

export const GIFT_MESSAGE_SIZE = 180;

const brandIdByCode = {
    1001: '18F',
    1019: 'HD',
    1009: '18B',
    1025: 'SCH',
    1010: 'FB',
    1012: 'CCO',
    1022: 'PZU',
    1002: 'TPF',
    1020: 'WLF',
    1018: 'SY',
    1029: 'BRY',
    1030: 'PMA',
    1034: 'FL',
};

const getGiftMessageConfiguration = (brandId) => {
    if (brandId) {
        const { giftMessageConfig } = brandThemes[brandIdByCode[brandId] || '18F'];
        return giftMessageConfig;
    }

    return {
        giftMessageMaxLines: 4,
        giftMessageMaxChars: 30,
    };
};

const convertMultilineStr = (str, n, acc) => {
    if (str.length === 0) {
        return acc;
    }
    acc.push(str.substring(0, n));
    return convertMultilineStr(str.substring(n), n, acc);
};

const getGiftMessageObjectGiftList = (recipient, productCode, productType) => {
    const { greetingMessage } = recipient;
    let giftMsgText = greetingMessage;
    let giftAuthor = '';
    // For floral brands 18F, FB, BRY and GPT product, gift message config has to be as HD
    let brandCode = productCode;
    if (['1001', '1010', '1029'].includes(brandCode) && productType === 'GPT') brandCode = '1019';
    const giftMessageConfig = getGiftMessageConfiguration(brandCode) || {};

    if (giftMessageConfig.giftMessageMaxLines > 1 && greetingMessage?.length > 0) {
        if (giftMsgText?.includes('|')) {
            const testMatch = greetingMessage.split('|');
            if (testMatch.length > 0 && testMatch[0] !== 'No Card Message') {
                // Look for the last position have From: to assign it to the author
                // otherwise we send the author empty
                if (testMatch[testMatch.length - 1].includes('From:')) {
                    giftMsgText = testMatch.slice(0, -1)?.join('|');
                    giftAuthor = `${testMatch[testMatch.length - 1]?.replace('From:', '')}`;
                } else {
                    const words = testMatch.join(' ').trim().split(' ');
                    let message = '';
                    const newContent = [];
                    words.forEach((word) => {
                        if (message.length + word.length + 1 <= giftMessageConfig.giftMessageMaxChars) {
                            message += `${word} `;
                        } else if (message.length + word.length <= giftMessageConfig.giftMessageMaxChars) {
                            message += word;
                        } else if (newContent.length < giftMessageConfig.giftMessageMaxLines) {
                            newContent.push(message);
                            message = `${word} `;
                        }
                    });
                    if (message && newContent.length < giftMessageConfig.giftMessageMaxLines) {
                        newContent.push(message);
                    }
                    const remainingLines = giftMessageConfig.giftMessageMaxLines - newContent.length > 0 ? '|'.repeat(giftMessageConfig.giftMessageMaxLines - newContent.length) : '';
                    giftMsgText = `${newContent.join('|')}${remainingLines}`;

                    giftAuthor = '';
                }
            }
        } else {
            giftMsgText = giftMsgText.substring(0, giftMsgText.lastIndexOf('From:') + 1);
            giftAuthor = giftMsgText.substring(giftMsgText.lastIndexOf('From:') + 1, giftMsgText.length);
        }
    }

    return {
        message: giftMsgText,
        author: giftAuthor,
    };
};

const isLoginRequired = (featureFlags, scope = '', writeAccess = true) => {
    if (!featureFlags || !scope) return false;

    const isKMSIEnabled = (featureFlags?.['is-keep-me-signed-in-redirect-enabled'] === true) || false;
    const signedInRedirectVariant = (featureFlags?.['is-keep-me-signed-in-redirect-variant']) || {};
    // ignore is login required if the is-keep-me-signed-in-redirect-enabled flag is OFF
    if (isKMSIEnabled !== true) return false;

    const pathname = (typeof window !== 'undefined' && window.location?.pathname) ? window.location?.pathname : '';
    const pathnameSplit = pathname.split(/\//g);
    let page = (pathname && pathnameSplit[2]) ? pathnameSplit[2] : '';
    if (pathname && page === '' && /\/account[/?]?/.test(pathname)) {
        page = 'home';
    }
    if (page && signedInRedirectVariant?.[page] && scope && /((read|write):account)/g.test(scope)) { // prevented undefined scope
        // If the API requires full access, but the scope returns as read only access
        // then we redirect users to login page (return true)
        if (writeAccess) {
            return /(read:account)/g.test(scope);
        }
    }
    // Do not redirect if the featureFlag is off
    return false;
};

const redirectToLogin = async (featureFlags, loginByRedirect) => {
    // Handle redirect to login if login required on click of cancel order button
    const accessToken = await authData.getAccessTokenSafely();
    const { scope = '' } = jwtDecode(accessToken);
    if (isLoginRequired(featureFlags, scope)) {
        loginByRedirect({ prompt: 'login' });
        return true;
    }
    return false;
};

const isHDBrand = (productBrand) => (productBrand === '1018' || productBrand === '1019' || productBrand === '1020');

// const checkIsDonationItem = (productName) => {
// let isDonatioItem = false;
// const matchDonation = productName?.toLowerCase().match(/(donation|fundraising)/gi);
// if (Array.isArray(matchDonation) && matchDonation.length > 0) {
//     isDonatioItem = true;
// }
// return /(donation|fundraising)/gi.test(productName);
// return isDonatioItem;
// };

const checkIsDonationItem = (productName) => /(donation|fundraising)/gi.test(productName);

const getEnterpriseId = () => {
    try {
        const enterpriseId = JSON.parse(JSON.parse(sessionStorage.getItem('persist:member')).enterpriseId);
        return enterpriseId?.enterpriseId;
    } catch (ex) {
        return null;
    }
};

const Helper = {
    refactorObjectData,
    refactorStateData,
    setState,
    getAuthentication,
    getUserEmailFromMultipleLocations,
    setSessionStorageObject,
    getSessionStorageObject,
    getChannelUser,
    getGiftListItemFromCart,
    getItemDetailsFromCart,
    updateRecipientsFromCartItems,
    checkRemainingRecipient,
    getGiftMessageConfiguration,
    convertMultilineStr,
    getGiftMessageObjectGiftList,
    isLoginRequired,
    redirectToLogin,
    isHDBrand,
    checkIsDonationItem,
    getEnterpriseId,
};

export default Helper;
