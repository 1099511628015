/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import Cookies from 'universal-cookie';

export const setPageTitle = (pageTitle) => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        document.title = pageTitle;
    }
};

export const getWindowLocation = () => {
    if (typeof window !== 'undefined' && window.location) {
        return window.location.origin;
    }
    return 'http://localhost';
};

export const parseUserInfo = (objToken, arrGetValOf) => {
    const objReturn = {};
    Object.keys(objToken).forEach((val) => {
        const strNode = val.toString();
        const iStartPos = strNode.lastIndexOf('/') + 1;
        const iEndPos = strNode.length;
        const strKey = strNode.substring(iStartPos, iEndPos);
        if (arrGetValOf.indexOf(strKey) >= 0) {
            objReturn[strKey] = objToken[strNode];
        }
    });
    return objReturn;
};

export const getTokenLocalStorage = (key) => {
    if (typeof window !== 'undefined' && window.localStorage) {
        return window.localStorage.getItem(key) || '';
    }

    return '';
};

export const setTokenLocalStorage = (name, value) => {
    if (typeof window !== 'undefined' && window.localStorage) {
        window.localStorage.setItem(name, JSON.stringify(value));
    }
};

export const removeTokenLocalStorage = (name) => {
    if (typeof window !== 'undefined' && window.localStorage) {
        window.localStorage.removeItem(name);
    }
};

export const queryStringBuilder = (params) => {
    let queryString = '';
    Object.keys(params).forEach((p) => {
        queryString = `${queryString}&${p}=${params[p]}`;
    });
    return queryString;
};

export const getGdprQuery = () => {
    const cookies = new Cookies();
    const gdprCookieValue = cookies.get('gdpr');

    if (gdprCookieValue) {
        return {
            gdprValue: gdprCookieValue,
        };
    }

    return {};
};

export const customCallbackURL = (url, params = {}) => {
    const gdprValue = getGdprQuery();

    const queryString = queryStringBuilder({ ...gdprValue, ...params });

    return `${url}?${queryString}`;
};

export function updateTokenInLocalStorage(accessToken) {
    const localStorageUserInfo = getTokenLocalStorage('userInfo');

    if (localStorageUserInfo) {
        let userInfo = JSON.parse(localStorageUserInfo);
        userInfo = {
            ...userInfo,
            token: accessToken,
        };

        setTokenLocalStorage('userInfo', userInfo);
    } else {
        setTokenLocalStorage('userInfo', { profile: {}, token: accessToken });
    }
}

export const getAccessTokenInSession = () => {
    let userInfo = getTokenLocalStorage('userInfo');
    userInfo = userInfo ? JSON.parse(userInfo) : {};
    const accessTokenInLocalStorage = userInfo?.token || '';

    return accessTokenInLocalStorage;
};

export function deffer() {
    let res = null;
    let rej = null;

    const promise = new Promise((resolve, reject) => {
        res = resolve;
        rej = reject;
    });

    promise.resolve = res;
    promise.reject = rej;

    return promise;
}
