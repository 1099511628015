/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from './restClient';

const RESOURCE_ROOT_CARTSERVICE = '/checkout';
const RESOURCE_VERSION_CARTSERVICE = '/v2';
const RESOURCE_PAGE_CARTSERVICE = '/cart';
const RESOURCE_PAGE_ACTION = '/cartcount';

/**
 * cartcount
 * url - /checkout/{apiversion}/cart/cartcount?brand={brandId}
 *
 * @param {Object} args
 * @param {Object} args.env
 * @param {String} args.jwtToken
  */

const getCartCount = ({ env, jwtToken }) => {
    const envObj = env || {};
    envObj.rootUri = `${RESOURCE_ROOT_CARTSERVICE}${RESOURCE_VERSION_CARTSERVICE}`;
    const resourcePage = `${RESOURCE_PAGE_CARTSERVICE}${RESOURCE_PAGE_ACTION}`;
    return restClient.getRequest(envObj, resourcePage, jwtToken)
        .then((response) => response);
};

export default getCartCount;
