/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { func, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import noop from '../../../helpers/noop';

const useStyles = makeStyles(() => ({
    button: ({ color }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        border: 'solid 1px',
        textTransform: 'uppercase',
        height: '60px',
        width: '60px',
        margin: '0 4px',
        '&:hover': {
            background: '#00000008',
        },
        cursor: 'pointer',
        transition: 'background .3s',
        color,
        borderColor: color,
    }),
    arrow: {
        height: '14px',
        width: 'inherit',
        marginLeft: '1px',
        backgroundSize: 'contain',
        background: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAbCAMAAAB7jU7LAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAD9QTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAsyWCLwAAABV0Uk5TAG8gcP/PEN+/n+8wj39AYIBfoK9Q5snUogAAAF9JREFUeJyF0EsSgCAMA9AqAZSPAnr/szquaHDU7t6qSUSmWdQZWKc4+4cXYa/kwI4BaXDW3sDef1yA+uUGcJpCWRvJdjkLr3oe8GqVRMoIUQtnVwVMVxzfv4a5Z1e6AFLmAxZjsB2cAAAAAElFTkSuQmCC) no-repeat center center',
        '&.back': {
            margin: '0 1px 0 0',
            transform: 'scaleX(-1)',
            filter: 'FlipH',
            '-ms-filter': '"FlipH"',
        },
    },
}));

const NavigationPaginate = ({
    color, direction, onClick, className,
}) => {
    const classes = useStyles({ color });

    return (
        <div
            className={`${className} ${classes.button} ${direction === 'forward' ? 'forwardArrow' : 'backwardArrow'} `}
            onClick={onClick}
            role="presentation"
            data-testid="navpag"
        >
            <div className={` ${classes.arrow} ${direction === 'forward' ? 'forward' : 'back'}`} />
        </div>
    );
};

NavigationPaginate.propTypes = {
    direction: string,
    color: string,
    className: string,
    onClick: func,
};

NavigationPaginate.defaultProps = {
    direction: 'forward',
    color: '#fff',
    className: {},
    onClick: noop,
};

export default NavigationPaginate;
