/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const SET_ACCOUNT_REGISTER = 'mbp-member/SET_ACCOUNT_REGISTER';
export const SET_ACCOUNT_MESSAGE = 'mbp-member/SET_ACCOUNT_MESSAGE';
export const CREATE_ACCOUNT_IN_PROGRESS = 'mbp-member/CREATE_ACCOUNT_IN_PROGRESS';

export const SET_EMAIL_OPT_IN_SUBSCRIBE = 'mbp-member/SET_EMAIL_OPT_IN_SUBSCRIBE';
