/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as AddToCartActionTypes from './AddToCart-ActionTypes';

export const loadItemAdded = (item) => ({
    type: AddToCartActionTypes.LOAD_ITEM_ADDED,
    data: {
        item,
    },
});

export const clearAddToCartError = () => ({
    type: AddToCartActionTypes.CLEAR_ADD_TO_CART_ERROR,
});

export const loadAddToCartError = (error, errorList = {}) => ({
    type: AddToCartActionTypes.LOAD_ADD_TO_CART_ERROR,
    data: {
        error,
        errorList,
    },
});

export const loadAddToCartloader = (loader) => ({
    type: AddToCartActionTypes.LOAD_ADD_TO_CART_LOADER,
    data: {
        loader,
    },
});

export const loadAddToCartGiftMessage = (message, occasionCode) => ({
    type: AddToCartActionTypes.LOAD_ADD_TO_CART_GIFT_MESSAGE,
    data: {
        message,
        occasionCode,
    },
});

export const addToCart = (item, history, deliveryType = null, categoryId = null, partNumber = null, skipAddons = false, deliveryDate = null, categoryName = null, categoryPath = null, isPassportBundleItem = false, movieSelected = null, selectedSku = {}, isPassportBundle = {}, handleMiniCartModalClick, enableMinicart, closeCalendar) => ({
    type: AddToCartActionTypes.ADD_ITEM_TO_CART,
    // deliveryType, categoryId, partNumber, and skipAddons, deliveryDate, categoryName,
    // categoryPath, isPassportBundleItem, movieSelected can all be omitted
    // they will not effect desired behavior for non-18F brands.
    payload: {
        item,
        history,
        deliveryType,
        categoryId,
        partNumber,
        skipAddons,
        deliveryDate,
        categoryName,
        categoryPath,
        isPassportBundleItem,
        movieSelected,
        selectedSku,
        isPassportBundle,
        handleMiniCartModalClick,
        enableMinicart,
        closeCalendar,
    },
});

export const addToCartSuccess = () => ({
    type: AddToCartActionTypes.ADD_ITEM_TO_CART_SUCCESS,
});

export const addToCartFailed = (exception) => ({
    type: AddToCartActionTypes.ADD_ITEM_TO_CART_FAILED,
    payload: exception,
});

export const addAddonsToCart = (addonData, history, orderItemId, oldAddonsOrderItemIds, selectedAddOnItems) => ({
    type: AddToCartActionTypes.ADD_ADDONS_TO_CART,
    payload: {
        addonData,
        history,
        orderItemId,
        oldAddonsOrderItemIds,
        selectedAddOnItems,
    },
});

export const addWrapUpsToCart = (wrapupData, history, orderItemId) => ({
    type: AddToCartActionTypes.ADD_WRAPUPS_TO_CART,
    payload: {
        wrapupData,
        history,
        orderItemId,
    },
});

export const addAddonsAndWrapupToCart = (addonData, wrapupData, history, orderItemId, oldAddonsOrderItemIds, selectedAddOnItems) => ({
    type: AddToCartActionTypes.ADD_ADDONS_AND_WRAPUP_TO_CART,
    payload: {
        addonData,
        history,
        orderItemId,
        oldAddonsOrderItemIds,
        selectedAddOnItems,
        wrapupData,
    },
});

export const buyAgain = (data) => ({
    type: AddToCartActionTypes.BUY_AGAIN,
    payload: data,
});
