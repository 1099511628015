/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export default {
    recipients: [],
    cartItems: {},
    shippingInfo: false,
    workingRecipient: 0,
    recipientForm: {
        entryId: '',
        addressId: '',
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        email: '',
        locationType: '',
        funeralServiceTime: '',
        nameOfLocation: '',
        state: '',
        country: '',
        zipCode: '',
        phone: '',
        city: '',
        giftMessageLine1: '',
        giftMessageLine2: '',
        giftMessageLine3: '',
        giftMessageLine4: '',
        giftMessageLine5: '',
        giftMessageLine6: '',
        giftMessageLine7: '',
        organizationName: '',
        isFormValid: false,
        cityArray: [],
        submitForm: false,
        checkSameAsDeliveryAddress: false,
        useAddressForAll: false,
    },
    calShipping: {
        calShippingChargesAttempt: 0,
        calShippingApplied: false,
    },
    cardIsle: {
        availability: {},
        selected: {},
    },
    giftMessage: {
        occasion: '',
        occasionName: '',
        message: '',
        author: '',
        isFormValid: true,
        isOrderConfirmation: false,
        showGiftMessageContainer: true,
        showGiftMessageForm: true,
    },
    giftMessageExpressions: [],
};
