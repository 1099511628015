/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './Order-ActionTypes';

export const getOrderId = (orderId) => ({
    type: actionTypes.GET_ORDER_ID,
    data: orderId,
}
);

export const loadOrderId = (orderId) => ({
    type: actionTypes.LOAD_ORDER_ID,
    data: orderId,
});

export const loadExpressCheckout = () => ({
    type: actionTypes.LOAD_EXPRESS_CHECKOUT,
});

export const clearOrderId = () => ({
    type: actionTypes.CLEAR_ORDER_ID_CHECKOUT,
});

export const loadCartCount = (cartCount) => ({
    type: actionTypes.LOAD_CART_COUNT,
    data: {
        cartCount,
    },
});

export const fetchLastOrderDetails = (data) => ({
    type: actionTypes.FETCH_LAST_ORDER_DETAILS,
    payload: data,
});

export const setLastOrdered = (data) => ({
    type: actionTypes.SET_LAST_ORDERED_ITEM,
    payload: data,
});

export const setAllOrdered = (data) => ({
    type: actionTypes.SET_ALL_ORDERED_ITEM,
    payload: data,
});

export const fetchAllOrderDetails = (data) => ({
    type: actionTypes.FETCH_ALL_ORDER_DETAILS,
    payload: {
        data,
    },
});
