/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import { take, call, fork } from 'redux-saga/effects';

// eslint-disable-next-line func-names
const takeLeading = (patternOrChannel, saga, ...args) => fork(function* () {
    while (true) {
        const action = yield take(patternOrChannel);
        yield call(saga, ...args.concat(action));
    }
});

export default takeLeading;
