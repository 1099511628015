/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import * as actionTypes from './PmallPersonalization-ActionTypes';

export const setPmallPersonalizationData = (data) => ({
    type: actionTypes.UPDATE_PMALL_PERSONALIZATION_DATA,
    data,
});

export const updatePmallImages = (data) => ({
    type: actionTypes.UPDATE_PMALL_IMAGES,
    data,
});

export const removePmallItem = () => ({
    type: actionTypes.REMOVE_PMALL_PERSONALIZATION,
});

export const updateCartPmallPersonalization = (data) => ({
    type: actionTypes.UPDATE_CART_PMALL_PERSONALIZATION,
    data,
});

export const updateCartPmallPersonalizationSuccess = () => ({
    type: actionTypes.UPDATE_CART_PMALL_PERSONALIZATION_SUCCESS,
});

export const updateCartPmallPersonalizationFailed = (data) => ({
    type: actionTypes.UPDATE_CART_PMALL_PERSONALIZATION_FAILED,
    data,
});
