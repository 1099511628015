/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import initialState from './initialState';
import * as actions from './Recipient-Actions';

export const recipientsReducer = (state = initialState.recipients, action) => {
    switch (action.type) {
        case actions.updateRecipients().type:
            if (!state.includes(action.data)) {
                return [
                    ...state,
                    action.data,
                ];
            }
            return state;
        default:
            return state;
    }
};

export const workingRecipientReducer = (state = initialState.workingRecipient, action) => {
    switch (action.type) {
        case actions.setWorkingRecipient().type:
            return action.data.index;
        case actions.updateWorkingRecipient().type:
            return state + 1;
        default:
            return state;
    }
};

export const orderItemsReducer = (state = initialState.cartItems, action) => {
    switch (action.type) {
        case actions.loadOrderItemDetails().type: {
            const { data } = action;
            const updateState = { [data.orderItemId]: data.orderItemDetails };
            return updateState;
        }
        default:
            return state;
    }
};
