/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as errorActions from './Error-Actions';
import * as errorOperations from './Error-Operations';
import errorReducer from './Error-Reducer';

export { errorOperations, errorActions };

const errorWatcherSagas = [
    ...errorOperations.watchers,
];

export {
    errorWatcherSagas,
};

export default errorReducer;
