/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import mbpLogger from 'mbp-logger';
import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getFeatureFlag } from '../../../../../App/ducks/Config/Config-Selectors';
import { getIsAuthenticatedStatus } from '../../../Common/Common-Selectors';

function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export const usePopUp = () => {
    const [showOverlay, setShowOverlay] = useState(false);

    // TODO :: remove the below code after expirement logging to track - safari mobile keychain popup issue
    const isExpirementMode = useSelector((state) => getFeatureFlag('is-expiremental-logging-enabled')(state));
    const prevShowOverlay = usePrevious(showOverlay);
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);

    const popUpRef = useRef();
    const timerCheckPopUp = useRef();

    const retryToCloseAuthPopUp = () => {
        // TODO remove below console - is added to check in fullstory
        console.log('retryToCloseAuthPopUp >>>>>>>>>>>>>>>>>> ispopup closed', !!popUpRef?.current?.close);

        if (popUpRef?.current?.close) {
            popUpRef.current.close();

            clearInterval(timerCheckPopUp.current);

            popUpRef.current = null;
        }
    };

    /**
     * Refocus auth0 window popup
     */
    const handlePopUpFocus = () => {
        if (!popUpRef?.current?.closed && popUpRef?.current?.focus) {
            popUpRef.current.focus();
        }
    };

    const listenToEvents = (event) => {
        // TODO remove below console - is added to check in fullstory
        if (isExpirementMode && popUpRef?.current?.close) {
            console.log('event >>>>> listenToEvents >>>>>>>>', event);
        }

        if (!timerCheckPopUp.current && popUpRef?.current?.close && event?.data?.type === 'authorization_response' && event?.data?.response?.code) {
            timerCheckPopUp.current = setInterval(() => {
                retryToCloseAuthPopUp();
            }, 1000);
        }
    };

    const subscribeToAuthLoginEvent = () => {
        // TODO remove below console - is added to check in fullstory
        if (isExpirementMode) {
            console.log('event >>>>> subscribeToAuthLoginEvent >>>>>>>>');
        }

        if (typeof window !== 'undefined') {
            window.addEventListener('message', listenToEvents);
            window.focusAuthLoginPopup = () => handlePopUpFocus();
        }
    };

    const unSubscribeToAuthLoginEvent = () => {
        // TODO remove below console - is added to check in fullstory
        if (isExpirementMode) {
            console.log('event >>>>> unSubscribeToAuthLoginEvent >>>>>>>>');
        }

        clearInterval(timerCheckPopUp.current);

        if (typeof window !== 'undefined') {
            window.removeEventListener('message', listenToEvents, false);
            window.focusAuthLoginPopup = undefined;
        }
    };

    useEffect(() => {
        if (isExpirementMode) {
            if (prevShowOverlay && !showOverlay
                && isAuthenticated
                && popUpRef.current && !popUpRef.current.closed) {
                // TODO remove below console - is added to check in fullstory
                console.log('auth0 popup did not close after login >>>');

                mbpLogger.logError({
                    appName: process.env.npm_package_name,
                    module: 'mbp-pwa-ui',
                    fileName: 'usePopUp.js',
                    function: 'usePopUp',
                    message: 'auth0 popup did not close after login',
                });
            }
        }
    }, [showOverlay]);

    /**
     * call back function passed to @auth0/auth0-spa-js sdk to open auth0 login on a popup
     * allows for customizing the popup width and height and position & close popup
     */
    const openPopup = (url) => {
        try {
            const width = 920;
            const height = 600;
            const left = window.screenX + (window.innerWidth - width) / 2;
            const top = window.screenY + (window.innerHeight - height) / 2;

            popUpRef.current = window.open(
                url,
                'auth0:authorize:popup',
                `left=${left},top=${top},width=${width},height=${height},resizable,scrollbars=yes,status=1`,
            );

            popUpRef?.current?.focus();

            subscribeToAuthLoginEvent();
        } catch (ex) {
            unSubscribeToAuthLoginEvent();
            console.log('Error response: ', ex);
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                fileName: 'usePopUp.js',
                function: 'openPopup',
                jsError: ex,
                message: 'auth0 failed to open auth popup',
            });
        }

        return popUpRef.current;
    };

    /**
     * closes the auth0 popup & remove screen overlay
     */
    const handleClosePopUp = () => {
        /**
         *  close popup window - @auth0/auth0-spa-js sdk would handle the popup close but
         *  there are cases where it fails - below case should be able to handle the edge cases
         *  eg : iPad Air 3 - Safari fails to close window popup
         */
        if (popUpRef?.current?.close) {
            popUpRef.current.close();
        }
    };

    return {
        showOverlay,
        openPopup,
        handleClosePopUp,
        handlePopUpFocus,
        setShowOverlay,
    };
};

export default {};
