/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import mbpUtil from 'mbp-api-util';

// Plugin Config card isle
const cardIslePluginConfig = {
    sdkURL: mbpUtil.getEnv('APP_CARD_ISLE_SDK_URL'),
    cardIsleOccasionURL: mbpUtil.getEnv('APP_CARD_ISLE_OCCASION_SDK_URL'),
    cardIsleGiftCardsURL: mbpUtil.getEnv('APP_CARD_ISLE_GIFTCARDS'),
    cardIslePdpURL: mbpUtil.getEnv('APP_CARD_ISLE_PDP_SDK_URL'),
};

export {
    cardIslePluginConfig,
};

export default {};
