/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable import/prefer-default-export */
import Months from '../state/constants/Months';

export const DaysOfTheWeekShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const DaysOfTheWeekFull = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thurday', 'Friday', 'Saturday'];

export const MonthsFull = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const MonthShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const convertToYYYYMMDD = (date) => {
    if (date && /^\d{2}[-/]\d{2}[-/]\d{4}$/.test(date)) {
        return [
            date.slice(6, 10),
            '-',
            date.slice(0, 2),
            '-',
            date.slice(3, 5),
            'T00:00:00',
        ].join('');
    }
    return date;
};

export const dateMMMMDDYYYY = (
    dateStringOrObject,
    nextYear = 0,
    nextMonth = 0,
    nextDay = 0,
) => {
    let date = dateStringOrObject;

    if (date && /^\d{2}[-/]\d{2}[-/]\d{4}$/.test(date)) {
        date = convertToYYYYMMDD(date);
    } else if (date && /^\d{4}-\d{2}-\d{2}?$/.test(date)) {
        date = `${date}T00:00:00`;
    }

    // Accepting
    // 2020-05-04 00:00:00.0 / 2020-05-04T22:58:00.0
    // or
    // 2020-05-04 00:00:00 / 2020-05-04T22:58:00
    if (date && /^\d{4}-\d{2}-\d{2}[\sT]\d{2}:\d{2}:\d{2}(\.0)?$/.test(date)) {
        const dateObj = new Date(date.replace(/\s/, 'T'));
        const day = dateObj.getDate() + nextDay;
        const month = dateObj.getMonth() + nextMonth;
        const year = dateObj.getFullYear() + nextYear;
        const newDate = new Date(year, month, day);
        const newDay = newDate.getDate();
        const newMonth = Months[newDate.getMonth()];
        const newYear = newDate.getFullYear();
        return [newMonth, ' ', newDay, ', ', newYear].join('');
    } if (date && typeof date === 'object' && date.getTime()) {
        const day = date.getDate() + nextDay;
        const month = date.getMonth() + nextMonth;
        const year = date.getFullYear() + nextYear;
        const newDate = new Date(year, month, day);
        const newDay = newDate.getDate();
        const newMonth = Months[newDate.getMonth()];
        const newYear = newDate.getFullYear();
        return [newMonth, ' ', newDay, ', ', newYear].join('');
    }
    return date;
};

export const dateMMMMDD = (date) => {
    // Accepting
    // 2020-05-04 00:00:00.0 / 2020-05-04T22:58:00.0
    // or
    // 2020-05-04 00:00:00 / 2020-05-04T22:58:00
    if (date && /^\d{4}-\d{2}-\d{2}[\sT]\d{2}:\d{2}:\d{2}(\.0)?$/.test(date)) {
        const dateObj = new Date(date.replace(/\s/, 'T'));
        const day = dateObj.getDate();
        const month = Months[dateObj.getMonth()];
        return [month, ' ', day].join('');
    }
    return date;
};

export const dateMMDDYYYY = (date) => {
    // Accepting
    // 2020-05-04 00:00:00.0 / 2020-05-04T22:58:00.0
    // or
    // 2020-05-04 00:00:00 / 2020-05-04T22:58:00
    if (date && /^\d{4}-\d{2}-\d{2}[\sT]\d{2}:\d{2}:\d{2}(\.0)?$/.test(date)) {
        const currentDatetime = new Date(date.replace(/\s/, 'T'));
        const currentMonth = currentDatetime.getMonth() + 1;
        // Making month 2 digit format
        const getMonth = (currentMonth) < 10 ? `0${currentMonth}` : currentMonth;
        // Making date 2 digit format
        const getDate = currentDatetime.getDate() < 10 ? `0${currentDatetime.getDate().toString()}` : currentDatetime.getDate();
        const formattedDate = `${getMonth}-${getDate}-${currentDatetime.getFullYear()}`;
        return formattedDate;
    }
    return date;
};

export const mmmmDDYYYY = (dateString) => {
    // dateString => "2021-05-05 00:00:00"
    let date = new Date(dateString); // if safari, date => 'Invalid Date' because Safari does not like '-' for instantiating a new Date object
    if (/^\d{4}-\d{2}-\d{2}[\sT]\d{2}:\d{2}:\d{2}(\.0)?$/.test(dateString) && date.toString() === 'Invalid Date') {
        const [d, t] = [dateString.split(' ')[0], dateString.split(' ')[1]]; // d => '2021-05-05'  t => '00:00:00'
        const formattedDate = `${d.split('-')[1]}/${d.split('-')[2]}/${d.split('-')[0]} ${t.split('.')[0]}`; // 05/05/2021 00:00:00
        date = new Date(formattedDate); // Wed May 05 2021 00:00:00 GMT-0700 (PDT)
    }
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    return `${MonthShort[month]} ${day}, ${year}`;
};

export const DDMMMYY = (data) => {
    // data => "2021-05-05 00:00:00"
    let date = new Date(data); // if safari, date => 'Invalid Date' because Safari does not like '-' for instantiating a new Date object
    if (/^\d{4}-\d{2}-\d{2}[\sT]\d{2}:\d{2}:\d{2}(\.0)?$/.test(data) && date.toString() === 'Invalid Date') {
        const [d, t] = [data.split(' ')[0], data.split(' ')[1]]; // d => '2021-05-05'  t => '00:00:00'
        const formattedDate = `${d.split('-')[1]}/${d.split('-')[2]}/${d.split('-')[0]} ${t.split('.')[0]}`; // 05/05/2021 00:00:00
        date = new Date(formattedDate); // Wed May 05 2021 00:00:00 GMT-0700 (PDT)
    }
    const month = MonthShort[date.getMonth()];
    let day = `${date.getDate()}`;
    const year = `${date.getFullYear()}`;
    const fYear = year.match(/\d{2}$/);
    if (day.length < 2) {
        day = `0${day}`;
    }
    const result = [day, month, fYear].join('-');
    return result;
};
