/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';
import { GRAPHQL_ENV } from '..';

/**
 * @param {string} brand - baskets or berries
 * @param {string} arrPartNumbers - array of SKU's to be fetched
 * @param {string} fields - fields to be queried
 */

const findSkuByPartNumbers = (brand, arrPartNumbers, fields) => (
    gql`{ findSkuByPartNumbers(brand: "${brand}", environment: "${GRAPHQL_ENV}", partNumbers: ${arrPartNumbers}) {
            ${fields}
            __typename
        }
    }`);

export default findSkuByPartNumbers;
