/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpLogger from 'mbp-logger';
import restClient from '../../app/helpers/restClient';

export const postChatEventWithEID = async (payload) => {
    const enterpriseKey =  localStorage.getItem('enterpriseId') || null;
    const resourcePath = payload.featureFlags['is-use-personalization-api-for-chat-events-enabled'] ? 'personalize/customerEvents/events/publish' : `userEvents/events/enterpriseIds/${enterpriseKey}`;
    await restClient.postFacade(
        payload.wcEnv,
        resourcePath,
        payload.token,
        payload.data,
    )
        .catch((err) => {
            mbpLogger.logError({
                err,
                function: 'postChatEventWithEID',
                appName: process.env.npm_package_name,
                module: 'EnterpriseId API',
            });
        });
};

export default postChatEventWithEID;
