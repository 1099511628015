/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { combineReducers } from 'redux';
import * as paymentActions from './Payment-Actions';
import * as paymentOperations from './Payment-Operations';

import * as googlePayPlugin from './plugins/GooglePay';

// Watchers
const paymentWatcherSagas = [
    ...paymentOperations.watchers,
    // Payment Method Watchers
    ...googlePayPlugin.googlePayOperations.watchers,
];

// Reducer
const paymentReducers = combineReducers({
    googlePay: googlePayPlugin.googlePayReducer,
});

// Plugins
const paymentPlugins = {
    GooglePay: googlePayPlugin,
};

export {
    paymentActions,
    paymentOperations,
    paymentWatcherSagas,
    paymentPlugins,
};

export default paymentReducers;
