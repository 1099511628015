/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { createContext } from 'react';

const initialContext = {
    isReady: false,
    showOverlay: false,
    verifyScopeExist: () => {},
    handlePopUpFocus: () => {},
    loginByRedirect: () => {},
    loginByAuth0PopUp: () => {},
    logout: () => {},
    getAccessTokenSafely: () => {},
    subscribeUser: () => {},
};

const AuthContext = createContext(initialContext);

export default AuthContext;
