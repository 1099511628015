/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import actionTypes from './Paypal-ActionTypes';

export const clearPaypalState = () => ({
    type: actionTypes.CLEAR_PAYPAL_STATE,
});

export default {};
