/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const getPaymentDetails = (state) => state?.checkout?.payment?.paymentForm?.paymentMethod;
export const getPaymentTypeUsedToPlaceOrder = (state) => state?.mfeCheckout?.payment?.orderPlacedPayment;
export const getOrderHistoryPlaced = (state) => state?.order?.allPlacedOrder || [];

// TODO: Restore memoization (possibly)
export const getEPaymentDetails = (state) => {
    let paymentDetails = {};
    const paymentInfo = getPaymentDetails(state);
    const paymentMethodId = paymentInfo?.id ? paymentInfo.id.toLowerCase() : '';

    try {
        if (paymentMethodId === 'paypal') {
            paymentDetails.paymentMethod = {
                id: 'PayPal',
                name: 'PayPal',
            };

            // Append VO
            paymentDetails = { ...paymentDetails };
        } else if (paymentMethodId === 'chasepay') {
            paymentDetails.paymentMethod = {
                id: 'ChasePay',
                name: 'Chase Pay',
            };

            // Append VO
            paymentDetails = { ...paymentDetails };
        } else if (paymentMethodId === 'visacheckout') {
            paymentDetails.paymentMethod = {
                id: 'VisaCheckout',
                name: 'Click to Pay',
            };

            // Append VO
            paymentDetails = { ...paymentDetails };
        } else if (paymentMethodId === 'applepaycheckout') {
            paymentDetails.paymentMethod = {
                id: 'ApplePay',
                name: 'Apple Pay',
            };
        } else {
            paymentDetails.paymentMethod = paymentInfo;
        }
    } catch (ex) {
        console.error('ERROR: getCartOrderDetails selector FAILED', ex);
    }

    return paymentDetails;
};

export const getOrderStatus = (state) => state.cart?.cartStatus || '';
export const getFirstItemRecipientZipCode = (state) => state.cart?.recipients?.[0]?.recipientAddress?.zipCode || '';
export const getOrderConfirmationId = (state) => state.cart?.orderIdWithSourceId || '';
export const getFirstItemBillingZipCode = (state) => state.cart?.customer?.customerAddress?.zipCode || '';
