/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { END } from 'redux-saga';
import {
    take, fork, call, put, select, takeLatest,
} from 'redux-saga/effects';
// TODO remove in the future, now still doing testing. CO-1456
// import Cookies from 'universal-cookie';
import Cookies from 'universal-cookie';
import mbpLogger from 'mbp-logger';

import mbpUtil from 'mbp-api-util';
import * as commonSelectors from '../../../Common/Common-Selectors';
import { errorActions, errorOperations } from '../../../Common/ducks/Error';
import * as retryCalculateShippingActions from '../Recipient/ducks/RetryCalculateShipping/RetryCalculateShipping-Actions';
import { showLoadingMessageActions } from '../../../Common/ducks/ShowLoadingMessage';
import { activePaymentMethod, inActivePaymentMethod } from '../Payment/Payment-Actions';
import { retrieveCart, workerRecalculateGiftCardTotal } from '../../../Cart/Cart-Operations';

import { getBrand } from '../../../App/ducks/Config/Config-Selectors';

import {
    calculateShipping,
    calculateShippingComplete,
    saveBannerToOrder,
    saveBannerProcessCheckCompleted,
    checkApplePayEnabled,
    validateShopCart,
    getDynamicPassportCharge,
    handleAddExtraItem,
} from './Checkout-Actions';

import { callHandleCartFailure, loadCart } from '../../../Cart/Cart-Actions';
import orderClient from '../../../../../apis/checkout-apis/orderClient';
import { checkJWT } from '../Global/Global-Operations';
import { addAddonsToCart, addToCartFailed } from '../AddToCart/AddToCart-Actions';
import { getAddressSource } from '../../../App/App-Selectors';
import { getFeatureFlags, getCheckoutFeatureFlags } from '../App/App-Selectors';
import { getActiveABTests } from '../../../App/ducks/ABTesting/ABTesting-Selectors';
import cartService from '../../../../../apis/cart-service-apis/index';
import { cartStatusCompleteList } from '../../../../../app/helpers/common/helper';

function* onLoadOrderDetails() {
    try {
        yield call(retrieveCart);
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations',
            function: 'onLoadOrderDetails',
            jsError: ex,
            message: 'Failed to load order details',
        });
    }
}

export function* calcShipping() {
    function* handleCalcShippingFailure(err) {
        // Clear Error
        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: 'error',
        }));

        // Retry Calculate Shipping
        // Commented out retry calcualte shipping code to check the impact of calculate shipping API calls
        /*
        const noOfAttempt = yield select(commonSelectors.getCalShippingAttempt);
        if (noOfAttempt < 1) {
            yield put(retryCalculateShippingActions.updateRetryCalculateShippingAttempt());
            yield call(calcShipping);
        } else {
            // Retry Calcualte Shipping Fails
            // Get Cart
            yield call(retrieveCart, 'payment');
            yield put(retryCalculateShippingActions.updateCalculateShippingApplied(false));

            // Log Error only if error status is 401, 403, 404, 500, 501, 503
            const arrErrorCodes = [401, 403, 404, 500, 501, 503];
            const errCode = err?.response?.status;

            if (errCode && arrErrorCodes.includes(errCode)) {
                yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
                yield call(errorOperations.getError, err, 'payment');
            }
        }
        */

        // Get Cart
        yield call(retrieveCart, 'payment');
        yield put(retryCalculateShippingActions.updateCalculateShippingApplied(false));

        // Log Error only if error status is 401, 403, 404, 500, 501, 503, 504
        const errCode = err?.response?.status;
        if (errCode && errorOperations.arrErrorCodes.includes(errCode)) {
            yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
            yield call(errorOperations.getError, err, 'payment');
        }
    }

    const logOrderId = yield select(commonSelectors.getOrderId);

    try {
        // yield put(showLoadingMessageActions.updateShowLoadingMessage());
        const orderId = yield select(commonSelectors.getOrderId);

        if (orderId) {
            const JWT_TOKEN = yield call(checkJWT);
            const flagState = yield select(getFeatureFlags);

            // Calculate Estimate Shipping
            const isShippingEstimated = flagState['is-estimated-shipping-enabled'] || false;
            const siteExpirement = flagState['site-experiment-mode'] || {};

            let calEstimatedShippping = 'false';
            if (isShippingEstimated) {
                calEstimatedShippping = 'true';
            }

            mbpLogger.logDebug({
                appName: process.env.npm_package_name,
                module: 'Checkout-Operations',
                function: 'calcShipping',
                message: 'Call - Calculate Shipping API',
                orderId: logOrderId,
            });

            const headers = {};

            if (siteExpirement.value) {
                headers.sv = siteExpirement.value;
            }

            // Call Calculate Shipping API
            const calculatOrd = yield call(
                orderClient.calculateShipping,
                {},
                JWT_TOKEN,
                orderId,
                calEstimatedShippping,
                headers,
            );

            mbpLogger.logDebug({
                appName: process.env.npm_package_name,
                module: 'Checkout-Operations',
                function: 'calcShipping',
                message: 'Response - Calculate Shipping API',
                orderId: logOrderId,
            });

            const { successFlag, result } = calculatOrd.data;
            if (successFlag === true || successFlag === 'true' || result === 'SUCCESS') {
                yield put(errorActions.clearErrors({
                    errType: 'payment',
                    field: 'error',
                }));
            }
        } else {
            const windowLocation = (typeof window !== 'undefined') ? window.location.href : '';
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                module: 'Checkout-Operations.js',
                function: 'calcShipping',
                message: 'Calculate Shipping - No OrderId',
                orderId: logOrderId,
                windowLocation,
            });
        }
    } catch (exp) {
        yield put(callHandleCartFailure(exp));

        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations',
            function: 'calcShipping',
            jsError: exp,
            message: 'Error occured while calculating order',
            orderId: logOrderId,
        });
        yield call(handleCalcShippingFailure, exp);
    }
    yield put(calculateShippingComplete());
}

function* prepareRetreiveCart(page) {
    try {
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        const isGiftCardApplied = yield select(commonSelectors.getIfGiftCardApplied);
        const flagState = yield select(getFeatureFlags);
        const checkoutFeatureFlags = yield select(getCheckoutFeatureFlags);
        const isShippingEstimated = flagState['is-estimated-shipping-enabled'] || false;

        const activeABTests = yield select(getActiveABTests);
        const isEnterpriseCheckout = ['enterprise', 'enterprise2'].includes(checkoutFeatureFlags['which-checkout-variation']) || activeABTests?.checkout === 'universal';
        const cartCount = yield select(commonSelectors.getCartCount);

        if (
            ['review-order', 'payment-review', 'review'].includes(page)
            || (page === 'payment' && isEnterpriseCheckout)
            || (
                (page === 'checkout-food' || isEnterpriseCheckout)
                && isShippingEstimated
                && cartCount > 0
            ) // Cart page and shipping page in food brands and enterprise
        ) {
            yield put(retryCalculateShippingActions.clearCalculateShippingAttempt());
            yield fork(calcShipping);
            yield take(calculateShippingComplete().type);
            yield put(getDynamicPassportCharge());
            if (isGiftCardApplied) {
                yield call(workerRecalculateGiftCardTotal);
            }
        } else if ((!isEnterpriseCheckout && page === 'payment') || (isEnterpriseCheckout && page === 'shipping')) {
            // Only call calculate shipping on payment page, if Gift Card is applied
            if (isGiftCardApplied) {
                yield put(retryCalculateShippingActions.clearCalculateShippingAttempt());
                yield fork(calcShipping);
                yield take(calculateShippingComplete().type);
                yield call(workerRecalculateGiftCardTotal);
            }
        }
    } catch (ex) {
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());

        const orderId = yield select(commonSelectors.getOrderId);
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'prepareRetreiveCart',
            jsError: ex,
            message: 'Error occured while prepareRetreiveCart',
            orderId,
        });
    }
}

function* checkoutRouteHandler(history) {
    try {
        const allOrderItems = yield select(commonSelectors.getRecipients);
        const isPassportOnlyItem = yield select(commonSelectors.getIsPassportOnlyItemOnCart);
        const isDonationOnlyItem = yield select(commonSelectors.getIsDonationOnlyItemOnCart);
        const orderId = yield select(commonSelectors.getOrderId);
        const pagePath = history.location.pathname;
        const orderStatus = yield select(commonSelectors.getOrderStatus);

        if (orderStatus && cartStatusCompleteList.includes(orderStatus)) {
            history.push(`/checkout/order-confirmation/${orderId}`);
        } else if (pagePath.indexOf('/shipping') > 0) {
            // IF Passport only item in cart proceed to payment page
            if (isPassportOnlyItem || isDonationOnlyItem) {
                history.push(`/checkout/payment/${orderId}`);
            } else if (allOrderItems.length === 0) {
                history.push(`/checkout/cart/${orderId}`);
            }
        } else if (pagePath.indexOf('/payment') > 0) {
            if (allOrderItems.length === 0) {
                history.push(`/checkout/cart/${orderId}`);
            }
        } else if (pagePath.indexOf('/review-order') > 0) {
            const placeOrderStatus = yield select(commonSelectors.getPlaceOrderStatus);
            const selectedPaymentMethod = yield select(commonSelectors.getPaymentMethod);
            const paymentForm = yield select(commonSelectors.getPaymentInfo);

            if (selectedPaymentMethod.id === 'CreditCard' && !paymentForm.nameOnCreditCard && !placeOrderStatus && allOrderItems.length > 0) {
                history.push(`/checkout/payment/${orderId}`);
            } else if (allOrderItems.length === 0) {
                history.push(`/checkout/cart/${orderId}`);
            }
        } else if (pagePath.indexOf('/cart') < 0) {
            history.push(`/checkout/cart/${orderId}`);
        }
    } catch (ex) {
        const orderId = yield select(commonSelectors.getOrderId);
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'checkoutRouteHandler',
            jsError: ex,
            message: 'Error occured while handling checkout route',
            orderId,
        });
    }
}

function* processValidateShopCart(action) {
    const {
        orderId,
        history,
    } = action.data;
    // api for ValidateShopCart
    // const JWT_TOKEN = yield call(checkJWT);
    // yield call(postValidateShopCart, {
    //     JWT_TOKEN,
    //     orderId,
    // });
    yield call(history.push, `/checkout/shipping/${orderId}`);
}

// TODO remove in the future, now still doing testing. CO-1456
// function removeBannerCookie() {
//     const cookies = new Cookies();
//     cookies.remove('banner', { path: '/' });
// }

export function* onPersistAttribution({ bannerCode, lsid }) {
    const featureFlags = yield select(getFeatureFlags);
    const orderId = yield select(commonSelectors.getOrderId);

    try {
        const cookies = new Cookies();
        const jwtToken = yield call(checkJWT);

        const addressSource = yield select(getAddressSource);
        let sourceValue = '';
        if (addressSource === 'smg') {
            sourceValue = 'smg';
        }

        const payload = {
            orderId: `${orderId}`,
            lsId: `${lsid}`,
        };

        const attributes = [];
        if (sourceValue && featureFlags['is-source-attribute-used-in-checkout']) {
            attributes.push(
                {
                    attributeName: 'source',
                    attributeValue: sourceValue,
                },
            );
        }
        if (attributes.length) {
            payload.attributes = attributes;
        }

        if (featureFlags['is-checkout-monitoring-enabled']) {
            console.error({
                function: 'onPersistAttribution',
                bannerCode,
                attributes,
                payload,
                bannerCookie: (cookies.get('banner')) ? cookies.get('banner') : '',
                addressSource,
                message: `Persisting banner payload ${orderId}`,
            });

            mbpLogger.logInfo({
                appName: process.env.npm_package_name,
                module: 'Checkout-Operations.js',
                function: 'onPersistAttribution',
                errorMessage: {
                    bannerCode,
                    attributes,
                    addressSource,
                },
                message: `Persisting banner attributes ${orderId} - Before call API`,
            });

            mbpLogger.logInfo({
                appName: process.env.npm_package_name,
                module: 'Checkout-Operations.js',
                function: 'onPersistAttribution',
                errorMessage: (cookies.get('banner')) ? cookies.get('banner') : '',
                message: `Persisting banner cookie ${orderId} - Before call API`,
            });

            mbpLogger.logInfo({
                appName: process.env.npm_package_name,
                module: 'Checkout-Operations.js',
                function: 'onPersistAttribution',
                errorMessage: payload,
                message: `Persisting banner payload ${orderId} - Before call API`,
            });
        }

        const persisAttrResp = yield call(cartService.recordAttributions, {
            jwtToken,
            cartId: orderId,
            bannerCode,
            attributes,
        });

        if (featureFlags['is-checkout-monitoring-enabled']) {
            console.log({
                appName: process.env.npm_package_name,
                module: 'Checkout-Operations.js',
                function: 'onPersistAttribution',
                errorMessage: persisAttrResp,
                message: `Persisting banner response API - ${orderId}`,
            });
            mbpLogger.logInfo({
                appName: process.env.npm_package_name,
                module: 'Checkout-Operations.js',
                function: 'onPersistAttribution',
                errorMessage: persisAttrResp,
                message: `Persisting banner response API - ${orderId}`,
            });
        }

        yield put(saveBannerProcessCheckCompleted());
    } catch (ex) {
        yield put(callHandleCartFailure(ex));
        if (featureFlags['is-checkout-monitoring-enabled']) {
            console.error({
                appName: process.env.npm_package_name,
                module: 'Checkout-Operations.js',
                function: 'onPersistAttribution',
                jsError: ex.response,
                message: 'Error response in pesisting banner',
            });
        }

        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations',
            function: 'onPersistAttribution',
            jsError: ex,
            errorMessage: ex.response,
            message: `Error occured while persisting banner information - ${orderId}`,
        });

        yield put(saveBannerProcessCheckCompleted());
    }
}

const checkApplePayAvailable = (merchantIdentifier, featureFlags) => new Promise((resolve) => {
    const promise = window.ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);

    promise.then((data) => {
        if (featureFlags['is-checkout-experimental-code-enabled']) {
            console.log('bladerunner the canMakePaymentsWithActiveCard response', data);
        }
        resolve(data);
    }).catch(() => resolve(false));
});

function* workerApplePayAvailable() {
    mbpLogger.logDebug({
        appName: process.env.npm_package_name,
        module: 'Checkout-Operations',
        function: 'workerApplePayAvailable',
        message: 'initiate apple pay availability check',
    });
    try {
        const brand = yield select(getBrand);
        const featureFlags = yield select(getFeatureFlags);
        const merchantIdentifier = mbpUtil.getEnv('APP_APPLE_PAY_MID') || `${brand['apple-pay-merchant-id']}`;
        const applePayEnabled = yield call(
            checkApplePayAvailable,
            merchantIdentifier,
            featureFlags,
        );
        if (featureFlags['is-checkout-experimental-code-enabled']) {
            console.log('bladerunner the applePayEnabled', applePayEnabled);
        }

        if (applePayEnabled) {
            yield put(activePaymentMethod('ApplePay'));
        } else {
            yield put(inActivePaymentMethod('ApplePay'));
        }
    } catch (ex) {
        yield put(inActivePaymentMethod('ApplePay'));
        mbpLogger.logError({
            function: 'workerApplePayAvailable',
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations',
            jsError: ex,
            message: 'workerApplePayAvailable failed',
        });
    }
}

function* watchCalculateShipping() {
    let action = yield take(calculateShipping().type);
    mbpLogger.logDebug({
        action,
        appName: process.env.npm_package_name,
        module: 'Checkout-Operations',
        function: 'watchCalculateShipping',
        message: 'action calculate shipping',
    });
    while (action !== END) {
        yield fork(calcShipping, action);

        action = yield take(calculateShipping().type);
    }
}

function* watchPersistAttribution() {
    let action = yield take(saveBannerToOrder().type);
    while (action !== END) {
        yield fork(onPersistAttribution, action.payload);
        action = yield take(saveBannerToOrder().type);
    }
}

function* watchCheckApplePayAvailability() {
    yield takeLatest(checkApplePayEnabled().type, workerApplePayAvailable);
}

function* watcherValidateShopCart() {
    yield takeLatest(validateShopCart().type, processValidateShopCart);
}

function* workerHandleAddExtraItemToCart(action) {
    const {
        extraItemData,
        orderItemId,
        selectedItems,
        handleClose,
    } = action.data;

    yield put(addAddonsToCart(extraItemData, null, orderItemId, null, selectedItems));

    const response = yield take([loadCart().type, addToCartFailed().type]);

    if (response.type === loadCart().type) {
        handleClose();
    }
}

function* watcherHandleAddExtraItemToCart() {
    yield takeLatest(handleAddExtraItem().type, workerHandleAddExtraItemToCart);
}

const watchers = [
    fork(watchCalculateShipping),
    fork(watchPersistAttribution),
    fork(watchCheckApplePayAvailability),
    fork(watcherValidateShopCart),
    fork(watcherHandleAddExtraItemToCart),
];

export {
    watchers,
    onLoadOrderDetails,
    prepareRetreiveCart,
    checkoutRouteHandler,
};
export default {};
