/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import Cookies from 'universal-cookie';

const validatePassport = (condition, passportData) => {
    const cookies = new Cookies();
    const persistedUserProfile = cookies.get('persisted_user_profile');
    const expiringSubDetails = passportData?.passport_messaging?.user_whose_passport_subscription_is_about_to_expire || {};
    const canceledPassport = persistedUserProfile?.passportFlag?.includes('01-03') || false;

    if (persistedUserProfile?.passportEndDate
        && persistedUserProfile?.passportFlag
        && persistedUserProfile?.passportStartDate) {
        const expirationDate = new Date(persistedUserProfile.passportEndDate).getTime();
        const today = new Date().getTime();

        switch (condition) {
            case 'isActivePassportUser':
                if (expirationDate > today && !canceledPassport) {
                    return true;
                }
                break;
            case 'isExpiredPassportUser':
                if (expirationDate < today || canceledPassport) { // we are treating canceled passport user as same as expired passport user
                    return true;
                }
                break;
            case 'isExpiringPassportUser':
                if (!canceledPassport && expiringSubDetails?.number_of_days_from_expiration && expirationDate > today) {
                    const daysDifference = Math.ceil((expirationDate - today) / (1000 * 60 * 60 * 24));
                    if (daysDifference <= expiringSubDetails?.number_of_days_from_expiration) {
                        return true;
                    }
                }
                break;
            default:
                return false;
        }
    } else if (condition === 'isNonPassportUser') return true;
    return false;
};

export default validatePassport;
