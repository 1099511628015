/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { END } from 'redux-saga';
import {
    call, fork, put, select, take, takeEvery,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import { checkJWT } from '../Member/ducks/Auth/Auth-Operations';
import { commonSelectors } from '../Member/ducks/Common';
import { buyAgain } from '../AddToCart/AddToCart-Actions';
import {
    loadOrderId, getOrderId, setLastOrdered, fetchLastOrderDetails, fetchAllOrderDetails, setAllOrdered,
} from './Order-Actions';
import { getOrderDetailsByOrderNumber, getOrdersByEmail } from '../../../apis/order-apis/fetchOrders';
import { getSSRDeviceType } from '../App/App-Selectors';
import { getProfileInfo } from '../Member/ducks/Common/Common-Selectors';
import { getFeatureFlags } from '../App/ducks/Config/Config-Selectors';
import getSiteConfig from '../../../app/pages/Account/utils/getSiteConfig';
import Helper from '../../../app/pages/Account/state/reducers/Helper';
import * as auth from '../Member/ducks/Auth/Plugin/auth/auth';

function* generateROSSPayload(orderNumber) {
    // Get featureFlags from state
    const featureFlags = yield select(getFeatureFlags);
    // Get device type from state
    const deviceType = yield select(getSSRDeviceType);
    const profile = yield select(getProfileInfo);
    const siteConfig = getSiteConfig();
    const domain = siteConfig.domain;
    const user = Helper.getChannelUser(deviceType, domain, featureFlags['is-web-using-new-channel-codes']); // 30001-HD, 30001-18F, 30002-CCO, etc
    return {
        orderNumber,
        email: profile.email || '',
        user,
    };
}

function* setOrderId(action) {
    try {
        yield put(loadOrderId(action.data));
    } catch (e) {
        console.log('ERROR');
    }
}

function* workerFetchLastOrderDetails({
    email,
}) {
    const isAuthenticated = yield select(commonSelectors.getIsAuthenticatedStatus);
    // Call Auth0 to get new accessToken
    const accessToken =  yield call(auth.getAccessTokenSafely);

    try {
        if (isAuthenticated && email) {
            const ordersByEmail = yield getOrdersByEmail(email, accessToken);

            if (ordersByEmail?.orders?.length) {
                const orderList = ordersByEmail.orders;
                const ORDERED_WITIN_TIME = 30 * 24 * 60 * 60 * 1000;
                let lastPlacedOrderWithinTime;

                // Get the regular products for now -- change this when functionality is completed for all product types
                const filteredRegularProductList = orderList.filter((order) => !order.items.some((item) => item.addonType !== ''));

                /**
                 * Get the last ordered item within the ORDERED_WITHIN_TIME
                 * sort the list in descending order
                 * get first index of list
                 * check if the order placed date is within the ORDERED_WITHIN_TIME
                 */
                filteredRegularProductList.sort((a, b) => new Date(b.orderPlaceddate) - new Date(a.orderPlaceddate));
                const lastOrdered = filteredRegularProductList[0];
                const isLastOrderedWithinTime = new Date(lastOrdered.orderPlaceddate).getTime() > (new Date().getTime() - ORDERED_WITIN_TIME);

                if (isLastOrderedWithinTime) {
                    lastPlacedOrderWithinTime = lastOrdered;
                }

                /**
                 * If ordered within ORDERED_WITIN_TIME, get order details
                 * set the orderDetails in redux to be used
                 */
                if (lastPlacedOrderWithinTime) {
                    const lastOrderedItemDetails = yield getOrderDetailsByOrderNumber(lastPlacedOrderWithinTime.orderNumber, accessToken);
                    if (lastOrderedItemDetails?.orderInfoList) {
                        yield put(setLastOrdered(lastOrderedItemDetails.orderInfoList));
                    }
                }
            } else {
                yield put(setLastOrdered(null));
            }
        } else {
            yield put(setLastOrdered(null));
        }
    } catch (ex) {
        mbpLogger.logError({
            function: 'workerFetchLastOrderDetails',
            appName: process.env.npm_package_name,
            module: 'Order-Operations.js',
            jsError: ex,
            email,
        });
    }
}

function* workerFetchAllOrderDetails({ data }) {
    const isAuthenticated = yield select(commonSelectors.getIsAuthenticatedStatus);
    const accessToken = yield call(checkJWT);
    try {
        if (isAuthenticated && data) {
            const { orderNumber, history, intOrderNumber } = data;
            const payload = yield generateROSSPayload(orderNumber);
            const getAllOrderDetails = yield getOrderDetailsByOrderNumber(accessToken, payload);
            const reqPayload = { history };

            // if intOrderNumber is passed, buy single item. Else buy all items in order
            if (intOrderNumber) {
                const orderDetails = getAllOrderDetails?.orders?.find((order) => order.intOrderNumber === intOrderNumber) || {};
                reqPayload.orders = [orderDetails];
            } else {
                reqPayload.orders = getAllOrderDetails?.orders;
            }
            // buy again will accept array of items and buy all items inside the array
            yield put(buyAgain(reqPayload));
            if (getAllOrderDetails?.orders?.length > 0) {
                yield put(setAllOrdered(getAllOrderDetails.orders));
            }
        } else {
            yield put(setAllOrdered(null));
        }
    } catch (ex) {
        mbpLogger.logError({
            function: 'workerFetchLastOrderDetails',
            appName: process.env.npm_package_name,
            module: 'Order-Operations.js',
            jsError: ex,
            data,
        });
    }
}

function* watchSetOrderId() {
    yield takeEvery(getOrderId().type, setOrderId);
}

function* watcherFetchAllOrderDetails() {
    let action = yield take(fetchAllOrderDetails().type);
    while (action !== END) {
        yield fork(workerFetchAllOrderDetails, action.payload);
        action = yield take(fetchAllOrderDetails().type);
    }
}

function* watcherFetchLastOrderDetails() {
    let action = yield take(fetchLastOrderDetails().type);
    while (action !== END) {
        yield fork(workerFetchLastOrderDetails, action.payload);
        action = yield take(fetchLastOrderDetails().type);
    }
}

const watchers = [
    fork(watchSetOrderId),
    fork(watcherFetchLastOrderDetails),
    fork(watcherFetchAllOrderDetails),
];

export {
    watchers,
    setOrderId,

    // Actions
    loadOrderId,
    getOrderId,
};
