/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const Countries = {
    US: 'United States',
    CA: 'Canada',
};

export const countryISOAlfa3Codes = {
    US: 'USA',
    CA: 'CAN',
};

export const countryISOAlfa2Code = {
    USA: 'US',
    CAN: 'CA',
};
