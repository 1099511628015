/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import mbpLogger from 'mbp-logger';

/**
 *
 * addCMSTheme - add Contentstack theme values to brandTheme
 *
 * By convention, all CMS values are snake_case, rather than standard js camelCase.
 * It was decided to preserve the snake_case when transferring values to brandTheme,
 * so that brandTheme attributes would always exactly match CMS names.
 * It also has the effect of signaling an attribute came from CMS rather than a hardcoded/legacy value.
 * @deprecated
 * @param {object} brandTheme brand's theme object from brandsConfig[ brandcode ], e.g. brandsConfig.HD
 * @param {object} cmsData    brand theme values from contentstack (object in first entry of content.entries of brand_theme_full)
 */
const addCMSTheme = (brandTheme, cmsData, THEME_QUERY, GRAPHQL_ENV) => {
    // font-family to use for this brand's site
    // e.g. 'font-family: lato, Arial, Verdana, sans-serif;'
    if (cmsData.global_font_family) {
        brandTheme.global_font_family = cmsData.global_font_family;      // eslint-disable-line no-param-reassign
    } else {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            query: THEME_QUERY,
            component: 'AppShell.js',
            message: 'Brand has no global_font_family',
            env: GRAPHQL_ENV,
        });
    }

    brandTheme.palette.cms = brandTheme.palette.cms ? brandTheme.palette.cms : {};          // eslint-disable-line no-param-reassign

    // Color palette from CMS.
    // e.g.
    //     brandTheme.palette.cms.primary = 'a1001a'
    //     brandTheme.palette.cms['Body Font'] = '#2f2f2f'
    ['colors', 'cta_colors', 'layout_colors', 'additional_colors', 'additional_palette_colors'].forEach((sectionName) => {
        if (cmsData[sectionName]) {
            const section = cmsData[sectionName];
            if (Array.isArray(section)) {
                /*
                    Entries expected in this form:
                        {
                            'title': 'Body Font',
                            'color': {
                                'color': '#2f2f2f',
                                'description': 'NIGHT RIDER',
                                'transparency': '',
                                'version': '1'
                            }
                        },
                */
                section.forEach((entry) => {
                    brandTheme.palette.cms[entry.title] = entry.color.color;      // eslint-disable-line no-param-reassign
                });
            } else {
                /*
                    Entries expected in this form:
                        'primary_hover': {
                            'color': '#790000',
                            'description': 'MAROON',
                            'transparency': '',
                            'version': '1'
                        },
               */
                Object.keys(section).forEach((entryKey) => {
                    brandTheme.palette.cms[entryKey] = section[entryKey].color;      // eslint-disable-line no-param-reassign
                });
            }
        }
    });

    // e.g. brandTheme.logos.primary_logo = {
    //     'content_type': 'image/svg+xml',
    //     'filename': 'hd-logo-black.svg',
    //     'url': 'https://images.contentstack.io/v3/assets/blt89dbf1c763ec00a6/bltff290eac2ecfd735/5e20dfa82d54964f9f397316/hd-logo-black.svg',
    //     'title': 'hd-logo-black.svg',
    //     'description': 'Harry and David Logo'
    // }
    if (cmsData.logos) {
        brandTheme.logos = {};      // eslint-disable-line no-param-reassign
        Object.keys(cmsData.logos).forEach((logoKey) => {
            if (cmsData.logos[logoKey]) {
                const cmsLogo = cmsData.logos[logoKey];
                brandTheme.logos[logoKey] = {      // eslint-disable-line no-param-reassign
                    contentType: cmsLogo.content_type || null,
                    filename: cmsLogo.filename || null,
                    description: cmsLogo.description || null,
                    title: cmsLogo.title || null,
                    url: cmsLogo.url || null,
                };
            }
        });
    }

    // Array of fonts, as defined by business in Contentstack (Brand Configuration > Typography Stack),
    // in order of primacy e.g. primary font is:
    //    brandTheme.cms.typography[0] = {
    //     'font_name': 'Lato - sans',
    //     'font_style': 'Main copy, sans serif',
    //     'font_stack': 'font-family: 'latoregular', Arial, Verdana, sans-serif;',
    //     'font_file_primary': 'DancingScript-Regular.woff2',
    //     'font_file_fallback': 'DancingScript-Regular.woff'
    // }
    // Generates <style> object to inject into #root

    if (cmsData.typography) {
        brandTheme.typography.cms = []; // eslint-disable-line no-param-reassign
        const stackArr = [];
        const linkImport = [];
        // eslint-disable-next-line consistent-return
        cmsData.typography.forEach((font) => {
            if (font.font_reference)  brandTheme.typography.cms.push({ ...font.font_reference });
            else if (font.webfont_import) stackArr.push(font.webfont_import.font_import);
            else if (font.webfont_link) linkImport.push(font.webfont_link.font_tag);
            else brandTheme.typography.cms.push({ ...font });
        });
        // set global font stack and fontsize for EM reference
        stackArr.push(`body {${cmsData.global_font_family} font-size: ${cmsData.baseline_font_size}px}`);
        if (brandTheme.typography.cms.length) {
            brandTheme.typography.cms.forEach((k) => {
                if (k?.webfont?.length) {
                    const webFonts = k.webfont[1]?.url ? `\nsrc: url(${k.webfont[0].url}), url(${k.webfont[1]?.url ? k.webfont[1].url : k.webfont[0].url})` : '';
                    const str = `@font-face {font-family: ${k.font_name}; ${webFonts}; font-display: swap;}`;
                    stackArr.push(str);
                    return str;
                }
                return null;
            });
        }
        // eslint-disable-next-line no-param-reassign
        brandTheme.typography.styleblock = `<style data-type="font-stack">${stackArr.join('\n')}</style>${linkImport.join('\n')}`;
    }

    // Other key-pair values from Contentstack,
    // such as cta_icon = '>' or '>>', presentation_family = 'food' or 'flower'
    // e.g. if (brandTheme.presentation_family === 'food') {
    if (cmsData.other) {
        Object.assign(brandTheme, cmsData.other);
    }
    if (cmsData.block_headings) {
        // eslint-disable-next-line no-param-reassign
        brandTheme.blockHeading = cmsData.block_headings;
    }
    const chatBotTitles = cmsData?.other?.chatbot;
    const chatBotColor = cmsData?.cta_colors?.primary;
    return {
        chatBotTitles,
        chatBotColor,
    };
};

export default addCMSTheme;
