/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import {
    fork, call, put, select,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import takeLeading from '../../../app/helpers/sagas/takeLeading';
import {
    setLocationType, setUserSelectedAddress, setUserSubmittedProductFilterZipcode, setValidatedZipcode, setProductFilterZipcodeValidity,
} from '../App/App-Actions';
import { getFeatureFlags } from '../App/ducks/Config/Config-Selectors';
import {
    getSCIData, setSCIDecedent, setSCICampaign, setSCILocNumber, setSCIDispositionType,
} from './SCI-Actions';
import { buildSCIURLPath, determineIfFuneralIsInFuture, sciDirectLocIDs } from './SCI-Helpers';
import * as authOperations from '../Member/ducks/Auth/Auth-Operations';
import { fetchSCIData } from '../../../apis/sci-api/fetchSCIData';

function* workerGetSCIData(action) {
    try {
        const featureFlags = yield select(getFeatureFlags);
        const JWT = yield call(authOperations.checkJWT);
        const {
            mId = '', utm_term = '', referrer = '', bannerCodeData = {}, history = {}, locNumber = '',
        } = action.payload;
        const isSCIDirect = sciDirectLocIDs[locNumber];
        let url = bannerCodeData.url ? bannerCodeData.url : '/all-funeral-arrangements-400176493';
        const isDeceasedJewish = url.includes('jewish');
        // scoped as error to toggle by flag as opposed to debug logging all of prod
        if (featureFlags['is-sci-logging-enabled']) {
            mbpLogger.logError({
                message: `SCI DEBUG: Intiating action for locNumber=${locNumber} mId=${mId}`,
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                payload: action.payload,
                mId,
                referrer,
                bannerCodeData,
                locNumber,
                url,
            });
        }

        // Set params for <SCIImoc />
        yield (put(setSCILocNumber(locNumber)));
        yield (put(setSCICampaign(utm_term)));
        // Call SCI API
        let apiData = {};
        // Do not call the API for SCI direct which is based on loc number
        if (!isSCIDirect) {
            apiData = yield call(fetchSCIData, { mId, JWT, locNumber });
        }
        // using the locNumber returns ONLY the location property
        // using mId returns an object with Location attached
        // find out which one it is an reconcole the difference
        if (!apiData.Location && apiData?.ZipCode?.length) {
            apiData = {
                Location: apiData,
            };
        }

        // API Response data flows
        const {
            Location = {}, DispositionType = '', FuneralServices = [], PrimaryPhotoUrl = '', DecedentAge = '', DecedentGender = '',
            IsDecedentAParent = '', IsDecedentASpouse = '', Themes = [],
        } = apiData;

        const funeralIsInFuture = determineIfFuneralIsInFuture(FuneralServices);

        // scoped as error to toggle by flag as opposed to debug logging all of prod
        if (featureFlags['is-sci-logging-enabled']) {
            mbpLogger.logError({
                message: `SCI DEBUG: API call details for locNumber=${locNumber} mId=${mId} if empty, API request may have been skipped or failed`,
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                Location,
                DispositionType,
                FuneralServices,
                PrimaryPhotoUrl,
            });
        }

        // If Zip, set to filter on collection pages gnav
        if (Location?.ZipCode) {
            yield put(setUserSelectedAddress({ addressComponents: apiData, addressSource: 'SCI' }));

            if (funeralIsInFuture) {
                yield put(setValidatedZipcode(Location.ZipCode));
                yield put(setProductFilterZipcodeValidity(true));
                yield put(setUserSubmittedProductFilterZipcode(Location.ZipCode));
            }

            // scoped as error to toggle by flag as opposed to debug logging all of prod
            if (featureFlags['is-sci-logging-enabled']) {
                mbpLogger.logError({
                    message: `SCI DEBUG: Set Zip Code complete for locNumber=${locNumber} mId=${mId} zipCode=${Location?.ZipCode}`,
                    appName: process.env.npm_package_name,
                    module: 'mbp-pwa-ui',
                });
            }
        } else {
            mbpLogger.logError({
                message: `SCI ERROR: No Zipcode provided for locNumber=${locNumber} mId=${mId}`,
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                initiatingAction: action.payload,
                Location,
                DispositionType,
                FuneralServices,
            });

            // set address source as SCI so we display SCI imoc on category pages
            // the checkoutAutoFill flag set to false ensures there is no attempt to
            // autofill with an empty address obj in checkout
            yield put(setUserSelectedAddress({ addressSource: 'SCI', checkoutAutoFill: false }));
        }

        // Consumed in <SCIImoc />
        if (DispositionType) {
            yield (put(setSCIDispositionType(DispositionType)));
            // scoped as error to toggle by flag as opposed to debug logging all of prod
            if (featureFlags['is-sci-logging-enabled']) {
                mbpLogger.logError({
                    message: `SCI DEBUG: Set DispositionType complete for locNumber=${locNumber} mId=${mId}`,
                    appName: process.env.npm_package_name,
                    module: 'mbp-pwa-ui',
                    dispositionType: DispositionType,
                });
            }
        } else {
            mbpLogger.logError({
                message: `SCI ERROR: No DispositionType provided for locNumber=${locNumber} mId=${mId}`,
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                dispositionType: DispositionType,
            });
        }

        if (funeralIsInFuture) {
            if (!isDeceasedJewish && !isSCIDirect) {
                yield put(setLocationType('Funeral home'));
            }
            if (!FuneralServices?.length) {
                mbpLogger.logError({
                    message: 'SCI ERROR: Funeral dates are missing',
                    appName: process.env.npm_package_name,
                    module: 'mbp-pwa-ui',
                    isfuneralInFuture: funeralIsInFuture,
                    services: FuneralServices,
                });
            }
        } else {
            mbpLogger.logError({
                message: 'SCI ERROR: Funeral dates are not in the future, locationType not set to funeral home redirecting to /all-sympathy-flowers-gifts-400176508',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                isfuneralInFuture: funeralIsInFuture,
                services: FuneralServices,
            });
            // if the funeral is in the past need to redirect to a certain collection
            // however need to preserve url params
            // split on the param marker, replace the first part of the url which is the collection link
            // recreate full url
            const splitUrl = url?.split('?');
            splitUrl[0] = '/all-sympathy-flowers-gifts-400176508';
            url = splitUrl?.join('?');
        }

        const SCIDecedentData = {
            DecedentAge,
            DecedentGender,
            Themes,
            IsDecedentAParent,
            IsDecedentASpouse,
        };

        if (FuneralServices?.length) {
            SCIDecedentData.FuneralServices = FuneralServices
                .filter((service) => service?.ServiceTypeCode && service.ServiceTypeCode !== null)
                .map((service) => service.ServiceTypeCode);
        }

        // set Decedent data from API
        yield put(setSCIDecedent(SCIDecedentData));

        /* Rebuld URL */

        // first part of the URL comes from CMS (bannercode)
        // however it is changed if all the funeral services were in the past
        // we get mId, utm_term(campagin_id), utm_referrer from the fhDirect link
        // we get utm_disposition from api call above
        // can we/should we move all this to just the CMS?
        const utm_mId_param = mId ? `utm_mId=${mId}` : '';
        const utm_term_param = utm_term ? `utm_term=${utm_term}` : '';
        const utm_disposition_param = DispositionType ? `utm_disposition=${DispositionType}` : 'utm_disposition=unknown';
        const utm_referrer_param = referrer ? `utm_referrer=${referrer}` : '';

        const redirectUrl = buildSCIURLPath(url, [utm_mId_param, utm_term_param, utm_disposition_param, utm_referrer_param]);

        yield call(history.push, redirectUrl);

        // scoped as error to toggle by flag as opposed to debug logging all of prod
        if (featureFlags['is-sci-logging-enabled']) {
            mbpLogger.logError({
                message: `SCI DEBUG: Flow complete, sending customer to ${redirectUrl}`,
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
            });
        }
    } catch (ex) {
        const {
            mId = '', utm_term = '', referrer = '', bannerCodeData = {}, history = {}, locNumber = '',
        } = action.payload;

        const url = bannerCodeData.url ? bannerCodeData.url : '/all-funeral-arrangements-400176493';
        const utm_mId_param = mId ? `utm_mId=${mId}` : '';
        const utm_term_param = utm_term ? `utm_term=${utm_term}` : '';
        const utm_disposition_param = 'utm_disposition=unknown'; // this would normally come from API response
        const utm_referrer_param = referrer ? `utm_referrer=${referrer}` : '';

        const redirectUrl = buildSCIURLPath(url, [utm_mId_param, utm_term_param, utm_disposition_param, utm_referrer_param]);
        yield put(setUserSelectedAddress({ addressSource: 'SCI' }));
        yield call(history.push, redirectUrl);

        mbpLogger.logError({
            message: `SCI ERROR: Something went wrong attempting the SCI Flow for mId=${mId} locNumber=${locNumber} sending customer to fallback url=${redirectUrl}`,
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            jsError: ex,
        });
    }
}

function* watchGetSCIData() {
    yield takeLeading(getSCIData().type, workerGetSCIData);
}

const watchers = [
    fork(watchGetSCIData),
];

export default watchers;
