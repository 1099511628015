/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useSelector } from 'react-redux';
import { getFeatureFlags } from '../../state/ducks/App/ducks/Config/Config-Selectors';

// initially match a string that appears to be an image file name
const regExMatch = /(\.png)|(\.jpg)|(\.jpeg)|(\.gif)||(\.mp4)|(\.webp)/g;
// replace instances of filenames and multiple white-spaces
// [-|_|(|)] <- match hyphens, underscores and parens
// ([a-z])(?=[A-Z])/g; <- match instance where an lowercase is immediately followed by an uppercase
// [[' ']{2,}] <- match instances of multiple spaces
const stripAllUnwanted = /(\.png)|(\.jpg)|(\.jpeg)|(\.gif)|(\.webp)|(\.psd)|(\.mp4)|(\.svg)|[-|_|(|)]/g;
const separateUppercase = /([a-z])(?=[A-Z])/g;
const stripAllMultispaces = /[[' ']{2,}]/g;
const normalizeAltCopy = (string) => {
    const featureFlags = useSelector(getFeatureFlags);
    if (string === '' || string === undefined || string === null) return 'web image';
    if (!featureFlags['is-alt-helper-enabled']) return string;
    const altString = string?.toString();
    if (altString?.toString()?.match(regExMatch)) {
        return altString.replace(stripAllUnwanted, ' ').replace(separateUppercase, '$1 ').replace(stripAllMultispaces, '').trim(); // return transformed string
    }
    return string; // else return untouched string
};
export default normalizeAltCopy;
