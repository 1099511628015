/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './GiftMessage-ActionTypes';

export const addGreetingCardGiftMessage = (data) => ({
    type: actionTypes.ADD_GREETING_CARD_GIFT_MESSAGE,
    data,
});

export default {};
