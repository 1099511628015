/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const ADD_RECIPIENT = 'ADD_RECIPIENT';
const UPDATE_RECIPIENTS = 'UPDATE_RECIPIENTS';
const CHANGE_WORKING_RECIPIENT = 'CHANGE_WORKING_RECIPIENT';
const UPDATE_WORKING_RECIPIENT = 'UPDATE_WORKING_RECIPIENT';
const SET_WORKING_RECIPIENT = 'SET_WORKING_RECIPIENT';
const LOAD_ORDERITEM_DETAILS = 'LOAD_ORDERITEM_DETAILS';

const TRIGGER_VALIDATE_ORDER = 'MFE/MBP-CHECKOUT/TRIGGER_VALIDATE_ORDER';
const CALL_TO_TRIGGER_VALIDATE_ORDER = 'MFE/MBP-CHECKOUT/CALL_TO_TRIGGER_VALIDATE_ORDER';
const TRIGGER_VALIDATE_ORDER_COMPLETED = 'MFE/MBP-CHECKOUT/TRIGGER_VALIDATE_ORDER_COMPLETED';

export {
    ADD_RECIPIENT,
    UPDATE_RECIPIENTS,
    CHANGE_WORKING_RECIPIENT,
    UPDATE_WORKING_RECIPIENT,
    CALL_TO_TRIGGER_VALIDATE_ORDER,
    TRIGGER_VALIDATE_ORDER,
    TRIGGER_VALIDATE_ORDER_COMPLETED,
    SET_WORKING_RECIPIENT,
    LOAD_ORDERITEM_DETAILS,
};
