/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpUtil from 'mbp-api-util';

const config = {
    development: {
        MMO_EDIT_URL: 'https://self-service-www-uat.mybluemix.net/editmyorder',
        ENABLE_CONSOLE_LOG: false,
        SESSION_STORAGE_ENABLED: 'off',
        SESSION_STORAGE_EXPIRES: 3600, // 1 h
        PERSIST_KEY: 'account',
        SHOP_THE_SITE_KEY: 'shopTheSite',
        ENV_NAME: 'DEV',
        DEV_ENV:
            typeof window !== 'undefined'
                ? (window.location.hostname === 'localhost' && window.location.port === '3007')
                : false, // set true for localhost
        ACCESS_TOKEN: '',
        ID_TOKEN: '',
        IS_AUTHENTICATED: false,
        APP_API_ENDPOINT_PATH: '/r/api',
        COULD_UPDATE_EMAIL_PREFERENCE_AFTER: 1860, // 31 minutes
        AUTH_LOGIN_URL: '/auth/login',
        AUTH_LOGOUT_URL: '/auth/logout',
        GENERIC_ERROR:
            "Oops, something went wrong and we couldn't process your request. Please try again later.",
        CC_ENCRYPTION_SOURCE_ID: 'W0066',
        MAX_ORDER_LIST: 20,
        USPS_ORDERTRACKING_URL:
            'https://tools.usps.com/go/TrackConfirmAction?tLabels=',
        FEDEX_ORDERTRACKING_URL:
            'https://www.fedex.com/fedextrack/?trknbr=',
        UPS_ORDERTRACKING_URL:
            'http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=',
        UDS_ORDERTRACKING_URL:
            'https://www.uniteddeliveryservice.com/track/barcode/',
        MMO_ORIGIN: typeof window !== 'undefined' ? window.location.origin : '',
    },
    production: {
        MMO_EDIT_URL: 'https://connect.1800flowers.com/editmyorder',
        ENABLE_CONSOLE_LOG: false,
        SESSION_STORAGE_ENABLED: 'off',
        SESSION_STORAGE_EXPIRES: 3600, // 1 h
        PERSIST_KEY: 'account',
        SHOP_THE_SITE_KEY: 'shopTheSite',
        ENV_NAME: 'PROD',
        DEV_ENV: false,
        ACCESS_TOKEN: '', // will be overridden by Auth0's token
        ID_TOKEN: '', // will be overridden by Auth0's token
        IS_AUTHENTICATED: false, // will be overridden by Auth0's token
        APP_API_ENDPOINT_PATH: '/r/api',
        COULD_UPDATE_EMAIL_PREFERENCE_AFTER: 1860, // 31 minutes
        AUTH_LOGIN_URL: '/auth/login',
        AUTH_LOGOUT_URL: '/auth/logout',
        GENERIC_ERROR:
            "Oops, something went wrong and we couldn't process your request. Please try again later.",
        CC_ENCRYPTION_SOURCE_ID: 'W0066',
        MAX_ORDER_LIST: 20,
        USPS_ORDERTRACKING_URL:
            'https://tools.usps.com/go/TrackConfirmAction?tLabels=',
        FEDEX_ORDERTRACKING_URL:
            'https://www.fedex.com/fedextrack/?trknbr=',
        UPS_ORDERTRACKING_URL:
            'http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=',
        UDS_ORDERTRACKING_URL:
            'https://www.uniteddeliveryservice.com/track/barcode/',
        MMO_ORIGIN: typeof window !== 'undefined' ? window.location.origin : '',
    },
};

// process.env.NODE_ENV will not work here because it gives 'production' for every deployed environments which breaks for uat1
const env = mbpUtil.getEnv('APP_GRAPHQL_ENV');
const GQL_ENV = (env === 'production' || env === 'uat2') ? 'production' : 'development';
const configENV = config[GQL_ENV];

export default configENV;
