/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useEffect, useState } from 'react';
import { getAccessTokenSafely } from '../../../state/ducks/Member/ducks/Auth/Plugin/auth/auth';

const useGetAccessTokenSafely = () => {
    const [jwt, setJWT] = useState(null);

    useEffect(() => {
        const getJWT = async () => {
            // No need to trap failures, getAccessTokenSafely always returns a value
            const token = await getAccessTokenSafely();
            setJWT(token);
        };

        getJWT();
    }, []);

    return jwt;
};

export default useGetAccessTokenSafely;
