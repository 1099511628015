/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as giftMessageExpressionsActions from './GiftMessageExpressions-Actions';
// import * as giftMessageExpressionsOperations from './GiftMessageExpressions-Operations';
import giftMessageExpressionsReducer from './GiftMessageExpressions-Reducers';

export {
    // giftMessageExpressionsOperations,
    giftMessageExpressionsActions,
};
export default giftMessageExpressionsReducer;
