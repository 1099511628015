/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import axios from 'axios';
import mbpLogger from 'mbp-logger';
import mbpUtil from 'mbp-api-util';
import Cookies  from 'universal-cookie';

import isDesktopMobileTablet from './DesktopMobile/isDesktopMobileTablet';
import useBrowserUUID from './useBrowserUUID';

const DEFAULT_BRAND = mbpUtil.getEnv('APP_BRAND_TOKEN') || 'flowers';
const brands = {
    thepopcornfactory: ['thepopcornfactory', 10201],
    cheryls: ['cheryls', 10202],
    flowers: ['flowers', 20051],
    fanniemay: ['fanniemay', 20052],
    baskets: ['baskets', 20054],
    fruitbouquets: ['fruitbouquets', 20551],
    simplychocolate: ['simplychocolate', 24052],
    harryanddavid: ['harryanddavid', 21051],
    wolfermans: ['wolfermans', 21551],
    stockyards: ['stockyards', 22051],
    celebrations: ['celebrations', 22551],
    napco: ['napco', 23552],
    berries: ['berries', 25052],
    goodsey: ['goodsey', 24552],
    personalizationmall: ['personalizationmall', 20051],
    vitalchoice: ['vitalchoice', 22051],
    'loadtest1.origin': ['harryanddavid', 21051],
};

const HTTP_HEADER_ACCEPTED_ENCODING = 'gzip, deflate';

/**
 * Find host from either:
 *  1. window object
 *  2. env var (wc_host)
 *  3. default: localhost
*/
const getBrandHost = () => {
    const windowHostname = (typeof window !== 'undefined') && window.location && window.location.hostname;

    mbpLogger.logDebug({
        windowHostname,
        function: 'getBrandHost',
        appName: process.env.npm_package_name,
        module: 'wc-api',
        message: 'getBrandHost | windowHostname',
    });

    // Handle common SSR host (proxyserver.common)
    if (!windowHostname) {
        mbpLogger.logDebug({
            'global.mbp': global.mbp || null,
            function: 'getBrandHost',
            appName: process.env.npm_package_name,
            module: 'wc-api',
            message: 'getBrandHost | !host',
        });

        const { ssrConfig } = global.mbp;
        mbpLogger.logDebug({
            ssrConfig,
            function: 'getBrandHost',
            appName: process.env.npm_package_name,
            module: 'wc-api',
            message: 'Determine true SSR domain',
        });

        if (ssrConfig && ssrConfig.hostName) {
            return ssrConfig.hostName;
        }
    }

    if (windowHostname) {
        return windowHostname;
    }

    return null;
};

const getCheckoutFeatureflags = () => {
    const sessionStorageCheckout = JSON.parse(window.sessionStorage.getItem('persist:checkout'));

    const appSessionStorage = sessionStorageCheckout ? JSON.parse(sessionStorageCheckout.app) : {};

    return appSessionStorage.featureFlags || {};
};

/**
 * Find host from either:
 *  1. window object
 *  2. env var (wc_host)
 *  3. default: localhost
*/
const getHost = () => {
    const windowHostname = (typeof window !== 'undefined') && window.location && window.location.hostname;

    if (!windowHostname) {
        return process.env.APP_WC_HOST_SSR || 'localhost';
    }

    const wcHost = mbpUtil.getEnv('APP_WC_HOST');
    return wcHost || windowHostname || 'localhost';
};

const getProtocol = () => {
    // SSR
    if (typeof window === 'undefined') {
        return process.env.APP_WC_PROTOCOL_SSR || 'https';
    }

    return mbpUtil.getEnv('APP_WC_PROTOCOL') || 'https';
};

const getPrefix = () => {
    // SSR
    if (typeof window === 'undefined') {
        return process.env.APP_API_PREFIX_SSR || '';
    }

    return mbpUtil.getEnv('APP_API_PREFIX') || '/r/api';
};

const getShopperManagerCookie = () => {
    const COOKIE_NAME = 'ShopperManagerEnterprise';
    const cookies = new Cookies();
    return cookies.get(COOKIE_NAME) || '';
};

const getSiteVariantCookie = () => {
    const COOKIE_NAME = 'sv';

    const cookies = new Cookies();

    return cookies.get(COOKIE_NAME) || '';
};

const getDeviceType = () => {
    if (typeof navigator !== 'undefined') {
        const navigatorUserAgent = navigator.userAgent;
        return isDesktopMobileTablet(navigatorUserAgent);
    }
    return '';
};

const getVersion = () => {
    const appVersion = mbpUtil.getEnv('APP_VERSION_ENV') || '';
    const deviceType = getDeviceType();
    return `${appVersion}-${deviceType}`;
};

const getEnterpriseId = () => {
    let enterpriseId = '';

    if (typeof window !== 'undefined') {
        enterpriseId = window.localStorage.getItem('enterpriseId') || '';
    }

    return enterpriseId;
};

const anonymousId = () => {
    let bId = '';

    if (typeof window !== 'undefined') {
        bId = useBrowserUUID() || '';
    }

    return bId;
};

const getTypeCheckoutFlow = () => {
    try {
        if (typeof window !== 'undefined') {
            const featureFlags = getCheckoutFeatureflags();

            const sessionStorageUI = JSON.parse(window.sessionStorage.getItem('persist:ui'));
            const abTesting = sessionStorageUI ? JSON.parse(sessionStorageUI.abTesting) : {};

            if (
                featureFlags['which-checkout-variation'] === 'enterprise'
                || (abTesting && abTesting.checkout === 'universal')
            ) {
                return 'pwa-universal';
            }
            if (featureFlags['which-checkout-variation'] === 'enterprise2') {
                return 'pwa-enterprise2';
            }
            if (featureFlags['which-checkout-variation'] === 'floral') {
                return 'pwa-floral';
            }
            if (featureFlags['which-checkout-variation'] === 'food') {
                return 'pwa-food';
            }
        }
        return 'pwa';
    } catch (e) {
        return 'pwa';
    }
};

const getHeaders = (headersInput) => {
    const headers = {
        ...headersInput,
        'baggage-sessionid': getShopperManagerCookie(),
        'source-app': getTypeCheckoutFlow(),
    };

    const appVersion = getVersion();
    if (appVersion) {
        headers['app-version'] = appVersion;
    }

    const sv = getSiteVariantCookie();

    if (!headers.sv && sv) {
        headers.sv = sv;
    }

    if (typeof window === 'undefined') {
        headers['Accept-Encoding'] =  HTTP_HEADER_ACCEPTED_ENCODING;
    }

    // pass enterpriseId
    const enterpriseId = getEnterpriseId();

    if (enterpriseId) {
        headers.eid = enterpriseId;
    }

    const anonymous = anonymousId();
    if (anonymous) {
        headers.anonymousId = anonymous;
    }

    return headers;
};

const getRootUri = () => '/wcs/resources/store/';

const getBrandFromHost = (inHost) => {
    mbpLogger.logDebug({
        inHost,
        function: 'getBrandFromHost',
        appName: process.env.npm_package_name,
        module: 'wc-api',
        message: 'getBrandFromHost | START',
    });
    let brandFromHost = DEFAULT_BRAND;
    const host = inHost || getBrandHost();

    if (host) {
        const tempHost = host.toLowerCase();

        // Search through brands object to find current brand
        Object.keys(brands).forEach((brand) => {
            const [token] = brands[brand];

            if (tempHost.includes(brand)) {
                mbpLogger.logDebug({
                    brand,
                    function: 'getBrandFromHost',
                    appName: process.env.npm_package_name,
                    module: 'wc-api',
                    message: 'getBrandFromHost | tempHost.includes(brand)',
                });
                // Set Brand Token
                brandFromHost = token || DEFAULT_BRAND;
            }
        });
    }

    mbpLogger.logDebug({
        brandFromHost,
        function: 'getBrandFromHost',
        appName: process.env.npm_package_name,
        module: 'wc-api',
        message: 'getBrandFromHost | END',
    });

    return brandFromHost;
};

/**
 * add jwtToken as a header
 * @param {*} jwt_token
 * @param {*} config
 */
const addJwtToken = function addJwtToken(jwtToken) {
    if (!jwtToken || jwtToken === '') {
        return 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ik16ZENSRUZGTWpkRVJUaERNalJDUWpSQ016TkJNakE1TURrMk5FWTNORFF6UVVZNE9UYzVNQSJ9.eyJodHRwczovLzgwMC1mbG93ZXJzLm5ldCI6eyJ0eXBlIjoiZ3Vlc3QiLCJzeXN0ZW1JRCI6IkFVVEgwLUdVRVNULTEwMDAwMDAwMDAwMDAwMDI5MjIiLCJjb250YWN0SWQiOiJHVUVTVC0xMDAwMDAwMDAwMDAwMDAyOTIyIn0sImlzcyI6Imh0dHBzOi8vZmxvd2Vyc3Rlc3QuYXV0aDAuY29tLyIsInN1YiI6Imdsb2lVRGxSeDdoaklBR3JlUDRpZ2o0c0RGdTlzYzN4QGNsaWVudHMiLCJhdWQiOiJodHRwczovL2RldjEub3JpZ2luLWdjcC44MDAtZmxvd2Vycy5uZXQiLCJpYXQiOjE1MzcyMTczMjUsImV4cCI6MTUzNzMwMzcyNSwiYXpwIjoiZ2xvaVVEbFJ4N2hqSUFHcmVQNGlnajRzREZ1OXNjM3giLCJzY29wZSI6ImN1c3RvbWVyOmNoZWNrb3V0IiwiZ3R5IjoiY2xpZW50LWNyZWRlbnRpYWxzIn0.NavrE2LDwYJM5e3WP9DD2cPBikUkGOv-bwfCoUSfWNx04iXWZRo_CKYOzKlKFHn78q2ChGI3otzP_x5Yb69Hl4EcfSXJ4by4PDBk5Vu9CQ3s4VWFpYyzycxsGLmLU_pGLiM44mpfQ8OWv_SMJyQKqcYiRNupUS2P2K1Jgus4l-gCRIkCgJ6IHpA7h9N9BK-WCpU32EDQXUIGWTwMU1i_861w0sWVD2NqP3ugxqQC_gahQ4y4m7_MBKTiPfdUhmQkaN82A-j7_5ndcO9QaDDcRzGwuvMxTmKQMqSNifGZMsNjO0UzdZD6HigOi_L-2iDm9NCo27gxajseuEPKrcSVbg';
    }
    return `Bearer ${jwtToken}`;
};

/**
 * add brand as param
 * brand is either passed in by client or determined using window hostname
 * @param {*} wcEnv
 */
const addBrand = (wcEnv) => (wcEnv.brand || getBrandFromHost() || DEFAULT_BRAND);

/**
 * parseParams to allow repeat same parameter in this case for the face filter.
 *
 * @param params    - object with parameters to be added to the url
 */
const parseParams = (params) => {
    const keys = Object.keys(params);
    let options = '';

    keys.forEach((key) => {
        const isParamTypeObject = typeof params[key] === 'object';
        const isParamTypeArray = isParamTypeObject && (params[key].length >= 0);

        if (!isParamTypeObject) {
            options += `${key}=${encodeURIComponent(params[key])}&`;
        }

        if (isParamTypeObject && isParamTypeArray) {
            params[key].forEach((element) => {
                options += `${key}=${encodeURIComponent(element)}&`;
            });
        }
    });

    return options ? options.slice(0, -1) : options;
};

const getEntryBrand = (wcEnv) => {
    // Review if the entry brand cookie exist or sent the default one
    const cookies = new Cookies();
    const entryBrandCookie = cookies.get('entryBrand');
    const entryBrand = entryBrandCookie || addBrand(wcEnv);

    return entryBrand || '';
};

/**
 * Call Axios to execute remote call to WC service.
 * Note: brand will get added as parameter on the URL
 *
 * @param method    - get/post/put/delete/etc
 * @param url       - string for url to call
 * @param params    - object with parameters to be added to the url
 * @param data      - body
 * @param wcEnv     - WC environment properties
 * @param jwtToken  - JWT Access Token
 * @param header    - object with headers to be added to the request
 * @param addBrandParam - bool which indicates if the brand should be added as a param
 */
const callAxios = (method, url, params, data, wcEnv, jwtToken, headers, addBrandParam) => {
    try {
        const allHeaders = getHeaders(headers);
        if (url.indexOf('://cdn') >= 0) { // removing baggage-sessionid for cdn urls
            delete allHeaders['baggage-sessionid'];
        }
        // Bail if no GUID or JWT provided
        if ((data && !data.guid) && !jwtToken && jwtToken !== null) {
            return false;
        }
        const reqParams = (!params) ? {} : params;
        const config = {
            method,
            url,
            params: { ...reqParams },
            // eslint-disable-next-line no-shadow
            paramsSerializer: reqParams.facet ? (reqParams) => parseParams(reqParams) : null,
            headers: allHeaders,
            data,
        };

        if (addBrandParam) {
            config.params = { brand: addBrand(wcEnv), ...reqParams };
        }

        // Get the entry brand from the cookie or domain
        const entryBrand = getEntryBrand(wcEnv);
        if (entryBrand && addBrandParam) {
            config.params.entryBrand = entryBrand;
        }

        if ((!reqParams && jwtToken) || (reqParams && !reqParams.suppressJWT && jwtToken)) {
            config.headers = { Authorization: addJwtToken(jwtToken), ...config.headers };
        }

        return axios(config);
    } catch (ex) {
        mbpLogger.logError({
            method,
            url,
            params,
            data,
            wcEnv,
            jwtToken,
            headers,
            function: 'callAxios',
            appName: process.env.npm_package_name,
            module: 'wc-api',
            jsError: ex,
            message: 'callAxios FAILED',
        });
    }

    return null;
};

const getUrlFacade = (wcEnv, resourcePath) => `${(wcEnv.protocol || getProtocol())}://${wcEnv.host || getHost()}${wcEnv.prefix || getPrefix()}${wcEnv.rootUri || getRootUri()}${resourcePath}`;

/**
 * POST for facade layer
 * Note: brand will get added as part of request body
 * @param {*} wcEnv
 * @param String resourcePath
 * @param {*} jwtToken
 * @param {*} data
 */
const postFacade = (wcEnv, resourcePath, jwtToken, data, params = null, headers = null, addBrandParam = true) => callAxios('post', getUrlFacade(wcEnv, resourcePath), params, data, wcEnv, jwtToken, headers, addBrandParam);

const getFacade = (wcEnv, resourcePath, jwtToken, params = null, headers = null, addBrandParam = true) => callAxios('get', getUrlFacade(wcEnv, resourcePath), params, null, wcEnv, jwtToken, headers, addBrandParam);

const patchFacade = (wcEnv, resourcePath, jwtToken, data, params = null, headers = null, addBrandParam = true) => callAxios('patch', getUrlFacade(wcEnv, resourcePath), params, data, wcEnv, jwtToken, headers, addBrandParam);

const putFacade = (wcEnv, resourcePath, jwtToken, data, params = null, headers = null, addBrandParam = true) => callAxios('put', getUrlFacade(wcEnv, resourcePath), params, data, wcEnv, jwtToken, headers, addBrandParam);

const deleteFacade = (wcEnv, resourcePath, jwtToken, data, params = null, headers = null, addBrandParam = true) => callAxios('delete', getUrlFacade(wcEnv, resourcePath), params, data, wcEnv, jwtToken, headers, addBrandParam);

const restClient = {
    patchFacade,
    postFacade,
    getFacade,
    putFacade,
    deleteFacade,
};
export default restClient;
