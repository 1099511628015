/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { createSelector } from 'reselect';
import { getWalletEntries } from '../../../Global/Global-Selectors';
import { getFeatureFlags } from '../../../App/App-Selectors';

export const getPaypalPaymentDetails = (state) => state?.checkout?.payment?.paypal;
export const getIsPaypalHasBillingDetails = (state) => {
    const paypal = state?.checkout?.payment.paypal;
    if (paypal && paypal.isBillingAddressMissingMandatoryFields && paypal.payload.nonce) {
        return false;
    }
    return true;
};
export const getChangePaypalAccount = (state) => state?.checkout?.payment?.paypal?.changeAccount;
export const getSavedPaypalAccount = createSelector(
    [
        getWalletEntries,
    ],
    (walletCards) => {
        if (walletCards.length > 0) {
            return walletCards.filter((card) => card?.payType === 'PY')[0];
        }
        return {};
    },
);

export const getPaymentUsingPaypalWallet = createSelector(
    [
        getFeatureFlags,
        getChangePaypalAccount,
        getSavedPaypalAccount,
    ],
    (flags, changeAccount, savedPaypalAccount) => {
        if (flags['is-paypal-wallet-enabled'] && !changeAccount && savedPaypalAccount?.payType === 'PY') {
            return true;
        }
        return false;
    },
);

export const getPaypalTokenizedCard = (state) => {
    const savedPaypalAccount = getSavedPaypalAccount(state);
    if (savedPaypalAccount?.cardNumber) {
        return savedPaypalAccount.cardNumber;
    }
    return null;
};

export const getPayPalError = (state) => state?.common?.error?.paypal;
