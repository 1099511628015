/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import reducers from './App-Reducers';
import * as actions from './App-Actions';
import * as actionTypes from './App-ActionTypes';
import * as helpers from './App-Helpers';
// import * as loaders from './App-LoaderTasks';
import * as operations from './App-Operations';
import * as selectors from './App-Selectors';

// Ducks
import * as common from './ducks/Common';
import * as config from './ducks/Config';
import * as brand from './ducks/Brand';
import * as abTesting from './ducks/ABTesting';

const ducks = {
    common,
    config,
    brand,
    abTesting,
};

const watchers = [
    ...operations.watchers,
    ...config.operations.watchers,
    ...abTesting.operations.watchers,
];

export {
    operations,
    actionTypes,
    actions,
    // loaders,
    helpers,
    selectors,
    ducks,
    watchers,
};

export default reducers;
