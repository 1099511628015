/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const PAYMENT_UPDATE_FORM_VALUES = 'PAYMENT_UPDATE_FORM_VALUES';
export const PAYMENT_CLEAR_UPDATE_FIELDS = 'PAYMENT_CLEAR_UPDATE_FIELDS';
export const PAYMENT_PREFILL_FORM_FIELDS = 'PAYMENT_PREFILL_FORM_FIELDS';
export const PAYMENT_GET_FIELDS = 'PAYMENT_GET_FIELDS';
export const PAYMENT_UPDATE_FORM_STATUS = 'PAYMENT_UPDATE_FORM_STATUS';
export const PAYMENT_SET_PAYMENT_METHOD = 'mbp-checkout/SET/PAYMENT/METHOD';
export const PAYMENT_SET_BILLING_METHOD = 'mbp-checkout/SET/BILLING/METHOD';
