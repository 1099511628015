/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// export const GET_ADDRESS_BOOK = 'mbp-member/GET_ADDRESS_BOOK';
// export const UPDATE_ADDRESSBOOK_PAGE_NUMBER = 'mbp-member/UPDATE_ADDRESSBOOK_PAGE_NUMBER';
export const LOAD_ADDRESS_BOOK = 'mbp-member/LOAD_ADDRESS_BOOK';
export const PROCESS_ADDRESS_BOOK_COMPLETED = 'mbp-member/PROCESS_ADDRESS_BOOK_COMPLETED';
export const SAVE_ADDRESS_BOOK = 'mbp-member/SAVE_ADDRESS_BOOK';
export const CLEAR_ADDRESS_BOOK = 'mbp-member/CLEAR_ADDRESS_BOOK';
export const SAVED_ADDRESS_BOOK = 'mbp-member/SAVED_ADDRESS_BOOK';
export const ADD_ADDRESS_BOOK = 'mbp-member/ADD_ADDRESS_BOOK';
export const EDIT_ADDRESS_BOOK = 'mbp-member/EDIT_ADDRESS_BOOK';
export const REMOVE_ADDRESS_BOOK = 'mbp-member/REMOVE_ADDRESS_BOOK';

export const LOAD_BILLING_ADDRESS = 'mbp-member/LOAD_BILLING_ADDRESS';
export const SAVE_BILLING_ADDRESS = 'mbp-member/SAVE_BILLING_ADDRESS';
export const SAVED_BILLING_ADDRESS = 'mbp-member/SAVED_BILLING_ADDRESS';
export const SAVED_GUEST_ADDRESS_BOOK = 'mbp-member/SAVED_GUEST_ADDRESS_BOOK';
export const CLEAR_GUEST_ADDRESS_BOOK = 'mbp-member/CLEAR_GUEST_ADDRESS_BOOK';
