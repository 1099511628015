/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { select } from 'redux-saga/effects';
import { getRecipients } from '../../Checkout/ducks/Common/Common-Selectors';
import { getLastAddedOrderItemDetails } from '../AddToCart-Selectors';
import { getValidatedZipcode } from '../../App/App-Selectors';
import { getOrderId } from '../../Common/Common-Selectors';
import { getCartAttributeByNameAndReturnValue } from '../../../../app/helpers/page/pageHelpers';

const EVENT_TYPE_ADD_TO_CART = 'clickstream.add-cart';
const ADD_ON_PAGE = {
    title: 'add-ons',
    type: 'add-ons',
};
const WRAP_UP_PAGE = {
    title: 'wrap-up',
    type: 'wrap-up',
};

export const payloadAddToCartCS = ({
    location, addToCartResp, trackPayload, zipCode, csEventbaseCode,
}) => {
    //  Build clickStream payload for clickstream.add-cart even
    let order;
    if (addToCartResp.cartItems && addToCartResp.cartItems.length > 0) {
        order = { orderId: addToCartResp.cartId };
        order.orderItemId = addToCartResp.cartItems[0].cartItemId;
    } else if (addToCartResp?.cartId[0]) {
        order = { orderId: addToCartResp.cartId[0] };
        order.orderItemId = addToCartResp.cartItemId[0];
    }
    let products;
    let category;

    if (trackPayload?.products[0]) {
        const {
            name, price, partNumber, categoryId = '', categoryName = '', variant = '',
        } = trackPayload.products[0];
        products = [{
            baseCode: csEventbaseCode,
            partNumber: variant || partNumber,
            name,
            price,
        }];
        category = {
            id: categoryId,
            name: categoryName,
        };
    }

    const payload = {
        eventType: EVENT_TYPE_ADD_TO_CART,
        metaData: {
            order,
            products,
            category,
            zipCode,
        },
    };
    if ((location?.state?.categoryPath || '')?.includes?.('searchterm')) {
        const searchTerm = location.state.categoryPath.split('/');
        payload.metaData.searchTerm = searchTerm[searchTerm.length - 1];
        payload.metaData.eventSubType = 'search';
    }
    return payload;
};

function deriveLatestProducts(productBeans, products) {
    const filteredProducts = [];
    if (Array.isArray(productBeans)) {
        productBeans.forEach((wrapup) => {
            const { productCode = '' } = wrapup;
            const item = products.find((product) => product?.partNumber.includes(productCode));
            filteredProducts.push(item);
        });
    }
    return filteredProducts;
}

export function* wrapupPayloadAddToCartCS(wrapupData, orderId) {
    const recipients = yield select(getRecipients);
    const zipCode = yield select(getValidatedZipcode);

    const tempProducts = [];

    recipients.forEach((items) => (
        items.cartItems?.forEach((cartItem) => {
            tempProducts.push({
                baseCode: cartItem.product.parentProductCode,
                name: cartItem.product.parentProductName,
                price: cartItem.itemInfo.price,
                partNumber: cartItem?.product?.productCode,
                addon: false,
            });
        })
    ));

    const products = deriveLatestProducts(wrapupData?.wrapUpProductBeans, tempProducts);

    const payload = {
        eventType: EVENT_TYPE_ADD_TO_CART,
        page: WRAP_UP_PAGE,
        metaData: {
            products,
            order: { orderId },
            zipCode,
        },
    };

    return payload;
}

function buildAddOnsProductPayload(orderItem) {
    const addOnsKeys = Object.keys(orderItem?.adonList || {});

    const products = [];
    if (addOnsKeys?.length > 0) {
        addOnsKeys.forEach((itemId) => {
            const {
                parentSkuCode = '',
                skuName = '',
                skuCode = '',
                skuListPrice = 0,
                skuOfferPrice = 0,
            } = orderItem?.adonList[itemId];
            products.push({
                baseCode: parentSkuCode,
                name: skuName,
                partNumber: skuCode,
                price: skuListPrice || skuOfferPrice,
                addon: true,
            });
        });
    }
    return products;
}

export function* addOnPayloadAddToCartCS() {
    const lastItem = yield select(getLastAddedOrderItemDetails);
    const cartId = yield select(getOrderId);

    const products = buildAddOnsProductPayload(lastItem);

    const categoryId = getCartAttributeByNameAndReturnValue(lastItem?.attributes || [], 'categoryId');
    const categoryIdent = getCartAttributeByNameAndReturnValue(lastItem?.attributes || [], 'categoryIdent');

    const payload = {
        eventType: EVENT_TYPE_ADD_TO_CART,
        page: ADD_ON_PAGE,
        metaData: {
            order: {
                orderId: cartId || '',
                orderItemId: lastItem?.cartItemId || '',
            },
            products,
            category: {
                id: categoryId || '',
                name: categoryIdent || '',
            },
            zipCode: lastItem?.deliveryInfo?.zipCode || '',
        },
    };

    return payload;
}

function buildAddOnsAndWrapupProductPayload(recipients, wrapupData, addOnsData) {
    const wrapupProducts = [];
    let addOnsProducts = [];
    recipients?.forEach((items) => (
        items?.cartItems?.forEach((cartItem) => {
            const addOns = buildAddOnsProductPayload(cartItem);
            addOnsProducts = [...addOnsProducts, ...addOns];
            wrapupProducts.push({
                baseCode: cartItem.product.parentProductCode,
                name: cartItem.product.parentProductName,
                price: cartItem.itemInfo.price,
                partNumber: cartItem?.product?.productCode,
                addon: false,
            });
        })
    ));

    const wrapUps = deriveLatestProducts(wrapupData?.wrapUpProductBeans, wrapupProducts);
    const addOns = deriveLatestProducts(addOnsData?.addOnProductBeans, addOnsProducts);

    return [...wrapUps, ...addOns];
}

export function* addOnAndWrapupPayloadAddToCartCS(wrapupData, addOnsData, orderId) {
    const recipients = yield select(getRecipients);
    const zipCode = yield select(getValidatedZipcode);

    const products = buildAddOnsAndWrapupProductPayload(recipients, wrapupData, addOnsData);
    const payload = {
        eventType: EVENT_TYPE_ADD_TO_CART,
        page: ADD_ON_PAGE,
        metaData: {
            products,
            order: { orderId },
            zipCode,
        },
    };
    return payload;
}
