/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from './restClient';

const RESOURCE_ROOT_CARTSERVICE = '/checkout';
const RESOURCE_VERSION_CARTSERVICE = '/v2';
const RESOURCE_PAGE_CARTSERVICE = '/cart';

/**
 * getCart
 * url - /checkout/{apiversion}/cart/{cartId}?brand={brandId}
 *
 * @param {Object} args
 * @param {String} args.env
 * @param {String} args.jwtToken
 * @param {String} args.cartId
 */
const getCartOrderDetails = ({ env, jwtToken, cartId }) => {
    const envObj = env || {};

    envObj.rootUri = `${RESOURCE_ROOT_CARTSERVICE}${RESOURCE_VERSION_CARTSERVICE}`;

    let resourcePage =  `${RESOURCE_PAGE_CARTSERVICE}`;
    if (cartId) {
        resourcePage += `/${cartId}`;
    }

    return restClient.getRequest(envObj, resourcePage, jwtToken)
        .then((response) => response);
};

export default getCartOrderDetails;
