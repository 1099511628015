/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import triggerPopChatEvent from '../../../components/MultiBrandChatController/AigoChatWidgetWrapperV2/popOpenChat';

const PERSIST_PAGE_KEY = 'persistPageData';
const PERSIST_CHECKOUT_KEY = 'persistCheckoutData';
const SESSION_TIME_IDENTIFIER = 'pageDataPersistedAt';

// controls the delay to show the widget (in minute)
// const DISPLAY_WIDGET_AFTER = 30;

const persistType = {
    standard_collections: 'collection',
    standard_product: 'product',
    checkout: 'checkout',
};

// delete object from storage
export const removePersistPageDataFromStorage = () => {
    if (typeof window !== 'undefined') {
        localStorage.removeItem(PERSIST_PAGE_KEY);
    }
};

const getDataFromStorage = () => {
    if (typeof window !== 'undefined') {
        const persistedData = JSON.parse(localStorage.getItem(PERSIST_PAGE_KEY) || '{}');

        // delete object from storage if the content is expired
        if (persistedData.expire <= new Date().getTime()) {
            removePersistPageDataFromStorage();
            return null;
        }
        return persistedData;
    }
    return null;
};

const saveDataToStorage = (pageData, storageKey) => {
    if (typeof window !== 'undefined') {
        localStorage.setItem(storageKey, JSON.stringify(pageData));

        sessionStorage.setItem(SESSION_TIME_IDENTIFIER, new Date(Date.now()).getTime());
    }
};

const formatPersistData = (type, data) => {
    const existingData = getDataFromStorage() || {};

    // Delete the existing product (if any) if new collection is visted
    if (type === 'standard_collections' && existingData.product) {
        delete existingData.product;
    }
    if (type === 'standard_product' && existingData.product) {
        delete existingData.product;
    }

    // Add / Overwrite page data
    existingData[persistType[type]] = data;

    // Overwrite expire time
    existingData.expire = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).getTime();
    return existingData;
};

// Funtions to be used in application to set and get the storage

export const persistPage = (pageProps) => {
    const { page, product, countryName = '' } = pageProps;
    // TODO:
    // - Flowers is the only brand that returns pageData.countryName
    // - FLowers is the only brand with international specific vendors
    // - Other brands exclusively sell US and CA and don't return pageData.countryName
    // - Refactor so other brands return pageData.countryName
    if (countryName?.toLowerCase() === 'united states' || countryName?.toLowerCase() === '') {
        // Saving category name
        if (page?.contentType === 'standard_collections') {
            page.categoryName = pageProps.categoryData?.content?.entries?.[0]?.seo?.breadcrumb?.[1]?.title || '';
        }
        // Checks if the following type of page data exist and saves data to the storage
        if (page && page.path && page.code) {
            if (product && page?.contentType === 'standard_product') {
                page.name = product.name;
                page.seo = product.seo;
                page.partNumber = product.partNumber;
                page.image = product.image;
            }
            const pageData = formatPersistData(page.contentType,
                page);
            saveDataToStorage(pageData, PERSIST_PAGE_KEY);
            triggerPopChatEvent({ didSetPersistData: JSON.stringify(pageData) });
        }
    }
};

export const persistCheckout = (data) => {
    if (data?.productCode) {
        const pageData = formatPersistData(persistType.checkout, data);
        saveDataToStorage(pageData, PERSIST_CHECKOUT_KEY);
    }
};

export const getPagePersistData = () => {
    if (typeof window !== 'undefined') {
        // const timePersistedAt = sessionStorage.getItem(SESSION_TIME_IDENTIFIER) || '0';
        const persistedData = getDataFromStorage();

        if (persistedData) {
            return persistedData;
        }
        return null;
    }
    return null;
};

export default {};
