/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const cancelSubscriptionApi = async (wcEnv, jwtToken, payload, params, headers) => {
    const resourcePage = 'retention-subscription/passport/subscription/cancel-subscription';

    return restClient.postFacade(wcEnv, resourcePage, jwtToken, payload, params, headers, false)
        .then((response) => response.data);
};

export default cancelSubscriptionApi;
