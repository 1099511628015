/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const UPDATE_BILLING_FORM = 'UPDATE_BILLING_FORM';
export const BILLING_UPDATE_FORM_VALUES = 'BILLING_UPDATE_FORM_VALUES';
export const BILLING_CLEAR_UPDATE_FIELDS = 'BILLING_CLEAR_UPDATE_FIELDS';
export const BILLING_PREFILL_FORM_ADDRESS = 'BILLING_PREFILL_FORM_ADDRESS';
export const BILLING_ADDRESS_SAME_AS_DELIVERY_ADDRESS = 'BILLING_ADDRESS_SAME_AS_DELIVERY_ADDRESS';
export const BILLING_GET_ADDRESS = 'BILLING_GET_ADDRESS';
export const BILLING_UPDATE_FORM_STATUS = 'BILLING_UPDATE_FORM_STATUS';
export const BILLING_UPDATE_FORM_AUTOFILLED = 'BILLING_UPDATE_FORM_AUTOFILLED';
export const CHECK_BILLING_SAME_RECIEPENT_FORM = 'CHECK_BILLING_SAME_RECIEPENT_FORM';
export const BILLING_GET_STATE_CITY_DATA = 'mbp-checkout/BILLING_GET_STATE_CITY_DATA';
export const UPDATE_SAVE_USER_DEFAULT_BILLING = 'mbp-checkout/UPDATE_SAVE_USER_DEFAULT_BILLING';
