/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, call, put, select, takeEvery,
} from 'redux-saga/effects';
import qs from 'qs';
import mbpLogger from 'mbp-logger';
import memberDucks from '../../../Member/ducks';
import * as actions from './AddressSearchQAS-Actions';
import * as recipientFormActions from '../../../Recipient/ducks/RecipientForm/RecipientForm-Actions';
import * as showLoadingMessageActions from '../ShowLoadingMessage/ShowLoadingMessage-Actions';
import * as commonSelector from '../../Common-Selectors';
import * as errorActions from '../Error/Error-Actions';
import QasClient from '../../../../../apis/qas-address-search-apis/qas-address-search-apis';
import { getBrand, getFeatureFlags } from '../../../App/ducks/Config/Config-Selectors';
import { getCountryCodeFromTwoToThreeDigit } from '../../../Checkout/helpers/countryCode';

const DEFAULT_QAS_SEARCH_LIMIT = 7;
const countryISOAlfa3Code = {
    US: 'USA',
    CA: 'CAN',
};

const countryISOAlfa2Code = {
    USA: 'US',
    CAN: 'CA',
};

const {
    addressBook: {
        addressBookOperations,
        addressBookActions,
    },
} = memberDucks;

const normailizeArrayToObject = (arr) => {
    const normalize = {};
    arr.forEach((item) => {
        normalize[[Object.keys(item)]] = item[[Object.keys(item)]];
    });
    return normalize;
};

function* queryQas(params) {
    let response = {};
    try {
        const flagState = yield select(getFeatureFlags);
        const qasAddressSearchTimeoutValue = flagState['qas-address-search-timeout-value'];
        const resQueryQas = yield call(QasClient.qasGet, {}, 'search', params, qasAddressSearchTimeoutValue * 1000);
        response = {
            responseType: 'success',
            result: resQueryQas,
        };
    } catch (ex) {
        response = {
            result: ex,
            responseType: 'error',
        };
    }
    return response;
}

function* formatAddressQas(params) {
    let response = {};
    try {
        const flagState = yield select(getFeatureFlags);
        const qasAddressSearchTimeoutValue = flagState['qas-address-search-timeout-value'] || 5;
        const resQueryQas = yield call(QasClient.qasGet, {}, 'format', params, qasAddressSearchTimeoutValue * 1000);
        response = {
            responseType: 'success',
            result: resQueryQas,
        };
    } catch (ex) {
        response = {
            result: ex,
            responseType: 'error',
        };
    }
    return response;
}

function* workerQueryQasAddressSearch(args) {
    let response = {};
    const { searchText, countryCode } = args.data;
    const isoCountryCode = countryCode?.length !== 3 ? countryISOAlfa3Code[countryCode] : countryCode;
    const brandProperties = yield select(getBrand);
    const queryLimit = brandProperties['qas-address-search-limit'] || DEFAULT_QAS_SEARCH_LIMIT;
    const params = {
        query: searchText,
        country: isoCountryCode,
        take: queryLimit,
    };
    const resQueryQas = yield call(queryQas, params);
    const { result } = resQueryQas;
    response = resQueryQas;

    if (resQueryQas.responseType === 'success'
        && result
        && result.data) {
        yield put(actions.loadQasAddressSearch(result.data));
    } else if (resQueryQas.responseType === 'error') {
        yield put(actions.clearQasAddressSearch());
    }

    return response;
}

function* watcherQueryQasAddressSearch() {
    yield takeEvery(actions.queryQasAddressSearch().type, workerQueryQasAddressSearch);
}

function* workerGetFormatQasAddress(args) {
    let response = {};
    const { address } = args.data;
    if (address && address.format) {
        const { format } = address;
        const formatParams = qs.parse(format.substring(format.indexOf('?') + 1));
        const resFormatQas = yield call(formatAddressQas, formatParams);
        const { result } = resFormatQas;
        response = resFormatQas;
        if (resFormatQas.responseType === 'success'
            && result
            && result.data) {
            yield put(actions.loadFomattedQasAddress(result.data));
        } else if (resFormatQas.responseType === 'error') {
            yield put(actions.clearFomattedQasAddress());
        }
    }
    return response;
}

function* watcherGetFormatQasAddress() {
    yield takeEvery(actions.getFormatQasAddress().type, workerGetFormatQasAddress);
}

function alignQasFormatResponse(formattedAddress) {
    const { components, address } = formattedAddress;
    const nComponents = normailizeArrayToObject(components);
    const nAddress = normailizeArrayToObject(address);

    let address1 = nAddress.addressLine1;
    let address2 = `${nAddress.addressLine2} ${nAddress.addressLine3}`;
    if (['APO', 'FPO'].includes(nComponents.locality1)) {
        address1 = nComponents.street1;
        address2 = nComponents.deliveryService4;
    }
    const returnAddress = {
        addressLine1: address1,
        addressLine2: address2,
        city: nAddress.locality,
        state: nAddress.province,
        country: countryISOAlfa2Code[nComponents.countryISO1],
        zipcode: nAddress.postalCode,
    };
    return returnAddress;
}

function trimAndLowerCase(value) {
    return (value) ? value.trim().toLocaleLowerCase() : '';
}

function trimStr(value) {
    return (value) ? value.trim() : '';
}

const removeSpaceAndComma = (str) => str.replace(/ |,/gi, '');

const removeComma = (str) => str.replace(/,/gi, '');

const formAddressFromMatchedStr = (address, arrUserInputedAddress) => {
    let addressFormation = '';
    const subStringCollection = [];
    for (let t = address.matched || [], r = address.suggestion, n = t.length - 1; n >= 0; n -= 1) {
        const extractedSubString = r.substring(t[n][0], t[n][1]);
        const extract = extractedSubString.split(' ');
        for (let i = 0; i < extract.length; i += 1) {
            subStringCollection.push(extract[i]);
        }
        addressFormation = `${addressFormation}${extractedSubString}`;
    }
    const suggestionText = removeComma(address.suggestion).split(' ');
    const addressFormationStr = removeSpaceAndComma(addressFormation);
    const addresslineUserInputed = removeSpaceAndComma(address.suggestion);
    if (addressFormationStr === addresslineUserInputed) {
        return true;
    }
    const notMatched = [];
    for (let i = 0; i < suggestionText.length; i += 1) {
        if (suggestionText[i] !== subStringCollection[i]) {
            notMatched.push(suggestionText[i]);
        }
    }
    const userInputedText = arrUserInputedAddress.toLocaleLowerCase().split(' ');
    if (notMatched.length > 0) {
        // eslint-disable-next-line arrow-body-style
        const notMatchedCheck = notMatched.map((notMatchedString) => {
            return userInputedText.includes(notMatchedString.toLocaleLowerCase()) ? 'Y' : 'N';
        });
        if (notMatchedCheck.indexOf('N') >= 0) {
            return false;
        }
        return true;
    }
    return false;
};

/**
 * @description this is the function that will check the QAS address, format and get the config file,  which will be use to make the dynamic regex that will be used to check the suggestion address from the QAS Api
 * @param {*} addressMatchedArray
 * @param {*} lineAddress
 * @returns sendUpdatedObject: Object
 */
function checkAddressMatchingPercentage(addressMatchedArray, lineAddress) {
    const matched = formAddressFromMatchedStr(addressMatchedArray, lineAddress);
    return matched;
}
/**
 * @description this will check if the children array is an entire subset of the parent array
 * @param {Array} parent
 * @param {Array} children
 * @returns {boolean}
 */
function checkSubset(parent, children) {
    let matchedAddressLength = 0;
    children.forEach((indexObject) => {
        if (parent.includes(indexObject)) {
            matchedAddressLength += 1;
        }
    });
    const matchedAddressOnLength = ((children.length - 1) === matchedAddressLength);
    const matchedAddressPercentage = (parseInt(((matchedAddressLength / children.length) * 100), 10) > 60);
    return (matchedAddressOnLength || matchedAddressPercentage);
}
/**
 * @description this will split the string on the base of the separater that has been provided
 * @param {*} arraySet
 * @param {string} [separator=' ']
 * @returns {boolean}
 */
function convertToArray(arraySet, separator = ' ') {
    return trimAndLowerCase(arraySet).split(separator);
}

/**
 * @description it will verify the address that will be if(QASAddress is equal to the LineAddress) || if(QASAddress is equal to the (Updated address {only if possible on Line Address}))
 * @param {String} parentAddress
 * @param {String} childAddress
 * @param {string} [updatedAddress='']
 * @returns {Boolean}
 */
function verfiytheAddress(parentAddress, childAddress, updatedAddress = '') {
    const parentAddressArray = convertToArray(parentAddress);
    const childAddressArray = convertToArray(childAddress);
    const checkAddress = checkSubset(parentAddressArray, childAddressArray);
    let checkUpdateAddress = false;
    if (!checkAddress && updatedAddress !== '') {
        const updatedAddressArray = convertToArray(updatedAddress);
        checkUpdateAddress = checkSubset(parentAddressArray, updatedAddressArray);
    }
    return (checkAddress || checkUpdateAddress);
}

function compareAddressWithQASFormat(qasFormatAddress, recipientFormAddress) {
    const resultSet = {
        allowSave: false,
        recipientFormAddress,
    };
    let checkZipcode = false;
    const checkCity = (trimAndLowerCase(qasFormatAddress.city) === trimAndLowerCase(recipientFormAddress.city));
    const checkState = trimAndLowerCase(qasFormatAddress.state) === trimAndLowerCase(recipientFormAddress.state);

    const countryQAS = qasFormatAddress.country?.length === 2 ? getCountryCodeFromTwoToThreeDigit(qasFormatAddress.country) : qasFormatAddress.country;
    const checkCountry = trimAndLowerCase(countryQAS) === trimAndLowerCase(recipientFormAddress.country);

    if (/(-)/g.test(String(qasFormatAddress.zipcode))) {
        const qasFormatAddressZipCode = convertToArray(qasFormatAddress.zipcode, '-');
        if (checkSubset(qasFormatAddressZipCode, [recipientFormAddress.zipCode])) {
            resultSet.recipientFormAddress.zipCode = qasFormatAddress.zipcode;
            checkZipcode = true;
        }
    }
    const checkBase = (checkCity && checkState && checkCountry && checkZipcode);
    if (!checkBase) {
        return resultSet;
    }
    if (trimAndLowerCase(qasFormatAddress.addressLine1) === trimAndLowerCase(recipientFormAddress.address1)
    && trimAndLowerCase(qasFormatAddress.addressLine2) === trimAndLowerCase(recipientFormAddress.address2)) {
        resultSet.allowSave = true;
        return resultSet;
    } if (verfiytheAddress(qasFormatAddress.addressLine1, recipientFormAddress.address1)) {
        resultSet.allowSave = true;
        resultSet.recipientFormAddress.address1 = qasFormatAddress.addressLine1;
    }
    return resultSet;
}

function compareSuggestion(addressToCompare, addressList) {
    const {
        address1, address2, city, state, zipCode,
    } = addressToCompare;
    let lineAddress = `${trimAndLowerCase(address1)}`;
    if (trimAndLowerCase(address2)) lineAddress = `${lineAddress} ${trimAndLowerCase(address2)}`;
    if (trimAndLowerCase(city)) lineAddress = `${lineAddress} ${trimAndLowerCase(city)}`;
    if (trimAndLowerCase(state)) lineAddress = `${lineAddress} ${trimAndLowerCase(state)}`;
    if (trimAndLowerCase(zipCode)) {
        let zipCodeTransform = trimAndLowerCase(zipCode);
        if (/(-)/g.test(String(zipCodeTransform))) {
            zipCodeTransform = convertToArray(zipCodeTransform, '-')[0] || zipCodeTransform;
        }
        lineAddress = `${lineAddress} ${trimAndLowerCase(zipCodeTransform)}`;
    }
    const filteredAddress = addressList.filter((address) => {
        const isMatched = checkAddressMatchingPercentage(address, lineAddress);
        return isMatched;
    });
    return filteredAddress;
}

function queryAddressFormation(formData) {
    let searchText = '';
    if (trimStr(formData.address1) && !trimStr(formData.address2)) {
        searchText = `${trimStr(formData.address1)},`;
    } else {
        searchText = `${trimStr(formData.address1)}`;
    }
    if (trimStr(formData.address2)) searchText = `${searchText} ${trimStr(formData.address2)},`;
    if (trimStr(formData.city)) searchText = `${searchText} ${trimStr(formData.city)}`;
    if (trimStr(formData.state)) searchText = `${searchText} ${trimStr(formData.state)}`;
    if (trimStr(formData.zipCode)) searchText = `${searchText} ${trimStr(formData.zipCode.split('-')[0])}`;
    return searchText;
}

/**
 * QAS Address Verification verify recipient address
 * returns {
 *      allowSave: true | false,
 *      addressVerifiedStatus: {
 *          dpvIndicator: 'Y' || 'N' || DPV value returned from QASAddress
 *          isAddressVerified: U - Unverified || V - Verified || O - Overwrite
 *      }
 * }
 */
function* validateAddressQAS(action) {
    let allowSave = false;
    const addressVerifiedStatus =  {
        dpvIndicator: 'N',
        isAddressVerified: 'U',
    };
    yield put(errorActions.clearErrors({
        errType: 'recipientForm',
        field: 'error',
    }));
    try {
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        // Reset QAS State
        yield put(actions.resetQasAddressState());

        const recipientFormDetails = action.data.recipient;
        const searchText = yield call(queryAddressFormation, recipientFormDetails);
        const countryCode = recipientFormDetails.country;
        const searchArgs = { data: { searchText, countryCode } };
        const responseQueryQasAddressSearch = yield call(workerQueryQasAddressSearch, searchArgs);

        if (responseQueryQasAddressSearch.responseType === 'success') {
            const searchSuggestion = yield select(commonSelector.getQasSearchSuggestion);

            if (searchSuggestion && searchSuggestion.results) {
                if (searchSuggestion.count === 0 || searchSuggestion.totalMatches === 0) {
                    allowSave = false;
                    yield put(actions.flagOnShowValidatedAddressSuggestion());
                    yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
                } else if (searchSuggestion.count > 0 && searchSuggestion.totalMatches > 0) {
                    const matchedAddress = yield call(compareSuggestion, recipientFormDetails, searchSuggestion.results);
                    if (matchedAddress.length === 1) {
                        const [addressOne] =  matchedAddress;
                        const formatParams = { data: { address: addressOne } };
                        const responseFormatQasAddress = yield call(workerGetFormatQasAddress, formatParams);
                        if (responseFormatQasAddress.responseType === 'success') {
                            const formatAddress = yield select(commonSelector.getQasSearchFormattedAddress);
                            const qasFormatAddress = yield call(alignQasFormatResponse, formatAddress);
                            const compareAddressFormatResult = yield call(compareAddressWithQASFormat, qasFormatAddress, recipientFormDetails);
                            allowSave = compareAddressFormatResult.allowSave || false;
                            if (allowSave) {
                                const { metadata: { dpv } } = formatAddress;
                                const dpvIndicator = (dpv && dpv.dpvIndicator) ? dpv.dpvIndicator : 'N';
                                addressVerifiedStatus.dpvIndicator = dpvIndicator;
                                addressVerifiedStatus.isAddressVerified = 'V';
                                yield put(actions.flagOffShowValidatedAddressSuggestion());
                            } else {
                                yield put(actions.flagOnShowValidatedAddressSuggestion());
                                yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
                            }
                        } else {
                            // failed to format
                            // Since QAS api failed - we allow recipient address to be submitted without verification
                            // dpvIndicator: 'N', isAddressVerified: 'U' - set to the address
                            allowSave = true;

                            mbpLogger.logError({
                                appName: process.env.npm_package_name,
                                module: 'mbp-pwa-ui',
                                function: 'validateAddressQAS',
                                message: 'Failed to query qas address format',
                                error: responseFormatQasAddress.result,
                            });
                        }
                    } else {
                        allowSave = false;
                        yield put(actions.flagOnShowValidatedAddressSuggestion());
                        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
                    }
                }
            }
        } else {
            // failed to query
            // Since QAS api failed - we allow recipient address to be submitted without verification
            // dpvIndicator: 'N', isAddressVerified: 'U' - set to the address
            allowSave = true;

            mbpLogger.logError({
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                function: 'validateAddressQAS',
                message: 'Failed to query qas address search',
                error: responseQueryQasAddressSearch.result,
            });
        }
    } catch (ex) {
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'validateAddressQAS',
            jsError: ex,
            message: 'validateAddressQAS Failed',
        });
    }
    return {
        allowSave,
        addressVerifiedStatus,
    };
}

function* workerKeepAddressQasVerification(args) {
    const { saveHandler } = args;
    yield put(actions.resetQasAddressState());
    yield call(saveHandler, 'keep', 'N', 'O');
}

function* watcherKeepAddressQasVerification() {
    yield takeEvery(actions.keepAddressQasVerification().type, workerKeepAddressQasVerification);
}

function* workerEditAddressQasVerification() {
    yield put(actions.resetQasAddressState());
    yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
}

function* watcherEditAddressQasVerification() {
    yield takeEvery(actions.editAddressQasVerification().type, workerEditAddressQasVerification);
}

function* workerSelectRecommandedAddressQasVerification(args) {
    const { address, saveHandler } = args;
    try {
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        yield put(actions.resetQasAddressState());
        yield call(workerGetFormatQasAddress, { data: { address } });
        const formatAddress = yield select(commonSelector.getQasSearchFormattedAddress);
        const { metadata: { dpv } } = formatAddress;
        const dpvIndicator = (dpv && dpv.dpvIndicator) ? dpv.dpvIndicator : 'N';
        const qasFormatAddress = yield call(alignQasFormatResponse, formatAddress);
        // Update Recipient Address Form Store
        if (qasFormatAddress && qasFormatAddress.addressLine1) {
            const countryQAS = qasFormatAddress.country?.length === 2 ? getCountryCodeFromTwoToThreeDigit(qasFormatAddress.country) : qasFormatAddress.country;

            const recipientFormData = yield select(commonSelector.getRecipientForm);
            const updateRecipient = { ...recipientFormData };
            updateRecipient.address1 = qasFormatAddress.addressLine1;
            updateRecipient.address2 = qasFormatAddress.addressLine2;
            updateRecipient.city = qasFormatAddress.city;
            updateRecipient.country = countryQAS;
            updateRecipient.state = qasFormatAddress.state;
            updateRecipient.zipCode = qasFormatAddress.zipcode;
            yield put(recipientFormActions.updateFormAddressFromPreFill(updateRecipient));
            yield call(saveHandler, 'qasAddressSelection', dpvIndicator, 'V', qasFormatAddress);
        }
    } catch (ex) {
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'workerSelectRecommandedAddressQasVerification',
            jsError: ex,
            message: 'workerSelectRecommandedAddressQasVerification Failed',
        });
    }
}

function* watcherSelectRecommandedAddressQasVerification() {
    yield takeEvery(actions.selectRecommandedAddressQasVerification().type, workerSelectRecommandedAddressQasVerification);
}

/* PWA Desktop Code Start */
function* proceedSaveBookAddresPwaDesktop(action) {
    const proceedToSave = yield call(validateAddressQAS, action);
    const { allowSave } = proceedToSave;
    if (allowSave) {
        const formFieldValues = action.data.recipient;

        const dataInfo = {
            data: {
                addressBookEntry: {
                    addressEntry: {
                        AddressBookEntryId: formFieldValues.entryId,
                        FirstName: formFieldValues.firstName,
                        LastName: formFieldValues.lastName,
                        EmployerName: formFieldValues.organizationName || '',
                        NickName: formFieldValues.nickName || formFieldValues.firstName,
                        RelationShip: '0',
                        PhoneNumber: formFieldValues.phone,
                    },
                    address: {
                        AddressType: formFieldValues.locationType,
                        AddressLineOne: formFieldValues.address1,
                        AddressLineTwo: formFieldValues.address2,
                        City: formFieldValues.city,
                        StateProvince: formFieldValues.state,
                        PostalCode: formFieldValues.zipCode,
                        Country: formFieldValues.country,
                        IsBillingAddress: false,
                    },
                },
            },
        };

        if (formFieldValues.entryId) {
            yield call(addressBookOperations.workers.workerEditAddressBook, dataInfo);
        } else {
            yield call(addressBookOperations.workers.workerAddAddressBook, dataInfo);
        }

        yield call(addressBookOperations.workers.workerLoadAddressBook, dataInfo);

        yield put(addressBookActions.proccessAddressBookCompleted());
    }
}

function* watcherSaveBookAddressPWADesktop() {
    yield takeEvery(actions.proceedSaveBookAddressPwaDesktop().type, proceedSaveBookAddresPwaDesktop);
}

const workers = {
    validateAddressQAS,
};

const watchers = [
    fork(watcherQueryQasAddressSearch),
    fork(watcherGetFormatQasAddress),
    fork(watcherEditAddressQasVerification),
    fork(watcherKeepAddressQasVerification),
    fork(watcherSelectRecommandedAddressQasVerification),
    fork(watcherSaveBookAddressPWADesktop),
];

export { watchers, workers };

export default {};
