/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable import/prefer-default-export */

const brandsByBrandId = {
    TPF: 'thepopcornfactory',
    CCO: 'cheryls',
    '18F': 'flowers',
    '18B': 'baskets',
    FBQ: 'fruitbouquets',
    HD: 'harryanddavid',
    WF: 'wolfermans',
    SY: 'stockyards',
    SCH: 'simplychocolate',
    CEL: 'celebrations',
    NAPCO: 'napcoimports',
    GOODSEY: 'goodsey',
    VC: 'vitalchoice',
    FL: 'florists',
};

const brandIds = {
    HD: 'HND',
};

export const getBrandByBrandId = (currentBrandId) => {
    let curBrand = '';

    if (currentBrandId) {
        Object.keys(brandsByBrandId).forEach((brandId) => {
            if (brandId === currentBrandId) {
                curBrand = brandsByBrandId[brandId];
            }
        });
    }
    return curBrand || 'flowers';
};

export const getBrandCode = (currentBrandId) => (Object.keys(brandIds).includes(currentBrandId) ? brandIds[currentBrandId] : (currentBrandId || '18F'));
