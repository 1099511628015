/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const SUBSCRIBE_EMAIL = 'mbp-checkout/SUBSCRIBE_EMAIL';
export const UNSUBSCRIBE_EMAIL = 'mbp-checkout/UNSUBSCRIBE_EMAIL';

export const SUBSCRIBE_TEXTMSG = 'mbp-checkout/SUBSCRIBE_TEXTMSG';
export const UNSUBSCRIBE_TEXTMSG = 'mbp-checkout/UNSUBSCRIBE_TEXTMSG';

export const SUBSCRIPTION_OPT_IN = 'mbp-checkout/SUBSCRIPTION_OPT_IN';
export const SET_SUBSCRIPTION_MESSAGE = 'mbp-checkout/SET_SUBSCRIPTION_MESSAGE';

export const SUBSCRIBE_ORDERSTATUS_OPT_IN = 'mbp-checkout/SUBSCRIBE_ORDERSTATUS_OPT_IN';
export const UNSUBSCRIBE_ORDERSTATUS_OPT_IN = 'mbp-checkout/UNSUBSCRIBE_ORDERSTATUS_OPT_IN';
