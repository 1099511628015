/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as addressBookActionTypes from './AddressBook-ActionTypes';
import * as addressBookActions from './AddressBook-Actions';
import addressBookOperations from './AddressBook-Operations';
import addressBookReducers from './AddressBook-Reducers';

const addressBookWatcherSagas = [
    ...addressBookOperations.watchers,
];

export {
    addressBookActionTypes,
    addressBookActions,
    addressBookOperations,
    addressBookWatcherSagas,
};

export default addressBookReducers;
