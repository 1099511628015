/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import Reducers from './Reducers';
import initialState from '../initialState';

const accountReducers = (state = initialState, action) => Reducers.successResponse(state, action);

export default accountReducers;
