/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import {
    any, func, number, string,
} from 'prop-types';
import Media from 'react-media';
import { withRouter } from 'react-router';

import CUSTOM_BREAKPOINTS from '../customBreakpoints';
import { getSSRDeviceType } from '../../../state/ducks/App/App-Selectors';

/*
    Determination of device type begins with userAgent on incoming request to determine ssrDeviceType (mapped to viewport on queries).
        - we can adjust that logic to return 'default' when UA is unknown or not handled specifically
        - when DesktopMobile is passed 'default' as ssrDeviceType we can use device determination for edge cases
        - failing all of that, we want as many responsive components as possible to serve a functionion UX
*/

// DesktopMobile - Determine if client is desktop or mobile,
//                 then invoke given desktop function if desktop
//                 or given mobile function if mobile.
//
// Props (all are optional):
//     desktop - function to invoke if client is desktop (omit if none)
//     mobile - function to invoke if client is mobile (omit if none)
//     param - parameter passed to desktop/mobile function invoked
//     breakpoint - Screen width (in logical pixels) of boundary between mobile and desktop.  Default is tabLandscape (currently 1024).
//                  If zero, determine mobile/desktop solely based on client hover capability.
//     ignoreHover - If specified, determine mobile/desktop solely based on width.  Default is false.
//
//  TL;DR: Unless props say otherwise, assume desktop if hover supported and screen width is breakpoint or wider.  Mobile otherwise.
//
// Examples:
//   <DesktopMobile desktop={bigIceCreamScreen} mobile={mobileIceCreamScreen}  param={{flavor: 'strawberry', form: 'cone'}} />
//   Only do something if desktop:         <DesktopMobile desktop={myDesktopFunc} />
//   Same, but only look at screen width:  <DesktopMobile desktop={myDesktopFunc} ignoreHover />
//

const DesktopMobile = ({
    desktop, mobile, param, breakpoint, ssrDeviceType,
}) => {
    // Logic:  If device returns true for no-hover, then we treat it
    //         as a mobile device, regardless of screen width.
    //         This way our desktop-specific hover things (like menus) won't fail.
    //
    //         Currently, all mobile and desktop browsers support media hover query,
    //         except Internet Explorer (which always returns false for any hover query,
    //         which is OK because IE is never mobile).
    //
    //         If hover supported, we'll use mobile config if width less than breakpoint,
    //         even though it's likely a laptop screen or small desktop window
    //         so customer has a decent usable interface.

    // if (breakpoint === 0) {
    //     if (ignoreHover) {
    //         // weird case - shouldn't happen - but if so, do our best.  Prefer mobile because works everywhere.
    //         return mobile ? <>{mobile(param)}</> : <>{desktop && desktop(param)}</>;
    //     }

    //     return (
    //         <Media query="(hover: none)">
    //             {(matches) => (matches ? <>{mobile && mobile(param)}</> : <>{desktop && desktop(param)}</>)}
    //         </Media>
    //     );
    // }

    const breakpointExpr = `(max-width: ${breakpoint || CUSTOM_BREAKPOINTS.tabLandscape}px)`;

    // if (ignoreHover) {
    //     return (
    //         <Media query={breakpointExpr}>
    //             {(matches) => (matches ? <>{mobile && mobile(param)}</> : <>{desktop && desktop(param)}</>)}
    //         </Media>
    //     );
    // }

    const deviceTypeDesktop = (ssrDeviceType === 'desktop');

    return (
        <Media
            query={breakpointExpr}
            defaultMatches={(!deviceTypeDesktop)}
        >
            {(matches) => (matches ? <>{mobile && mobile(param)}</> : <>{desktop && desktop(param)}</>)}
        </Media>
    );
};

DesktopMobile.propTypes = {
    desktop: func,
    mobile: func,
    param: any,
    breakpoint: number,
    // ignoreHover: bool,
    ssrDeviceType: string,
};

DesktopMobile.defaultProps = {
    desktop: null,
    mobile: null,
    param: null,
    breakpoint: CUSTOM_BREAKPOINTS.tabLandscape,
    // ignoreHover: false,
    ssrDeviceType: '',
};

const mapStateToProps = (state) => ({
    ssrDeviceType: getSSRDeviceType(state),
});

export default withRouter(
    connect(
        mapStateToProps,
        null,
    )(DesktopMobile),
);
