/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import Cookies from 'universal-cookie';
import qs from 'qs';
import mbpLogger from 'mbp-logger';

import attributionContants from './attributionConstants';
import { EnterpriseIdPayloadBody, EnterpriseIdNewPayloadBodyType } from '../../../apis/enterpriseid-apis/createEnterpriseIdRestCall';
import ENTERPRISE_EVENTS from '../EnterpriseID';
import reportWebchatConversion from '../../../apis/webchat-apis/v2/reportConversion';

const cookies = new Cookies();
const COOKIE_NAME_BANNER = 'banner';
const SESSION_NAME_BANNER = 'banner_session';
const COOKIE_NAME_ENTRY_BRAND = 'entryBrand';
const LOCAL_STORAGE_ENTERPRISE_ID = 'enterpriseId';
const LOCAL_STORAGE_ATTRIBUTION_DATA = 'attributionData';

export const getBannerCookieSession = () => {
    if (typeof sessionStorage !== 'undefined') {
        return sessionStorage.getItem(SESSION_NAME_BANNER);
    }
    return null;
};

export const checkForSearchAttribution = ({
    featureFlags,
    approvedReferrers,
    overrideReferrer,   // for testing.  Omit (or null) for normal use.
}) => {
    try {
        const docReferrer = overrideReferrer || ((typeof document !== 'undefined') && !getBannerCookieSession() ? document.referrer : null);

        mbpLogger.logInfo({
            function: 'checkForSearchAttribution',
            attributionNewSearchEnabled: featureFlags && featureFlags['is-attribution-new-search-check-enabled'],
            attributionNaturalSearchRefs: featureFlags && featureFlags['attribution-natural-search-refs'],
            approvedReferrers,
            overrideReferrer,
            docReferrer,
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: 'checkForSearchAttribution | START',
        });

        if (featureFlags) {
            if (featureFlags['is-attribution-new-search-check-enabled']) {
                if (docReferrer) {
                    if (approvedReferrers && approvedReferrers !== '') {
                        const associatedBannerCodeReferrerEntries = approvedReferrers.split('|'); // [".google.=seogoogle", ".yahoo.=seoyahoo", ".bing.=seobing", ".ask.=seoask", ".comcast.=seocomcast", ".aol.=seoaol"]
                        const foundAssociatedBannerCodeReferrerEntry = associatedBannerCodeReferrerEntries.find((code) => { // .google.=seogoogle
                            const bCode = code.split('='); // [".google.", "seogoogle"]
                            const bannerRef = bCode[0]; // .google.

                            return (docReferrer.indexOf(bannerRef) !== -1); // returns true if a referrer matches entry key
                        });

                        if (foundAssociatedBannerCodeReferrerEntry) {
                            const bannerCode = foundAssociatedBannerCodeReferrerEntry.split('=')[1]; // seogoogle
                            mbpLogger.logInfo({
                                foundAssociatedBannerCodeReferrerEntry,
                                bannerCode,
                                appName: process.env.npm_package_name,
                                module: 'mbp-pwa-ui',
                                message: `checkForSearchAttribution | assoc banner code referrer found | ${bannerCode}`,
                            });

                            if (bannerCode) {
                                return bannerCode;
                            }
                        }
                    }
                }
            } else {
                const loc = (typeof window !== 'undefined') ? window.location.href : null;
                if (loc && docReferrer) {
                    const ref = featureFlags['attribution-natural-search-refs'] || '.google.=seogoogle|.yahoo.=seoyahoo|.bing.=seobing|.ask.=seoask|.comcast.=seocomcast|.aol.=seoaol';
                    if (ref) {
                        const bannerCodes = ref.split('|'); // [".google.=seogoogle", ".yahoo.=seoyahoo", ".bing.=seobing", ".ask.=seoask", ".comcast.=seocomcast", ".aol.=seoaol"]

                        const bannerCode = bannerCodes.find((code) => { // .google.=seogoogle
                            const bCode = code.split('='); // [".google.", "seogoogle"]
                            const bannerRef = bCode[0]; // .google.

                            return (docReferrer && docReferrer.indexOf(bannerRef) !== -1); // returns true if a referrer exists
                        });

                        if (bannerCode) { // we've found a valid referrer
                            const seoBannerCode = bannerCode.split('=')[1];
                            mbpLogger.logInfo({
                                bannerCode: seoBannerCode,
                                appName: process.env.npm_package_name,
                                module: 'mbp-pwa-ui',
                                message: `checkForSearchAttribution | returning natural referrer | ${seoBannerCode}`,
                            });
                            return seoBannerCode; // seogoogle - this will be set as default cookie for referrer
                        }
                    }
                }
            }
        }
    } catch (e) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            jsError: e,
            message: 'checkForSearchAttribution FAILED',
        });
    }

    mbpLogger.logInfo({
        function: 'checkForSearchAttribution',
        appName: process.env.npm_package_name,
        module: 'mbp-pwa-ui',
        message: 'checkForSearchAttribution | false',
    });

    return false;
};

// Generate banner cookie value for Natural Search referrers
// Step 1 - check if document referrer exists
// Step 2 - check is referrer like .google exists within said string
// Step 3 - if referrer exists return referrer to set as banner cookie
// const generateNaturalSearchBannerCode = (approvedReferrers) => {
//     const docReferrer = (typeof document !== 'undefined') ? document.referrer : null;
//     // const docReferrer = 'https://www.google.com'; // for testing

//     const bannerCodes = approvedReferrers.split('|');
//     const bannerCode = bannerCodes.find((code) => { // .google.=seogoogle
//         const bCode = code.split('='); // [".google.", "seogoogle"]
//         const bannerRef = bCode[0]; // .google.

//         return (docReferrer.indexOf(bannerRef) !== -1) ? bannerRef : null;
//     }).split('=')[1]; // .google.=seogoogle -> seogoogle

//     mbpLogger.logInfo({
//         function: 'generateNaturalSearchBannerCode',
//         appName: process.env.npm_package_name,
//         module: 'mbp-pwa-ui',
//         bannerCode,
//         message: 'generateNaturalSearchBannerCode | ',
//     });

//     return bannerCode;
// };

const generateBannerDataObject = (bannerData) => {
    const {
        code, eyebrowImage, lsId, promoCode,
    } = bannerData;

    let bannerCookieValue;
    if (code) {
        bannerCookieValue = code;
        if (typeof code === 'object') {
            bannerCookieValue = code.code;
        }
    }
    return {
        c: bannerCookieValue || '',
        e: eyebrowImage || '',
        l: lsId || '',
        p: promoCode || '',
    };
};

const setBannerRedirect = (parsedQueryParams, location) => {
    const lsid = (attributionContants.LSID in parsedQueryParams) ? parsedQueryParams.lsid : '';
    if (parsedQueryParams && location) {
        const landingPageUrl = location.search ? `/${location.search}` : '/';

        const bannerCookieVal = generateBannerDataObject({
            code: parsedQueryParams?.r || parsedQueryParams?.ref || parsedQueryParams?.LocNumber,
            eyebrowImage: '',
            lsId: lsid,
            promoCode: '',
        });

        mbpLogger.logInfo({
            landingPageUrl,
            bannerCookieVal,
            function: 'processAttribution | setBannerRedirect',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: 'setBannerRedirect | END',
        });

        return {
            landingPageUrl: landingPageUrl || '/',
            bannerCookieVal: JSON.stringify(bannerCookieVal),
        };
    }

    return {
        landingPageUrl: '/',
    };
};

export const setBannerCookieSession = ({ value }) => {
    if (typeof sessionStorage !== 'undefined' && value) {
        sessionStorage.setItem(SESSION_NAME_BANNER, JSON.stringify(value));
    }
};

export const setBannerCookie = ({
    value,
    expires,
}) => {
    const cookieProps = {
        path: '/',
        encode: String,
    };

    if (expires && expires !== '') {
        const currentDate = new Date();
        const expirationDate = new Date();
        expirationDate.setDate(currentDate.getDate() + parseInt(expires, 10));

        cookieProps.expires = expirationDate;
    }

    cookies.set(COOKIE_NAME_BANNER, value, cookieProps);
};

export const setEntryBrandCookie = ({
    value,
    expires,
}) => {
    const cookieProps = {
        path: '/',
        encode: String,
    };

    if (expires) {
        const currentDate = new Date();
        const expirationDate = new Date();
        expirationDate.setDate(currentDate.getDate() + parseInt(expires, 10));

        cookieProps.expires = expirationDate;
    }

    cookies.set(COOKIE_NAME_ENTRY_BRAND, value, cookieProps);
};

export const getEntryBrandCookie = () => cookies.get('entryBrand');

// Handle application attribution tracking
// processFor -> 'Attribution' - Standard
// processFor -> 'ProcessExpressRequest' -> Express Checkout
export const processAttribution = (props, processFor, attrBannerCode) => {
    let bannerDetails = 'NOT_SET';

    try {
        const {
            featureFlags, location,
            attributionURL,
            attributionCookieExpiration,
        } = props;
        const queryParams = (attributionURL) ? attributionURL.search : location.search;
        const parsedQueryParams = (queryParams) ? qs.parse(queryParams.slice(1)) : null;
        mbpLogger.logInfo({
            function: 'processAttribution',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: 'featureFlags is defined',
            errorMessage: {
                featureFlags: !!featureFlags?.['is-checkout-monitoring-enabled'],
                search: location?.search,
                attributionURL: attributionURL || '',
            },
        });

        if (location?.search) {
            console.log({
                function: 'processAttribution',
                errorMessage: {
                    featureFlags: !!featureFlags?.['is-checkout-monitoring-enabled'],
                    search: location?.search,
                    attributionURL: attributionURL || '',
                },
            });
        }

        if (featureFlags) {
            mbpLogger.logInfo({
                function: 'processAttribution',
                message: `processAttribution | START | attrBannerCode "${attrBannerCode}" parsedQueryParams ${props?.location?.search?.slice(1)}`,
            });

            if (featureFlags['is-checkout-monitoring-enabled']) {
                console.error({
                    function: 'processAttribution',
                    message: `Debug: processAttribution | START | attrBannerCode "${attrBannerCode}" attributionURL ${attributionURL}`,
                });
            }

            let hasBanner = false;
            let bannerCode = '';
            let overrideReferrer = null;

            let hasEntryBrand = false;
            let entryBrand = '';

            if (parsedQueryParams) {
                if (attributionContants.BANNER_CODE in parsedQueryParams) {
                    hasBanner = 'BANNER_CODE';
                    bannerCode = parsedQueryParams[attributionContants.BANNER_CODE];
                }

                if (attributionContants.ITEM_SKU in parsedQueryParams) {
                    hasBanner = 'ITEM_SKU';
                    bannerCode = parsedQueryParams[attributionContants.ITEM_SKU];
                }

                if (attributionContants.BANNER_CODE_REF in parsedQueryParams) {
                    hasBanner = 'BANNER_CODE_REF';
                    bannerCode = parsedQueryParams[attributionContants.BANNER_CODE_REF];
                }

                if (attributionContants.SCI_LOC_NUMBER in parsedQueryParams) {
                    hasBanner = 'SCI_LOC_NUMBER';
                    bannerCode = parsedQueryParams[attributionContants.SCI_LOC_NUMBER];
                }

                // The initial Brand that comes through the brand tabs
                if (attributionContants.ENTRY_BRAND in parsedQueryParams) {
                    hasEntryBrand = 'ENTRY_BRAND';
                    entryBrand = parsedQueryParams[attributionContants.ENTRY_BRAND];
                }

                if (featureFlags['is-checkout-monitoring-enabled']) {
                    console.error({
                        function: 'processAttribution',
                        message: `Debug: processAttribution | bannerCode "${bannerCode}" from "${hasBanner}"`,
                    });
                }

                overrideReferrer = parsedQueryParams.testreferrer ? parsedQueryParams.testreferrer : null;
            }

            // Check for search referrer to determine attribution type (standard/search)
            const approvedReferrers = (featureFlags && featureFlags['attribution-natural-search-refs']) ? featureFlags['attribution-natural-search-refs'] : '.google.=seogoogle|.yahoo.=seoyahoo|.bing.=seobing|.ask.=seoask|.comcast.=seocomcast|.aol.=seoaol';
            const defaultSearchBannerCode = checkForSearchAttribution({
                featureFlags,
                approvedReferrers,
                overrideReferrer,
            });

            // Set entry brand value in the cookies
            if (hasEntryBrand && entryBrand) {
                setEntryBrandCookie({
                    expires: attributionCookieExpiration,
                    value: entryBrand,
                });
            }

            // Standard Attribution campaign tracking (utm)
            if (!defaultSearchBannerCode && hasBanner && bannerCode) {
                bannerDetails = setBannerRedirect(parsedQueryParams, location);
                if (featureFlags['is-checkout-monitoring-enabled']) {
                    console.error({
                        function: 'processAttribution',
                        errorMessage: bannerDetails,
                        message: 'Debug: Standard Attribution ::: banner details',
                    });
                }

                if (processFor === 'Attribution' || processFor === 'ProcessExpressRequest') {
                    const {
                        bannerCookieVal,
                    } = bannerDetails;

                    setBannerCookie({
                        expires: attributionCookieExpiration,
                        value: bannerCookieVal,
                    });

                    setBannerCookieSession({
                        value: bannerCookieVal,
                    });
                    if (featureFlags['is-checkout-monitoring-enabled']) {
                        console.error({
                            function: 'processAttribution',
                            errorMessage: bannerCookieVal || '',
                            message: `Debug: Standard attribution ::: cookie set ${bannerCookieVal}`,
                        });
                    }

                    return bannerCookieVal;
                }
            }

            // Search attribution tracking
            if (defaultSearchBannerCode) { // referrer like seogoogle
                // Default - Natural
                let code = defaultSearchBannerCode;

                mbpLogger.logInfo({
                    function: 'processAttribution',
                    appName: process.env.npm_package_name,
                    module: 'mbp-pwa-ui',
                    code,
                    message: `Search Attribution ::: START | default "${code}"`,
                });

                // With banner or url r/ref param (PAID)
                if (attrBannerCode || bannerCode) {
                    code = attrBannerCode || bannerCode; // r= attribution from redux store - Paid (or URL if redux entry is missing)

                    if (typeof attrBannerCode === 'object') {
                        code = attrBannerCode.code;
                    }

                    mbpLogger.logInfo({
                        function: 'processAttribution',
                        appName: process.env.npm_package_name,
                        module: 'mbp-pwa-ui',
                        code,
                        message: `Search Attribution ::: PAID | "${code}"`,
                    });
                } else {
                    mbpLogger.logInfo({
                        function: 'processAttribution',
                        appName: process.env.npm_package_name,
                        module: 'mbp-pwa-ui',
                        code,
                        message: `Search Attribution ::: NATURAL | "${code}"`,
                    });
                }

                const nsCookieValue = generateBannerDataObject({
                    code,
                });

                setBannerCookie({
                    expires: attributionCookieExpiration,
                    value: nsCookieValue,
                });

                setBannerCookieSession({
                    value: nsCookieValue,
                });
                if (featureFlags['is-checkout-monitoring-enabled']) {
                    console.error({
                        function: 'processAttribution',
                        nsCookieValue,
                        message: 'Debug: Standard attribution ::: cookie set',
                    });
                }

                mbpLogger.logInfo({
                    function: 'processAttribution',
                    appName: process.env.npm_package_name,
                    module: 'mbp-pwa-ui',
                    nsCookieValue,
                    code,
                    message: 'Search Attribution ::: END; cookie set',
                });

                return nsCookieValue;
            }
        }
    } catch (e) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            jsError: e,
            message: 'processAttribution failed',
        });
    }

    mbpLogger.logInfo({
        function: 'processAttribution',
        appName: process.env.npm_package_name,
        module: 'mbp-pwa-ui',
        bannerDetails,
        message: 'processAttribution ::: END',
    });

    return bannerDetails;
};

/**
 * Gets attribution data from localstorage
 */
export const getAttributionDataFromChatWidget = () => JSON.parse(localStorage.getItem(LOCAL_STORAGE_ATTRIBUTION_DATA));

/**
 * Stores attribution data to localstorage
 * @param {*} data
 */
export const storeAttributionDataFromChatWidget = (data) => {
    localStorage.setItem(LOCAL_STORAGE_ATTRIBUTION_DATA, JSON.stringify(data));
};

/**
 * construct the object that contains the attribution data from localStorage
 * if 48 hours have passed then it will return null
 * @param {*} orderId
 * @param {*} orderTotal
 */
export const orderCompleteAttributionData = async (orderId, orderTotal, brandCode, featureFlag) => {
    try {
        const timeLimit = 172800000; // 48 hours in milliseconds
        const attributionData = getAttributionDataFromChatWidget();
        if (attributionData) {
            const conversationEndDate = new Date(Date.parse(attributionData.endDate)) || '';
            const eventDate = new Date();
            const diff = (conversationEndDate && eventDate) && Math.abs(eventDate - conversationEndDate); // currentd date minus end date in milliseconds
            const isPast48Hours = diff > timeLimit;
            if (attributionData
                && ((attributionData.chatEnded && !isPast48Hours) || attributionData.isCurrentlyChatting)) {
                const enterpriseKey = localStorage.getItem(LOCAL_STORAGE_ENTERPRISE_ID) || null;
                const wcEnv = { rootUri: '/' };
                await reportWebchatConversion({
                    orderNumber: orderId,
                    orderTotal,
                    conversationId: attributionData?.convId,
                    eventDate,
                    enterpriseId: enterpriseKey,
                    brandCode,
                    // the .catch() is a failsafe, but should never be entered due to proper error handling in reportWebchatConversion function
                }).catch((error) => mbpLogger.logError({
                    appName: process.env.npm_package_name,
                    jsError: error,
                    message: `orderCompleteAttributionData reportWebchatConversion failed convid: ${attributionData?.convId}`,
                }));
                return {
                    data: featureFlag['is-use-personalization-api-for-chat-events-enabled']
                        ? new EnterpriseIdNewPayloadBodyType(
                            ENTERPRISE_EVENTS.WEBCHAT_ORDER_ATTRIBUTION_WITH_EID,
                            brandCode,
                            enterpriseKey,
                            {
                                webOrderNumber: orderId,
                                webOrderValue: orderTotal,
                                conversationId: attributionData?.convId,
                                eventDate,
                                isFromAigo: attributionData?.isFromAigo || false,
                            },
                        ) : new EnterpriseIdPayloadBody(ENTERPRISE_EVENTS.WEBCHAT_ORDER_ATTRIBUTION, {
                            webOrderNumber: orderId,
                            webOrderValue: orderTotal,
                            conversationId: attributionData?.convId,
                            eventDate,
                            isFromAigo: attributionData?.isFromAigo || false,
                        }),
                    wcEnv,
                };
            }
        }
        mbpLogger.logInfo({
            function: 'orderCompleteAttributionData',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            attributionData,
            message: 'orderCompleteAttributionData ::: END',
        });
    } catch (e) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            jsError: e,
            message: 'orderCompleteAttributionData failed',
        });
    }
    return null;
};
