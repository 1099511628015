/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// IMPORTANT!!
// DO NOT change without verifying the CMS entries: Global stack -> Relationships content type
const Relationships = [
    { id: '0', name: 'Self' },
    { id: '15', name: 'Wife' },
    { id: '14', name: 'Husband' },
    { id: '1', name: 'Mom' },
    { id: '2', name: 'Dad' },
    { id: '4', name: 'Daughter' },
    { id: '3', name: 'Son' },
    { id: '6', name: 'Sister' },
    { id: '5', name: 'Brother' },
    { id: '17', name: 'Girlfriend' },
    { id: '16', name: 'Boyfriend' },
    { id: '8', name: 'Grandma' },
    { id: '7', name: 'Grandpa' },
    { id: '20', name: 'Aunt' },
    { id: '10', name: 'Uncle' },
    { id: '18', name: 'Friend' },
    { id: '21', name: 'Family' },
    { id: '22', name: 'Colleague' },
    { id: '23', name: 'Client' },
    { id: '19', name: 'Other' },
];

export default Relationships;
