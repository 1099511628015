/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpLogger from 'mbp-logger';
import subscriptionConfig from '../../common/subscription/subscription-helpers';
import { getBaseCode } from '../product/dataLayerProductHelpers';

import { getDataLayerActionEntry } from './pageDataLayerCommonHelpers';
import { getCartAttributeByNameAndReturnValue } from '../../page/pageHelpers';

const getAppliedPromotions = (appliedPromos) => {
    let promoCodes = '';

    try {
        if (appliedPromos?.length > 0) {
            appliedPromos.forEach((promo) => {
                // Handle multiple codes (comma delimited)
                if (promoCodes !== '') {
                    promoCodes += ',';
                }

                promoCodes += promo.promotionCode;
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            appliedPromos,
            function: 'getAppliedPromotions',
            appName: process.env.npm_package_name,
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to generate applied promos data.',
        });
    }

    return promoCodes;
};

const loadAddOnData = (addOns, brandCode, isPassport) => {
    let addOnsData = null;

    try {
        if (addOns) {
            addOnsData = Object.keys(addOns).map((key) => {
                const {
                    deliveryInfo,
                    itemDiscounts = {},
                    itemInfo,
                    product,
                } = addOns[key];

                const addOnProduct = {
                    sku: product.productCode || '',
                    productName: product.parentProductName || '',
                    productCategory: product.productType || '',
                    productURL: 'productURL',
                    price: itemInfo.price || '0.00',
                    quantity: itemInfo.quantity,
                    adjustments: {
                        discount: itemDiscounts.totalMerchandiseDiscount || '0.00',
                        shipping: itemDiscounts.totalShippingDiscount || '0.00',
                        surcharge: itemDiscounts.totalSurchargeDiscount || '0.00',
                    },
                    total: itemInfo.totalProduct || '0.00',
                    deliveryDate: deliveryInfo?.deliveryDate, // deliveryInfo is empty on TPF personalization
                    baseCode: product.productCode,
                    brandCode: product.brandCode,
                    isPassport: product.isPassport,
                    orderItemId: 'orderItemId',
                };

                return addOnProduct;
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            addOns,
            brandCode,
            isPassport,
            function: 'loadAddOnData',
            appName: process.env.npm_package_name,
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to build add-on item data.',
        });
    }

    return addOnsData;
};

const loadGreetingCardData = (greetingCards) => {
    let cgcItem = null;

    try {
        if (greetingCards.length > 0) {
            const {
                cartItemId,
                itemInfo,
                product,
            } = greetingCards[0];

            cgcItem = {
                sku: product.productCode || '',
                productName: product.parentProductName || '',
                price: itemInfo.price || '0.00',
                quantity: itemInfo.quantity,
                adjustments: {
                    discount: itemInfo.priceAdjustments || '0.00',
                    shipping: '0.00',
                    surcharge: '0.00',
                },
                orderItemId: cartItemId || '',
            };
        }
    } catch (ex) {
        mbpLogger.logError({
            item: cgcItem,
            function: 'loadGreetingCardData',
            appName: process.env.npm_package_name,
            module: 'mbp-page',
            jsError: ex,
            jsMessage: 'Failed to build Greeting Card item data.',
        });
    }

    return cgcItem;
};

const loadRecipientData = (address) => {
    let recipient = null;

    try {
        if (address) {
            const {
                firstName,
                lastName,
                businessTitle,
                addressLine1,
                addressLine2,
                email,
                city,
                state,
                zipCode,
                phoneNo,
                country,
            } = address;

            const recipCountry = (country === 'US') ? 'US' : country;

            recipient = {
                firstName,
                lastName,
                location: businessTitle,
                address1: addressLine1,
                address2: addressLine2,
                id: email,
                city,
                state,
                postal: zipCode,
                phone1: phoneNo,
                country: recipCountry,
            };
        }
    } catch (ex) {
        mbpLogger.logError({
            address,
            function: 'loadRecipientData',
            appName: process.env.npm_package_name,
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to build item Recipient data',
        });
    }

    return [recipient];
};

const loadComponentData = (components) => {
    let compsData = null;

    try {
        if (components) {
            compsData = components.map((component) => {
                const {
                    itemInfo,
                    product,
                } = component;

                const componentProduct = {
                    sku: product.productCode || '',
                    productName: product.parentProductName || '',
                    quantity: itemInfo.quantity,
                };

                return componentProduct;
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            components,
            function: 'loadComponentData',
            appName: process.env.npm_package_name,
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to build component item data.',
        });
    }

    return compsData;
};

const generateCheckoutDataLayerItemArrays = (recipients, pidConsistent) => {
    try {
        const items = [];
        const productAddons = [];
        const productAdjustments = [];
        const productAttributes = [];
        const productCategoryIdentifiers = [];
        const productCategoryIds = [];
        const productCategoryPaths = [];
        const productComponents = [];
        const productCoupons = [];
        const productDeliveryDates = [];
        const productDeliveryMethods = [];
        const productGreetingCards = [];
        const productImageUrls = [];
        const productOrderItemIds = [];
        const productQuantities = [];
        const productRecipients = [];
        const productTotals = [];
        const productTypes = [];
        const productUrls = [];
        const productSubscription = [];

        recipients.forEach((recip) => {
            const { cartItems, recipientAddress } = recip;
            cartItems.forEach((cartItem, idx) => {
                const productCount = idx + 1;
                const {
                    addons,
                    attributes,
                    cartItemId,
                    components,
                    deliveryInfo,
                    itemDiscounts,
                    itemInfo,
                    greetingCards,
                    product,
                    subscription,
                } = cartItem;

                const brandCode = product.brandCode || '';
                const isPassport = product.isPassport || '';

                let pid = product.parentProductCode || product.productCode || '';
                if (pidConsistent) {
                    pid = product.parentProductCode || product.productCode || '';
                }

                const item = {
                    partNumber: getBaseCode(pid),
                    name: product.parentProductName || '',
                    price: itemInfo.price || product.listPrice || '',
                    sKUs: [{ partNumber: product.productCode || '' }],
                    brandId: brandCode,
                    productCount,

                    adjustments: {
                        discount: 0,
                        shipping: 0,
                        surcharge: 0,
                        total: 0,
                    },
                    attributes: [''], // CS
                    category: product.productType || '',
                    categoryId: '',
                    categoryName: '',
                    categoryPath: '',
                    components: [''],
                    coupons: '',
                    deliveryDates: deliveryInfo?.deliveryDate || '',
                };

                if (attributes?.length > 0) {
                    item.categoryId = getCartAttributeByNameAndReturnValue(attributes, 'categoryId') || '';
                    item.categoryName = getCartAttributeByNameAndReturnValue(attributes, 'categoryIdent') || '';
                    item.categoryPath = getCartAttributeByNameAndReturnValue(attributes, 'categoryPath') || '';
                }

                let subscriptionAttributes = {};

                if (subscription && subscription.duration && subscription.interval) {
                    subscriptionAttributes = {
                        duration: subscription.duration,
                        type: subscription?.type,
                        frequency: subscription.interval,
                        durationName: subscriptionConfig.subscription_duration?.[subscription.duration],
                        frequencyName: subscriptionConfig.subscription_interval?.[subscription.interval],
                    };
                }

                // Product Url
                productUrls.push('productUrl');
                // Product Image Url
                productImageUrls.push('productImageURL');
                // Total Product
                productTotals.push(itemInfo.totalProduct || '0.00');
                // Delivery Date
                productDeliveryDates.push(deliveryInfo?.deliveryDate || '');

                // Delivery Method
                productDeliveryMethods.push(deliveryInfo?.deliverySLA || '');
                // OrderItem ID
                productOrderItemIds.push(cartItem.cartItemId || '');
                // Quantity
                productQuantities.push(itemInfo.quantity || '');

                productSubscription.push(subscriptionAttributes);
                item.quantity = itemInfo.quantity || ''; // As per current tealium data layer the quantity is counting from product array

                // Product Attributes Data
                // TODO - Nothing applied to PWA CCO
                productAttributes.push(['']);

                // Adjustments
                if (itemDiscounts) {
                    productAdjustments.push({
                        discount: itemDiscounts.totalMerchandiseDiscount || '0.00',
                        shipping: itemDiscounts.totalShippingDiscount || '0.00',
                        surcharge: itemDiscounts.totalSurchargeDiscount || '0.00',
                    });
                } else {
                    productAdjustments.push({
                        discount: '0.00',
                        shipping: '0.00',
                        surcharge: '0.00',
                    });
                }

                productTypes.push('');

                // Add-Ons Data
                if (addons && addons.length > 0) {
                    item.addOns = loadAddOnData(addons, brandCode, isPassport);
                }
                productAddons.push(item.addOns || ['']);

                // Product Category ID/Identifier
                productCategoryIdentifiers.push(item.categoryIdent || '');
                productCategoryIds.push(item.categoryId || '');
                productCategoryPaths.push('');

                // Product Components (CYO/PYO)
                if (components && components.length > 0) {
                    item.components = loadComponentData(cartItem.components);
                }
                productComponents.push(item.components || ['']);

                // Coupons
                if (itemDiscounts?.promotions?.length > 0) {
                    item.coupons = itemDiscounts.promotions.map((promoItem) => promoItem.promotionCode || '');
                }
                productCoupons.push(item.coupons || '');

                // Greeting Card Data
                if (greetingCards?.length > 0) {
                    item.greetingCards = loadGreetingCardData(greetingCards);
                }
                productGreetingCards.push(item.greetingCards || ['']);

                if (recipientAddress) {
                    item.recipient = loadRecipientData(recipientAddress);
                }
                productRecipients.push(item.recipient || ['']);

                if (pidConsistent) {
                    items.push(item);
                } else {
                    items[cartItemId] = item;
                }
            });
        });

        if (items.length) {
            const checkoutItemsArrays = {
                productAdjustments,
                productAttributes,
                productAddons,
                productComponents,
                productCoupons,
                productDeliveryDates,
                productDeliveryMethods,
                productGreetingCards,
                productImageUrls,
                productOrderItemIds,
                productQuantities,
                productRecipients,
                productTotals,
                productTypes,
                productUrls,
                productSubscription,
            };

            return {
                products: items,
                ...checkoutItemsArrays,
            };
        }
    } catch (ex) {
        mbpLogger.logError({
            recipients,
            function: 'generateCheckoutDataLayerItemArrays',
            appName: process.env.npm_package_name,
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to build Checkout data layer arrays.',
        });
    }

    return {};
};

const generateCheckoutPageDataLayer = (page, pageDataLayer, pidConsistent = false) => {
    if (!page || !pageDataLayer) {
        mbpLogger.logError({
            page,
            pageDataLayer,
            function: 'generateCheckoutPageDataLayer',
            appName: process.env.npm_package_name,
            module: 'mbp-page',
            message: 'Missing required params',
        });

        return {};
    }

    const {
        pageName, pageType, checkoutStep, paymentDetails,
    } = page;
    const dataLayer = { ...pageDataLayer };
    const { order, externalCart } = getDataLayerActionEntry('track_order_data', page.dataLayer);

    try {
        const checkoutPageDetails = {
            'interstitial-login': {
                pageName: 'CheckoutAccountSignIn',
                pageType: 'checkoutAccountSignIn',
            },
            cart: {
                pageName: 'ShoppingCart',
                pageType: 'shoppingCart',
                step: '0',
            },
            shipping: {
                pageName: 'Recipient',
                pageType: 'recipient',
                step: '1',
            },
            // delivery: {
            //     pageName: 'DeliveryAndGreeting',
            //     pageType: 'deliveryandgreeting',
            //     step: '1',
            // },
            payment: {
                pageName: 'Payment',
                pageType: 'payment',
                step: '2',
            },
            review_order: {
                pageName: 'PlaceOrder',
                pageType: 'placeorder',
                step: '3',
            },
            order_confirmation: {
                pageName: 'Thankyouconfirm',
                pageType: 'thankyouconfirm',
            },
        };

        // Base Data Layer Props
        if (page && page.name) {
            dataLayer.pageName = checkoutPageDetails[page.name].pageName || '';
            dataLayer.pageType = checkoutPageDetails[page.name].pageType || '';
            dataLayer.checkoutStep = checkoutPageDetails[page.name].step || '';
        } else { // SPC
            dataLayer.pageName = pageName;
            dataLayer.pageType = pageType;
            dataLayer.checkoutStep = checkoutStep;
        }

        dataLayer.order_checkout_service_type = 'gcp';

        if (Object.keys(order).length > 0) {
            const { recipients } = order;

            const totalGiftCardAmount = order.totalGiftCardAmount ? Number(order.totalGiftCardAmount) : 0.00;

            const giftCardBT = totalGiftCardAmount;
            const orderTotal = parseFloat(order.totalProduct || 0) + parseFloat(order.totalNetShipping || 0) + parseFloat(order.totalTax || 0);
            const merchandiseTotalBT = parseFloat(order.totalProduct);
            const taxBT = parseFloat(order.totalTax);
            const shippingBT = parseFloat(order.totalNetShipping);
            const discountBT = parseFloat(order.totalDiscount);
            const discountShippingBT = parseFloat(order.totalNetShippingDiscount);

            // Pr duplicate purchase action on Order Con page refresh [Legacy]
            dataLayer.allow_conversion_tracking = true;
            dataLayer.order_total = orderTotal.toFixed(2) || '0.00';
            dataLayer.order_adjusted_total = (orderTotal + discountBT).toFixed(2) || '0.00';
            dataLayer.order_total_balance = (parseFloat(order.cartTotal) + totalGiftCardAmount).toFixed(2) || '0.00';
            dataLayer.order_product_total = merchandiseTotalBT.toFixed(2) || '0.00';
            dataLayer.order_tax = taxBT.toFixed(2) || '0.00';
            dataLayer.order_shipping = shippingBT.toFixed(2) || '0.00';
            dataLayer.order_discount = discountBT.toFixed(2) || '0.00';
            dataLayer.order_total_tax = taxBT.toFixed(2) || '0.00';
            dataLayer.order_adjusted_shipping = discountShippingBT.toFixed(2) || '0.00';
            dataLayer.order_external_cart_order_total = parseFloat(externalCart?.cartTotal || 0).toFixed(2) || '0.00';

            dataLayer.order_promoCode = '';
            dataLayer.order_promoMemberId = '';
            if (order.appliedPromotions?.length > 0) {
                order.appliedPromotions.forEach((orderLevelPromotion) => {
                    dataLayer.order_promoCode = orderLevelPromotion.promotionCode || '';
                    dataLayer.order_promoMemberId = orderLevelPromotion.membershipCode || '';
                });
            }

            dataLayer.order_itemsCount = order.orderSize || '';
            dataLayer.order_currency = 'USD';
            dataLayer.order_currencyCode = '$';
            dataLayer.order_bannerCode = '';
            dataLayer.order_giftCard = giftCardBT || '0.00';
            dataLayer.order_brandName = order.primaryBrand || '';
            dataLayer.order_appliedPromos = getAppliedPromotions(order.appliedPromotions) || '';

            dataLayer.order_adjustments_total = Number(order.totalDiscount).toFixed(2) || '0.00';
            dataLayer.order_giftCardsTotal = Number(totalGiftCardAmount).toFixed(2) || '0.00';

            if (paymentDetails) {
                const { paymentMethod } = paymentDetails;
                // TODO: Fix me; paymentMethod object does not contain ccType or ccNum
                // let isCreditCardUsed = false;
                // if (paymentMethod.id === 'CreditCard') {
                //     dataLayer.order_CCType = paymentMethod.ccType.substring(0, 1) || '';
                //     dataLayer.order_Last4CC = paymentMethod.ccNum.substring(paymentMethod.ccNum.length - 4, paymentMethod.ccNum.length) || '';
                //     // isCreditCardUsed = true;
                // }
                dataLayer.order_payment_type = paymentMethod.id;
                dataLayer.order_paymentType = paymentMethod.id; // TODO: Remove me, eventually.
            }

            // Customer Data
            if (order.customer?.customerAddress) {
                const billingAddress = order.customer.customerAddress;
                dataLayer.customer_firstName = billingAddress.firstName || '';
                dataLayer.customer_lastName = billingAddress.lastName || '';
                dataLayer.customer_city = billingAddress.city || '';
                dataLayer.customer_state = billingAddress.state || '';
                dataLayer.customer_postal = billingAddress.zipCode || '';
                dataLayer.customer_userId = billingAddress?.customer?.customerId || order?.customer?.customerId || '';
                dataLayer.order_billing_phone = billingAddress.phoneNumber || '';
                dataLayer.customer_addressLine1 = billingAddress.address1 || '';
                dataLayer.customer_addressLine2 = billingAddress.address2 || '';
                // Override member details if not logged in
                if (!dataLayer.customer_email) {
                    dataLayer.customer_email = billingAddress.email || '';
                }

                if (billingAddress.country === 'US') {
                    dataLayer.customer_country = 'USA';
                } else {
                    dataLayer.customer_country = billingAddress.country || '';
                }
            }

            // Items Data
            let itemArrays = null;
            if (recipients?.length > 0) {
                itemArrays = generateCheckoutDataLayerItemArrays(recipients, pidConsistent);
            }

            // Order Confirmation [trigger]
            if ((order.orderIdWithSourceId || order.cartId) && dataLayer.pageType === 'thankyouconfirm') {
                dataLayer.order_id = order.orderIdWithSourceId || order.cartId || '';
            }

            // Order id for tracking purpose
            if (order.orderIdWithSourceId) {
                dataLayer.order_source_id = order.orderIdWithSourceId || '';
            }

            // Merge base properties w/ product arrays
            if (dataLayer && itemArrays) {
                return {
                    ...dataLayer,
                    ...itemArrays,
                };
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            page,
            order,
            pageName,
            pageType,
            checkoutStep,
            paymentDetails,
            function: 'generateCheckoutPageDataLayer',
            appName: process.env.npm_package_name,
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to generate checkout page data layer.',
        });
    }

    return dataLayer;
};

export {
    generateCheckoutPageDataLayer,
    generateCheckoutDataLayerItemArrays,
};
