/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpApiUtil from 'mbp-api-util';
import mbpApiBrand from 'mbp-api-brand';
import mbpConfigServerApi from 'mbp-config-server-api';
import mbpLogger from 'mbp-logger';
import sdkFeatureFlags from 'feature-flags-sdk';

import cookies from '../../app/helpers/common/storage/cookies';

const context = {
    mbpApiUtil,
    mbpApiBrand,
    mbpConfigServerApi,
    mbpLogger,
    sdkFeatureFlags,

    cookies,
};

export default context;
