/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useMemo } from 'react';
import {
    func, object, bool, string,
} from 'prop-types';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';

import { getPublicRoutes } from '../routes/publicRoutes';

import { getSSRDeviceType } from '../state/ducks/App/App-Selectors';
import { getFeatureFlag } from '../state/ducks/App/ducks/Config/Config-Selectors';
import { trackApp } from './helpers/tracking/common/commonTrackingHelpers';
import AppShell from './components/AppShell/AppShell';
import AppRoutes from './AppRoutes';

// intersection-observer polyfill
if (typeof window !== 'undefined' && typeof window.IntersectionObserver === 'undefined') {
    // eslint-disable-next-line no-unused-expressions
    import('intersection-observer');
}

const App = (props) => {
    const {
        store,
        isServer,
        isShell,
        apolloClient,
        apolloClientSSR,
        is404,
        productUnavailableName,
    } = props;

    const ffIsAccountMfeEnabled = useSelector(getFeatureFlag('is-account-mfe-enabled'));
    const ffWhichAccountRouteOnMFE = useSelector(getFeatureFlag('which-account-route-enabled-on-mfe'));
    const ssrDeviceType = useSelector(getSSRDeviceType);

    const pRoutes = useMemo(() => getPublicRoutes({
        isAccountMFEEnabled: ffIsAccountMfeEnabled,
        accountRoutesMFEObject: ffWhichAccountRouteOnMFE,
    }), [ffIsAccountMfeEnabled, ffWhichAccountRouteOnMFE]);

    return (
        <AppShell
            isServer={isServer} // from universal loader
            isShell={isShell} // from universal loader
            apolloClientSSR={apolloClientSSR}
            ssrDeviceType={ssrDeviceType}
        >
            <AppRoutes
                store={store}
                isServer={isServer}
                apolloClient={apolloClient}
                is404={is404}
                productUnavailableName={productUnavailableName}
                pRoutes={pRoutes}
            />
        </AppShell>
    );
};

App.propTypes = {
    store: object.isRequired,
    isServer: bool,
    apolloClient: object.isRequired,
    apolloClientSSR: func,
    isShell: bool,
    is404: bool,
    productUnavailableName: string,
};

App.defaultProps = {
    isServer: false,
    isShell: false,
    apolloClientSSR: null,
    is404: false,
    productUnavailableName: '',
};

// TODO do we still need this? I dont think so
// Required for SSR
export const AppHelmet = Helmet;

// TODO can we do something with a hook here?
const enhance = compose(
    trackApp('mbp-pwa-ui'),
);

export default enhance(App);
