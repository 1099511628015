/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable no-underscore-dangle */
import {
    ApolloClient,
    InMemoryCache,
    ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import mbpUtil from 'mbp-api-util';
import mbpLogger from 'mbp-logger';

import MultiAPILink from './helpers/MultiAPILink';
import serilizeGetQuery from './helpers/serializeGetQuery';
import { genericProductPaginationContainer } from './helpers/paginationCachePolicyObjectGenerators';

export const GRAPHQL_ENV = mbpUtil.getEnv('APP_GRAPHQL_ENV')
    ? mbpUtil.getEnv('APP_GRAPHQL_ENV')
    : process.env.NODE_ENV || 'development';

const buildGraphQLClient = (props = {}) => {
    const {
        host = mbpUtil.getEnv('APP_WC_HOST'),
        ssrMode,
        initialState,
    } = props;

    const ssrGraphQLHost = mbpUtil.getEnv('APP_GRAPHQL_HOST_SSR') || 'aggregator.acquisition-services.svc.cluster.local:8080';
    const uri = (typeof window !== 'undefined')
        ? `https://${host}/r/api/aggregator/graphql` // Client-Side
        : `${mbpUtil.getEnv('APP_WC_PROTOCOL_SSR')}://${ssrGraphQLHost}${mbpUtil.getEnv('APP_API_PREFIX_SSR')}/aggregator/graphql`; // Server-Side

    const customFetchSerializer = (url, options) => {
        let cleanedURL = url;
        if (options.method === 'GET') {
            cleanedURL = serilizeGetQuery(url);
        }
        return fetch(cleanedURL, options);
    };

    const surrogateSet = new Set();
    const afterwareAddSurrogateKeys = new ApolloLink((operation, forward) => forward(operation).map((response) => {
        const context = operation.getContext();
        const { response: { headers } } = context;
        const surrogateKey = headers.get('surrogate-key');
        surrogateSet.add(surrogateKey);
        return response;
    }));

    const link = ApolloLink.from([
        onError(({
            operation, graphQLErrors, networkError,
        }) => {
            if (graphQLErrors) {
                graphQLErrors.forEach((data) => mbpLogger.logError({
                    message: `[GraphQL error]: Message: ${data?.message}`,
                    queryPath: data?.path,
                    query: data?.source,
                    function: 'Apollo Client GQL Error',
                    appName: process.env.npm_package_name,
                    module: 'mbp-pwa-ui',
                }));
            }
            if (networkError) {
                /**
                   * A network error has 1 of 3 shapes according to Apollo Types
                   *
                   * 1. Error: {
                   *    stack?: string
                   * }
                   *
                   * 2. ServerError: {
                   *    response: Response: {
                   *       headers: Headers;
                   *       ok: boolean;
                   *       redirected: boolean;
                   *       status: number;
                   *       statusText: string;
                   *       type: ResponseType;
                   *       url: string;
                   *    },
                   *    result: Result string,
                   *    statusCode: number
                   * }
                   *
                   * 3. ServerParseError: {
                   *       response: Response; (see above)
                   *       statusCode: number;
                   *       bodyText: string;
                   * }
                */

                if (networkError?.stack) {
                    mbpLogger.logError({
                        message: `[Network error]: ${networkError}`,
                        error: networkError,
                        triggeredBy: operation.operationName,
                        function: 'Apollo Client Network Error',
                        appName: process.env.npm_package_name,
                        module: 'ApolloLink',
                    });
                    return;
                }

                mbpLogger.logError({
                    message: `[Network error]: ${networkError}`,
                    triggeredBy: operation.operationName,
                    extraInfo: networkError?.response?.statusText || networkError?.bodyText,
                    statusCode: networkError?.statusCode,
                    requestUrl: networkError?.response?.url,
                    function: 'Apollo Client Network Error - no stack',
                    appName: process.env.npm_package_name,
                    module: 'ApolloLink',
                });
            }
        }),
        afterwareAddSurrogateKeys,
        new MultiAPILink({
            uri,
            fetch: customFetchSerializer,
        }),
    ]);

    const cache = new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    findProductsBySearchTerm: genericProductPaginationContainer(),
                    findCategoryById: genericProductPaginationContainer(),
                    findContentByUID: {
                        keyArgs: ['uid', 'contentType'],
                    },
                },
            },
        },
    }).restore(initialState || {});

    // cache invalidation headers
    const getSurrogateKeys = () => [...Array.from(surrogateSet)];

    const apolloClient = new ApolloClient({
        link,
        cache,
        ...(ssrMode ? { ssrMode: true } : { ssrForceFetchDelay: 100000 }),
        connectToDevTools: true,
    });

    return { apolloClient, getSurrogateKeys };
};

export default buildGraphQLClient;
