/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    SET_FAB_VARIANT,
    SET_AB_TESTING_VARIANT,
    SET_AB_TESTING_LOADED,
    SET_AB_TESTING_TIMEOUT,
} from './ABTesting-ActionTypes';

export const setFABVariant = (data) => ({
    type: SET_FAB_VARIANT,
    payload: data,
});

export const setABTestingVariant = (data) => ({
    type: SET_AB_TESTING_VARIANT,
    payload: data,
});

export const setABTestingLoaded = () => ({
    type: SET_AB_TESTING_LOADED,
    payload: { loaded: true },
});

export const setABTestingTimeout = () => ({
    type: SET_AB_TESTING_TIMEOUT,
    payload: { timeout: true },
});

export default {};
