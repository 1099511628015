/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { getSSRDeviceType } from '../../../../state/ducks/App/App-Selectors';

const isValidCallback = (callback) => callback && typeof callback === 'function';

export const useExitIntent = (callback) => {
    const isExitTriggered = useRef(false);

    const handleExitIntent = (event) => {
        if (isExitTriggered.current) return;
        if (event.clientY < 5) {
            isExitTriggered.current = true;

            if (isValidCallback(callback)) {
                callback('exit');
            }
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('mousemove', handleExitIntent);
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('mousemove', handleExitIntent);
            }
        };
    }, [callback]);
};

export const useScrollDetection = (callback, scrollThreshold = 500) => {
    const isMobile = useSelector(getSSRDeviceType) === 'mobile';
    const [previousScroll, setPreviousScroll] = useState(0);
    const timeout = useRef(null);

    const handleScroll = () => {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            const scrollY = window.scrollY || window.pageYOffset;
            const scrollDifference = scrollY - previousScroll;

            if (Math.abs(scrollDifference) > scrollThreshold) {
                if (isValidCallback(callback)) {
                    callback('exit');
                }
            }

            setPreviousScroll(scrollY);
        }, 100);
    };

    useEffect(() => {
        const scrollY = window.scrollY || window.pageYOffset;
        setPreviousScroll(scrollY);

        if (typeof window !== 'undefined' && isMobile) {
            window.addEventListener('scroll', handleScroll);
        }

        return () => {
            clearTimeout(timeout.current);
            if (typeof window !== 'undefined' && isMobile) {
                window.removeEventListener('scroll', handleScroll);
            }
        };
    }, [callback]);
};

export default { useExitIntent };
