/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session'; // defaults to localStorage for web and AsyncStorage for react-native

import commonReducers from './Common-Reducers';
import * as commonSelectors from './Common-Selectors';

// Ducks
import { errorActions, errorOperations } from './ducks/Error';
import { showLoadingMessageActions, showLoadingMessageOperations } from './ducks/ShowLoadingMessage';
import { qasAddressSearchActions, qasAddressSearchOperations } from './ducks/AddressSearchQAS';
import { pmallPersonalizationActions, pmallPersonalizationOperations } from './ducks/PmallPersonalization';
import { donationActions, donationOperations } from './ducks/Donation';

const commonWatcherSagas = [
    ...errorOperations.watchers,
    ...showLoadingMessageOperations.watchers,
    ...qasAddressSearchOperations.watchers,
    ...pmallPersonalizationOperations.watchers,
    ...donationOperations.watchers,
];

const commonDucks = {
    error: {
        errorActions,
        errorOperations,
    },
    showLoadingMessage: {
        showLoadingMessageActions,
        showLoadingMessageOperations,
    },
    qasAddressSearch: {
        qasAddressSearchActions,
        qasAddressSearchOperations,
    },
    pmallPersonalization: {
        pmallPersonalizationActions,
        pmallPersonalizationOperations,
    },
    donation: {
        donationActions,
        donationOperations,
    },
};

export {
    commonWatcherSagas,
    commonSelectors,

    // Ducks
    commonDucks,
};

const commonReducerPersistConfig = {
    key: 'common',
    storage: sessionStorage,
};

const commonPersistReducer = persistReducer(commonReducerPersistConfig, commonReducers);

export default commonPersistReducer;
