/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';

import * as orderActions from './Order-Actions';
import * as orderOperations from './Order-Operations';
import orderReducers from './Order-Reducer';

const orderWatcherSagas = [
    ...orderOperations.watchers,
];

export {
    orderOperations,
    orderActions,
    orderWatcherSagas,
};

const orderReducerPersistConfig = {
    key: 'order',
    storage: sessionStorage,
};

const orderPersistReducer = persistReducer(orderReducerPersistConfig, orderReducers);

export default orderPersistReducer;
