/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { createSelector } from 'reselect';

import brandThemes from '../../../../../app/components/AppShell/brandtheme';
import { getBrand } from '../../../App/ducks/Config/Config-Selectors';
import { getFeatureFlags } from '../App/App-Selectors';
import { getGooglePaymentUsingCardFromWallet } from '../../../Payment/plugins/GooglePay/GooglePay-Selectors';

import {
    getPaypalPaymentDetails,
    getPaymentUsingPaypalWallet,
    getIsPaypalHasBillingDetails,
} from '../Payment/plugins/Paypal/Paypal-Selectors';
import { getOrderItemErrors } from './ducks/Error/Error-Selector';
import { getCartAttributeByNameAndReturnValue } from '../../../../../app/helpers/page/pageHelpers';

const getActiveABTests = (state) => state.ui.abTesting;

export const getCurrentBrandId = (state) => ({ ...{ ...state }.ui }.brandId) || '';

// Add to cart
export const getAddToCartLoaderStatus = (state) => state.addToCart.loader;
export const getAddToCartError = (state) => state.addToCart.error;

export const getLoaderStatus = (state) => state.common.showLoadingMessage.flag;
export const getLoaderId = (state) => state.common.showLoadingMessage.loaderId;

export const getOrder = (state) => state?.order;
export const getOrderId = (state) => state?.order?.orderId;

/**
 * @param {} state
 */
export const getBrandsById = (state) => {
    if (state && state.page && state.page.brands && state.page.brands.byId) {
        return state.page.brands.byId;
    }

    return null;
};

/**
 * @param {} state
 */
export const getConfigByDomain = (state) => {
    if (state && state.app && state.app.config && state.app.config.byDomain) {
        return state.app.config.byDomain;
    }
    return null;
};

const getFullCart = (state) => state.cart || null;
export const getCartOrder = createSelector(
    [getFullCart],
    (cart) => {
        // verified cart is not empty
        if (cart && cart.cartId) {
            return cart;
        }

        return null;
    },
);

export const getTotalItemInCart = (state) => state?.order?.cartCount;
export const getAmount = (state) => ({ orderTotal: state?.cart?.cartTotal });
export const getGiftCards = (state) => state?.cart?.appliedGiftCards;

export const getOrderTotalProduct = (state) => state?.cart?.totalProduct || 0;
const getTotalAdjustments = (state) => state?.cart?.totalDiscount || 0;
const getOrderTotalBeforeTaxes = (state) => state?.cart?.totalBeforeTaxes || 0;
const getTotalShippingAdjustments = (state) => state?.cart?.totalNetShippingDiscount || 0;
export const getOrderTotalShipping = (state) => {
    const cartOrder = state.cart;
    if (cartOrder && cartOrder.totalShipping) {
        return Number(cartOrder.totalShipping).toFixed(2);
    }
    return 0;
};
export const getOrderTotalTax = (state) => state?.cart?.totalTax || 0;
const getOrderPromotions = (state) => state?.cart?.appliedPromotions;
const getGCsAppliedToOrder = (state) => state?.cart?.appliedGiftCards;
const getShippingTax = (state) => state?.cart?.totalShippingTax || 0;

export const getBilling = (state) => {
    if (state.cart?.customer?.customerAddress) {
        const billingAddress = state.cart?.customer?.customerAddress;
        return {
            firstName: billingAddress.firstName,
            lastName: billingAddress.lastName,
            address1: billingAddress.address1,
            address2: billingAddress.address2,
            state: billingAddress.state,
            country: billingAddress.country,
            zipCode: billingAddress.zipCode,
            city: billingAddress.city,
            phone: billingAddress.phoneNumber,
            email: billingAddress.email,
            confirmEmail: billingAddress.email,
        };
    }

    return {};
};

export default getBilling;
// Error
export const getError = (state) => state?.common?.error;
export const getPaymentError = (state) => state?.common?.error?.payment || {};
export const getCartError = (state) => state?.common?.error?.cart || '';
export const getRecipientFormError = (state) => state?.common?.error?.recipientForm;
export const getRecipientGiftMessageError = (state) => state?.common?.error?.giftMessage;
export const getGiftCardError = (state) => state?.common?.error?.giftCard;
export const getPromotionError = (state) => state?.common?.error?.promotion;
export const getCalendarError = (state) => state?.common?.error?.calendar;
export const getDTWError = (state) => state?.common?.error?.dtw;
export const getPayPalError = (state) => state?.common?.error?.paypal;

export const getIsPromotionHasError = (state) => {
    const promoError = getPromotionError(state);
    if (promoError?.error || promoError?.promotionCode) {
        return true;
    }
    return false;
};

export const getIsPaymentHasError = (state) => {
    const paymentError = getPaymentError(state);
    let errorMessage = '';
    Object.keys(paymentError).forEach((fieldName) => {
        if (paymentError[fieldName] !== '' && fieldName !== 'validateBillingAddress' && fieldName !== 'error') {
            errorMessage += `${paymentError[fieldName]} `;
        }
    });

    if (errorMessage !== '') {
        return true;
    }

    return false;
};

export const getIsRecipientHasError = (state) => {
    const recipientError = getOrderItemErrors(state);
    let errorMessage = '';

    Object.keys(recipientError).forEach((orderItemId) => {
        Object.keys(recipientError[orderItemId]).forEach((featureType) => {
            if (recipientError[orderItemId][featureType].errorKey) {
                errorMessage += `${recipientError[orderItemId][featureType].errorKey} `;
            }
        });
    });

    if (errorMessage !== '') {
        return true;
    }

    return false;
};

// Recipient
export const getCalShippingAttempt = (state) => state?.recipient?.calShipping?.calShippingChargesAttempt;
export const getCalShippingStatus = (state) => state?.recipient?.calShipping?.calShippingApplied;
export const getRecipientForm = (state) => state?.recipient?.recipientForm;
export const getCurrentRecipients = (state) => state?.recipient?.recipients;
export const getWorkingRecipient = (state) => state?.recipient?.workingRecipient;
export const getShippingInfo = (state) => state?.recipient?.shippingInfo;
export const getGiftMessageInfo = (state) => state?.recipient?.giftMessage;
export const getUserSubmittedProductFilterZipcode = (state) => state.ui.appContext.userSubmittedProductFilterZipcode;
export const getLocationType = (state) => state.ui.appContext.locationType;

export const getIfZipCodeIsValid = (state) => {
    const recipientFormError = getRecipientFormError(state);
    // avoid international product - zipcode is saved as 00000
    if (recipientFormError?.zipCode !== '00000' && recipientFormError?.zipCode === 'Invalid Zip Code') {
        return false;
    }
    return true;
};

// QAS Address Search
export const getQasSearchSuggestion = (state) => state.common.addressSearchQAS.suggestion;
export const getQasSearchFormattedAddress = (state) => state.common.addressSearchQAS.formattedAddress;
export const getShowValidatedAddressSuggestion = (state) => state.common.addressSearchQAS.showValidatedAddressSuggestion;
export const getHasQasFailed = (state) => state.common.addressSearchQAS.qasFailed;

// Gift Expressions
export const getGiftMessageExpressions = (state) => state.recipient.giftMessageExpressions;

// Payment

export const getRewardPointsInfo = (state) => state?.checkout?.payment?.rewardPoints;
export const getPaymentInfo = (state) => state?.checkout?.payment?.paymentForm;
export const getBillingInfo = (state) => state?.checkout?.payment?.billingAddressForm;
export const getBillingFormFilled = (state) => state?.checkout?.payment?.billingAddressForm?.isFormValid;
export const getPaymentMethod = (state) => state?.checkout?.payment?.paymentForm?.paymentMethod;
export const getPaymentMethods = (state) => state?.checkout?.payment?.paymentMethods;
export const getApplePayStatus = (state) => state?.checkout?.payment?.paymentMethods?.ApplePay?.active;

export const getIfPayPalPaymentFailedDueToMissingAddress = createSelector(
    [
        getPaymentError,
    ],
    (paymentFailedError) => {
        if (paymentFailedError.error === 'ERROR_PAYPAL_ADDRESS') {
            return true;
        }
        return false;
    },
);

// Subscription
export const getSubscription = (state) => state?.checkout?.payment?.subscription;
export const getEmailOptIn = (state) => state?.checkout?.payment?.subscription?.emailOptIn;
export const getSMSOptIn = (state) => state?.checkout?.payment?.subscription?.smsOptIn;
export const getOptInEntryMessage = (state) => state?.checkout?.payment?.subscription.optInEntryMessage;
export const getUserProfile = (state) => state?.account?.userProfile;

// promotion
export const getPromotionDetails = (state) => state?.checkout?.payment?.promotion;

const getGiftCardDiscounts = (state) => getCartOrder(state)?.totalGiftCardAmount || '0.00';
export const getOrderTotalBalance = (state) => state.cart?.cartTotal || 0.00;

export const disablePaymentFields = (state) => (state.cart?.cartTotal && Number(state.cart?.cartTotal) === 0);

export const getPlaceOrderStatus = createSelector(
    [
        getPaymentInfo,
        getBillingInfo,
        disablePaymentFields,
        getPaypalPaymentDetails,
        getPaymentUsingPaypalWallet,
        getGooglePaymentUsingCardFromWallet,
    ],
    (
        paymentInfo,
        billingInfo,
        disablePaymentMethod,
        paypalBT,
        usePayPalWallet,
        useGooglePayWallet,
    ) => {
        if (paymentInfo?.paymentMethod?.id === 'PayPal') {
            if (paypalBT && paypalBT.payload && paypalBT.payload.nonce && billingInfo.isFormValid) { // Paypal Braintree
                return true;
            }

            if (usePayPalWallet) {
                return true;
            }
        }

        if (paymentInfo?.paymentMethod?.id === 'GooglePay' && useGooglePayWallet) {
            return true;
        }

        if (paymentInfo && billingInfo) { // Credit Card
            if (disablePaymentMethod && billingInfo.isFormValid) { // Gift Card covers order
                return true;
            }
            if (paymentInfo.isFormValid && billingInfo.isFormValid) { // Credit Card
                return true;
            }

            return false;
        }

        return false;
    },
);

export const removeMaskForPhoneNumber = (number) => {
    let strNumber = number.toString().trim();
    strNumber = strNumber.replace(/\s/g, '').replace(/[^0-9+]/g, '');
    if (strNumber.indexOf('+') === 0 && strNumber.indexOf('1') === 1) {
        strNumber = strNumber.substring(2, strNumber.length);
    } else if (strNumber.indexOf('+') >= 0) {
        strNumber = strNumber.replace(/\s/g, '').replace(/[^0-9]/g, '');
    }
    return strNumber;
};

export const getReviewOrderStatus = createSelector(
    [
        getPaymentInfo,
        getBillingInfo,
        disablePaymentFields,
        getSMSOptIn,
        getPaymentUsingPaypalWallet,
        getIsPaypalHasBillingDetails,
        getGooglePaymentUsingCardFromWallet,
    ],
    (
        paymentInfo,
        billingInfo,
        disablePaymentMethod,
        smsOptIn,
        usePaypalWallet,
        paypalHasBillingAddress,
        useGooglePayCardWallet,
    ) => {
        if (paymentInfo && billingInfo) {
        // sms opt-in enabled
            if (smsOptIn) {
                const mobilePhone = removeMaskForPhoneNumber(billingInfo.mobilePhone);

                if (smsOptIn && (mobilePhone.length >= 10 && mobilePhone.length <= 12)) {
                    return true;
                }

                return false;
            }

            // Giftcard Fully Applied & Billing Form filled
            if (disablePaymentMethod && billingInfo.isFormValid) { // Gift Card covers order
                return true;
            }

            if (paymentInfo.paymentMethod.id === 'Klarna'
            && !billingInfo.isFormValid) {
                return false;
            }

            // credit card
            if (paymentInfo.paymentMethod.id === 'CreditCard'
                && paymentInfo.isFormValid
                && (
                    billingInfo.phone
                    && billingInfo.email
                    && billingInfo.isFormValid
                )
            ) {
                return true;
            }

            // Paypal Wallet Card Or Paypal - Missing Billing Address
            if (paymentInfo.paymentMethod.id === 'PayPal'
            && ((usePaypalWallet || !paypalHasBillingAddress)
            && !billingInfo.isFormValid)) {
                return false;
            }

            // Google Pay Card From Wallet - Missing Billing Address
            if (paymentInfo.paymentMethod.id === 'GooglePay'
            && useGooglePayCardWallet
            && !billingInfo.isFormValid) {
                return false;
            }

            // all other payment methods
            if (paymentInfo.paymentMethod.id !== 'CreditCard') {
                return true;
            }
        }

        return false;
    },
);

const getRecipientOrderItems = (state) => state.cart?.recipients;

// Exclude Passport Item from the cart
export const getOrderItemExcludePassportAndDonation = createSelector(
    [
        getRecipientOrderItems,
    ],
    (recipients) => {
        if (recipients) {
            return recipients.filter((items) => (
                items.cartItems.filter((cartItem) => !cartItem.product.isPassport && cartItem.itemInfo?.lineItemType !== 'DONATION').length !== 0
            ));
        }
        return [];
    },
);

// List product by Order Item and name
export const getOrderItemAndName = (state) => {
    const itemsName = {};
    if (state.cart?.recipients?.length > 0) {
        const { recipients } = state.cart || {};
        recipients.filter((items) => (
            items.cartItems.filter((cartItem) => !cartItem.product?.isPassport && cartItem.itemInfo?.lineItemType !== 'DONATION').length !== 0
        )).forEach((items) => {
            items.cartItems.forEach((item) => {
                itemsName[item.cartItemId] = item.product?.parentProductName || '';
            });
        });
    }
    return itemsName;
};

export const getOrderContainsSubscriptionItem = (state) => {
    if (state.cart?.recipients) {
        const { recipients } = state.cart;
        return recipients.filter((recipient) => (
            recipient.cartItems.filter((item) => item.subscription?.type
            || item.product?.seoUrl?.includes('subscription')).length !== 0
        ));
    }
    return [];
};

export const getOrderContainsClubItemPayByCreditCard = (state) => {
    let clubItem = [];

    // Club Items that have paymentMethod set with value 0 will not have recurring payment
    // Credit Card is the only payment method displayed to customer
    if (state.cart?.recipients) {
        const { recipients } = state.cart;
        clubItem = recipients.filter((recipient) => (
            recipient.cartItems.filter((item) => {
                let paymentMethodCode = -1;

                if (item.product?.paymentMethod !== '' && item.product?.paymentMethod !== null) {
                    paymentMethodCode = item.product?.paymentMethod;
                }

                if (Number(paymentMethodCode) === 0) {
                    return true;
                }

                return false;
            }).length !== 0
        ));
    }

    if (clubItem.length > 0) return true;

    return false;
};

export const getAllItemsExceptForDigitalProdDonationAndPassport = (state) => {
    if (state.cart?.recipients) {
        const { recipients } = state.cart;
        return recipients.filter((items) => (
            items.cartItems.filter((cartItems) => (
                !cartItems.product.isPassport
                && (
                    !['DONATION', 'NBCU', 'DDELVR'].includes(cartItems.itemInfo?.lineItemType)
                )
            )).length !== 0
        ));
    }
    return [];
};

export const getRecipientsExcludePassport = createSelector(
    [
        getOrder,
        getOrderId,
        getCartOrder,
        getOrderItemExcludePassportAndDonation,
    ],
    (order, orderId, cartOrder, excudePassportItem) => {
        let recips = [];
        try {
            if (!order && !orderId) {
                return [];
            }
            if (cartOrder?.recipients) {
                recips = excudePassportItem;
            }
        } catch (ex) {
            console.error('ERROR: getRecipients selector FAILED', ex);
        }
        return recips;
    },
);

export const safeGetOrderItemsId = (state) => {
    if (state
        && state.cart
        && state.cart.recipients) {
        return state.cart.recipients[0].cartItems[0].cartItemId;
    }
    return '';
};

// Get the list of orderItem ids
export const getOrderItemIds = (state) => {
    const cartItemIds = [];
    if (state.cart?.recipients) {
        const { recipients } = state.cart;
        recipients.filter((items) => (
            items.cartItems.filter((cartItems) => !cartItems.product.isPassport && cartItems.itemInfo?.lineItemType !== 'DONATION').length !== 0
        )).forEach((items) => {
            items.cartItems.forEach((item) => {
                cartItemIds.push(item.cartItemId);
            });
        });
    }
    return cartItemIds;
};

// Get Order Item By Order Id
export const getOrderItemById = (orderItemList, cartItemId) => {
    const filterOrderItem = [];
    if (orderItemList.length > 0) {
        orderItemList.forEach((items) => {
            items.cartItems.forEach((cartItem) => {
                if (cartItem.cartItemId === cartItemId) {
                    const { recipientAddress } = items;
                    filterOrderItem[0] = {
                        cartItem: [cartItem],
                        recipientAddress,
                    };
                }
            });
        });
    }
    return filterOrderItem;
};

export const getPersonalizationPrice = (state) => {
    let totalPrice = 0;
    const totalProductPrice = getOrderTotalProduct(state);
    const { recipients } = getCartOrder(state);
    recipients.forEach((recipient) => {
        const { cartItems } = recipient;
        cartItems.forEach((order) => {
            totalPrice += order.itemInfo?.totalProduct;
        });
    });
    return parseFloat(totalProductPrice - totalPrice).toFixed(2);
};

export const getCartCount = (state) => {
    const cartOrder = state.cart;
    if (cartOrder) {
        const { recipients } = cartOrder;
        if (recipients?.length > 0) {
            return recipients.map((items) => (
                items.cartItems.length
            ))?.reduce((total, orderItem) => total + orderItem);
        }
    }
    return 0;
};

export const getOrderItemsWithError = (state) => {
    const paymentErrors = getPaymentError(state);
    return Object.keys(paymentErrors).filter((errorKey) => (Number(errorKey) > 0 && Number(errorKey) > 0 && paymentErrors[errorKey]));
};

export const getFirstItemOnCartWithError = (state) => {
    const listOrderItemIds = getOrderItemIds(state);
    const currentEditingItem = getWorkingRecipient(state);
    const orderItemsHavingError = getOrderItemsWithError(state);
    const recipients = getOrderItemExcludePassportAndDonation(state);
    let itemWithError = null;
    let itemIndex = 0;

    recipients.forEach((items) => {
        items.cartItems.forEach((orderItem) => {
            if (orderItemsHavingError.includes(orderItem.cartItemId)
            || (Object.keys(orderItem.adonList).filter((addOnId) => orderItemsHavingError.includes(addOnId)).length > 0)
            || orderItem?.greetingCards?.filter((greetingCard) => orderItemsHavingError.includes(greetingCard.greetingCardItemId)).length > 0) {
                if (!itemWithError) {
                    itemWithError = orderItem.cartItemId;
                }
            }
        });
    });

    if (itemWithError) {
        itemIndex = listOrderItemIds.indexOf(itemWithError);
    }

    return itemIndex >= 0 ? itemIndex : currentEditingItem;
};

// Passport
export const getPaymentMethodToPurchasePassport = () => ['CreditCard', 'PayPal'];

// Get Passport Item from the cart
export const getPassportItem = (state) => {
    const cart = getCartOrder(state);
    if (cart?.recipients) {
        const { recipients, cartId } = state.cart;
        if (recipients?.length > 0) {
            const passportItem = recipients.filter((items) => (
                items.cartItems.filter((cartItem) => !!cartItem.product.isPassport).length !== 0
            ));

            if (passportItem && passportItem.length > 0) {
                passportItem[0].ordersId = cartId;
            }

            return passportItem;
        }
    }
    return [];
};

// Get Movie Item from cart
export const getMovieItemInCart = createSelector(
    [getRecipientsExcludePassport],
    (recipients) => {
        if (recipients.length) {
            return recipients.filter((items) => (
                items.cartItems.filter((cartItems) => (
                    cartItems.itemInfo?.lineItemType === 'NBCU'
                )).length !== 0
            ));
        }
        return [];
    },
);

export const getOrderCharges = (state) => {
    const cartOrderData = getCartOrder(state);
    if (cartOrderData && (cartOrderData.totalNetShipping || cartOrderData.totalServiceCharge)) {
        return {
            shippingCharge: Number(cartOrderData.totalNetShipping),
            serviceCharge: Number(cartOrderData.totalServiceCharge),
        };
    }

    return {
        shippingCharge: 0,
        serviceCharge: 0,
    };
};

export const getDonationItem = (state) => {
    if (state.cart?.recipients) {
        const { recipients } = state.cart;
        return recipients.filter((items) => (
            items.cartItems.filter((cartItem) => cartItem.itemInfo?.lineItemType === 'DONATION').length !== 0
        ));
    }
    return [];
};

export const getIsDonationOnlyItemOnCart = (state) => {
    const passportItem = getDonationItem(state);
    const cartItems = getRecipientsExcludePassport(state);
    if (passportItem.length > 0 && cartItems && cartItems.length === 0) {
        return true;
    }
    return false;
};

export const getIsPassportOnlyItemOnCart = (state) => {
    const passportItem = getPassportItem(state);
    const cartItems = getRecipientsExcludePassport(state);
    if (passportItem.length > 0 && cartItems && cartItems.length === 0) {
        return true;
    }
    return false;
};

export const getOrderProductTypes = createSelector([
    getOrderItemExcludePassportAndDonation,
    getIsPassportOnlyItemOnCart,
],
(recipients, isPassportOnlyItem) => {
    let hasFPTProduct = false;
    let hasGPTProduct = false;

    if (recipients.length > 0) {
        recipients.forEach((items) => {
            items.cartItems.forEach((item) => {
                if (item.produc?.productType === 'FPT') {
                    hasFPTProduct = true;
                }
                if (item.product?.productType === 'GPT') {
                    hasGPTProduct = true;
                }
            });
        });
    }
    if (isPassportOnlyItem) {
        hasGPTProduct = true;
    }
    return {
        hasFPTProduct,
        hasGPTProduct,
    };
});

export const getHasMultiBrandProduct = (state) => {
    const { hasFPTProduct, hasGPTProduct } = getOrderProductTypes(state);
    if (hasFPTProduct && hasGPTProduct) {
        return true;
    }
    return false;
};

/**
* @param {} state
*/
export const getOrderStatus = (state) => {
    if (state.order?.orderId && state.cart?.cartStatus) {
        return state.cart.cartStatus;
    }

    return null;
};

const countNoOfOrderItems = (cartItems) => {
    let noOfItems = 0;
    Object.keys(cartItems).forEach((index) => {
        noOfItems += cartItems[index].cartItems.length;
    });
    return noOfItems;
};

export const getOrderTotalSummary = (state) => {
    const totalServiceAndShipping = getOrderCharges(state);
    return ({
        totalProduct: getOrderTotalProduct(state),
        totalShipping: totalServiceAndShipping?.shippingCharge,
        totalService: totalServiceAndShipping?.serviceCharge,
        totalShippingAdjustments: getTotalShippingAdjustments(state),
        totalAdjustments: getTotalAdjustments(state),
        totalTax: (Number(getOrderTotalTax(state)) + Number(getShippingTax(state))).toFixed(2),
        orderTotalBalance: getOrderTotalBalance(state),
        totalGiftCards: getGiftCardDiscounts(state),
        orderTotalBeforeTaxes: getOrderTotalBeforeTaxes(state),
    });
};

export const getAppliedPromotions = (state) => ({
    appliedPromotions: getOrderPromotions(state),
});

export const getAppliedGiftCards = (state) => ({
    appliedGiftcards: getGCsAppliedToOrder(state),
});

export const getGiftCardDetails = (state) => state.cart?.appliedGiftCards || [];
export const getIfGiftCardApplied = (state) => {
    const giftCard = state.cart?.appliedGiftCards || [];
    if (giftCard && giftCard.length > 0) {
        return true;
    }
    return false;
};

export const getRecipients = createSelector(
    [
        getOrder,
        getOrderId,
        getCartOrder,
    ],
    (order, orderId, cartOrder) => {
        let recips = [];

        try {
            if (!order && !orderId) {
                return [];
            }
            if (cartOrder?.recipients) {
                recips = cartOrder.recipients;
            }
        } catch (ex) {
            console.error('ERROR: getRecipients selector FAILED', ex);
        }

        return recips;
    },
);

export const getCartOrderDetails = createSelector(
    [
        getCartOrder,
    ],
    (cartOrder) => {
        try {
            if (cartOrder) {
                return cartOrder;
            }
        } catch (ex) {
            console.error('ERROR: getCartOrderDetails selector FAILED', ex);
        }

        return null;
    },
);

export const getNoOfOrderItems = (state) => {
    const cartItems = getRecipientsExcludePassport(state);
    if (cartItems && cartItems.length > 0) {
        return countNoOfOrderItems(cartItems);
    }
    return 0;
};

export const isDeliveryMessageAndDateFilledForAllItems = (state) => {
    const recipients = getOrderItemExcludePassportAndDonation(state);
    let isDelMsgAndDateFilled = true;
    if (recipients && recipients.length > 0) {
        recipients.forEach((orderItem) => {
            orderItem.cartItems.forEach((item) => {
                if (!item.greetingMessage?.greetingMessage || !item.deliveryInfo?.deliveryDate) {
                    isDelMsgAndDateFilled =  false;
                }
            });
        });
    } else {
        isDelMsgAndDateFilled =  false;
    }
    return isDelMsgAndDateFilled;
};

// Exclude Passport Item from the cart
export const isPassportInEligibleProductsInCart = (state) => {
    let isInEligible = false;
    const recipients = getOrderItemExcludePassportAndDonation(state);
    if (recipients && recipients.length > 0) {
        recipients.forEach((orderItem) => {
            orderItem.cartItems.forEach((item) => {
                if (!item.product?.isPassport) isInEligible = true;
            });
        });
    }
    return isInEligible;
};

export const isDeliveryAddressFilledForAllItems = (state) => {
    const recipients = getOrderItemExcludePassportAndDonation(state);
    let addressFilled = true;
    if (recipients && recipients.length > 0) {
        recipients.forEach((orderItem) => {
            const { recipientAddress } = orderItem;
            if (recipientAddress && !recipientAddress.firstName) {
                addressFilled = false;
            }
        });
    } else {
        addressFilled = false;
    }
    return addressFilled;
};

export const continueToPayment = createSelector(
    [
        isDeliveryAddressFilledForAllItems,
        isDeliveryMessageAndDateFilledForAllItems,
        getIsPassportOnlyItemOnCart,
    ],
    (orderItemAddressFilled, orderItemMsgAndDateFilled, passportOnlyItemOnCart) => {
        if (passportOnlyItemOnCart) {
            return true;
        }
        if (orderItemAddressFilled && orderItemMsgAndDateFilled) {
            return true;
        }
        return false;
    },
);

/**
 * @param {} state
 */
// Brand Customer Care Contact
export const getCustomerCare = (state) => {
    const brandDetails = getBrand(state);
    if (brandDetails && brandDetails.phoneNumber) {
        return brandDetails.phoneNumber;
    }
    return '';
};

/**
 * @param {} state
 */
// Get Espot
export const getEspotById = (state, id) => {
    if (state && state.entities && state.entities.espots) {
        let espot = state.entities.espots.byId[id];
        if (!espot) {
            const arrEspotName = Object.keys(state.entities.espots.byId).filter(((espotId) => (espotId.indexOf(id) >= 0)));
            const [espotName] = arrEspotName;
            espot = state.entities.espots.byId[espotName] || null;
        }
        return espot;
    }
    return null;
};

export const getCdnImagePath = (state) => {
    const brandDetails = getBrand(state) || '';
    return `https://cdn1.${brandDetails['domain-name']}.com/wcsstore/${brandDetails.identifier}/images`;
};

export const getProductsById = function getProductsById(state) {
    if (state && state.catalog && state.catalog.products && state.catalog.products.byId) {
        return state.catalog.products.byId;
    }
    return null;
};

export const getSelectedProductSku = function getSelectedProductSku(state) {
    if (state && state.ui && state.ui.pdpCart && state.ui.pdpCart.selectedSKUProduct) {
        return state.ui.pdpCart.selectedSKUProduct;
    }
    return null;
};

export const getShopRunnerEligibleItemInCart = (state) => {
    let shopRunnerEligbleItem = [];
    if (state.cart?.recipients) {
        const { recipients } = state.cart;
        shopRunnerEligbleItem = recipients.filter((items) => (
            items.cartItems.filter((cartItems) => (cartItems.product.isPassport && cartItems.product.isShoprunnerEligible) || cartItems.product.isShoprunnerEligible).length !== 0
        ));
    }
    if (shopRunnerEligbleItem.length > 0) {
        return true;
    }
    return false;
};

// DTW - selectors
export const getDTWOptions = (state) => {
    if (state && state.common && state.common.dtw) {
        return state.common.dtw;
    }

    return null;
};

export const isCheckoutPage = () => {
    if (typeof window !== 'undefined') {
        if (window.location.pathname.indexOf('/checkout') >= 0 || window.location.pathname.indexOf('/add-ons') >= 0) {
            return true;
        }
    }
    return false;
};

export const getLogo = createSelector(
    [
        getBrand,
    ],
    (currentBrand) => {
        if (currentBrand && currentBrand.code) {
            const { logos } = brandThemes[brandThemes[currentBrand.code] ? currentBrand.code : '18F'];
            return logos?.primary_logo?.url;
        }
        return null;
    },
);

export const getGiftMessageConfiguration = createSelector(
    [
        getBrand,
    ],
    (currentBrand) => {
        if (currentBrand && currentBrand.code) {
            const { giftMessageConfig } = brandThemes[brandThemes[currentBrand.code] ? currentBrand.code : '18F'];
            return giftMessageConfig;
        }

        return {};
    },
);

// Check Digital item in cart
export const getDigitalItemInCart = createSelector(
    [getMovieItemInCart, getPassportItem],
    (movieItems, passportItem) => {
        let hasDigitalItemInCart = false;

        if (movieItems?.length > 0 || passportItem?.length > 0) {
            hasDigitalItemInCart = true;
        }

        return hasDigitalItemInCart;
    },
);

// Checkout Food Supported Payment Method
export const getSupportedPaymentMethods = createSelector(
    [getFeatureFlags, getApplePayStatus],
    (flags, applePayStatus) => {
        const paymentMethods = ['CreditCard'];

        // Paypal Enabled
        if (flags['is-paypal-braintree-enabled']) {
            paymentMethods.push('PayPal');
        }

        // Chase Pay Enabled
        if (flags['is-chasepay-enabled']) {
            paymentMethods.push('ChasePay');
        }

        // Apple Pay Enabled
        if (flags['is-apple-pay-enabled-checkout'] && applePayStatus === 1) {
            paymentMethods.push('ApplePay');
        }

        return paymentMethods;
    },
);

export const getGreetingCards = (state) => {
    const cartRecipients = state?.cart?.recipients || [];
    const cartId = state?.cart?.cartId || null;

    const greetingCards = [];
    if (cartRecipients.length > 0) {
        cartRecipients.forEach((recipient) => {
            if (recipient?.cartItems?.length > 0) {
                recipient.cartItems.forEach((orderItem) => {
                    if (orderItem?.greetingCards?.length > 0) {
                        orderItem.greetingCards.forEach((card) => {
                            greetingCards.push({
                                keyId: card?.greetingCardKey,
                                ordersId: cartId,
                                orderItemsId: orderItem.cartItemId,
                                skuCode: card?.product?.productCode,
                            });
                        });
                    }
                });
            }
        });
    }
    return greetingCards;
};

export const getLabelData = (state) => {
    const cartOrder = state.cart?.recipients || [];
    const cartId = state.cart?.cartId || [];
    const labelData = [];

    if (cartOrder?.length > 0) {
        cartOrder.forEach((recipient) => {
            if (recipient?.cartItems?.length > 0) {
                recipient.cartItems.forEach((cartItem) => {
                    if (cartItem.addons?.length > 0) {
                        cartItem.addons.forEach((addon) => {
                            if (addon.attributes?.length > 0) {
                                const personalizationId = getCartAttributeByNameAndReturnValue(addon.attributes, 'key_id') || null;
                                if (personalizationId) {
                                    labelData.push({
                                        keyId: personalizationId,
                                        ordersId: cartId,
                                        orderItemsId: cartItem.cartItemId,
                                        skuCode: cartItem.product?.productCode,
                                    });
                                }
                            }
                        });
                    }
                });
            }
        });
    }
    return labelData;
};

// Review if there is HD(1019), SY(1018), WLF(1020) product in the cart.
export const isHDFamilyBrandProductInCart = (state) => {
    const cartItems = getOrderItemExcludePassportAndDonation(state);
    let productAvailable = false;
    cartItems.forEach((items) => (
        items.cartItems.forEach(
            (item) => {
                if (['1019', '1020', '1018'].includes(item.product.brandCode)) {
                    productAvailable = true;
                }
            },
        )
    ));
    return productAvailable;
};

export const getIfGiftItemHaveDeliveryAddresssAndDate = (state) => {
    const hasAddress = isDeliveryAddressFilledForAllItems(state);
    const hasGiftMessageAndDate = isDeliveryMessageAndDateFilledForAllItems(state);

    if (hasAddress && hasGiftMessageAndDate) {
        return true;
    }
    return false;
};

// Pmall Personalization
export const getPmallSummaryInfo = (state) => state.common.pmallPersonalization || {};

// Passport

export const getPassportSubscriptionStatus = (state) => {
    const passportItem = getPassportItem(state);
    if (passportItem.length > 0) {
        return true;
    }
    return false;
};

// Passport Bundle
export const getPassportBundleData = (state) => state?.passport?.passportBundle;

export const getPassportBundleCollectionData = (state) => state?.passport?.passportBundleCollection;

// Passport Campaign Data
export const getPassportCampaignData = (state) => state?.passport?.passportCampaign;

export const getPassportSignInData = (state) => state?.passport?.passportSignIn;

// Passport Side Bar Status
export const getPassportSideBarStatus = (state) => state?.passport?.passportSideBarStatus;

export const getPassportData = (state) => state?.passport?.passportData;

export const getPassportTncContentMarkUp = (state) => state?.passport?.tncContent;

export const getPassportBannerStatus = (state) => {
    const paymentMethod = getPaymentMethod(state);
    const allowedPaymentMethods = getPaymentMethodToPurchasePassport();
    if (allowedPaymentMethods.includes(paymentMethod?.id)) {
        return true;
    }
    return false;
};

export const getStatusOfPassportApplyRmGC = (state) => state?.passport?.showPassportConsentToRmGC;

export const getPassportProcessStatus = (state) => state?.passport?.passportProcess;

export const getIsPassportCapPriceReached = (state) => {
    const shippingPrice = getOrderTotalShipping(state);
    const passportSubscriptionStatus = getPassportSubscriptionStatus(state);
    const brandDetails = getBrand(state) || '';
    const passportItemPrice = brandDetails['passport-item-price'] || 0;
    if ((shippingPrice > passportItemPrice) && !passportSubscriptionStatus) {
        return true;
    }
    return false;
};

export const dynamicPassportCharge = (state) => state?.passport?.dynamicPassportCharge;

export const getPassportError = (state) => state?.passport?.error;

export const getPassportLoader = (state) => state?.passport?.loader;

export const getPassportABData = (state) => state?.passport?.passportABData;

export const getDisplayAddExtraItemLink = (state) => {
    const flags = getFeatureFlags(state);
    const activeABTests = getActiveABTests(state);
    const cartItems = getOrderItemExcludePassportAndDonation(state);
    let displayAddExtraItemLink = false;

    const abTestingShowExtraLink = activeABTests?.['checkout-addons']?.toLowerCase() || '';

    if (abTestingShowExtraLink !== 'select addons' || !flags['is-add-extra-item-link-enabled']) {
        return false;
    }

    cartItems.forEach((items) => (
        items.cartItems.forEach(
            (itemDetails) => {
                const isInternationalProduct = ['USA', 'US', 'CAN', 'CA'].includes(itemDetails?.deliveryInfo?.countryCode || '');
                const productType = itemDetails?.product?.productType || '';
                const noAddons = (Object.keys(itemDetails?.adonList)?.length === 0);
                const isSympathyProduct = itemDetails?.product?.sympathyValue;

                if (noAddons
                    && productType === 'FPT'
                    && !isSympathyProduct
                    && !isInternationalProduct
                    && !displayAddExtraItemLink) {
                    displayAddExtraItemLink = true;
                }
            },
        )
    ));

    return displayAddExtraItemLink;
};
