/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actions from './CardIsle-Actions';
import initialState from '../../initialState';

const cardIsleReducer = (state = initialState.cardIsle, action) => {
    switch (action.type) {
        case actions.loadCardIsle().type:
            return {
                ...state,
                availability: action.data,
            };
        case actions.setCardIsleSelected().type:
            return {
                ...state,
                selected: action.data,
            };
        case actions.resetCardIsle().type:
            return {
                ...state,
                selected: {},
            };
        default:
            return state;
    }
};

export default cardIsleReducer;
