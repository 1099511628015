/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { shape, string, arrayOf } from 'prop-types';
import Helmet from 'react-helmet';
import markdownLinkLiteralParser from './markdownLinkLiteralParser';
import { sanitizingString } from './sanitizingString';

const handleDuplicate = (faqArray)  => {
    const allQuestions = [];
    const allAnswers = [];
    const uniqueSchema  = [];
    faqArray.forEach((faqData) => {
        if (allQuestions.includes(sanitizingString(faqData.question)) || allAnswers.includes(sanitizingString(markdownLinkLiteralParser(faqData.answer)))) {
            return;
        }
        allQuestions.push(sanitizingString(faqData.question));
        allAnswers.push(sanitizingString(markdownLinkLiteralParser(faqData.answer)));
        uniqueSchema.push(faqData);
    });
    return uniqueSchema;
};

// ld+json schema
const FaqSeoSchema = ({ faqArray }) => {
    if (!Array.isArray(faqArray) || !faqArray.every((qaItem) => qaItem?.question && qaItem?.answer)) return <></>;
    const uniqueFaqArray = handleDuplicate(faqArray);
    return (
        <Helmet>
            <script type="application/ld+json">
                {`{"@context": "https://schema.org","@type": "FAQPage","mainEntity": [${uniqueFaqArray && uniqueFaqArray.map((faqData) => (`{"@type": "Question","name": "${sanitizingString(faqData.question)}","acceptedAnswer": {"@type": "Answer","text": "${sanitizingString(markdownLinkLiteralParser(faqData.answer))}"}}`))}]}`}
            </script>
        </Helmet>
    );
};

FaqSeoSchema.propTypes = {
    faqArray: arrayOf(shape({
        question: string,
        answer: string,
    })).isRequired,
};

export default FaqSeoSchema;
