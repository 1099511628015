/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';

// Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

import GraphqlPassportTnC from '../GraphqlPassportTnC/GraphqlPassportTnC';

const styles = {
    dialogTitle: {
        textAlign: 'right',
        padding: '0',
        margin: '6px',
    },
    paperStyle: {
        minWidth: '65vw',
        minHeight: '300px',
    },
};

const PassportTncModal = ({
    dialogOpen,
    closeModal,
    classes,
}) => (
    <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={closeModal}
        classes={{
            paper: classes.paperStyle,
        }}
    >
        <DialogTitle className={classes.dialogTitle} disableTypography>
            <IconButton aria-label="Close" onClick={closeModal}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <GraphqlPassportTnC />
            </DialogContentText>
        </DialogContent>
    </Dialog>
);

PassportTncModal.propTypes = {
    dialogOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PassportTncModal);
