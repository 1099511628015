/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './ShippingInfo-ActionTypes';

export const triggerShowShippingInfo = () => ({
    type: actionTypes.SHOW_SHIPPING_INFO,
});

export const updateShowShippingInfo = () => ({
    type: actionTypes.UPDATE_SHOW_SHIPPING_INFO,
});
