/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const RESOURCE_PAGE_FACADE = '/capture';
const RESOURCE_ROOT_FACADE = '/address';
const RESOURCE_PAGE_ACTION = '/v2';

const getQASAddress = (
    wcEnv, jwtToken, actionType, params,
) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE;

    const resourcePage = `${RESOURCE_PAGE_FACADE}/${RESOURCE_PAGE_ACTION}/${actionType}`;

    return restClient.getFacade(env, resourcePage, jwtToken, params)
        .then((response) => response);
};

export default getQASAddress;
