/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import {
    GET_VENMO_CLIENTID,
    LOAD_VENMO_CLIENTID,
    CLEAR_VENMO_CLIENTID,
    SUBMIT_VENMO_PAYMENT,
} from './Venmo-ActionTypes';

export const getVenmoClientId = () => ({
    type: GET_VENMO_CLIENTID,
});

export const loadVenmoClientId = (clientId) => ({
    type: LOAD_VENMO_CLIENTID,
    data: clientId,
});

export const clearVenmoClientId = () => ({
    type: CLEAR_VENMO_CLIENTID,
});

export const submitVenmoPayment = (data) => ({
    type: SUBMIT_VENMO_PAYMENT,
    data,
});

export default {};
