/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './PaymentForm-ActionTypes';

export const paymentUpdateFormValues = (event) => ({
    type: actionTypes.PAYMENT_UPDATE_FORM_VALUES,
    data: event,
});

export const paymentClearUpdateFields = () => ({
    type: actionTypes.PAYMENT_CLEAR_UPDATE_FIELDS,
});

export const paymentPrefillFormfields = (data) => ({
    type: actionTypes.PAYMENT_PREFILL_FORM_FIELDS,
    data,
});

export const paymentGetFileds = () => ({
    type: actionTypes.PAYMENT_GET_FIELDS,
});

export const paymentUpdateFormStatus = (data) => ({
    type: actionTypes.PAYMENT_UPDATE_FORM_STATUS,
    data,
});

export const setPaymentMethod = (paymentMethod) => ({
    type: actionTypes.PAYMENT_SET_PAYMENT_METHOD,
    payload: paymentMethod,
});
