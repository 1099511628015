/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable import/prefer-default-export */

import { put, call, getContext } from 'redux-saga/effects';
import mbpUtil from 'mbp-api-util';

import * as brandActions from './Brand-Actions';
import { getBrandIdByHostName } from '../../../../../app/helpers/brandIdentification/brandIdentification';
import memCache from '../../../../../app/helpers/memCache/memCache';
import * as configActions from '../Config/Config-Actions';

const getKey = (entry, brand) => entry.substring(brand.length + 1);
const parseConfig = (rawConfig, domain) => {
    const parsedConfig = {};

    // Pull out brand-specfic properties
    Object.keys(rawConfig).forEach((entry) => {
        if (entry.indexOf(domain) !== -1) {
            const fkey = entry.startsWith(domain) ? getKey(entry, domain) : '';

            if (fkey) {
                parsedConfig[fkey] = rawConfig[entry];
            }
        }
    });

    return parsedConfig;
};

export function* fetchBrand({ hostname }) {
    // pull from saga context
    const mbpApiUtil = yield getContext('mbpApiUtil');
    const mbpApiBrand = yield getContext('mbpApiBrand');
    const mbpConfigServerApi = yield getContext('mbpConfigServerApi');
    const mbpLogger = yield getContext('mbpLogger');

    const brandId = getBrandIdByHostName(hostname);

    // note: this is not an api call
    const brand = yield call(mbpApiBrand.getBrandById, {}, brandId);
    let config = {};

    const host = (typeof window !== 'undefined')
        ? mbpApiUtil.getEnv('APP_WC_HOST')
        : mbpApiUtil.getEnv('APP_CONFIG_HOST_SSR');

    const refBrandEnv = mbpUtil.getEnv('APP_REF_BRAND_ENV') || 'ref-brand';
    const topic = 'ref-brand';
    const branch = 'master';

    const cacheKey = host + topic + branch;

    let configResponse = memCache.get(cacheKey);

    if (!configResponse) {
        try {
            configResponse = yield call(
                mbpConfigServerApi.getConfig,
                { host },
                refBrandEnv,
                branch,
            );
        } catch (error) {
            mbpLogger.logError({
                error,
                module: 'Brand-Operations',
                function: 'fetchBrand Saga',
            });
            yield put(brandActions.brandLoadFailed(error));
            return;
        }

        if (configResponse.status === 200) {
            memCache.set(cacheKey, configResponse);
        }
    }

    if (configResponse?.data?.propertySources?.length) {
        const [configPropertySource] = configResponse.data.propertySources;
        config = parseConfig(configPropertySource.source, brand.domain);
    }

    const result = {
        ...brand,
        ...config,
    };

    yield put(configActions.configLoaded(brand.domain, topic, config));
    yield put(brandActions.brandLoaded(brandId, result));
}
