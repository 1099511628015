/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';
import { func, object } from 'prop-types';
import mbpLogger from 'mbp-logger';
import { useQuery } from '@apollo/client';

import { bindActionCreators } from 'redux';
import loadable from '@loadable/component';
import * as app from '../../../../state/ducks/App';
import { GRAPHQL_ENV } from '../../../gql';
import findContent from '../../../gql/queries/findContent';
import withPageView from '../../../helpers/tracking/hocs/withPageView';
import { getCart } from '../../../../state/ducks/Cart/Cart-Actions';
import { getFeatureFlags } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import PassportEnrollmentPage from './PassportPageV2/PassportEnrollmentPage';

const PassportBlock = loadable(() => import(/* webpackChunkName: "PassportBlock" */ './PassportBlock'));

const brandSelectors = app.ducks.brand.selectors;
const PassportPage = ({
    brand, actionGetCart, featureFlags,
}) => {
    const [yotpoLoaded, setYotpoLoaded] = useState(false);
    const handledYotpoLoaded = useCallback(() => {
        setYotpoLoaded(true);
    });

    useEffect(() => {
        actionGetCart();

        if (!yotpoLoaded) {
            return;
        }

        if (typeof window !== 'undefined' && typeof window.yotpoWidgetsContainer?.initWidgets === 'function') {
            window.yotpoWidgetsContainer.initWidgets();
        }
    }, [yotpoLoaded]);

    const IS_YOTPO_ENABLED = featureFlags['is-yotpo-passport-enrollment-page-enabled'];

    const PASSPORT_PAGE_QUERY = findContent({
        brand: brand.domain,
        locale: 'en-us',
        contentType: 'passport_enrolment_page',
    });

    const { error, data } = useQuery(PASSPORT_PAGE_QUERY);
    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            query: PASSPORT_PAGE_QUERY,
            component: 'PassportPage.js',
            message: `Error loading data from Graphql ${error}`,
            env: GRAPHQL_ENV,
            error,
        });
        return null;
    }
    if (!data) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            query: PASSPORT_PAGE_QUERY,
            component: 'PassportPage.js',
            message: 'No data returned for query',
            env: GRAPHQL_ENV,
        });
        return null;
    }
    if (data?.findContent?.content?.entries?.length > 0) {
        const content = data.findContent.content.entries[0];

        return (
            <>
                {featureFlags['is-passport-enrollment-pg-v2-enabled']
                    ? (
                        <>
                            <PassportEnrollmentPage data={content} isYotpoEnabled={IS_YOTPO_ENABLED} />
                            {IS_YOTPO_ENABLED && (
                                <Helmet onChangeClientState={handledYotpoLoaded}>
                                    <script
                                        src="https://cdn-widgetsrepository.yotpo.com/v1/loader/jM5Ztz30MpQJGaZJLoixEA"
                                        onError={() => {
                                            mbpLogger.logError({
                                                appName: process.env.npm_package_name,
                                                component: 'PassportBlock.js',
                                                message: 'Failed to load yotpo widgets',
                                            });
                                        }}
                                    />
                                </Helmet>
                            )}
                        </>
                    )
                    : <PassportBlock data={content} />}
            </>
        );
    }
    return null;
};

PassportPage.propTypes = {
    brand: object.isRequired,
    actionGetCart: func,
    featureFlags: object,
};

PassportPage.defaultProps = {
    actionGetCart: () => {},
    featureFlags: {},
};

const mapStateToProps = (state) => ({
    brand: brandSelectors.getBrand(state),
    featureFlags: getFeatureFlags(state),
});

const mapDispatchToProps = (dispatch) => ({
    actionGetCart: bindActionCreators(getCart, dispatch),
});

const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withPageView({
        pageViewSuccessTest: () => true,
    }),
);

export default enhance(PassportPage);
