/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    useState, useRef, useCallback, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import UAParser from 'ua-parser-js';
import { getIsBot } from '../../../state/ducks/App/App-Selectors';
import { customIntersectionObserverRef, getIntersectionObserverRef } from '../IntObserver/IntObserver';

// each instance of visibility will have a 1 to 1 ratio, 1 component uses this
/**
 * @param {string} id - The ID of the target DOM element.
 * @param {object} customIntObsOptions - Optional custom Intersection Observer options.
 * @returns {[boolean, React.MutableRefObject]} A tuple containing a boolean indicating visibility
 * status and a mutable ref object to attach to the target element.
 */
const useVisibility = (id, customIntObsOptions) => {
    const isBot = useSelector(getIsBot);
    const browser = new UAParser();
    const info = browser.getResult();

    const browserName = info.browser.name || '';

    if (
        isBot
        || typeof window === 'undefined'
        || browserName === 'IE'
        || ((browserName === 'Safari'))
        || !window.IntersectionObserver) {
        return [true, { current: null }];
    }

    let intOb = null;
    if (customIntObsOptions) {
        // new instance for each hook
        intOb = customIntersectionObserverRef({ ObserverClass: IntersectionObserver }, customIntObsOptions);
    } else {
        // singleton
        intOb = getIntersectionObserverRef({ ObserverClass: IntersectionObserver });
    }

    const [visible, setVisibility] = useState(false);
    const ref = useRef(null);

    // callback per hook
    const observerCallback = useCallback(() => {
        setVisibility(true);
    });

    useEffect(() => {
        if (ref.current && !visible) {
            intOb.observe(id, ref.current, observerCallback);
        }

        if (visible) {
            intOb.unobserve(id, ref.current);
        }

        // unmounted component that never was in the viewport
        return () => {
            if (!visible) {
                intOb.unobserve(id, ref.current);
            }
        };
    }, [visible, ref.current]);

    return [visible, ref];
};

export default useVisibility;
