/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { call, put, select } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import mbpLogger from 'mbp-logger';

import * as helper from '../Auth/helper/helper';
import * as enterpriseIdActions from './EnterpriseId-Actions';
import { getCurrentBrandId } from '../../../App/ducks/Brand/Brand-Selectors';
import { uuidv4 } from '../../../../../app/helpers/uuid';
import { checkJWT } from '../Auth/Auth-Operations';
import { getBillingInfo } from '../../../Common/Common-Selectors';

const setTokenLocalStorage = (value, name) => {
    if (typeof window !== 'undefined' && window.localStorage) {
        window.localStorage.setItem(name, value);
    }
};

const removeNullFromIdentifiers = (identifiers) => identifiers.filter((identifier) => identifier?.value?.length > 0);

function* loadEnterpriseUserSegments(response) {
    try {
        const profileAttributes = response?.profileAttributes || [];
        let segments = {};
        profileAttributes.forEach((itemObj) => {
            if (itemObj?.attributeKey) {
                segments[itemObj?.attributeKey] = itemObj?.attributeValue;
            }
        });
        segments = Object.keys(segments).length > 0 ? segments : null;
        yield put(enterpriseIdActions.enterpriseIdUserSegmentsLoaded(segments));
    } catch (error) {
        mbpLogger.logError({
            function: 'loadEnterpriseUserSegments',
            appName: process.env.npm_package_name,
            module: 'EnterpriseId',
            message: 'Failed to load EID user segments from api',
            jsError: error,
        });
    }
}

function* generateCustomerIdentifiers(payload) {
    // if no uuid probably new customer, generate and add to EID call for single identifier if rest are null
    let browserUUID = yield call(helper.getTokenLocalStorage, 'browserUUID');
    if (!browserUUID) {
        browserUUID = yield call(uuidv4);
        setTokenLocalStorage(browserUUID, 'browserUUID');
    }
    // global identifiers
    const jwtToken = yield call(checkJWT);
    const billingInformation = yield select(getBillingInfo);
    const brandId = yield select(getCurrentBrandId);

    // action specific identifiers
    let emailId;
    let contactId;
    let auth0UserId;
    if (payload?.data) {
        contactId = payload.data?.user.contactId;
        emailId = payload.data?.user.email;
        const jwtPayload = jwtDecode(jwtToken);
        auth0UserId = jwtPayload?.sub;
    }

    // prepare API payload
    const identifiers = {
        brandId,
        identifiers: removeNullFromIdentifiers([
            billingInformation.email && { key: 'billingEmail', value: `${billingInformation.email}` }, // billing form
            browserUUID && { key: 'browserUUID', value: `${browserUUID}` }, // generated or cookie
            contactId && { key: 'contactId', value: `${contactId}` }, // jwtToken
            emailId && { key: 'emailId', value: `${emailId}` }, // jwtToken
            auth0UserId && { key: 'auth0UserId', value: `${auth0UserId}` }, // jwtToken
        ]),
    };

    return { identifiers, jwtToken };
}

function* handleEIDResponse(response) {
    yield put(enterpriseIdActions.enterpriseIdLoaded(response.enterpriseId));
    yield call(loadEnterpriseUserSegments, response);
    setTokenLocalStorage(response.enterpriseId, 'enterpriseId');
    if (typeof window !== 'undefined' && window.mbpDataLayerView && window.utag?.data && response.enterpriseId) {
        window.mbpDataLayerView.customer_eid = response.enterpriseId || '';
        window.utag.data.customer_eid = response.enterpriseId || '';
    }
}

export {
    handleEIDResponse,
    generateCustomerIdentifiers,
    setTokenLocalStorage,
    removeNullFromIdentifiers,
};

export default {};
