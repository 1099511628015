/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpLogger from 'mbp-logger';
import restClient from '../../app/helpers/restClient';
import { getOrdersByEmailIdQL } from '../../app/pages/Account/services/graphQL';

export const getOrdersByEmail = async (email, accessToken) => {
    let orders;
    let error;
    const resourcePage = 'orderhistory/graphql';
    const wcEnvConfig = { rootUri: '/' };
    const payload = {
        query: getOrdersByEmailIdQL(email),
    };

    try {
        const response = await restClient.postFacade(
            wcEnvConfig,
            resourcePage,
            accessToken,
            payload,
        );
        const data = response?.data?.data?.getOrdersByEmailId;
        orders = data?.orders || {};
        if (data?.error) {
            error = data.error;
        }
    } catch (ex) {
        error = ex;
    }

    return {
        orders, error,
    };
};

export const getOrderDetailsByOrderNumber = async (accessToken, payload) => {
    let orders;
    let error;
    const resourcePath = 'retention/selfservice/order/status';
    const wcEnvConfig = { rootUri: '/' };

    try {
        const response = await restClient.postFacade(wcEnvConfig, resourcePath, accessToken, payload);
        const data = response?.data;
        orders = data?.orders || {};
    } catch (ex) {
        error = ex;
        const errorMessage = `Order Status Error - payload: ${JSON.stringify(payload)}`;
        mbpLogger.logError({
            function: 'getOrderDetailsByOrderNumber',
            appName: process.env.npm_package_name,
            module: 'fetchOrders.js',
            message: errorMessage,
            jsError: ex,
        });
    }

    return {
        orders, error,
    };
};

export default {};
