/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actions from './Account-Actions';
import initialState from '../../../../initialState';

const accountReducer = (state = initialState.payment.account, action) => {
    switch (action.type) {
        case actions.setAccountMessage().type:
            return {
                ...state,
                accountMessage: action.data,
            };
        case actions.createAccountInProgress().type:
            return {
                ...state,
                inProgress: action.data,
            };
        case actions.setEmailOptInSubscribe().type:
            return {
                ...state,
                isSubscribe: action.isSubscribe,
            };
        default:
            return state;
    }
};

export default accountReducer;
