/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// docs: https://github.com/reactivestack/cookies/tree/master/packages/universal-cookie
import UniversalCookie from 'universal-cookie';

// TODO, add support for server side
class Cookies {
    constructor({
        CookieInterface,
    }) {
        this.cookies = new CookieInterface();
    }

    /**
     *
     * @param {string} name
     * @param {object} options
     */
    get = (name, options) => this.cookies.get(name, options)

    /**
     *
     * @param {string} name
     * @param {string|object} value
     * @param {object} options
     */
    set = (name, value, options) => this.cookies.set(name, value, options)
}

const cookies = new Cookies({ CookieInterface: UniversalCookie });

export default cookies;
