/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpLogger from 'mbp-logger';

/* eslint-disable import/prefer-default-export */

export class IntObserver {
    constructor({
        ObserverClass,
        mbpLoggerInterface,
        threshold,
        rootMargin,
    }) {
        this.observerCount = 0;
        this.intOb = new ObserverClass(this.genericCallback, { threshold: threshold || [0], rootMargin: rootMargin || '33.33%' });
        this.mbpLogger = mbpLoggerInterface;
        this.callbackMap = {};
    }

    /**
    * @description This function serves as the singular callback that our
    * instance of IntersectionObserver will trigger. Its job is to select
    * the correct callbacks from the map to inform the corresponding hooks
    * when its time to update state.
    * @param {array} elements
    */
  genericCallback = (elements) => {
      const inView = elements.filter((e) => e.intersectionRatio > 0);

      let foundCallback = false;

      // things are being observed but nothing came into view
      if (elements?.length && !inView.length) {
          return;
      }

      inView.forEach((element) => {
          const callbackId = element.target.dataset?.staticid;

          if (!callbackId) {
              return;
          }

          const dynamicCallback = this.callbackMap[callbackId];

          if (!dynamicCallback) {
              return;
          }

          foundCallback = true;
          dynamicCallback();
      });

      if (!foundCallback) {
          this.handleCallbackIdError();
      }
  }

  observe = (id, elemRef, callback) => {
      if (!id || !(elemRef instanceof HTMLElement)) {
          return;
      }

      this.callbackMap[id] = callback;
      this.intOb.observe(elemRef);
      this.observerCount += 1;
  }

  unobserve = (id, elemRef) => {
      if (!id || !(elemRef instanceof HTMLElement)) {
          return;
      }

      delete this.callbackMap[id];
      this.intOb.unobserve(elemRef);
      this.observerCount -= 1;
  }

  handleCallbackIdError = () => {
      // if we end up here it means that somehow the callback ids
      // and the map no longer match up. We need to  log the error
      // and show all the elements using int observer otherwise they
      // will never show up

      this.mbpLogger.logError({
          message: 'No callback id found.',
          module: 'IntObserver',
          function: 'genericCallback',
      });

      Object.values(this.callbackMap).forEach((func) => {
          func();
      });
  }
}

let intOb = null;
export const getIntersectionObserverRef = () => {
    if (typeof window === 'undefined' || !window.IntersectionObserver) {
        return null;
    }

    if (!intOb) {
        intOb = new IntObserver({
            ObserverClass: window.IntersectionObserver,
            mbpLoggerInterface: mbpLogger,
        });
    }

    return intOb;
};

export const customIntersectionObserverRef = (ObserverClass, customIntObsOptions) => {
    let customIntObj = null;
    if (typeof window === 'undefined' || !window.IntersectionObserver) {
        return null;
    }

    if (!customIntObj) {
        customIntObj = new IntObserver({
            ObserverClass: window.IntersectionObserver,
            mbpLoggerInterface: mbpLogger,
            threshold: customIntObsOptions?.threshold,
            rootMargin: customIntObsOptions?.rootMargin,
        });
    }

    return customIntObj;
};
