/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { element } from 'prop-types';
import Auth0Context from './auth-context';
import useAuthProvider from './auth-provider';
import AuthSiteLoader from './AuthSiteLoader';
import TagAuthenticationStatus from './TagAuthenticationStatus';

const AuthProvider = ({ children }) => {
    const authProvider = useAuthProvider();

    return (
        <Auth0Context.Provider
            value={authProvider}
        >
            <TagAuthenticationStatus />
            <AuthSiteLoader />
            { children }
        </Auth0Context.Provider>
    );
};

AuthProvider.propTypes = {
    children: element.isRequired,
};

export default AuthProvider;
