/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from './restClient';

const RESOURCE_ROOT_CARTSERVICE = '/checkout';
const RESOURCE_VERSION_CARTSERVICE = '/v2';
const RESOURCE_PAGE_CARTSERVICE = '/cart';

/**
 * addToCart
 * url - /checkout/{apiVersion}/cart?brand={brandId}
 *
 * @param {Object} args
 * @param {String} args.env
 * @param {string} args.jwtToken
 * @param {array} args.products
 */
const addToCart = ({ env, jwtToken, products }) => {
    const envObj = env || {};

    envObj.rootUri = `${RESOURCE_ROOT_CARTSERVICE}${RESOURCE_VERSION_CARTSERVICE}`;

    const payload = {
        products,
    };

    return restClient.postRequest(envObj, RESOURCE_PAGE_CARTSERVICE, jwtToken, payload)
        .then((response) => response);
};

export default addToCart;
