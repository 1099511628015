/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { combineReducers } from 'redux';
import BrandReducers from '../../ducks/App/ducks/Brand/Brand-Reducers';

export default combineReducers({
    brands: BrandReducers,
});
