/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import useTheme from '@material-ui/styles/useTheme';
import { getSSRDeviceType } from '../../../state/ducks/App/App-Selectors';

const regex = /[a-z][A-Z]/g;

/**
 * @description This hook is used to have a consistent hook from SSR to CSR.
 * Currently, useMediaQuery does not work on the server, and returns false initially, which can cause problems.
 * Also, ssrDeviceType is not dynamic.
 * This hook will use ssrDeviceType if useMediaQuery is not working (server or first render).
 * @param {object} breakpoints The queries that are used to determine screen size (if you need a query for each screen size)
 *  shape: {mobile: string, tablet: string, desktop: string}
 * @param {string} query If you just need a single query, this will work well
 * @return {string} Will be 'mobile', 'tablet', or 'desktop'.
 */
const useSSRMediaQuery = (breakpoints) => {
    const ssrDeviceType = useSelector(getSSRDeviceType);
    const theme = useTheme();
    // Need to pull out the px if present
    const isMobileFromQuery = useMediaQuery(theme.breakpoints.up(parseInt(String(breakpoints?.mobile || theme.breakpoints.values.xs || 400).replace(regex, ''), 10)));
    const isTabletFromQuery = useMediaQuery(theme.breakpoints.up(parseInt(String(breakpoints?.tablet || theme.breakpoints.values.sm || 700).replace(regex, ''), 10)));
    const isDesktopFromQuery = useMediaQuery(theme.breakpoints.up(parseInt(String(breakpoints?.desktop || theme.breakpoints.values.lg || 1024).replace(regex, ''), 10)));

    // If all are false, then we use the ssrDeviceType
    if (!isMobileFromQuery && !isTabletFromQuery && !isDesktopFromQuery) {
        return ssrDeviceType;
    }

    return  (isDesktopFromQuery && 'desktop') || (isTabletFromQuery && 'tablet') || (isMobileFromQuery && 'mobile');
};

export default useSSRMediaQuery;
