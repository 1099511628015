/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { getFeatureFlags } from '../App/ducks/Config/Config-Selectors';

export const getOrderId = (state) => state?.order?.orderId;

export const getPaymentMethod = (state) => state?.checkout?.payment?.paymentForm?.paymentMethod;

// Get Passport Item from the cart
export const getPassportItem = (state) => {
    if (state.cart?.recipients) {
        const { recipients, cartId } = state.cart;
        if (recipients?.length > 0) {
            const passportItem = recipients.filter((items) => (
                items.cartItems.filter((cartItem) => !!cartItem.product.isPassport).length !== 0
            ));

            if (passportItem && passportItem.length > 0) {
                passportItem[0].ordersId = cartId;
            }

            return passportItem;
        }
    }
    return [];
};

export const getPassportOrderItemId = (state) => {
    const passportItem = getPassportItem(state);
    if (passportItem.length > 0) {
        return passportItem[0].cartItemId;
    }
    return '';
};

export const getPassportSubscriptionStatus = (state) => {
    const passportItem = getPassportItem(state);
    if (passportItem.length > 0) {
        return true;
    }
    return false;
};

export const getGiftCardDetails = (state) => state.cart?.appliedGiftCards || [];
export const getIfGiftCardApplied = (state) => {
    if (state?.cart?.appliedGiftCards?.length > 0) {
        return true;
    }
    return false;
};

export const getAppliedGiftCards = (state) => state?.cart?.appliedGiftCards || {};

export const getPassportData = (state) => state?.passport?.passportData;

export const getPassportSideBarStatus = (state) => state?.passport?.passportSideBarStatus;

export const getPassportCampaignData = (state) => state?.passport?.passportCampaign;

export const getStatusOfPassportApplyRmGC = (state) => state?.passport?.showPassportConsentToRmGC;

export const getPassportSignInData = (state) => state?.passport?.passportSignIn;

export const getPassportError = (state) => state?.passport?.error;

export const getPassportABData = (state) => state?.passport?.passportABData;

export const getPassportSaleForceABTest = (state) => state?.passport?.passportSaleForceABTest;
export const getPassportSaleForceABTestVariant = (state) => (state?.passport?.passportSaleForceABTest?.contentSF?.campaign?.campaignResponses?.[0]?.userGroup) || 'Default';

export const getIsPassportRenewalAllowed = (state) => {
    const userDetails = state?.member?.profile;
    if (userDetails?.userRole === 'P'
        && userDetails?.passportStartDate
        && userDetails?.passportEndDate) {
        const endDate = new Date(userDetails.passportEndDate).getTime();
        const ninetyDaysFromNow = new Date(new Date().setDate(new Date().getDate() + 90));
        if (endDate > ninetyDaysFromNow) {
            return false;
        }
    }
    return true;
};

export const getPassportMemberProfile = (state) => state?.member?.profile;

/**
 * @param {} state
 */
// Get Passport Feature Flag
export const getFlagEnablePassport = (state) => {
    const flags = getFeatureFlags(state);
    if (flags && Object.prototype.hasOwnProperty.call(flags, 'is-product-passport-banner-enabled')) {
        return flags['is-product-passport-banner-enabled'];
    }
    return false;
};
