/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './AddressBook-ActionTypes';

export const loadAddressBook = (pageNumber) => ({
    type: actionTypes.LOAD_ADDRESS_BOOK,
    data: {
        pageNumber,
    },
});

export const saveAddressBook = (addressBookEntries, totalEntries, pageNumber, totalPages) => ({
    type: actionTypes.SAVE_ADDRESS_BOOK,
    data: {
        addressBookEntries,
        totalEntries,
        pageNumber,
        totalPages,
    },
});

export const saveGuestAddressBook = (guestAddressBookEntries) => ({
    type: actionTypes.SAVED_GUEST_ADDRESS_BOOK,
    data: {
        guestAddressBookEntries,
    },
});

export const clearAddressBook = () => ({
    type: actionTypes.CLEAR_ADDRESS_BOOK,
});

export const clearGuestAddressBook = () => ({
    type: actionTypes.CLEAR_GUEST_ADDRESS_BOOK,
});

export const savedAddressBook = () => ({
    type: actionTypes.SAVED_ADDRESS_BOOK,
});

export const proccessAddressBookCompleted = () => ({
    type: actionTypes.PROCESS_ADDRESS_BOOK_COMPLETED,
});

export const addAddressBook = (addressBookEntry) => ({
    type: actionTypes.ADD_ADDRESS_BOOK,
    data: {
        addressBookEntry,
    },
});

export const editAddressBook = (addressBookEntry) => ({
    type: actionTypes.EDIT_ADDRESS_BOOK,
    data: {
        addressBookEntry,
    },
});

export const removeAddressBook = (addressBookEntryId) => ({
    type: actionTypes.REMOVE_ADDRESS_BOOK,
    data: {
        addressBookEntryId,
    },
});

export const loadBillingAddress = () => ({
    type: actionTypes.LOAD_BILLING_ADDRESS,
});

export const saveBillingAddress = (billingAddress) => ({
    type: actionTypes.SAVE_BILLING_ADDRESS,
    data: {
        billingAddress,
    },
});

export const savedBillingAddress = () => ({
    type: actionTypes.SAVED_BILLING_ADDRESS,
});
