/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import { createSelector } from 'reselect';

const getMfeCheckout = (state) => state?.mfeCheckout;

export const getFeatureFlags = (state) => {
    const mfeCheckout = getMfeCheckout(state);

    return mfeCheckout?.app?.featureFlags || {};
};

export const getBrandConfig = (state) => {
    const mfeCheckout = getMfeCheckout(state);

    return mfeCheckout?.app?.config || {};
};

export const getCheckoutAppConfigLoaded = (state) => {
    const flags = getFeatureFlags(state);
    const config = getBrandConfig(state);

    if (Object.keys(flags).length > 0 && Object.keys(config).length > 0) {
        return true;
    }

    return false;
};

/**
 * Returns feature flags based on sessionKey used for checkout features flags
 * selector should be used for checkout feature in container that require A/B test
 */
export const getCheckoutFeatureFlags = (state) => state?.checkout?.app?.featureFlags || {};

/**
 * Returns feature flag based on sessionKey used for checkout features flags
 * selector should be used for checkout feature in container that require A/B test
 */
export const getCheckoutFeatureFlag = (selector) => createSelector(
    [getCheckoutFeatureFlags],
    (featureFlags) => {
        if (Array.isArray(selector)) {
            let result = featureFlags;
            // eslint-disable-next-line consistent-return
            selector.forEach((propertyName) => {
                // eslint-disable-next-line no-prototype-builtins
                if (!result?.hasOwnProperty || !result?.hasOwnProperty(propertyName)) {
                    return false;
                }
                result = result[propertyName];
            });

            return result;
        }

        if (featureFlags) {
            return featureFlags[selector] ?? false;
        }

        return false;
    },
);

export const getActiveABTests = (state) => state.ui.abTesting;
export const getActiveABTest = (testName) => (state) => state?.ui?.abTesting?.[testName] || '';
