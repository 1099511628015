/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    makeStyles, IconButton,
} from '@material-ui/core';
import { withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    object,
} from 'prop-types';
import { brandSurveyNewTab } from '../MultiBrandChatController/utils/qualtricsScripts';
import { getBrand } from '../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getFeatureFlags } from '../../../state/ducks/App/ducks/Config/Config-Selectors';

const useStyles = makeStyles((theme) => ({
    openButton: {
        zIndex: '1001',
        position: 'fixed',
        bottom: 50,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        border: `solid ${theme.palette.cms?.primary} 1px`,
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        width: '35px !important',
        height: '112px !important',
        '& svg': {
            color: theme.palette.cms?.primary,
            fontSize: '35px',
            width: 'auto',
            display: 'block',
            margin: '0 auto',
        },
        '& button': {
            color: theme.palette.cms?.primary,
            cursor: 'pointer',
            fontSize: '1.7rem',
            backgroundColor: 'white',
            border: 'none',
            height: '110px',
            width: '100%',
        },
        '& path': {
            fill: theme.palette.cms?.primary,
        },
    },
    feedBackButtonTop: {
        borderTop: 'none !important',
        backgroundColor: 'unset !important',
    },
    feedbackLabel: {
        webkitTransform: 'rotate(180deg)',
        transform: 'rotate(180deg)',
        fontSize: '14px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        webkitWritingMode: 'vertical-rl',
        writingMode: 'vertical-rl',
        padding: '5px',
        whiteSpace: 'nowrap',
    },
}));

const MobileSurveyButon = ({ brand, flags }) => {
    const classes = useStyles();
    // shape = { desktop: {feedback: [], chat:[]}, mobile: {feedback: [], chat: []} }
    const restrictedPages = flags['chat-and-survey-page-restrictions']?.['mobile']?.['feedback'] || [];
    const currentPath = useLocation()?.pathname || '';
    return (
        restrictedPages?.includes(currentPath)
            ? null
            : (
                <div data-testid="AigoChatWidgetWrapperV2">
                    <div className={`${classes.openButton}`}>
                        <IconButton
                            aria-hidden="true"
                            tabIndex="-1"
                            className={`${classes.feedBackButton} ${classes.feedBackButtonTop}`}
                            onClick={() => {
                                brandSurveyNewTab(brand.code);
                            }}
                        >
                            <span className={classes.feedbackLabel}>[+] FEEDBACK</span>

                        </IconButton>
                    </div>
                </div>
            )
    );
};

MobileSurveyButon.propTypes = {
    brand: object.isRequired,
    flags: object.isRequired,
};
const mapStateToProps = (state) => ({
    brand: getBrand(state),
    flags: getFeatureFlags(state),
});

export default connect(mapStateToProps)(withRouter(MobileSurveyButon));
