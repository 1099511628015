/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actions from './Order-Actions';

const initialState = {
    cartCount: 0,
    orderId: '',
    expressCheckout: false,
};

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.loadCartCount().type:
            return { ...state, cartCount: action.data.cartCount };
        case actions.loadOrderId().type:
            return { ...state, orderId: action.data[0] };
        case actions.clearOrderId().type:
            return { ...state, orderId: '' };
        case actions.loadExpressCheckout().type:
            return { ...state, expressCheckout: true };
        case actions.setLastOrdered().type:
            return { ...state, lastPlacedOrder: action.payload };
        case actions.setAllOrdered().type:
            return { ...state, allPlacedOrder: action.payload };
        default:
            return state;
    }
};

export default orderReducer;
