/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const addPassportBundleItem = (currentState, sku) => {
    const { passportBundleItemSkus } = { ...currentState };
    if (passportBundleItemSkus[sku]) {
        passportBundleItemSkus[sku] += 1;
    } else {
        passportBundleItemSkus[sku] = 1;
    }
    return {
        passportBundleItemCurrentlyInCart: true,
        passportBundleItemSkus,
    };
};

export const removePassportBundleItem = (currentState, sku) => {
    const { passportBundleItemSkus } = { ...currentState };
    let isPassportBundleItemInCart = true;
    if (passportBundleItemSkus[sku] > 1) {
        passportBundleItemSkus[sku] -= 1;
    } else if (passportBundleItemSkus[sku] === 1) {
        delete passportBundleItemSkus[sku];
    }
    if (Object.keys(passportBundleItemSkus).length === 0) {
        isPassportBundleItemInCart = false;
    }
    return {
        passportBundleItemCurrentlyInCart: isPassportBundleItemInCart,
        passportBundleItemSkus,
    };
};

export default {};
