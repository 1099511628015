/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withRouter } from 'react-router';
import { object, string, bool } from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';
import ReactHtmlParser from 'react-html-parser';
// Redux
import { trackData } from '../../../../helpers/tracking/common/commonTrackingHelpers';
import { getBrand } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getFeatureFlags, getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getActiveABTest } from '../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import getOGImageFallback from './getOGImageFallback';

const GraphqlDesktopSeoHeadTags = ({
    seoData, featureFlags, brand, location: { pathname }, noscript = '', ffIsSmartAppBannerEnabled, iosSmartBannerHide, androidSmartBannerHide,
}) => {
    if (seoData) {
        const {
            enable_index_follow = false, page_title = '', meta_description = '', keywords = '', og = [], seo_searchable = true, canonicalUrl = '', canonical_url = '', canonical = '',
        } = seoData || {};
        // Persist data layer
        trackData({
            seoData,
            action: 'track_seo_data',
        });

        const determinePath = (pathName) => (pathName === '/' ? '' : pathName);

        // IF canonicalUrl exsists on PDP return it
        // ELSE return concatenated string
        const setCanonicalUrl = () => {
            // if cms canonical overwrite
            if (canonical) {
                return canonical;
            }
            // if canonical from api call
            if (canonicalUrl || canonical_url) {
                return canonicalUrl || canonical_url;
            }
            // else use current path
            return `https://www.${brand.domain}.com${determinePath(pathname)}`;
        };

        // IF brand is 18F, BRY, or FBQ temporarily we add pwa.www.brand-name.com
        // ELSE pwa.brand-name.com
        const setRelAlternateUrl = () => {
            if (canonicalUrl) {
                const newUrl = canonicalUrl.split('https://www.')[1];
                return (newUrl.indexOf('1800flowers') !== -1 || newUrl.indexOf('fruitbouquets') !== -1 || newUrl.indexOf('berries') !== -1) ? `pwa.www.${newUrl}` : `pwa.${newUrl}`;
            }
            return (`https://pwa.${brand.domain === '1800flowers' || brand.domain === 'fruitbouquets' || brand.domain === 'berries'
                ? `www.${brand.domain}` // pwa.www.brand-name.com
                : `${brand.domain}`}.com${determinePath(pathname)}` // pwa.brand-name.com
            );
        };

        const canonicalUrlIsFromDifferentBrand = () => {
            if (canonicalUrl && !canonicalUrl?.includes(brand['domain-name'])) {
                return true;
            }
            return false;
        };

        // Copy so we can modify
        const newOg = og && og.slice ? og.slice() : [];
        // Primary use case is CONTENT/DEPARTMENT pages - look into adding the data at page level
        // add og:url to og array if it does not exist
        if (!newOg?.some((graph) => (graph.property === 'og:url') && (graph.content?.length > 0))) {
            newOg.push({
                property: 'og:url',
                content: setCanonicalUrl(),
            });
        }
        // AB Test for 18F ios and android Smart Banner
        const iosSmartBannerAbTest = brand?.domain === '1800flowers' && iosSmartBannerHide;
        const androidSmartBannerAbTest = brand?.domain === '1800flowers' && androidSmartBannerHide;

        if (!newOg?.some((graph) => (graph.property === 'og:image')  && graph.content?.length > 0)) {
            newOg.push({
                property: 'og:image',
                content: getOGImageFallback(brand.domain),
            });
        }

        return (
            <Helmet>
                {!seo_searchable && enable_index_follow && canonical && <meta name="robots" content="index, follow" /> }
                {page_title && (<title>{ReactHtmlParser(page_title)}</title>)}
                {/* some pages we dont want searchable(toggle in CMS), ONLY display meta if not true */}
                {!seo_searchable &&  !enable_index_follow && <meta name="robots" content="noindex,nofollow" />}
                {canonicalUrlIsFromDifferentBrand() && <meta name="robots" content="noindex,follow" />}
                {keywords && <meta name="keywords" content={keywords} />}
                {meta_description && <meta name="description" content={meta_description} />}
                {brand && pathname && <link rel="canonical" href={setCanonicalUrl()} />}
                {/* TODO: temporary until all brands desktop/mobile are on 4.2 */}
                {featureFlags['is-alternate-url-enabled'] && brand && pathname && <link rel="alternate" media="only screen and (max-width: 640px)" href={setRelAlternateUrl()} />}
                {newOg && newOg.length > 0 && Array.isArray(newOg) && newOg.map((d) => (<meta key={d.property} property={d.property} content={d.content} />))}
                {/* IF brand is 18F, we add App Smart Banner */}
                {!iosSmartBannerAbTest && ffIsSmartAppBannerEnabled && brand && brand['domain-name'] && brand['smart-app-banner-app-id'] && <meta name="apple-itunes-app" content={`app-id=${brand['smart-app-banner-app-id']}`} />}
                {!androidSmartBannerAbTest && ffIsSmartAppBannerEnabled && brand && brand['domain-name'] && brand['smart-app-banner-android-app-id'] && <meta name="google-play-app" content={`app-id=${brand['smart-app-banner-android-app-id']}`} />}
                <noscript>
                    {`${brand.domain === 'florists' ? 'Florists' : '1800flowers'} ${noscript}`}
                </noscript>
            </Helmet>
        );
    }
    return null;
};

GraphqlDesktopSeoHeadTags.propTypes = {
    seoData: object.isRequired,
    brand: object.isRequired,
    location: object.isRequired,
    featureFlags: object.isRequired,
    noscript: string,
    ffIsSmartAppBannerEnabled: bool.isRequired,
    iosSmartBannerHide: bool.isRequired,
    androidSmartBannerHide: bool.isRequired,
};
GraphqlDesktopSeoHeadTags.defaultProps = {
    noscript: '',
};
const mapStateToProps = (state) => ({
    brand: getBrand(state),
    featureFlags: getFeatureFlags(state),
    ffIsSmartAppBannerEnabled: getFeatureFlag('is-smart-app-banner-enabled')(state),
    iosSmartBannerHide: getActiveABTest('iosSmartBannerHide')(state),
    androidSmartBannerHide: getActiveABTest('androidSmartBannerHide')(state),
});

const enhance = compose(
    withRouter,
    connect(mapStateToProps),
);

export default enhance(GraphqlDesktopSeoHeadTags);
