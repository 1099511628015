/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { getCurrentViewOrderItem } from '../../../Cart/Cart-Selectors';

const GIFT_MESSAGE_LEN = 180;

export const getGiftMessage = (state) => {
    const recipients = getCurrentViewOrderItem(state);
    if (recipients && recipients.cartItems) {
        const orderItem = recipients.cartItems[0];
        if (orderItem.greetingMessage?.greetingMessage) {
            return orderItem.greetingMessage.greetingMessage;
        }
        return 'No Message';
    }
    return 'No Message';
};

export const getGiftMessageAttribute = (state) => ({ ...state }.recipient);

export const getGiftMessageInputText = (state) => getGiftMessageAttribute(state).giftMessage.message;

export const getGiftMessageAuthor = (state) => getGiftMessageAttribute(state).giftMessage.author;

export const getGiftMessageField = (state) => getGiftMessageAttribute(state).giftMessage;

export const getGiftMessageFormStatus = (state) => getGiftMessageAttribute(state).isFormValid;

export const getGiftMessageLen = (state) => {
    const message = getGiftMessageInputText(state);
    const msgLen = GIFT_MESSAGE_LEN - message.length;
    return msgLen;
};

export const getGiftMessageOccasion = (state) => state.giftMessage.occasion;
