/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpLogger from 'mbp-logger';
import restClient from '../../app/helpers/restClient';

export const subscriptionRecipientUpdateApi = async (
    wcEnv,
    token,
    payload,
    closeForm,
) => {
    const resourcePage = 'retention-subscription/passport/subscription/change-recipient-address';
    const response = await restClient.putFacade(wcEnv, resourcePage, token, payload);
    delete response.headers;
    delete response.request;
    delete response.config;
    const error = {};
    try {
        const updatedMessage = response?.data?.result;
        const responseStatus = response?.status;
        if (responseStatus === 200) {
            error.message = '';
            closeForm();
        } else {
            error.message = updatedMessage;
        }
    } catch (err) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            jsError: err,
            message: 'Subscription Recipient Failed',
        });
        error.message = err.message;
    }
    return { response, error };
};

export default {};
