/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import cookies from './common/storage/cookies';
import { GRAPHQL_ENV } from '../gql/index';

const gaProductionPropertyIds = {
    '1800flowers': '864GQ5CPBS',
    harryanddavid: 'BMECK137YG',
    berries: 'M1GMN7SFVT',
    '1800baskets': 'PXX4N94SCR',
    simplychocolate: 'DVD22191SL',
    fruitbouquets: 'B17GNWBXDP',
    cheryls: '4E4GQYVGPW',
    thepopcornfactory: 'M1D8K6WQPE',
    wolfermans: 'T5R2CQ77B7',
    vitalchoice: 'TLNHJ0K34L',
    florists: 'X4QS7RBEEW',
    plants: 'TPFNJ7V51Q',
};

const gaStagingPropertyIds = {
    '1800flowers': 'BD06RL8CDB',
    harryanddavid: '0GNVDNX9PJ',
    personalizationmall: '',
    berries: 'PHSWXWWH64',
    '1800baskets': 'Z4JRQ3TT3P',
    simplychocolate: 'LVRJH7NZBP',
    fruitbouquets: 'RPDX2L36GC',
    cheryls: 'X5YGSW56J0',
    thepopcornfactory: 'JNDBQKR8CL',
    wolfermans: '1QB3Y1WTR3',
    vitalchoice: 'N32E0C2T9L',
    florists: 'ZHHL04PPMV',
    plants: 'R63V37RG0N',
};

class GoogleAnalyticsInterface {
    constructor() {
        this.brand = null;
        this.env = null;

        if (typeof window !== 'undefined') {
            this.brand = window.location.hostname?.split('.')?.[1] || '1800flowers';
            this.env = GRAPHQL_ENV;
        }
    }

    getGAClientAndSessionId = () => {
        const brand = this.brand;
        const env = this.env;
        const isProd = env === 'uat2' || env === 'production';
        const propertyId = isProd ? gaProductionPropertyIds[brand] : gaStagingPropertyIds[brand];

        const sid = cookies.get(`_ga_${propertyId}`);
        const cid = cookies.get('_ga');
        const gaSessionId = sid?.split('.')?.[2] || null;
        const gaClientId = cid?.slice(6) || null;

        return { gaSessionId, gaClientId };
    }
}

export default GoogleAnalyticsInterface;
