/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const locationTypes = {

    default: [
        { id: 'Residence', name: 'Residence' },
        { id: 'Business', name: 'Business' },
        { id: 'P.O.Box', name: 'P.O.Box' },
        { id: 'APO/FPO', name: 'APO/FPO' },
    ],
    HD: [
        { id: 'Residence', name: 'Residence' },
        { id: 'Business', name: 'Business' },
        { id: 'P.O.Box', name: 'P.O.Box' },
        { id: 'APO/FPO', name: 'APO/FPO' },
    ],
    '18F': [
        { id: 'Apartment', name: 'Apartment' },
        { id: 'Residence', name: 'Residence' },
        { id: 'Business', name: 'Business' },
        { id: 'Church', name: 'Church' },
        { id: 'Funeral home', name: 'Funeral Home' },
        { id: 'Hospital', name: 'Hospital' },
        { id: 'School', name: 'School' },
    ],
    FBQ: [
        { id: 'Apartment', name: 'Apartment' },
        { id: 'Residence', name: 'Residence' },
        { id: 'Business', name: 'Business' },
        { id: 'Church', name: 'Church' },
        { id: 'Funeral home', name: 'Funeral Home' },
        { id: 'Hospital', name: 'Hospital' },
        { id: 'School', name: 'School' },
    ],
    SCH: [
        { id: 'Residence', name: 'Residence' },
        { id: 'Business', name: 'Business' },
        { id: 'Funeral home', name: 'Funeral Home' },
        { id: 'Hospital', name: 'Hospital' },
        { id: 'School', name: 'School' },
        { id: 'Church', name: 'Church' },
        { id: 'Apartment', name: 'Apartment' },
    ],
    '18B': [
        { id: 'Residence', name: 'Residence' },
        { id: 'Business', name: 'Business' },
        { id: 'Funeral home', name: 'Funeral Home' },
        { id: 'Hospital', name: 'Hospital' },
        { id: 'School', name: 'School' },
        { id: 'Church', name: 'Church' },
        { id: 'Apartment', name: 'Apartment' },
    ],
    BRY: [
        { id: 'Residence', name: 'Residence' },
        { id: 'Business', name: 'Business' },
        { id: 'Funeral home', name: 'Funeral Home' },
        { id: 'Hospital', name: 'Hospital' },
        { id: 'School', name: 'School' },
        { id: 'Church', name: 'Church' },
        { id: 'Apartment', name: 'Apartment' },
    ],
};

export default locationTypes;
