/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const GET_ERROR = 'mbp-checkout/GET_ERROR';
export const LOAD_ERROR = 'mbp-checkout/LOAD_ERROR';
export const CLEAR_ERRORS = 'mbp-checkout/CLEAR_ERRORS';
export const LOGGED_ERROR_KEYS = 'mbp-checkout/LOGGED_ERROR_KEYS';

export const CLEAR_ORDER_ITEM_ERRORS = 'mbp-checkout/CLEAR_ORDER_ITEM_ERRORS';
export const PROCESS_ORDER_ITEM_ERROR = 'mbp-checkout/PROCESS_ORDER_ITEM_ERROR';
export const PROCESS_FEATURES_ERROR = 'mbp-checkout/PROCESS_FEATURES_ERROR';
export const SET_ORDER_ITEM_ERROR = 'mbp-checkout/SET_ORDER_ITEM_ERROR';

export const CLEAR_ALL_ERRORS = 'mbp-checkout/CLEAR_ALL_ERRORS';
