/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const CALCULATE_SHIPPING_ACTION = 'mbp-checkout/CALCULATE/SHIPPING';
const CALCULATE_SHIPPING_COMPLETE = 'mbp-checkout/CALCULATE/SHIPPING/COMPLETE';
const PERSIST_ATTRIBUTION_ACTION = 'mbp-checkout/PERSIST/ATTRIBUTION';
const SET_SUBMIT_PAYMENT_BUTTONT_TYPE = 'SET_SUBMIT_PAYMENT_BUTTONT_TYPE';
const SAVE_BANNER_PROCESS_CHECK_COMPLETED = 'mbp-checkout/SAVE_BANNER_PROCESS_CHECK_COMPLETED';
const SAVE_BANNER_PROCESS_CHECK_FAILED = 'mbp-checkout/SAVE_BANNER_PROCESS_CHECK_FAILED';
const LOAD_ORDER_DATA = 'mbp-checkout/LOAD/CART';
const CHECK_APPLE_PAY_ENABLED = 'mbp-checkout/CHECK/APPLE/PAY/ENABLED';
const APP_PROCESS_PAGE = 'mbp-checkout/APP_PROCESS_PAGE';
const VALIDATESHOPCART = 'mbp-checkout/PROCESS/EXPRESS/CHECKOUT/VALIDATESHOPCART';
const HANDLE_ADD_EXTRA_ITEM = 'mbp-checkout/HANDLE/ADD/EXTRA/ITEM';
const SAVE_EXTRA_ATTRIBUTES_COMPLETED = 'mbp-checkout/SAVE_EXTRA_ATTRIBUTES_COMPLETED';

export default {
    CALCULATE_SHIPPING_ACTION,
    CALCULATE_SHIPPING_COMPLETE,
    LOAD_ORDER_DATA,
    PERSIST_ATTRIBUTION_ACTION,
    SET_SUBMIT_PAYMENT_BUTTONT_TYPE,
    SAVE_BANNER_PROCESS_CHECK_COMPLETED,
    SAVE_BANNER_PROCESS_CHECK_FAILED,
    CHECK_APPLE_PAY_ENABLED,
    APP_PROCESS_PAGE,
    VALIDATESHOPCART,
    HANDLE_ADD_EXTRA_ITEM,
    SAVE_EXTRA_ATTRIBUTES_COMPLETED,
};
