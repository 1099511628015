/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { END } from 'redux-saga';
import {
    take, fork, call, select, put, takeLatest,
} from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import mbpLogger from 'mbp-logger';

import memberDucks from '../Member/ducks';
import orderClient from '../../../apis/checkout-apis/orderClient';
import * as checkoutActions from '../Checkout/ducks/Checkout/Checkout-Actions';

import { errorActions, errorOperations } from '../Common/ducks/Error';
import { showLoadingMessageActions } from '../Common/ducks/ShowLoadingMessage';
import { retrieveCart } from '../Cart/Cart-Operations';
import {
    placeOrder,
    updateShowOrderConfirmation,
    applePayOrderSuccess,
    applePayOrderValidateMerchantSuccess,
    clickStreamOrderComplete,
} from './Payment-Actions';

import * as commonSelectors from '../Common/Common-Selectors';
import { clearGooglePayClientId } from './plugins/GooglePay/GooglePay-Actions';
import { getGooglePayTokenizedCard } from './plugins/GooglePay/GooglePay-Selectors';
import { getBrand } from '../App/ducks/Brand/Brand-Selectors';
import { removePersistPageDataFromStorage } from '../../../app/helpers/EnterpriseID/CustomerDataPersistance/customerDataPersistance';
import { orderCompleteAttributionData } from '../../../app/helpers/attribution/attributionHelpers';
import { postOrderCompleteAttributionData } from '../Checkout/ducks/Payment/Payment-Actions';

import enterpriseIdDuck from '../Member/ducks/EnterpriseId/EnterpriseId-Operations';
import { billingClearUpdateFields, billingUpdateFormValue, updateCheckBillingAddressSameForm } from '../Checkout/ducks/Payment/ducks/BillingAddressForm/BillingAddressForm-Actions';
import { paymentClearUpdateFields } from '../Checkout/ducks/Payment/ducks/PaymentForm/PaymentForm-Actions';
import { getProfileInfo } from '../Member/ducks/Common/Common-Selectors';
import { loadAddressBook, loadBillingAddress } from '../Member/ducks/AddressBook/AddressBook-Actions';
import { promotionActions } from '../Checkout/ducks/Payment/ducks/Promotion';
import { processOrderItemError } from '../Checkout/ducks/Common/ducks/Error/Error-Operations';
import { getFeatureFlags } from '../App/ducks/Config/Config-Selectors';
import { unsubscribeEmail, unsubscribeOrderStatus, unsubscribeTextmsg } from '../Checkout/ducks/Payment/ducks/Subscription/Subscription-Action';
import { cartStatusCompleteList } from '../../../app/helpers/common/helper';
import { trackEvent } from '../TagManager/ducks/TagManager/TagManager-Actions';

const {
    auth: {
        authOperations,
    },
} = memberDucks;

const { checkJWT } = authOperations.workers;

const parseBannerCookie = () => {
    const cookies = new Cookies();
    const bCookie = cookies.get('banner');

    if (bCookie) {
        const bAttr = JSON.parse(JSON.stringify(bCookie));

        mbpLogger.logInfo({
            bCookie,
            function: 'parseBannerCookie',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui :: checkout',
            message: 'Parsed banner cookie for order upload',
        });

        return bAttr;
    }
    return '';
};

function* persistBanner() {
    const logOrderId = yield select(commonSelectors.getOrderId);
    const featureFlags = yield select(getFeatureFlags);

    try {
        const bannerObj = parseBannerCookie();
        if (bannerObj?.c) {
            if (featureFlags['is-checkout-monitoring-enabled']) {
                mbpLogger.logError({
                    appName: process.env.npm_package_name,
                    module: 'PlaceOrder-Operations.js',
                    function: 'persistBanner',
                    errorMessage: bannerObj,
                    message: `Call Persist Banner - ${logOrderId}`,
                });
            }
            yield put(checkoutActions.saveBannerToOrder(bannerObj.c, bannerObj.l)); // c = bannerCode, l= lsid
            yield take(checkoutActions.saveBannerProcessCheckCompleted().type);
        }
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'PlaceOrder-Operations.js',
            function: 'persistBanner',
            jsError: ex,
            errorMessage: ex.response,
            message: `PersistBanner Failed - ${logOrderId} - Error occured while parsing banner`,
        });
    }
}

function* updateUserBillingAddress() {
    const billingForm = yield select(commonSelectors.getBillingInfo);
    const memberInfo = yield select(getProfileInfo);
    try {
        mbpLogger.logInfo({
            function: 'updateUserBillingAddress',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: 'updateUserBillingAddress - Start',
            memberInfoContact: memberInfo.contactId,
        });

        if (memberInfo?.contactId && billingForm.defaultBilling && ['US', 'CA'].includes(billingForm.country)) {
            const jwtToken = yield call(checkJWT);
            const contactId = memberInfo?.contactId;
            const newUserBillingAddress = {
                addressEntry: {
                    FirstName: billingForm.firstName || '',
                    LastName: billingForm.lastName || '',
                    EmployerName: billingForm.organizationName || '',
                    NickName: '',
                    RelationShip: '0',
                    PhoneNumber: billingForm.phone || '',
                },
                address: {
                    AddressType: billingForm.locationType || 'Residence',
                    AddressLineOne: billingForm.address1 || '',
                    AddressLineTwo: billingForm.address2 || '',
                    City: billingForm.city || '',
                    StateProvince: billingForm.state || '',
                    PostalCode: billingForm.zipCode || '',
                    Country: billingForm.country || '',
                    IsBillingAddress: true,
                },
            };

            const userBillingResponse = yield call(
                orderClient.updateUserBilling,
                {},
                jwtToken,
                contactId,
                newUserBillingAddress,
            );

            if (userBillingResponse.data && userBillingResponse.data.Result) {
                yield put(loadAddressBook());
                yield put(loadBillingAddress());
            }

            mbpLogger.logInfo({
                function: 'updateUserBilling',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                newUserBillingAddress,
                contactId,
                userBillingResponse,
                message: 'call updateBillingResponse action',
            });
        }
    } catch (ex) {
        const contactId = memberInfo?.contactId;
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'updateUserBillingAddress',
            jsError: ex,
            message: 'updateUserBillingAddress Failed - Error occured while save billing address',
            contactId,
        });
    }
}

const removeMaskForPhoneNumber = (number) => {
    let strNumber = number.toString().trim();
    strNumber = strNumber.replace(/\s/g, '').replace(/[^0-9+]/g, '');
    if (strNumber.indexOf('+') === 0 && strNumber.indexOf('1') === 1) {
        strNumber = strNumber.substring(2, strNumber.length);
    } else if (strNumber.indexOf('+') >= 0) {
        strNumber = strNumber.replace(/\s/g, '').replace(/[^0-9]/g, '');
    }
    return strNumber;
};

function* buildConfigObj({
    disablePaymentFields, params, googlePayment,
}) {
    let configObj = {};
    const logOrderId = yield select(commonSelectors.getOrderId);
    const flagState = yield select(getFeatureFlags);
    try {
        if (flagState['is-checkout-monitoring-enabled']) {
            mbpLogger.logError({
                function: 'buildConfigObj',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                message: 'buildConfigObj - Start',
                orderId: logOrderId,
            });
        }

        const paymentMethod = yield select(commonSelectors.getPaymentMethod);
        const jwtToken = yield call(checkJWT);

        const billingInfo = yield select(commonSelectors.getBillingInfo);

        configObj = {
            orderId: yield select(commonSelectors.getOrderId),
            jwtToken,
            billingInfo: googlePayment?.billingAddress || billingInfo,
            orderTotal: yield select(commonSelectors.getAmount),
            user: yield select(commonSelectors.getSubscription),
        };

        // Add Passport
        if (yield select(commonSelectors.getPassportSubscriptionStatus)) {
            configObj.orderTotal.passportTermsAndConditionChecked = 'Y';
        }

        // Unmask Phone Number
        if (configObj.billingInfo.phone) {
            configObj.billingInfo.phone = removeMaskForPhoneNumber(configObj.billingInfo.phone);
        }

        // Unmask Mobile Phone
        if (configObj.billingInfo.mobilePhone) {
            configObj.billingInfo.mobilePhone = removeMaskForPhoneNumber(configObj.billingInfo.mobilePhone);
        }

        if (flagState['is-checkout-monitoring-enabled']) {
            mbpLogger.logError({
                function: 'buildConfigObj',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                message: `Payment Method - ${paymentMethod.id}`,
                orderId: logOrderId,
            });
        }

        // Add params to configObj
        configObj.params = params || {};
        if (disablePaymentFields === false) {
            const paymentInfo = yield select(commonSelectors.getPaymentInfo);

            configObj.paymentInfo = { ...paymentInfo };

            if (paymentMethod.id === 'GooglePay') {
                configObj.paymentInfo = {};
                configObj.paymentInfo.paymentMethod = 'GooglePay';

                const tokenizedGooglePayCard = yield select(getGooglePayTokenizedCard);
                if (googlePayment?.isTokenizedGooglePayCard && tokenizedGooglePayCard) {
                    configObj.paymentInfo.accountNumber = tokenizedGooglePayCard;
                    configObj.paymentInfo.isCardTokenized = true;
                } else if (googlePayment?.nonce) {
                    configObj.paymentInfo.accountNumber = googlePayment.nonce;
                }

                if (googlePayment?.paymentCardType) {
                    configObj.paymentInfo.brand = googlePayment.paymentCardType;
                    configObj.paymentInfo.attributes = [{
                        attributeName: 'lastFourDigits',
                        attributeValue: googlePayment.cardlastFourDigit,
                    }];
                }
            }
        }
        if (flagState['is-checkout-monitoring-enabled']) {
            mbpLogger.logError({
                function: 'buildConfigObj',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                message: 'buildConfigObj - End - payload config obj prepared',
                orderId: logOrderId,
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'buildConfigObj',
            jsError: ex,
            message: 'buildConfigObj Failed',
            orderId: logOrderId,
        });
    }

    return configObj;
}

// TODO: Move entire Place Order for Microfrontend Place Order Functionality
function* onPlaceOrder(action) {
    const logOrderId = yield select(commonSelectors.getOrderId);
    const flagState = yield select(getFeatureFlags);

    try {
        const {
            history, source,
            disablePaymentFields, params,
            googlePayment,
            recaptcha,
        } = action.payload;
        if (flagState['is-checkout-monitoring-enabled']) {
            mbpLogger.logError({
                function: 'onPlaceOrder',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                message: 'onPlaceOrder | START',
                orderId: logOrderId,
            });
        }

        // If the banner exist in the cookie.
        yield call(persistBanner);

        // Update user billing adddress if it is login.
        yield call(updateUserBillingAddress);

        // Associate EnterpriseId with guest Email address
        if (flagState['is-enterprise-id-update-enabled']) {
            yield call(enterpriseIdDuck.workers.workerSagaUpdateEnterpriseId);
        }

        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        yield put(errorActions.clearErrors({
            errType: 'paymentClearAllFields',
            field: 'error',
        }));

        const configObj = yield call(buildConfigObj, {
            disablePaymentFields,
            params,
            googlePayment,
        });

        if (source && configObj.params) {
            configObj.params.sourceApp = source;
        }

        if (flagState['is-checkout-monitoring-enabled']) {
            mbpLogger.logError({
                function: 'onPlaceOrder',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                message: 'call - placeOrder API',
                orderId: logOrderId,
            });
        }
        // Place Order API Call
        const placeOrderResponse = yield call(
            orderClient.placeOrder,
            {},
            configObj.jwtToken,
            configObj.orderId,
            configObj.params,
            configObj.billingInfo,
            configObj.paymentInfo,
            configObj.orderTotal,
            configObj.user,
            recaptcha,
        );

        if (flagState['is-checkout-monitoring-enabled']) {
            mbpLogger.logError({
                function: 'onPlaceOrder',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                message: 'placeOrder API Response',
                orderId: logOrderId,
            });
        }

        if (placeOrderResponse.data.successFlag === true || placeOrderResponse.data.successFlag === 'true') {
            if (source === 'PWA-DESKTOP' || source === 'PWA' || source === 'SPC') {
                if (googlePayment.isCheckout) {
                    yield put(paymentClearUpdateFields());
                    yield put(billingClearUpdateFields());
                    yield put(updateCheckBillingAddressSameForm(false));
                }
                yield put(clearGooglePayClientId());
            }
            // Get Cart
            yield call(retrieveCart);

            const orderStatus = yield select(commonSelectors.getOrderStatus);
            if (cartStatusCompleteList.includes(orderStatus)) {
                const brand = yield select(getBrand);
                yield put(clickStreamOrderComplete(configObj.paymentInfo));
                // clear banner codes attemps when an order is completed
                yield put(promotionActions.setBannerCodesAttempted([]));
                yield put(updateShowOrderConfirmation());

                yield put(unsubscribeOrderStatus());
                yield put(unsubscribeTextmsg());
                yield put(unsubscribeEmail());

                // Delete persistPageData from storage once user has purchased what they were looking for
                if (flagState?.['is-personalization-recently-viewed-products-enabled']) {
                    yield call(removePersistPageDataFromStorage);
                }

                if (history && (source === 'PWA-DESKTOP' || source === 'PWA')) {
                    // send attribution data to events api for reporting
                    const attributionData = yield call(orderCompleteAttributionData, configObj.orderId, configObj.orderTotal, brand?.code, flagState);
                    if (attributionData) {
                        yield put(postOrderCompleteAttributionData(attributionData));
                    }

                    history.push(`/checkout/order-confirmation/${configObj.orderId}`);
                }
            }
        } else {
            yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
            throw new Error(`${placeOrderResponse.data}`);
        }
    } catch (ex) {
        mbpLogger.logError({
            function: 'onPlaceOrder',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: 'Failed onPlaceOrder',
            jsError: ex,
            orderId: logOrderId,
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'payment');
        yield fork(processOrderItemError, {
            data: {
                orderItemId: logOrderId,
                errorFeatureType: 'recipient',
                errorValues: ex.response?.data,
            },
        });
        yield call(errorOperations.getError, ex, 'payment');
        const IsPaymentHasError = yield select(commonSelectors.getIsPaymentHasError);
        const isShippingHasErrors = yield select(commonSelectors.getIsRecipientHasError);
        const { history } = action.payload;

        // Let the user in review page if the issue is in the product item
        // other wise we sent the user back to payment page
        if (IsPaymentHasError && !isShippingHasErrors) {
            history.push(`/checkout/payment/${logOrderId}`);
        }
    }
}

function* onApplePayOrderSuccess(action) {
    const { history, email } = action.payload;
    try {
        yield put(trackEvent({
            eventCategory: 'Checkout',
            eventAction: 'Track Event',
            eventLabel: 'Successful Purchase - ApplePay',
        }));

        yield call(retrieveCart);

        const orderId = yield select(commonSelectors.getOrderId);
        const flagState = yield select(getFeatureFlags);

        // We call the code to add email for enterprise id
        if (email) {
            yield put(billingUpdateFormValue({
                target: {
                    name: 'email',
                    value: email,
                },
            }));

            // Associate EnterpriseId with guest Email address
            if (flagState['is-enterprise-id-update-enabled']) {
                yield call(enterpriseIdDuck.workers.workerSagaUpdateEnterpriseId);
            }
        }

        yield put(clickStreamOrderComplete({ paymentMethod: 'applepay' }));

        yield call(history.push, `/checkout/order-confirmation/${orderId}`);
    } catch (ex) {
        console.log('error applepay', ex.response);
        mbpLogger.logError({
            function: 'onApplePayOrderSuccess',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: 'Failed - onApplePayOrderSuccess',
            jsError: ex,
        });
    }
}

function* onApplePayMerchantSuccess() {
    try {
        // If the banner exist in the cookie.
        yield call(persistBanner);
    } catch (ex) {
        mbpLogger.logError({
            function: 'onApplePayMerchantSuccess',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: 'Failed - onApplePayMerchantSuccess',
            jsError: ex,
        });
    }
}

function* placeOrderWatcher() {
    let action = yield take(placeOrder().type);
    while (action !== END) {
        yield call(onPlaceOrder, action);
        action = yield take(placeOrder().type);
    }
}

function* watcherApplePayOrderSuccess() {
    yield takeLatest(applePayOrderSuccess().type, onApplePayOrderSuccess);
}

function* watcherApplePayValidateMerchantSuccess() {
    yield takeLatest(applePayOrderValidateMerchantSuccess().type, onApplePayMerchantSuccess);
}

// Payment Plugins
// Watcher Sagas
const watchers = [
    fork(placeOrderWatcher),
    fork(watcherApplePayOrderSuccess),
    fork(watcherApplePayValidateMerchantSuccess),
];

export {
    watchers,
    onPlaceOrder,
};
