/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './AddressBook-ActionTypes';
import initialState from '../../initialState';

const addressBookReducer = (state = initialState.addressBook, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_ADDRESS_BOOK:
            return {
                ...state,
                addressBookEntries: [],
                guestAddressBookEntries: [],
                billingAddress: [],
                totalEntries: 0,
                pageNumber: 0,
                totalPages: 0,
            };
        case actionTypes.CLEAR_GUEST_ADDRESS_BOOK:
            return {
                ...state,
                guestAddressBookEntries: [],
            };
        case actionTypes.SAVE_ADDRESS_BOOK:
            return {
                ...state,
                addressBookEntries: action.data.addressBookEntries,
                totalEntries: action.data.totalEntries,
                pageNumber: action.data.pageNumber,
                totalPages: action.data.totalPages,
            };
        case actionTypes.SAVE_BILLING_ADDRESS:
            return {
                ...state,
                billingAddress: action.data.billingAddress,
            };
        case actionTypes.SAVED_GUEST_ADDRESS_BOOK:
            return {
                ...state,
                guestAddressBookEntries: [...state.guestAddressBookEntries.slice(0, 4), action.data.guestAddressBookEntries],
            };
        default:
            return state;
    }
};

export default addressBookReducer;
