/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './Error-ActionTypes';

export const clearErrors = (clear) => ({
    type: actionTypes.CLEAR_ERRORS,
    data: clear,
});

export const getError = (error, errorKey) => ({
    type: actionTypes.GET_ERROR,
    errorKey,
    data: error,
});

export const loadError = (error, errorKey) => ({
    type: actionTypes.LOAD_ERROR,
    errorKey,
    data: error,
});

export const loggedErrorKeys = (errorKeys) => ({
    type: actionTypes.LOGGED_ERROR_KEYS,
    errorKeys,
});

// +++++++++++++++++++++++++++++
// Enterprise Universal checkout
// +++++++++++++++++++++++++++++

export const processOrderItemError = ({
    errorFeatureType, errorValues, orderItemId,
}) => ({
    type: actionTypes.PROCESS_ORDER_ITEM_ERROR,
    data: {
        errorFeatureType,
        errorValues,
        orderItemId,
    },
});

export const processFeaturesError = ({
    errorFeatureType, errorValues, orderItemId,
}) => ({
    type: actionTypes.PROCESS_FEATURES_ERROR,
    data: {
        errorFeatureType,
        errorValues,
        orderItemId,
    },
});

export const setOrderItemError = (orderItemId, errors) => ({
    type: actionTypes.SET_ORDER_ITEM_ERROR,
    orderItemId,
    errors,
});

export const clearOrderItemErrors = (orderItemId, featureType, clearData) => ({
    type: actionTypes.CLEAR_ORDER_ITEM_ERRORS,
    data: {
        orderItemId,
        featureType,
        clearData,
    },
});

export const clearAllErrors = () => ({
    type: actionTypes.CLEAR_ALL_ERRORS,
});
