/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import {
    put, fork, call, takeEvery, select,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';

import {
    updateCartPmallPersonalizationFailed, updateCartPmallPersonalizationSuccess,
    updateCartPmallPersonalization,
    removePmallItem,
    updatePmallImages,
} from './PmallPersonalization-Actions';
import orderClient from '../../../../../apis/checkout-apis/orderClient';
import { showLoadingMessageActions } from '../ShowLoadingMessage';
import { errorActions, errorOperations } from '../Error';
import { checkJWT } from '../../../Member/ducks/Auth/Auth-Operations';
import { getOrderId } from '../../../Member/ducks/Common/Common-Selectors';
import { retrieveCart } from '../../../Cart/Cart-Operations';
import { prepareRetreiveCart } from '../../../Checkout/ducks/Checkout/Checkout-Operations';

const updatePmallPersonalizationAPI = ({
    wcEnv, jwtToken, orderItemId, orderId, personalization,
}) => (
    orderClient.updateCart(
        wcEnv,
        jwtToken,
        orderItemId,
        null,
        null,
        null,
        orderId,
        personalization,
    )
);

function* updatePmallProduct(action) {
    try {
        // Show Site Loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        // Clear Error
        yield put(errorActions.clearErrors({
            errType: 'giftMessage',
            field: 'error',
        }));
        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: action.data.orderItemId,
        }));

        const jwtToken = yield call(checkJWT);
        const orderId = yield select(getOrderId);

        const personalizationLines = [
            {
                perAttributeId: 'description',
                perAttributeValue: action.data.description,
            },
            {
                perAttributeId: 'previewUrl',
                perAttributeValue: action.data.previewUrl,
            },
        ];

        const personalizationObj = {
            personalizationType: 'EXTERNAL',
            personalizationId: action.data.refnum,
            personalizationLines,
        };

        if (action?.data?.addonCount) {
            personalizationObj.addonCount = action.data.addonCount;
        }

        const configObj = {
            wcEnv: {},
            jwtToken,
            orderItemId: action.data.cartItemId,
            personalization: personalizationObj,
            orderId,
        };

        // Save New personalization data
        yield call(updatePmallPersonalizationAPI, configObj);

        yield put(updatePmallImages());
        yield put(removePmallItem());

        // Prepare Retreive cart
        yield call(prepareRetreiveCart, action.data.pageType);

        yield call(retrieveCart);

        yield call(action.data.onCloseModal);
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield put(updateCartPmallPersonalizationSuccess());
    } catch (ex) {
        yield put(updateCartPmallPersonalizationFailed());

        mbpLogger.logError({
            orderItem: action.data,
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'updatePmallProduct',
            jsError: ex,
            message: 'Failed Update Cart Pmall Personalization Product',
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'pmall');
    }
}

function* updatePmallProductWatcher() {
    yield takeEvery(updateCartPmallPersonalization().type, updatePmallProduct);
}

const watchers = [
    fork(updatePmallProductWatcher),
];

export {
    watchers,
    updatePmallProduct,
};

export default {};
