/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, call, takeEvery, put, select,
} from 'redux-saga/effects';

import mbpLogger from 'mbp-logger';

// Operations
import { retrieveCart } from '../../../Cart/Cart-Operations';
import { checkJWT } from '../../../Checkout/ducks/Global/Global-Operations';
import { showLoadingMessageActions } from '../ShowLoadingMessage';
import * as addDonationItemActions from './Donation-Actions';
import Donation from '../../../../../apis/cart-service-apis/donation';
import { getOrderId } from '../../Common-Selectors';
import { setOrderId } from '../../../Order/Order-Operations';

function* handleFailedToAddDonation(exception) {
    const response = exception?.response || {};
    let addDonationErrorMessage = 'We are sorry, there seems to be a technical issue, please try again';
    const errorMessage = response?.data?.detailedError?.errorMessage || response?.data?.errorMessage;
    if (errorMessage) {
        addDonationErrorMessage = errorMessage;
    }
    yield put(addDonationItemActions.loadAddToDonationError(addDonationErrorMessage));
}

// APIs
function* workerAddDonationItem(action) {
    try {
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        yield put(addDonationItemActions.loadAddToDonationError(''));
        const jwtToken = yield call(checkJWT);
        const orderId = yield select(getOrderId);
        const payload = {
            donationCampaignName: action.data?.title || '',
            productCode: action.data?.sku_code || '',
            customAmount: action.data?.denomination || '',
        };
        const donationResponse = yield call(Donation, {
            jwtToken, payload,
        });
        if (!orderId && donationResponse?.data?.cartId) {
            yield call(setOrderId, {
                data: [donationResponse.data.cartId],
            });
        }
        yield call(retrieveCart);
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-checkout',
            function: 'workerCartServiceDonation',
            error: ex,
            message: 'Error occured while getting donation Item',
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(handleFailedToAddDonation, ex);
    }
}

function* watchAddDonationItem() {
    yield takeEvery(addDonationItemActions.addDonation().type, workerAddDonationItem);
}

const watchers = [
    fork(watchAddDonationItem),
];

export {
    watchers,
};

export default {};
