/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable no-underscore-dangle */
// To debug IE 11 youneed ie11 and stable
// need install the package "react-app-polyfill"
// import "react-app-polyfill/ie11";
// import "react-app-polyfill/stable";

import React from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { loadableReady } from '@loadable/component';
import { ApolloProvider } from '@apollo/client';
import { StylesProvider, createGenerateClassName } from '@material-ui/styles';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import mbpLogger from 'mbp-logger';

import ErrorBoundary from './app/components/ErrorBoundary/ErrorBoundary';
import AppErrorBoundaryUI from './app/components/ErrorBoundary/components/AppErrorBoundaryUI/AppErrorBoundaryUI';
import polyfills from './polyfills';
import App from './app/App';
import configureStore, { configureHistory } from './state/store';
import registerServiceWorker from './registerServiceWorker';
import buildGraphQLClient from './app/gql';
import { AuthProvider } from './state/ducks/Member/ducks/Auth/Plugin/auth';
import StyleLinkContext from './app/components/EnterpriseDesignSystem/StyleLinkContext/StyleLinkContext';

const history = configureHistory();
const store = configureStore(window.__INITIAL_STATE__);
const persistor = persistStore(store);

// Delete initial state object
if (window.__INITIAL_STATE__) {
    delete window.__INITIAL_STATE__;
}

const apolloState = (typeof window !== 'undefined' && window.__APOLLO_STATE__) ? window.__APOLLO_STATE__ : null;

// Delete initial Apollo state object
if (typeof window !== 'undefined' && window.__APOLLO_STATE__) {
    delete window.__APOLLO_STATE__;
}

const { apolloClient } = buildGraphQLClient({
    initialState: apolloState,
});

// Create a new MUI class name generator.
const generateClassName = createGenerateClassName({
    productionPrefix: 'mbp',
});

const root = document.querySelector('#root');

const insertCss = (...styles) => {
    const removeCss = styles.map((style) => style._insertCss());
    return () => removeCss.forEach((dispose) => dispose());
};

// array that holds list of css variable link tags
const linkTagList = new Set();
const insertLinkTag = (s) => linkTagList.add(s);

let RootComp = null;
RootComp = () => (
    <ApolloProvider injectFirst client={apolloClient}>
        <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter history={history}>
                    <ErrorBoundary
                        boundaryName="App Page Error Boundary"
                        fallback={() => <AppErrorBoundaryUI />}
                    >
                        <AuthProvider>
                            <StylesProvider generateClassName={generateClassName}>
                                <StyleContext.Provider value={{ insertCss }}>
                                    <StyleLinkContext.Provider value={{ insertLinkTag }}>
                                        <App
                                            store={store} // For MFE support
                                            apolloClient={apolloClient}
                                        />
                                    </StyleLinkContext.Provider>
                                </StyleContext.Provider>
                            </StylesProvider>
                        </AuthProvider>
                    </ErrorBoundary>
                </BrowserRouter>
            </PersistGate>
        </ReduxProvider>
    </ApolloProvider>
);

const renderApp = () => {
    if (root.hasChildNodes() === true) {
        // save reference to initial html from SSR
        loadableReady(() => {
            hydrate(<RootComp />, root);
            registerServiceWorker();
        });
    } else {
        render(<RootComp />, root);
    }
};

Promise.all(polyfills)
    .then(renderApp)
    .catch((error) => {
        mbpLogger.logError({
            function: 'render app',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            jsError: error,
            message: 'Failed to load polyfills.',
        });
    });
