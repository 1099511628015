/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
/*
'1800flowers': '18F', => SI_efHkCVl2PkEfNkO
harryanddavid: 'HD', SI_8qN5rly9He3I3vU
'1800baskets': '18B', SI_8k9E3CPBZSMptuC
simplychocolate: 'SCH', SI_51NZ9sEsCwt5ZB4
fruitbouquets: 'FBQ', SI_9GfSrQx2AkRFU2O
cheryls: 'CCO', SI_8wRneFIwVhOqLRk
thepopcornfactory: 'TPF', SI_dbaJ9a0AqmLBYDI
wolfermans: 'WLF', SI_emsN9k9BC78c6Mu
stockyards: 'SY', SI_cMf2jAWZfXmT9mm
berries: 'BRY', => SI_eRirOIgruoH7hKS
*/

import Cookies from 'universal-cookie';

const getCookieData = () => {
    const cookies = new Cookies();
    const data = [];

    // Gather cookie data
    const gaId = cookies.get('_ga');
    const NLC = cookies.get('NLC');

    // Assign cookies if they exist
    if (gaId) data.push(`gaId=${gaId}`);
    if (NLC) data.push(`NLC=${NLC}`);

    return data;
};

const getFullstoryData = () => {
    const cookies = new Cookies();
    const data = [];
    if (typeof window !== 'undefined' && window['_fs_namespace']) {
        // fsSessionUrl
        if (typeof window[window['_fs_namespace']].getCurrentSessionURL === 'function') {
            data.push(`fsSessionUrl=${window[window['_fs_namespace']].getCurrentSessionURL()}&integration_src=qualtrics`);
        }
        // fsSession
        if (typeof window[window['_fs_namespace']].getCurrentSession === 'function') {
            data.push(`fsSession=${window[window['_fs_namespace']].getCurrentSession()}`);
        }
        // fsCookie
        if (cookies.get('fs_uid')) {
            data.push(`fsCookie=${cookies.get('fs_uid') || ''}`);
        }
    }
    return data;
};

const getWindowData = () => {
    const data = [];
    if (window.location?.href) data.push(`currentURL=${window.location?.href}`);
    if (window.localStorage?.enterpriseId) data.push(`EID=${window.localStorage.enterpriseId}`);
    if (window.utag?.data?.order_id) data.push(`transactionId=${window.utag.data.order_id}`);
    if (window.utag?.data?.page_type) data.push(`_ga=${window.utag.data.page_type}`);
    return data;
};

const getBrandSurveyParameters = (brand) => {
    // Collect data
    const cookieData = getCookieData();
    const fullStoryData = getFullstoryData();
    const windowData = getWindowData();

    // Assign data
    let params = ['device=Mobile'];
    if (brand) params.push(`brandId=${brand}`);
    if (cookieData.length) params = params.concat(cookieData);
    if (fullStoryData.length) params = params.concat(fullStoryData);
    if (windowData.length) params = params.concat(windowData);

    // Returned formatted queryString parameters
    return `?${params.sort().join('&')}`;
};

export const flowerScript = async (id) => {
    const c = `${id}_container`;
    const o = document.getElementById(c);
    let d = null;
    if (o) {
        o.innerHTML = '';
        d = o;
    } else {
        d = document.createElement('div');
        d.id = c;
    }
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = `https://zn8owaoqv3jxag31i-1800flowers.siteintercept.qualtrics.com/SIE/?Q_SIID=${id}`;

    if (document.body) {
        document.body.appendChild(s);
        document.body.appendChild(d);
    }
};

/**
 * Maps brand to specific survey script intercept id
 * @param {*} queue
 */
export const brandSurveyScript = (brand) => {
    switch (brand) {
        case '18B':
            flowerScript('SI_8k9E3CPBZSMptuC'); // Intercerp 18B
            break;
        case '18F':
            flowerScript('SI_efHkCVl2PkEfNkO'); // Intercerp 18F
            break;
        case 'CCO':
            flowerScript('SI_8wRneFIwVhOqLRk'); // Intercerp CCO
            break;
        case 'FB':
            flowerScript('SI_9GfSrQx2AkRFU2O'); // Intercerp FBQ
            break;
        case 'HD':
            flowerScript('SI_8qN5rly9He3I3vU'); // Intercerp HD
            break;
        case 'BRY':
            flowerScript('SI_eRirOIgruoH7hKS'); // Intercerp BRY
            break;
        case 'SCH':
            flowerScript('SI_51NZ9sEsCwt5ZB4'); // Intercerp SCH
            break;
        case 'TPF':
            flowerScript('SI_dbaJ9a0AqmLBYDI'); // Intercerp TPF
            break;
        case 'WLF':
            flowerScript('SI_emsN9k9BC78c6Mu'); // Intercerp WLF
            break;
        case 'SY':
            flowerScript('SI_cMf2jAWZfXmT9mm'); // Intercerp SY
            break;
        case 'VC':
            flowerScript('SI_85F9KUVYBBSuPhY'); // Intercerp VC
            break;
        default:
            flowerScript('SI_efHkCVl2PkEfNkO');
    }
};

/**
 * Maps brand to specific survey on new tab
 * @param {*} queue
 */
export const brandSurveyNewTab = (brand) => {
    let brandSurveyLink;
    switch (brand) {
        case '18B':
            brandSurveyLink = 'https://1800flowers.co1.qualtrics.com/jfe/form/SV_eaifVuNUmxd8B5X'; // Intercerp 18B
            break;
        case '18F':
            brandSurveyLink = 'https://1800flowers.co1.qualtrics.com/jfe/form/SV_ai6pM3wRhx5aOeF'; // Intercerp 18F
            break;
        case 'CCO':
            brandSurveyLink = 'https://1800flowers.co1.qualtrics.com/jfe/form/SV_9HavOuBmje52h5X'; // Intercerp CCO
            break;
        case 'FB':
            brandSurveyLink = 'https://1800flowers.co1.qualtrics.com/jfe/form/SV_d0ahhJTamjUmtPT'; // Intercerp FBQ
            break;
        case 'HD':
            brandSurveyLink = 'https://1800flowers.co1.qualtrics.com/jfe/form/SV_1OjOzjee5iI1TP7'; // Intercerp HD
            break;
        case 'BRY':
            brandSurveyLink = 'https://1800flowers.co1.qualtrics.com/jfe/form/SV_81yP3c5T67lcSW1'; // Intercerp BRY
            break;
        case 'SCH':
            brandSurveyLink = 'https://1800flowers.co1.qualtrics.com/jfe/form/SV_8oyjuDUjFmtMDoV'; // Intercerp SCH
            break;
        case 'TPF':
            brandSurveyLink = 'https://1800flowers.co1.qualtrics.com/jfe/form/SV_bBXTpwCw3U13OPr'; // Intercerp TPF
            break;
        case 'WLF':
            brandSurveyLink = 'https://1800flowers.co1.qualtrics.com/jfe/form/SV_6s1PD7T8Q4eMGiy'; // Intercerp WLF
            break;
        case 'SY':
            brandSurveyLink = 'https://1800flowers.co1.qualtrics.com/jfe/form/SV_5vuMhNmGmDRiSjQ'; // Intercerp SY
            break;
        case 'VC':
            brandSurveyLink = 'https://1800flowers.co1.qualtrics.com/jfe/form/SV_8qsUKMAirVwoUTk'; // Intercerp VC
            break;
        default:
            brandSurveyLink = 'https://1800flowers.co1.qualtrics.com/jfe/form/SV_ai6pM3wRhx5aOeF';
    }
    const completeSurveyLink = `${brandSurveyLink}${getBrandSurveyParameters(brand)}`;
    window.open(completeSurveyLink, '_blank');
};
