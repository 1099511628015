/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as enterpriseIdActionTypes from './EnterpriseId-ActionTypes';

export const loadEnterpriseIdFromLocalStorage = () => ({
    type: enterpriseIdActionTypes.LOAD_ENTERPRISE_ID_FROM_LOCAL_STORAGE,
});

export const requestEnterpriseId = (params) => ({
    type: enterpriseIdActionTypes.REQUEST_ENTERPRISE_ID,
    params,
});

export const enterpriseIdLoaded = (enterpriseId) => ({
    type: enterpriseIdActionTypes.ENTERPRISE_ID_LOADED,
    data: enterpriseId,
});

export const requestToUpdateEnterpiseId = (params) => ({
    type: enterpriseIdActionTypes.UPDATE_ENTERPRISE_ID,
    params,
});

export const updateOnSignInEnterpriseId = (accessToken) => ({
    type: enterpriseIdActionTypes.UPDATE_ON_SIGN_IN_ENTERPRISE_ID,
    accessToken,
});

export const enterpriseIdMessageLoaded = (enterpriseIdMessage) => ({
    type: enterpriseIdActionTypes.ENTERPRISE_ID_MESSAGE_LOADED,
    data: enterpriseIdMessage,
});

export const enterpriseIdUserSegmentsLoaded = (segments) => ({
    type: enterpriseIdActionTypes.LOAD_ENTERPRISE_USER_SEGMENT,
    data: segments,
});

export const enterpriseIdLoadingError = () => ({
    type: enterpriseIdActionTypes.ENTERPRISE_ID_LOADING_ERROR,
    error: true,
});

export const enterpriseIdLoading = () => ({
    type: enterpriseIdActionTypes.ENTERPRISE_ID_LOADING,
});

export const enterpriseIdDisableLoading = () => ({
    type: enterpriseIdActionTypes.ENTERPRISE_ID_DISABLE_LOADING,
});
