/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as profileActionTypes from './Profile-ActionTypes';

export const clearProfileDetails = () => ({
    type: profileActionTypes.CLEAR_PROFILE_DETAILS,
});

export const setProfileDetails = (data) => ({
    type: profileActionTypes.SET_PROFILE_DETAILS,
    data,
});

export const setProfileEmailId = (data) => ({
    type: profileActionTypes.SET_PROFILE_EMAIL_ID,
    data,
});

export const updateEmailAddress = (emailId) => ({
    type: profileActionTypes.SET_PROFILE_EMAIL_ADDRESS,
    data: {
        emailId,
    },
});

export const setProfileUserRole = (data) => ({
    type: profileActionTypes.SET_PROFILE_USER_ROLE,
    data,
});

export const loadProfileDetails = (accesstoken, userData) => ({
    type: profileActionTypes.LOAD_PROFILE_DETAILS,
    data: {
        accesstoken,
        userInfo: userData,
    },
});

export const updateUserProfileDetails = (userData) => ({
    type: profileActionTypes.UPDATE_PROFILE_DETAILS,
    data: {
        userInfo: userData,
    },
});

export const loadProfileDetailsFromLocalStorage = () => ({
    type: profileActionTypes.LOAD_OFFLINE_PROFILE_DETAILS,
});

export const updateShopRunnerUser = () => ({
    type: profileActionTypes.UPDATE_SHOPRUNNER_USER,
});

export const loadProfileUserRole = (userRole) => ({
    type: profileActionTypes.LOAD_PROFILE_USER_ROLE,
    data: {
        userRole,
    },
});

export const loadedProfileUserRole = () => ({
    type: profileActionTypes.LOADED_PROFILE_USER_ROLE,
});

export const setMembershipType = (membershipType) => ({
    type: profileActionTypes.SET_MEMBERSHIP_TYPE,
    membershipType,
});

export const setMembershipTypeLoading = () => ({
    type: profileActionTypes.SET_MEMBERSHIP_TYPE_LOADING,
});
